import { MembersApi, useGetMembersQuery } from '@src/modules/auth/services/memberApi'
import { setUsersMappedData } from '@src/modules/settings/data/ImportExport/ImportExportSlice'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { Dropdown } from 'antd'
import { useEffect, useRef, useState } from 'react'
import close from '../../../../../assets/icons/importExport/close-circle.svg'
import down from '../../../../../assets/icons/importExport/down.svg'
import right from '../../../../../assets/icons/importExport/right.svg'
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/members/search.svg'
import Search from '@src/modules/shared/components/Input/search'

const MappLine = ({
  importedUser,
  setMappedUsers,
  mappedUsers,
}: {
  importedUser: any
  setMappedUsers: Function
  mappedUsers: any[]
}) => {
  const dispatch = useAppDispatch()
  const [selectedUser, setSelectedUser] = useState('')
  const [isInputEmail, setIsInputEmail] = useState(false)
  const [invitedUserMail, setInvitedUserMail] = useState('')
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { data: users } = useGetMembersQuery({
    limit: 10,
    skip: currentPage,
    search,
  })
  const divRef = useRef(null)

  const addInvitedUser = () => {
    setMappedUsers({ ...mappedUsers, [importedUser]: { user: invitedUserMail, isInvited: true } })
  }
  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
  }
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    if (scrollHeight - scrollTop === clientHeight && users?.metadata?.last_page > currentPage) {
      setCurrentPage((prev) => prev + 1)
    }
  }
  const removeUserByEmail = (email: string) => {
    const updatedUsers = {}
    Object.keys(mappedUsers).forEach((key) => {
      const currentUser = mappedUsers[key]
      if (currentUser.user === email) {
        return
      }
      updatedUsers[key] = currentUser
    })
    setMappedUsers(updatedUsers)
    setInvitedUserMail('')
  }
  useEffect(() => {
    dispatch(
      MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
        setCurrentPage(1)
        scrollToTop()
        if (draft?.payload) draft.payload = []
        return draft
      }),
    )
  }, [search])
  return (
    <div className="content_line">
      <div className="user">{importedUser}</div>

      <img src={right} />

      <Dropdown
        dropdownRender={() => (
          <div className="ant-dropdown-menu task-dropdown spaces-tree task-dropdown-users">
            <div className="option_list">
              <Dropdown
                dropdownRender={() => (
                  <div
                    className="ant-dropdown-menu users_list"
                    onScroll={handleScroll}
                    ref={divRef}
                  >
                    <Search
                      onSearch={(s) => {
                        setCurrentPage(1)
                        setSearch(s)
                      }}
                      prefix={<SearchIcon />}
                      variant="label-inline"
                    />

                    {users?.payload?.map((user, usersIndex: number) => {
                      return (
                        <div
                          className="user_list_line"
                          key={'user' + usersIndex}
                          onClick={() => {
                            setSelectedUser(user?.email)
                            setMappedUsers({
                              ...mappedUsers,
                              [importedUser]: { user: user?.id, isInvited: false },
                            })
                            dispatch(setUsersMappedData(mappedUsers))
                          }}
                        >
                          {user?.avatar ? (
                            <img src={user?.avatar} className="avatar" />
                          ) : (
                            <span className="first_letter">{user?.email?.slice(0, 1)}</span>
                          )}
                          <span className="email">{user?.email?.slice(0, 20)}...</span>
                        </div>
                      )
                    })}
                  </div>
                )}
                trigger={['click']}
              >
                <span onClick={() => setIsInputEmail(false)}>Select a user</span>
              </Dropdown>

              <span onClick={() => setIsInputEmail(true)}>Invite by email</span>
            </div>
          </div>
        )}
        trigger={['click']}
      >
        <div className="select_user">
          {isInputEmail ? (
            <>
              <input
                type="text"
                placeholder="Enter user email"
                value={invitedUserMail}
                onChange={(e) => {
                  setInvitedUserMail(e.target.value)
                }}
                onBlur={() => addInvitedUser()}
              />
            </>
          ) : (
            <>
              {selectedUser ? (
                <span>{selectedUser?.slice(0, 25)}</span>
              ) : (
                <span>Do not import user</span>
              )}
            </>
          )}

          {invitedUserMail ? (
            <img
              src={close}
              onClick={() => {
                removeUserByEmail(invitedUserMail)
              }}
            />
          ) : (
            <img src={down} />
          )}
        </div>
      </Dropdown>
    </div>
  )
}

export default MappLine
