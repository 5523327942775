import { ReactComponent as Bar } from '../../assets/icons/dashboard/bar.svg'
import BarsVersion from './BarsVersion/BarsVersion'
import HigChart from './HigChart'
import { ReactComponent as Line } from '../../assets/icons/dashboard/line.svg'
import { Col, Row, Select, Skeleton } from 'antd'
import { generateYears } from './utils/generateYears'
import { transformDataYearlyOverview } from './transofrmYearlyOverview'
import { useGetYearlyOverviewQuery } from '../../services/dashboardApi'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { enMonthsLong, frMonthsLong } from './utils/month'

export default function YearlyOverview() {
  const { t, i18n } = useTranslation(['dashboard'])
  const [selectedKey, setSelectedKey] = useState<string>('0')
  const [year, setYear] = useState(new Date().getFullYear())
  const theme = document.querySelector('body')?.id
  const language = i18n?.language

  const { data, isLoading } = useGetYearlyOverviewQuery(
    {
      year,
    },
    {
      skip: !year,
    },
  )

  const chatParams = data && transformDataYearlyOverview(data, i18n)
  const textStyleChart = {
    fontSize: '12px',
    fontFamily: '',
    color: theme === 'light' ? '#6B8396' : '#D7E7EF',
  }

  const options = {
    chart: {
      type: 'spline',
      height: '220',
      backgroundColor: theme === 'light' ? '#f8fbfd' : 'transparent',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      ...chatParams?.xAxis,
      labels: {
        style: textStyleChart,
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: textStyleChart,
      },
    },
    lang: {},
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
      series: {
        stickyTracking: true,
        animation: {
          duration: 2000,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    tooltip: {
      borderRadius: 10,
      outside: false,
      shared: false,
      formatter: function () {
        return `<span style="color:#6B8396 ;font-size: 14px">${language === 'ar' ? this.key : language === 'en' ? enMonthsLong.find((el) => el.includes(this.key)) : frMonthsLong.find((el) => el.includes(this.key))}</span><br/><span style="color:${this.color} ; font-size:20px">\u25CF</span> <b style="color:#6B8396  ; font-weight:400;">${this.y}</b> <span style="color:#6B8396  ; font-weight:300;">${this.series.name}</span> <br/>`
      },
    },
    credits: {
      enabled: false,
    },

    series: chatParams?.series,
  }

  const onChange = (value: string) => {
    setYear(Number(value))
  }

  const itemsMap = {
    '1': <HigChart options={options} />,
    '0': <BarsVersion data={data} />,
  }

  const renderComponent = () => {
    if (selectedKey !== null && itemsMap[selectedKey]) {
      return itemsMap[selectedKey]
    }
    return null
  }
  const headerItems = [
    {
      value: '0',
      icon: <Line />,
      radiusLTR: '7px 0px 0px 7px',
      radiusRTL: '0px 7px 7px 0px',
    },
    {
      value: '1',
      icon: <Bar />,
      radiusLTR: '0px 7px 7px 0px',
      radiusRTL: '7px 0px 0px 7px',
    },
  ]
  return (
    <div className="yearly-overview">
      {isLoading ? (
        <YearlyOverviewSkeleton />
      ) : (
        <>
          <div className="yearly-overview_header">
            <p className="yearly-overview_header-title">{t('select_year')}</p>
            <Select
              // showSearch
              placeholder={t('select_team')}
              optionFilterProp="children"
              onChange={onChange}
              size="small"
              options={generateYears()}
              defaultValue={new Date().getFullYear().toString()}
              className="yearly-overview_select"
            />
            <div className="yearly-overview_header-elements">
              {headerItems.map((item) => (
                <div
                  key={item.value}
                  style={{
                    borderRadius: i18n?.language === 'ar' ? item?.radiusRTL : item?.radiusLTR,
                    backgroundColor:
                      selectedKey === item?.value && theme === 'light'
                        ? '#D7E7EF'
                        : theme === 'dark'
                          ? '#1D1D1D'
                          : '',
                  }}
                  onClick={() => setSelectedKey(item.value)}
                  className="yearly-overview_header-element"
                >
                  {item.icon}
                </div>
              ))}
            </div>
          </div>
          {renderComponent()}
        </>
      )}
    </div>
  )
}

const YearlyOverviewSkeleton = () => {
  return (
    <div className="yearly-overview-skeleton">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Skeleton.Input active size="small" style={{ width: 150 }} />
        </Col>
        <Col>
          <Skeleton.Input active size="small" style={{ width: 100 }} />
        </Col>
      </Row>
      <Row gutter={16}>
        {Array.from({ length: 12 }).map((_, index) => (
          <Col span={2} key={index}>
            <Skeleton active paragraph={{ rows: 4 }} />
          </Col>
        ))}
      </Row>
    </div>
  )
}
