import { ReactComponent as Prev } from '../../assets/prev.svg'
import { ReactComponent as Next } from '../../assets/next.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'
import { formatDateToMultiLang } from '@src/modules/tasks/utils/formateDateToMultiLang'
const DateNavigator = ({
  startDate,
  endDate = new Date(),
  setCurrentDate,
  currentDate,
}: {
  startDate?: Date
  endDate?: Date
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>
  currentDate: Date
}) => {
  const { i18n } = useTranslation()

  const isPrevDisabled = () => {
    const prevDate = new Date(currentDate)
    prevDate.setDate(prevDate.getDate() - 1)
    return prevDate < startDate
  }

  const isNextDisabled = () => {
    const nextDate = new Date(currentDate)
    nextDate.setDate(nextDate.getDate() + 1)
    return nextDate > endDate
  }

  const handlePrevClick = () => {
    const newDate = new Date(currentDate)
    newDate.setDate(newDate.getDate() - 1)
    setCurrentDate(newDate)
  }

  const handleNextClick = () => {
    const newDate = new Date(currentDate)
    newDate.setDate(newDate.getDate() + 1)
    setCurrentDate(newDate)
  }
  const language = localStorage.getItem('i18nextLng')
  return (
    <div className="followup-dashboard_calendar" dir={language === 'ar' && 'ltr'}>
      {!isPrevDisabled() && (
        <Button onClick={handlePrevClick} disabled={isPrevDisabled()}>
          <Prev />
        </Button>
      )}

      <span className="followup-dashboard_date">
        {formatDateToMultiLang(currentDate, i18n?.language)}
      </span>

      {!isNextDisabled() && (
        <Button onClick={handleNextClick} disabled={isNextDisabled()}>
          <Next />
        </Button>
      )}
    </div>
  )
}

export default DateNavigator
