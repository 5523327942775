import {
  useDeleteAttachementsMutation,
  useGetAttachmentByIdQuery,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from '@src/modules/tasks/services/tasksApi'
import { Modal, Tooltip, message } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as MediaTypeSVG } from './../../assets/icons/mediaPlayer/media-type.svg'
import { ReactComponent as FullScreenSVG } from './../../assets/icons/mediaPlayer/full-screen.svg'
import { ReactComponent as ZoomInSVG } from './../../assets/icons/mediaPlayer/zoom-in.svg'
import { ReactComponent as ZoomOutSVG } from './../../assets/icons/mediaPlayer/zoom-out.svg'
import { ReactComponent as DonwloadSVG } from './../../assets/icons/mediaPlayer/download.svg'
import { ReactComponent as OpenSVG } from './../../assets/icons/mediaPlayer/open.svg'
import { ReactComponent as ShareSVG } from './../../assets/icons/mediaPlayer/copy.svg'
import { ReactComponent as DeleteSVG } from './../../assets/icons/mediaPlayer/delete.svg'
import { ReactComponent as CloseSVG } from './../../assets/icons/mediaPlayer/close.svg'
import { ReactComponent as ArrowBackSVG } from './../../assets/icons/mediaPlayer/arrow-back.svg'
import { ReactComponent as ArrowNextSVG } from './../../assets/icons/mediaPlayer/arrow-next.svg'
import ImagePlayer from './ImagePlayer/ImagePlayer'
import VideoPlayer from './VideoPlayer/VideoPlayer'
// import { downloadFile } from '../../utils/downloadFile'
import Spinner from '../Spinner'
import PdfReader from './PdfReader/PdfReader'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { useEffect, useRef, useState } from 'react'

const getMediaType = (media) => {
  const { mimetype } = media || {}
  if (mimetype?.includes('image')) {
    return 'image'
  } else if (mimetype?.includes('video')) {
    return 'video'
  }
  if (mimetype?.includes('pdf')) {
    return 'pdf'
  }
}

const getAllowedAttachments = (attachements) => {
  return attachements?.filter(
    (attachement) =>
      attachement?.mimetype.includes('image') ||
      attachement?.mimetype.includes('video') ||
      attachement?.mimetype?.includes('pdf'),
  )
}

const MediaPlayer = ({ open, handleClose, data, id }) => {
  const { t } = useTranslation(['taskDetails'])

  const [searchParams, setSearchparams] = useSearchParams()
  const [updateTask] = useUpdateTaskMutation()
  const [deleteAttachements] = useDeleteAttachementsMutation()
  const taskId = searchParams.get('taskId')
  const attachmentId = searchParams.get('attachementId')
  const { i18n } = useTranslation()
  // const [downloading, setDownloading] = useState<boolean>()
  const [activeSlider, setActiveSlider] = useState<number>(0)
  let { attachements, withSwiper = true } = data
  const navigate = useNavigate()
  const { data: task } = useGetTaskQuery({ id: taskId }, { skip: !taskId })
  const {
    data: media,
    isLoading,
    isError,
  } = useGetAttachmentByIdQuery({ id: attachmentId }, { skip: !attachmentId })

  const slider = useRef(null)

  useEffect(() => {
    if (isError && attachmentId) {
      closeMediaPlayes()
      message.error('Attachment not found')
    }
  }, [isError])

  attachements = attachements || task?.attachements || [media]

  withSwiper = attachements?.length > 1

  const closeMediaPlayes = () => {
    if (searchParams?.get('attachementId')) {
      searchParams.delete('attachementId')
      setSearchparams(searchParams)
    }
    handleClose(id)
  }

  const allowedAttachments = getAllowedAttachments(attachements)

  const videos = allowedAttachments.filter((att) => att.mimetype.includes('video'))

  const attachmentIndex = allowedAttachments?.findIndex(
    (attachment) => attachment.id === attachmentId,
  )

  let settings: any = {
    dots: true,
    infinite: false,
    speed: 300,
    swipe: withSwiper,
    focusOnSelect: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    touchMove: false,
    arrows: false,
    rtl: i18n.language === 'ar',
    afterChange: (current) => {
      if (withSwiper) {
        setActiveSlider(current)
        navigate(updateURL({ label: 'attachementId', value: allowedAttachments[current]?.id }))
      }
    },
    beforeChange: (_, next) => setActiveSlider(next),
  }

  if (attachmentIndex !== -1)
    settings = {
      ...settings,
      initialSlide: attachmentIndex,
    }

  useEffect(() => {
    attachmentIndex !== -1 && setActiveSlider(attachmentIndex)
  }, [attachmentIndex])

  const deleteAttachementsHandler = () => {
    deleteAttachements({ id: data?.attachementId as any }).then(() => {
      closeMediaPlayes()
      if (data?.onDelete) data?.onDelete()
      if (task)
        updateTask({
          id: taskId,
          attachementsId: task?.attachements
            ?.filter((attachement) => attachement.id !== data?.attachementId)
            .map((attachement) => attachement.id) as string[],
        } as any)
    })
  }

  const fullScreenMode = () => {
    const element: any = document.body
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) element.requestFullscreen()
    } else if (document.exitFullscreen)
      document.exitFullscreen().catch((err) => {
        message.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
      })
  }

  const openInNewTab = () => {
    if (media?.location || attachements[0].location)
      window.open(
        media?.location ? media?.location : attachements[0].location,
        '_blank',
        'noopener,noreferrer',
      )
  }
  const shareMedia = () => {
    navigator.clipboard.writeText(media?.location ? media?.location : attachements[0].location)
    message.success(t('link_copied'))
  }

  const activeMedia = allowedAttachments[activeSlider]

  const mediaType = getMediaType(activeMedia)
  const isLastSlide = activeSlider === allowedAttachments.length - 1

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={closeMediaPlayes}
      onCancel={closeMediaPlayes}
      wrapClassName="media-player"
      closable={false}
    >
      <div className="media-player-content">
        <div className="media-player-content-header">
          <div className="header-media-type">
            <MediaTypeSVG />
            {mediaType === 'image'
              ? t('image')
              : mediaType === 'video'
                ? t('video')
                : t('pdf') || mediaType}
          </div>
          {mediaType === 'image' ? (
            <div className="header-zoom">
              <Tooltip title={t('full_screen')}>
                <FullScreenSVG className="media-full-screen" onClick={fullScreenMode} />
              </Tooltip>
              <div className="header-zoom-controll">
                <Tooltip title={t('zoom_out')}>
                  <div>
                    <ZoomOutSVG />
                  </div>
                </Tooltip>
                <div className="zoom-value">100%</div>
                <Tooltip title={t('zoom_in')}>
                  <div>
                    <ZoomInSVG />
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : null}
          <div className="header-options">
            <Tooltip title={t('download_attachement')}>
              <div
                className="header-options-download"
                onClick={() => {
                  window.open(
                    (media?.location ? media?.location : attachements[0].location)?.slice(0, -1) +
                      '1',
                    '_blank',
                    'noopener,noreferrer',
                  )
                }}
              >
                <DonwloadSVG /> {t('download')}{' '}
              </div>
            </Tooltip>
            <Tooltip title={t('open_in_new_tab')}>
              <OpenSVG onClick={openInNewTab} />
            </Tooltip>
            <Tooltip title={t('copy_link')}>
              <ShareSVG onClick={shareMedia} />
            </Tooltip>
            {taskId && attachmentId && (
              <Tooltip title={t('delete_attachement')}>
                <DeleteSVG onClick={deleteAttachementsHandler} />
              </Tooltip>
            )}
            {/* <div className="header-options-comment">
              <CommentSVG />
              Add comment
            </div> */}
            <Tooltip title={t('close')}>
              <CloseSVG onClick={closeMediaPlayes} />
            </Tooltip>
          </div>
        </div>

        <div className="media-player-content-body" onClick={closeMediaPlayes}>
          {withSwiper && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  activeSlider !== 0 && slider?.current?.slickPrev()
                }}
                className={`media-player-swiper-arrow ${activeSlider === 0 ? 'disable-button' : ''}`}
              >
                <ArrowBackSVG />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  !isLastSlide && slider?.current?.slickNext()
                }}
                className={`media-player-swiper-arrow ${isLastSlide ? 'disable-button' : ''}`}
              >
                <ArrowNextSVG />
              </button>
            </>
          )}
          <Slider {...settings} ref={slider}>
            {allowedAttachments?.map((media, index: number) => {
              const mediaType = getMediaType(media)

              return (
                <div key={index} className="slider-container">
                  {isLoading ? (
                    <div className="media-spinner">
                      <Spinner />
                    </div>
                  ) : mediaType !== 'video' || !activeSlider || index === activeSlider ? (
                    <>
                      {mediaType === 'image' && <ImagePlayer media={media} />}
                      {mediaType === 'video' && (
                        <VideoPlayer videos={videos} activeIndex={attachmentIndex} media={media} />
                      )}
                      {mediaType === 'pdf' && <PdfReader media={media} />}
                    </>
                  ) : null}
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </Modal>
  )
}

export default MediaPlayer
