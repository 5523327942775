import { api } from '@src/modules/shared/services/api'
interface Content {
  content: string
  taskId?: string
  time: string
}

export interface CreateAnswer {
  questionId: string
  parentId?: string
  date: Date
  body: Content[]
}
export interface updateAnswer {
  body: {
    parentId?: string
    date: Date
    content: Content[]
  }
  id: string
}
export const answersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAnswers: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answer`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },
    }),

    addEmojiAnswer: build.mutation<any, any>({
      query: (body: { answerId: string; code: null | string; questionId: string }) => ({
        url: `api/reaction`,
        method: 'POST',
        body,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const {
          data: { payload: data },
        }: any = (await queryFulfilled) || {}

        dispatch(
          answersApi.util.updateQueryData('getAllAnswers', _, (draft) => {
            if (draft?.payload)
              draft.payload = draft?.payload?.map((answer) =>
                data?.answerId === answer?.id
                  ? {
                      ...answer,
                      reactions: [...(answer?.reactions || []), data],
                    }
                  : answer,
              )

            return draft
          }),
        )
      },
    }),
    updateEmojiAnswer: build.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `api/reaction/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const {
          data: { payload: data },
        }: any = (await queryFulfilled) || {}

        dispatch(
          answersApi.util.updateQueryData('getAllAnswers', undefined, (draft) => {
            if (draft?.payload)
              draft.payload = draft?.payload?.map((answer) =>
                data?.answerId === answer?.id
                  ? {
                      ...answer,
                      reactions: answer?.reactions?.map((reaction) =>
                        reaction?.id === data?.id ? data : reaction,
                      ),
                    }
                  : answer,
              )
            return draft
          }),
        )
      },
    }),

    deleteEmojiAnswer: build.mutation<any, any>({
      query: ({ id, answerId, questionId }) => ({
        url: `api/reaction/${id}`,
        method: 'DELETE',
        body: {
          answerId,
          questionId,
        },
      }),
      async onQueryStarted(arg, { dispatch }) {
        const { id, answerId } = arg || {}

        dispatch(
          answersApi.util.updateQueryData('getAllAnswers', undefined, (draft) => {
            if (draft?.payload)
              draft.payload = draft?.payload?.map((answer) =>
                answerId === answer?.id
                  ? {
                      ...answer,
                      reactions: answer?.reactions?.filter((reaction) => reaction?.id !== id),
                    }
                  : answer,
              )
            return draft
          }),
        )
      },
      // invalidatesTags: [{ type: 'REPLIES', id: 'MEMBER' }] as any,
    }),
    searchAnswers: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/advancedsearch/v2`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },
    }),
  }),
})

export const {
  useGetAllAnswersQuery,
  useAddEmojiAnswerMutation,
  useUpdateEmojiAnswerMutation,
  useDeleteEmojiAnswerMutation,
  useSearchAnswersQuery,
} = answersApi
