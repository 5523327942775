import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { Skeleton } from 'antd'

const SetAssignment = () => {
  const { width } = useWindowSize()

  return (
    <div className="skeleton_card setAssignment_skeleton">
      <div className="setState_skeleton_header">
        <div className="setState_skeleton_header_right">
          <Skeleton.Avatar active></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={
              width > 450
                ? { width: '194px', height: '16px', borderRadius: '30px' }
                : { width: '60px', height: '14px', borderRadius: '30px' }
            }
          ></Skeleton.Button>

          <Skeleton.Avatar active style={{ width: '30px', height: '30px' }}></Skeleton.Avatar>

          <Skeleton.Avatar
            active
            style={{ width: '30px', height: '30px', marginLeft: '-10px' }}
          ></Skeleton.Avatar>

          {width > 600 && (
            <Skeleton.Avatar
              active
              style={{ width: '30px', height: '30px', marginLeft: '-10px' }}
            ></Skeleton.Avatar>
          )}
        </div>

        <Skeleton.Button
          active
          style={
            width > 600
              ? { width: '85px', height: '22px', borderRadius: '30px' }
              : { width: '40px', height: '14px', borderRadius: '30px' }
          }
        ></Skeleton.Button>
      </div>

      <Skeleton.Button
        active
        style={{ width: '220px', height: '16px', borderRadius: '30px', marginLeft: '40px' }}
      ></Skeleton.Button>
    </div>
  )
}

export default SetAssignment
