export const getActivityColor = (activity) =>
  activity?.source === 'task'
    ? '#2BA7DF'
    : activity?.source === 'space'
      ? '#FCA9BD'
      : activity?.source === 'genericFolder'
        ? '#3DD455'
        : activity?.source === 'list'
          ? '#7C4493'
          : 'red'
