export const filterFields = (data, myFieldsObj) => {
  const filtredFields = {}
  if (!data) return []
  Object.keys(data)?.forEach((field) => {
    if (myFieldsObj[field]) {
      filtredFields[field] = data[field]
    }
  })
  return filtredFields
}
