import { useAppDispatch, useAppSelector } from '../../store'

import { ReactComponent as CollapseSVG } from '../../assets/icons/sidebar/Collapse.svg'
import DailyQuestionSidebar from './../../../dailyQuestions/components/Sidebar'
import { Layout } from 'antd'
import MainSidebar from '../SidebarItems/SidebarItems'
import RTLStyleDecorator from '../RTLStyleDecorator'
import SettingsSidebar from './../../../settings/components/Sidebar/Sidebar'
import ViewSidebar from '../ViewSidebar'
import { toggleSidebar } from '../../store/slices/settings/settingsSlice'
import { useLocation } from 'react-router-dom'

const sidebars = {
  mainSidebar: MainSidebar,
  settingSidebar: SettingsSidebar,
  dailyQuestionSidebar: DailyQuestionSidebar,
}

function Sidebar() {
  const { sidebar } = useAppSelector((state) => state.settings)
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const inSettings = pathname?.includes('settings')
  const inDailyQuestion = pathname?.includes('daily-questions')
  const sidebarId = inDailyQuestion ? 'daily-question-sidebar' : 'layout-sidebar'

  const SidebarComponent = inSettings
    ? sidebars['settingSidebar']
    : inDailyQuestion
      ? sidebars['dailyQuestionSidebar']
      : sidebars['mainSidebar']
  const sidebarOpen = !inSettings ? sidebar[sidebarId] : true

  return (
    <ViewSidebar open={sidebarOpen} sidebarId={sidebarId}>
      <Layout.Sider className="main-layout-sidebar">
        <div className="sidebar">
          {sidebarOpen && !inSettings && (
            <RTLStyleDecorator>
              <div
                className={`sidebar-collapse-btn left`}
                onClick={() => dispatch(toggleSidebar(sidebarId))}
              >
                <CollapseSVG />
              </div>
            </RTLStyleDecorator>
          )}

          <SidebarComponent />
        </div>
      </Layout.Sider>
    </ViewSidebar>
  )
}

export default Sidebar
