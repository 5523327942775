import { createReactBlockSpec } from '@blocknote/react'
import { defaultProps } from '@blocknote/core'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { useEffect, useRef, useState } from 'react'
import { Dropdown, Switch } from 'antd'
import darkEmbed from '../../../../../shared/assets/icons/blockNote/darkEmbed.svg'
import drive from '../../../../../shared/assets/icons/blockNote/drive.svg'
import dots from '../../../../../shared/assets/icons/blockNote/dots.svg'
import embed from '../../../../../shared/assets/icons/blockNote/embed.svg'
import iconError from '../../../../../shared/assets/icons/blockNote/error.svg'
import eye from '../../../../../shared/assets/icons/blockNote/eye.svg'
import open from '../../../../../shared/assets/icons/blockNote/open.svg'
import { setGoogleDriveElement } from '@src/modules/document/data/documentSlice/documentSlice'
import Button from '../../../Button/Button'

const GoogleDriveBlock = createReactBlockSpec(
  {
    type: 'googleDrive',
    propSchema: {
      ...defaultProps,
      link: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: ({ block }: any) => {
      const className = block.props.class || 'embed-container'
      const link = block.props.link

      const dispatch = useAppDispatch()
      const driveRef = useRef<string>('')
      const [driveLink, setDriveLink] = useState<string>(link || '')
      const [isError, setIsError] = useState<boolean>(false)

      const onSwitchChange = (checked: boolean) => {
        console.log(`switch to ${checked}`)
      }

      const onInputChange = (e) => {
        driveRef.current = e.target.value
        if (
          driveRef.current !== '' &&
          !/^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/.test(driveRef.current)
        ) {
          setIsError(true)
        } else setIsError(false)
      }

      const openSlide = () => {
        driveLink && window.open(driveLink, '_blank')
      }

      useEffect(() => {
        if (driveLink) dispatch(setGoogleDriveElement({ link: driveLink }))
      }, [driveLink])

      return (
        <div className={className}>
          <div className="embed-container-left">
            <img src={drive} className="embed-icon" />

            <span className="embed-name">Google drive</span>

            <input
              type="text"
              placeholder="Paste or type URL here..."
              defaultValue={driveLink || ''}
              onChange={onInputChange}
            />
          </div>

          <div className="embed-container-right">
            {isError && (
              <span className="error">
                <img src={iconError} /> Unrecognized URL
              </span>
            )}

            {driveLink ? (
              <div className="settings-embed">
                <img src={open} className="open-icon" onClick={openSlide} />

                <Dropdown
                  className={className}
                  dropdownRender={() => (
                    <div className="dropdown-render-settings">
                      <span>
                        <img src={eye} /> Show preview
                        <Switch size="small" onChange={onSwitchChange} />
                      </span>
                      <div className="divider"></div>
                      <span>
                        <img src={darkEmbed} /> Copy link to block
                      </span>

                      <span>
                        <img src={darkEmbed} /> Copy link to original
                      </span>
                    </div>
                  )}
                  trigger={['click']}
                >
                  <img src={dots} />
                </Dropdown>
              </div>
            ) : (
              <Button
                className="btn-embed"
                style={isError ? { cursor: 'not-allowed' } : {}}
                disabled={isError}
                onClick={() => !isError && setDriveLink(driveRef.current)}
              >
                <img src={embed} /> Embed
              </Button>
            )}
          </div>
        </div>
      )
    },
  },
)

export default GoogleDriveBlock
