import NotFound from '../../features/NotFound/NotFound'
import Header from '../Header'

const SomthingWrong = () => {
  return (
    <div className="somthing-wrong-page">
      <Header removeEvents={true} />
      <div className="content">
        <NotFound
          statusCode={403}
          message="Oops! Something went wrong"
          underMessage="You don't have permission to access this page"
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  )
}

export default SomthingWrong
