import { CSSProperties, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Bar({ stats }) {
  const { t } = useTranslation(['tasks'])
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleMouseOver = () => {
    setIsTooltipVisible(true)
  }
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const { offsetX, offsetY } = event.nativeEvent
    setTooltipPosition({ x: offsetX, y: offsetY - 14 })
  }
  const handleMouseOut = () => {
    setIsTooltipVisible(false)
  }

  const tooltipStyle = useMemo(
    () =>
      ({
        position: 'absolute',
        width: 'fit-content',
        height: '17px',
        left: `25px`,
        top: `${tooltipPosition.y}px`,
      }) as CSSProperties,
    [tooltipPosition],
  )
  const generateGradientBackground = (hexColor: string, opacity: number): string => {
    const rgbaColor = hexToRGBA(hexColor, opacity)

    return `linear-gradient(159.63deg, ${rgbaColor} 13.54%, ${hexColor} 86.46%)`
  }

  const hexToRGBA = (hex: string, opacity: number): string => {
    if (!hex) return ''

    const r = parseInt(hex?.slice(1, 3), 16)
    const g = parseInt(hex?.slice(3, 5), 16)
    const b = parseInt(hex?.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  return (
    <div
      className="bars-chart_element_bar"
      style={{
        height: `${stats?.percentage}`,
        background: generateGradientBackground(stats?.color, 0.7),
        cursor: 'pointer',
        position: 'relative',
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMove}
    >
      {isTooltipVisible && (
        <div className="bars-chart_tooltip" style={tooltipStyle}>
          <p
            className="bars-chart_tooltip-status"
            style={{
              background: generateGradientBackground(stats?.color, 0.7),
              boxShadow: '-6.39px 6.39px 10.96px 2.74px ' + stats?.color,
            }}
          ></p>
          <p className="bars-chart_tooltip-percentage">{stats?.percentage}</p> {stats?.name}
          <p className="bars-chart_tooltip-tasks">
            {stats?.total} {t('tasks').slice(0, -1)}
          </p>
        </div>
      )}
    </div>
  )
}
