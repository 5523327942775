import { ReactComponent as NoNotif } from '../../assets/icons/notification/noNotif.svg'
import { useTranslation } from 'react-i18next'

const NoNotifications = () => {
  const { t } = useTranslation(['notifications'])

  return (
    <div className="not_notifications">
      <NoNotif />

      <p>{t('You have no notifications yet ..')}</p>
    </div>
  )
}

export default NoNotifications
