import { api } from '@src/modules/shared/services/api'
import { IUser } from '../data/authTypes'

interface getUsersPayload {
  id?: string
  users?: IUser[]
  skip?: number
  limit?: number
  listId?: string
}
interface getTeamsPayload {
  teams?: any
  skip?: number
  limit?: number
  search?: string
  userId?: string
}

const defaultLimit = 6

export const UsersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<any, getUsersPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/users`,
          params,
        }
      },

      transformResponse: (response: { payload: IUser[] }): IUser[] => {
        return response.payload
      },
    }),
    getOneUser: build.query<any, getUsersPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/users/${params.id}`,
          params,
        }
      },
    }),
    getTeams: build.query<any, getTeamsPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/teams`,
          params,
        }
      },

      transformResponse: (response: { payload: any }): any[] => {
        return {
          ...(response.payload || {}),
          payload: response?.payload?.payload?.map((team) => ({ ...team, isTeam: true })),
        }
      },
    }),
  }),
})

export const { useGetUsersQuery, useGetOneUserQuery, useGetTeamsQuery } = UsersApi
