import React, { useState, ChangeEvent, DragEvent, useRef } from 'react'
import { ReactComponent as UploadIcon } from '../../assets/icons/components/upload.svg'
import useWindowSize from '../../hooks/useWindowSize'
import Button from '../Button/Button'
import Input from '../Input/Input'

interface FileUploadProps {
  handleFile: (file: File) => void
  label: string
  disabled: boolean
  isCreateWorkSpace?: boolean
  className?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  handleFile,
  label,
  disabled,
  isCreateWorkSpace,
  className,
}) => {
  const [dragging, setDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { width } = useWindowSize()

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    setDragging(false)
    const file = e.dataTransfer.files[0]
    handleFile(file)
  }

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    if (file) {
      handleFile(file)
    }
  }

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div
      className={`file-upload-container ${dragging ? 'dragging' : ''} ${isCreateWorkSpace && 'in-workspace-upload'} ${className}`}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleFileInputClick}
    >
      {isCreateWorkSpace && width <= 850 ? (
        <p className="in-workspace-letter">S</p>
      ) : (
        <p className="uplod-label">{label}</p>
      )}

      <Input
        disabled={disabled}
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        inputRef={fileInputRef}
        style={{ display: 'none' }}
        variant="label-inline"
      />

      <Button
        className={`uplod-button  ${isCreateWorkSpace && width <= 850 && 'in-workspace-upload-icon'}`}
        htmlType="button"
      >
        <UploadIcon />
      </Button>
    </div>
  )
}

export default FileUpload
