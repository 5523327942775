/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'
import { CreateFolderPayload } from './folderTypes'
import { FolderType } from '../../components/Folder/Folder'

export const createFolder = createAsyncThunk(
  'folders/create-folder',
  async (query: CreateFolderPayload) => {
    try {
      const response = await axiosInstance.post(`/api/genericfolder`, query)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getFolder = createAsyncThunk('folders/get-folder', async (query: { id: string }) => {
  try {
    const response = await axiosInstance.get(`/api/genericfolder/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const bulkugenericfolders = createAsyncThunk(
  'spaces/bulk-folders',
  async (query: { folders: { id: string; order: number; newOrder: number }[] }) => {
    try {
      const response = await axiosInstance.patch(`/api/bulkugenericfolders`, query)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const bulkChildgenericfolder = createAsyncThunk(
  'spaces/bulk-folders-lists',
  async (query: { lists: { id: string; order: number; newOrder: number }[] }) => {
    try {
      const response = await axiosInstance.patch(`/api/bulkChildgenericfolder`, query)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const updateFolder = createAsyncThunk('folders/update-folder', async (query: FolderType) => {
  try {
    const { id, ...body } = query
    const response = await axiosInstance.patch(`/api/genericfolder/${id}`, body)

    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const deleteFolder = createAsyncThunk(
  'folders/delete-folder',

  async (query: { id: string }) => {
    try {
      const response = await axiosInstance.delete(`/api/genericfolder/${query.id}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
