import { ReactNode } from 'react'
import { TreeItemRenderContext } from 'react-complex-tree'
import { FileType } from '@src/modules/spaces/data/fileSlice/fileSlice'
import { Skeleton } from 'antd'
import { ReactComponent as DragIcon } from './../../../../../assets/icons/task/drag.svg'
import AddSubTaskInput from '@src/modules/tasks/components/ListView/AddSubTask/AddSubTaskInput'
import TaskRow from '@src/modules/tasks/components/ListView/TaskRow'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'

export const TaskSkeleton = ({
  depth,
  depthPadding = 42,
}: {
  depth: number
  depthPadding?: number
}) => {
  const randomWidth = Math.floor(Math.random() * (400 - 150 + 1)) + 150
  return (
    <RTLStyleDecorator>
      <div
        className="task-item-skeleton"
        style={{
          paddingLeft: `${(depth + 1) * 29 + depthPadding}px`,
        }}
      >
        <Skeleton.Avatar size={'small'} active style={{ width: '16px', height: '16px' }} />
        <Skeleton.Input
          active
          size={'small'}
          style={{ width: `${randomWidth}px`, height: '16px' }}
        />
      </div>
    </RTLStyleDecorator>
  )
}

export const getFakeTask = (tree, item) => {
  return tree['fake-task'] && tree['fake-task']?.parentId === item?.index ? tree['fake-task'] : null
}

export interface renderItemProps {
  title: string
  arrow: ReactNode
  depth: number
  context: TreeItemRenderContext
  children: ReactNode
  tree: any
  removeFakeTask: () => void
  addFakeTask: (p: string, t: boolean, b: boolean) => void
  activeList: FileType
}

export const renderItem = ({
  title,
  arrow,
  depth,
  context,
  children,
  removeFakeTask,
  addFakeTask,
  tree,
  // multiple,
  activeList,
}: renderItemProps) => {
  const item = tree[title]
  const data = item?.content
  const parentItem = tree[item?.parentId]
  const fakeTask = getFakeTask(tree, item)

  return (
    <li {...context.itemContainerWithChildrenProps} className="item-task-row">
      <div
        {...context.itemContainerWithoutChildrenProps}
        onDragOver={context.interactiveElementProps.onDragOver}
        draggable={context.interactiveElementProps.draggable}
        tabIndex={context.interactiveElementProps.tabIndex}
        data-rct-item-focus={context.interactiveElementProps['data-rct-item-focus']}
        data-rct-item-id={context.interactiveElementProps['data-rct-item-id']}
        data-rct-item-interactive={context.interactiveElementProps['data-rct-item-interactive']}
        // style={{ position: 'relative', height: 43 + 'px' }}
        className={`task-container`}
        onDragStart={(event) => {
          document.body.style.overflow = 'auto'
          const dragElements = document.querySelectorAll('.drag-element-spaces')
          dragElements.forEach((ele) => ele.remove())

          event.dataTransfer.clearData()
          event.dataTransfer.setData('text/plain', '')
          const dragElement = document.createElement('div')
          dragElement.className = 'drag-element-spaces'
          dragElement.innerHTML = `
            <div>${data?.name}</div>
            `
          document.body.appendChild(dragElement)
          document.body.style.overflow = 'hidden'
          event.dataTransfer.setDragImage(dragElement, 0, 0)
          context.interactiveElementProps.onDragStart(event)
        }}
      >
        {item?.id === 'fake-task' ? (
          <AddSubTaskInput
            parentId={item?.parentId}
            removeFakeTask={removeFakeTask}
            isBottom={item?.isBottom}
            data={parentItem?.content?.status}
            depth={depth}
            depthPadding={42}
            groupBy={null}
          />
        ) : (
          <div>
            <div className="task-container-options">
              <span className="drag-icon-task">
                <DragIcon />
              </span>
            </div>
            <TaskRow
              data={data}
              list={activeList}
              depth={depth}
              toogleExpand={context.toggleExpandedState}
              expand={context.expandItem}
              arrow={arrow}
              addFakeTask={(top: boolean, bottom: boolean) => addFakeTask(data?.id, top, bottom)}
              arrowProps={context.arrowProps}
            />
          </div>
        )}
      </div>

      {item?.isLoading ? (
        <>
          {fakeTask?.isTop && (
            <AddSubTaskInput
              parentId={tree[fakeTask?.index]?.parentId}
              removeFakeTask={removeFakeTask}
              isBottom={false}
              data={tree[fakeTask?.index]?.content?.status}
              depth={depth + 1}
              depthPadding={42}
              groupBy={null}
            />
          )}
          {Array(item?.content?.totalSubtask || 0)
            .fill('')
            .map((_, i) => (
              <TaskSkeleton depth={depth} key={i} />
            ))}
          {fakeTask?.isBottom && (
            <AddSubTaskInput
              parentId={tree[fakeTask?.index]?.parentId}
              removeFakeTask={removeFakeTask}
              isBottom={false}
              data={tree[fakeTask?.index]?.content?.status}
              depth={depth + 1}
              depthPadding={42}
              groupBy={null}
            />
          )}
        </>
      ) : (
        children
      )}
    </li>
  )
}

// const getDefaultStatus = (listId) => {}
