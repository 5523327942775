import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../../components/Header'
import Button from '@src/modules/shared/components/Button/Button'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { Segmented } from 'antd'
import { ReactComponent as ListIcon } from '@src/modules/tasks/assets/icons/tasks/list.svg'
import { ReactComponent as BoardIcon } from '@src/modules/tasks/assets/icons/tasks/board.svg'
import { SegmentedValue } from 'antd/es/segmented'
import { useEffect, useState } from 'react'
import TeamCard from '../../components/TeamCard/TeamCard'
import TeamList from '../../components/TeamList/TeamList'
import { getTeams } from '../../data/teams/TeamsThunk'
import SkeletonLoader from '@src/modules/shared/components/SkeletonLoader'
import { ReactComponent as ListSkeleton } from '../../assets/icons/teams/list_skeleton.svg'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTranslation } from 'react-i18next'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'

const Teams = () => {
  const { t } = useTranslation(['teams'])
  const { teams, status } = useAppSelector((state) => state.teams)
  const [activeItem, setActiveItem] = useState<string>('0')
  const headerProps = { title: t('manage_teams') }
  const dispatch = useAppDispatch()

  useTitle(t('Settings - Teams'))

  const items = [
    {
      label: t('list'),
      value: '0',
      icon: <ListIcon />,
    },
    {
      label: t('board'),
      value: '1',
      icon: <BoardIcon />,
    },
  ]

  useEffect(() => {
    dispatch(getTeams())
  }, [])

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="teams-view-container-tabs">
        <Segmented
          options={items}
          defaultValue={'0'}
          onChange={(e: SegmentedValue) => {
            setActiveItem(e as string)
          }}
        />
      </div>

      <div className="teams-view-container">
        <SkeletonLoader
          isLoading={status === 'loading'}
          skeleton={<ListSkeleton className="skeleton skeleton-teams" />}
        >
          {teams?.length > 0 ? (
            <>
              <div className={`teams-container ${activeItem === '0' ? 'list' : 'board'}`}>
                {activeItem === '0' ? (
                  <TeamList data={teams} status={status} />
                ) : (
                  teams?.map((team) => <TeamCard data={team} status={status} />)
                )}
              </div>

              <Can I="create" a="teams">
                <RTLStyleDecorator>
                  <div className="create-team-button-container float-button-teams">
                    <Button
                      children={t('create_team')}
                      className="create-team-button"
                      htmlType={'submit'}
                      onClick={() => dispatch(openModal({ id: 'teams-modal' }))}
                    />
                  </div>
                </RTLStyleDecorator>
              </Can>
            </>
          ) : (
            <div className="no-teams-container">
              <span>{t('you_have_no_teams')}</span>

              <div className="create-team-button-container">
                <Can I="create" a="teams">
                  <Button
                    children={t('create_team')}
                    className="create-team-button"
                    htmlType={'submit'}
                    onClick={() => dispatch(openModal({ id: 'teams-modal' }))}
                  />
                </Can>
              </div>
            </div>
          )}
        </SkeletonLoader>
      </div>
    </ViewLayout>
  )
}

export default Teams
