import { useAppSelector } from '@src/modules/shared/store'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { defaultFieldsIcons } from '../../../ListView/utils/defaultFields'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { fieldComponentMap, getCustomFieldValue } from '../../../ListView/utils/fieldComponentMap'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { useParams } from 'react-router-dom'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  useCreateCustomFieldValueMutation,
  useDeleteCustomFieldValueMutation,
  useUpdateCustomFieldValueMutation,
} from '@src/modules/tasks/services/customFieldsApi'
import { Fragment, useState } from 'react'

interface ICustomFieldsProps {
  task: TaskType
}

type ICustomFieldsValues = string | string[]

const CustomFields = ({ task }: ICustomFieldsProps) => {
  const { t } = useTranslation(['tasks'])
  const { headers } = useAppSelector((state) => state.tasks)
  const [updateTask] = useUpdateTaskMutation()
  const [data, setData] = useState<TaskType>(task)
  const [createCustomFieldValue] = useCreateCustomFieldValueMutation()
  const [updateCustomFieldValue] = useUpdateCustomFieldValueMutation()
  const [deleteCustomFieldValue] = useDeleteCustomFieldValueMutation()
  const { listId } = useParams()
  const { data: list } = useGetListQuery({ id: listId })

  const localUpdateTask = (label: string, value) => {
    setData((prev) => ({ ...prev, [label]: value }))
  }

  const getIcon = (customField) => {
    return (
      defaultFieldsIcons[customField.id] ||
      CustomFieldsIcons?.find((field) => field.field === customField?.fieldType)?.icon
    )
  }

  const notHiddenFields = headers?.filter((field) => !field.hidden)
  const customFields = notHiddenFields?.filter((field) => field?.custom)

  const createCustomFieldValueHandler = async (fieldId: string, value: ICustomFieldsValues) => {
    const newFieldValue = {
      value,
      customFieldId: fieldId,
    }

    const originalFieldsValues = task?.customFieldsValues || []

    localUpdateTask('customFieldsValues', [...originalFieldsValues, newFieldValue])

    try {
      const res = await createCustomFieldValue({
        value,
        task: {
          id: task.id,
        },
        customField: {
          id: fieldId,
        },
      }).unwrap()

      const { payload } = res || {}
      const updatedFieldsValues = [
        ...originalFieldsValues,
        {
          customFieldId: payload.customField?.id,
          value: payload.value,
          id: payload.id,
        },
      ]

      localUpdateTask('customFieldsValues', updatedFieldsValues)
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-create-custom-field-value'))
    }
  }

  const updateCustomFieldValueHandler = async (fieldId: string, value: ICustomFieldsValues) => {
    const originalFieldsValues = task?.customFieldsValues

    localUpdateTask(
      'customFieldsValues',
      originalFieldsValues.map((field) => (field.id === fieldId ? { ...field, value } : field)),
    )

    try {
      await updateCustomFieldValue({
        id: fieldId,
        value,
      }).unwrap()
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-update-custom-field-value'))
    }
  }

  const deleteCustomFieldValueHandler = async (fieldId: string) => {
    const originalFieldsValues = task?.customFieldsValues

    localUpdateTask(
      'customFieldsValues',
      originalFieldsValues.filter((field) => field.id !== fieldId),
    )

    try {
      await deleteCustomFieldValue({
        id: fieldId,
      }).unwrap()
    } catch (err) {
      localUpdateTask('customFieldsValues', originalFieldsValues)
      message.error(t('error-while-delete-custom-field-value'))
    }
  }

  return (
    <div className="task-custom-field">
      {customFields?.map((field) => {
        const ComponentConfig = fieldComponentMap[field.fieldType]

        const { component, props } = ComponentConfig || {}

        const CustomComponent = component || Fragment

        const componentProps = props
          ? props(field, task, false, updateTask, null, null, list)
          : null
        const customFieldValue = getCustomFieldValue(data, field)
        const icon = getIcon(field)

        const saveCustomFieldValue = (value: string) => {
          if (value === undefined || customFieldValue === value) return

          const taskField = data?.customFieldsValues?.find(
            (customField) => customField.customFieldId === field.id,
          )

          if (taskField) {
            if (value && value?.length > 0 && value !== 'false')
              updateCustomFieldValueHandler(taskField.id, value)
            else deleteCustomFieldValueHandler(taskField.id)
          } else createCustomFieldValueHandler(field.id as string, value)
        }

        return (
          <div className="task-custom-field-row" key={field.id}>
            <div className="task-custom-field-col">
              {icon} <OverflowTooltip>{field.fieldName}</OverflowTooltip>
            </div>
            <div className="task-custom-field-col">
              <CustomComponent
                {...componentProps}
                saveCustomFieldValue={saveCustomFieldValue}
                customFieldValue={customFieldValue}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CustomFields
