import { ReactComponent as SwitchIcon } from '../../assets/icons/workspace/switchIcon.svg'
import { useTranslation } from 'react-i18next'

const Loader = () => {
  const { t } = useTranslation(['workspaces'])

  return (
    <div className="loader-container">
      <div className="border-animation ">
        <div className="loader-content">
          <div className="content-container">
            <span>{t('Switching workspaces')}</span>
            <SwitchIcon />
            <p>{t('Switch made with Success')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
