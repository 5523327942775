import ViewTitle from '@src/modules/shared/components/ViewTitle'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DailyQuestion } from '../../assets/icons/sidebar/daily-questions.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { Avatar, Tooltip } from 'antd'
import { useAppSelector } from '@src/modules/shared/store'
import { useParams } from 'react-router-dom'
const Header = () => {
  const { t } = useTranslation(['dailyQuestion'])
  const { width } = useWindowSize()
  const { myTeams } = useAppSelector((state) => state.teams)

  const { teamId: selectedTeamId } = useParams()
  const team = myTeams?.find((team) => team?.id === selectedTeamId)

  return (
    <ViewTitle sidebarID="daily-question-sidebar">
      <DailyQuestion />
      <div className="daily-question-header">{t('daily_question_title')}</div>

      {width < 600 && (
        <Avatar.Group
          maxCount={4}
          maxStyle={{ color: '#986AAA', backgroundColor: '#FAEDFF' }}
          className="daily-question-header_users"
        >
          {team?.roles?.map((el) => {
            return (
              <Tooltip title={el?.user?.fullName || el?.user?.email} placement="top">
                {el?.user?.avatar ? (
                  <Avatar src={el?.user?.avatar} />
                ) : (
                  <Avatar style={{ backgroundColor: 'rgb(167 179 186)', cursor: 'pointer' }}>
                    {el?.user?.email[0]}
                  </Avatar>
                )}
              </Tooltip>
            )
          })}
        </Avatar.Group>
      )}
    </ViewTitle>
  )
}

export default Header
