import NotificationAsideCard from '@src/modules/notifications/components/NotificationAsideCard/NotificationAsideCard'
import { handleNotificationType } from '@src/modules/notifications/utils/notificationType'
import InProgressImport from '@src/modules/settings/features/ImportExport/components/InProgressImport/InProgressImport'
import { getOneTask } from '@src/modules/tasks/data/tasksSlice/tasksThunk'
import { notification } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { store, useAppSelector } from '../store/index'
import { ReactComponent as CloseIcon } from './../assets/icons/globalActions/notif-close-icon.svg'
import Logo from './../assets/images/sidebar/logoIcon.svg'
import RTLStyleDecorator from '../components/RTLStyleDecorator'

const NotificationsProvider = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation(['notifications'])

  const { newNotifications, importClickUpProgressValue } = useAppSelector(
    (state) => state.notification,
  )

  const NotificationImport = ({ progress }: { progress: number }) => {
    return (
      <div className="import-notif">
        <InProgressImport importClickUpProgressValue={progress} />
      </div>
    )
  }

  const openNotification = (importClickUpProgressValue: number) =>
    ({
      message: <NotificationImport progress={importClickUpProgressValue} />,
      placement: 'bottomLeft',
      className: 'screen-recording-notif',
      closeIcon: <CloseIcon />,
      key: 'import',
      duration: 0,
    }) as any

  if (importClickUpProgressValue) {
    notification.info(openNotification(importClickUpProgressValue))
  }

  const handlePushNotification = (notifType, taskId) => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
    } else if (document.visibilityState !== 'visible' && Notification.permission === 'granted') {
      const notif = new Notification('TAM App', {
        body: notifType,
        icon: Logo,
        badge: Logo,
      })

      notif.addEventListener('click', async (event) => {
        event.preventDefault()
        try {
          const res = await store.dispatch(getOneTask({ id: taskId }))
          window.location.href = `/spaces/list/${res.payload.payload.fileId}?taskId=${res.payload.payload.id}`
        } catch (error) {
          console.error('Error fetching task information:', error)
        }
      })
    } else {
      console.log('Please grant permission for browser notifications.')
    }
  }

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('notifications granted !')
        } else {
          console.log('You have denied browser notifications.')
        }
      })
    }
  }, [])

  useEffect(() => {
    newNotifications.map((notification) => {
      const notifType = handleNotificationType(
        t,
        notification?.activity ? 'activity' : 'comment',
        notification?.activity,
        notification?.comment,
      )

      handlePushNotification(notifType, notification?.activity?.taskId)
    })
  }, [newNotifications])

  return (
    <>
      {pathname.includes('notifications') || (
        <RTLStyleDecorator>
          <div className="new_notifications_container">
            {newNotifications?.map((notification: INotification | any) => {
              return (
                <RTLStyleDecorator>
                  <NotificationAsideCard notification={notification} />
                </RTLStyleDecorator>
              )
            })}
          </div>
        </RTLStyleDecorator>
      )}
    </>
  )
}

export default NotificationsProvider
