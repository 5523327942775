import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'
import theme from '../../../assets/icons/theme.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

interface Props {
  ThemeColors: string[]
  confirmAvatar: boolean
  selectedTheme: any
  setSelectedTheme: any
  confirmTheme: boolean
  setConfirmTheme: any
}
const WorkspaceTheme: React.FC<Props> = ({
  ThemeColors,
  confirmAvatar,
  selectedTheme,
  setSelectedTheme,
  confirmTheme,
  setConfirmTheme,
}) => {
  const { t } = useTranslation(['workspaces'])
  const { width } = useWindowSize()

  return (
    <>
      <h1>{t('Choose a color theme for your Workspace')} </h1>

      <ColorSelect
        colors={ThemeColors}
        disabled={confirmAvatar ? false : true}
        selectedColor={selectedTheme}
        setSelectedColor={setSelectedTheme}
      />

      {width <= 850 && <img src={theme} />}

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmTheme(!confirmTheme)}
        disabled={confirmAvatar ? false : true}
      />
    </>
  )
}

export default WorkspaceTheme
