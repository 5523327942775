import { Avatar, Select, Spin, Tag } from 'antd'
import React from 'react'
import { ReactComponent as SearchIcon } from '../../assets/icons/teams/search-normal.svg'

import {} from '@src/modules/auth/services/userApi'
import { useAppSelector } from '@src/modules/shared/store/index'
import type { DefaultOptionType, SelectProps } from 'antd/es/select'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions?: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
  fetchOnMount?: boolean
  skip?: number
  setSkip?: React.Dispatch<React.SetStateAction<number>>
  search?: string
  setSearch?: React.Dispatch<React.SetStateAction<string>>
  setSelectedUsers?: any
  selectedUsers?: any
}

const DebounceSelect = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
    avatar?: string
    fullName: string
    email?: string
    skip?: number
    setSkip: React.Dispatch<React.SetStateAction<number>>
    search?: string
    setSearch: React.Dispatch<React.SetStateAction<string>>
    setSelectedUsers: any
    selectedUsers: any
  } = any,
>({
  fetchOptions,
  skip,
  setSkip,
  search,
  setSearch,
  selectedUsers,
  setSelectedUsers,
  debounceTimeout = 800,
  fetchOnMount = false,
  ...props
}: DebounceSelectProps<ValueType>) => {
  const { metadata, loading, users } = useAppSelector((state) => state.users)
  const tagRender = ({ closable, onClose }) => {
    return <Tag closable={closable} onClose={onClose} style={{ display: 'none' }} />
  }
  const handlePopupScroll = (event) => {
    const { target } = event
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (skip < metadata?.last_page) {
        setSkip && setSkip((old: number) => old + 1)
      }
    }
  }
  function truncateString(inputString: string, maxLength: number): string {
    if (inputString.length > maxLength) {
      return inputString.substring(0, maxLength) + '...'
    } else {
      return inputString
    }
  }
  return (
    <div className="custom-user-select">
      <Select
        labelInValue
        loading={loading}
        defaultValue={'Add members by name or email'}
        className="custom-user-select-content"
        filterOption={false}
        onSearch={(newValue: string) => {
          setSkip && setSkip(1)
          setSearch && setSearch(newValue)
        }}
        dropdownClassName="custom-user-select-dropDown"
        onChange={(newValue: any) => {
          let serializedArray = selectedUsers
          const oldValues: any = newValue
          const elementsToAdd = oldValues.filter(
            (el1) => !serializedArray.some((el2) => el1.value === el2.value),
          )
          const updatedArr2 = [...serializedArray, ...elementsToAdd]
          const elementsToRemove = serializedArray.filter(
            (el2) => !oldValues?.some((el1) => el1.value === el2.value),
          )
          serializedArray = updatedArr2.filter(
            (el2) => !elementsToRemove.some((elToRemove) => el2.value === elToRemove.value),
          )
          setSelectedUsers(serializedArray)
        }}
        searchValue={search}
        notFoundContent={
          loading ? (
            <div className="teams-spinner">
              <Spin size="small" />
            </div>
          ) : null
        }
        options={
          users?.map((option) => ({
            label: (
              <div className="option-container">
                {option.avatar ? (
                  <Avatar src={option?.avatar} style={{ marginRight: 8 }} />
                ) : (
                  <Avatar
                    style={{
                      marginRight: 8,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {typeof option?.firstName === 'string'
                      ? option?.firstName?.charAt(0).toUpperCase()
                      : option?.email?.charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
                <span
                  className="option-container-first-name"
                  style={{
                    color: 'var(--on-surface)',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {truncateString(option?.firstName || '', 10)} {option?.firstName && <div>.</div>}
                </span>
                <span
                  style={{
                    color: 'var(--on-surface)',
                    fontSize: '13px',
                    fontWeight: '400',
                    paddingLeft: '5px',
                  }}
                >
                  {option?.email?.split('@')[0]}@
                </span>

                <span
                  style={{
                    color: '#8DA5B8',
                    fontSize: '13px',
                    fontWeight: '400',
                  }}
                >
                  {option?.email?.split('@')[1]}{' '}
                </span>
              </div>
            ),
            value: option?.id,
            title: {
              avatar: option?.avatar,
              id: option?.id,
              fullName: option?.fullName || option?.email,
            },
          })) as unknown as DefaultOptionType[]
        }
        tagRender={tagRender}
        {...props}
        suffixIcon={<SearchIcon />}
        onPopupScroll={handlePopupScroll}
      />
    </div>
  )
}

export default DebounceSelect
