import { OneAnswerDashboard } from '@src/modules/dailyQuestions/components/MemberView/components/AnswersList/OneAnswer'
import { ICurrentQuestion } from '@src/modules/dailyQuestions/components/MemberView/memberView.interface'
import { useGetAnswersQuery } from '@src/modules/dailyQuestions/services/memberAnswerApi'
import Spinner from '@src/modules/spaces/components/Spinner'
import { useEffect, useState } from 'react'

interface IAnswers {
  team: any
  endDateQuery: string
  dateQuery: string
  question: ICurrentQuestion
  item: ICurrentQuestion
}

export default function Answers({ team, endDateQuery, dateQuery, question, item }: IAnswers) {
  const [res, setRes] = useState({})
  const addToState = (key, value) => {
    setRes((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  const getFromState = (key) => {
    return res[key]
  }
  const { data, isLoading } = useGetAnswersQuery(
    {
      teamId: team?.id,
      startDate: endDateQuery,
      endDate: dateQuery,
      questionId: question?.id,
    },
    {
      skip: !team?.id || !question?.id,
    },
  )
  useEffect(() => {
    addToState(generateKey(question, dateQuery), data)
  }, [data])
  return (
    <div className="followup-dashboard-question_answers">
      {isLoading ? (
        <Spinner />
      ) : (
        question?.id == item?.id &&
        getFromState(generateKey(question, dateQuery))?.payload?.map((el) => {
          return <OneAnswerDashboard answer={el} />
        })
      )}
    </div>
  )
}

const generateKey = (question, dateQuery) => {
  return `${question?.id}-${dateQuery}`
}
