import ColorPicker from '../ColorPicker'
import React from 'react'
import Input from '../Input/Input'

interface ColorSelectProps {
  title?: string
  colors: string[]
  selectedColor: string | null
  setSelectedColor: (color: string | null) => void
  disabled?: boolean
}

const ColorSelect: React.FC<ColorSelectProps> = ({
  title,
  colors,
  disabled,
  selectedColor,
  setSelectedColor,
}) => {
  const handleSelectColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value
    setSelectedColor(color === selectedColor ? null : color)
  }
  const handlePickColor = (color: string) => {
    setSelectedColor(color === selectedColor ? null : color)
  }

  return (
    <div>
      {title ? <h2 className="color-select-title">{title}</h2> : null}

      <div className="colors-container">
        {colors.map((color, index) => {
          const isSelected = selectedColor === color
          return (
            <label
              key={index}
              style={{
                backgroundColor: color,
                boxShadow: isSelected ? `0 0 13px ${color}` : 'none',
              }}
              className={`color-select `}
            >
              <Input
                type="radio"
                name="colorSelect"
                value={color}
                checked={isSelected}
                disabled={disabled}
                onChange={handleSelectColor}
                variant="label-inline"
              />
              <div className="color-button" />
            </label>
          )
        })}

        <ColorPicker onPick={(color) => handlePickColor(color)} />
      </div>
    </div>
  )
}

export default React.memo(ColorSelect)
