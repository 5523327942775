/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  getOneTemplate,
  getStatus,
  getTemplates,
  createStatus,
  createStatuses,
} from './statusThunk'
import { IStatus } from '@softylines/tam-types'

export interface statusType extends IStatus {
  id: string
}

export interface templateStatusType {
  [key: string]: statusType[]
}

export interface templateType {
  id: string
  name: string
  status: templateStatusType | statusType[]
}

export interface StatusType {
  templates: { payload: templateType[]; metadata: any } | null
  template: templateType | null
  statuses: statusType[] | null
  error: any
  status: string
}

const initialState: StatusType = {
  templates: null,
  template: null,
  statuses: null,
  error: null,
  status: 'idle',
}

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    updateTemplates(state, actions: PayloadAction<templateStatusType>) {
      const newStatus = actions.payload
      if (state.template) state.template = { ...state.template, status: newStatus }
    },
    resetTemplate(state) {
      state.template = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(
      getTemplates.fulfilled,
      (state, action: PayloadAction<StatusType['templates']>) => {
        state.templates = action.payload
        state.status = 'idle'
      },
    )
    builder.addCase(getTemplates.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getOneTemplate.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(
      getOneTemplate.fulfilled,
      (state, action: PayloadAction<{ payload: templateType }>) => {
        state.template = action.payload.payload
        state.status = 'idle'
      },
    )
    builder.addCase(getOneTemplate.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getStatus.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getStatus.fulfilled, (state, _: PayloadAction<{ payload: templateType }>) => {
      state.status = 'idle'
    })
    builder.addCase(getStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(createStatus.pending, (state) => {
      state.error = null
      // state.status = 'loading'
    })
    builder.addCase(createStatus.fulfilled, (state, _action: PayloadAction<any>) => {
      state.error = null
      state.status = 'idle'
    })
    builder.addCase(createStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(createStatuses.pending, (state) => {
      state.error = null
    })
    builder.addCase(createStatuses.fulfilled, (state, _action: PayloadAction<any>) => {
      state.error = null
      state.status = 'idle'
    })
    builder.addCase(createStatuses.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const { updateTemplates, resetTemplate } = statusSlice.actions

export default statusSlice.reducer
