import DetailsForm, { spaceTypes } from '../../SpaceForms/DetailsForm'
import { Fragment, useEffect, useState } from 'react'

import BasicForm from '../../SpaceForms/BasicForm'
import { Modal } from 'antd'
import SpaceReview from '../../SpaceForms/SpaceReview'
import { resetTemplate } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { useAppDispatch } from '@src/modules/shared/store'
import { useTranslation } from 'react-i18next'

export interface SpaceStepProps {
  data: any
  setPreviousStep: () => void
  setNextStep: () => void
  setData: (data: any) => void
  handleClose: () => void
}

interface stepsType {
  [key: number]: (obj: SpaceStepProps) => JSX.Element
}

const isTheLast = (step: number): boolean => {
  return step === 2
}

const createSpaceSteps: stepsType = {
  0: BasicForm,
  1: DetailsForm,
  2: SpaceReview,
}

const SpaceModal = ({ open, handleClose, data, id }: any) => {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState<number>(data?.activeStep || 0)
  const [creationData, setCreationData] = useState<any>({ sharing: spaceTypes[1] })
  const nextStepHandler = () => setActiveStep(activeStep + 1)
  const previousStepHandler = () => setActiveStep(activeStep - 1)
  const StepForm = createSpaceSteps[activeStep] || Fragment
  const { t } = useTranslation(['modals'])
  useEffect(() => {
    setCreationData({ sharing: spaceTypes[1], ...data })
    setActiveStep(data?.activeStep || 0)
    dispatch(resetTemplate())
  }, [open])

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">
          {data?.modalTitle
            ? t(data.modalTitle)
            : isTheLast(activeStep)
              ? t('space.review')
              : t('space.create')}
        </p>
      </div>

      <div className="">
        <StepForm
          handleClose={() => handleClose(id)}
          data={creationData}
          setData={setCreationData}
          setNextStep={nextStepHandler}
          setPreviousStep={previousStepHandler}
        />
      </div>
    </Modal>
  )
}

export default SpaceModal
