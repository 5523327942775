import * as Yup from 'yup'

import { Modal, message } from 'antd'
import {
  useCreateQuickAnswerMutation,
  useGetQuickAnswersQuery,
  useUpdateQuickAnswerMutation,
} from '@src/modules/dailyQuestions/services/quickAnswerApi'

import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'

import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useAppDispatch } from '@src/modules/shared/store'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextArea } from '@src/modules/shared/components/Input'

export default function ManageQuickAnswerModal({ open, handleClose, data, id }) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dailyQuestion'])
  const { t: mt } = useTranslation(['modals'])
  const [loading, setLoading] = useState(false)
  const [createQuickAnswer] = useCreateQuickAnswerMutation()
  const [udpateQuickAnswer] = useUpdateQuickAnswerMutation()
  const initialValues = data?.isUpdate
    ? {
        name: data?.data?.name,
        description: data?.data?.description,
      }
    : {
        name: '',
        description: '',
      }
  const { refetch } = useGetQuickAnswersQuery({
    limit: 10,
    page: 1,
  })
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().max(50).required(),
      description: Yup.string().trim().required(),
    }),
    onSubmit: (values) => {
      setLoading(true)

      if (!data?.isUpdate) {
        createQuickAnswer(values)
          .unwrap()
          .then(() => {
            message.success(t('quick_answer.created'))
            setLoading(false)
            dispatch(closeModal({ id: 'quick-answer-modal' }))
            refetch()
          })
      } else {
        udpateQuickAnswer({
          id: data?.data?.id,
          ...values,
        })
          .unwrap()
          .then(() => {
            message.success(t('quick_answer.updated'))
            setLoading(false)
            dispatch(closeModal({ id: 'quick-answer-modal' }))
            refetch()
          })
      }
    },
  })

  return (
    <Modal
      footer={false}
      centered
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => {
        handleClose(id)
      }}
      wrapClassName="custom-modal quick-answer-modal"
      closable={false}
    >
      <div className="manage-quickAnswer">
        <p className="manage-quickAnswer_title">
          {data?.isUpdate ? t('quick_answer.update') : t('quick_answer.create')}
        </p>
        <form className="register-card-container" onSubmit={formik.handleSubmit}></form>
        <div className="manage-quickAnswer-form">
          <Input
            type={'text'}
            name="name"
            disabled={false}
            formik={formik}
            variant="filled"
            placeholder={t('quick_answer.name_placeholder')}
            label={t('quick_answer.name')}
            required={true}
            className="quickAnswer-name-input"
            status={formik.errors.name ? 'error' : 'success'}
          />
          <TextArea
            name="description"
            label="Description"
            disabled={false}
            formik={formik}
            variant="filled"
            size="md"
            placeholder={t('quick_answer.description_placeholder')}
            required={true}
            status={formik.errors.description ? 'error' : 'success'}
          />
        </div>

        <div className="form-actions flex-end">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              formik.setValues(initialValues)
              dispatch(closeModal({ id: 'quick-answer-modal' }))
            }}
          >
            {mt('invite_people.cancel')}
          </Button>

          {data?.isUpdate ? (
            <Button
              htmlType="submit"
              className="confirm-action"
              onClick={() => formik.handleSubmit()}
            >
              {loading ? mt('invite_people.loading') : t('quick_answer.update')}
            </Button>
          ) : (
            <Button
              htmlType="submit"
              className="confirm-action"
              onClick={() => formik.handleSubmit()}
            >
              {loading ? mt('invite_people.loading') : t('quick_answer.create')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
