import { api } from '@src/modules/shared/services/api'
import { TagTypes } from '../data/tasksSlice/tasksTypes'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'

interface getTagsPayload {
  tags?: string[] | TagTypes[]
  skip?: number
  search?: string
  limit?: number
  spaceId?: string
  options?: any
}

const defaultLimit = 3

export const TagsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<any, getTagsPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/tags`,
          params,
        }
      },
      transformResponse: (response: { payload: TagTypes }): TagTypes => {
        return response.payload
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs.arg.options
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }
        currentCache.metadata = newItems.metadata

        currentCache.payload =
          shouldCache || otherArgs.arg.skip > 1
            ? removeDuplicatesById(currentCache?.payload)
            : newItems?.payload
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      providesTags: (result) => {
        return result
          ? [
              result?.payload?.map(({ id }: { id: string }) => ({ type: 'TAGS', id }) as const),
              { type: 'TAGS', id: 'LIST' },
            ]
          : []
      },
    }),

    getTag: build.query<any, Partial<{ id: string }>>({
      query: (params) => ({
        url: `api/tags/${params.id}`,
      }),
      transformResponse: (response: { payload: TagTypes }): TagTypes => {
        return response.payload
      },
    }),

    addTag: build.mutation<TagTypes, TagTypes & { name: string; color: string; spaceId: string }>({
      query: (body) => ({
        url: `api/tags`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TAGS', id: 'LIST' }] as any,
    }),

    updateTag: build.mutation<
      any,
      Partial<
        TagTypes & {
          tagsId: string[]
        }
      >
    >({
      query(data) {
        const { id, ...body } = data
        return {
          url: `api/tags/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (task) => [{ type: 'TAGS', id: task?.id }] as any,
    }),

    bulkUpdateTags: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/bulkupdate`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
    deleteTag: build.mutation<any, Partial<{ id: string }>>({
      query(data) {
        const { id } = data
        return {
          url: `api/tags/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (task) => [{ type: 'TAGS', id: task?.id }] as any,
    }),
    uploadTagAttachements: build.mutation<any, Partial<{ files: File[] }>>({
      query(data) {
        return {
          url: `api/upload`,
          method: 'POST',
          body: objectToFormData(data),
          formData: true,
        }
      },
    }),
  }),
})

export const updateTag = (task: any, query: getTagsPayload) => {
  return TagsApi.util.updateQueryData('getTags', query, (draftTags) => {
    return {
      new: task,
      ...draftTags,
    }
  })
}

export const {
  useGetTagsQuery,
  useAddTagMutation,
  useUpdateTagMutation,
  useBulkUpdateTagsMutation,
  useUploadTagAttachementsMutation,
  useDeleteTagMutation,
  useGetTagQuery,
} = TagsApi

export function objectToFormData(data) {
  const formData = new FormData()

  // Append files to FormData
  data.files.forEach((file: File) => {
    formData.append(`files`, file, file.name)
  })

  // Append other form fields
  // formData.append('taskId', data.taskId)

  return formData
}
