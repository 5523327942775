import deepClone from 'deep-clone'
import { insert } from '../../Board/Board'
import { store } from '@src/modules/shared/store'
import { getTasks } from '@src/modules/tasks/data/tasksSlice/tasksThunk'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { convertTasksToTemplateTree, removeDuplicate } from './listViewManagment'

export const manageSocket = {
  updateEvent(newObj, setData, setCount, groupBy, updates) {
    //TODO: fix task count after update
    setData((prev) => {
      let newCounts = {}
      let treeClone = deepClone(prev) || {}
      let { id, parentId, order, users = [], teams = [] } = newObj
      const mergedUsers = [...users, ...teams]

      const treeId =
        parentId ||
        (groupBy?.type === GroupByFields.ASSIGNEE
          ? newObj[groupBy?.type]
              ?.map(({ id }) => id)
              .reverse()
              .join('&') || 'not_assigned_users'
          : groupBy?.type === GroupByFields.STATUS
            ? newObj[groupBy?.type]?.id
            : groupBy?.type === GroupByFields.PRIORITY
              ? newObj[groupBy?.type]
              : groupBy?.custom
                ? newObj?.customFieldsValues?.find((field) => field.customFieldId === groupBy?.id)
                    ?.value || 'empty'
                : null)

      if (updates?.taskUUID)
        order = updates?.taskBelowUUID
          ? treeClone[treeId]?.children
              ?.filter((taskId) => taskId !== updates.taskUUID)
              ?.findIndex((taskId) => taskId === updates?.taskBelowUUID) + 1
          : 0
      else order = treeClone[treeId]?.children?.findIndex((taskId) => taskId === id)

      const { parentId: oldParentId, status: oldStatus } =
        (treeClone && treeClone[id] && treeClone[id]?.content) || {}
      const { id: oldStatusId } = oldStatus || {}
      if (treeClone) {
        Object.keys(treeClone || {})?.forEach((key) => {
          if (treeClone[key]?.children) {
            const taskIdIndex = treeClone[key]?.children?.findIndex((taskId) => taskId === id)
            if (
              taskIdIndex !== -1 &&
              (oldParentId !== parentId || (oldStatusId !== treeId && !parentId))
            ) {
              const isRoot = treeClone[key]?.isRoot
              const totalSubtasks = treeClone[key]?.content?.totalSubtask
              treeClone[key].children = treeClone[key].children.filter((taskId) => taskId !== id)

              if (isRoot) {
                newCounts[key] = (newCounts[key] || 0) - 1
              } else if (totalSubtasks > 0)
                treeClone[key].content = {
                  ...treeClone[key].content,
                  totalSubtask: totalSubtasks - 1,
                }
            }
          }
        })
      }

      if (treeClone[id])
        treeClone = {
          ...treeClone,
          ...convertTasksToTemplateTree(
            [{ ...newObj, users: mergedUsers }],
            (taskId) => prev[taskId]?.children,
            groupBy?.type,
          ),
        }

      if (parentId && treeClone[parentId]) {
        //insert
        const oldChildrens = treeClone[parentId].children?.filter((taskId) => taskId !== id) || []
        treeClone[parentId] = {
          ...treeClone[parentId],
          children: insert(oldChildrens, order, id),
        }
      } else {
        const oldChildrens = treeClone[treeId]?.children?.filter((taskId) => taskId !== id) || []
        treeClone[treeId] = {
          ...treeClone[treeId],
          children: removeDuplicate(insert(oldChildrens, order, id)),
        }
      }

      if (parentId && treeClone[parentId] && parentId !== oldParentId) {
        const oldChildrens = treeClone[parentId]?.children || []

        treeClone[parentId] = {
          ...treeClone[parentId],
          children: removeDuplicate(insert(oldChildrens, order, id)),
        }
        treeClone[parentId].content = {
          ...treeClone[parentId].content,
          totalSubtask: treeClone[parentId]?.content?.totalSubtask + 1,
        }
      }

      if (treeId && !parentId && treeClone[treeId]) {
        const oldChildrens = treeClone[treeId]?.children || []
        treeClone[treeId] = {
          ...treeClone[treeId],
          children: removeDuplicate(insert(oldChildrens, order, id)),
        }
        if (!oldChildrens.includes(id)) newCounts[treeId] = (newCounts[treeId] || 0) + 1
      }

      setCount((prev) => {
        const countClone = deepClone(prev)
        // Object.keys(countClone).forEach((id) =>
        //   newCounts[id]
        //     ? (countClone[id].total_items = countClone[id].total_items + newCounts[id])
        //     : null,
        // )
        return countClone
      })

      return treeClone
    })
  },

  deleteEvent(dataToDelete: string[], setCount, prev, groupBy) {
    //set new data tree

    let newCounts = {}
    let treeClone = deepClone(prev)
    dataToDelete.forEach((id) => {
      const task = treeClone?.[id]

      if (task) {
        let parentTaskId = task?.parentId

        if (groupBy === GroupByFields.ASSIGNEE) {
          parentTaskId =
            task?.content[groupBy]
              ?.map(({ id }) => id)
              .reverse()
              .join('&') || 'not_assigned_users'
        } else if (groupBy === GroupByFields.PRIORITY) {
          parentTaskId = task?.content?.priority || 'null'
        }

        if (treeClone[parentTaskId]?.children) {
          treeClone[parentTaskId].children = treeClone[parentTaskId].children.filter(
            (childId) => childId !== id,
          )
          const totalsubtasks = treeClone[parentTaskId].content?.totalSubtask
          if (treeClone[parentTaskId]?.isRoot) {
            newCounts[parentTaskId] = (newCounts[parentTaskId] || 0) + 1
          } else if (totalsubtasks > 0)
            treeClone[parentTaskId].content = {
              ...treeClone[parentTaskId].content,
              totalSubtask: totalsubtasks - 1,
            }
        }

        delete treeClone[id]
      }
    })
    // set new count
    setCount((prev) => {
      const countClone = deepClone(prev)
      Object.keys(countClone).forEach((id) =>
        newCounts[id]
          ? (countClone[id].total_items = countClone[id].total_items - newCounts[id])
          : null,
      )
      return countClone
    })

    return treeClone
  },

  updateManyEvent(dataToUpdate, setData, setCount, groupBy) {
    const { ids, listId } = dataToUpdate || {}

    store
      .dispatch(
        getTasks({
          fileId: listId,
          options: `{"where":{"id":{"operation":"$in","value":${JSON.stringify(ids)}}}}`,
        }),
      )
      .unwrap()
      .then((res) => {
        const tasks = res?.payload?.payload
        tasks.forEach((task) => manageSocket.updateEvent(task, setData, setCount, groupBy, null))
      })
  },
}
