import {
  PlateElement,
  // PlateElementProps,
  createPluginFactory,
  // removeNodes,
} from '@udecode/plate-common'
import { Dropdown } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../../../../../shared/assets/icons/blockNote/arrow.svg'
import { useGetDocumentQuery } from '@src/modules/spaces/services/documentApi'

export const ELEMENT_DOC_MENTION = 'mention_doc_tam'

const createMentionDocPlugin = createPluginFactory({
  key: ELEMENT_DOC_MENTION,
  isElement: true,
  isInline: true,
  isMarkableVoid: true,
  isVoid: true,
})

const renderDocumentLocation = (document) => {
  const { space, folder } = document || {}
  return (
    <>
      {space && (
        <div className="mention-doc__path">
          <div className="mention-doc__space">
            {(space?.name && space.name[0]) || 'E'}
            <span>
              {space?.name?.length > 10 ? space?.name?.slice(0, 10) + '...' : space?.name}
            </span>
          </div>
          /
          <div className="mention-doc__folder">
            <span>
              {document?.name?.length > 10 ? document?.name?.slice(0, 10) + '...' : document?.name}
            </span>
          </div>
        </div>
      )}
      {folder && folder?.id && (
        <div className="mention-doc__path">
          <div className="mention-doc__space">
            {(folder?.space?.name && folder?.space?.name[0]) || 'E'}
            <span>
              {folder?.space?.name?.length > 5
                ? folder?.space?.name.slice(0, 5) + '...'
                : folder?.space?.name}
            </span>
          </div>
          /
          <div className="mention-doc__folder">
            {(folder.name && folder.name[0]) || 'E'}
            <span>{folder.name?.length > 5 ? folder.name.slice(0, 5) + '...' : folder.name}</span>
          </div>
          /
          <div className="mention-doc__folder">
            <span>
              {document.name?.length > 5 ? document.name.slice(0, 5) + '...' : document.name}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default createMentionDocPlugin

export function MentionDocElement({ children, ...props }: any) {
  const navigate = useNavigate()
  const { element } = props
  const { data } = useGetDocumentQuery(element?.children[0]?.id)
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const handelNavigateTO = (id) => {
    navigate(`/spaces/document/${id}`)
    // window.location.href = `${location.origin}/spaces/document/${id}`
  }
  return (
    <PlateElement
      {...props}
      onClick={(e) => e.stopPropagation()}
      contentEditable={false}
      className="mention-compoent-item"
    >
      <div className="mention-doc__component-item">
        <Dropdown
          placement="bottomLeft"
          open={dropdownVisible}
          onOpenChange={() => setDropdownVisible(!dropdownVisible)}
          overlay={
            <div className="mention-doc__dropdown-menu">
              {data?.cover ? (
                <span
                  className="mention-doc__cover"
                  style={{
                    backgroundImage: `url(${data?.cover})`,
                    backgroundPositionY: `-${data?.cover_y}px`,
                    backgroundPositionX: `${data?.cover_x}px`,
                  }}
                />
              ) : (
                <span className="mention-doc__nocover">NO COVER</span>
              )}

              <span className="mention-doc__icon">{data?.icon}</span>
              <div className="mention-doc__footer">
                <span className="mention-doc__name">{data?.name}</span>
                <span className="mention-doc__location">{renderDocumentLocation(data)}</span>
              </div>
            </div>
          }
          trigger={['hover']}
        >
          <div
            className={
              dropdownVisible
                ? 'mention-doc__content mention-doc__content--active'
                : 'mention-doc__content'
            }
            onClick={() => handelNavigateTO(element?.children[0]?.id)}
          >
            <span className="mention-doc__icon">{data?.icon}</span>
            <span className="mention-doc__name">{data?.name}</span>
            <ArrowIcon className="mention-doc__arrow-icon" />
          </div>
        </Dropdown>

        <p className="mention-doc__children">{children}</p>
      </div>
    </PlateElement>
  )
}
