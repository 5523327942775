/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTeams, getTeamById, createTeam, updateTeam, getMyTeams } from './TeamsThunk'

const initialState = {
  status: 'idle',

  error: null,
  teams: [],
  myTeams: null,
  team: null,
  metadata: null,
}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTeams.fulfilled, (state, action: PayloadAction<any>) => {
      state.teams = action.payload.payload.payload
      state.metadata = action.payload.payload.metadata
      state.status = 'succeeded'
    })
    builder.addCase(getTeams.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getMyTeams.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getMyTeams.fulfilled, (state, action: PayloadAction<any>) => {
      state.myTeams = action.payload.payload.payload
      state.metadata = action.payload.payload.metadata
      state.status = 'succeeded'
    })
    builder.addCase(getMyTeams.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(createTeam.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(createTeam.fulfilled, (state, action: PayloadAction<any>) => {
      state.teams = [...state.teams, action.payload.payload]
      state.status = 'succeeded'
    })
    builder.addCase(createTeam.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getTeamById.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTeamById.fulfilled, (state, action: PayloadAction<any>) => {
      state.team = action.payload.payload
      state.status = 'succeeded'
    })
    builder.addCase(getTeamById.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(updateTeam.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(updateTeam.fulfilled, (state, action: PayloadAction<any>) => {
      state.teams = state.teams.map((team) =>
        action.payload.payload.id === team.id ? action.payload.payload : team,
      )

      state.status = 'succeeded'
    })
    builder.addCase(updateTeam.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {} = teamsSlice.actions // eslint-disable-line

export default teamsSlice.reducer
