import { Navigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../store'

interface WorkspaceGuardProps {
  children: React.ReactNode
}

const WorkspaceGuard: React.FC<WorkspaceGuardProps> = ({ children }) => {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth)
  const currentWorkspace = localStorage.getItem('currentWorkspace')
  const [searchParams] = useSearchParams()
  const redirectUrl: string | null = searchParams.get('redirect')

  if (
    (user?.workspaces?.length === 0 && isAuthenticated) ||
    (currentWorkspace !== 'undefined' && currentWorkspace !== null)
  ) {
    return <>{children}</>
  } else if (redirectUrl) {
    return <Navigate to={redirectUrl} />
  } else {
    return <Navigate to="/spaces" />
  }
}

export default WorkspaceGuard
