import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import { Dropdown } from 'antd'
import { isDarkColor } from '@src/modules/shared/utils/isDarkColor'
import { useState } from 'react'

const DropdownField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const { typeConfig } = field
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const option = typeConfig?.options

  const value = field.custom ? customFieldValue : task[field.value]

  const selectedOption = option?.find((opt) => opt.value === value)

  return (
    <div className="list-dropdown-field" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        placement="bottomCenter"
        open={isDropdownVisible}
        onOpenChange={(visible) => setIsDropdownVisible(visible)}
        dropdownRender={() => (
          <div className="dropdown-field-dropdown ant-dropdown-menu">
            <p>Select an option</p>
            <div className="dropdown-field-options">
              {option?.length ? (
                option?.map((opt, i) => (
                  <div
                    key={i}
                    className="dropdown-field-option"
                    style={{
                      backgroundColor: opt.color,
                    }}
                    onClick={() => {
                      saveCustomFieldValue(opt.value)
                      setIsDropdownVisible(false)
                    }}
                  >
                    <OverflowTooltip style={{ color: isDarkColor(opt.color) ? '#fff' : '#000' }}>
                      {opt.value}
                    </OverflowTooltip>
                  </div>
                ))
              ) : (
                <div>No options found</div>
              )}
            </div>
          </div>
        )}
        trigger={['click']}
      >
        {selectedOption ? (
          <div
            className="dropdown-field-value"
            style={{
              backgroundColor: selectedOption?.color,
              color: isDarkColor(selectedOption?.color) ? '#fff' : '#000',
            }}
          >
            {selectedOption?.value}
          </div>
        ) : (
          <div className="dropdown-field-empty-value">-</div>
        )}
      </Dropdown>
    </div>
  )
}

export default DropdownField
