import { ReactComponent as Calendar } from '../../assets/cryCalendar.svg'
import { useTranslation } from 'react-i18next'

export default function InvalidDay({
  getCurrentTime,
  startTime,
}: {
  getCurrentTime: () => string
  startTime: string
}) {
  const { t } = useTranslation(['dailyQuestion'])

  return (
    <div className="invalid-day">
      {startTime > getCurrentTime() ? (
        <>
          <Calendar />
          <p className="invalid-day_description">{t('answers_tab.invalid_time')}</p>
        </>
      ) : (
        <>
          <Calendar />
          <p className="invalid-day_description">{t('answers_tab.invalid_date')}</p>
        </>
      )}
    </div>
  )
}
