/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearTrash, getTrash } from './TrashThunk'
import { ITrash } from '@softylines/tam-types'

export interface TrashType extends Omit<ITrash, 'type'> {
  type: 'document' | 'list' | 'folder' | 'space' | 'task'
}

export type trashType = {
  status: string
  error: null
  trashList: TrashType[]
  metadata: any
  restoredElement: string

  isCleared: boolean
}

const initialState: trashType = {
  status: 'idle',
  error: null,
  trashList: [],
  metadata: null,

  restoredElement: '',
  isCleared: false,
}

const trashSlice = createSlice({
  name: 'trash',
  initialState,
  reducers: {
    getRestoredElement: (state, { payload }) => {
      state.restoredElement = payload
    },

    informClearTrash: (state, { payload }) => {
      state.isCleared = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTrash.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTrash.fulfilled, (state, action: PayloadAction<any>) => {
      state.trashList = action?.payload?.payload?.payload
      state.metadata = action?.payload?.payload?.metadata
      state.status = 'succeeded'
    })
    builder.addCase(getTrash.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(clearTrash.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(clearTrash.fulfilled, (state) => {
      state.status = 'succeeded'
    })
    builder.addCase(clearTrash.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const { getRestoredElement, informClearTrash } = trashSlice.actions // eslint-disable-line

export default trashSlice.reducer
