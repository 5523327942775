import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { formatDate, formatShortDate } from '../../utils/formatDate'
import TaskPriority from '@src/modules/tasks/components/TaskPriority'
import { useNavigate } from 'react-router-dom'
import { openTaskDetails } from '@src/modules/notifications/components/NotificationAsideCard/NotificationAsideCard'
import { useTranslation } from 'react-i18next'
import { replaceAMPMWithArabic } from '@src/modules/shared/utils/replaceAMPMWithArabic'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'

interface TaskItem {
  task: TaskType
}

const TaskItem = ({ task }) => {
  const { t, i18n } = useTranslation(['dashboard'])
  const createdAt = formatDate(task.createdAt as string)
  const navigate = useNavigate()

  return (
    <div className="task-item" onClick={() => openTaskDetails(task?.id, navigate)}>
      <RTLStyleDecorator disableRTLAdjustments>
        <div className="task-item-date" style={{ borderColor: task?.status?.color }}>
          {t('myTasks.created_at')} {createdAt[0]} <br />
          {replaceAMPMWithArabic(createdAt[1], i18n.language)}
        </div>
      </RTLStyleDecorator>

      <RTLStyleDecorator>
        <div className="task-item-title">
          <p>{task.name}</p>
          <p>
            {task.path?.map((item: { name: string }, index: number) => {
              return (
                <span key={index + item.name}>
                  {item?.name}
                  {index !== (task?.path as string[]).length - 1 ? ' > ' : ''}
                </span>
              )
            })}
          </p>
        </div>
      </RTLStyleDecorator>

      <div className="task-item-right">
        <p className="task-item-due-date">
          {task.dueDate ? formatShortDate(task.dueDate as string, i18n.language) : null}
        </p>
        <TaskPriority showLabel={false} disable activePriorityCode={task.priority} />
      </div>
    </div>
  )
}

export default TaskItem
