// Tag.tsx

import Input from '@src/modules/shared/components/Input/Input'
import React from 'react'

interface TagProps {
  label: string
  checked: boolean
  onChange: () => void
  disabled: boolean
}

const CustomCheckBox: React.FC<TagProps> = ({ label, checked, onChange, disabled }) => {
  return (
    <label className={`tag ${checked ? 'selected' : ''}`}>
      <Input
        type="checkbox"
        hidden={true}
        checked={checked}
        onChange={onChange}
        className="hidden-checkbox"
        disabled={disabled}
      />
      {label}
    </label>
  )
}

export default CustomCheckBox
