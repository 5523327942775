// FooterSection.tsx

import { previewAttachmentContent } from '@src/modules/tasks/components/EditTaskPopup/components/Attachements/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomTimeInput from '../../../CustomTimeInput/CustomTimeInput'
import { ReactComponent as Attachement } from '../../assets/attachement.svg'
import { ReactComponent as Coll } from '../../assets/collap.svg'
import { ReactComponent as Download } from '../../assets/download.svg'
import AnswerAttachement from '../QestionBlocsList/AnswerAttachement'
import { Attachment } from '../QestionBlocsList/attachement.interface'
import { Body } from './answersList.inteface'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
interface Props {
  body: Body
  index: number
  disabled?: boolean
  attachments: Attachment[]
}

export const FooterSection: React.FC<Props> = ({ body, index, disabled, attachments }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { t } = useTranslation(['dailyQuestion'])
  const navigate = useNavigate()
  const attachmentsByAnswer = attachments.filter((att) => body?.attachmentIds?.includes(att?.id))

  return (
    <>
      <div className={`bloc-footer ${disabled ? 'bloc-footer_row' : ''}`}>
        <div className={`bloc-footer_right ${disabled ? 'hidden' : ''}`}>
          <CustomTimeInput
            disabled={true}
            updateEditorTime={() => {}}
            value={body?.time}
            id={index}
          />
        </div>
        <div className="bloc-footer_left">
          <Attachement cursor={'pointer'} />
          {attachmentsByAnswer?.length > 0 && (
            <div className="bloc-footer_left-files">
              <p>
                {attachmentsByAnswer.length} {t('answers_tab.attached_files')}
              </p>
              <Coll
                cursor={'pointer'}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  rotate: collapsed ? '180deg' : '0deg',
                }}
              />
            </div>
          )}
        </div>
      </div>
      {collapsed && (
        <div className="bloc-footer_attachements">
          {attachmentsByAnswer?.map((file: Attachment, index: number) => (
            <div
              key={index}
              className="bloc-footer_attachement"
              onClick={() => previewAttachmentContent(file, navigate)}
            >
              <div className="bloc-footer_attachement-info">
                <span>
                  <Tooltip title={file.originalname} placement="topLeft">
                    {file.originalname}
                  </Tooltip>
                </span>
                <a href={file.location} download target="_blank">
                  <Download />
                </a>
              </div>
              {file.mimetype.startsWith('image/') && !file.mimetype.startsWith('image/svg') ? (
                <img src={file.location} alt="error" />
              ) : (
                <AnswerAttachement />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default FooterSection
