export function removeFirstDuplicateById(ids) {
  const seenIds = new Set()
  const result = []

  for (const id of ids) {
    if (!seenIds.has(id)) {
      seenIds.add(id)
      result.push(id)
    }
  }

  return result
}
