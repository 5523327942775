// Object contain all the functions that manage the socket updated for the task in board view
import { store } from '@src/modules/shared/store'
import { insert } from '../components/Board/Board'
import { TaskType } from '../data/tasksSlice/tasksTypes'
import { GroupByFields } from '../features/Tasks/Tasks'
import deepClone from 'deep-clone'
import { getTasks } from '../data/tasksSlice/tasksThunk'

const removeDuplicate = (arr) => arr.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)

export const socketTasksManagments = {
  deleteTask: (obj, idsToDelete: string[], setCount?: (count) => void) => {
    idsToDelete.forEach((idToDelete) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const array = obj[key]
          obj[key] = array?.filter((obj) => obj.id !== idToDelete)
          if (obj[key]?.length !== array?.length && setCount) {
            setCount((prev) => ({
              ...prev,
              [key]: {
                ...prev[key],
                total_items: prev[key]?.total_items === 0 ? 0 : prev[key]?.total_items - 1,
              },
            }))
          }
        }
      }
    })

    return { ...obj }
  },

  deleteListTask: (obj, idToDelete) => {
    const filteredArray = Object.values(obj).filter((item: any) => item?.id !== idToDelete)
    return filteredArray
  },

  updateTask: (obj, updatedObjectData, currentListId: string, groupBy: string, setCount) => {
    let copy = deepClone(obj)
    const wrongFile = updatedObjectData?.fileId !== currentListId
    const idToUpdate = updatedObjectData?.id

    const { users = [], teams = [] } = updatedObjectData
    const mergedUsers = [...users, ...teams]

    for (const key in copy) {
      if (obj.hasOwnProperty(key)) {
        const array = copy[key]
        copy[key] = wrongFile
          ? array?.filter((obj) => obj?.id !== idToUpdate)
          : array?.map((obj) => {
              if (obj.id === idToUpdate) {
                return { ...obj, ...updatedObjectData, users: mergedUsers }
              }
              return obj
            })
      }
    }

    if (groupBy === GroupByFields.PRIORITY) {
      copy = socketTasksManagments.deleteTask(copy, [idToUpdate], setCount)
      copy[updatedObjectData?.priority] = removeDuplicate(
        insert(
          copy[updatedObjectData?.priority] || [],
          updatedObjectData?.priority_count,
          updatedObjectData,
        ),
      )
    } else if (groupBy === GroupByFields.ASSIGNEE) {
      copy = socketTasksManagments.deleteTask(copy, [idToUpdate], setCount)

      const treeKey =
        updatedObjectData?.users
          .map(({ id }) => id)
          .reverse()
          .join('&') || 'not_assigned_users'

      copy[treeKey] = removeDuplicate(
        insert(copy[treeKey] || [], updatedObjectData?.assigned_count, updatedObjectData),
      )
    }

    return copy
  },

  updateMany: (setNewData, dataToUpdate, setCount, groupBy) => {
    const { ids, listId } = dataToUpdate || {}

    store
      .dispatch(
        getTasks({
          fileId: listId,
          options: `{"where":{"id":{"operation":"$in","value":${JSON.stringify(ids)}}}}`,
        }),
      )
      .unwrap()
      .then((res) => {
        const tasks = res?.payload?.payload
        setNewData((obj) => {
          let newObj = deepClone(obj)

          tasks.forEach(
            (task: TaskType) =>
              (newObj = socketTasksManagments.updateTask(newObj, task, listId, groupBy, setCount)),
          )

          return newObj
        })
      })
  },

  updateListTask: (obj, idToUpdate, updatedObjectData) => {
    const updatedArray = obj.map((item) => {
      if (item.id === idToUpdate) {
        return updatedObjectData
      }
      return item
    })
    return updatedArray
  },

  bulkUpdateTask: (
    obj,
    updates,
    columns,
    targetTask: TaskType,
    setCount: (count) => void,
    groupBy,
  ) => {
    let newObj = deepClone(obj) // Create a deep copy of the object

    if (!targetTask || updates?.parentId || targetTask?.parentId) return obj

    newObj = socketTasksManagments.deleteTask(newObj, [updates?.taskUUID], setCount)

    const status = targetTask?.parentId
      ? targetTask?.status
      : columns.find((status) => status.id === updates?.statusId)

    setCount &&
      setCount((prev) => ({
        ...prev,
        [updates?.statusId]: {
          ...prev[updates?.statusId],
          total_items: prev[updates?.statusId]?.total_items + 1,
        },
      }))

    const columnId =
      groupBy === GroupByFields.ASSIGNEE
        ? targetTask[groupBy]
            ?.map(({ id }) => id)
            .reverse()
            .join('&') || 'not_assigned_users'
        : groupBy === GroupByFields.STATUS
          ? targetTask[groupBy]?.id
          : targetTask[groupBy]

    let newIndex = updates?.taskBelowUUID
      ? newObj[columnId]?.findIndex((task) => task?.id === updates?.taskBelowUUID) + 1
      : 0

    newObj[columnId] = insert(newObj[columnId] || [], newIndex, {
      ...targetTask,
      status,
      depth: 0,
    })

    return newObj
  },
}
