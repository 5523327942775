/* eslint-disable @typescript-eslint/no-extra-semi */
import Button from '@src/modules/shared/components/Button/Button'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { setCurrentWorkspace } from '@src/modules/shared/utils/setCurrentWorkspace'
import { message } from 'antd'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Carousel } from '../../components/Carousel'
import { Loader } from '../../components/Loader'
import { fetchUser } from '../../context/AuthProvider'
import { createWorkspace, inviteToWorkspace, logout, selectWorkspace } from '../../data/authThunk'
import { categories, options, ThemeColors } from '../../__mock__'
import WorkspaceAvatar from './CodeBlocks/WorkspaceAvatar'
import { WorkspaceGategories } from './CodeBlocks/WorkspaceGategories'
import WorkspaceInviteUsers from './CodeBlocks/WorkspaceInviteUsers'
import WorkspaceName from './CodeBlocks/WorkspaceName'
import WorkspaceTheme from './CodeBlocks/WorkspaceTheme'
import WorkspaceUsers from './CodeBlocks/WorkspaceUsers'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import desktop from '../../assets/icons/desktop.svg'
import orgDesktop from '../../assets/icons/orgDesktop.svg'

type FormValues = {
  workspaceName: string
  icon?: string | null
  color?: string | null
  tags: Array<string> | null
  theme?: string
  capacity: string
}

const initialValues: FormValues = {
  workspaceName: '',
  tags: [],
  color: '',
  icon: null,
  capacity: '',
  theme: '',
}

export const colors = [
  '#c5c5c5',
  '#0ba5ec',
  '#ed40bd',
  '#9777e8',
  '#3dd455',
  '#0B0B0B',
  '#2B41AF',
  '#E9950E',
]

const CreateWorkspace = () => {
  const { t } = useTranslation(['workspaces'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { user, status } = useAppSelector((state) => state.auth)

  const [selectedColor, setSelectedColor] = useState<string | null>('#A6A6A6')
  const [selectedTheme, setSelectedTheme] = useState<string | null>('#A6A6A6')
  const [selectedUserNumber, setSelectedUserNumber] = useState('Only me')
  const [uploadedImage, setUploadedImage] = useState<any | null>()
  const [confirm, setConfirm] = useState(false)
  const [confirmWorkspaceName, setConfirmWorkspaceName] = useState(false)
  const [confirmAvatar, setConfirmAvatar] = useState(false)
  const [confirmTheme, setConfirmTheme] = useState(false)
  const [confirmUsersNumber, setConfirmUserNumber] = useState(false)
  const [confirmInviteUsers, setConfirmInviteUsers] = useState(false)
  const [emails, setEmails] = useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [confirmCategories, setConfirmCategories] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const workspaceNameRef = useRef<any | null>(null)
  const avatarRef = useRef<any | null>(null)
  const themeRef = useRef<any | null>(null)
  const userSelectRef = useRef<any | null>(null)
  const userInvitetRef = useRef<any | null>(null)
  const categoriesRef = useRef<any | null>(null)
  const confirmWorkspaceRef = useRef<any | null>(null)

  const { width } = useWindowSize()

  useEffect(() => {
    if (confirm) {
      workspaceNameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmWorkspaceName && avatarRef?.current && formik?.errors?.workspaceName === undefined) {
      avatarRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmAvatar && themeRef?.current) {
      themeRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmTheme && userSelectRef.current) {
      userSelectRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmUsersNumber && userInvitetRef.current) {
      userInvitetRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmInviteUsers && categoriesRef.current) {
      categoriesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmCategories && confirmWorkspaceRef.current) {
      confirmWorkspaceRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (confirmUsersNumber && selectedUserNumber === 'Only me') {
      setConfirmInviteUsers(true)
    }
  }, [
    confirmWorkspaceName,
    confirmAvatar,
    confirmTheme,
    confirm,
    confirmUsersNumber,
    confirmInviteUsers,
    confirmCategories,
    selectedUserNumber,
  ])

  useEffect(() => {
    let timer
    if (status === 'loading') {
      setShowLoader(true)
    } else if (showLoader) {
      timer = setTimeout(() => {
        setShowLoader(false)
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [status, showLoader])

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      workspaceName: Yup.string()
        .required(t('Workspace name is required'))
        .min(5, t('Workspace name must be at least 5 characters'))
        .matches(/^[a-zA-Z][a-zA-Z0-9 ]*$/, t('Workspace name must not contain special symbols')),
    }),

    onSubmit: (values) => {
      values.tags = selectedCategories
      selectedTheme !== null ? (values.theme = selectedTheme) : null
      values.capacity = selectedUserNumber
      if (uploadedImage !== null) {
        values.icon = uploadedImage
        delete values.color
      }
      if (selectedColor !== null) {
        values.color = selectedColor
      }

      dispatch(createWorkspace(values))
        .unwrap()
        .then((data) => {
          dispatch(selectWorkspace(data?.payload?.id))
            .unwrap()
            .then(async (response) => {
              setCurrentWorkspace(data?.payload?.id)

              if (emails.length > 0)
                await dispatch(
                  inviteToWorkspace({ workspace: response?.payload?.id, emails: emails }),
                )
                  .unwrap()
                  .catch((err) => {
                    message.error(err.message || t('Error while inviting users'))
                  })
              fetchUser()
              window.location.href = '/dashboard'
            })
            .catch((err) => {
              message.error(err.message || t('Error while selecting workspace'))
            })
        })
        .catch((err) => {
          message.error(err.message || t('Error while creating workspace'))
        })
    },
  })

  const cancelCreateWorkspace = () => {
    if (user?.user?.workspaces?.length > 0) navigate('/dashboard')
    else dispatch(logout())
  }

  const handleCategorieSelect = (category: string) => {
    setSelectedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category],
    )
  }

  return (
    <div className="workspace-module">
      {!showLoader && (
        <RTLStyleDecorator>
          <div className="cancel-button" onClick={cancelCreateWorkspace}>
            {t('cancel')}
          </div>
        </RTLStyleDecorator>
      )}

      {showLoader ? (
        <Loader />
      ) : (
        <>
          <RTLStyleDecorator>
            <form className="form-container" onSubmit={formik.handleSubmit}>
              <div className="welcome-container">
                <div className="welcome-container-top">
                  <h1>
                    {t('Welcome')}, {user?.firstName || user?.user?.firstName}!
                  </h1>

                  <span>{t('Ready to set your workspace and get your tasks in line?')}</span>
                </div>

                {width <= 850 && <img src={desktop} />}

                <Button
                  children={t('Absolutely')}
                  onClick={() => setConfirm(!confirm)}
                  htmlType="button"
                  className="workspace-btn"
                />
              </div>

              <div
                className={`workspace-name-container ${confirm ? '' : 'disabled'}`}
                ref={workspaceNameRef}
              >
                <WorkspaceName
                  confirm={confirm}
                  formik={formik}
                  setConfirmWorkspaceName={setConfirmWorkspaceName}
                  confirmWorkspaceName={confirmWorkspaceName}
                />
              </div>

              <div
                className={`workspace-avatar-container ${
                  confirmWorkspaceName && formik?.errors?.workspaceName === undefined
                    ? ''
                    : 'disabled'
                }`}
                ref={avatarRef}
              >
                <WorkspaceAvatar
                  confirmWorkspaceName={confirmWorkspaceName}
                  formik={formik}
                  colors={colors}
                  confirmAvatar={confirmAvatar}
                  setUploadedImage={setUploadedImage}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  setConfirmAvatar={setConfirmAvatar}
                />
              </div>

              <div
                className={`workspace-color-container  ${confirmAvatar ? '' : 'disabled'}`}
                ref={themeRef}
              >
                <WorkspaceTheme
                  ThemeColors={ThemeColors}
                  confirmAvatar={confirmAvatar}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  confirmTheme={confirmTheme}
                  setConfirmTheme={setConfirmTheme}
                />
              </div>

              <div
                className={`workspace-users-selection-container ${confirmTheme ? '' : 'disabled'}`}
                ref={userSelectRef}
              >
                <WorkspaceUsers
                  options={options}
                  selected={selectedUserNumber}
                  setSelected={setSelectedUserNumber}
                  confirmTheme={confirmTheme}
                  setConfirmUserNumber={setConfirmUserNumber}
                  confirmUsersNumber={confirmUsersNumber}
                />
              </div>

              {selectedUserNumber !== 'Only me' && (
                <div
                  className={`workspace-invite-users-container ${
                    confirmUsersNumber ? '' : 'disabled'
                  }`}
                  ref={userInvitetRef}
                >
                  <WorkspaceInviteUsers
                    emails={emails}
                    setEmails={setEmails}
                    confirmUsersNumber={confirmUsersNumber}
                    setConfirmInviteUsers={setConfirmInviteUsers}
                    confirmInviteUsers={confirmInviteUsers}
                  />
                </div>
              )}

              <div
                className={`workspace-categorie-container ${confirmInviteUsers ? '' : 'disabled'}`}
                ref={categoriesRef}
              >
                <WorkspaceGategories
                  categories={categories}
                  confirmInviteUsers={confirmInviteUsers}
                  selectedCategories={selectedCategories}
                  handleCategorieSelect={handleCategorieSelect}
                  setConfirmCategories={setConfirmCategories}
                  confirmCategories={confirmCategories}
                />
              </div>

              <div
                className={`workspace-confirm-container ${confirmCategories ? '' : 'disabled'}`}
                ref={confirmWorkspaceRef}
              >
                <div className="workspace-confirm-container-top">
                  <h1>
                    {t('Great job')}, {user?.firstName || user?.user?.firstName}!
                  </h1>

                  <span>{t('Your workspace is now good to go')}!</span>
                </div>

                {width <= 850 && <img src={orgDesktop} />}

                <Button
                  children={'Confirm'}
                  className="workspace-btn"
                  htmlType="submit"
                  disabled={confirmCategories ? false : true}
                />
              </div>
            </form>
          </RTLStyleDecorator>

          <Carousel
            confirm={confirm}
            confirmWorkspaceName={confirmWorkspaceName}
            confirmAvatar={confirmAvatar}
            confirmTheme={confirmTheme}
            confirmUsersNumber={confirmUsersNumber}
            confirmInviteUsers={confirmInviteUsers}
          />
        </>
      )}
      <div className="color_linear" style={{ background: 'rgb(152,106,171)' }}></div>

      <div className="color_linear_2" style={{ background: 'rgba(101, 255, 227, 0.50)' }}></div>
    </div>
  )
}

export default CreateWorkspace
