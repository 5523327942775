export default function CollapIcon() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.82469 6.12501L10.8059 6.12501C10.9301 6.12429 11.0516 6.16055 11.1551 6.22918C11.2585 6.2978 11.3392 6.39568 11.3868 6.51034C11.4344 6.62499 11.4468 6.75122 11.4224 6.87294C11.3979 6.99466 11.3378 7.10634 11.2497 7.19376L7.75594 10.6813C7.63883 10.7977 7.48043 10.863 7.31531 10.863C7.15019 10.863 6.99179 10.7977 6.87469 10.6813L3.38094 7.19376C3.29279 7.10634 3.23268 6.99466 3.20826 6.87294C3.18384 6.75122 3.19623 6.62499 3.24383 6.51034C3.29143 6.39568 3.3721 6.2978 3.47555 6.22918C3.579 6.16055 3.70055 6.12429 3.82469 6.12501Z"
        fill="#9747FF"
      />
    </svg>
  )
}
