/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  createDocument,
  deleteDocument,
  editDocument,
  getAllDocuments,
  getDocument,
} from './documentThunk'
import { DocumentType } from './documentType'

const initialState: DocumentType = {
  error: null,
  status: 'idle',
  postStatus: 'idle',
  document: null,
  documentList: [],

  mentionedElem: { type: '', value: '', id: '', link: '' },
  googleDocsElem: { link: '' },
  googleSheetsElem: { link: '' },
  googleSlideElem: { link: '' },
  googleDriveElem: { link: '' },
  iframeElem: { link: '' },
  iframeHeight: null,
  codeElem: { code: '', lang: '' },
}

const FilesSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setMentionElement: (state, { payload }) => {
      state.mentionedElem = payload
    },
    setGoogleDocsElement: (state, { payload }) => {
      state.googleDocsElem = payload
    },
    setGoogleSheetsElement: (state, { payload }) => {
      state.googleSheetsElem = payload
    },
    setGoogleSlideElement: (state, { payload }) => {
      state.googleSlideElem = payload
    },
    setGoogleDriveElement: (state, { payload }) => {
      state.googleDriveElem = payload
    },
    setIframeElement: (state, { payload }) => {
      state.iframeElem = payload
    },
    setIframeHeight: (state, { payload }) => {
      state.iframeHeight = payload
    },

    setCodeElement: (state, { payload }) => {
      state.codeElem = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createDocument.pending, (state) => {
      state.error = null
      state.postStatus = 'loading'
    })

    builder.addCase(createDocument.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.document = action.payload
      state.postStatus = 'success'
    })

    builder.addCase(createDocument.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.postStatus = 'failed'
    })

    /** ---get all-- */

    builder.addCase(getAllDocuments.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(getAllDocuments.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.status = 'success'
      state.documentList = action.payload
    })

    builder.addCase(getAllDocuments.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.status = 'failed'
    })

    /** -----get-one------ */

    builder.addCase(getDocument.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(getDocument.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.status = 'success'
      state.document = action.payload.payload
    })

    builder.addCase(getDocument.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.status = 'failed'
    })

    /** -----update------ */

    builder.addCase(editDocument.pending, (state) => {
      state.error = null
      state.postStatus = 'loading'
    })

    builder.addCase(editDocument.fulfilled, (state) => {
      state.error = null
      state.postStatus = 'success'
    })

    builder.addCase(editDocument.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.postStatus = 'failed'
    })

    /** -----delete-one------ */

    builder.addCase(deleteDocument.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(deleteDocument.fulfilled, (state) => {
      state.error = null
      state.status = 'success'
    })

    builder.addCase(deleteDocument.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.status = 'failed'
    })
  },
})

export const {
  setMentionElement,
  setGoogleDocsElement,
  setGoogleSheetsElement,
  setGoogleSlideElement,
  setGoogleDriveElement,
  setIframeElement,
  setIframeHeight,
  setCodeElement,
} = FilesSlice.actions // eslint-disable-line

export default FilesSlice.reducer
