/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'
import { commentProps } from './commentType'

export const createComment = createAsyncThunk(
  'comment/create-comment',
  async ({ documentId, content, resolved }: commentProps) => {
    try {
      const response = await axiosInstance.post(`/api/documents/${documentId}/comments`, {
        content,
        resolved,
      })

      if (response.status === 201) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getAllComments = createAsyncThunk(
  'comments/get-all',
  async ({ documentId, filter }: { documentId: string; filter: {} }) => {
    try {
      const response = await axiosInstance.get(
        `/api/documents/${documentId}/comments?options=${filter}`,
      )

      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getComment = createAsyncThunk(
  'comments/get-one',
  async ({ commentId }: { commentId: string }) => {
    try {
      const response = await axiosInstance.get(`/api/comment/${commentId}`)

      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const updateComment = createAsyncThunk(
  'comment/update-comment',
  async ({ commentId, content, resolved }: commentProps) => {
    try {
      const response = await axiosInstance.patch(`/api/comment/${commentId}`, {
        content,
        resolved,
      })
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const deleteComment = createAsyncThunk(
  'comment/delete-comment',
  async ({ commentId }: { commentId: string }) => {
    try {
      const response = await axiosInstance.delete(`/api/comment/${commentId}`)
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

type reactionProps = {
  type?: string
  code: string
  commentId: string
  answerId?: string
  reactionId?: string
}

export const addReaction = createAsyncThunk('comment/add-reaction', async (data: reactionProps) => {
  try {
    const response = await axiosInstance.post(`/api/reaction`, data)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const updateReaction = createAsyncThunk(
  'comment/update-reaction',
  async (data: reactionProps) => {
    const { reactionId, ...rest } = data

    try {
      const response = await axiosInstance.patch(`/api/reaction/${reactionId}`, { rest })

      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const deleteReaction = createAsyncThunk(
  'comment/delete-reaction',
  async (data: reactionProps) => {
    const { reactionId } = data

    try {
      const response = await axiosInstance.delete(`/api/reaction/${reactionId}`)

      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)
