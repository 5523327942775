export default function LeftArrow() {
  return (
    <svg width="30" height="30" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91758 14.187C9.79614 14.187 9.67469 14.1423 9.57882 14.0464L5.41146 9.87903C4.73394 9.20151 4.73394 8.08936 5.41146 7.41185L9.57882 3.24449C9.76418 3.05913 10.071 3.05913 10.2563 3.24449C10.4417 3.42985 10.4417 3.73665 10.2563 3.922L6.08897 8.08936C5.78217 8.39616 5.78217 8.89471 6.08897 9.20151L10.2563 13.3689C10.4417 13.5542 10.4417 13.861 10.2563 14.0464C10.1605 14.1359 10.039 14.187 9.91758 14.187Z"
        fill="#7C4493"
      />
    </svg>
  )
}
