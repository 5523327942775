/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createFolder, deleteFolder } from './folderThunk'

export interface FoldersType {
  error: any
  status: string
}

const initialState: FoldersType = {
  error: null,
  status: 'idle',
}

const FoldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFolder.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(createFolder.fulfilled, (state) => {
      state.status = 'idle'
    })
    builder.addCase(createFolder.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(deleteFolder.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteFolder.fulfilled, (state) => {
      state.status = 'idle'
    })
    builder.addCase(deleteFolder.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {} = FoldersSlice.actions // eslint-disable-line

export default FoldersSlice.reducer
