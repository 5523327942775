import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const ImagePlayer = ({ media }: any) => {
  return (
    <div className="media-image-player" onClick={(e) => e.stopPropagation()}>
      <TransformWrapper>
        <TransformComponent>
          <img src={media?.location} />
        </TransformComponent>
      </TransformWrapper>
    </div>
  )
}

export default ImagePlayer
