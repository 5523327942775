import { useAppDispatch } from '@src/modules/shared/store/index'
import { message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import deleteTrash from '../../../assets/icons/trash/deleteTrash.svg'
import { deleteItemPermanently } from '../../../data/trash/TrashThunk'
import circle from '../../../assets/icons/trash/circle.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import Button from '@src/modules/shared/components/Button/Button'

const DeletePermanentlyModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['trash'])
  const dispatch = useAppDispatch()
  const { elem } = data || {}
  const { onDelete } = data
  const lang = localStorage.getItem('i18nextLng')

  const handleDelete = (elem) => {
    dispatch(deleteItemPermanently(elem?.id))
      .unwrap()
      .then((res) => {
        message.success(res?.message || 'Element deleted with success')
        onDelete()
      })
    handleClose(id)
  }

  return (
    <Modal
      footer={true}
      centered
      open={open}
      closeIcon={null}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      closable={true}
      className="delete-permanently-modal"
    >
      <RTLStyleDecorator>
        <div className="delete-permanently-modal_header">
          {t('Delete permanently')}

          <img
            src={circle}
            onClick={() => handleClose(id)}
            className={`close ${lang === 'ar' ? 'ar-close' : ''}`}
          />
        </div>
      </RTLStyleDecorator>

      <div className="delete-permanently-modal_content">
        <img src={deleteTrash} />

        <h2>{t('Delete this element permanently?')}</h2>

        <p>
          <span>{t('Important:')}</span>
          {t(
            'Are you sure you want to delete this element permanently? Once deleted, it cannot be recovered.',
          )}
        </p>
      </div>

      <div className="delete-permanently-modal_footer">
        <Button className="btn cancel" onClick={() => handleClose(id)}>
          {t('Cancel')}
        </Button>

        <Button className="btn delete" onClick={() => handleDelete(elem)}>
          {t('Delete')}
        </Button>
      </div>
    </Modal>
  )
}

export default DeletePermanentlyModal
