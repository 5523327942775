import { useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import axiosInstance from '../../shared/utils/axios'
import { useSelector } from 'react-redux'
import { clearTokens, getTokens } from '../utils/token'
import useIsMountedRef from '../hook/useIsMountedRef'
import { initialise } from '../data/authSlice'
import { RootState, store } from '@src/modules/shared/store'
import LazyLoad from '@src/modules/shared/components/LazyLoad/LazyLoad'
import { setPermissions } from '@src/modules/shared/store/slices/permissions/permissionsSlice'

interface AuthProviderProps {
  children: React.ReactNode
}

interface JwtPayload {
  exp: number
}

const isValidToken = (token: string) => {
  const decoded: JwtPayload = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const fetchUser = async function fetchUser() {
  const { refresh_token } = getTokens()
  if (refresh_token && isValidToken(refresh_token)) {
    try {
      const response = await axiosInstance.get('/auth/me')
      const user = response.data.payload
      store.dispatch(initialise({ isAuthenticated: true, user }))
      store.dispatch(setPermissions(user?.user?.roles))
    } catch (err) {
      if (err.request) {
        console.error('CORS error or network issue:', err.message)
      } else {
        store.dispatch(initialise({ isAuthenticated: false, user: null }))
        clearTokens()
      }
    }
  } else {
    store.dispatch(initialise({ isAuthenticated: false, user: null }))
    clearTokens()
  }
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const isMounted = useIsMountedRef()
  const { isInitialised } = useSelector((state: RootState) => state.auth)

  const currentWorkspace = localStorage.getItem('currentWorkspace')

  useEffect(() => {
    if (!isMounted.current) return
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace])

  if (!isInitialised) {
    return <LazyLoad />
  }

  return <>{children}</>
}

export default AuthProvider
