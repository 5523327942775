import TableHeader from './TableHeader'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import deepClone from 'deep-clone'
import {
  useBulkUpdateTasksMutation,
  useGetSubtasksQuery,
  useUpdateTaskMutation,
} from '@src/modules/tasks/services/tasksApi'
import { ControlledTreeEnvironment, InteractionMode, Tree } from 'react-complex-tree'
import { useEffect, useState } from 'react'
import { TaskSkeleton, renderItem, renderItemProps } from './helpers/renderItem'
import {
  addSkeletons,
  convertTasksToTemplateTree,
  generateFakeTask,
  getTasksChildrenIds,
  mergeFakeTask,
  removeDuplicate,
  removeSkeletons,
  setNewStatusTree,
} from '../../../ListView/utils/listViewManagment'
import { getTasksChildren } from '../../../ListView/StatusTree/StatusTree'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { resetSocketEvents } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { renderArrow } from '../../../ListView/utils/renderArrow'
import { useTranslation } from 'react-i18next'
import { socketListManagement } from '../../../ListView/ListView'

interface SubTasksTreeProps {
  task: TaskType
}

const increaseChildrensCount = (item) => {
  return {
    ...item,
    content: { ...item?.content, totalSubtask: item?.content?.totalSubtask + 1 },
  }
}

const decreaseChildrensCount = (item) => {
  return {
    ...item,
    content: { ...item?.content, totalSubtask: item?.content?.totalSubtask - 1 },
  }
}

const SubTasksTree = ({ task }: SubTasksTreeProps) => {
  const { t } = useTranslation(['tasks'])
  const { deletedTask, updatedTask, bulkUpdateTask, createdTask, updatedMany } = useAppSelector(
    (state) => state.tasks,
  ) //socket updates "socket events"
  const [bulkupdate] = useBulkUpdateTasksMutation()
  const [updateTask] = useUpdateTaskMutation()
  const { data, isLoading, refetch } = useGetSubtasksQuery({
    fileId: task?.fileId,
    options: JSON.stringify({
      where: {
        parentId: { operation: '$Equal', value: task.id },
      },
      order: { subtaskCount: 'ASC' },
    }),
  })
  const [tree, setTree] = useState<any>({ [task.id]: {} })
  const [expandedItems, setExpandedItems] = useState<string[]>([])
  const { payload: subtasks } = data || {}
  const dispatch = useAppDispatch()

  const fakeTask = tree['fake-task']
  const listId = task.fileId

  useEffect(() => {
    socketListManagement(
      { deletedTask, updatedTask, bulkUpdateTask, updatedMany },
      setTree,
      () => {},
      listId,
      null,
      t,
    )
  }, [deletedTask, updatedTask, bulkUpdateTask])

  useEffect(() => {
    refetch()
  }, [])

  const removeLocalTask = (id: string = 'fake-task') => {
    setTree((prev) => {
      const treeClone = deepClone(prev)
      const fakeTask = treeClone[id]
      const fakeTaskParentId = fakeTask?.parentId
      if (treeClone[fakeTaskParentId]?.children) {
        treeClone[fakeTaskParentId].children = treeClone[fakeTaskParentId].children.filter(
          (childId) => childId !== id,
        )
        const totalsubtasks = treeClone[fakeTaskParentId].content?.totalSubtask
        if (id !== 'fake-task' && totalsubtasks > 0) {
          treeClone[fakeTaskParentId].content = {
            ...treeClone[fakeTaskParentId].content,
            totalSubtask: totalsubtasks - 1,
          }
        }
      }
      if (treeClone[fakeTaskParentId]) treeClone[fakeTaskParentId].isLoading = false

      delete treeClone[id]

      return treeClone
    })
  }

  const addFakeTask = (parentId: string, isTop: boolean, isBottom: boolean) => {
    removeLocalTask()
    const fakeTask = generateFakeTask('fake-task', parentId, isTop, isBottom)
    setTree((prev) => ({
      ...prev,
      [parentId]: {
        ...prev[parentId],
        children: mergeFakeTask(prev[parentId]?.children || [], fakeTask),
      },
      'fake-task': fakeTask,
    }))
  }
  const expandManagement = (tree) => {
    expandedItems?.forEach(async (itemId) => {
      const item = tree[itemId]
      const { totalSubtask } = item?.content || {}
      const totalCurrentChildren = getTasksChildrenIds(item)?.length
      if (
        ((totalCurrentChildren || totalSubtask === 0) && totalCurrentChildren === totalSubtask) ||
        item?.isLoading
      )
        return

      addSkeletons(item, setTree)
      const response = await getTasksChildren(itemId, task?.fileId)
      removeSkeletons(item?.id, setTree)
      saveSubtasks(response?.payload, item)
    })
  }

  const saveSubtasks = (subtasks: TaskType[], parentTask: { id: string; content: TaskType }) => {
    const newSubtasks = removeDuplicate(subtasks?.map(({ id }) => id))
    setTree((prev) => {
      const fakeTask = prev['fake-task']?.parentId === parentTask?.id ? prev['fake-task'] : null
      return {
        ...prev,
        [parentTask?.id]: {
          ...parentTask,
          content: {
            ...(parentTask?.content || {}),
            totalSubtask: newSubtasks.length,
          },
          children: removeDuplicate(mergeFakeTask(newSubtasks, fakeTask)),
        },
        ...convertTasksToTemplateTree(subtasks, (taskId: string) => prev[taskId]?.children),
      }
    })
  }

  useEffect(() => {
    if (createdTask && createdTask?.status) {
      const syncData = async () => {
        const item = tree[createdTask?.parentId || createdTask?.status?.id]
        const oldChildrens = getTasksChildrenIds(item)
          ?.map((id: string) => tree[id]?.content)
          .filter((item: TaskType) => !!item && createdTask?.id !== item?.id)

        saveSubtasks([...(oldChildrens || []), createdTask], item)
      }
      const alreadyCreated = tree[createdTask?.id]

      if (!alreadyCreated) syncData()
      dispatch(resetSocketEvents())
    }
  }, [createdTask])

  useEffect(() => {
    expandManagement(tree)
  }, [expandedItems])

  useEffect(() => {
    if (subtasks) {
      const subtasksIds = subtasks.map((subtask) => subtask?.id)
      const treeSubtasks = convertTasksToTemplateTree(
        subtasks,
        (taskId: string) => tree[taskId]?.children,
      )
      const newTree = {
        ...tree,
        [task.id]: {
          ...tree?.root,
          id: task.id,
          index: task.id,
          content: task,
          children: subtasksIds,
        },
        ...treeSubtasks,
      }
      setTree(newTree)
    }
  }, [subtasks])

  const dropEndEvent = (dragItems, target) => {
    const item = dragItems[0]

    const parent: any = Object.values(tree).find((potentialParent: any) =>
      potentialParent.children?.includes(item.index),
    )
    const newTree = target?.treeId

    if (target.targetType === 'item' || target.targetType === 'root') {
      if (target.targetItem === parent.index) {
        // NOOP
      } else {
        const newParent = tree[target.targetItem]

        setTree((prev) => {
          return {
            ...prev,
            [parent.index]: decreaseChildrensCount({
              ...prev[parent.index],
              children: parent.children.filter((child) => child !== item.index),
            }),
            [target.targetItem]: increaseChildrensCount({
              ...prev[target.targetItem],
              children: [item.index, ...(prev[target.targetItem].children ?? [])],
            }),
            [item.index]: setNewStatusTree(item, newTree, false, prev),
          }
        })
        updateTask({
          id: item?.id,
          parentId: newParent?.isRoot ? null : target.targetItem,
          statusId: newParent?.isRoot ? newTree : item?.content?.status?.id,
        })
      }
    } else {
      const newParent = tree[target.parentItem]
      let newParentChildren = [...(newParent.children ?? [])].filter(
        (child) => child !== item.index,
      )

      if (target.parentItem === item.index) {
        return
      }

      if (target.parentItem === parent.index) {
        const isOldItemPriorToNewItem =
          ((newParent.children ?? []).findIndex((child) => child === item.index) ?? Infinity) <
          target.childIndex
        newParentChildren.splice(
          target.childIndex - (isOldItemPriorToNewItem ? 1 : 0),
          0,
          item.index,
        )

        setTree((prev) => ({
          ...prev,
          [target.parentItem]: { ...prev[target.parentItem], children: newParentChildren },
        }))

        const taskBelow =
          newParentChildren[target.childIndex - (isOldItemPriorToNewItem ? 1 : 0) - 1]

        bulkupdate({
          taskUUID: item?.id,
          statusId: item?.content?.status?.id,
          fileId: listId,
          targetIndex: 'subtaskIndex',
          taskBelowUUID: taskBelow,
          parentId: tree[target.parentItem]?.isRoot ? null : target.parentItem,
        })
      } else {
        newParentChildren.splice(target.childIndex, 0, item.index)
        setTree((prev) => ({
          ...prev,
          [parent?.index]: decreaseChildrensCount({
            ...prev[parent.index],
            children: parent.children.filter((child) => child !== item.index),
          }),
          [target.parentItem]: increaseChildrensCount({
            ...prev[target.parentItem],
            children: newParentChildren,
          }),
          [item.index]: setNewStatusTree(item, newTree, newParent?.isRoot, prev),
        }))

        if (newParent?.isRoot) {
          const taskBelow = newParentChildren[target.childIndex - 1]

          bulkupdate({
            taskUUID: item?.id,
            fileId: listId,
            targetIndex: 'subtaskIndex',
            taskBelowUUID: taskBelow,
            parentId: null,
          })
        } else
          updateTask({
            id: item?.id,
            parentId: target.parentItem,
            order: target.childIndex?.toString(),
          })
      }
    }
  }

  return (
    <div className="subtasks-tree">
      <TableHeader />
      <div className="subtasks-tree-container">
        {isLoading ? (
          <div className="tree-skeletons">
            {Array(task?.totalSubtask)
              .fill('')
              .map((_, i) => (
                <TaskSkeleton depth={-1} key={i} />
              ))}
          </div>
        ) : (
          <ControlledTreeEnvironment
            items={tree}
            getItemTitle={(item) => item.data}
            viewState={{
              [task?.id]: {
                expandedItems,
              },
            }}
            renderDragBetweenLine={(info) => {
              return (
                <div
                  style={{
                    width: `calc(100% - ${info.draggingPosition.depth * 29}px)`,
                    height: '3px',
                    marginLeft: 'auto',
                    background: '#6f00ff',
                  }}
                ></div>
              )
            }}
            defaultInteractionMode={InteractionMode.ClickItemToExpand}
            onDrop={dropEndEvent}
            canDropOnNonFolder={false}
            canDragAndDrop={true}
            canDropOnFolder={true}
            canReorderItems={true}
            renderItemArrow={renderArrow}
            onExpandItem={(item) => {
              if (item) setExpandedItems([...expandedItems, item.index as string])
            }}
            onCollapseItem={(item) =>
              setExpandedItems(
                expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index),
              )
            }
            renderTreeContainer={({ containerProps, children }) => {
              return <div {...containerProps}>{children}</div>
            }}
            renderItem={(props) =>
              renderItem({
                ...props,
                tree,
                removeFakeTask: removeLocalTask,
                addFakeTask,
                activeList: { id: task?.fileId },
              } as unknown as renderItemProps)
            }
            renderItemsContainer={({ children, containerProps }) => (
              <ul {...containerProps}>{children}</ul>
            )}
            canSearch={false}
            canRename={false}
          >
            <Tree treeId={task?.id} rootItem={task?.id} treeLabel="Tree Example" />
          </ControlledTreeEnvironment>
        )}
      </div>
      {!fakeTask && (
        <div className="add-bottom-task" onClick={() => addFakeTask(task?.id, false, true)}>
          <p>{t('add_task')}</p>
        </div>
      )}
    </div>
  )
}

export default SubTasksTree
