const RecordingSVG = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.0218506"
        y="0.627441"
        width="19.4355"
        height="17.7455"
        rx="7.20088"
        fill="#9747FF"
      />
      <rect x="5.73962" y="5.5" width="8" height="8" rx="2" fill="white" />
    </svg>
  )
}

export default RecordingSVG
