import { ComponentProps } from 'react'

type Props = {}

function AsanaHighlightedSVG(props: Props & ComponentProps<'svg'>) {
  return (
    <svg
      {...props}
      width="108"
      height="109"
      viewBox="0 0 108 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54 6.92334C27.9387 6.92334 6.75 28.112 6.75 54.1733C6.75 80.2347 27.9387 101.423 54 101.423C80.0613 101.423 101.25 80.2347 101.25 54.1733C101.25 28.112 80.0613 6.92334 54 6.92334Z"
        fill="url(#paint0_radial_1206_11852)"
      />
      <path
        d="M69.2562 53.9746C62.7695 53.9746 57.512 59.3074 57.512 65.8861C57.512 72.4651 62.7695 77.7983 69.2562 77.7983C75.7421 77.7983 81 72.4651 81 65.8861C81 59.3074 75.7421 53.9746 69.2562 53.9746ZM38.744 53.9754C32.2582 53.9754 27 59.3074 27 65.8867C27 72.4651 32.2582 77.7983 38.744 77.7983C45.2303 77.7983 50.4886 72.4651 50.4886 65.8867C50.4886 59.3074 45.2303 53.9754 38.744 53.9754ZM65.7437 39.0848C65.7437 45.6634 60.4862 50.9975 54.0003 50.9975C47.5139 50.9975 42.2561 45.6634 42.2561 39.0848C42.2561 32.5066 47.5139 27.1733 54.0003 27.1733C60.4862 27.1733 65.7437 32.5066 65.7437 39.0848Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1206_11852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(54 58.5697) scale(62.6188)"
        >
          <stop stop-color="#FFB900" />
          <stop offset="0.6" stop-color="#F95D8F" />
          <stop offset="0.9991" stop-color="#F95353" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default AsanaHighlightedSVG
