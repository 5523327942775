import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// const saveSidebarStates = (sidebarId, value = true) => {
//   const previousState = JSON.parse(localStorage.getItem('sidebars'))
//   localStorage.setItem('sidebars', JSON.stringify({ ...(previousState || {}), [sidebarId]: value }))
// }

// const getSidebarState = (sidebars) => {
//   const sidebarsState = JSON.parse(localStorage.getItem('sidebars')) || null
//   let newSidebars = {}
//   Object.keys(sidebars)?.forEach(
//     (sidebarId) =>
//       (newSidebars = {
//         ...newSidebars,
//         [sidebarId]: sidebarsState ? sidebarsState[sidebarId] : true,
//       }),
//   )
//   return newSidebars
// }

const initialState: SettingsState = {
  sidebar: {
    'layout-sidebar': true,
    'settings-sidebar': true,
    'daily-question-sidebar': true,
  },
  sidebarWidth: 275,
  screenRecording: {
    startRecording: false,
    stopRecording: false,
    isRecording: false,
    recordedFile: null,
    blobFile: null,
    status: '',
    taskId: null,
  },

  isScroll: true,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleSidebar: (state, _action: PayloadAction<string>) => {
      const updatedSidebar = { ...state.sidebar }

      for (const sidebarKey in updatedSidebar) {
        if (Object.hasOwnProperty.call(updatedSidebar, sidebarKey)) {
          updatedSidebar[sidebarKey] = !updatedSidebar[sidebarKey]
        }
      }

      const updatedWidth = state.sidebarWidth > 0 ? 60 : 275

      return { ...state, sidebar: updatedSidebar, sidebarWidth: updatedWidth }
    },

    openSidebar: (state, action: PayloadAction<string>) => {
      if (state.sidebar) {
        // saveSidebarStates(action.payload)
        state.sidebar[action.payload] = true
      }
    },
    setSidebarWidth: (state, action: PayloadAction<number>) => {
      state.sidebarWidth = action.payload
    },
    closeSidebar: (state, _action: PayloadAction<string>) => {
      if (state.sidebar) {
        // saveSidebarStates(action.payload, false)
        state.sidebar['layout-sidebar'] = false
        state.sidebar['settings-sidebar'] = false

        state.sidebar['daily-question-sidebar'] = false
        state.sidebarWidth = 60
      }
    },
    startRecording: (state) => {
      state.screenRecording.startRecording = true
    },
    stopRecording: (state) => {
      state.screenRecording.startRecording = false
      state.screenRecording.isRecording = false
    },
    setRecordedFile: (state, action: PayloadAction<any>) => {
      state.screenRecording.recordedFile = action.payload.file
      state.screenRecording.blobFile = action.payload?.blob
    },
    setStatus: (state, action: PayloadAction<{ taskId: string | null; status: string }>) => {
      const { status, taskId } = action.payload
      state.screenRecording.status = status
      if (status === 'acquiring_media') {
        state.screenRecording.startRecording = true
      } else if (status === 'stopped') {
        state.screenRecording.startRecording = false
        state.screenRecording.isRecording = false
      } else if (status === 'recording') {
        state.screenRecording.isRecording = true
        state.screenRecording.taskId = taskId
      } else {
        state.screenRecording = { ...initialState.screenRecording, taskId }
      }
    },
    resetFiles: (state) => {
      state.screenRecording.blobFile = null
      state.screenRecording.recordedFile = null
      state.screenRecording.taskId = null
    },
  },
})

export default settingsSlice.reducer

export const {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  startRecording,
  stopRecording,
  setRecordedFile,
  setStatus,
  setSidebarWidth,
  resetFiles,
} = settingsSlice.actions
