import React, { useState, useEffect, useCallback } from 'react'
import { ReactComponent as TimerClock } from './assets/icons/timer-clock.svg'
import { ReactComponent as TimerPlay } from './assets/icons/timer-play.svg'

import { ReactComponent as TimerPause } from './assets/icons/timer-pause.svg'

import { Dropdown, message } from 'antd'

import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { closeTimerById, startTimerWithPayload } from '../../data/timerData/slices'
import { useTimer } from '../Timer/hooks/useTimer'
import { formatTimerTime } from '../Timer/utils/formateTimerTime'

import GlobalTimerDropdownContent from './GlobalTimerDropdownContent'
import { useTranslation } from 'react-i18next'

function GlobalTimer() {
  const { currentActiveTimer, timerStartAt } = useAppSelector((state) => state.timer)
  const { t } = useTranslation(['timer'])
  const [isRunning, setIsRunning] = useState(timerStartAt ? true : false)
  const [actionCount, setActionCount] = useState(0)
  const [lastActionTime, setLastActionTime] = useState(Date.now())
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (timerStartAt) {
      setIsRunning(true)
    } else {
      setIsRunning(false)
    }
  }, [timerStartAt])
  const checkRapidActions = () => {
    const now = Date.now()
    if (now - lastActionTime < 5000) {
      setActionCount((prevCount) => prevCount + 1)
      if (actionCount >= 5) {
        message.warning(t('please_avoid_rapidly_starting_and_stopping_the_timer'))
        return true
      }
    } else {
      setActionCount(0)
    }
    setLastActionTime(now)
    return false
  }

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (checkRapidActions()) return
      dispatch(closeTimerById(currentActiveTimer?.id))
      setIsRunning(false)
    },
    [checkRapidActions, currentActiveTimer],
  )

  const { user } = useAppSelector((state) => state.auth)
  const { openTimer } = user

  useEffect(() => {
    if (openTimer) {
      dispatch(startTimerWithPayload(openTimer))
    }
  }, [openTimer])
  const { elapsedSeconds } = useTimer()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <>
      {/* //TODO: remove this after testing */}
      {/* {false ? (
        <Skeleton.Input active />
      ) : ( */}
      <Dropdown
        dropdownRender={() => <GlobalTimerDropdownContent elapsedSeconds={elapsedSeconds} />}
        trigger={['click']}
        open={dropdownOpen}
        onOpenChange={setDropdownOpen}
      >
        <div
          className={`global-timer-floating-button-container ${isRunning ? 'global-timer-floating-button-container-running' : ''}`}
        >
          <TimerClock />
          {isRunning ? <TimerPause onClick={handleClose} /> : <TimerPlay />}
          {formatTimerTime(elapsedSeconds)}
        </div>
      </Dropdown>
      {/* )} */}
    </>
  )
}

export default GlobalTimer
