import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { Skeleton } from 'antd'

const CommentSkeleton = () => {
  const { width } = useWindowSize()

  return (
    <div className="skeleton_card setName_skeleton">
      <div className="setState_skeleton_header">
        <div className="setState_skeleton_header_right">
          <Skeleton.Avatar active></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={{ width: '194px', height: '16px', borderRadius: '30px' }}
          ></Skeleton.Button>

          {width > 900 && (
            <Skeleton.Button
              active
              style={{ width: '120px', height: '16px', borderRadius: '30px' }}
            ></Skeleton.Button>
          )}
        </div>

        {width > 450 && (
          <Skeleton.Button
            active
            style={{ width: '85px', height: '22px', borderRadius: '30px' }}
          ></Skeleton.Button>
        )}
      </div>

      <div className="comment_skeleton_content" style={width < 900 ? { width: '80%' } : {}}>
        <Skeleton.Button
          active
          style={{ width: '150px', height: '60px', borderRadius: '5px' }}
        ></Skeleton.Button>

        <Skeleton.Button
          active
          style={{ width: '200px', height: '15px', borderRadius: '30px' }}
        ></Skeleton.Button>

        <Skeleton.Button
          active
          style={{ width: '100%', height: '15px', borderRadius: '30px' }}
        ></Skeleton.Button>

        <Skeleton.Button
          active
          style={{ width: '150px', height: '15px', borderRadius: '30px' }}
        ></Skeleton.Button>
      </div>
    </div>
  )
}

export default CommentSkeleton
