import DeleteIcon from '@src/modules/dailyQuestions/assets/icons/member/DeleteIcon'
import { Editor } from '../../memberView.interface'
import React, { useState } from 'react'
import { Popover } from 'antd'
import { ReactComponent as Menu } from '../../assets/menu.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  isView: boolean
  removeEditor: (id: number) => void
  editor: Editor
}

function DeleteBlocButton({ isView, removeEditor, editor }: Props) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation(['dailyQuestion'])

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  return (
    <>
      {!isView && (
        <Popover
          content={
            <a
              onClick={() => {
                hide()
                removeEditor(editor.id)
              }}
            >
              <div className="daily-bloc_popover">
                {!isView && (
                  <div className="remove-editor">
                    <DeleteIcon />
                  </div>
                )}
                <p>{t('answers_tab.delete')}</p>
              </div>
            </a>
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <Menu />
        </Popover>
      )}
    </>
  )
}

export default React.memo(DeleteBlocButton)
