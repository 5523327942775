export const localSearch = (data: any[], search: string, fields: string[]) => {
  const searchLowerCase = search?.toLowerCase()
  if (!search) return data
  const result = data?.filter((member) => {
    return fields?.some(
      (field) => member?.[field] && member?.[field]?.toLowerCase()?.includes(searchLowerCase),
    )
  })

  return result
}
