type AnyValue = string | number | object | any[]

export function removeRedundantItems(list: AnyValue[]): AnyValue[] {
  const uniqueValues = new Map<any, boolean>()

  const isObject = (item: AnyValue): item is object => typeof item === 'object' && item !== null

  return list.filter((item) => {
    if (isObject(item)) {
      const key = JSON.stringify(item)
      if (!uniqueValues.has(key)) {
        uniqueValues.set(key, true)
        return true
      }
    } else {
      if (!uniqueValues.has(item)) {
        uniqueValues.set(item, true)
        return true
      }
    }
    return false
  })
}

export default removeRedundantItems

export const removeDuplicateAttachments = (attachments: EnhancedUploadFile<any>[]) => {
  const uniqueValues = new Map<string, boolean>()
  return attachments.filter((attachment) => {
    if (!attachment) return false
    const key = attachment?.key || attachment?.uid
    if (!uniqueValues.has(key)) {
      uniqueValues.set(key, true)
      return true
    }
    return false
  })
}
