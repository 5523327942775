export const detectLanguage = (text: string) => {
  const firstCharCode = text?.charCodeAt(0)

  if (firstCharCode >= 0x0600 && firstCharCode <= 0x06ff) {
    return 'arabic'
  }

  if (
    (firstCharCode >= 0x0041 && firstCharCode <= 0x007a) ||
    (firstCharCode >= 0x00c0 && firstCharCode <= 0x017f)
  ) {
    return 'english'
  }
}
