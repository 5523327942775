import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { updateElapsedTime } from '@src/modules/tasks/data/timerData/slices'

export const useTimer = () => {
  const dispatch = useAppDispatch()
  const { timerStartAt, elapsedSeconds } = useAppSelector((state) => state.timer)

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (timerStartAt) {
      interval = setInterval(() => {
        dispatch(updateElapsedTime())
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timerStartAt, dispatch])

  return { elapsedSeconds }
}
