import { dateToTimeSince } from '@src/modules/shared/utils/date'
import { Avatar, Button, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import EditSVG from '@src/modules/shared/assets/icons/components/edit-2.svg'
import TrashSVG from '@src/modules/shared/assets/icons/components/trash.svg'
import { useUpdateCommentMutation } from '@src/modules/tasks/services/commentsApi'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'

type Props = {
  ref: React.Ref<HTMLDivElement>
  reply: IComment
  refetch: Function
}

function ReplyItem({ reply, ref, refetch }: Props) {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const { t } = useTranslation(['document'])
  const [isEdit, setEdit] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [editComment, { isLoading }] = useUpdateCommentMutation()
  const [replyContent, setReplyContent] = useState<any>(reply?.content || '[]')

  // const editor: BlockNoteEditor = useBlockNote({
  //   editable: false,
  //   onEditorContentChange: (editor) => {
  //     setReplyContent(JSON.stringify(editor.topLevelBlocks))
  //   },
  //   initialContent: processData(JSON.parse(replyContent)) as any,
  // })

  // useEffect(() => {
  //   editor.isEditable = isEdit
  // }, [isEdit])
  const handelSubmit = () => {
    editComment({
      commentId: reply.id || '',
      content: replyContent,
    } as any)
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        setEdit(false)
        message.success('Reply edited with success!')
      })
  }

  const handleMouseEnter = () => {
    if (user.user.id === reply.ownerId) setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      ref={ref}
      key={reply.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="comment-item reply-item"
    >
      <div className="comment-item-avatar">
        {reply.owner?.avatar ? (
          <img src={reply.owner.avatar} alt="" />
        ) : (
          <Avatar className="task-users-item">
            {reply?.owner?.fullName ? reply.owner.fullName[0] : reply.owner.email[0]}
          </Avatar>
        )}
      </div>

      <div className="comment-item-content reply-item-content">
        <div className="comment-item-content-header">
          <div className="comment-item-content-header-name">
            <Tooltip title={reply.owner.fullName}>
              {reply.owner.fullName.length > 23
                ? reply.owner.fullName.slice(0, 23) + '...'
                : reply.owner.fullName}
            </Tooltip>
            <span className="a-commenter">{t('Commented')}</span>
          </div>

          {isHovered && user.user.id === reply.ownerId && (
            <RTLStyleDecorator>
              <div className="comment-edit-delete-buttons">
                <Button onClick={() => setEdit(!isEdit)}>
                  <img src={EditSVG} />
                </Button>

                <Button
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: `delete-comment-modal`,
                        data: { id: reply.id, fetch: refetch },
                      }),
                    )
                  }}
                >
                  <img src={TrashSVG} />
                </Button>
              </div>
            </RTLStyleDecorator>
          )}

          {!isHovered && (
            <div className="comment-item-content-header-date">
              {dateToTimeSince(reply.createdAt)}
            </div>
          )}
        </div>

        <div className="comment-item-content-body">
          <BlockNoteRichText value={replyContent} setValue={setReplyContent} onlyView={!isEdit} />
        </div>

        {isEdit ? (
          <div className="save-comment" onClick={handelSubmit}>
            {isLoading ? 'Saving...' : 'Save'}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ReplyItem
