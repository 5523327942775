import reminderIcon from '../../assets/icons/notification/reminder.svg'
import { useTranslation } from 'react-i18next'

type props = {
  reminder: any
}

const Reminder = ({ reminder }: props) => {
  const { t } = useTranslation(['notifications'])

  return (
    <div className="reminder">
      <img src={reminderIcon} className="reminder-icon" />

      <div className="reminder-details">
        <span className="rem">{t('Reminder :')} </span>

        <span className="message">{reminder?.content}</span>
      </div>
    </div>
  )
}

export default Reminder
