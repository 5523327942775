import { defaultProps } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import MuxPlayer from '@mux/mux-player-react'

const VideoBlock = createReactBlockSpec(
  {
    type: 'video',
    propSchema: {
      ...defaultProps,
      src: {
        default: '',
      },
      width: {
        default: 500,
      },
      class: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: ({ block, contentRef }) => {
      const className = block.props.class || ''
      const src = block.props.src
      const style = {
        width: block.props.width,
      }

      return <MuxPlayer ref={contentRef} className={className} style={style} src={src} />
    },
    toExternalHTML: ({ contentRef }) => <MuxPlayer ref={contentRef} />,
    parse: (element: any) => {
      const src = element.src
      const width = element.width
      const className = element.class

      if (src === '' || !width) {
        return
      }

      return {
        src: src || undefined,
        width: width || '500px',
        class: className || '',
      }
    },
  },
)

export default VideoBlock
