/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'
import { BulkUpdateStatusPayload, CreateStatusPayload, CreateStatusesPayload } from './statusTypes'

export const getTemplates = createAsyncThunk('status/templates', async () => {
  try {
    const response = await axiosInstance.get(`/api/templates`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getOneTemplate = createAsyncThunk(
  'status/template',
  async (query: { name: string }) => {
    try {
      const response = await axiosInstance.get(`/api/templates/${query.name}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getStatus = createAsyncThunk('status/get-status', async () => {
  try {
    const response = await axiosInstance.get(`/api/status`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const createStatus = createAsyncThunk(
  'status/create-status',
  async (query: CreateStatusPayload) => {
    try {
      const response = await axiosInstance.post(`/api/status`, query)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message[0])
    }
  },
)

export const createStatuses = createAsyncThunk(
  'status/create-statuses',
  async (query: CreateStatusesPayload) => {
    try {
      const response = await axiosInstance.post(`/api/manyStatus`, query)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message[0])
    }
  },
)

export const deleteStatus = createAsyncThunk(
  'status/delete-status',
  async (query: { id: string }) => {
    try {
      const response = await axiosInstance.delete(`/api/status/${query.id}`)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
export const updateStatus = createAsyncThunk(
  'status/update-status',
  async (query: { id: string; data: any }) => {
    try {
      const response = await axiosInstance.patch(`/api/status/${query.id}`, query.data)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const bukUpateStatus = createAsyncThunk(
  'status/bulkupdate-status',
  async (query: { status: BulkUpdateStatusPayload[] }) => {
    try {
      const response = await axiosInstance.patch(`/api/bulkstatus`, query)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
