import { Dropdown, Tooltip } from 'antd'
import { useState } from 'react'
import PriorityList from './PriorityList'
import { priorities } from '../../../spaces/__mock__'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { useTranslation } from 'react-i18next'
interface taskPriorityProps {
  showLabel: boolean
  task?: TaskType
  disable?: boolean
  placement?: 'bottomRight' | 'bottomCenter'
  children?: any
  activePriorityCode?: number
  onSelect?: (p: any) => void
  mutpile?: boolean
  activePrioritiesIds?: number[]
}

const TaskPriority: React.FC<taskPriorityProps> = ({
  showLabel,
  activePriorityCode = null,
  disable,
  children,
  placement = 'bottomRight',
  task,
  onSelect,
  mutpile = false,
  activePrioritiesIds,
}) => {
  const { t } = useTranslation(['tasks'])
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const handleCloseAllDropdowns = () => setDropdownVisible(false)
  const activePriorities = activePrioritiesIds
    ? priorities.filter((p) => activePrioritiesIds.includes(p.id))
    : priorities.filter((p) => p.id === (+activePriorityCode || task?.priority))

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible)
  }

  return (
    <div className="task-priority" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        open={dropdownVisible}
        placement={placement}
        onOpenChange={handleDropdownVisibleChange}
        disabled={disable}
        dropdownRender={() => (
          <PriorityList
            data={{
              priorities,
              activeItemIDs: activePriorities?.map((p) => p.id),
              isLoading: false,
              task,
              onSelect,
            }}
            mutpile={mutpile}
            handleCloseAllDropdowns={handleCloseAllDropdowns}
          />
        )}
        trigger={['click']}
      >
        {children || (
          <div className="task-priority-list">
            {(activePriorities?.length > 0 ? activePriorities : [priorities[0]]).map(
              (activePriority, i) => (
                <Tooltip
                  title={
                    disable
                      ? null
                      : activePriority?.name
                        ? t(
                            `priorities.${activePriority?.name === undefined ? 'None' : activePriority?.name}`,
                          )
                        : t('list_view.select_priority')
                  }
                  key={i}
                >
                  <div className="priority-item">
                    <Flag
                      color={activePriority?.color}
                      stroke={activePriority?.color || '#B8B6B6'}
                    />
                    {showLabel && (
                      <p className="label">
                        {activePriority?.name
                          ? t(
                              `priorities.${activePriority?.name === undefined ? 'None' : activePriority?.name}`,
                            )
                          : t('priority')}
                      </p>
                    )}
                  </div>
                </Tooltip>
              ),
            )}
          </div>
        )}
      </Dropdown>
    </div>
  )
}

export default TaskPriority

export const Flag = ({ color, stroke }: { color: string; stroke?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
      <path
        d="M1 1.5V11.9286H13.7349C14.2699 11.9286 14.5374 11.9286 14.5999 11.7705C14.6624 11.6123 14.4672 11.4295 14.0767 11.0637L9.47831 6.75621C9.33225 6.61939 9.25922 6.55098 9.25922 6.46429C9.25922 6.37759 9.33225 6.30918 9.47831 6.17236L14.0767 1.86491C14.4672 1.49912 14.6624 1.31623 14.5999 1.15811C14.5374 1 14.2699 1 13.7349 1H1.5C1.2643 1 1.14645 1 1.07322 1.07322C1 1.14645 1 1.2643 1 1.5Z"
        fill={color}
      />
      <path
        d="M1 11.9286V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.7349C14.2699 1 14.5374 1 14.5999 1.15811C14.6624 1.31623 14.4672 1.49912 14.0767 1.86491L9.47831 6.17236C9.33225 6.30918 9.25922 6.37759 9.25922 6.46429C9.25922 6.55098 9.33225 6.61939 9.47831 6.75621L14.0767 11.0637C14.4672 11.4295 14.6624 11.6123 14.5999 11.7705C14.5374 11.9286 14.2699 11.9286 13.7349 11.9286H1ZM1 11.9286V18"
        stroke={stroke || color}
        strokeLinecap="round"
      />
    </svg>
  )
}
