import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../components/Header/Header'
import { useEffect } from 'react'
import Tasks from '../../tasks/features/Tasks/Tasks'
import { useNavigate, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useTitle } from '@src/modules/shared/hooks/useTitle'

const SelectList = () => {
  const { t } = useTranslation(['tasks'])
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('lastSelectedList')
    navigate('/dashboard')
  }, [])
  return <h1 className="selectlist">{t('select_list')}</h1>
}

function Spaces() {
  const { t } = useTranslation(['tasks'])

  const { listId } = useParams()
  useTitle(t('Spaces'))

  return (
    <ViewLayout header={Header}>
      {listId ? (
        <div className="spaces">
          <div className="spaces-content">
            <Tasks />
          </div>
        </div>
      ) : (
        <SelectList />
      )}
    </ViewLayout>
  )
}

export default Spaces
