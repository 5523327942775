function ActivitySVG({ fill }: { fill: string }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 12.5645H15L13 17.5645L11 7.56445L9 12.5645H7"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 12.5645C3 8.32145 3 6.20045 4.318 4.88245C5.636 3.56445 7.758 3.56445 12 3.56445C16.243 3.56445 18.364 3.56445 19.682 4.88245C21 6.20045 21 8.32245 21 12.5645C21 16.8075 21 18.9285 19.682 20.2465C18.364 21.5645 16.242 21.5645 12 21.5645C7.757 21.5645 5.636 21.5645 4.318 20.2465C3 18.9285 3 16.8065 3 12.5645Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ActivitySVG
