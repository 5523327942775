import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../store'
import { fetchUser } from '@src/modules/auth/context/AuthProvider'
import { setCurrentWorkspace } from '../utils/setCurrentWorkspace'

interface MainLayoutProps {
  children: React.ReactNode
}

const AuthGuard = ({ children }: MainLayoutProps) => {
  const dispatch = useAppDispatch()
  const { user, isAuthenticated } = useAppSelector((state) => state.auth)
  const currentWorkspace = localStorage.getItem('currentWorkspace')
  const location = useLocation()

  useEffect(() => {
    if (!currentWorkspace) {
      const ws = user?.lastWorkspaceUsed || user?.workspaces?.[0]?.workspace?.id
      setCurrentWorkspace(ws)
    }
    if (!user?.user?.roles) fetchUser()
  }, [currentWorkspace, dispatch, user])

  return isAuthenticated && user ? (
    children
  ) : (
    <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />
  )
}

export default AuthGuard
