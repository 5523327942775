import TaskActionMenu from '../../TaskActionMenu'
import { TaskType, column } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useAppSelector } from '@src/modules/shared/store'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { ListType } from '@src/modules/spaces/components/List/List'
import { fieldComponentMap, getCustomFieldValue } from '../utils/fieldComponentMap'
import { useRef } from 'react'
import { useIsInViewport } from '@src/modules/tasks/hooks/useInViewport'
import { isArray, isNumber } from 'lodash'

export type CustomFieldValue = string | number | boolean | string[]
export interface BaseFieldProps {
  saveCustomFieldValue: (value: CustomFieldValue) => void
  field: column
  task: TaskType
  customFieldValue: CustomFieldValue
}

interface listColumnsProps {
  task: TaskType
  openTaskDetails: () => void
  canOnlyView: boolean
  localUpdateTask: (key: string, value: any) => void
  list: ListType
  createCustomFieldValue: (id: string, value: CustomFieldValue) => void
  updateCustomFieldValue: (id: string, value: CustomFieldValue) => void
  deleteCustomFieldValue: (id: string) => void
}

const RenderIfVisible = (props) => {
  const { children, style } = props
  const childRef = useRef<HTMLDivElement>(null)

  const inView = useIsInViewport(childRef, null, 'list-container')

  return (
    <div ref={childRef} style={style}>
      {inView && children}
    </div>
  )
}

const ListColumns = ({
  task,
  openTaskDetails,
  canOnlyView,
  localUpdateTask,
  list,
  createCustomFieldValue,
  updateCustomFieldValue,
  deleteCustomFieldValue,
}: listColumnsProps) => {
  const { headers } = useAppSelector((state) => state.tasks)
  const [updateTask] = useUpdateTaskMutation()

  const notHiddenFields = headers?.filter((field) => !field.hidden)

  return (
    <div className="list-view-columns" onClick={openTaskDetails}>
      {notHiddenFields?.map((field, i) => {
        if (field.disable) return null
        const ComponentConfig = fieldComponentMap[field.fieldType]

        const { component: CustomComponent, props } = ComponentConfig || {}

        const componentProps = props
          ? props(field, task, canOnlyView, updateTask, localUpdateTask, openTaskDetails, list)
          : null

        const customStyle = {
          width: field.width,
          minWidth: field.minWidth,
          maxWidth: field?.maxWidth,
        }

        const customFieldValue = getCustomFieldValue(task, field)

        const saveCustomFieldValue = (value: string) => {
          if (value === undefined || customFieldValue === value) return

          const taskField = task?.customFieldsValues?.find(
            (customField) => customField.customFieldId === field.id,
          )

          if (taskField && taskField.id) {
            if (
              value === 'false' ||
              !value ||
              (isArray(value) && value?.length === 0) ||
              (isNumber(value) && value === 0)
            )
              deleteCustomFieldValue(taskField.id)
            else updateCustomFieldValue(taskField.id, value)
          } else createCustomFieldValue(field.id as string, value)
        }

        return (
          <RenderIfVisible style={customStyle} key={i}>
            <div className="list-view-column">
              {ComponentConfig ? (
                <CustomComponent
                  {...componentProps}
                  saveCustomFieldValue={saveCustomFieldValue}
                  customFieldValue={customFieldValue}
                />
              ) : (
                '--'
              )}
            </div>
          </RenderIfVisible>
        )
      })}
      <div className="list-view-column list-view-more-column">
        <TaskActionMenu data={task} disable={canOnlyView} />
      </div>
    </div>
  )
}

export default ListColumns
