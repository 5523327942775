import { Skeleton } from 'antd'
import ViewLayout from '@src/modules/shared/components/ViewLayout/ViewLayout'

const Header = () => {
  return (
    <div className="header-skeleton">
      <Skeleton.Button
        active
        style={{ width: '120px', height: '20px', borderRadius: '30px' }}
      ></Skeleton.Button>
    </div>
  )
}

const DocumentSkeleton = () => {
  return (
    <ViewLayout header={Header}>
      <div className="doc-skeleton-container">
        <div className="top">
          <Skeleton.Button
            active
            style={{ width: '105px', height: '26px', borderRadius: '30px' }}
          ></Skeleton.Button>

          <Skeleton.Button
            active
            style={{ width: '105px', height: '26px', borderRadius: '30px' }}
          ></Skeleton.Button>

          <Skeleton.Button
            active
            style={{ width: '105px', height: '26px', borderRadius: '30px' }}
          ></Skeleton.Button>
        </div>

        <Skeleton.Button
          active
          style={{ width: '201px', height: '79px', borderRadius: '30px' }}
        ></Skeleton.Button>

        <div className="bottom">
          <Skeleton.Avatar
            active
            style={{ width: '35px', height: '35px', borderRadius: '50%' }}
          ></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={{ width: '294px', height: '26px', borderRadius: '30px' }}
          ></Skeleton.Button>
        </div>
      </div>
    </ViewLayout>
  )
}

export default DocumentSkeleton
