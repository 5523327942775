import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async ({ limit, skip, search }: { limit: number; skip: number; search?: string }) => {
    try {
      const queryParams = search
        ? `?limit=${limit}&skip=${skip}&search=${search}`
        : `?limit=${limit}&skip=${skip}`
      const response = await axiosInstance.get(`api/users${queryParams}`)
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
