import { useEffect, useState } from 'react'
import TaskUsers from '../../TaskUsers'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { ISubTask } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

type Props = {
  task: ISubTask
}

function AssignUsers({ task }: Props) {
  const [updateTask] = useUpdateTaskMutation()
  const [defaultUsers, setUsers] = useState(task?.users)
  const { teams } = task

  const updateAssignedUsers = (assignedUsers) => {
    setUsers(assignedUsers)
    updateTask({
      id: task.id,
      users:
        assignedUsers?.filter((user) => user?.email !== undefined)?.map((user) => user?.id) || null,
      teams: assignedUsers.filter((user: any) => user?.name !== undefined) || null,
    })
  }

  useEffect(() => {
    setUsers(task?.users)
  }, [task?.users])

  return (
    <TaskUsers
      onClick={(e) => e.stopPropagation()}
      userNumber={3}
      displayAddButton={false}
      members={[...defaultUsers, ...teams]}
      setAssignedMembersList={updateAssignedUsers}
      assignedMembers={defaultUsers}
    />
  )
}

export default AssignUsers
