import Button from '@src/modules/shared/components/Button/Button'
import Spinner from '@src/modules/shared/components/Spinner/Spinner'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { getSpaces } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import back from '../../../../assets/icons/importExport/Back.svg'
import SpaceMapLine from './SpaceMapLine'
import { useTranslation } from 'react-i18next'

const SpaceMapping = () => {
  const { t } = useTranslation(['importExport'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [mappedSpaces, setMappedSpaces] = useState<any>({})
  const { importedDataProcess, status } = useAppSelector((state) => state.importExport)
  const importedSpaces: any =
    importedDataProcess?.spaces && Object.keys(importedDataProcess?.spaces)

  useEffect(() => {
    dispatch(getSpaces({}))
  }, [])

  if (status === 'loading') {
    return <Spinner />
  } else
    return (
      <div className="import_spaces">
        <img
          src={back}
          className="back_icon"
          onClick={() => {
            searchParams.delete('view')
            setSearchParams(searchParams)
          }}
        />

        <div className="space_mapping_heading">
          <h2>{t('space_mapping.title')}</h2>

          <p>{t('space_mapping.description')}</p>
        </div>

        <div className="spaces_select_container">
          <div className="content_line">
            <h3>{t('space_mapping.imported_spaces')}</h3>
            <h3 className="tam_spaces">{t('space_mapping.tam_spaces')}</h3>
          </div>

          <form className="spaces">
            {importedSpaces &&
              importedSpaces.map((space, index) => {
                return (
                  <SpaceMapLine
                    key={'space' + index}
                    importedSpace={space}
                    mappedSpaces={mappedSpaces}
                    setMappedSpaces={setMappedSpaces}
                  />
                )
              })}
          </form>
        </div>

        <Button
          className="next_btn"
          onClick={() =>
            navigate(
              updateURL({
                label: 'view',
                value: 'userMapping',
              }),
            )
          }
        >
          {t('space_mapping.next')}
        </Button>
      </div>
    )
}

export default SpaceMapping
