import Questions from './components/Questions/Questions'
import Teams from './components/Teams/Teams'
import { FollowUpContextProvider } from './context/FollowUpContext'

export default function FollowUp() {
  return (
    <FollowUpContextProvider>
      <div className="followup-dashboard">
        <Teams />
        <Questions />
      </div>
    </FollowUpContextProvider>
  )
}
