/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, Fragment } from 'react'
import { Routes, Route, RouteProps } from 'react-router-dom'
import pages, { filterPrivateRoutes } from './routes'
import LazyLoad from '../components/LazyLoad/LazyLoad'
import ModalsProvider from '../providers/ModalsPovider'
import { useAppSelector } from '../store'
import { AbilityContext } from '../context/permissions/Can'
import * as Sentry from '@sentry/react'
import SomthingWrong from '../components/SomthingWrong'

type RouteConfig = {
  exact: boolean | null
  path: string
  component: React.ComponentType<any>
  guard?: React.ComponentType<any> | typeof Fragment
  layout?: React.ComponentType<any> | typeof Fragment
} & RouteProps

export const renderRoutes = (routes: RouteConfig[] = []) => {
  const { permissions } = useAppSelector((state) => state.permissions)

  return (
    <AbilityContext.Provider value={permissions}>
      <Suspense fallback={<LazyLoad />}>
        <Sentry.ErrorBoundary fallback={<SomthingWrong />}>
          <Routes>
            {filterPrivateRoutes(routes, permissions).map((route: RouteConfig, index: number) => {
              const Component = route.component
              const Guard = route?.guard || Fragment
              const Layout = route?.layout || Fragment

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Guard>
                      <Layout>
                        <Component />
                      </Layout>
                    </Guard>
                  }
                />
              )
            })}
          </Routes>
        </Sentry.ErrorBoundary>
        <ModalsProvider />
      </Suspense>
    </AbilityContext.Provider>
  )
}

const routes: RouteConfig[] = [...pages]

export default routes
