import CustomCheckBox from '@src/modules/auth/components/CustomCheckBox/CustomCheckBox'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'

interface Props {
  categories: string[]
  confirmInviteUsers: any
  selectedCategories: any
  handleCategorieSelect: any
  setConfirmCategories: any
  confirmCategories: boolean
}
export const WorkspaceGategories: React.FC<Props> = ({
  categories,
  confirmInviteUsers,
  selectedCategories,
  handleCategorieSelect,
  setConfirmCategories,
  confirmCategories,
}) => {
  const { t } = useTranslation(['workspaces'])

  return (
    <>
      <h1>{t('What will you use TAM for?')}</h1>

      <div className="categorie-container">
        {categories.map((category) => (
          <CustomCheckBox
            key={category}
            disabled={confirmInviteUsers ? false : true}
            label={category}
            checked={selectedCategories.includes(category)}
            onChange={() => handleCategorieSelect(category)}
          />
        ))}
      </div>

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmCategories(!confirmCategories)}
        disabled={confirmInviteUsers ? false : true}
      />
    </>
  )
}
