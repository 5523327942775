import { Fragment, lazy } from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import GuestLayout from '@src/modules/shared/layout/GuestLayout/GuestLayout'
import WorkspaceGuard from '@src/modules/shared/guards/WorkspaceGuard'
import { PATH } from './paths'
import OAuthGuard from '@src/modules/shared/guards/OAuthGuard'

type RouteConfig = {
  exact: boolean | null
  path: string
  component: React.ComponentType<any>
  guard?: React.ComponentType<any> | typeof Fragment | any
  layout?: React.ComponentType<any> | typeof Fragment
} & RouteProps

const routes: RouteConfig[] = [
  {
    exact: true,
    path: PATH.ROOT,
    guard: OAuthGuard,
    component: () => <Navigate to="/login" />,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.LOGIN,
    component: lazy(() => import('../features/Login/Login')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.REGISTER,
    component: lazy(() => import('../features/Register/Register')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.FOGETPASSWORD,
    component: lazy(() => import('../features/ForgetPassword/ForgotPassword')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.RESETPASSWORD,
    component: lazy(() => import('../features/ResetPassword/ResetPassword')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.EMAILVERIFICATION,
    component: lazy(() => import('../features/EmailVerification/EmailVerification')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.GOOGLE,
    component: lazy(() => import('../features/GoogleAuth/GoogleAuth')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.MICROSOFT,
    component: lazy(() => import('../features/MicrosoftAuth/MicrosoftAuth')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: WorkspaceGuard,
    path: '/settings/integrations/:integrationId/:value',
    component: lazy(() => import('../features/IntegrationAuth/IntegrationAuth')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: WorkspaceGuard,
    path: PATH.WORKSPACE,
    component: lazy(() => import('../features/CreateWorkspace/CreateWorkspace')),
    layout: GuestLayout,
  },
  {
    exact: true,
    guard: OAuthGuard,
    path: PATH.WORKSPACEINVITE,
    component: lazy(() => import('../features/WorkspaceInvite/WorkspaceInvite')),
    layout: GuestLayout,
  },
]

export default routes
