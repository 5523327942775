import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import ViewLayout from '@src/modules/shared/components/ViewLayout'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Table } from 'antd'
import { formatDistanceToNow } from 'date-fns'
import { ar, fr, enUS } from 'date-fns/locale'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../components/Header'
import MemberName from '../../components/MemberName'
import MemberRole from '../../components/MemberRole'
import MembersSettings from '../../components/MembersSettings/MembersSettings'
import {
  MembersApi,
  useDeleteMutipleInvitationMutation,
  useDeleteMutipleMemberMutation,
  useGetMembersQuery,
} from '../../services/membersApi'
import { initializeData } from '../../utils/initializeData'
import { ReactComponent as DeleteSVG } from './../../assets/icons/members/delete.svg'
import { ReactComponent as SearchIcon } from './../../assets/icons/members/search.svg'
import { message } from 'antd'
import Search from '@src/modules/shared/components/Input/search'

const colors: any = {
  invited: '#0BA5EC',
}

const columns = (t, locale) => [
  {
    title: t('name'),
    key: 'name',
    render: (data: any) => <MemberName data={data} />,
    sorter: true,
  },
  {
    title: t('email'),
    key: 'email',
    className: 'member-email',
    sorter: true,
    render: (data: any) => (
      <div className="member-email">
        <p>{data.email}</p>
        {data?.isInvited ? (
          <div
            className="member-state"
            style={{ borderColor: colors[data.state], color: colors[data.state] }}
          >
            {t('invited')}
          </div>
        ) : null}
      </div>
    ),
  },
  {
    title: t('role'),
    key: 'role',
    sorter: true,
    render: (data: any) => {
      return (
        <MemberRole
          role={data?.roles[0]?.id}
          member={data}
          className="member-role-table"
          isOwner={data.roles?.find((role: any) => role.dataId.toLowerCase() === 'owner')}
        />
      )
    },
  },
  {
    title: t('last_active'),
    dataIndex: 'lastActive',
    sorter: true,
    key: 'lastActive',
    render: (data: any, e) => {
      return e.isActive
        ? t('Active now')
        : data
          ? formatDistanceToNow(data, { addSuffix: true, locale })
          : t('Never')
    },
    className: 'member-email member-last-active',
  },
  {
    title: t('settings'),
    key: 'settings',
    className: 'member-setting',
    render: (data: any) => <MembersSettings data={data} />,
  },
]

export const defaultMemmbersSort = {
  sortBy: 'name',
  sortOrder: 'ASC',
}

const Members = () => {
  const dispatch = useAppDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const [sort, setSort] = useState<{ sortOrder: string; sortBy: string } | null>(
    defaultMemmbersSort,
  )
  const { t } = useTranslation(['members'])
  const [activeGroup, setActiveGroup] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [deleteMutipleMember, { isLoading: deletetingMembers }] = useDeleteMutipleMemberMutation()
  const [deleteMutipleInvitation, { isLoading: deletetingInvitations }] =
    useDeleteMutipleInvitationMutation()

  const headerProps = { title: t('manage_members') }
  useTitle(t('Settings - Members'))

  const lang = localStorage.getItem('i18nextLng')
  const locale = lang === 'ar' ? ar : lang === 'fr' ? fr : enUS

  const antdTableLocale = {
    emptyText: t('no_data'),
  }

  const {
    data: members,
    isLoading,
    isFetching,
    refetch: refetchMembers,
  } = useGetMembersQuery({
    search: searchString,
    limit: 15,
    skip: currentPage,
    ...(sort || {}),
  })

  useEffect(() => {
    dispatch(
      MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
        const scrollerElement = document.querySelector('.ant-table')
        setCurrentPage(1)
        if (scrollerElement.scrollTop > 200) {
          scrollerElement.scrollTop = 0
        }
        if (draft?.payload) draft.payload = []
        return draft
      }),
    )
  }, [searchString, sort])

  useEffect(() => {
    refetchMembers()
  }, [])

  useEffect(() => {
    if (!members?.metadata?.last_page) return
    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      const scrollHeightTrunc = Math.trunc(scrollHeight - scrollTop)

      const bottom =
        scrollHeightTrunc === clientHeight ||
        scrollHeightTrunc + 1 === clientHeight ||
        scrollHeightTrunc - 1 === clientHeight

      if (bottom && members?.metadata?.last_page > currentPage) {
        setCurrentPage((prev) => prev + 1)
        return () => {}
      }
    }
    const scrollerElement = document.querySelector('.ant-table')
    scrollerElement.addEventListener('scroll', handleScroll)
    return () => {
      scrollerElement.removeEventListener('scroll', handleScroll)
    }
  }, [members])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMemebersHandler = () => {
    const membersIds: string[] = []
    const InvitedMembersEmails = []

    members?.payload?.forEach((member, i) => {
      const isOwner = member.roles[0].name.toLowerCase() === 'owner'
      if (selectedRowKeys?.includes(i)) {
        if (isOwner) return message.error(t('owner_cant_be_deleted'))
        member?.isInvited ? InvitedMembersEmails.push(member?.email) : membersIds.push(member?.id)
      }
    })

    deleteMutipleMember({ userIds: membersIds })
      .unwrap()
      .then(() => setSelectedRowKeys([]))

    deleteMutipleInvitation({ emails: InvitedMembersEmails })
      .unwrap()
      .then(() => setSelectedRowKeys([]))
  }

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="dashboard-members">
        <div className="dashboard-members-bar settings-bar">
          <Search
            onSearch={(s) => {
              setCurrentPage(1)
              setSearchString(s)
            }}
            size="sm"
            prefix={<SearchIcon />}
            className="input-search"
            placeholder={t('search_placeholder')}
            style={{ width: '100%', maxWidth: '320px' }}
            containerClassName="dashboard-members-bar-search"
            variant="label-inline"
          />

          {/* <div className="setting-button">
            <SearchInput
              onSearch={(s) => {
                setCurrentPage(1)
                setSearchString(s)
              }}
              prefix={<SearchIcon />}
              className="input-search"
              placeholder={t('search_placeholder')}
            />
          </div> */}
          {selectedRowKeys?.length > 0 ? (
            <RTLStyleDecorator>
              <div className="dashboard-members-global-update">
                <div className="remove-members" onClick={deleteMemebersHandler}>
                  <DeleteSVG />
                  {deletetingMembers || deletetingInvitations
                    ? t('deleting')
                    : t('remove_selected')}
                </div>
              </div>
            </RTLStyleDecorator>
          ) : null}

          <Can I="create" a="users">
            <div
              className="setting-button-b1"
              onClick={() => dispatch(openModal({ id: 'invite-modal' }))}
            >
              {t('invite')}
            </div>
          </Can>
        </div>

        <div className="dashboard-members-container">
          <div className="dashboard-members-container-groups">
            <div
              className={`group-item ${activeGroup === 1 ? 'active-group' : ''}`}
              onClick={() => setActiveGroup(1)}
            >
              {t('full_members')}{' '}
              {members?.payload?.length > 0 ? members?.metadata?.total_items : 0}
            </div>
          </div>

          <Table
            dataSource={initializeData(members?.payload)}
            columns={columns(t, locale)}
            sortDirections={['descend', 'ascend']}
            className="members-table"
            onChange={(_, filters, sorter: any) => {
              setCurrentPage(1)

              const { order } = sorter || filters || {}

              if (order) {
                setSort({
                  sortBy: sorter?.columnKey,
                  sortOrder: order === 'descend' ? 'DESC' : 'ASC',
                })
              }
            }}
            rowSelection={rowSelection}
            showSorterTooltip={true}
            pagination={false}
            loading={isLoading || isFetching}
            locale={antdTableLocale}
          />
        </div>
      </div>
    </ViewLayout>
  )
}

export default Members
