import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'

type notificationsType = {
  isRead?: boolean
  type?: string
  page?: number
  isMention?: boolean
}
export const getAllNotifications = createAsyncThunk(
  'notifications/getAll',
  async ({ isRead, type, page, isMention }: notificationsType) => {
    const API = `/api/notification?${`isRead=${isRead}`}${
      type ? `&type=${type}` : ''
    }&limit=15&skip=${page}&isMention=${isMention}
`
    try {
      const response = await axiosInstance.get(API)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getNotificationCount = createAsyncThunk(
  'notifications/getAllUserNotification',
  async () => {
    const API = `api/usernotification/count?isRead=false`
    try {
      const response = await axiosInstance.get(API)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const clearNotification = createAsyncThunk(
  'notifications/readOne',
  async (notifId: string) => {
    try {
      const response = await axiosInstance.patch(`/api/usernotification/clear/${notifId}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const clearAllNotifications = createAsyncThunk(
  'notifications/clear-all-notifications',
  async (source: string) => {
    try {
      const response = await axiosInstance.patch(`/api/usernotification/clear?source=${source}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)
