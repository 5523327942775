import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../../../shared/hooks/useDebounce'
import historyIcon from '../assets/icons/history.svg'
import DescriptionHistory from './DescriptionHistory'
import { useGetDescriptionHistoryQuery } from '@src/modules/tasks/services/notificationApi'

type Props = {
  taskId: string
  taskDescription: string
  task?: TaskType
}

function Description({ taskId, taskDescription = 'null' }: Props) {
  const { t } = useTranslation(['document'])
  const [updateTask] = useUpdateTaskMutation()
  const isFirstRender = useRef(true)

  const [description, setDescription] = useState(taskDescription)
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const [isLocalChange, setIsLocalChange] = useState(false)

  const addTaskAttachment = (file: { id: string }) => {
    if (file) updateTask({ id: taskId, attachementsId: [file?.id] })
  }
  useEffect(() => {
    isFirstRender.current = false
  }, [])

  const handleDescriptionChange = (newDescription: string) => {
    //   TODO
    // if (!taskDescription && !isFirstRender.current) {
    //   updateTask({
    //     id: taskId,
    //     description: newDescription,
    //   })

    //   return
    // }

    newDescription != description && setDescription(newDescription)
    setIsLocalChange(true)
  }
  const [restor, setRestor] = useState('')
  const [newValue, setNewValue] = useState('')
  useDebounce(
    () => {
      if (!isFirstRender.current && isLocalChange) {
        if (newValue != description) {
          updateTask({
            id: taskId,
            description: description,
          }).then((res: { data: { payload: { description: string } } }) =>
            setNewValue(res?.data?.payload?.description),
          )
          setIsLocalChange(false)
          setRestor('')
        }
      }
    },
    500,
    [description, taskId, isLocalChange],
  )
  const { data } = useGetDescriptionHistoryQuery({
    taskId,
    skip: 0,
    limit: 2,
  })
  return (
    <div className="description" style={{ flexDirection: 'row' }}>
      {data?.payload?.length > 0 && (
        <div className="show-history" onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
          <img src={historyIcon} />
          <span>{t('Show history')}</span>
        </div>
      )}

      <DescriptionHistory
        setIsHistoryOpen={setIsHistoryOpen}
        isHistoryOpen={isHistoryOpen}
        setDescription={setRestor}
      />
      <div className="blockNoteContainer">
        <BlockNoteRichText
          value={restor ? restor : description}
          setValue={handleDescriptionChange}
          focus={true}
          onUplaod={addTaskAttachment}
          key={restor}
        />
      </div>
    </div>
  )
}

export default Description
