import { clearTokens, getTokens, setTokens } from '../../auth/utils/token'
import axios from 'axios'
import { message } from 'antd'
import socketManager from '../socket/socketManager'
import { store } from '../store'

const baseURL = import.meta.env.VITE_APP_BASE_URL as string
const headers = {
  Accept: 'application/json',
  // 'Content-Type': 'apaplication/json',
}

const axiosInstance = axios.create({
  baseURL,
  headers,
  withCredentials: true,
})

export const publicAxios = axios.create({
  baseURL,
  headers,
  withCredentials: true,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const { access_token } = getTokens()
    const user = store.getState().auth.user
    const x_workspace = localStorage.getItem('currentWorkspace') || user?.lastWorkspaceUsed

    if (access_token) config.headers['Authorization'] = `Bearer ${access_token}`
    if (x_workspace) config.headers['x-workspace'] = config.headers['x-workspace'] || x_workspace
    socketManager.joinWorkSpace(x_workspace)
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)
let isRefreshing = false
let refreshSubscribers = []

const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach((callback) => callback(newAccessToken))
  refreshSubscribers = []
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 503) {
      window.location.reload()
    }
    const previousRequest = error?.config
    if (error?.response?.status === 413) {
      message.error('File too large')
    }
    if (error?.response?.status === 401 && !previousRequest?.sent) {
      previousRequest.sent = true
      const currentWorkspace = localStorage.getItem('currentWorkspace')
      if (!isRefreshing) {
        isRefreshing = true
        try {
          const response = await publicAxios.get('/auth/refresh', {
            headers: { 'x-workspace': currentWorkspace },
          })
          const { accessToken } = response.data.payload
          setTokens(accessToken)
          previousRequest.headers['Authorization'] = `Bearer ${accessToken}`
          onRefreshed(accessToken)
          return axiosInstance(previousRequest)
        } catch (err) {
          clearTokens()
          window.location.replace('/')
        } finally {
          isRefreshing = false
        }
      } else {
        return new Promise((resolve) => {
          refreshSubscribers.push((newAccessToken) => {
            previousRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
            resolve(axiosInstance(previousRequest))
          })
        })
      }
    }

    return Promise.reject(error?.response?.data || error)
  },
)

export default axiosInstance
