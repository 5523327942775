import { ReactComponent as FolderIcon } from '../assets/icons/profile/folder-add.svg'
import { ReactComponent as TaskIcon } from '../assets/icons/profile/add-square.svg'
import { ReactComponent as ListIcon } from '../assets/icons/profile/task-square.svg'

export const getActivityIcon = (activity) =>
  activity?.source === 'task' ? (
    <TaskIcon />
  ) : activity?.source === 'space' ? (
    <TaskIcon />
  ) : activity?.source === 'genericFolder' ? (
    <FolderIcon />
  ) : activity?.source === 'list' ? (
    <ListIcon />
  ) : (
    <TaskIcon />
  )
