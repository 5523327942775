import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'

const UpdatedOn = ({ elem }) => {
  return (
    <div className="deleted-on">
      <span>{reformulateDate(elem?.createdAt)}</span>
    </div>
  )
}

export default UpdatedOn
