/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateProfile, updateUserPassword, getUserActivity } from './ProfileThunk'
import { IUserProfile } from '@softylines/tam-types'

interface Profile extends IUserProfile {}

interface ProfileState {
  status: 'idle' | 'loading' | 'failed' | 'succeeded'
  profile: Profile | null
  error: string | null
  userActivity: any
  metadata: any
  fetchActivityStatus: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: ProfileState = {
  status: 'idle',
  profile: null,
  error: null,
  userActivity: [],
  metadata: null,
  fetchActivityStatus: 'idle',
}

const profileSlice = createSlice({
  name: 'workspaceSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProfile.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(updateProfile.fulfilled, (state, action: PayloadAction<any>) => {
      state.profile = action.payload.payload
      state.status = 'succeeded'
    })
    builder.addCase(updateProfile.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(updateUserPassword.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(updateUserPassword.fulfilled, (state, _action: PayloadAction<any>) => {
      state.status = 'succeeded'
    })
    builder.addCase(updateUserPassword.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getUserActivity.pending, (state) => {
      state.error = null
      state.fetchActivityStatus = 'loading'
    })
    builder.addCase(getUserActivity.fulfilled, (state, _action: PayloadAction<any>) => {
      state.fetchActivityStatus = 'succeeded'
      state.userActivity = _action.payload.result.payload.map(
        (activity) => activity?.activity || activity?.comment,
      )
      state.metadata = _action.payload.result.metadata
    })
    builder.addCase(getUserActivity.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.fetchActivityStatus = 'failed'
    })
  },
})

export const {} = profileSlice.actions // eslint-disable-line

export default profileSlice.reducer
