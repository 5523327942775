/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearTokens, setTokens } from '../utils/token'
import {
  login,
  logout,
  selectWorkspace,
  googleOauth,
  microsoftOauth,
  createWorkspace,
  invitationResponse,
  register,
  integrationOauth,
} from './authThunk'
import { notification } from 'antd'

export interface AuthState {
  status: string
  isAuthenticated: boolean
  isInitialised: boolean
  user: {
    user: any
    id: string
    firstName: string
    lastName: string
    fullName: string
    email: string
    isVerified: boolean
    avatar: string
    workspaces: any
    currentWorkspace: any
    lastWorkspaceUsed: any
    roles: any
    theme: string
  } | null
  currentWorkSpace: any
  error: string | null
}

const initialState: AuthState = {
  status: 'idle',
  isAuthenticated: false,
  isInitialised: false,
  currentWorkSpace: null,
  user: null,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user } = action.payload
      state.isAuthenticated = isAuthenticated
      state.isInitialised = true
      state.user = user
      //@ts-ignore
      removeLoader()
    },
    restore: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken, user } = action.payload.payload
      setTokens(accessToken, refreshToken)
      state.isAuthenticated = true
      state.user = user
      state.status = 'succeeded'

      document.body.id = user?.theme
      localStorage.setItem('theme', document.body.id)
    })
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(googleOauth.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(googleOauth.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken, user } = action.payload.payload
      setTokens(accessToken, refreshToken)

      state.isAuthenticated = true
      state.user = user
      state.status = 'succeeded'
    })
    builder.addCase(googleOauth.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(logout.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false
      state.user = null
      state.status = 'succeeded'
      clearTokens()
      notification.destroy()
      location.reload()
    })
    builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(selectWorkspace.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(selectWorkspace.fulfilled, (state, action: PayloadAction<any>) => {
      state.isAuthenticated = true
      const { accessToken } = action.payload.payload
      setTokens(accessToken)

      state.status = 'succeeded'
    })
    builder.addCase(selectWorkspace.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(createWorkspace.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(createWorkspace.fulfilled, (state, _action: PayloadAction<any>) => {
      state.status = 'succeeded'
    })
    builder.addCase(createWorkspace.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(invitationResponse.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(invitationResponse.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken } = action.payload.payload.tokens
      setTokens(accessToken, refreshToken)

      state.isAuthenticated = true
      state.user = action.payload.payload.user
      state.status = 'succeeded'
    })
    builder.addCase(invitationResponse.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(register.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(register.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken } = action.payload.payload
      setTokens(accessToken, refreshToken)
      state.status = 'succeeded'
    })
    builder.addCase(register.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(microsoftOauth.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(microsoftOauth.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken, user } = action.payload

      setTokens(accessToken, refreshToken)
      state.isAuthenticated = true
      state.user = user
      state.status = 'succeeded'
    })
    builder.addCase(microsoftOauth.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(integrationOauth.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(integrationOauth.fulfilled, (state, action: PayloadAction<any>) => {
      const { accessToken, refreshToken, user } = action.payload

      setTokens(accessToken, refreshToken)
      state.isAuthenticated = true
      state.user = user
      state.status = 'succeeded'
    })
    builder.addCase(integrationOauth.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const { initialise, restore } = authSlice.actions

export default authSlice.reducer
