/* import { ReactComponent as StarSVG } from '@src/modules/shared/assets/icons/components/fi-rr-star.svg'
import { ReactComponent as DownloadSVG } from '@src/modules/shared/assets/icons/components/u_arrow-from-top.svg' 
import { ReactComponent as ShareSVG } from '@src/modules/shared/assets/icons/components/fi-rr-share.svg'*/
import { ReactComponent as NextSVG } from './../assets/icons/NextAngle.svg'
import { ReactComponent as PreviousSVG } from './../assets/icons/PreviousAngle.svg'
import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import { ReactComponent as CloseSVG } from '@src/modules/shared/assets/icons/components/close-icon.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { detectLanguage } from '@src/modules/spaces/utils/detectLanguage'
import { useTranslation } from 'react-i18next'
import TaskActionMenu from '../../TaskActionMenu'
import lock from '../assets/icons/lock.svg'
import { IPath, TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { Tooltip } from 'antd'
type Props = {
  task: TaskType
  handleCancel: () => void
  isLoading: boolean
}

function ModalTitle({ task, handleCancel, isLoading }: Props) {
  const { t } = useTranslation(['taskDetails'])
  const [imageError, setImageError] = useState('')
  const navigate = useNavigate()

  const nextTask = task?.nextTask
  const previousTask = task?.previousTask

  const switchTask = (taskId: string) => navigate(updateURL({ label: 'taskId', value: taskId }))

  const goToNextTask = () => nextTask && switchTask(nextTask?.id)
  const goToPreviousTask = () => previousTask && switchTask(previousTask?.id)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.ctrlKey) {
        if (event.key === 'ArrowLeft' && previousTask) {
          goToPreviousTask()
        } else if (event.key === 'ArrowRight' && nextTask) {
          goToNextTask()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [previousTask, nextTask, goToPreviousTask, goToNextTask])

  return (
    <div className="-edit-task-popup-title">
      <div className="tasks-orders">
        <Tooltip
          title={previousTask?.name ? `${previousTask?.name}  (Shift + Ctrl + ←)` : null}
          placement="bottom"
        >
          <span onClick={goToPreviousTask} className={!previousTask && 'blocked-arrow'}>
            <PreviousSVG />
          </span>
        </Tooltip>
        <Tooltip
          title={nextTask?.name ? `${nextTask?.name}  (Shift + Ctrl + →)` : null}
          placement="bottom"
          className={!nextTask && 'blocked-arrow'}
        >
          <span onClick={goToNextTask}>
            <NextSVG />
          </span>
        </Tooltip>
      </div>

      <h1>
        {task?.path &&
          task?.path?.map((path: IPath, index: number) => (
            <div
              key={index}
              style={detectLanguage(path?.name) === 'arabic' ? { direction: 'rtl' } : {}}
              className="path"
            >
              <span className="path">
                {detectLanguage(path?.name) !== 'arabic' && index != 0 ? ' > ' : ''}
                {path?.type === 'SPACE' &&
                  (path?.icon && path.id != imageError ? (
                    <img
                      src={path?.icon}
                      style={{ width: 20, height: 20 }}
                      onError={() => setImageError(path.id)}
                    />
                  ) : (
                    <span className="first_letter">{path?.name?.slice(0, 1)}</span>
                  ))}

                {path?.type === 'SPACE' && path?.isPrivate && <img src={lock} />}

                {path?.type === 'LIST' ? <Link to={''}> {path?.name}</Link> : path?.name}

                {path?.type === 'SPACE' && path?.isPrivate && <img src={lock} />}
                {detectLanguage(path?.name) === 'arabic' ? (index !== 0 ? ' < ' : ' ') : ''}
              </span>
            </div>
          ))}
      </h1>

      <RTLStyleDecorator>
        <div className="title-infos">
          <small>
            {t('created_at')} {reformulateDate(task?.createdAt)}
          </small>

          {/* <Button>
          <StarSVG />
        </Button>

        <Button>
          <DownloadSVG />
        </Button> 

        
        <Button>
          <ShareSVG />
        </Button>*/}

          <TaskActionMenu data={task} />
          {!isLoading ? (
            <CloseSVG onClick={handleCancel} className="title-infos-close-icon" />
          ) : null}
        </div>
      </RTLStyleDecorator>
    </div>
  )
}

export default ModalTitle
