import { useSearchParams } from 'react-router-dom'

type Props = {
  title: string
  id: string
}

function ParentNavigation({ title, id }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleBack = () => {
    searchParams.set('taskId', id)
    setSearchParams(searchParams)
  }

  return (
    <div className="ParentNavigation" onClick={handleBack}>
      <div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 8L10 3L15 8"
            stroke="#374957"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3L10 11C10 16.523 14.477 21 20 21H21"
            stroke="#374957"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div>{title || 'random title'}</div>
    </div>
  )
}

export default ParentNavigation
