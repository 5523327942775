import React, { useEffect, useMemo, useState, useRef } from 'react'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchIcon } from '../../../shared/assets/icons/search.svg'

interface SearchInputProps {
  onSearch: (query: string) => void
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string
  placeholder?: string
  value?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  icon,
  disabled = false,
  value,
  ...props
}) => {
  const [query, setQuery] = useState('')
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation(['header'])
  const handleSearch = useMemo(() => {
    return (searchQuery: string) => {
      onSearch(searchQuery.replace(' ', '%20'))
    }
  }, [])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
    setQuery(value)
  }

  const debouncedResults = useMemo(() => {
    return debounce(() => handleSearch(query), 500)
  }, [handleSearch, query])

  useEffect(() => {
    debouncedResults()

    if (inputRef.current) {
      inputRef.current.focus()
    }

    return () => {
      debouncedResults.cancel()
    }
  }, [debouncedResults])

  return (
    <div className={`input-container ${props?.className}`}>
      {icon && (
        <span>
          <SearchIcon />
        </span>
      )}

      <input
        ref={inputRef}
        type="search"
        disabled={disabled}
        value={inputValue || query}
        onChange={handleChange}
        placeholder={props.placeholder || t('search')}
        {...props}
      />
    </div>
  )
}

export default SearchInput
