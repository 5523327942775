export default function RightArrow() {
  return (
    <svg width="30" height="30" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.08242 3.10206C6.20386 3.10206 6.32531 3.1468 6.42118 3.24267L10.5885 7.41003C11.2661 8.08755 11.2661 9.1997 10.5885 9.87721L6.42118 14.0446C6.23582 14.2299 5.92902 14.2299 5.74367 14.0446C5.55831 13.8592 5.55831 13.5524 5.74367 13.3671L9.91103 9.1997C10.2178 8.8929 10.2178 8.39435 9.91103 8.08755L5.74367 3.92019C5.55831 3.73483 5.55831 3.42803 5.74367 3.24267C5.83954 3.15319 5.96098 3.10206 6.08242 3.10206Z"
        fill="#7C4493"
      />
    </svg>
  )
}
