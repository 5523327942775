import { Body } from '../components/AnswersList/answersList.inteface'

export function calculateTotalDuration(objects: Body[]): string {
  let totalMinutes = 0

  if (!objects?.length) return

  for (const obj of objects) {
    const [hours, minutes] = obj.time.split(':').map(Number)
    totalMinutes += hours * 60 + minutes
  }

  const totalHours = Math.floor(totalMinutes / 60)
  const remainingMinutes = totalMinutes % 60

  return `${totalHours} hours and ${remainingMinutes} minutes`
}
