export const formatDate = (dateString: string, lang: string = 'en-US'): string[] => {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }

  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date)

  return formattedDate.split(',')
}

export const formatShortDate = (dateString: string, lang): string => {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }

  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date)

  return formattedDate
}
