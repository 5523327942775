import Input from '@src/modules/shared/components/Input/Input'
import React from 'react'

interface Props {
  options: string[]
  selected: string
  onSelect: (option: string) => void
  disabled: boolean
}

const CustomRadioButton: React.FC<Props> = ({ options, selected, onSelect, disabled }) => {
  return (
    <div className="radio-button-container">
      {options.map((option, idx) => (
        <label key={idx} className={`radio-option ${option === selected ? 'selected' : ''}`}>
          <Input
            type="radio"
            hidden={true}
            disabled={disabled}
            value={option}
            checked={option === selected}
            onChange={() => onSelect(option)}
            className="hidden-radio"
          />
          {option}
        </label>
      ))}
    </div>
  )
}

export default CustomRadioButton
