import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import clickupIcon from '../../../../assets/icons/importExport/ClickUpActive.svg'
import csvIcon from '../../../../assets/icons/importExport/csv-active.svg'

const InProgressImport = ({
  importClickUpProgressValue,
}: {
  importClickUpProgressValue: number
}) => {
  const { t } = useTranslation(['importExport'])

  return (
    <div className="in_progress_import">
      <div className="icon">
        <img src={importClickUpProgressValue ? clickupIcon : csvIcon} />
      </div>

      <div className="progress">
        <p>{t('Importing ...')}</p>

        <Progress percent={importClickUpProgressValue} showInfo={false} status="active" />
      </div>

      <p className="cancel">{t('Cancel import')}</p>
    </div>
  )
}

export default InProgressImport
