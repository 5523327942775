import { arabicMonths, enMonthsShort, frMonthsShort } from './utils/month'

type Status = {
  name: string
  color: string
  count: string
}

type DataItem = {
  category: any
  status: Status[]
  month: string
}

type SeriesData = {
  [key: string]: number[]
}

type ChartData = {
  xAxis: {
    categories: string[]
  }
  series: {
    name: string
    data: number[]
  }[]
}

export const transformDataYearlyOverview = (data: DataItem[], i18n): ChartData => {
  const seriesData: SeriesData = {}

  data?.forEach((item) => {
    const month = new Date(item.month).getMonth()
    item?.category?.forEach((status) => {
      if (!seriesData[status.name]) {
        seriesData[status.name] = Array(12).fill(0)
      }
      seriesData[status?.name][month] += parseInt(status.count, 10)
    })
  })
  const language = i18n?.language

  const series = Object.keys(seriesData).map((name) => ({
    name,
    data: seriesData[name],
    color: data
      .find((item: any) => item.category.some((s) => s.name === name))
      ?.category.find((s) => s.name === name)?.color,
  }))

  return {
    xAxis: {
      categories:
        language === 'ar' ? arabicMonths : language === 'en' ? enMonthsShort : frMonthsShort,
    },
    series,
  }
}
