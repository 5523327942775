import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DocsIcon from '../../assets/icons/document.svg'
import download from '../../assets/icons/download.svg'
import duplicate from '../../assets/icons/duplicate.svg'
import logo from '../../assets/images/sidebar/logo.svg'
import { handleDownloadPDF } from '../../utils/downloadPDF'
import useWindowSize from '../../hooks/useWindowSize'
import { IDocument } from '@softylines/tam-types'

// import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
// import { PDFDownloadLink } from '@react-pdf/renderer'
// import { PDFViewer } from '@react-pdf/renderer'

const DocsHeader = ({}: { document: IDocument }) => {
  const { t } = useTranslation(['document'])
  const { width } = useWindowSize()

  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: 'column',
  //   },

  //   image: { height: 150, width: 500 },

  //   section: {
  //     margin: 10,
  //     padding: 10,
  //     flexGrow: 1,
  //   },
  // })

  // const MyDocument = () => (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Image style={styles.image} src={document.cover} />
  //       </View>
  //       <View style={styles.section}>
  //         <Text>{document.name}</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // )

  return (
    <header className="docs-header">
      <div className="header-left">
        <div className="project-workflow">
          <img src={DocsIcon} />
          <span>{t('Project workflow')}</span>
        </div>

        <div className="duplicate">
          <img src={duplicate} />
          {width > 850 && <span>{t('Duplicate')}</span>}
        </div>

        <div className="download" onClick={() => handleDownloadPDF('shared-doc')}>
          <img src={download} />
          {width > 850 && <span>{t('Download')}</span>}
        </div>
      </div>

      <div className="header-right">
        <Link to={location.origin.replace('docs.', '')}>
          <span className="sign-in-up">{t('Sign up or Sign in')}</span>
        </Link>

        <img src={logo} className="logo" />
      </div>

      {/* <PDFDownloadLink document={<MyDocument />}>download</PDFDownloadLink> */}

      {/* <PDFViewer>
        <MyDocument />
      </PDFViewer> */}
    </header>
  )
}

export default DocsHeader
