import { editDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { store } from '@src/modules/shared/store'
import { updateList } from '@src/modules/spaces/data/fileSlice/fileThunk'
import { updateFolder } from '@src/modules/spaces/data/folderSlice/folderThunk'
import { manageFiles, setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { updateSpace } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { message } from 'antd'

export const makePublicOrPrivate = (isPrivateOrPublic: boolean, data) => {
  if (data?.type === 'folderId') {
    store
      .dispatch(updateFolder({ id: data.id, name: data?.name, isPrivate: !isPrivateOrPublic }))
      .unwrap()
      .then(() => {
        store.dispatch(
          manageFiles({
            targetId: data.id,
            id: data.spaceId || '',
            newObj: { ...data, isPrivate: !isPrivateOrPublic },
            type: 'update',
            parent: 'space',
            targetData: 'folders',
          }),
        )
        store.dispatch(
          setSpacesSocketEvent({
            updateType: 'updatedItem',
            updateEvent: { ...data, isPrivate: !isPrivateOrPublic },
          }),
        )
      })
      .catch((err) => {
        message.error(err?.message || 'Error while update a folder')
      })
  } else if (data?.type === 'spaceId') {
    store
      .dispatch(updateSpace({ id: data.id, isPrivate: !isPrivateOrPublic }))
      .unwrap()
      .then(() => {
        store.dispatch(
          setSpacesSocketEvent({
            updateType: 'updatedItem',
            updateEvent: { ...data, isPrivate: !isPrivateOrPublic },
          }),
        )
      })
  } else if (data?.type === 'documentId') {
    store
      .dispatch(editDocument({ documentId: data.id, isPrivate: !isPrivateOrPublic }))
      .unwrap()
      .then(() => {
        store.dispatch(
          setSpacesSocketEvent({
            updateType: 'updatedItem',
            updateEvent: { ...data, isPrivate: !isPrivateOrPublic },
          }),
        )
      })
  } else
    store
      .dispatch(updateList({ id: data.id, name: data?.name, isPrivate: !isPrivateOrPublic }))
      .unwrap()
      .then(() => {
        store.dispatch(
          manageFiles({
            targetId: data?.id,
            id: data?.folderId ? data.folderId : data?.spaceId,
            newObj: { ...data, isPrivate: !isPrivateOrPublic },
            type: 'update',
            parent: data?.folderId ? 'folder' : 'space',
            targetData: 'lists',
          }),
        )
        store.dispatch(
          setSpacesSocketEvent({
            updateType: 'updatedItem',
            updateEvent: { ...data, isPrivate: !isPrivateOrPublic },
          }),
        )
      })
      .catch((err) => {
        message.error(err?.message || 'Error while update a folder')
      })
}
