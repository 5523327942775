export default function DeleteIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.0644531" y="0.477173" width="66" height="66" rx="33" fill="#FFD3D0" />
      <path
        d="M48.0169 24.7221C47.9837 24.7221 47.9338 24.7221 47.884 24.7221C39.0956 23.8416 30.3239 23.5094 21.6352 24.3898L18.2462 24.7221C17.5484 24.7886 16.9337 24.2902 16.8673 23.5924C16.8008 22.8947 17.2992 22.2966 17.9804 22.2301L21.3694 21.8979C30.2076 21.0008 39.1621 21.3496 48.1332 22.2301C48.8143 22.2966 49.3127 22.9113 49.2463 23.5924C49.1964 24.2403 48.6482 24.7221 48.0169 24.7221Z"
        fill="#F04438"
      />
      <path
        d="M27.2501 23.0444C27.1837 23.0444 27.1172 23.0444 27.0342 23.0277C26.3697 22.9114 25.9045 22.2635 26.0208 21.599L26.3863 19.4227C26.6521 17.8278 27.0176 15.6183 30.8884 15.6183H35.2411C39.1285 15.6183 39.494 17.9109 39.7432 19.4393L40.1087 21.599C40.225 22.2802 39.7598 22.9281 39.0953 23.0277C38.4142 23.144 37.7663 22.6789 37.6666 22.0143L37.3011 19.8546C37.0685 18.4093 37.0187 18.1269 35.2577 18.1269H30.905C29.144 18.1269 29.1108 18.3595 28.8616 19.838L28.4795 21.9977C28.3798 22.6124 27.8482 23.0444 27.2501 23.0444Z"
        fill="#F04438"
      />
      <path
        d="M38.3976 51.3365H27.7319C21.934 51.3365 21.7014 48.1302 21.5186 45.5385L20.4388 28.8091C20.3889 28.1279 20.9206 27.5299 21.6017 27.48C22.2995 27.4468 22.8809 27.9618 22.9308 28.643L24.0106 45.3724C24.1934 47.8976 24.2598 48.8445 27.7319 48.8445H38.3976C41.8863 48.8445 41.9528 47.8976 42.1189 45.3724L43.1988 28.643C43.2486 27.9618 43.8467 27.4468 44.5278 27.48C45.209 27.5299 45.7406 28.1113 45.6907 28.8091L44.6109 45.5385C44.4281 48.1302 44.1956 51.3365 38.3976 51.3365Z"
        fill="#F04438"
      />
      <path
        d="M35.8231 42.1994H30.2909C29.6098 42.1994 29.0449 41.6345 29.0449 40.9534C29.0449 40.2722 29.6098 39.7074 30.2909 39.7074H35.8231C36.5042 39.7074 37.0691 40.2722 37.0691 40.9534C37.0691 41.6345 36.5042 42.1994 35.8231 42.1994Z"
        fill="#F04438"
      />
      <path
        d="M37.2176 35.554H28.911C28.2299 35.554 27.665 34.9891 27.665 34.308C27.665 33.6269 28.2299 33.062 28.911 33.062H37.2176C37.8987 33.062 38.4636 33.6269 38.4636 34.308C38.4636 34.9891 37.8987 35.554 37.2176 35.554Z"
        fill="#F04438"
      />
    </svg>
  )
}
