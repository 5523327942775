import { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store'
import { invitationResponse } from '@src/modules/auth/data/authThunk'
import { setCurrentWorkspace } from '../utils/setCurrentWorkspace'

interface MainLayoutProps {
  children: React.ReactNode
}

const GuestGuard = ({ children }: MainLayoutProps) => {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth)
  const [searchParams] = useSearchParams()
  const token: string | null = searchParams.get('token')
  const dispatch = useAppDispatch()
  const response = searchParams.get('decision')
  const redirectUrl: string | null = searchParams.get('redirect')

  useEffect(() => {
    if (token !== null && token !== undefined) {
      dispatch(invitationResponse({ token, response }))
        .unwrap()
        .then((data) => {
          const ws =
            data?.payload?.payload?.user?.lastWorkspaceUsed ||
            data?.payload?.payload?.user?.workspaces?.[0]?.workspace?.id
          setCurrentWorkspace(ws)
        })
    }
  }, [token, response, dispatch])

  if (redirectUrl && isAuthenticated) {
    return <Navigate to={redirectUrl} />
  } else if (isAuthenticated && user?.isVerified) {
    return <Navigate to="/workspace" />
  } else if (user?.user) {
    return <Navigate to="/spaces" />
  } else {
    return children
  }
}

export default GuestGuard
