import { ReactComponent as ManagerIcon } from '../../assets/icons/manager/manager.svg'
import { Avatar, Switch, message } from 'antd'
import { useState } from 'react'
import {
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
} from '../../services/managerQuestionsApi'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import TeamsMmebersList from './TeamsMmebersList'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'

const isValidQuestion = (question, t) => {
  const errors = []
  const { days, name, usersIds, endTime, startTime, teamId, isPrivate } = question || {}

  if (!name.trim()) {
    errors.push(t('name_is_required'))
  } else if (!teamId) {
    errors.push(t('team_id_is_required'))
  } else if (days?.length === 0) {
    errors.push(t('you_must_select_days'))
  } else if (!isValidTime(startTime?.hours, 'hours')) {
    errors.push(t('start_time_hours_is_not_valid'))
  } else if (!isValidTime(startTime?.minutes, 'minutes')) {
    errors.push(t('start_time_minutes_is_not_valid'))
  } else if (!isValidTime(endTime?.hours, 'hours')) {
    errors.push(t('end_time_hours_is_not_valid'))
  } else if (!isValidTime(endTime?.minutes, 'minutes')) {
    errors.push(t('end_time_minutes_is_not_valid'))
  } else if (isPrivate && (usersIds?.length === 0 || !usersIds)) {
    errors.push(t('you_have_to_select_users_on_private_question'))
  }

  errors?.forEach((error) => message.error(error))

  return !errors?.length
}

const reformulateQuestion = (question) => {
  const { days, name, usersIds, endTime, startTime, teamId, isPrivate, inTime, showMembers } =
    question || {}

  return {
    title: name,
    days: days?.map((day) => day?.toString()),
    usersIds,
    startTime: `${startTime?.hours}:${startTime?.minutes}`,
    endTime: `${endTime?.hours}:${endTime?.minutes}`,
    teamId,
    private: isPrivate,
    inTime,
    showMembers,
  }
}

export const renderDays = (activeDays: any, onClick?: (i: number) => void, small?: boolean) => {
  return Array(7)
    .fill('')
    .map((_, i) => {
      const newIndex = i >= 6 ? 0 : i + 1
      return (
        <div
          className={`day-item ${
            activeDays?.includes(newIndex?.toString()) || activeDays?.includes(newIndex)
              ? 'active-day'
              : ''
          }`}
          onClick={(e) => {
            e.stopPropagation()
            onClick && onClick(newIndex)
          }}
          style={small && { padding: '0px' }}
        >
          {days[i]}
        </div>
      )
    })
}

const isValidTime = (value, type) => {
  if (value === '') return true
  value = +value
  if (typeof value !== 'number' || isNaN(value)) {
    return false
  }

  return type === 'hours' ? value >= 0 && value <= 23 : value >= 0 && value <= 60
}

export const days = { 0: 'M', 1: 'T', 2: 'W', 3: 'T', 4: 'F', 5: 'S', 6: 'S' }

export const generateNonWatcher = (team) => {
  const members = []
  team?.roles?.map((role) => {
    if (role?.dataId !== 'watcherTeam') members?.push(role?.user)
  })
  return members
}
const QuestionForm = ({ data, team, removeQuestionForm }: any) => {
  const { t } = useTranslation(['dailyQuestion'])
  const [createQuestion, { isLoading: creatingLoading }] = useCreateQuestionMutation()
  const [updateQuestion, { isLoading: updatingLoading }] = useUpdateQuestionMutation()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { startTime, endTime } = data || {}
  const startTimeValues = startTime?.split(':')
  const endTimeValues = endTime?.split(':')

  const initialValues = {
    startTime: data?.isEdit
      ? { hours: startTimeValues[0], minutes: startTimeValues[1] }
      : { minutes: '00', hours: '08' },
    endTime: data?.isEdit
      ? { hours: endTimeValues[0], minutes: endTimeValues[1] }
      : { minutes: '00', hours: '17' },
    teamId: team?.id,
    isPrivate: data?.isEdit ? data?.private : false,
    usersIds: data?.isEdit ? data?.targets?.map((user: memberType) => user?.id) : null,
    name: data?.isEdit ? data?.title : '',
    days: data?.isEdit ? data?.days?.map((day: string) => +day) : [1, 2, 3, 4, 5],
    inTime: data?.isEdit ? data?.inTime : false,
    showMembers: data?.isEdit ? data?.showMembers : false,
  }

  const isLoading = creatingLoading || updatingLoading

  const [newQuestion, setQuestion] = useState<any>(initialValues)

  const setFieldValue = (field: string, value) => {
    setQuestion((prev) => ({ ...prev, [field]: value }))
  }

  const handleTimeChange = (e, type: string, valueType: string) => {
    const { value } = e.target
    if (!isValidTime(value, valueType)) {
      return
    }
    setQuestion((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [valueType]: value,
      },
    }))
  }

  const createQuestionHandler = () => {
    if (!isValidQuestion(newQuestion, t)) return
    setButtonDisabled(true)
    if (data?.isEdit) {
      updateQuestion({ id: data?.id, ...reformulateQuestion(newQuestion) })
        .unwrap()
        .catch((err) => {
          message.error(err?.message || 'Error while update question')
          setButtonDisabled(false)
        })
    } else
      createQuestion(reformulateQuestion(newQuestion))
        .unwrap()
        .catch((err) => {
          message.error(err?.message || 'Error while create question')
          setButtonDisabled(false)
        })
  }

  return (
    <div className="question-form">
      <form>
        <Input
          variant="label-inline"
          type="text"
          className="question-name"
          placeholder={t('what_question_ask')}
          value={newQuestion?.name}
          onChange={(e) => setFieldValue('name', e.target.value)}
        />
        <p className="form-label">{t('daily_on')}</p>
        <div className="form-days">
          {renderDays(newQuestion?.days, (dayIndex) =>
            setFieldValue(
              'days',
              newQuestion?.days?.includes(dayIndex)
                ? newQuestion?.days?.filter((day: number) => day !== dayIndex)
                : [...(newQuestion?.days || []), dayIndex],
            ),
          )}
        </div>
        <p className="form-label">{t('set_time_range')}</p>
        <div className="form-time-range">
          <div className="date">
            <span>{t('beginning_of_the_day')}</span>
            <div className="time">
              <div>
                <Input
                  variant="label-inline"
                  type="text"
                  placeholder="00"
                  value={newQuestion?.startTime?.hours}
                  maxLength={2}
                  onChange={(e) => handleTimeChange(e, 'startTime', 'hours')}
                />
                <span>{t('hour')}</span>
              </div>
              <div>
                <Input
                  variant="label-inline"
                  type="text"
                  placeholder="00"
                  maxLength={2}
                  value={newQuestion?.startTime?.minutes}
                  onChange={(e) => handleTimeChange(e, 'startTime', 'minutes')}
                />
                <span>{t('minute')}</span>
              </div>
            </div>
          </div>
          <div className="date">
            <span>{t('end_of_the_day')}</span>
            <div className="time">
              <div>
                <Input
                  variant="label-inline"
                  type="text"
                  placeholder="00"
                  maxLength={2}
                  value={newQuestion?.endTime?.hours}
                  onChange={(e) => handleTimeChange(e, 'endTime', 'hours')}
                />
                <span>{t('hour')}</span>
              </div>
              <div>
                <Input
                  variant="label-inline"
                  type="text"
                  placeholder="00"
                  maxLength={2}
                  value={newQuestion?.endTime?.minutes}
                  onChange={(e) => handleTimeChange(e, 'endTime', 'minutes')}
                />
                <span>{t('minute')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="option-row">
          <p className="form-label">{t('answer_only_in_time_range')}</p>
          <Switch value={newQuestion?.inTime} onChange={(e) => setFieldValue('inTime', e)} />
        </div>
        <div className="option-row">
          <p className="form-label">{t('answers_can_be_shown_to_members')}</p>
          <Switch
            value={newQuestion?.showMembers}
            onChange={(e) => setFieldValue('showMembers', e)}
          />
        </div>
        <div className="option-row">
          <p className="form-label">{t('not_for_all_the_team')}</p>
          <Switch value={newQuestion?.isPrivate} onChange={(e) => setFieldValue('isPrivate', e)} />
        </div>
        {newQuestion?.isPrivate ? (
          <>
            <p className="form-label">{t('which_member_of_the_team_you_want_to_ask')}</p>
            <div className="target-team">
              <div className="team-icon">
                <Avatar src={team?.icon} style={{ background: !team?.icon && team?.color }}>
                  {team?.name ? team?.name[0]?.toUpperCase() : '-'}
                </Avatar>
              </div>
              <div className="team-info">
                <span>{team?.name}</span>
                <span>({generateNonWatcher(team)?.length} members)</span>
              </div>
              <div className="team-role">
                <ManagerIcon />
              </div>
            </div>
            <TeamsMmebersList
              members={generateNonWatcher(team)}
              onSelect={(members) => setFieldValue('usersIds', members)}
              defaultSelected={newQuestion?.usersIds}
            />
          </>
        ) : null}
        <div className="confirmation">
          <RTLStyleDecorator>
            <div className="submit-button" onClick={removeQuestionForm}>
              {t('cancel')}
            </div>
          </RTLStyleDecorator>

          <div
            className="submit-button"
            onClick={buttonDisabled ? undefined : createQuestionHandler}
            aria-disabled={buttonDisabled}
            style={{
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              opacity: buttonDisabled ? 0.5 : 1,
            }}
          >
            {isLoading ? t('loading') : data?.isEdit ? t('update_question') : t('create_question')}
          </div>
        </div>
      </form>
    </div>
  )
}

export default QuestionForm
