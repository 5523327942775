import { Ability } from '@casl/ability'
import { createSlice } from '@reduxjs/toolkit'
import { GetPermissions } from '@src/modules/shared/context/permissions/GetPermissions'

interface PermissionsState {
  permissions: any
}

const initialState: PermissionsState = {
  permissions: new Ability(),
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions(state, { payload }) {
      const userPermissions = GetPermissions(payload)
      state.permissions = userPermissions
    },
  },
})

export const { setPermissions } = permissionsSlice.actions
export default permissionsSlice.reducer
