import React, { RefAttributes } from 'react'
import ReplyItem from './ReplyItem'

type Props = {
  replies: IComment[]
  refetch: Function
}

const Replies = React.forwardRef<HTMLDivElement, Props & RefAttributes<HTMLDivElement>>(
  (props, ref) => {
    return (
      <>
        {props.replies.length > 0 &&
          props.replies.map((reply: IComment) => (
            <ReplyItem key={reply.id} ref={ref} reply={reply} refetch={props.refetch} />
          ))}
      </>
    )
  },
)
export default Replies
