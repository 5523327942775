import { isArray } from 'lodash'

export function getItemIndex(arr): number {
  if (arr.length < 2) {
    if (arr.length === 1) {
      return 0
    } else {
      return null
    }
  }
  return arr.length - 1
}

export const parser = {
  formatFilter(filter) {
    const parsedFilter = []

    filter?.forEach((filter, index: number) => {
      let { field, operation, targetFilter, orm } = filter || {}
      field = field === 'users' ? 'assigned_to' : field
      if (!field && !operation && !targetFilter) return
      const parsedFilterObjcet = {
        [field]: {
          operation: `$${operation}`,
          value: !isArray(targetFilter) ? targetFilter : targetFilter?.map((data) => data?.id),
        },
      }
      if (orm === 'where' || orm === 'OR' || index === 0) parsedFilter.push(parsedFilterObjcet)
      else {
        const index = getItemIndex(parsedFilter)
        parsedFilter[index] = {
          ...(parsedFilter[index] || {}),
          [field]: {
            operation: `$${operation}`,
            value: !isArray(targetFilter) ? targetFilter : targetFilter?.map((data) => data?.id),
          },
        }
      }
    })
    const result = JSON.stringify({ where: parsedFilter })
    return result.replace(/\\/g, '')
  },
}
