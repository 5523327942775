import Comment from '@src/modules/shared/components/Comment/Comment'

const AssignedToMe = ({ comments }) => {
  return (
    <div className="open">
      {comments?.map((comment) => {
        return <Comment comment={comment} key={comment.commentId} />
      })}
    </div>
  )
}

export default AssignedToMe
