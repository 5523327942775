import { useGetAnswersQuery } from '@src/modules/dailyQuestions/services/memberAnswerApi'
import OneAnswer from './OneAnswer'
import { ActionsResponse } from './answersList.inteface'
import { useAppSelector } from '@src/modules/shared/store'

const AnswersList = ({ endDateQuery, dateQuery, team, currentQuestion }) => {
  const { isLoading } = useGetAnswersQuery(
    {
      teamId: team?.id,
      startDate: endDateQuery,
      endDate: dateQuery,
      questionId: currentQuestion?.id,
    },
    {
      skip: !team?.id || !currentQuestion?.id,
    },
  )

  const data = useAppSelector((state) => state.answers)
  const key = `${currentQuestion?.id}-${dateQuery}`

  return (
    <>
      {data?.answers[key]?.length > 0 &&
        !isLoading &&
        data?.answers[key]?.map((el: ActionsResponse) => {
          return (
            <OneAnswer
              answer={el}
              team={team}
              key={el?.id}
              AnswersKey={key}
              questionId={currentQuestion?.id}
            />
          )
        })}
    </>
  )
}
export default AnswersList
