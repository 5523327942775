import { ReactComponent as CloseTask } from '../../assets/icons/controlBar/close.svg'
import { ReactComponent as SelectedTask } from '../../assets/icons/controlBar/selected-task.svg'
import { ReactComponent as AssignSVG } from '../../assets/icons/controlBar/assign.svg'
import { ReactComponent as StatusSVG } from '../../assets/icons/controlBar/status.svg'
import { ReactComponent as DuplicateSVG } from '../../assets/icons/controlBar/duplicate.svg'
import { ReactComponent as MoveSVG } from '../../assets/icons/controlBar/move.svg'
import { ReactComponent as DateSVG } from '../../assets/icons/controlBar/date.svg'
import { ReactComponent as PrioritySVG } from '../../assets/icons/controlBar/priority.svg'
import { ReactComponent as DeleteSVG } from '../../assets/icons/controlBar/delete.svg'

import { ListType } from '@src/modules/spaces/components/List/List'
import SeacrhInput from '@src/modules/spaces/components/SeacrhInput'
import { getOneList } from '@src/modules/spaces/data/fileSlice/fileThunk'
import { toList } from '@src/modules/spaces/utils/toList'
import {
  setClear,
  setSelectedItems,
  setSocketEvent,
} from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import {
  useCopyTaskMutation,
  useDeleteMutipleTaskMutation,
  useMoveTaskMutation,
  useUpdateMultipleTasksMutation,
} from '@src/modules/tasks/services/tasksApi'
import { Dropdown, Spin, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from '../../store'
import SpacesTree from '../SpacesTree'
import { useLocation, useParams } from 'react-router-dom'
import { TaskStatus } from '@src/modules/tasks/components/TaskStatus'
import Calendar from '@src/modules/tasks/components/Calendar'
import TaskPriority from '@src/modules/tasks/components/TaskPriority'
import TaskUsers from '@src/modules/tasks/components/TaskUsers'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { listApi } from '@src/modules/spaces/services/fileApi'

const TreeDropdonw = ({ children, setSelectedList, open, setOpen }) => {
  const dispatch = useAppDispatch()
  const [trigger] = listApi.endpoints.getStatusesByList.useLazyQuery()
  const onSelect = (items) => {
    const id = items[0]

    trigger({ listId: id })
      .unwrap()
      .then((status) => {
        dispatch(getOneList({ id }))
          .unwrap()
          .then((res) => {
            const list = toList(res?.payload)
            setSelectedList({ ...list, status })
          })
      })
  }

  return (
    <Dropdown
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
      placement="bottomCenter"
      dropdownRender={() => {
        return (
          <div className="ant-dropdown-menu task-dropdown spaces-tree">
            <SeacrhInput onSearch={() => {}} icon={''} />
            <div className="spaces-tree-container">
              <SpacesTree readOnly onSelectList={onSelect} />
            </div>
          </div>
        )
      }}
    >
      {children}
    </Dropdown>
  )
}

const ControlBar = () => {
  const [openDublicateState, setOpenDublicateState] = useState(false)
  const [moveState, setMoveState] = useState(false)
  const { t } = useTranslation(['layout'])
  const { listId } = useParams()
  const { pathname } = useLocation()
  const [moveTask, { isLoading: isMoving }] = useMoveTaskMutation()
  const [copyTask, { isLoading: isCopying }] = useCopyTaskMutation()
  const [deleteMutiple, { isLoading: isDeleting }] = useDeleteMutipleTaskMutation()
  const [updateMultiple, { isLoading: isUpdating }] = useUpdateMultipleTasksMutation()

  const dispatch = useAppDispatch()
  const { selectedItems } = useAppSelector((state) => state.tasks)
  const totalSelectedTasks = selectedItems?.length
  const clearSelectedHandler = () => {
    dispatch(setSelectedItems([]))
    dispatch(setClear(true))
  }

  useEffect(() => {
    if (!pathname.includes('spaces')) clearSelectedHandler()
  }, [pathname])

  const moveTaskHandler = (list: ListType) => {
    moveTask({
      tasks: selectedItems?.map((taskId) => ({
        taskId,
        fileId: list?.id,
        statusId: list?.status[0]?.id,
      })),
    })
      .unwrap()
      .then(() => {
        if (listId !== list?.id)
          dispatch(setSocketEvent({ updateType: 'deletedTask', updateEvent: selectedItems }))
        setMoveState(false)
        clearSelectedHandler()
      })
  }

  const copyTaskHandler = (list: ListType) => {
    copyTask({
      tasks: selectedItems?.map((taskId) => ({
        taskId,
        fileId: list?.id,
        statusId: list?.status[0]?.id,
      })),
    })
      .unwrap()
      .then(() => {
        setOpenDublicateState(false)

        clearSelectedHandler()
      })
  }

  const deleteTasks = () => {
    deleteMutiple({ tasks: selectedItems.filter((taskId) => taskId !== 'fake-task') })
      .unwrap()
      .then(() => {
        dispatch(setSocketEvent({ updateType: 'deletedTask', updateEvent: selectedItems }))
        clearSelectedHandler()
      })
  }

  const updateTasks = (label: string, value) => {
    updateMultiple({ ids: selectedItems, payload: { [label]: value } })
      .unwrap()
      .then(() => {
        dispatch(
          setSocketEvent({
            updateType: 'updatedMany',
            updateEvent: { ids: selectedItems, payload: { [label]: value } },
          }),
        )
        if (label === 'statusId') clearSelectedHandler()
      })
  }

  return (
    <div className={`header-controls-bar ${selectedItems?.length > 0 ? '' : 'close-controls-bar'}`}>
      <div className="selected-tasks">
        <Tooltip title="Clear">
          <SelectedTask onClick={clearSelectedHandler} />
        </Tooltip>
        <span>
          {totalSelectedTasks} {t(selectedItems?.length > 1 ? 'tasks_selected' : 'task_selected')}
        </span>
      </div>
      <div className="controls-buttons">
        <TaskUsers
          placement="bottomCenter"
          userNumber={0}
          displayAddButton={false}
          members={[]}
          setAssignedMembersList={(m) =>
            updateTasks(
              'users',
              m?.map((user) => user?.id),
            )
          }
        >
          <Tooltip title={t('assign')}>
            <span>{isUpdating ? <Spin /> : <AssignSVG />}</span>
          </Tooltip>
        </TaskUsers>
        <TaskStatus
          displayLabel={false}
          displayIcon={false}
          listId={listId}
          placement="bottomCenter"
          onSelect={(status) => updateTasks('statusId', status.id)}
          activeStatus={{} as any}
        >
          <Tooltip title={t('status')}>
            <span>
              <StatusSVG />
            </span>
          </Tooltip>
        </TaskStatus>

        <TreeDropdonw setSelectedList={moveTaskHandler} setOpen={setMoveState} open={moveState}>
          <Tooltip title={isMoving ? `${t('move')}...` : t('move')}>
            <span>{isMoving ? <Spin /> : <MoveSVG />}</span>
          </Tooltip>
        </TreeDropdonw>

        <TreeDropdonw
          setSelectedList={copyTaskHandler}
          setOpen={setOpenDublicateState}
          open={openDublicateState}
        >
          <Tooltip title={isCopying ? `${t('duplicate')}...` : t('duplicate')}>
            <span>{isCopying ? <Spin /> : <DuplicateSVG />}</span>
          </Tooltip>
        </TreeDropdonw>

        <Calendar
          placement="bottomCenter"
          setStart={(date) => {
            updateTasks('startDate', date)
          }}
          setEnd={(date) => {
            updateTasks('dueDate', date)
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Tooltip title={t('date')}>
            <span>
              <DateSVG />
            </span>
          </Tooltip>
        </Calendar>
        <TaskPriority
          showLabel={false}
          placement="bottomCenter"
          onSelect={(p) => updateTasks('priority', p)}
        >
          <Tooltip title={t('priority')}>
            <span>
              <PrioritySVG />
            </span>
          </Tooltip>
        </TaskPriority>
        <Tooltip title={isDeleting ? 'Deleting...' : 'Delete All'}>
          <span onClick={deleteTasks}>{isDeleting ? <Spin /> : <DeleteSVG />}</span>
        </Tooltip>
      </div>
      <div className="unselect-tasks" onClick={clearSelectedHandler}>
        <CloseTask />
        {t('close')}
      </div>
    </div>
  )
}

export default ControlBar
