import { useEffect, useState } from 'react'
import { ReactComponent as AddStatusIcon } from './../../../assets/icons/status/add-status.svg'
import { ReactComponent as CloseSVG } from './../../../assets/icons/status/close.svg'
import { ReactComponent as SaveIcon } from './../../../assets/icons/status/save.svg'
import { colors } from '@src/modules/settings/features/Workspace/Workspace'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { createStatus, getTemplates } from '@src/modules/spaces/data/statusSlice/statusThunk'
import { ListType } from '@src/modules/spaces/components/List/List'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import { message } from 'antd'
import ColorPicker from '@src/modules/shared/components/ColorPicker'
import { useTranslation } from 'react-i18next'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'

interface AddStatusProps {
  data: ListType
}

const AddStatus = ({ data }: AddStatusProps) => {
  const { t } = useTranslation(['tasks'])
  const dispatch = useAppDispatch()
  const { refetch } = useGetListQuery({ id: data?.id }, { skip: !data.id })
  const { templates } = useAppSelector((state) => state.status)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusInfo, setStatusInfo] = useState<any>(null)

  const saveStatusInfo = (key: string, value) =>
    setStatusInfo((prev) => ({ ...prev, [key]: value }))
  const createNewStatus = () => {
    if (loading) return
    setLoading(true)
    const defaultCategoryId = templates?.payload[0]?.status[1]?.categoryId

    if (!statusInfo.name.trim() || !statusInfo.color) {
      message.error(t('board_view.Name is required'))
      setLoading(false)
      return
    }

    if (statusInfo.name.length < 3) {
      message.error(t('board_view.Name must be at least 3 characters long'))
      setLoading(false)
      return
    }
    if (data?.status.some((item) => item.name === statusInfo.name)) {
      message.error(t('board_view.Status with this name already exists'))
      setLoading(false)
      return
    }

    if (statusInfo.name.length > 50) {
      message.error(t('board_view.Name must be maximum 50 characters long'))
      setLoading(false)
      return
    }

    dispatch(
      createStatus({ ...statusInfo, categoryId: defaultCategoryId, order: 1, fileId: data.id }),
    )
      .unwrap()
      .then(() => {
        refetch()
        setStatusInfo(null)
      })
      .catch((err) => {
        message.error(err.message || t('board_view.Error while creating status'))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!templates) dispatch(getTemplates())
  }, [])

  return (
    <div className="view-board-add-status">
      {!statusInfo && (
        <div
          className="add-status-button"
          onClick={() => setStatusInfo({ color: colors.slice(5)[0], name: '' })}
        >
          <AddStatusIcon />
          {t('board_view.new_status')}
        </div>
      )}

      {statusInfo && (
        <div
          className="new-status-card"
          tabIndex={0}
          style={{ opacity: statusInfo ? 1 : 0, border: '2px solid ' + statusInfo?.color }}
        >
          <Input
            variant="label-inline"
            size="xxs"
            placeholder={t('board_view.status_name')}
            value={statusInfo?.name}
            autoFocus={true}
            onChange={(e) => saveStatusInfo('name', e.target.value)}
          />

          <RTLStyleDecorator>
            <div className="close-status-card">
              <CloseSVG onClick={() => setStatusInfo(null)} />
            </div>
          </RTLStyleDecorator>

          <div className="new-status-colors">
            {colors.slice(5).map((color, index: number) => (
              <div
                style={{
                  background: color,
                  boxShadow: statusInfo?.color === color ? `0px 0px 7px 3px ${color}` : '',
                }}
                key={index}
                onClick={() => saveStatusInfo('color', color)}
              ></div>
            ))}

            <ColorPicker
              defaultColor={statusInfo?.color}
              onPick={(color) => saveStatusInfo('color', color)}
            />
          </div>

          <Button
            className="save-button"
            onClick={createNewStatus}
            disabled={loading}
            aria-disabled={loading}
          >
            <SaveIcon />
            {loading ? t('board_view.loading') : t('board_view.save')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AddStatus
