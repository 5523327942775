import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import InviteMemberForm from '../../InviteMemberForm/InviteMemberForm'
import { useTranslation } from 'react-i18next'

const InviteMemberModal = ({ open, handleClose, id }: any) => {
  const { t } = useTranslation(['modals'])

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal invite-modal list-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('invite_people.title')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <p className="small-description-modal">{t('invite_people.invite_description')}</p>
      <div>
        <InviteMemberForm />
      </div>
    </Modal>
  )
}

export default InviteMemberModal
