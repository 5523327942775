import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { ReactComponent as TransferIcon } from '../../../assets/icons/workspace/transfer.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'

const TransferModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-moda"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('TransferModal.Transfer ownership')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div className="delete-image-container">
        <TransferIcon />
      </div>

      <p className="small-description">
        {t('TransferModal.You are about to transfer the ownership of')} “<span>{data?.name}</span>”
        {t('TransferModal.to User name? Are you sure of this action?')}
      </p>

      <div className="buttons-container">
        <Button
          children={t('TransferModal.Cancel')}
          htmlType="button"
          onClick={() => handleClose(id)}
          disabled={false}
          className="cancel-button"
        />
        <Button
          children={t('TransferModal.Transfer')}
          htmlType="submit"
          className="delete-button"
          disabled={false}
        />
      </div>
    </Modal>
  )
}

export default TransferModal
