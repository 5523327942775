import EditIcon from '@src/modules/dailyQuestions/assets/icons/member/EditIcon'
import { useAppSelector } from '@src/modules/shared/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { canAnswerQuestions } from '../../utils/canAnswerQuestions'

interface IEditAnswerButton {
  handleEdit: () => void
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
  isEdit: boolean
  answers: any
}

function EditAnswerButton({ handleEdit, setIsEdit, isEdit, answers }: IEditAnswerButton) {
  const { t } = useTranslation(['dailyQuestion'])
  //TODO : test with update At

  const { myTeams } = useAppSelector((state) => state.teams)
  const userId = useAppSelector((state) => state.auth?.user?.user?.id)
  const { teamId: selectedTeamId } = useParams()

  const hasAccess = canAnswerQuestions(myTeams, selectedTeamId, userId)

  return (
    <>
      {hasAccess && (
        <p className="edit-section">
          <p
            onClick={() => {
              handleEdit()
              setIsEdit(true)
            }}
            style={{ cursor: 'pointer' }}
            className={isEdit ? 'edit-bloc edit' : 'edit-bloc'}
          >
            <EditIcon fill={isEdit ? '#7C4493' : '#3d4f5c'} />
          </p>

          {answers?.payload?.current?.updatedAt ? (
            <span className="edited">{t('answers_tab.edited')}</span>
          ) : null}
        </p>
      )}
    </>
  )
}

export default React.memo(EditAnswerButton)
