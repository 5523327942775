import { ReactComponent as DeleteIcon } from './../../../spaces/assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as DotSVG } from '../../assets/icons/teams/dots.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/teams/edit.svg'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import deleteTeamIcon from '../../assets/icons/teams/delete_team.svg'
import { manageDropdownPermissions } from '@src/modules/spaces/utils/spaceActions'

import { Button, Dropdown, MenuProps, message } from 'antd'
import { useAppDispatch } from '@src/modules/shared/store'
import { deleteTeam, getTeams } from '../../data/teams/TeamsThunk'

interface teamProps {
  data:
    | {
        id: string
        name: string
      }
    | any
}

const TeamActionMenu: React.FC<teamProps> = ({ data }) => {
  const dispatch = useAppDispatch()
  const handleDelete = () => {
    dispatch(deleteTeam({ id: data?.id }))
      .unwrap()
      .then(() => {
        message.success('Team deleted successfully')
        dispatch(getTeams())
      })
      .catch((err) => message.error(err?.message || 'error while deleting the team'))
  }
  const items: (data: { id: string }) => MenuProps['items'] = (_data) => {
    return manageDropdownPermissions(
      [
        {
          key: '0',
          icon: <EditIcon />,
          danger: false,
          access: true,
          label: 'Edit team',
          requiredPermission: { action: 'update', resource: 'teams' },
          onClick: () => {
            dispatch(openModal({ id: 'teams-modal', data: data }))
          },
        },
        {
          key: '1',
          icon: <DeleteIcon />,
          danger: true,
          access: true,
          label: 'Delete',
          requiredPermission: { action: 'delete', resource: 'teams' },
          onClick: () => {
            dispatch(
              openModal({
                id: 'delete-team-modal',
                data: {
                  data: data,
                  handleDelete: handleDelete,
                  icon: deleteTeamIcon,
                  title: 'Delete Team',
                  subtitle: `Delete ${data?.name} Team?`,
                  description:
                    'Deleting this team will unassign all team members from associated tasks and permanently remove the team from the system. Please confirm that you want to proceed, as this action cannot be undone.',
                },
              }),
            )
          },
        },
      ],
      false,
    )
  }

  return (
    <div className={`task-item`} onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlayClassName="space-dropdown"
        trigger={['click']}
        menu={
          data
            ? {
                items: items(
                  data as {
                    id: string
                  },
                ),
              }
            : {}
        }
      >
        <div style={{ paddingRight: '15px' }}>
          <Button
            type="text"
            shape="circle"
            icon={<DotSVG />}
            disabled={
              items(
                data as {
                  id: string
                },
              )?.length > 0
                ? false
                : true
            }
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default TeamActionMenu
