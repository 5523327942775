import { Button } from 'antd'

type btnProps = {
  title: string
  className?: string
  style?: any
  htmlType?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  loading?: boolean
  icon?: string
  onClick: Function
}

const Btn = ({ title, className, disabled, icon, loading, style, onClick, htmlType }: btnProps) => {
  return (
    <Button
      className={`btn ${className}`}
      style={style}
      icon={icon}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      onClick={() => onClick()}
    >
      {title}
    </Button>
  )
}

export default Btn
