import { CreateAnswer } from '@src/modules/dailyQuestions/services/memberAnswerApi'
import { message } from 'antd'
import { Editor, ICurrentQuestion } from '../memberView.interface'

interface IUpdateAnswerResponse {
  error: {
    message: string[]
    statusCode: number
  }
}

interface IUpdateAnswerResponse {
  error: {
    message: string[]
    statusCode: number
  }
}

export const handleUpdateAnswer = async (
  answerContent: CreateAnswer,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsView: React.Dispatch<React.SetStateAction<boolean>>,
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>,
  getRes: () => void,
  udpateAnswer: (answerContent) => Promise<IUpdateAnswerResponse>,
  id: string,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  try {
    const updatePayload = {
      body: {
        parentId: answerContent?.parentId,
        body: answerContent?.body,
      },
      id,
    }
    setLoading(true)
    const response: IUpdateAnswerResponse = await udpateAnswer(updatePayload)
    if (response?.error?.statusCode === 400) {
      if (response?.error?.message) {
        response?.error?.message?.map((response: string) => {
          message.error(response)
        })
      }
    } else {
      message.success('Answer updated with success')
      setIsView(true)
      setEditors({})
      getRes()
      setIsEdit(false)
    }
  } catch (error) {
    message.error(error?.message || 'Error creating answer')
  } finally {
    setLoading(false)
  }
}

export const updateAnswerFn = (
  currentQuestion: ICurrentQuestion,
  currentResponse: Date,
  getEditorsForQuestionId: Editor[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsView: React.Dispatch<React.SetStateAction<boolean>>,
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>,
  getRes,
  udpateAnswer,
  id: string,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const body = []
  let empty: number = 0
  getEditorsForQuestionId?.map((el) => {
    const attachmentIds = el?.files?.map((el) => {
      return el?.data?.id
    })
    body?.push({
      time: el?.time,
      content: el?.content,
      attachmentIds,
    })
    if (!el?.content) {
      empty++
    }
  })
  const answerData = {
    questionId: currentQuestion ? currentQuestion.id : undefined,
    body,
    date: currentResponse,
  }
  if (!currentResponse) message.error('You Must Define The Date !')
  else if (empty !== 0) {
    message.error('Your blocs should not be empty')
  } else {
    handleUpdateAnswer(
      answerData,
      setLoading,
      setIsView,
      setEditors,
      getRes,
      udpateAnswer,
      id,
      setIsEdit,
    )
  }
}
