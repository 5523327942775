import { useState } from 'react'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { ReactComponent as CheckSVG } from '../../../assets/icons/tasks/check.svg'
import { ReactComponent as CopySVG } from '../../../assets/icons/tasks/copy.svg'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import Input from '@src/modules/shared/components/Input/Input'

const TextField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const [copied, setCopied] = useState<boolean>(false)
  const [isInput, setIsInput] = useState(false)
  const [taskFieldValue, setTaskFieldValue] = useState(
    field?.custom ? customFieldValue : task?.[field?.value],
  )

  const handleCopyClick = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(taskFieldValue)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  const fieldClickHandler = (e) => {
    if (!field.custom) return
    e.stopPropagation()
    setIsInput(true)
  }

  const focusOut = (e) => {
    if (!e.currentTarget?.contains(e.relatedTarget)) setIsInput(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value
      saveCustomFieldValue(value)
      setIsInput(false)
    } else if (e.key === 'Escape') setIsInput(false)
  }

  const Icon = copied ? CheckSVG : CopySVG

  return (
    <div
      className="list-string-field"
      onClick={fieldClickHandler}
      tabIndex={0}
      onBlur={focusOut}
      onKeyDown={handleKeyDown}
    >
      {isInput ? (
        <Input
          autoFocus
          type="text"
          defaultValue={taskFieldValue}
          value={taskFieldValue}
          variant="label-inline"
          size="xs"
          key={taskFieldValue}
          onChange={(e) => setTaskFieldValue(e.target.value)}
        />
      ) : (
        <OverflowTooltip>{taskFieldValue || '-'}</OverflowTooltip>
      )}
      {taskFieldValue && !isInput && (
        <div onClick={handleCopyClick} className="copy-button">
          <Icon width={15} height={15} />
        </div>
      )}
    </div>
  )
}
export default TextField
