import React, { useState, useEffect, useCallback } from 'react'
import { ReactComponent as TimerPlay } from '../assets/icons/timer-play.svg'
import { ReactComponent as NoteIcon } from '../assets/icons/note-icon.svg'
import { ReactComponent as TimerPause } from '../assets/icons/timer-pause.svg'

import { Avatar, Dropdown, message, Skeleton, Tooltip } from 'antd'
import { Input } from '@src/modules/shared/components/Input'
import Search from '@src/modules/shared/components/Input/search'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { closeTimerById, startTimerForTask } from '../../../data/timerData/slices'
import { formatTimerTime } from '../../Timer/utils/formateTimerTime'
import { ReactComponent as XClose } from '../assets/icons/x-close-icon.svg'
import { useTranslation } from 'react-i18next'
const TimerContent = ({
  tasks,
  handelSearch,
  search,
  setPage,
  page,
  refetch,
  tasksMetaData,
  elapsedSeconds,
}) => {
  const { currentActiveTimer, timerStartAt } = useAppSelector((state) => state.timer)

  const [isRunning, setIsRunning] = useState(!!timerStartAt)
  const [selectedTask, setSelectedTask] = useState(currentActiveTimer?.task)
  const { t } = useTranslation(['timer'])
  const dispatch = useAppDispatch()
  const [note, setNote] = useState(currentActiveTimer?.description || '')
  useEffect(() => {
    setNote(currentActiveTimer?.description || '')
    currentActiveTimer?.task && setSelectedTask(currentActiveTimer?.task)
  }, [currentActiveTimer])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const handleDropdownOpenChange = (open) => {
    setDropdownOpen(open)
  }
  const handelOpen = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (note.length < 3) {
        message.warning(t('please_enter_a_note'))
        return
      }
      dispatch(startTimerForTask(selectedTask?.id, note))
      setIsRunning(true)
    },
    [dispatch, selectedTask, note],
  )

  const handelClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(closeTimerById(currentActiveTimer?.id))
      setIsRunning(false)
      refetch()
    },
    [dispatch, currentActiveTimer],
  )

  useEffect(() => {
    if (timerStartAt) {
      setIsRunning(true)
    } else {
      setIsRunning(false)
    }
  }, [timerStartAt])
  return (
    <div className="global-timer-dropdown-content-header-tabs-content-timer">
      <Dropdown
        open={dropdownOpen}
        onOpenChange={handleDropdownOpenChange}
        dropdownRender={() => (
          <div
            className="global-timer-dropdown-content-header-tabs-content-timer-dropdown"
            onScroll={(e) => handleScroll(e, tasksMetaData?.total_items, page, 10, setPage)}
          >
            {Array.isArray(tasks) &&
              tasks?.map((item, index) => (
                <div
                  className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-item"
                  key={index}
                  onClick={() => {
                    setSelectedTask(item)
                    setDropdownOpen(false)
                  }}
                >
                  <span
                    className="global-timer-dropdown-content-body-item-content-header-icon"
                    style={{ backgroundColor: item?.status?.color || '#c5c5c5' }}
                  ></span>

                  <OverflowTooltip
                    title={item?.name}
                    className="global-timer-dropdown-content-body-item-content-header-tooltip"
                  >
                    <span>{item?.name}</span>
                  </OverflowTooltip>

                  <Avatar.Group maxCount={1}>
                    {item?.users?.map((assignee, index) => (
                      <Avatar src={assignee?.avatar} key={index}>
                        {assignee?.name?.charAt(0) || assignee?.fullName?.charAt(0)}
                      </Avatar>
                    ))}
                  </Avatar.Group>
                </div>
              ))}
            {tasksMetaData?.next && (
              <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-skeleton">
                <Skeleton.Input active />
              </div>
            )}
          </div>
        )}
        trigger={['click']}
      >
        <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search">
          <Search
            placeholder={selectedTask ? selectedTask?.name : t('name_of_the_task')}
            onChange={(e) => handelSearch(e)}
            value={selectedTask ? selectedTask?.name : search}
            prefix={
              selectedTask ? (
                <span
                  className="global-timer-dropdown-content-body-item-content-header-icon"
                  style={{ backgroundColor: selectedTask?.status?.color || '#c5c5c5' }}
                ></span>
              ) : null
            }
            suffix={selectedTask ? <XClose onClick={() => setSelectedTask(null)} /> : null}
          />
        </div>
      </Dropdown>

      <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search">
        <Input
          placeholder={t('add_a_note')}
          prefix={<NoteIcon />}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>

      <div className="global-timer-dropdown-content-header-tabs-content-timer-controls">
        <Tooltip
          title={
            !selectedTask
              ? t('please_select_a_task')
              : note?.length < 3
                ? t('please_enter_a_note')
                : ''
          }
        >
          <div>
            {isRunning ? (
              <TimerPause onClick={handelClose} />
            ) : (
              <TimerPlay
                onClick={!selectedTask || note?.length < 3 ? undefined : handelOpen}
                style={{
                  opacity: !selectedTask || note?.length < 3 ? 0.5 : 1,
                  cursor: !selectedTask || note?.length < 3 ? 'not-allowed' : 'pointer',
                }}
              />
            )}
          </div>
        </Tooltip>
        <span className="global-timer-dropdown-content-header-tabs-content-timer-display">
          {formatTimerTime(elapsedSeconds)}
        </span>
      </div>
    </div>
  )
}

export default TimerContent
