export function getMonthsBetweenDates(
  startDate: Date,
  endDate: Date,
): { name: string; number: number }[] {
  const monthsArray: { name: string; number: number }[] = []

  monthsArray.push({
    name: startDate?.toLocaleString('default', { month: 'long' }),
    number: startDate?.getMonth(),
  })

  let currentDate = new Date(startDate)
  while (currentDate < endDate) {
    currentDate.setMonth(currentDate.getMonth() + 1)
    monthsArray.push({
      name: currentDate?.toLocaleString('default', { month: 'long' }),
      number: currentDate?.getMonth(),
    })
  }

  const endMonth = endDate?.toLocaleString('default', { month: 'long' })
  if (!monthsArray.some((month) => month.name === endMonth)) {
    monthsArray.push({
      name: endMonth,
      number: endDate.getMonth(),
    })
  }

  return monthsArray
}
