import { IUser } from '@src/modules/auth/data/authTypes'
import { Avatar, Badge } from 'antd'

type props = {
  owner: IUser | any
}

const Owner = ({ owner }: props) => {
  const isActive = owner?.lastActive < new Date() ? true : false

  return (
    <div className="user_profile_img">
      {owner?.avatar ? (
        <>
          <Badge dot color={isActive ? '#12B76A' : '#FF8C22'}>
            <Avatar src={owner?.avatar}>
              {owner?.firstName && owner?.firstName[0]?.toUpperCase()}
            </Avatar>
          </Badge>
        </>
      ) : owner?.firstName ? (
        <span className="user_first_letter">{owner?.firstName?.slice(0, 1)}</span>
      ) : (
        <span className="user_first_letter">D</span>
      )}
    </div>
  )
}

export default Owner
