import { api } from '@src/modules/shared/services/api'

export const memberAnswersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuickAnswers: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/quick-answer`,
          params,
        }
      },
    }),
    createQuickAnswer: build.mutation<any, any>({
      query: (body) => ({
        url: `api/quick-answer`,
        method: 'POST',
        body,
      }),
    }),
    updateQuickAnswer: build.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `api/quick-answer/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteQuickAnswer: build.mutation<any, any>({
      query: ({ id }) => ({
        url: `api/quick-answer/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetQuickAnswersQuery,
  useCreateQuickAnswerMutation,
  useUpdateQuickAnswerMutation,
  useDeleteQuickAnswerMutation,
} = memberAnswersApi
