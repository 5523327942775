function MenuSVG() {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.564453" y="0.662598" width="18.4355" height="18.4355" rx="2" fill="#E4E7EC" />
      <path
        d="M9.49388 9.59186H10.07M9.49388 10.168H10.07M4.11687 9.59186H4.69297M4.11687 10.168H4.69297M14.8709 9.59186H15.447M14.8709 10.168H15.447M10.5503 9.87996C10.5503 10.3042 10.2064 10.6481 9.78212 10.6481C9.35788 10.6481 9.01397 10.3042 9.01397 9.87996C9.01397 9.45573 9.35788 9.11182 9.78212 9.11182C10.2064 9.11182 10.5503 9.45573 10.5503 9.87996ZM5.17325 9.87996C5.17325 10.3042 4.82934 10.6481 4.40511 10.6481C3.98087 10.6481 3.63696 10.3042 3.63696 9.87996C3.63696 9.45573 3.98087 9.11182 4.40511 9.11182C4.82934 9.11182 5.17325 9.45573 5.17325 9.87996ZM15.9273 9.87996C15.9273 10.3042 15.5834 10.6481 15.1591 10.6481C14.7349 10.6481 14.391 10.3042 14.391 9.87996C14.391 9.45573 14.7349 9.11182 15.1591 9.11182C15.5834 9.11182 15.9273 9.45573 15.9273 9.87996Z"
        stroke="#374957"
        strokeWidth="1.53629"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MenuSVG
