import { SVGProps } from 'react'

type Props = {}

function NoteSVG(props: SVGProps<SVGSVGElement & Props>) {
  return (
    <svg
      {...props}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1360_27752)">
        <path
          d="M5.62514 3.20837C5.62514 3.37413 5.5593 3.5331 5.44209 3.65031C5.32488 3.76752 5.1659 3.83337 5.00014 3.83337C4.83438 3.83337 4.67541 3.76752 4.5582 3.65031C4.44099 3.5331 4.37514 3.37413 4.37514 3.20837C4.37514 3.04261 4.44099 2.88364 4.5582 2.76643C4.67541 2.64922 4.83438 2.58337 5.00014 2.58337C5.1659 2.58337 5.32488 2.64922 5.44209 2.76643C5.5593 2.88364 5.62514 3.04261 5.62514 3.20837ZM10.0001 8.4167V5.64171C10.0157 4.35731 9.54477 3.11458 8.68194 2.16303C7.81912 1.21148 6.62826 0.621554 5.34848 0.511705C4.63417 0.461729 3.91745 0.565763 3.24683 0.816768C2.5762 1.06777 1.96731 1.45989 1.46136 1.9666C0.955404 2.47331 0.564186 3.08277 0.314175 3.75377C0.0641643 4.42477 -0.0388073 5.14163 0.0122276 5.85587C0.196394 8.50337 2.53431 10.5 5.45139 10.5H7.91681C8.46914 10.4994 8.99866 10.2797 9.38922 9.88911C9.77978 9.49856 9.99948 8.96904 10.0001 8.4167ZM5.29181 1.34337C6.36117 1.43797 7.35511 1.93375 8.07393 2.7311C8.79275 3.52845 9.1832 4.5683 9.16681 5.64171V8.4167C9.16681 8.74823 9.03512 9.06617 8.8007 9.30059C8.56627 9.53501 8.24833 9.66671 7.91681 9.66671H5.45139C2.93723 9.66671 1.00014 8.04171 0.843894 5.79837C0.802874 5.22695 0.880134 4.6532 1.07085 4.11298C1.26156 3.57276 1.56162 3.07768 1.9523 2.65865C2.34297 2.23963 2.81586 1.90567 3.34142 1.67764C3.86698 1.44961 4.43392 1.33242 5.00681 1.33337C5.10139 1.33337 5.19681 1.33712 5.29181 1.34337ZM5.83348 8.00004V5.50004C5.83348 5.27902 5.74568 5.06706 5.5894 4.91078C5.43312 4.7545 5.22116 4.66671 5.00014 4.66671H4.58348C4.47297 4.66671 4.36699 4.7106 4.28885 4.78874C4.21071 4.86688 4.16681 4.97287 4.16681 5.08337C4.16681 5.19388 4.21071 5.29986 4.28885 5.378C4.36699 5.45614 4.47297 5.50004 4.58348 5.50004H5.00014V8.00004C5.00014 8.11055 5.04404 8.21653 5.12218 8.29467C5.20032 8.37281 5.3063 8.4167 5.41681 8.4167C5.52732 8.4167 5.6333 8.37281 5.71144 8.29467C5.78958 8.21653 5.83348 8.11055 5.83348 8.00004Z"
          fill="#6A859A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1360_27752">
          <rect width="10" height="10" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NoteSVG
