import { FC, useMemo } from 'react'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar as ReactBigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { replaceAMPMWithArabic } from '@src/modules/shared/utils/replaceAMPMWithArabic'
import { arTN } from 'date-fns/locale'
import { format, getDay, parse, startOfWeek } from 'date-fns'
interface DndCalendarProps {
  events: any
  date: any
  onNavigate: any
  onEventDrop: any
  onEventResize: any
  components: any
  onSelectSlot: any
  onSelectEvent: any
  className?: string
}

const lang = {
  fr: {
    week: 'La semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    month: 'Mois',
    previous: 'Antérieur',
    next: 'Prochain',
    today: `Aujourd'hui`,
    agenda: 'Ordre du jour',

    showMore: (total) => `+${total} plus`,
  },
  ar: {
    week: 'أسبوع',
    work_week: 'أسبوع العمل',
    day: 'يوم',
    month: 'شهر',
    previous: 'سابق',
    next: 'التالي',
    today: 'اليوم',
    agenda: 'جدول أعمال',

    showMore: (total) => `+${total} إضافي`,
  },
}

function eventStyleGetter(event) {
  const { task } = event
  var style = {
    backgroundColor: '#FFE9D1',
    border: `0.5px solid ${task?.status?.color}`,
    color: '#374957',
    borderRadius: '5px',
  }
  return {
    style,
  }
}

const DndCalendar: FC<DndCalendarProps> = (props) => {
  const { i18n } = useTranslation([''])
  const { formats } = useMemo(
    () => ({
      formats: {
        timeGutterFormat: (date, culture, localizer) =>
          replaceAMPMWithArabic(localizer.format(date, 'hh:mm a', culture), i18n.language),
      },
    }),
    [],
  )

  const momentCurrent = moment()

  const Calendar = withDragAndDrop(ReactBigCalendar)

  return (
    <Calendar
      culture={i18n.language}
      defaultView="day"
      localizer={localizer}
      eventPropGetter={eventStyleGetter}
      formats={formats}
      resizable
      scrollToTime={momentCurrent
        .set({
          h: momentCurrent.hours() - 1,
          m: momentCurrent.minutes(),
        })
        .toDate()}
      step={30}
      messages={lang[i18n.language]}
      selectable
      rtl={i18n.language === 'ar'}
      views={['day']}
      {...props}
    />
  )
}

const locales = {
  'ar-tn': arTN,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export default DndCalendar
