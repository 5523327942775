export const PATH = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  WORKSPACE: '/workspace',
  FOGETPASSWORD: '/forget-password',
  RESETPASSWORD: '/reset-password/:id',
  EMAILVERIFICATION: '/verify-email',
  GOOGLE: '/google/oauth',
  MICROSOFT: '/auth/azure',
  WORKSPACEINVITE: '/workspace/invitation',
}
