import { useState } from 'react'
import { Divider, Dropdown } from 'antd'
import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { ReactComponent as NoColorIcon } from '@src/modules/customFields/assets/no-color.svg'
import { ReactComponent as CloseIcon } from '@src/modules/customFields/assets/close-icon.svg'
import Input from '@src/modules/shared/components/Input/Input'
import { colors } from '../../CustomDrawer/CustomDrawer'
import { useTranslation } from 'react-i18next'

function DropDown({ formik }) {
  const { t } = useTranslation(['customFields'])
  const [error, setError] = useState(false)
  const OptionImage = ({ color, id, disabled = false }) => {
    const [openDropDown, setOpenDropDown] = useState(false)
    const [selectedColor, setSelectedColor] = useState(color)

    const handleSelectedColor = (color) => {
      formik.setFieldValue(
        'values.options',
        formik?.values?.values?.options?.map((option) => {
          if (option?.orderIndex === id) {
            return { ...option, color }
          }
          return option
        }),
      )
    }
    const handleOpenChange = (visible: boolean) => {
      setOpenDropDown(visible ? id : false)
    }

    return (
      <span>
        <Dropdown
          open={disabled ? false : openDropDown === id}
          onOpenChange={handleOpenChange}
          overlayClassName="options-color-overlay"
          trigger={['click']}
          dropdownRender={() => (
            <>
              <span>{t('color')}</span>
              <Divider className="divider" />
              <ColorSelect
                colors={colors}
                setSelectedColor={(e) => {
                  setSelectedColor(e)
                  handleSelectedColor(e)
                }}
                selectedColor={selectedColor}
              />
            </>
          )}
          className="options-color-select"
        >
          {!selectedColor ? (
            <NoColorIcon className="options-color-select-icon" />
          ) : (
            <div className="selected-color" style={{ backgroundColor: selectedColor }}></div>
          )}
        </Dropdown>
      </span>
    )
  }

  const handleDeleteOption = (id) => {
    const updatedOptions = formik.values.values.options
      .filter((item) => item.orderIndex !== id)
      .map((item, index) => ({ ...item, orderIndex: index }))

    formik.setFieldValue('values.options', updatedOptions)
  }

  const handleAddOption = () => {
    const currentOptions = formik?.values?.values?.options ?? []

    if (currentOptions.length) {
      const lastOption = currentOptions[currentOptions.length - 1]

      if (!lastOption.value?.trim()) {
        setError(true)
        return
      }
    }
    setError(false)
    formik.setFieldValue('values', {
      default: 0,
      placeholder: formik?.values?.values?.options?.[0]?.value || '',
      options: [
        ...currentOptions,
        {
          value: '',
          color: '',
          orderIndex: currentOptions.length || 0,
        },
      ],
    })
  }

  return (
    <>
      <div className="options-container">
        <span className="options-container-label">{t('options')}</span>
        {formik?.values?.values?.options?.map((option, index) => (
          <div key={index}>
            <Input
              name={`values.options.${index}.value`}
              prefix={<OptionImage color={option?.color} id={option?.orderIndex} />}
              placeholder={t('option_name')}
              formik={formik}
              value={option.value}
              variant="filled"
              suffix={
                <span
                  className="options-container-input-close"
                  onClick={() => handleDeleteOption(option?.orderIndex)}
                >
                  <CloseIcon />
                </span>
              }
              required={false}
              disabled={false}
              error={error && !option.value.trim() ? t('option_error_message') : null}
            />
          </div>
        ))}
      </div>
      <div className="add-option" onClick={handleAddOption}>
        + {t('add_option')}
      </div>
    </>
  )
}

export default DropDown
