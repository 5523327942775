type YearOption = {
  value: number
  label: string
}

export const generateYears = (): YearOption[] => {
  const currentYear = new Date().getFullYear()
  const endYear = currentYear + 10
  const years: YearOption[] = []

  for (let year = 2021; year <= endYear; year++) {
    years.push({
      value: year,
      label: year.toString(),
    })
  }

  return years
}
