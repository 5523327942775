import { Avatar, Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { ReactComponent as InvitedIcon } from '../../../assets/icons/invite/invited.svg'
import { UserOutlined } from '@ant-design/icons'
import Button from '@src/modules/shared/components/Button/Button'
import { useAppSelector } from '@src/modules/shared/store'
import { useTranslation } from 'react-i18next'

const InvitedUsersTags = (worksapce: string = 'Tam') => ({
  0: { color: '#F51F45', label: 'Already Invited' },
  1: { color: '#1cc04b', label: 'New in ' + worksapce },
  2: { color: '#05B7ED', label: 'Already exist in ' + worksapce },
  3: { color: '#1cc04b', label: 'Invitation sent' },
})

const InvitedUsersModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])
  const { user } = useAppSelector((state) => state?.auth)
  const workSpace = user?.currentWorkspace?.workspace?.name
  let newReformatData = { ...data?.response }
  const result = newReformatData?.sendTo?.map((item) => item?.email)
  newReformatData.sendTo = result
  const getEmailTag = (email: string) => {
    const groupMap = {
      alreadyInvited: 0,
      sendTo: 1,
      alreadyInWorkspace: 2,
      error: 4,
    }
    let group = null
    Object?.keys(newReformatData)?.forEach((groupKey) => {
      if (newReformatData[groupKey]?.includes(email)) {
        group = groupMap[groupKey]
      }
    })
    return group !== null ? InvitedUsersTags(workSpace)[group] : null
  }

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal invite-modal list-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('InvitedUsersModal.Invite people')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div>
        <div className="invite-modal-party">
          <InvitedIcon />
          <p>
            {t('InvitedUsersModal.You invite')} {data?.emails?.length || 0}{' '}
            {t('InvitedUsersModal.people to your workspace')}
          </p>
        </div>

        <div className="invite-modal-user-list">
          {data?.emails?.map((email: string, i: number) => {
            const { color = '', label = 'Error' } = getEmailTag(email) || {}

            return (
              <div className="user-item" key={i}>
                <Avatar icon={<UserOutlined />} />
                <p>{email}</p>

                <div className="invited-user-tag" style={{ color, borderColor: color }}>
                  {label}
                </div>
              </div>
            )
          })}
        </div>

        <div className="action_form">
          <Button onClick={() => handleClose(id)}>{t('InvitedUsersModal.OK')}</Button>
        </div>
      </div>
    </Modal>
  )
}
export default InvitedUsersModal
