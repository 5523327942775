import { useState, useEffect, useRef } from 'react'
import { Avatar } from '@src/modules/shared/components/Avatar'
import { ReactComponent as DeleteIcon } from '../../assets/icons/teams/trash.svg'
import { ReactComponent as DeleteTagIcon } from '../../assets/icons/teams/delete_tag.svg'
import { Row, Skeleton, Spin } from 'antd'
import { Empty } from 'antd'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'
import { useGetMembersTeamsQuery } from '../../services/membersApi'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import Input from '@src/modules/shared/components/Input/Input'

const UserSelectFilter = ({ selectedUser, setSelectedUser, users }) => {
  const { t } = useTranslation(['teams'])
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [tagInputValue, setTagInputValue] = useState('')
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const wrapperRef = useRef(null)
  const handleChange = (event) => {
    setTagInputValue(event.target.value)
    setIsDropdownVisible(true)
  }
  useDebounce(
    async () => {
      setSearchValue(tagInputValue)
      setPage(1)
    },
    500,
    [tagInputValue],
  )
  const { data: memberTeams, isFetching } = useGetMembersTeamsQuery({
    search: searchValue,
    limit: 20,
    skip: page,
  })
  const mergeAndRemoveDuplicates = (array1, array2) => {
    const formatData = array1?.payload?.filter((item) => {
      return !array2?.some((it) => it?.id === item?.id)
    })
    return formatData
  }
  const data = mergeAndRemoveDuplicates(memberTeams, users)

  const renderTags = () => {
    const userCount = selectedUser?.length
    const displayCount = userCount > 2 ? 2 : userCount
    const remainingCount = userCount - displayCount

    return (
      <>
        {selectedUser?.slice(0, displayCount).map((user, index) => {
          return (
            <div key={index} className="user-tag">
              <Avatar
                text={user?.fullName || user?.email || 'user'}
                src={user?.avatar}
                color={user?.color || '#A6A6A6'}
                size={'small'}
              />
              <span className="user-tag-name-container">{user?.fullName || user?.email}</span>

              <Button
                className="user-tag-button"
                onClick={() => handleDeleteUser(user)}
                style={{ marginTop: '4px', marginRight: '2px' }}
              >
                <DeleteTagIcon />
              </Button>
            </div>
          )
        })}
        {remainingCount > 0 && <span className="remaining-users">+{remainingCount}</span>}
      </>
    )
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsDropdownVisible(false)
    }
  }

  const handleDeleteUser = (userToDelete) => {
    setSelectedUser(selectedUser.filter((user) => user !== userToDelete))
  }
  const addUser = (user) => {
    if (!selectedUser.some((selected) => selected.id === user.id)) {
      setSelectedUser([...selectedUser, user])
      setTagInputValue('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={wrapperRef} className="">
      <div className="input-and-tags-container">
        {renderTags()}
        <Input
          className="add-user-input"
          placeholder={t('Select members')}
          value={tagInputValue}
          onChange={handleChange}
          onClick={() => setIsDropdownVisible(true)}
          variant="label-inline"
          size="xs"
        />
      </div>
      {isDropdownVisible && (
        <div
          className="user-list-container"
          onScroll={(e) => handleScroll(e, memberTeams.metadata?.total_items, page, 20, setPage)}
        >
          {isFetching && searchValue !== '' ? (
            <div className="spiner-container">
              <Spin />
            </div>
          ) : data?.length > 0 ? (
            <>
              {data?.map((user, index: number) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    key={index}
                  >
                    <div className="user-option" key={index} onClick={() => addUser(user)}>
                      <Avatar
                        text={user?.fullName || user?.email || 'user'}
                        src={user?.avatar}
                        color={user?.color || '#A6A6A6'}
                        size={'small'}
                      />
                      <Tooltip title={user?.fullName} placement="topLeft">
                        <span>
                          {user?.fullName?.length > 13
                            ? user?.fullName?.slice(0, 13)
                            : user?.fullName}
                        </span>
                      </Tooltip>
                      <Tooltip title={user?.email} placement="topLeft">
                        <span className="user-email">
                          {user?.email?.length > 25 ? user?.email?.slice(0, 25) : user?.email}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="user-list">
                      {selectedUser.map(
                        (selected, index) =>
                          selected.id === user.id && (
                            <div className="selected-user" key={index}>
                              <Button onClick={() => handleDeleteUser(selected)}>
                                <DeleteIcon />
                              </Button>
                            </div>
                          ),
                      )}
                    </div>
                  </div>
                )
              })}
              {memberTeams?.metadata?.total_items != memberTeams?.payload?.length && (
                <LoadingInfintyScrollSkeleton />
              )}
            </>
          ) : (
            <Empty description={<span>No users found!</span>} />
          )}
        </div>
      )}
    </div>
  )
}

export default UserSelectFilter
const LoadingInfintyScrollSkeleton = () => {
  return (
    <div className="user-list-skeleton">
      <Row justify="start" align="middle">
        <Skeleton.Avatar size={'small'} />
        <Skeleton.Input active size="small" style={{ width: 310, height: 20 }} />
      </Row>
      <Row justify="start" align="middle">
        <Skeleton.Avatar size={'small'} />

        <Skeleton.Input active size="small" style={{ width: 310, height: 20 }} />
      </Row>
    </div>
  )
}
