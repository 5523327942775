import ViewTitle from '@src/modules/shared/components/ViewTitle'
import { ReactComponent as DashbaordSVG } from '../../assets/icons/dashboard/dashboard.svg'
import { useTranslation } from 'react-i18next'
// import { ReactComponent as AngleSVG } from '../../assets/icons/dashboard/angle.svg'
// import { Switch } from 'antd'

interface HeaderProps {
  onAllowChange: (a: boolean) => void
}

const Header: React.FC<HeaderProps> = ({}) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <ViewTitle>
      <div className="dashbaord-header">
        <div className="dashbaord-header-left">
          <DashbaordSVG /> {t('dashboard')}
        </div>

        <div className="dashbaord-header-right">
          <div className="customization-button">
            {/* <label htmlFor="customization">
              <Switch
                size={'small'}
                id="customization"
                defaultChecked
                onChange={(checked) => onAllowChange(checked)}
              />
              Customize layout
            </label> */}
          </div>
          {/* <div className="manage-button">
            Manage Sections
            <AngleSVG />
          </div> */}
        </div>
      </div>
    </ViewTitle>
  )
}

export default Header
