import ar from '../../locales/ar'
import en from '../../locales/en'
import fr from '../../locales/fr'
import { IUser } from '../auth/data/authTypes'

const lang = localStorage.getItem('i18nextLng')
const data = lang === 'ar' ? ar : lang === 'fr' ? fr : en

export const fovorities = [
  {
    name: 'Jocelyn Jennings hello world',
    color: null,
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '1CF9267A-786B-D627-AA29-D5F1B6F66AFB',
  },
  {
    name: 'Giselle Farmer',
    color: '#f9ddbb',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '9941D2FA-7E7C-5360-C7B2-C3B99C533CBA',
  },
  {
    name: 'Herrod Webb',
    color: '#9cf296',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'D7436AAA-3C79-9ED4-1723-EEA4A11D27EB',
  },
  {
    name: 'Griffith Hawkins',
    color: '#bfff60',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '5928BA6D-DB2F-685E-F978-3AD76D4C7292',
  },
  {
    name: 'Zahir Shepard',
    color: '#74edcc',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '61B2A66A-5CC6-BD63-B31D-52182E31B06C',
  },
]

export const share = [
  {
    name: 'Micah Charles',
    color: '#cc3ad1',
    icon: null,
    id: '76025CE2-AA12-96AB-1697-3962EE76667F',
  },
  {
    name: 'Ima Wood',
    color: '#b2ffbb',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '9CD68DB6-7C14-CFC4-1037-F92E79E43BBF',
  },
  {
    name: 'Vaughan Prince',
    color: '#4fa014',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'D32ACF91-E313-3619-8663-435BC15D0298',
  },
  {
    name: 'Tallulah Velez',
    color: '#ddd235',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '61518061-F8A9-E343-D8B7-D1FED37F833A',
  },
  {
    name: 'Quentin Mcdowell',
    color: '#ff77c6',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'B40D3E7C-24CD-956A-1863-AA31F1E8E268',
  },
]

export const projects = [
  {
    name: 'Farrah Pratt',
    color: '#c7adea',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'E14C8754-896A-C413-9DED-D37984B173DE',
  },
  {
    name: 'Tatum Kelley',
    color: '#4ae8e8',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'B9791639-824D-BAA8-EE47-F4ECF349D6E9',
  },
  {
    name: 'Bree Mills',
    color: '#8c0937',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'EDD08B21-8A65-D978-7080-CC34C1BA1E30',
  },
  {
    name: 'Audra Franks',
    color: '#fca36c',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '9C3E428B-3109-A1C7-8421-5BE746ABF485',
  },
  {
    name: 'Briar Thompson',
    color: '#56cbef',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '449737E7-8C30-DE8F-34D5-817C9BD789E1',
  },
]

export const archive = [
  {
    name: 'Maggy Mack',
    color: '#93bcea',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: 'BA97A774-753D-F90B-3C64-D101AAAF9D9C',
  },
  {
    name: 'Micah Brock',
    color: '#0340ba',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '50CBB856-C7A0-58EC-11D7-72395E10A051',
  },
  {
    name: 'Chiquita Holden',
    color: '#2e97a5',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '4C828329-22B8-A806-580A-AB5D91F8EA5B',
  },
  {
    name: 'Tobias Yang',
    color: '#24b773',
    icon: null,
    id: 'FD2B3498-1CBB-3814-BDB7-2181BEA6C7E4',
  },
  {
    name: "McKenzie O'connor",
    color: '#55d6a9',
    icon: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    id: '3EB62E7D-EF59-E2B5-D533-C1DD01487E47',
  },
]

export const folders = [
  { id: 1, name: 'folder', icon: '' },
  { id: 2, name: 'folder1', icon: '' },
  { id: 3, name: 'folder2', icon: '' },
]

export const lists = [
  {
    name: 'Aristotle Tate',
    id: '9F23EBA8-D62B-289D-1D0D-AB7D762C01DB',
    icon: 'https://cnn.com/group/9?p=8',
  },
  {
    name: 'Angelica Pearson',
    id: '5AB6E5B8-C113-7B5B-AAB8-F943C3CF9EDA',
    icon: 'https://cnn.com/site?ab=441&aad=2',
  },
  {
    name: 'Eaton Heath',
    id: '37C25C31-7EA7-A174-2292-FA5846E1F155',
    icon: 'http://ebay.com/sub?q=test',
  },
  {
    name: 'Mollie Welch',
    id: '01E91099-F828-B7A2-9C88-91131EE3CCC4',
    icon: 'http://nytimes.com/sub/cars?k=0',
  },
  {
    name: 'Hector Hickman',
    id: '6CBA3EA3-4AD6-0D8B-9EBC-BCF25FA9203F',
    icon: 'http://reddit.com/fr?gi=100',
  },
]

export const status = [
  { id: 1, name: 'New Request', color: '#C5C5C5', nb_tasks: 2 },
  { id: 2, name: 'In Progress', color: '#FFA948', nb_tasks: 12 },
  { id: 3, name: 'Done', color: '#3DD455', nb_tasks: 9 },
  { id: 4, name: 'Rejected', color: '#F51F45', nb_tasks: 8 },
  { id: 5, name: 'Blocked', color: '#0BA5EC', nb_tasks: 90 },
]

export const tasks = [
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 4,
    statusId: 1,

    name: 'Write Blog Post',
    description: 'Compose an engaging and informative blog post on a relevant topic.',
    nb_subtasks: 1,
    due_date: '11/05/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 15,
  },
  {
    id: 5,
    statusId: 1,

    name: 'Organize Product Launch Event',
    description: 'Coordinate and plan a successful product launch event for a new product.',
    nb_subtasks: 5,
    due_date: '11/30/2023',
    nb_attachment: 4,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 28,
  },
  {
    id: 6,
    statusId: 1,

    name: 'Develop Mobile App Prototype',
    description: 'Design and create a prototype for a mobile application with key features.',
    nb_subtasks: 3,
    due_date: '10/25/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 20,
  },
  {
    id: 7,
    statusId: 1,

    name: 'Plan Company Retreat',
    description: 'Plan and organize a company retreat to boost employee morale and teamwork.',
    nb_subtasks: 4,
    due_date: '12/10/2023',
    nb_attachment: 3,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 18,
  },
  {
    id: 8,
    statusId: 1,

    name: 'Create Marketing Campaign',
    description: 'Develop a comprehensive marketing campaign to promote a new product.',
    nb_subtasks: 4,
    due_date: '11/20/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 22,
  },
  {
    id: 9,
    statusId: 1,

    name: 'Revise Employee Handbook',
    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 2,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    statusId: 1,

    name: 'Design Website Redesign',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    statusId: 3,

    name: 'Launch Social Media Ad Campaign',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    statusId: 1,

    name: 'Conduct Customer Feedback Survey',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    statusId: 5,

    name: 'Organize Training Workshop',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
]

export const subtasks = [
  {
    id: 9,
    name: 'subtask 1',

    description:
      "Update and revise the company's employee handbook with current policies and procedures.",
    nb_subtasks: 1,
    due_date: '10/15/2023',
    nb_attachment: 1,
    thumbnail: null,
    nb_comments: 9,
  },
  {
    id: 10,
    name: 'subtask 1',
    description:
      'Redesign the company website to improve user experience and modernize the design.',
    nb_subtasks: 3,
    due_date: '12/05/2023',
    nb_attachment: 2,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 14,
  },
  {
    id: 11,
    name: 'subtask 1',
    description:
      'Plan, create, and launch a targeted social media advertising campaign for the company.',
    nb_subtasks: 2,
    due_date: '11/18/2023',
    nb_attachment: 3,
    thumbnail: null,
    nb_comments: 17,
  },
  {
    id: 12,
    name: 'subtask 1',
    description:
      'Create and distribute a customer feedback survey to gather insights and improve services.',
    nb_subtasks: 1,
    due_date: '10/30/2023',
    nb_attachment: 1,
    thumbnail: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    nb_comments: 11,
  },
  {
    id: 13,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 14,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 15,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 16,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 17,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 18,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 19,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 20,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 21,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 22,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 23,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 24,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 25,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 26,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 27,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 28,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 29,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 30,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 31,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 32,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 33,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 34,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 35,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 36,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 37,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 38,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 39,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 40,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 43,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 44,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 45,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 46,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 47,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 48,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 49,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 28585,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 258852,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 282585,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 258582,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 258582,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
  {
    id: 285528285,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },

  {
    id: 800,
    name: 'subtask 1',
    description:
      'Organize and facilitate a training workshop for employees to enhance their skills.',
    nb_subtasks: 3,
    due_date: '12/15/2023',
    nb_attachment: 2,
    thumbnail: null,
    nb_comments: 13,
  },
]

export const users = [
  {
    firstName: 'John',
    lastName: 'Doe',
    email: 'johndoe@example.com',
    username: 'johndoe123',
    fullName: 'John Doe',
    workspaces: [
      {
        id: '1234567890abcdef',
        name: 'Workspace1',
        icon: 'https://example.com/workspace1.png',
        color: '#FF5733',
        theme: '#C1B332',
      },
      {
        id: 'abcdef1234567890',
        name: 'Workspace2',
        icon: 'https://example.com/workspace2.jpg',
        color: '#33FF57',
        theme: '#A6A6A6',
      },
    ],
    lastWorkspaceUsed: '1234567890abcdef',
    centralId: 'central12345',
    id: 'user1-uuid-1234',
    jobTitle: 'Software Engineer',
    roles: [
      {
        id: 'role1-uuid-1234',
        name: 'developer',
        permissions: [
          {
            id: 'perm1-uuid-1234',
            name: 'full access user permission',
            actions: ['CREATE', 'UPDATE', 'DELETE', 'READ'],
            resourceName: 'Projects',
          },
        ],
        icon: 'https://example.com/role-icon.svg',
        description: 'developer role',
        level: 1,
      },
    ],
    currentWorkspace: {
      id: '1234567890abcdef',
      name: 'Workspace1',
      icon: 'https://example.com/workspace1.png',
      color: '#FF5733',
      theme: '#C1B332',
    },
  },
  {
    firstName: 'Alice',
    lastName: 'Smith',
    email: 'alicesmith@example.com',
    username: 'alicesmith456',
    fullName: 'Alice Smith',
    workspaces: [
      {
        id: 'abcdef1234567890',
        name: 'Workspace2',
        icon: 'https://example.com/workspace2.jpg',
        color: '#33FF57',
        theme: '#A6A6A6',
      },
    ],
    lastWorkspaceUsed: 'abcdef1234567890',
    centralId: 'central67890',
    id: 'user2-uuid-5678',
    jobTitle: 'Project Manager',
    roles: [
      {
        id: 'role2-uuid-5678',
        name: 'manager',
        permissions: [
          {
            id: 'perm2-uuid-5678',
            name: 'project management permission',
            actions: ['CREATE', 'UPDATE', 'READ'],
            resourceName: 'Tasks',
          },
        ],
        icon: 'https://example.com/manager-icon.svg',
        description: 'manager role',
        level: 2,
      },
    ],
    currentWorkspace: {
      id: 'abcdef1234567890',
      name: 'Workspace2',
      icon: 'https://example.com/workspace2.jpg',
      color: '#33FF57',
      theme: '#A6A6A6',
    },
  },
] as IUser[]

export const members = [
  {
    firstName: 'Alice',
    lastName: 'Smith',
    email: 'alicesmith@example.com',
    username: 'alicesmith456',
    fullName: 'Alice Smith',
    workspaces: [
      {
        id: 'abcdef1234567890',
        name: 'Workspace2',
        icon: 'https://example.com/workspace2.jpg',
        color: '#33FF57',
        theme: '#A6A6A6',
      },
    ],
    lastWorkspaceUsed: 'abcdef1234567890',
    centralId: 'central67890',
    id: 'user2-uuid-5678',
    jobTitle: 'Project Manager',
    roles: [
      {
        id: 'role2-uuid-5678',
        name: 'manager',
        permissions: [
          {
            id: 'perm2-uuid-5678',
            name: 'project management permission',
            actions: ['CREATE', 'UPDATE', 'READ'],
            resourceName: 'Tasks',
          },
        ],
        icon: 'https://example.com/manager-icon.svg',
        description: 'manager role',
        level: 2,
      },
    ],
    currentWorkspace: {
      id: 'abcdef1234567890',
      name: 'Workspace2',
      icon: 'https://example.com/workspace2.jpg',
      color: '#33FF57',
      theme: '#A6A6A6',
    },
  },
] as IUser[]

export const statuses = [
  {
    name: 'open',
    id: '1',
    color: '#FFA948',
  },
  {
    name: 'closed',
    id: '2',
    color: '#d1e9ff',
  },
  {
    name: 'in progress',
    id: 3,
    color: '#d1ffe9',
  },
  {
    name: 'pending',
    id: 4,
    color: '#ffecd1',
  },
  {
    name: 'resolved',
    id: 5,
    color: '#d1ffec',
  },
  {
    name: 'on-hold',
    id: 6,
    color: '#ffd1e9',
  },
  {
    name: 'cancelled',
    id: 7,
    color: '#ffc2c2',
  },
  {
    name: 'archived',
    id: 8,
    color: '#e2e2e2',
  },
]

export const task_timer = [
  {
    userName: 'user 1',
    task: 41,
    time: 14512,
  },
  {
    userName: 'user 2',
    task: 41,
    time: 500,
  },
]

export const priorities: any = [
  {
    name: data.tasks.priorities.None,
    id: null,
    color: null,
  },
  {
    name: data.tasks.priorities.Urgent,
    id: 1,
    color: '#F04438',
  },
  {
    name: data.tasks.priorities.High,
    id: 3,
    color: '#FFB700',
  },
  {
    name: data.tasks.priorities.Normal,
    id: 2,
    color: '#0BA5EC',
  },

  {
    name: data.tasks.priorities.Low,
    id: 4,
    color: '#B8B6B6',
  },
]
