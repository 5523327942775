const allowedFieldsTypes = [
  'priority',
  'status',
  'users',
  'checkbox',
  'label',
  'website',
  'dropdown',
  'rating',
]

export const getFieldsToGroupBy = (header) => {
  return header?.filter((item) => !item?.hidden && allowedFieldsTypes.includes(item.fieldType))
}
