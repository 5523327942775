import Button from '@src/modules/shared/components/Button/Button'
import { useAppSelector } from '@src/modules/shared/store'
import {
  useAddCommnetMutation,
  useUpdateCommentMutation,
} from '@src/modules/tasks/services/commentsApi'
import { useGetHistoryQuery } from '@src/modules/tasks/services/notificationApi'
import { message, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import CommentInput from '../CommentInput'
import TaskActivitiesSkeleton from '../TaskDetailSkeleton/TaskActivitiesSkeleton'
import ActivityItem from './ActivityItem'
import CommentItem from './CommentItem'
import Replies from './Replies'

const filterActivity = (activity) => {
  const { action, oldValue, newValue } = activity
  if (action !== 'update') return true

  const fields = Object.keys(newValue)
  const newTask = {}
  fields.forEach((field) => {
    newTask[field] = oldValue[field]
  })

  return JSON.stringify(newTask) !== JSON.stringify(newValue)
}

function Comments() {
  const { t } = useTranslation(['taskDetails'])
  const [comment, setComment] = useState<string>('')
  const [comments, setComments] = useState<IComment[]>([])
  const [searchparams, _] = useSearchParams()
  const taskId = searchparams.get('taskId')
  const [addComment] = useAddCommnetMutation()
  const [editComment] = useUpdateCommentMutation()
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(5)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { selectedUsersIds } = useAppSelector((state: any) => state.editor)
  const { commentToEdit } = useAppSelector((state) => state.comment)

  const bottomRef = useRef(null)

  const { data: history, refetch, isLoading } = useGetHistoryQuery({ taskId, limit })
  const { metadata: historyMetadata, payload: historyPayload } = history || {
    payload: {},
    metadata: {},
  }
  useEffect(() => {
    if (historyPayload.length > 0) {
      setComments([...historyPayload.map((item: INotification) => item.comment)])
    }
  }, [historyPayload])
  const [reset, setReset] = useState(false)
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [historyPayload])

  const loadMore = () => {
    if (!isLoading && historyMetadata?.current_page < historyMetadata?.last_page) {
      setLimit((prev) => prev + 10)
    }
  }

  const handleSubmitComment = () => {
    setIsSubmitting(true)
    const data = {
      taskId: taskId || '',
      parentId: null,
      content: comment,
    }
    const ids = selectedUsersIds.filter((id) => id !== null && id !== undefined)

    if (ids.length > 0) {
      data['mentionIds'] = ids
    }

    if (!commentToEdit) {
      setLoading(true)
      addComment(data)
        .then(() => {
          refetch()
          setReset((prev) => !prev)
        })
        .catch((err) => {
          message.error(err.message)
        })
        .finally(() => {
          setIsSubmitting(false)
          setLoading(false)
        })
    } else {
      editComment({
        commentId: commentToEdit.id || '',
        content: comment,
      } as any)
        .then(() => {
          refetch()
          setReset(true)
        })
        .catch((err) => {
          message.error(err.message)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }
  return (
    <div className="comment-side">
      <div className="comment-title">{t('activity')}</div>
      <hr className="comment-title-hr" />

      {isLoading ? (
        <TaskActivitiesSkeleton />
      ) : (
        <>
          <div className="comment-side-wrapper">
            {historyPayload &&
              Array.isArray(historyPayload) &&
              historyPayload
                ?.map((notification: INotification, index: number) => {
                  if (
                    notification?.type === 'comment' &&
                    notification?.comment?.id &&
                    !notification?.comment?.parentId &&
                    notification.comment
                  ) {
                    return (
                      <CommentItem
                        comments={comments}
                        key={notification?.comment?.content + index}
                        comment={notification?.comment}
                        setComments={setComments}
                        refetch={refetch}
                        owner={notification?.owner}
                      >
                        <Replies
                          key={`reply-id-${notification?.comment?.id}`}
                          replies={notification?.comment?.replies || []}
                          refetch={refetch}
                        />
                      </CommentItem>
                    )
                  } else if (notification?.type === 'activity') {
                    return (
                      filterActivity(notification?.activity) && (
                        <ActivityItem
                          key={notification?.activity?.id}
                          activity={notification?.activity}
                        />
                      )
                    )
                  }
                })
                .reverse()}
            <div ref={bottomRef}></div>

            {isLoading && (
              <Spin
                style={{
                  marginInline: 'auto',
                }}
              />
            )}

            {historyMetadata?.current_page < historyMetadata?.last_page && (
              <Button onClick={() => loadMore()} className="comment-load-more">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.71661 3.49472C5.65803 3.55282 5.61154 3.62195 5.5798 3.69811C5.54807 3.77427 5.53174 3.85596 5.53174 3.93847C5.53174 4.02098 5.54807 4.10267 5.5798 4.17883C5.61154 4.25499 5.65803 4.32412 5.71661 4.38222L8.57911 7.24472C8.63769 7.30282 8.68418 7.37195 8.71591 7.44811C8.74764 7.52427 8.76398 7.60596 8.76398 7.68847C8.76398 7.77098 8.74764 7.85267 8.71591 7.92883C8.68418 8.00499 8.63769 8.07412 8.57911 8.13222L5.71661 10.9947C5.65803 11.0528 5.61154 11.1219 5.5798 11.1981C5.54807 11.2743 5.53174 11.356 5.53174 11.4385C5.53174 11.521 5.54807 11.6027 5.5798 11.6788C5.61154 11.755 5.65803 11.8241 5.71661 11.8822C5.83371 11.9986 5.99212 12.064 6.15724 12.064C6.32235 12.064 6.48076 11.9986 6.59786 11.8822L9.4666 9.01347C9.81773 8.66191 10.015 8.18535 10.015 7.68847C10.015 7.19159 9.81773 6.71503 9.4666 6.36347L6.59786 3.49472C6.48076 3.37831 6.32235 3.31297 6.15724 3.31297C5.99212 3.31297 5.83371 3.37831 5.71661 3.49472Z"
                    fill="#6D8496"
                  />
                </svg>
                <span>{t('load_more')}</span>
              </Button>
            )}

            <div ref={bottomRef}></div>
          </div>
        </>
      )}

      <CommentInput
        holderId="comment-input"
        comment={comment}
        setComment={setComment}
        reset={reset}
        setReset={setReset}
        onSubmit={handleSubmitComment}
        loading={loading}
        disabled={isSubmitting}
      />
    </div>
  )
}

export default Comments
