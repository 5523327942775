function DetailsSVG({ fill }: { fill: string }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2.56445H8C4.691 2.56445 2 5.25545 2 8.56445V21.5645C2 21.8297 2.10536 22.084 2.29289 22.2716C2.48043 22.4591 2.73478 22.5645 3 22.5645H16C19.309 22.5645 22 19.8735 22 16.5645V8.56445C22 5.25545 19.309 2.56445 16 2.56445ZM20.5 16.5645C20.5 18.7705 18.206 21.0645 16 21.0645H3.5V8.56445C3.5 6.35845 5.794 4.06445 8 4.06445H16C18.206 4.06445 20.5 6.35845 20.5 8.56445V16.5645Z"
        fill={fill}
      />
      <path
        d="M7 10.5645C7 10.0122 7.44772 9.56445 8 9.56445H16C16.5523 9.56445 17 10.0122 17 10.5645C17 11.1167 16.5523 11.5645 16 11.5645H8C7.44772 11.5645 7 11.1167 7 10.5645ZM7 14.5645C7 14.0122 7.44772 13.5645 8 13.5645H13C13.5523 13.5645 14 14.0122 14 14.5645C14 15.1167 13.5523 15.5645 13 15.5645H8C7.44772 15.5645 7 15.1167 7 14.5645Z"
        fill={fill}
      />
    </svg>
  )
}

export default DetailsSVG
