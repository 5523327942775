import { Avatar } from 'antd'

const User = ({ elem }) => {
  return (
    <div className="user-col">
      <Avatar src={elem?.owner?.avatar}>{elem?.owner?.fullName[0]}</Avatar>
      <span className="user-col-name">{elem?.owner?.fullName}</span>
    </div>
  )
}

export default User
