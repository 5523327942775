import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import type { Dayjs } from 'dayjs'
import dayLocaleData from 'dayjs/plugin/localeData'
import { Calendar, Select, Dropdown } from 'antd'
import MonthSelector from './MonthSelector/MonthSelector'
import DateSelector from './DataShortCut'
import { ReactComponent as CalendarIcon } from '../../assets/icons/task/calendar-add.svg'
import { DateInput } from '@src/modules/shared/components/DateInput'
import { ReactComponent as DateIcon } from '../../assets/icons/task/calendar.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/task/Clock.svg'
import { ReactComponent as GrayCalendar } from '../../assets/icons/task/grayCalendar.svg'
import { useTranslation } from 'react-i18next'
// import { message } from 'antd'

import TimeSelector from './TimseSelect/TimeSelect'
import Button from '@src/modules/shared/components/Button/Button'
import { useAppSelector } from '@src/modules/shared/store'
import { formatDateToMultiLang } from '../../utils/formateDateToMultiLang'

dayjs.extend(dayLocaleData)
interface CalendarProps {
  handleDateChange: (date: dayjs.Dayjs) => void
  handleCalendarDateChange: (date: dayjs.Dayjs) => void
  setSelectDate: (date: string) => void
  selectDate: string
  startDate: any
  endDate: any
  setStartDate: (date: dayjs.Dayjs) => void
  setEndDate: (date: dayjs.Dayjs) => void
  className?: string
  isTrash?: boolean
}
const CustomCalendar: React.FC<CalendarProps> = ({
  handleDateChange,
  handleCalendarDateChange,
  setSelectDate,
  selectDate,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  className,
  isTrash,
}) => {
  const wrapperStyle: React.CSSProperties = {}

  const { t } = useTranslation(['global'])

  return (
    <div className={`full-calendar-container ${className} `}>
      <div className="shortcut-container">
        <DateSelector
          onChange={handleDateChange}
          setSelectDate={setSelectDate as any}
          dateType={'startDate'}
          selectedDate={startDate}
          isSelected={selectDate === 'startDate'}
        />
      </div>

      <div style={wrapperStyle} className="calendar-container">
        <Calendar
          fullscreen={false}
          defaultValue={null}
          value={selectDate === 'startDate' ? startDate : endDate}
          onChange={(date) => {
            handleCalendarDateChange(date)
          }}
          headerRender={({ value, onChange }) => {
            const start = 0
            const end = 12
            const monthOptions = []
            let current = value?.clone()
            const localeData = value?.localeData()
            const months = []
            for (let i = 0; i < 12; i++) {
              current = current?.month(i)
              months.push(localeData?.monthsShort(current))
            }
            for (let i = start; i < end; i++) {
              monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                  {months[i]}
                </Select.Option>,
              )
            }
            const year = value?.year()
            const options = []
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              )
            }

            return (
              <div className="calendar">
                <div>
                  <DateInput
                    placeholder={t('Due Date')}
                    selectedDate={endDate}
                    Icon={<CalendarIcon />}
                    setSelectDate={setSelectDate}
                    dateType={'endDate'}
                    isSelected={selectDate === 'endDate'}
                  />
                </div>

                <div className="navigation-container">
                  <MonthSelector value={value} onChange={onChange} />

                  <Button onClick={() => onChange(dayjs())} className="today-button">
                    {t('Today')}
                  </Button>
                </div>
              </div>
            )
          }}
        />

        {!isTrash && (
          <div className="select-time-container">
            <div className="clock-contianer">
              <ClockIcon /> <span>{t('Hour')}</span>
            </div>

            <TimeSelector
              onTimeChange={(hour, minute) => {
                if (selectDate === 'startDate') {
                  handleCalendarDateChange(startDate?.hour(hour).minute(minute))
                  setStartDate(startDate.hour(hour).minute(minute))
                } else {
                  handleCalendarDateChange(endDate?.hour(hour).minute(minute))
                  setEndDate(endDate.hour(hour).minute(minute))
                }
              }}
              hours={selectDate === 'startDate' ? startDate?.hour() : endDate?.hour()}
              minutes={selectDate === 'startDate' ? startDate?.minute() : endDate?.minute()}
            />
          </div>
        )}
      </div>
    </div>
  )
}

interface CalendarDropDownProps {
  setStart?: (d: Dayjs) => void
  setEnd?: (d: Dayjs) => void
  defStart?: string | Dayjs
  defEnd?: string | Dayjs
  disable?: boolean
  children?: any
  placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
  onClick?: (e: any) => void
  isInTaskList?: boolean
  isEditTaskPopup?: boolean
  isTrash?: boolean
}
// !After using the calendar you need to set start and end date to null with setStart and setEnd
const CalendarDropDown = ({
  defStart,
  defEnd,
  setStart,
  disable = false,
  setEnd,
  onClick,
  children,
  placement = 'bottomLeft',
  isInTaskList,
  isEditTaskPopup,
  isTrash,
}: CalendarDropDownProps) => {
  const { t } = useTranslation(['trash'])

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Dayjs>()
  const [endDate, setEndDate] = useState<Dayjs>()
  const [selectDate, setSelectDate] = useState('endDate')
  const isExpired = dayjs().isAfter(dayjs(endDate), 'day')

  const {
    user: { user },
  } = useAppSelector((state) => state?.auth)
  if (user?.lang === 'ar') placement = 'bottomRight'

  const handleCalendarDateChange = (date: Dayjs) => {
    if (selectDate === 'startDate') {
      setStart?.(date)
      setStartDate(date)
    } else {
      setEnd?.(date)
      setEndDate(date)
    }
  }

  const handleDateChange = (newDate: Dayjs) => {
    if (selectDate === 'startDate') {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible)
  }

  useEffect(() => {
    if (defStart) setStartDate(dayjs(defStart))
    if (typeof defEnd === 'string') setEndDate(dayjs(defEnd))
  }, [defStart, defEnd])

  return (
    <div onClick={onClick}>
      <Dropdown
        placement={placement}
        open={dropdownVisible}
        disabled={disable}
        onOpenChange={handleDropdownVisibleChange}
        dropdownRender={() => (
          <CustomCalendar
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleCalendarDateChange={handleCalendarDateChange}
            setSelectDate={setSelectDate}
            selectDate={selectDate}
            className={isTrash && 'trash-calendar'}
            isTrash={isTrash}
          />
        )}
        trigger={['click']}
      >
        {isTrash ? (
          <span className="filter_item">{t('Deleted on')}</span>
        ) : (
          children || (
            <button
              className={`calendar-button ${isExpired ? 'expired-date' : ''} ${isInTaskList ? 'garyIcon' : ''}`}
            >
              {endDate ? null : isInTaskList ? <GrayCalendar /> : <DateIcon />}

              {endDate !== undefined ? (
                isEditTaskPopup ? (
                  <>
                    <DateIcon /> {formatDateToMultiLang(endDate, user?.lang, 'D MMM')}
                  </>
                ) : (
                  formatDateToMultiLang(endDate, user?.lang, 'D MMM')
                )
              ) : !isInTaskList ? (
                t('Add Date')
              ) : (
                ''
              )}
            </button>
          )
        )}
      </Dropdown>
    </div>
  )
}

export default CalendarDropDown
