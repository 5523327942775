import Bar from './Bar'
import { IYearlyOverview } from '../yearly.interface'
import { generateMonthlyData } from '../utils/generateMonthlyData'
import { useTranslation } from 'react-i18next'
interface IBarsVersion {
  data: IYearlyOverview[]
  height?: number
}

export default function BarsVersion({ data, height = 210 }: IBarsVersion) {
  const { t, i18n } = useTranslation(['months'])

  return (
    <div className="bars-chart" style={{ height: `${height}px` }}>
      <div className="devider"></div>
      {data &&
        generateMonthlyData(data)?.map((el) => {
          return (
            <>
              <div className="bars-chart_element">
                <div className="bars-chart_element_bars">
                  {el?.status?.map((stats, index: number) => {
                    return <Bar key={index} stats={stats} />
                  })}
                  {el?.status?.length === 0 && (
                    <div
                      className="bars-chart_element_bar"
                      style={{
                        height: `100%`,
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                    ></div>
                  )}
                </div>
                <span className="bars-chart_element_month" lang={i18n?.language}>
                  {t(el.month)}
                </span>
              </div>
              <div className="devider"></div>
            </>
          )
        })}
    </div>
  )
}
