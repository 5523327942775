import { FolderIcon, folderActions } from '@src/modules/spaces/components/Folder/Folder'
import { AddListItems, PermissionesAction, items } from '@src/modules/spaces/utils/spaceActions'
import { ReactComponent as LockIcon } from './../../../../spaces/assets/icons/space/lock.svg'
import { ReactComponent as PlusIcon } from './../../../../spaces/assets/icons/folder/plus.svg'
import { ReactComponent as SettingsIcon } from './../../../../spaces/assets/icons/folder/settings.svg'
import { Dropdown, message } from 'antd'
import { sidebarItemIDs } from '@src/modules/spaces/components/SidebarSpaces/SidebarSpaces'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '@src/modules/shared/store'
import { updateFolder } from '@src/modules/spaces/data/folderSlice/folderThunk'
import { setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import Toggle from '../Toggle'
import OverflowTooltip from '../../OverflowTooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@src/modules/shared/components/Input/Input'
import { useTranslation } from 'react-i18next'

const FolderRow = ({
  isExpanded,
  data,
  arrow,
  toggleExpand,
  select,
  readOnly,
  isActive,
  isImport,
  isSelected,
  multiple,
  halfSelect,
}) => {
  const dispatch = useAppDispatch()
  const [isRename, setRename] = useState<boolean>(false)
  const { t } = useTranslation(['modals'])
  useEffect(() => {
    if (isActive && !isExpanded) toggleExpand()
  }, [isActive])

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t('folder.invalid_name')),
  })

  const formik = useFormik({
    initialValues: { name: data.name || '' },
    validationSchema,
    onSubmit: (values) => {
      if (values.name !== data.name) {
        dispatch(updateFolder({ id: data.id || '', name: values.name }))
          .unwrap()
          .then(() =>
            dispatch(
              setSpacesSocketEvent({
                updateType: 'updatedItem',
                updateEvent: { ...data, name: values.name },
              }),
            ),
          )
          .catch((err) => {
            message.error(err.message || t('folder.error_while_updating_folder_name'))
          })
          .finally(() => setRename(false))
      } else {
        setRename(false)
      }
    },
  })

  const actions = { ...folderActions, onrename: () => setRename(!isRename) }

  return (
    <div className="folder-row-tree folder-item">
      <div className="col1" onClick={toggleExpand}>
        <div className="folder-row-tree-icon">
          <FolderIcon color={data?.color} open={isExpanded} />
        </div>

        <div className="folder-row-tree-arrow">{arrow}</div>

        <div className="folder-row-tree-name" onClick={isImport || select}>
          {!isRename ? (
            <OverflowTooltip children={data.name} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Input
                type="text"
                name="name"
                disabled={false}
                variant="label-inline"
                onClick={(e) => e.stopPropagation()}
                onBlur={() => formik.handleSubmit()}
                className="sidebar-rename-input"
                size="xxs"
                formik={formik}
                defaultValue={data.name}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    formik.handleSubmit()
                  } else if (e.key === 'Escape') {
                    setRename(false)
                  }
                }}
              />
            </form>
          )}
        </div>
      </div>

      {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}

      {!readOnly && (
        <div className="folder-item-settings">
          <Dropdown
            menu={{
              items: items(
                {
                  ...data,
                  group: sidebarItemIDs.PROJECTS,
                  resource: 'folders',
                  parent: data?.space,
                },
                [PermissionesAction.NO_OUT_FROM_SPACE, PermissionesAction.NO_CREATE_FOLDER],
                actions,
              ),
            }}
            trigger={['click']}
          >
            <span>
              <SettingsIcon />
            </span>
          </Dropdown>

          <Dropdown
            menu={{
              items: AddListItems(
                {
                  ...data,
                  group: sidebarItemIDs.PROJECTS,
                  resource: 'folders',
                  parent: data?.space,
                },
                [PermissionesAction.NO_CREATE_FOLDER],
                actions,
              ),
            }}
            trigger={['click']}
          >
            <span>
              <PlusIcon />
            </span>
          </Dropdown>
        </div>
      )}

      {(isImport || multiple) && (
        <Toggle select={select} isSelected={isSelected} isMargin={true} halfSelect={halfSelect} />
      )}
    </div>
  )
}

export default FolderRow
