import { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { closeSidebar, toggleSidebar } from '../../store/slices/settings/settingsSlice'
import useWindowSize from '../../hooks/useWindowSize'
import { setSidebarWidth } from '../../store/slices/settings/settingsSlice'
import { sidebarCloseAt } from '../../layout/MainLayout/MainLayout'
import { useLocation } from 'react-router-dom'
const DISABLE_TEXT_SELECTION_CLASS = 'disable-text-selection'

function ViewSidebar({ children, open, sidebarId }: any) {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { pathname } = useLocation()
  const { sidebarWidth } = useAppSelector((state) => state.settings)
  const ref = useRef(null)
  const defaultWidth = 275
  const collapsedWidth = 0
  const maxWidth = 400
  const minWidth = 275
  const isSpace = !pathname.includes('settings') && !pathname.includes('daily-questions')

  const startResize = useCallback(
    (_event) => {
      document.body.classList.add(DISABLE_TEXT_SELECTION_CLASS)

      const doResize = (moveEvent) => {
        let newWidth = moveEvent.clientX - ref.current.getBoundingClientRect().left
        newWidth = Math.max(newWidth, minWidth)
        newWidth = Math.min(newWidth, maxWidth)
        dispatch(setSidebarWidth(newWidth))
        if (newWidth >= 90 && !open && sidebarId) dispatch(toggleSidebar(sidebarId))
      }

      const stopResize = () => {
        document.body.classList.remove(DISABLE_TEXT_SELECTION_CLASS)

        document.removeEventListener('mousemove', doResize)
        document.removeEventListener('mouseup', stopResize)
      }

      document.addEventListener('mousemove', doResize)
      document.addEventListener('mouseup', stopResize)
    },
    [open, minWidth, maxWidth],
  )

  useEffect(() => {
    setSidebarWidth(open ? defaultWidth : collapsedWidth)
  }, [open, dispatch])

  useEffect(() => {
    if (width < sidebarCloseAt && sidebarId) dispatch(closeSidebar(sidebarId))
  }, [width])

  return (
    // <RTLStyleDecorator
    <>
      <div
        ref={ref}
        className={`${isSpace ? 'space-sidebar' : ''} view-sidebar ${!open ? 'view-sidebar-collapsed ' : ''}`}
        style={{ width: `${sidebarWidth}px` }}
      >
        {children}
        <div className="drag-handle" onMouseDown={startResize} />
      </div>
      {width < sidebarCloseAt && open && isSpace && (
        <div
          className="view-sidebar-overlay"
          onClick={() => dispatch(closeSidebar(sidebarId))}
        ></div>
      )}
    </>
    // </RTLStyleDecorator>
  )
}

export default ViewSidebar
