import axiosInstance from './axios'

export const uploadFile = async (file: File, additionalFields?: any) => {
  try {
    const formData = new FormData()
    formData.append('icon', file)

    for (const key in additionalFields) {
      if (additionalFields.hasOwnProperty(key)) {
        formData.append(key, additionalFields[key])
      }
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    const response = await axiosInstance.post('/api/upload/icon', formData, { headers })

    if (response.data) {
      return response.data
    } else {
      throw new Error('Failed to upload file to Cloud.')
    }
  } catch (error) {
    console.error('Error uploading file:', error)
    throw error
  }
}
