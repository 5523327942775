import { Drawer, Dropdown, message } from 'antd'
import { ReactComponent as WarningSVG } from '../../../../assets/icons/status/warning.svg'
import { Button } from '@src/modules/shared/components/Button'
import {
  useGetConflictedStatusesQuery,
  useResolveConflictedStatusesMutation,
} from '@src/modules/spaces/services/fileApi'
import { useEffect, useState } from 'react'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { IStatus } from '../StatusGroup/StatusGroup'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowSVG } from '../../../../assets/icons/status/arrow.svg'
import { ReactComponent as AngleDownSVG } from '../../../../assets/icons/status/angle-down.svg'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

type conflictedStatusType = statusType & { toBe: IStatus; taskCount: number }

const getTotalTasks = (conflictedStatuses: conflictedStatusType[]) => {
  return conflictedStatuses?.reduce((acc, status) => acc + status.taskCount, 0)
}

const ConflictManagementDrawer = ({ handleClose, open, id: drawerId, data }) => {
  const { statuses, hierarchyType, entityUuid, onResolve, inheritStatusFromParent } = data
  const [conflictsStatuses, setConflictsStatuses] = useState<conflictedStatusType[] | null>(null)
  const { data: conflictedStatuses } = useGetConflictedStatusesQuery(
    { entityUuid, hierarchyType },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const [resolveConflictedStatuses, { isLoading }] = useResolveConflictedStatusesMutation()
  const { t } = useTranslation(['modals'])
  const closeModal = () => handleClose(drawerId)

  const totalConflictedTasks = getTotalTasks(conflictsStatuses)

  const newStatusItems = (conflictedStatus) =>
    statuses
      ?.filter((status) => !status?.isDeleted)
      ?.map((status) => {
        return {
          key: status?.id,
          label: status?.name,
          onClick: () => saveStatusToBe(conflictedStatus?.id, status),
        }
      })

  useEffect(() => {
    setConflictsStatuses(conflictedStatuses as conflictedStatusType[])
  }, [conflictedStatuses])

  const saveStatusToBe = (conflictedStatusId, status) => {
    const newConflictsStatuses = conflictsStatuses.map((conflictStatus) =>
      conflictStatus?.id === conflictedStatusId
        ? { ...conflictStatus, toBe: status }
        : conflictStatus,
    )

    setConflictsStatuses(newConflictsStatuses)
  }

  const save = () => {
    const payload = {
      entityUuid,
      hierarchyType,
      inheritStatusFromParent,
    }

    const allConflictsResolved = conflictsStatuses?.every((status) => status?.toBe)

    if (!allConflictsResolved) {
      message.error(t('status-conflicts.resolve-all-conflicts'))
      return
    }

    const statusesKey = inheritStatusFromParent ? 'parentStatuses' : 'customStatuses'

    payload[statusesKey] = conflictsStatuses
      .map((status) => {
        if (!status?.toBe) return null
        const { isNew, isDeleted, ...rest } = status?.toBe

        if (isNew) delete rest?.id

        const newStatusItem = inheritStatusFromParent
          ? {
              parentStatusId: rest?.id,
            }
          : {
              statusPayload: rest,
            }

        return {
          conflictingStatusId: status?.id,
          ...newStatusItem,
        }
      })
      .filter((status) => status)

    const restOfStatuses = statuses?.filter(
      (status) => !conflictsStatuses.find((item) => item?.toBe?.id === status?.id),
    )

    if (!inheritStatusFromParent)
      payload[statusesKey] = [
        ...payload[statusesKey],
        ...restOfStatuses?.map((status) => {
          const { isNew, isDeleted, ...rest } = status
          if (isNew) {
            delete rest.isNew
            delete rest.id
          }
          return { statusPayload: rest, createNew: !!isNew }
        }),
      ]

    resolveConflictedStatuses(payload)
      .unwrap()
      .then(() => {
        onResolve()
        closeModal()
      })
      .catch((error) => {
        message.error(
          error?.message || t('status-conflicts.error-while-resolving-conflicted-statuses'),
        )
      })
  }

  return (
    <Drawer width={670} onClose={closeModal} open={open} className="status-drawer" placement="left">
      <div className="status-drawer-content">
        <div className="status-drawer-warning-img">
          <WarningSVG />
          <div className="status-drawer-warning-text">
            {t('status-conflicts.resolve-your-conflicts')}
            <p>
              {t('status-conflicts.warning-text-1')} {totalConflictedTasks}{' '}
              {t('status-conflicts.warning-text-2')}
            </p>
          </div>
        </div>

        <div className="status-drawer-map">
          <div className="status-drawer-map-header">
            <p>{t('status-conflicts.old-status')} </p>
            <p>{t('status-conflicts.new-status')}</p>
          </div>
          <div className="status-drawer-map-content">
            {conflictsStatuses?.map((status) => {
              return (
                <div className="status-drawer-map-item" key={status?.id}>
                  <div className="status-drawer-map-item-left">
                    <div className="status-drawer-map-item-status">
                      <span style={{ background: status?.color }}></span> {status?.name}
                    </div>
                    <div className="status-drawer-map-item-tasks">
                      {status?.taskCount} {t('status-conflicts.task')} <ArrowSVG />
                    </div>
                  </div>
                  <div className="status-drawer-map-item-right">
                    <div className="status-drawer-map-item-select">
                      <Dropdown menu={{ items: newStatusItems(status) }} trigger={['click']}>
                        <div>
                          <p className="status-drawer-map-item-selcetd-status">
                            {status?.toBe ? (
                              <>
                                {<span style={{ background: status?.toBe?.color }}></span>}
                                <OverflowTooltip> {status.toBe?.name}</OverflowTooltip>
                              </>
                            ) : (
                              t('status-conflicts.select-status')
                            )}
                          </p>
                          <AngleDownSVG />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="status-drawer-footer">
          <Button className="cancel-button" onClick={closeModal}>
            {t('status-conflicts.cancel')}
          </Button>
          <Button onClick={save} loading={isLoading}>
            {t('status-conflicts.save')}
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default ConflictManagementDrawer
