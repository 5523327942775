import { api } from '@src/modules/shared/services/api'

export const managerQuestionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query<any, { teamId: string }>({
      query: (params) => {
        return {
          url: `api/question`,
          params,
        }
      },

      transformResponse: (response: { payload: any }) => {
        return response?.payload?.map((question, i: number) => {
          return { ...(question || {}), index: i }
        })
      },

      providesTags: (result = []) => {
        return [
          ...result?.map((question: any) => ({ type: 'QUESTIONS', id: question?.id }) as const),
          { type: 'QUESTIONS', id: 'MANAGER' },
        ]
      },
    }),

    createQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: `api/question`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'QUESTIONS', id: 'MANAGER' }] as any,
    }),

    updateQuestion: build.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `api/question/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'QUESTIONS', id: 'MANAGER' }] as any,
    }),

    deleteQuestion: build.mutation<any, any>({
      query: (id) => ({
        url: `api/question/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'QUESTIONS', id: 'MANAGER' }] as any,
    }),
  }),
})

export const {
  useGetQuestionsQuery,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = managerQuestionsApi
