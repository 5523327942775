import ViewTitle from '@src/modules/shared/components/ViewTitle'
import { ReactComponent as DocumentsSVG } from '../../assets/icons/header/documents.svg'
import { ReactComponent as AddDocSVG } from '../../assets/icons/header/add-doc.svg'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import Button from '@src/modules/shared/components/Button/Button'

const Header = () => {
  const { t } = useTranslation(['documents'])
  const dispatch = useAppDispatch()

  return (
    <ViewTitle>
      <div className="documents-header">
        <div className="documents-header-left">
          <DocumentsSVG /> {t('documents')}
        </div>

        <div className="documents-header-right">
          <Button
            className="new-document"
            onClick={() => {
              dispatch(openModal({ id: 'create-new-document-modal' }))
            }}
          >
            <AddDocSVG />
            {t('new_doc')}
          </Button>
        </div>
      </div>
    </ViewTitle>
  )
}

export default Header
