import { useEffect } from 'react'
import ActivitySVG from './icons/ActivitySVG'
import DetailsSVG from './icons/DetailsSVG'
import { ModalSidarBar } from './types'

export default function TaskModelSideBar({ setModalView, current }: ModalSidarBar) {
  useEffect(() => {
    setModalView(1)
  }, [])
  const SELECTED_VIEW_COLOR = '#986AAA'
  const UNSELECTED_VIEW_COLOR = '#374957'
  return (
    <div className="task-modal-sider">
      <div
        className="task-modal-sider_element"
        style={{ color: current === 1 ? SELECTED_VIEW_COLOR : UNSELECTED_VIEW_COLOR }}
        onClick={() => current !== 1 && setModalView(1)}
      >
        <DetailsSVG fill={current === 1 ? SELECTED_VIEW_COLOR : UNSELECTED_VIEW_COLOR} />
        <p>Details</p>
      </div>
      <p className="task-modal-sider_seperator"></p>
      <div
        className="task-modal-sider_element"
        style={{ color: current === 2 ? SELECTED_VIEW_COLOR : UNSELECTED_VIEW_COLOR }}
        onClick={() => current !== 2 && setModalView(2)}
      >
        <ActivitySVG fill={current === 2 ? SELECTED_VIEW_COLOR : UNSELECTED_VIEW_COLOR} />
        <p>Activity</p>
      </div>
    </div>
  )
}
