import Button from '@src/modules/shared/components/Button/Button'
import { closeModal, openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { useAppDispatch } from '@src/modules/shared/store'
import { message } from 'antd'
import { inviteToWorkspace } from '@src/modules/auth/data/authThunk'
import MemberRole from '../MemberRole'

import { ReactComponent as AngleIcon } from './../../assets/icons/invite/angle.svg'
import { useState } from 'react'
import { useGetMembersQuery } from '../../services/membersApi'
import { defaultMemmbersSort } from '../../features/Members/Members'
import { useTranslation } from 'react-i18next'
import MultipleSelection from '@src/modules/shared/components/ MultipleSelection'

const validateEmail = (email: string) =>
  /^[^\s@]+@[^\s@]{2,}\.[^\s@]{2,}$/.test(email.toLowerCase())

const InviteMemberForm = () => {
  const { t } = useTranslation(['modals'])
  const [loading, setLoading] = useState<boolean>(false)
  const [emailInput, setEmailInput] = useState('')
  const { refetch } = useGetMembersQuery({ search: '', limit: 15, skip: 1, ...defaultMemmbersSort })

  const dispatch = useAppDispatch()
  const initialValues = {
    emails: [],
    role: '',
  }

  function getEmailsArray(data) {
    const { emails } = data
    const emailArray = emails?.map((entry) => entry?.email)
    return {
      emails: emailArray,
      role: data?.role || '',
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup?.object().shape({
      emails: Yup?.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z]{3,}\.[a-zA-Z]+$/,
                t('invite_people.Invalid email format'),
              )
              .required(t('invite_people.Email is required')),
            role: Yup.object().shape({ id: Yup.string(), name: Yup.string() }),
          }),
        )
        .required(t('invite_people.Email is required')),
    }),

    onSubmit: (values) => {
      const result = getEmailsArray(values)
      result?.emails?.map((item) => {
        return item?.toLowerCase()
      })
      const isExistingEmail = emailInput.length > 0
      const isValidEmail = validateEmail(emailInput)
      const isManyEmails = result?.emails?.length > 0

      if (!(isManyEmails || isExistingEmail)) {
        return
      }

      if (!isValidEmail && isExistingEmail) {
        formik.setErrors({ emails: [{ email: t('invite_people.Invalid email format') }] })
        return
      }

      setLoading(true)
      dispatch(
        inviteToWorkspace(
          isManyEmails ? result : { emails: [emailInput?.toLowerCase()], role: result?.role },
        ),
      )
        .unwrap()
        .then((res) => {
          refetch()
          dispatch(closeModal({ id: 'invite-modal' }))
          dispatch(
            openModal({
              id: 'invited-users-modal',
              data: {
                response: res?.result,
                emails: isManyEmails ? result?.emails : [emailInput?.toLowerCase()],
              },
            }),
          )
          formik.setValues(initialValues)
        })
        .catch((error) => {
          message.error(
            error?.message || t('invite_people.There was an error sending the invitations.'),
          )
        })
        .finally(() => setLoading(false))
    },
  })

  return (
    <div className="invite-form ta-form list-form">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <label>{t('invite_people.invite_by_email')}</label>

          <MultipleSelection formik={formik} emailInput={emailInput} setEmailInput={setEmailInput}>
            <MemberRole
              role={'member'}
              onClick={(role: any) => (formik.values.role = role?.name)}
              className="invite-form-role-modal"
              Icon={AngleIcon}
            />
          </MultipleSelection>

          <div className="form-actions flex-end">
            <Button
              htmlType="reset"
              className="cancel-action"
              onClick={() => {
                formik.setValues(initialValues)
                dispatch(closeModal({ id: 'invite-modal' }))
              }}
            >
              {t('invite_people.cancel')}
            </Button>

            <Button htmlType="submit" className="confirm-action" loading={loading}>
              {loading ? t('invite_people.loading') : t('invite_people.send_invite')}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

export default InviteMemberForm
