import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { useEffect, useState } from 'react'

import OtherNotifications from '../OtherNotifications/OtherNotifications'
import AssignedToMe from '../AssignedToMe/AssignedToMe'
import Header from '../../components/Header/Header'
import Mentions from '../Mentions/Mentions'
import type { TabsProps } from 'antd'
import ViewLayout from '@src/modules/shared/components/ViewLayout/ViewLayout'
import { getAllNotifications } from '../../data/NotificationsThunk'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface TabsType {
  [key: number]: () => JSX.Element
}

const Tabs: TabsType | any = {
  1: AssignedToMe,
  2: OtherNotifications,
  3: Mentions,
}

const renderNotificationCount = (count: number) => {
  if (count === 0) return

  if (count > 999) {
    return <p className="notification_tab_cout">+999</p>
  } else return <p className="notification_tab_cout">{count}</p>
}

const Notifications = () => {
  const { t } = useTranslation(['notifications'])
  const dispatch = useAppDispatch()
  let [searchParams, setSearchParams] = useSearchParams()
  const { notifications, clearedNotification, metadata, newNotifications, notificationCount } =
    useAppSelector((state) => state.notification)
  useTitle(t('notifications'))

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <p className="notification_tab">
          {t('important')}
          {notificationCount?.important > 0 &&
            renderNotificationCount(notificationCount?.important)}
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p className="notification_tab">
          {t('other')}
          {notificationCount?.all > 0 && renderNotificationCount(notificationCount?.all)}
        </p>
      ),
    },
    {
      key: '3',
      label: (
        <p className="notification_tab">
          {t('mentions')}
          {notificationCount?.mention > 0 && renderNotificationCount(notificationCount?.mention)}
        </p>
      ),
    },
  ]

  const [notificationList, setNotificationList] = useState(notifications?.payload)
  const [page, setPage] = useState<number>(1)
  const [isRead, setIsRead] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>(searchParams.get('tab') || '1')
  const Tab = Tabs[+activeTab]

  const updateTab = (t: string) => {
    setActiveTab(t)
    setPage(1)
    setNotificationList([])
  }

  useEffect(() => {
    if (!metadata?.next) setPage(1)
  }, [activeTab, metadata?.next, page])

  useEffect(() => {
    setNotificationList((prev) => prev?.filter((notif) => notif?.id !== clearedNotification))
  }, [clearedNotification])

  useEffect(() => {
    if (activeTab === '1') {
      searchParams.set('tab', '1')
      setSearchParams(searchParams)
      dispatch(getAllNotifications({ isRead, type: 'important', isMention: false, page }))
        .unwrap()
        .then((res) => {
          setNotificationList(res?.payload?.payload)
        })
    }
    if (activeTab === '2') {
      searchParams.set('tab', '2')
      setSearchParams(searchParams)
      dispatch(getAllNotifications({ isRead, page, isMention: false, type: 'all' }))
        .unwrap()
        .then((res) => {
          setNotificationList(res?.payload?.payload)
        })
    }
    if (activeTab === '3') {
      searchParams.set('tab', '3')
      setSearchParams(searchParams)
      dispatch(getAllNotifications({ page, isRead, isMention: true, type: 'all' }))
        .unwrap()
        .then((res) => {
          setNotificationList(res?.payload?.payload)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRead, activeTab, dispatch, newNotifications?.length, notificationList?.length === 0])

  const loadMoreNotifications = () => {
    if (metadata?.next) {
      setPage(page + 1)

      switch (activeTab) {
        case '1':
          dispatch(getAllNotifications({ isRead, type: 'important', page, isMention: false }))
            .unwrap()
            .then((res) => {
              setNotificationList((prev) => [...prev, ...res.payload.payload])
            })
          break

        case '2':
          dispatch(getAllNotifications({ isRead, page, type: 'all', isMention: false }))
            .unwrap()
            .then((res) => {
              setNotificationList((prev) => [...prev, ...res.payload.payload])
            })
          break

        case '3':
          dispatch(getAllNotifications({ page, isRead, type: 'all', isMention: true }))
            .unwrap()
            .then((res) => {
              setNotificationList((prev) => [...prev, ...res.payload.payload])
            })
          break
      }
    }
  }

  const headerProps = { items, activeTab, onTab: updateTab, setIsRead, setNotificationList }
  const tabProps = { isRead, activeTab, notificationList, loadMoreNotifications, page }

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="section_notifications">
        <Tab {...tabProps} />
      </div>
    </ViewLayout>
  )
}

export default Notifications
