import { memberType } from '../MemberName/MemberName'
import { Dropdown, MenuProps, message } from 'antd'
import { ReactComponent as SettingIcon } from './../../assets/icons/members/settings.svg'
import { ReactComponent as ResendIcon } from './../../assets/icons/members/resend-invitation.svg'
import { ReactComponent as CancelIcon } from './../../assets/icons/members/cancel.svg'
import { ReactComponent as RemoveIcon } from './../../assets/icons/members/remove.svg'
import { ReactComponent as CopyIcon } from './../../assets/icons/members/copy.svg'
import { ReactComponent as GuestIcon } from './../../assets/icons/members/guest.svg'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useDeleteInvitationMutation } from '../../services/membersApi'
import axiosInstance from '@src/modules/shared/utils/axios'
import { manageDropdownPermissions } from '@src/modules/spaces/utils/spaceActions'
import { useTranslation } from 'react-i18next'

interface MembersSettingsProps {
  data: memberType
}

const MembersSettings = ({ data }: MembersSettingsProps) => {
  const { t } = useTranslation(['members'])

  const dispatch = useAppDispatch()
  const [deleteInvitation] = useDeleteInvitationMutation()
  const isMember = !data.isInvited
  const isOwner = data.isOwner || data.roles?.find((role: any) => role.dataId === 'owner')
  const memberActions: MenuProps['items'] = !isMember
    ? manageDropdownPermissions(
        [
          {
            key: '1',
            label: t('members_settings.resend_invitation'),
            icon: <ResendIcon />,
            onClick: () => {
              axiosInstance
                .post('/api/workspace/resend-invitation', { email: data?.email })
                .then((res) => {
                  message.success(res.data.message || t('members_settings.invitation_success'))
                })
                .catch((err) => {
                  message.error(err?.message || t('members_settings.invitation_error'))
                })
            },
            requiredPermission: { action: 'create', resource: 'users' },
          },
          {
            key: '2',
            label: t('members_settings.cancel_invitation'),
            icon: <CancelIcon />,
            onClick: () => {
              deleteInvitation({ emails: [data.email] })
                .unwrap()
                .catch((err) => {
                  message.error(err?.data?.message || t('members_settings.delete_invitation_error'))
                })
            },
            requiredPermission: { action: 'create', resource: 'users' },
          },
        ],
        true,
      )
    : manageDropdownPermissions(
        [
          {
            key: '1',
            label: t('members_settings.convert_to_guest'),
            icon: <GuestIcon />,
            disabled: isOwner,
            access: true,
            onClick: () => {},
          },
          {
            key: '2',
            label: t('members_settings.copy_member_id'),
            icon: <CopyIcon />,
            access: true,
            onClick: () => {
              navigator.clipboard.writeText(data.id)
            },
          },
          {
            key: '3',
            label: t('remove'),
            disabled: isOwner,
            access: !isOwner,
            icon: <RemoveIcon />,
            onClick: () => dispatch(openModal({ id: 'delete-member-modal', data })),
            requiredPermission: { action: 'delete', resource: 'users' },
          },
        ],
        false,
      )

  return (
    <Dropdown menu={{ items: memberActions }} trigger={['click']}>
      <div className="member-setting">
        <SettingIcon />
      </div>
    </Dropdown>
  )
}

export default MembersSettings
