import { ReactComponent as Collapsed } from '../../assets/collaps.svg'
import { ReactComponent as NotCollapsed } from '../../assets/notCollaps.svg'
import Spinner from '@src/modules/shared/components/Spinner'
import { ReactComponent as EmptyQuestion } from '../../assets/emptyQuestions.svg'
import Answers from '../Answers/Answers'
import { useFollowUpContext } from '../../context/FollowUpContext'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Calendar } from '../../../../../dailyQuestions/components/MemberView/assets/cryCalendar.svg'
import { useAppSelector } from '@src/modules/shared/store'
export const QuestionItem = ({ isLoad, questions, dateQuery, endDateQuery, indexOfTheDay }) => {
  const { team, question, setQuestion } = useFollowUpContext()
  const user = useAppSelector((state) => state.auth)
  const userId = user?.user?.user?.id
  const isManager = UserisManager(team?.managers, userId)
  const isWatcher = UserIsWatcher(team?.roles, userId)
  const { t: tdq } = useTranslation(['dailyQuestion'])
  const { t } = useTranslation(['dashboard'])
  const style = {
    cursor: 'pointer',
  }
  if (isLoad) {
    return <Spinner />
  }
  const dontHaveAnyQuestionToday = questions?.some((item) => {
    return item?.days?.includes(indexOfTheDay + '')
  })

  return (
    <div className="followup-dashboard-questions">
      {questions?.length === 0 && (
        <div className="followup-dashboard-empty">
          <EmptyQuestion />
          <p>{t('no_questions')}</p>
        </div>
      )}
      {dontHaveAnyQuestionToday ? (
        questions?.map((item) => {
          const doesDayExist = item.days.includes(indexOfTheDay + '')
          return doesDayExist ? (
            <div className="followup-dashboard-question" key={item?.id}>
              <p
                className={`${'followup-dashboard-question_name'} ${item?.id === question?.id && 'active-question'}`}
              >
                {item?.title}
              </p>

              {canSeeAnswers(isManager, isWatcher, item?.showMembers) && (
                <p className="followup-dashboard_date">
                  {t('see') + ' ' + ' ' + t('answers')}
                  {question?.id === item?.id ? (
                    <Collapsed onClick={() => setQuestion(null)} style={style} />
                  ) : (
                    <NotCollapsed onClick={() => setQuestion(item)} style={style} />
                  )}
                </p>
              )}
              {question?.id === item?.id && (
                <Answers
                  item={item}
                  question={question}
                  dateQuery={dateQuery}
                  endDateQuery={endDateQuery}
                  team={team}
                />
              )}
            </div>
          ) : null
        })
      ) : questions?.length > 0 ? (
        <div className="no_data_conatiner">
          <Calendar />
          <span className="invalid-day_description">{tdq('answers_tab.short_invalid_data')}</span>
        </div>
      ) : null}
    </div>
  )
}

const UserisManager = (managers, id) => {
  return managers?.some((manager) => manager?.userId === id)
}
export const UserIsWatcher = (users, id) => {
  return users?.some((user) => {
    return user?.userId === id && user?.role?.dataId === 'watcherTeam'
  })
}
const canSeeAnswers = (isManager, isWatcher, showMembers) => {
  if (showMembers) {
    return true
  } else if (isManager || isWatcher) return true
  return false
}
