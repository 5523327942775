import Button from '@src/modules/shared/components/Button/Button'
import React from 'react'
import { ReactComponent as Send } from '../../assets/send.svg'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { useGetAnswersQuery } from '@src/modules/dailyQuestions/services/memberAnswerApi'
import { ICurrentQuestion } from '../../memberView.interface'
import AnimatedButton from '@src/modules/shared/components/AnimatedButton/AnimatedButton'
interface IAddAnswerButton {
  isView: boolean
  showCreation: boolean
  isLoading: boolean
  isEdit: boolean
  createAnswerFn: () => void
  updateAnswerFn: () => void
  endDateQuery: string
  dateQuery: string
  team: any
  currentQuestion: ICurrentQuestion
}
function AddAnswerButton({
  showCreation,
  isLoading,
  isView,
  isEdit,
  createAnswerFn,
  updateAnswerFn,
  endDateQuery,
  dateQuery,
  team,
  currentQuestion,
}: IAddAnswerButton) {
  const { t } = useTranslation(['dailyQuestion'])
  const { refetch, isSuccess } = useGetAnswersQuery(
    {
      teamId: team?.id,
      startDate: endDateQuery,
      endDate: dateQuery,
      questionId: currentQuestion?.id,
    },
    {
      skip: !team?.id || !currentQuestion?.id,
    },
  )

  return (
    <>
      {!isView && showCreation && (
        <div className="post-answer-container">
          <AnimatedButton>
            <Button
              children={
                <>
                  {isLoading ? t('answers_tab.loading') : t('answers_tab.submit')} <Send />
                </>
              }
              className="post-answer-btn"
              disabled={isLoading}
              onClick={() => {
                try {
                  if (!isEdit) createAnswerFn()
                  else {
                    updateAnswerFn()
                  }
                } catch (error) {
                  message.error(error)
                } finally {
                  if (isSuccess) {
                    refetch()
                  }
                }
              }}
            ></Button>
          </AnimatedButton>
        </div>
      )}
    </>
  )
}

export default React.memo(AddAnswerButton)
