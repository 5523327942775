import RTLStyleDecorator from '../../RTLStyleDecorator'

const ItemsArrows = {
  space: (isExpanded: boolean) => (
    <RTLStyleDecorator disableRTLAdjustments>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        className={`space-tree-arrow ${isExpanded ? 'expanded-arrow' : ''}`}
      >
        <path
          d="M4.81837 10.4269V4.44692C4.81775 4.34059 4.84881 4.23648 4.9076 4.14786C4.96638 4.05925 5.05022 3.99015 5.14843 3.94937C5.24664 3.9086 5.35477 3.89799 5.45903 3.91891C5.56329 3.93982 5.65896 3.99131 5.73384 4.06682L8.72116 7.05949C8.82088 7.15979 8.87684 7.29548 8.87684 7.43692C8.87684 7.57835 8.82088 7.71404 8.72116 7.81435L5.73384 10.807C5.65896 10.8825 5.56329 10.934 5.45903 10.9549C5.35477 10.9758 5.24664 10.9652 5.14843 10.9245C5.05022 10.8837 4.96638 10.8146 4.9076 10.726C4.84881 10.6374 4.81775 10.5332 4.81837 10.4269Z"
          fill={isExpanded ? '#374957' : '#98A2B3'}
        />
      </svg>
    </RTLStyleDecorator>
  ),
  folder: (isExpanded: boolean) => (
    <RTLStyleDecorator disableRTLAdjustments>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="5"
        viewBox="0 0 7 5"
        fill="none"
        className={`folder-arrow space-tree-arrow ${isExpanded ? 'expanded-folder-arrow' : ''}`}
      >
        <path
          d="M6.22721 0.845049C6.12064 0.742889 5.97648 0.685547 5.82621 0.685547C5.67594 0.685547 5.53178 0.742889 5.42521 0.845049L3.38322 2.78677L1.36967 0.845049C1.2631 0.742889 1.11894 0.685547 0.968667 0.685547C0.818399 0.685547 0.674235 0.742889 0.567664 0.845049C0.514351 0.89604 0.472036 0.956706 0.443159 1.02355C0.414281 1.09039 0.399414 1.16208 0.399414 1.23449C0.399414 1.3069 0.414281 1.37859 0.443159 1.44544C0.472036 1.51228 0.514351 1.57294 0.567664 1.62393L2.97937 3.94961C3.03225 4.00102 3.09516 4.04183 3.16447 4.06968C3.23379 4.09752 3.30813 4.11186 3.38322 4.11186C3.45831 4.11186 3.53265 4.09752 3.60197 4.06968C3.67128 4.04183 3.73419 4.00102 3.78707 3.94961L6.22721 1.62393C6.28053 1.57294 6.32284 1.51228 6.35172 1.44544C6.3806 1.37859 6.39546 1.3069 6.39546 1.23449C6.39546 1.16208 6.3806 1.09039 6.35172 1.02355C6.32284 0.956706 6.28053 0.89604 6.22721 0.845049Z"
          fill="black"
        />
      </svg>
    </RTLStyleDecorator>
  ),
}

export const renderItemArrow = ({ item, context }) => {
  const isExpanded = context.isExpanded
  const itemType = item?.type
  const itemArrow = ItemsArrows[itemType]
  const arrow = itemArrow && itemArrow(isExpanded)
  return <div className="space-tree-arrow">{arrow}</div>
}
