import { detectLanguage } from '@src/modules/spaces/utils/detectLanguage'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { ISubTask, TaskType } from '../../data/tasksSlice/tasksTypes'
import { useUpdateTaskMutation } from '../../services/tasksApi'
import { ReactComponent as UpdateIcon } from './../../assets/icons/task/update-name.svg'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import Input from '@src/modules/shared/components/Input/Input'

interface TaskNameProps {
  data: TaskType | ISubTask
  disable?: boolean
}

const isValidName = (name: string) => {
  return name.trim()
}

const moveCaretAtEnd = (e: React.FocusEvent<HTMLInputElement, Element>) => {
  const temp_value = e.target.value
  e.target.value = ''
  e.target.value = temp_value
}

const TaskName = ({ data, disable = false }: TaskNameProps) => {
  const [value, setValue] = useState<string | null>(data?.name)
  const [updateMode, setUpdateMode] = useState<boolean>(false)
  const [updateTask] = useUpdateTaskMutation()

  const language = detectLanguage(value)
  const textDirection = language === 'arabic' ? 'rtl' : 'ltr'

  const saveChanges = (name: string) => {
    if (!isValidName(name)) {
      message.error('name is not valid')
      setValue(data?.name)
      setUpdateMode(false)
      return
    }

    if (name.trim() === data.name) {
      setUpdateMode(false)
      return
    }

    updateTask({ id: data?.id, name })
      .unwrap()
      .then(() => {
        setUpdateMode(false)
      })
      .catch((err) => {
        message.error(err?.data?.message || 'Error on update task name')
      })
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') saveChanges(e.currentTarget.value)
  }

  const blurHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    saveChanges(e.currentTarget.value)
  }

  useEffect(() => {
    setValue(data?.name)
  }, [data?.name])

  return (
    <div className="task-name">
      {!updateMode ? (
        <OverflowTooltip
          children={value}
          className="task-name-tootlip"
          placement={'topLeft'}
          arrow={false}
          as={'span'}
          onClick={(e) => e.preventDefault()}
          style={{ direction: textDirection }}
        />
      ) : (
        <Input
          autoFocus
          onChange={(e) => setValue(e.currentTarget.value.replace(/\n/g, ''))}
          value={value || ''}
          onKeyDown={keyDownHandler}
          onBlur={blurHandler}
          onFocus={moveCaretAtEnd}
          onClick={(e) => e.stopPropagation()}
          variant="label-inline"
        />
      )}

      {!disable ? (
        <span
          onClick={(e) => {
            e.stopPropagation()
            setUpdateMode(!updateMode)
          }}
        >
          <UpdateIcon className="task-name-update-name" />
        </span>
      ) : null}
    </div>
  )
}

export default TaskName
