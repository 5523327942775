import debounce from 'lodash/debounce'
import { useState } from 'react'

interface Editor {
  id: number
  content: string
}

export interface EditorsState {
  [key: string]: Editor[]
}

const useDebouncedUpdateEditorHtml = (): ((
  id: number,
  content: string,
  setEditors: React.Dispatch<React.SetStateAction<EditorsState>>,
  questionId: string,
  date: Date,
  userId: string,
) => void) => {
  const [debouncedUpdateEditorHtml] = useState<
    (
      id: number,
      content: string,
      setEditors: React.Dispatch<React.SetStateAction<EditorsState>>,
      questionId: string,
      date: Date,
      userId: string,
    ) => void
  >(() =>
    debounce(
      (
        id: number,
        content: string,
        setEditors: React.Dispatch<React.SetStateAction<EditorsState>>,
        // questionId: string,
        // date: Date,
        // userId: string,
      ) => {
        setEditors((prev) => {
          const newContent = content
          const updatedEditors = { ...prev }
          Object.keys(updatedEditors).forEach((key) => {
            updatedEditors[key] = updatedEditors[key].map((editor) =>
              editor.id === id ? { ...editor, content: newContent } : editor,
            )
          })
          return updatedEditors
        })
        // const key = `${questionId}-${date.toDateString()}-${userId}`
      },
      50,
    ),
  )
  return debouncedUpdateEditorHtml
}

export { useDebouncedUpdateEditorHtml }
