import CalendarDropDown from '@src/modules/tasks/components/Calendar/Calendar'
import dayjs from 'dayjs'

type props = {
  setStartDate: Function
  setEndDate: Function
}

const TrashFilterTime = ({ setStartDate, setEndDate }: props) => {
  return (
    <CalendarDropDown
      isTrash={true}
      setStart={(date: any) => {
        const startDate = dayjs(date)
        setStartDate(startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
      }}
      setEnd={(date: any) => {
        const endDate = dayjs(date)
        setEndDate(endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
      }}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default TrashFilterTime
