import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/workspace/Group.svg'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'
import * as Yup from 'yup'
import lock from '../../../assets/icons/workspace/Group 8.svg'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

const initialValues = {
  password: '',
}

const DeleteWorkspaceModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: () => {},
  })

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-moda"
      closable={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="space-modal-header">
          <p className="modal-title">{t('DeleteWorkspaceModal.Delete workspace')}</p>
          <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
        </div>

        <div className="delete-image-container">
          <DeleteIcon />
        </div>

        <p className="small-description">
          {t('DeleteWorkspaceModal.You are about to delete')} “<span>{data?.name}</span>”,{' '}
          {t('DeleteWorkspaceModal.including all its history')},{' '}
          {t('DeleteWorkspaceModal.tasks and files.This action is permanent and cannot be undone')}
        </p>

        <p className="small-description">
          {t('DeleteWorkspaceModal.To confirm this action, please enter your password.')}
        </p>

        <div className="password-input-container">
          <Input
            name="password"
            formik={formik}
            variant="filled"
            placeholder={t('DeleteWorkspaceModal.Enter password to confirm deletion')}
            type="password"
            required={false}
            disabled={false}
            prefix={<img src={lock} alt="lock" />}
          />
        </div>

        <div className="buttons-container">
          <Button
            children={t('DeleteWorkspaceModal.Cancel')}
            htmlType="button"
            onClick={() => handleClose(id)}
            disabled={false}
            className="cancel-button"
          />
          <Button
            children={t('DeleteWorkspaceModal.Delete')}
            htmlType="submit"
            className="delete-button"
            disabled={false}
          />
        </div>
      </form>
    </Modal>
  )
}

export default DeleteWorkspaceModal
