import dayjs from 'dayjs'
import 'dayjs/locale/ar-tn'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
dayjs.locale('ar-tn-custom', {
  ...dayjs.Ls['ar-tn'],
  months: [
    'جانفي',
    'فيفري',
    'مارس',
    'أفريل',
    'ماي',
    'جوان',
    'جويلية',
    'أوت',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
})
export function formatDateToMultiLang(date, language, dateFormat = 'dddd DD MMM') {
  let locale
  let format = dateFormat

  switch (language) {
    case 'ar':
      locale = 'ar-tn-custom'
      break
    case 'fr':
      locale = 'fr'
      break
    case 'en':
    default:
      locale = 'en'
  }

  return dayjs(date).locale(locale).format(format)
}
