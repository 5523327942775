import ListHeader from '../../../ListView/ListHeader'

function TableHeader() {
  return (
    <div className="task-list-sub-item subtasks-table-header">
      <ListHeader withResize={false} withDnd={false} withSort={false} />
    </div>
  )
}

export default TableHeader
