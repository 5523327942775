import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import SubTasks from '../SubTasksTree/index'
import { useTranslation } from 'react-i18next'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useState } from 'react'
import CustomFields from '../CustomFields'

function index({ task }: { task: TaskType }) {
  const { t } = useTranslation(['taskDetails'])
  const [activeTab, setActiveTab] = useState<string>('0')

  const onChangeTabs = (key: string) => setActiveTab(key)

  const TabLabel = ({
    label,
    active,
    disabled = false,
  }: {
    label: string | React.ReactNode
    active: boolean
    disabled?: boolean
  }) => {
    return (
      <div
        style={disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
        className={`tabs-tab-label ${active ? 'tabs-tab-active' : 'tabs-tab-inactive'}`}
      >
        {label}
      </div>
    )
  }

  const tabItems: TabsProps['items'] = [
    {
      key: '0',
      label: <TabLabel label={t('subtask')} active={activeTab === '0'} />,
      children: <SubTasks task={task} />,
    },
    {
      key: '1',
      label: <TabLabel label={t('details')} active={activeTab === '1'} />,
      children: <CustomFields task={task} />,
    },
    {
      key: '2',
      label: <TabLabel disabled label={t('check_list')} active={false} />,
      children: 'Content of Tab Pane 2',
      disabled: true,
    },
  ]

  return (
    <Tabs
      activeKey={activeTab}
      items={tabItems}
      onChange={onChangeTabs}
      className="edit-popup-subtasks-tabs"
    />
  )
}

export default index
