import EmailInput from '@src/modules/auth/components/EmailInput/EmailInput'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'

interface Props {
  emails: string[]
  setEmails: any
  confirmUsersNumber: any
  setConfirmInviteUsers: any
  confirmInviteUsers: any
}
const WorkspaceInviteUsers: React.FC<Props> = ({
  emails,
  setEmails,
  confirmUsersNumber,
  setConfirmInviteUsers,
  confirmInviteUsers,
}) => {
  const { t } = useTranslation(['workspaces'])

  return (
    <>
      <h1>{t('Invite persons to your Workspace')}</h1>
      <EmailInput
        emails={emails}
        setEmails={setEmails}
        disabled={confirmUsersNumber ? false : true}
      />

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmInviteUsers(!confirmInviteUsers)}
        disabled={confirmUsersNumber ? false : true}
      />
    </>
  )
}

export default WorkspaceInviteUsers
