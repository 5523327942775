import { SVGProps } from 'react'

type Props = {}

function ImageIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.625 8C0.625 6.09671 0.626327 4.73212 0.765873 3.69419C0.903002 2.67424 1.16321 2.06382 1.61351 1.61351C2.06382 1.16321 2.67424 0.903002 3.69419 0.765873C4.73212 0.626327 6.09671 0.625 8 0.625C9.90329 0.625 11.2679 0.626327 12.3058 0.765873C13.3258 0.903002 13.9362 1.16321 14.3865 1.61351C14.8368 2.06382 15.097 2.67424 15.2341 3.69419C15.3737 4.73212 15.375 6.09671 15.375 8C15.375 9.90329 15.3737 11.2679 15.2341 12.3058C15.097 13.3258 14.8368 13.9362 14.3865 14.3865C13.9362 14.8368 13.3258 15.097 12.3058 15.2341C11.2679 15.3737 9.90329 15.375 8 15.375C6.09671 15.375 4.73212 15.3737 3.69419 15.2341C2.67424 15.097 2.06382 14.8368 1.61351 14.3865C1.16321 13.9362 0.903002 13.3258 0.765873 12.3058C0.626327 11.2679 0.625 9.90329 0.625 8Z"
        stroke="#94A4B1"
        strokeWidth="1.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9959 9.55597L13.9589 9.51903C13.4538 9.01321 13.0854 8.64431 12.6617 8.44746C11.9603 8.12151 11.1508 8.12151 10.4494 8.44746C10.0257 8.64432 9.65728 9.01322 9.15212 9.51904L9.06843 9.6028C8.85474 9.81648 8.71659 9.95415 8.60664 10.0501C8.5047 10.1391 8.46736 10.1552 8.46569 10.1559C8.33575 10.2033 8.19029 10.1753 8.08723 10.0831C8.08603 10.0819 8.05736 10.0531 7.99565 9.93246C7.92921 9.80252 7.85207 9.62339 7.73303 9.34562L7.68556 9.23486L7.67485 9.20987L7.67484 9.20984C7.41008 8.59207 7.19655 8.0938 6.99556 7.71221C6.79145 7.32468 6.56688 6.9897 6.24942 6.74785C5.59641 6.25039 4.74829 6.08706 3.95724 6.30642C3.57266 6.41306 3.23974 6.64068 2.9063 6.92469C2.64519 7.1471 2.34929 7.43508 2.00002 7.78273C2.00001 7.85412 2 7.92648 2 7.99981C2 8.57174 2.00037 9.08412 2.00406 9.54604L2.58404 8.96606C3.08303 8.46708 3.43115 8.11962 3.71683 7.87629C4.00182 7.63355 4.16841 7.54503 4.29126 7.51096C4.70562 7.39606 5.14988 7.48161 5.49193 7.74219C5.59334 7.81944 5.71513 7.9635 5.88959 8.29472C6.06446 8.62675 6.25865 9.07865 6.53663 9.72725L6.5841 9.83802L6.5922 9.85693C6.70084 10.1104 6.79458 10.3292 6.8827 10.5015C6.97313 10.6784 7.0851 10.8635 7.25288 11.0138C7.69979 11.4143 8.33101 11.5358 8.89467 11.33C9.10629 11.2527 9.279 11.1225 9.42865 10.9918C9.57446 10.8646 9.74274 10.6963 9.93775 10.5012L9.95231 10.4867C10.5784 9.86056 10.7807 9.67187 10.9761 9.58105C11.3435 9.41032 11.7675 9.41032 12.135 9.58105C12.3304 9.67187 12.5326 9.86056 13.1588 10.4867L13.9491 11.2771C13.9779 10.7913 13.9904 10.227 13.9959 9.55597ZM8.08723 10.0831C8.08717 10.083 8.08711 10.0829 8.08704 10.0829C8.08716 10.083 8.08723 10.0831 8.08726 10.0831L8.08727 10.0831C8.08727 10.0831 8.08726 10.0831 8.08723 10.0831Z"
        fill="#94A4B1"
      />
      <circle cx="11.9993" cy="4.00033" r="1.33333" fill="#94A4B1" />
    </svg>
  )
}

export default ImageIcon
