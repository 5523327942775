async function toDataURL(url) {
  const blob = await fetch(url).then((res) => res.blob())
  return URL.createObjectURL(blob)
}

export async function downloadFile(file) {
  const a = document.createElement('a')
  a.href = await toDataURL(file?.url)
  a.download = file?.name
  a.click()
}
