interface Props {
  date: Date
  setShowMonth: React.Dispatch<React.SetStateAction<boolean>>
  handleMonthChange: (value: number) => void
  TodayDate: Date
  startBlock: Date
}

export default function YearDropDown({
  startBlock,
  TodayDate,
  setShowMonth,
  handleMonthChange,
}: Props) {
  return (
    <>
      <div className="calendar-year_dropdown-overlay" onClick={() => setShowMonth(false)}></div>
      <div className="calendar-year_dropdown">
        {getYearsBetweenDates(startBlock, TodayDate)?.map((year) => {
          return (
            <div
              onClick={() => {
                handleMonthChange(year)
                setShowMonth(false)
              }}
              className="calendar-year_dropdown-element"
            >
              {year}
            </div>
          )
        })}
      </div>
    </>
  )
}

function getYearsBetweenDates(startDate, endDate) {
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const years = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  return years
}
