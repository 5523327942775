import { IUser } from '@src/modules/auth/data/authTypes'
import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import { Avatar, Tooltip } from 'antd'

import { useTranslation } from 'react-i18next'

type props = {
  owner: IUser | Partial<IUser>
  updatedAt: string | Date
}

const LastUpdateInfos = ({ owner, updatedAt }: props) => {
  const { t } = useTranslation('document')

  return (
    <div className="last_update_infos">
      <div className="user">
        <Avatar src={owner?.avatar}>{owner?.fullName?.slice(0, 1)}</Avatar>

        <Tooltip title={owner?.fullName} placement="top">
          <span>
            {owner?.fullName?.length > 20 ? owner?.fullName?.slice(0, 20) + '...' : owner?.fullName}
          </span>
        </Tooltip>
      </div>

      <span className="last_updated">{t('Last updated')}</span>

      <span className="update_time">{reformulateDate(updatedAt)}</span>
    </div>
  )
}

export default LastUpdateInfos
