import { useTranslation } from 'react-i18next'

export default function MemberEmptyView({ message }: { message?: string }) {
  const { t } = useTranslation(['dailyQuestion'])

  return (
    <div className="emptyView-members">
      <svg
        width="572"
        height="443"
        viewBox="0 0 572 573"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_6047_111756)">
          <path
            d="M286 449.939C388.725 449.939 472 366.664 472 263.939C472 161.214 388.725 77.9395 286 77.9395C183.275 77.9395 100 161.214 100 263.939C100 366.664 183.275 449.939 286 449.939Z"
            fill="white"
          />
        </g>
        <path
          d="M368.855 116.501C376.806 116.501 383.251 110.055 383.251 102.104C383.251 94.1519 376.806 87.7058 368.855 87.7058C360.904 87.7058 354.458 94.1519 354.458 102.104C354.458 110.055 360.904 116.501 368.855 116.501Z"
          fill="#0BA5EC"
        />
        <path
          d="M417.866 146.979C430.771 146.979 441.233 136.891 441.233 124.447C441.233 112.002 430.771 101.914 417.866 101.914C404.961 101.914 394.499 112.002 394.499 124.447C394.499 136.891 404.961 146.979 417.866 146.979Z"
          fill="#A1E1FF"
        />
        <g opacity="0.2">
          <path
            d="M313.118 345.521C313.118 352.379 310.394 358.956 305.545 363.806C300.695 368.655 294.118 371.38 287.26 371.38C280.402 371.38 273.825 368.655 268.975 363.806C264.126 358.956 261.401 352.379 261.401 345.521C261.401 338.663 264.126 332.086 268.975 327.236C273.825 322.387 280.402 319.663 287.26 319.663C294.118 319.663 300.695 322.387 305.545 327.236C310.394 332.086 313.118 338.663 313.118 345.521Z"
            fill="#0BA5EC"
          />
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M286.885 197.507C278.222 197.507 270.788 200.093 265.552 203.791C260.509 207.359 258.014 211.509 257.29 215.091C256.22 219.878 253.316 224.055 249.202 226.726C245.088 229.397 240.09 230.349 235.282 229.378C230.474 228.407 226.238 225.59 223.483 221.532C220.727 217.473 219.673 212.497 220.545 207.669C223.48 193.111 232.504 181.229 243.934 173.148C255.829 164.744 270.904 160.012 286.885 160.012C319.945 160.012 354.22 182.121 354.22 217.625C354.22 238.182 342.002 254.809 326.177 264.506C324.077 265.792 321.744 266.651 319.311 267.034C316.879 267.418 314.395 267.319 312.001 266.743C309.607 266.167 307.35 265.124 305.359 263.676C303.368 262.227 301.681 260.401 300.396 258.3C299.111 256.2 298.252 253.867 297.868 251.435C297.484 249.003 297.583 246.519 298.159 244.125C298.736 241.731 299.778 239.474 301.226 237.482C302.675 235.491 304.502 233.805 306.602 232.519C313.972 228.007 316.726 222.254 316.726 217.625C316.726 210.204 307.481 197.507 286.885 197.507Z"
            fill="#0BA5EC"
          />
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M286.885 247.478C297.229 247.478 305.633 255.857 305.633 266.226V285.646C305.633 290.618 303.657 295.386 300.142 298.902C296.626 302.418 291.857 304.393 286.885 304.393C281.913 304.393 277.145 302.418 273.629 298.902C270.113 295.386 268.138 290.618 268.138 285.646V266.226C268.138 255.882 276.529 247.478 286.885 247.478Z"
            fill="#0BA5EC"
          />
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M332.512 238.894C333.778 241.007 334.616 243.35 334.976 245.787C335.337 248.224 335.213 250.709 334.613 253.098C334.012 255.488 332.947 257.736 331.477 259.713C330.007 261.691 328.162 263.359 326.047 264.623L296.517 282.297C292.251 284.852 287.145 285.607 282.322 284.397C277.5 283.187 273.355 280.111 270.8 275.845C268.246 271.58 267.49 266.474 268.7 261.651C269.91 256.828 272.986 252.684 277.252 250.129L306.796 232.442C308.908 231.178 311.25 230.342 313.686 229.982C316.122 229.623 318.605 229.747 320.993 230.347C323.381 230.948 325.627 232.012 327.604 233.481C329.58 234.95 331.248 236.793 332.512 238.907"
            fill="#0BA5EC"
          />
        </g>
        <path
          d="M290.635 333.07C290.635 337.356 288.932 341.467 285.901 344.498C282.87 347.529 278.759 349.232 274.473 349.232C270.187 349.232 266.076 347.529 263.045 344.498C260.014 341.467 258.312 337.356 258.312 333.07C258.312 328.784 260.014 324.673 263.045 321.642C266.076 318.611 270.187 316.909 274.473 316.909C278.759 316.909 282.87 318.611 285.901 321.642C288.932 324.673 290.635 328.784 290.635 333.07Z"
          fill="#0BA5EC"
        />
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M273.956 175.527C263.522 175.527 254.251 178.63 247.412 183.466C240.818 188.12 236.745 194.145 235.491 200.377C235.261 201.646 234.78 202.856 234.076 203.937C233.373 205.018 232.46 205.948 231.393 206.671C230.326 207.395 229.125 207.899 227.86 208.153C226.596 208.407 225.294 208.407 224.03 208.151C222.765 207.896 221.565 207.391 220.498 206.666C219.432 205.941 218.521 205.01 217.818 203.929C217.115 202.847 216.636 201.636 216.407 200.367C216.178 199.098 216.205 197.796 216.485 196.537C218.89 184.616 226.337 174.609 236.215 167.627C246.507 160.361 259.746 156.12 273.956 156.12C304.016 156.12 332.241 175.967 332.241 204.696C332.241 221.4 322.311 235.415 308.529 243.858C306.334 245.202 303.695 245.62 301.193 245.018C298.691 244.417 296.53 242.846 295.186 240.652C293.841 238.457 293.424 235.819 294.025 233.316C294.627 230.814 296.197 228.653 298.392 227.309C307.817 221.542 312.847 213.177 312.847 204.696C312.847 190.486 297.564 175.527 273.956 175.527ZM273.956 243.6C276.527 243.6 278.994 244.621 280.812 246.44C282.631 248.258 283.653 250.725 283.653 253.297V272.716C283.653 275.288 282.631 277.755 280.812 279.573C278.994 281.392 276.527 282.413 273.956 282.413C271.384 282.413 268.917 281.392 267.099 279.573C265.28 277.755 264.259 275.288 264.259 272.716V253.297C264.259 250.725 265.28 248.258 267.099 246.44C268.917 244.621 271.384 243.6 273.956 243.6Z"
          fill="#0BA5EC"
        />
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M311.812 230.619C312.467 231.711 312.9 232.922 313.087 234.182C313.274 235.442 313.21 236.726 312.9 237.962C312.591 239.197 312.041 240.36 311.281 241.383C310.522 242.405 309.569 243.269 308.476 243.923L278.933 261.61C277.84 262.291 276.622 262.748 275.35 262.953C274.078 263.158 272.779 263.108 271.527 262.805C270.274 262.503 269.095 261.953 268.058 261.19C267.02 260.426 266.145 259.464 265.484 258.359C264.823 257.253 264.388 256.027 264.206 254.752C264.024 253.477 264.097 252.178 264.423 250.932C264.748 249.685 265.318 248.516 266.1 247.493C266.882 246.469 267.86 245.612 268.978 244.97L298.521 227.283C300.726 225.965 303.365 225.576 305.857 226.201C308.349 226.826 310.491 228.415 311.812 230.619Z"
          fill="#0BA5EC"
        />
        <path
          d="M150.581 390.651C167.801 387.461 179.108 370.557 175.836 352.895C172.565 335.234 155.953 323.502 138.732 326.692C121.512 329.882 110.205 346.786 113.477 364.448C116.749 382.109 133.361 393.841 150.581 390.651Z"
          fill="url(#paint0_linear_6047_111756)"
        />
        <g clip-path="url(#clip0_6047_111756)">
          <path
            d="M129.186 365.086L161.298 353.174"
            stroke="white"
            strokeWidth="5.46194"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_6047_111756"
            x="0"
            y="0.939453"
            width="572"
            height="572"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="23" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.895833 0 0 0 0 0.966755 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6047_111756"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6047_111756"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_6047_111756"
            x1="138.732"
            y1="326.692"
            x2="150.581"
            y2="390.651"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B455FF" />
            <stop offset="1" stop-color="#7F61FA" />
          </linearGradient>
          <clipPath id="clip0_6047_111756">
            <rect
              width="39.8904"
              height="39.8904"
              fill="white"
              transform="translate(120.939 368.146) rotate(-65.3522)"
            />
          </clipPath>
        </defs>
      </svg>

      <p className="emptyView-members_title">{message || t('you_have_no_question')}</p>
    </div>
  )
}
