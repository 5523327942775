import { Dropdown, MenuProps, Space } from 'antd'
import { useEffect, useState } from 'react'

import { ReactComponent as Collapsed } from '../../assets/icons/answers/collap.svg'
import LeftArrow from './components/LeftArrow'
import RightArrow from './components/RightArrow'
import YearDropDown from './YearDropDown'
import { getMonthsBetweenDates } from '../MemberView/utils/getMonthsBetweenDates'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'

interface calendarProps {
  BeforeCurrentNumber?: number
  AfterCurrentNumber?: number
  missings?: { day: number; date: string }[]
  setCurrentResponse: React.Dispatch<React.SetStateAction<Date>>
  startDate: Date
  isPrevDisbaled: boolean
  setIsPrevDisbaled: React.Dispatch<React.SetStateAction<boolean>>
  questionId: string
  setDayNum: React.Dispatch<React.SetStateAction<string>>
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
}
const Calendar = ({
  BeforeCurrentNumber = 3,
  AfterCurrentNumber = 4,
  missings,
  startDate,
  setIsPrevDisbaled,
  isPrevDisbaled,
  setCurrentResponse,
  questionId,
  setDayNum,
  setIsEdit,
}: calendarProps) => {
  const { t, ...rest } = useTranslation(['months'])
  const language = rest[1]?.language
  const [date, setDate] = useState(new Date())
  const [showYear, setShowYear] = useState<boolean>(false)
  const today = Date.now()
  const tod = new Date(today)
  const createdAt = new Date(startDate)
  const TodayDate = formatWithLeadingZeros(tod)
  const startBlock = formatWithLeadingZeros(createdAt)
  let disabled = true
  if (missings && missings?.length > 0) {
    disabled = true
  }

  useEffect(() => {
    if (missings && missings?.length > 0 && missings[0]?.date !== TodayDate) {
      disabled = true
      setIsPrevDisbaled(true)
    } else {
      disabled = false
      setIsPrevDisbaled(false)
    }
  }, [missings])

  useEffect(() => {
    setCurrentResponse(new Date())
    setDate(new Date())
  }, [questionId])

  const renderDays = () => {
    const daysArray = []
    let current: string
    for (let i = BeforeCurrentNumber * -1; i <= AfterCurrentNumber; i++) {
      const offset = i
      const currentDate = new Date(date)
      currentDate.setDate(date.getDate() + offset)
      const dayInMonth = currentDate.getDate()
      const dayName = currentDate.toLocaleDateString('en-US', {
        weekday: 'short',
      })
      const IndexDate = formatWithLeadingZeros(currentDate)

      let isMissing: boolean
      if (missings && missings.length > 0) {
        isMissing = missings?.some((missing) => missing?.date === IndexDate)
      }
      if (i === 0) {
        current = IndexDate
        let dayNumber = currentDate.getDay()
        dayNumber = ((dayNumber + 1) % 7) - 1
        setDayNum(dayNumber == -1 ? '6' : dayNumber?.toString())
      }
      const handleDayClick = () => {
        if (currentDate <= new Date(startBlock) || currentDate > tod) {
          return
        }
        setDate(currentDate)
        setCurrentResponse(currentDate)
        setIsEdit(false)
      }
      const isDisabled = currentDate <= new Date(startBlock) || currentDate > tod

      daysArray.push(
        <div
          key={i}
          className={`calendar-day ${i === 0 ? 'current-day' : ''} day ${
            IndexDate === TodayDate ? 'calendar-today' : ''
          }  passed-day ${!isMissing ? 'day-block' : ''}`}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
          onClick={handleDayClick}
        >
          <div className="day-name">{t(dayName)}</div>
          <div className="day-in-month">{dayInMonth}</div>
        </div>,
      )
    }

    if (current === TodayDate) disabled = true
    else {
      disabled = false
    }

    if (current === startBlock) setIsPrevDisbaled(true)
    else {
      setIsPrevDisbaled(false)
    }

    return daysArray
  }
  const handlePrevDay = () => {
    const newDate = new Date(date)
    newDate.setDate(date.getDate() - 1)
    setDate(newDate)
    setCurrentResponse(newDate)
    setIsEdit(false)
  }

  const handleNextDay = () => {
    const newDate = new Date(date)
    newDate.setDate(date.getDate() + 1)
    setDate(newDate)
    setCurrentResponse(newDate)
    setIsEdit(false)
  }
  const handleMonthChange = (value: number) => {
    const newDate = new Date(date.getFullYear(), value, date.getDate())
    const startDate = new Date(startBlock)
    if (newDate > tod) {
      setDate(tod)
      setCurrentResponse(tod)
    } else if (newDate < startDate) {
      setDate(startDate)
      setCurrentResponse(startDate)
    } else {
      setDate(newDate)
      setCurrentResponse(newDate)
    }
    setIsEdit(false)
  }
  const handleYearChange = (value: number) => {
    const newDate = new Date(value, date.getMonth(), date.getDate())
    const startDate = new Date(startBlock)
    if (newDate > tod) {
      setDate(tod)
      setCurrentResponse(tod)
    } else if (newDate < startDate) {
      setDate(startDate)
      setCurrentResponse(startDate)
    } else {
      setDate(newDate)
      setCurrentResponse(newDate)
    }
    setIsEdit(false)
  }
  const items: MenuProps['items'] = getMonthsBetweenDates(new Date(startBlock), tod).map(
    (el, index: number) => {
      return {
        label: <p>{t(el?.name.toLowerCase())}</p>,
        key: index,
        onClick: () => {
          handleMonthChange(el?.number)
        },
      }
    },
  )

  return (
    <div className="calender-daily_question">
      <span className="calender-daily_question_title">
        <p className="calender-daily_question_title-month">
          {getMonthsBetweenDates(new Date(startBlock), tod)?.length > 0 && (
            <Dropdown menu={{ items }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <p className="calender-daily_question_title-month">
                    {t(`${getMonthYearFromDate(date).month.toLowerCase()}`)}
                    <Collapsed />
                  </p>
                </Space>
              </a>
            </Dropdown>
          )}
        </p>
        <p className="calender-daily_question_title-year" onClick={() => setShowYear(!showYear)}>
          {getMonthYearFromDate(date).year}
          <Collapsed />
          {showYear && (
            <YearDropDown
              date={date}
              setShowMonth={setShowYear}
              handleMonthChange={handleYearChange}
              TodayDate={tod}
              startBlock={new Date(startBlock)}
            />
          )}
        </p>
      </span>
      <div
        className="follow-up-calendar-container"
        style={{
          direction: language === 'ar' ? 'rtl' : 'ltr',
        }}
      >
        <Button
          disabled={isPrevDisbaled}
          style={{ cursor: !isPrevDisbaled ? 'pointer' : 'not-allowed', padding: '5px' }}
          onClick={handlePrevDay}
          className="calendar-daily-button"
        >
          {language === 'ar' ? <RightArrow /> : <LeftArrow />}
        </Button>

        <div className="calendar-days">{renderDays()}</div>

        <Button
          disabled={disabled}
          style={{ cursor: !disabled ? 'pointer' : 'not-allowed', padding: '5px' }}
          onClick={handleNextDay}
          className="calendar-daily-button"
        >
          {language === 'ar' ? <LeftArrow /> : <RightArrow />}
        </Button>
      </div>
    </div>
  )
}

export default Calendar

function getMonthYearFromDate(date: Date) {
  return {
    month: date?.toLocaleString('default', { month: 'long' }),
    year: date?.getFullYear(),
  }
}

const formatWithLeadingZeros = (date) => {
  return `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}/${
    date.getDate() < 10 ? '0' : ''
  }${date.getDate()}/${date.getFullYear()}`
}
