import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LeftIcon } from '../../../assets/icons/task/arrow-left.svg'
import { ReactComponent as RightIcon } from '../../../assets/icons/task/arrow-right.svg'
import Button from '@src/modules/shared/components/Button/Button'

interface Props {
  value?: Dayjs
  onChange: (date: Dayjs) => void
}

const MonthSelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation(['global'])
  const [valueState, setValueState] = useState<Dayjs>(value)

  useEffect(() => {
    if (!valueState) setValueState(dayjs(new Date()))
  }, [])

  useEffect(() => {
    if (value) setValueState(value)
  }, [value])

  const months = [
    t('JAN'),
    t('FEB'),
    t('MAR'),
    t('APR'),
    t('MAY'),
    t('JUN'),
    t('JUL'),
    t('AUG'),
    t('SEP'),
    t('OCT'),
    t('NOV'),
    t('DEC'),
  ]
  const currentMonth = valueState ? valueState.month() : 0

  const prevMonth = () => {
    if (currentMonth === 0) {
      onChange(valueState?.subtract(1, 'year')?.month(11))
      setValueState(valueState?.subtract(1, 'year')?.month(11))
    } else {
      onChange(valueState.subtract(1, 'month'))
      setValueState(valueState.subtract(1, 'month'))
    }
  }

  const nextMonth = () => {
    if (currentMonth === 11) {
      onChange(valueState?.add(1, 'year').month(0))
      setValueState(valueState?.add(1, 'year').month(0))
    } else {
      onChange(valueState?.add(1, 'month'))
      setValueState(valueState?.add(1, 'month'))
    }
  }

  const getMonthDisplay = (index) => {
    if (index < 0) return months[11]
    if (index > 11) return months[0]
    return months[index]
  }
  return (
    <div className="month-selector">
      <Button className="month-selector__arrows" onClick={prevMonth}>
        <RightIcon />
      </Button>

      <div>{getMonthDisplay(currentMonth - 1)}</div>

      <div className="active-month">{months[currentMonth]}</div>

      <div>{getMonthDisplay(currentMonth + 1)}</div>

      <Button className="month-selector__arrows" onClick={nextMonth}>
        <LeftIcon />
      </Button>
    </div>
  )
}

export default MonthSelector
