import { Dropdown } from 'antd'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { ReactComponent as RemoveSVG } from '../../../assets/icons/customFields/remove.svg'
import { removeDuplicate } from '../../ListView/utils/listViewManagment'
import { isDarkColor } from '@src/modules/shared/utils/isDarkColor'

const LabelField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const { typeConfig } = field

  const option = typeConfig?.options

  let labels = field.custom ? customFieldValue : task[field.value]

  const removeLabel = (e, label) => {
    e.stopPropagation()
    saveCustomFieldValue([...(labels || []).filter((l) => l !== label)])
  }

  if (!Array.isArray(labels)) labels = null

  return (
    <div className="list-label-field list-dropdown-field" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        placement="bottomCenter"
        dropdownRender={() => (
          <div className="dropdown-field-dropdown ant-dropdown-menu">
            <div className="selected-labels">
              {labels?.map((label, i) => {
                const selectedOption = option.find((opt) => opt.value === label)
                return (
                  <div
                    key={i}
                    className="dropdown-field-option"
                    style={{
                      backgroundColor: selectedOption.color,
                    }}
                    onClick={() => saveCustomFieldValue(labels.filter((l) => l !== label))}
                  >
                    <OverflowTooltip
                      style={{ color: isDarkColor(selectedOption.color) ? '#fff' : '#000' }}
                    >
                      {selectedOption.value}
                    </OverflowTooltip>
                    <RemoveSVG onClick={(e) => removeLabel(e, label)} />
                  </div>
                )
              })}
            </div>
            <p>Select an option</p>
            <div className="dropdown-field-options">
              {option?.length ? (
                option?.map((opt, i) => (
                  <div
                    key={i}
                    className="dropdown-field-option"
                    style={{
                      backgroundColor: opt.color,
                    }}
                    onClick={() =>
                      saveCustomFieldValue(removeDuplicate([...(labels || []), opt.value]))
                    }
                  >
                    <OverflowTooltip style={{ color: isDarkColor(opt.color) ? '#fff' : '#000' }}>
                      {opt.value}
                    </OverflowTooltip>
                  </div>
                ))
              ) : (
                <div>No options found</div>
              )}
            </div>
          </div>
        )}
        trigger={['click']}
      >
        {labels?.length > 0 ? (
          <div className="dropdown-field-value">
            {labels?.map((value, i) => {
              const label = option.find((opt) => opt.value === value)
              return (
                <div
                  key={i}
                  className="dropdown-field-value-label"
                  style={{
                    backgroundColor: label.color,
                  }}
                >
                  <OverflowTooltip style={{ color: isDarkColor(label.color) ? '#fff' : '#000' }}>
                    {label.value}
                  </OverflowTooltip>
                  <RemoveSVG onClick={(e) => removeLabel(e, label.value)} />
                </div>
              )
            })}
          </div>
        ) : (
          <div className="empty-custom-field dropdown-field-value">-</div>
        )}
      </Dropdown>
    </div>
  )
}

export default LabelField
