import { detectLanguage } from '@src/modules/spaces/utils/detectLanguage'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { message } from 'antd'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import useDebounce from '../../../../shared/hooks/useDebounce'

type Props = {
  taskId: string
  defaultTaskName: string
}

function TaskName({ taskId, defaultTaskName }: Props) {
  const textAreaRef = useRef<any>(null)
  const [updateTask] = useUpdateTaskMutation()
  const isFirstRender = useRef(true)
  const [taskName, setTaskName] = useState<string>(defaultTaskName)

  useDebounce(
    () => {
      if (!isFirstRender.current && taskName !== defaultTaskName) {
        updateTask({ id: taskId, name: taskName }).catch((err) => {
          message.error(err.message)
        })
      }
    },
    500,
    [taskName],
  )

  const handleInputChange = (e) => setTaskName(e.target.value)

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  useEffect(() => {
    if (defaultTaskName !== taskName) setTaskName(defaultTaskName)
  }, [defaultTaskName])

  const language = detectLanguage(taskName)
  const textDirection = language === 'arabic' ? 'rtl' : 'ltr'
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      event.preventDefault()
    }
  }
  return (
    <>
      <textarea
        ref={textAreaRef}
        rows={taskName.length > 200 ? 4 : taskName.length > 140 ? 3 : taskName.length > 70 ? 2 : 1}
        style={{
          overflowY: 'auto',
          direction: textDirection,
        }}
        defaultValue={taskName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="edit-task-title"
      />
      <hr className="under-task-name" />
    </>
  )
}

export default TaskName
