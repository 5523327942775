import { Badge, Button, Dropdown, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactComponent as AddIcon } from '../../assets/icons/usersList/AddIcon.svg'
import { ReactComponent as CloseSVG } from '../../assets/icons/tasks/close.svg'
import { ReactComponent as AddUserIcon } from '../../assets/icons/usersList/user-add.svg'
import { Avatar } from '@src/modules/shared/components/Avatar'
import { MembersList } from '../TasksFilter/components/MembersFilter/MembersFilter'
import { useAppDispatch } from '@src/modules/shared/store'
import { MembersListApi } from '@src/modules/settings/services/membersListApi'
import { IUser } from '@src/modules/auth/data/authTypes'

export const isActiveUser = (usersList: any[], user: any) => {
  if (!usersList) return false
  return !!usersList.find((member) => member.id === user.id)
}

interface TaskUsersProps {
  userNumber: number
  displayAddButton: boolean
  members: IUser[]
  placement?: 'bottomRight' | 'bottomCenter'
  disable?: boolean
  children?: any
  listId?: string
  allowRemoveUser?: boolean
  multiple?: boolean
  setAssignedMembersList?: (members: IUser[]) => void
  assignedMembers?: IUser[]
  onClick?: (e: any) => void
}

// 1. setAssignedMembersList is a function that is passed from the parent component
// 2. it is used to update the assigned members list in the parent component
// 3. Then get the updated list from the parent component and pass it to the child component
// Example: src/modules/tasks/components/EditTaskPopup/index.tsx

const TaskUsers: React.FC<TaskUsersProps> = ({
  userNumber,
  displayAddButton,
  members,
  placement = 'bottomRight',
  children,
  allowRemoveUser = true,
  disable = false,
  multiple = true,
  setAssignedMembersList,
  listId,
  onClick,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible)
  }
  const dispatch = useAppDispatch()
  const [assignedMembers, setAssignedMembers] = useState<IUser[]>(null)

  const updateMembersList = (newMember: IUser) => {
    setAssignedMembers((prev) => {
      if (!multiple) return [newMember]
      const newArr = [...(prev || members || [])]
      const memberExistIndex = newArr?.findIndex((member) => newMember?.id === member?.id)
      if (memberExistIndex !== -1) newArr?.splice(memberExistIndex, 1)
      else newArr?.push(newMember)
      return newArr
    })
  }
  useEffect(() => {
    if (assignedMembers) setAssignedMembersList(assignedMembers as any)
  }, [assignedMembers])
  useEffect(() => {
    dispatch(
      MembersListApi.util.updateQueryData('getMembersList', undefined, (draft) => {
        if (draft && draft.payload) draft.payload = []
        return draft
      }),
    )
  }, [listId])

  useEffect(() => {
    setAssignedMembers(null)
  }, [dropdownVisible])

  const removeUser = (event, user) => {
    event.stopPropagation()
    updateMembersList(user)
  }

  return (
    <div className="task-users" onClick={onClick}>
      <Dropdown
        open={dropdownVisible}
        onOpenChange={handleDropdownVisibleChange}
        disabled={disable}
        dropdownRender={() => (
          <MembersList
            setMembers={updateMembersList}
            listId={listId}
            assignedMember={assignedMembers || members}
          />
        )}
        placement={placement}
        trigger={['click']}
      >
        {children || (
          <div className="task-users-list">
            {members?.length > 0
              ? members?.map((user: any, index: number) =>
                  index < userNumber ? (
                    <Tooltip title={user?.fullName || user?.email} key={index}>
                      <div className="task-users-item">
                        {!allowRemoveUser ? (
                          <Badge>
                            <Avatar
                              text={user?.fullName || user?.name || user?.email}
                              src={user?.avatar || user?.icon}
                              color={user?.color || '#A6A6A6'}
                              size={28}
                            />
                          </Badge>
                        ) : (
                          <Badge
                            color="red"
                            size={'small'}
                            count={
                              <div
                                className="task-users-item-remove"
                                onClick={(e) => removeUser(e, user)}
                              >
                                <CloseSVG width={9} height={9} />
                              </div>
                            }
                          >
                            <Avatar
                              text={user?.fullName || user?.name || user?.email}
                              src={user?.avatar || user?.icon}
                              color={user?.color || '#A6A6A6'}
                              size={28}
                            />
                          </Badge>
                        )}
                      </div>
                    </Tooltip>
                  ) : index === userNumber ? (
                    <React.Fragment key={index}>
                      <span className="members-rest">+{members?.length - userNumber}</span>
                    </React.Fragment>
                  ) : null,
                )
              : displayAddButton === false && (
                  <div className="empty-task-info" key="empty">
                    <AddUserIcon />
                  </div>
                )}
            <React.Fragment>
              {displayAddButton && (
                <Button type="dashed" shape="circle" icon={<AddIcon />} className="add-user-btn" />
              )}
            </React.Fragment>
          </div>
        )}
      </Dropdown>
    </div>
  )
}

export default TaskUsers
