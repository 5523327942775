import React from 'react'

import ImagePlaceholder from '../icons/ImagePlaceholder'
import PdfPlaceholder from '../icons/PDFPlaceholder'
import VideoPlaceholder from '../icons/VideoPlaceholder'
import Audiopalceholder from '../icons/Audiopalceholder'

const simplifyMediaType = (mediaType: string): string => {
  switch (mediaType) {
    case 'application/pdf':
      return 'pdf'
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
      return 'image'
    case 'video/mp4':
      return 'video'
    case 'audio/mp3':
      return 'audio'
    default:
      return 'image'
  }
}

const getSvgBasedOnMediaType = (
  mediaType: string,
): React.ReactComponentElement<SVGElement & any> => {
  const simplifiedMediaType = simplifyMediaType(mediaType)
  switch (simplifiedMediaType) {
    case 'image':
      return <ImagePlaceholder />
    case 'pdf':
      return <PdfPlaceholder />
    case 'video':
      return <VideoPlaceholder />
    case 'audio':
      return <Audiopalceholder />
    default:
      return <ImagePlaceholder />
  }
}

export default getSvgBasedOnMediaType
