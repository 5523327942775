import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

import { FC, ReactNode, useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import Calendar from '../components/Calendar'
import FollowUp from '../components/FollowUp/FollowUp'
import GridItem from '../components/GridItem'
import Header from '../components/Header'
import MyTasks from '../components/MyTasks'
import RecentActivities from '../components/RecentActivities'
import TasksOverview from '../components/TasksOverview/TasksOverview'
import ViewLayout from '@src/modules/shared/components/ViewLayout'
import YearlyOverview from '../components/YearlyOverview/YearlyOverview'
import { dashboardLayouts } from '../utils/layoutSettings'
import { useAppSelector } from '@src/modules/shared/store'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useTranslation } from 'react-i18next'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export const disableDashboard = false
const availableHandles = ['e', 'se']
export interface DashbaordProps {}

const Dashbaord: FC<DashbaordProps> = () => {
  const { t, i18n } = useTranslation(['dashboard'])
  const { sidebar } = useAppSelector((state) => state.settings)
  const [allowCustomize, setAllowCustomize] = useState<boolean>(false)
  const { width } = useWindowSize()
  const sidebarOpen = sidebar['layout-sidebar']
  useTitle(t('dashboard'))
  const theme = document.querySelector('body')?.id

  useEffect(() => {
    const gridLayoutContainer = document.querySelector('.react-grid-layout')
    const children = document.querySelectorAll('.react-grid-item')
    gridLayoutContainer.setAttribute('dir', 'ltr')
    children.forEach((child) => child.setAttribute('dir', i18n.dir()))
  }, [i18n, i18n.language])

  const { layouts, layout } = dashboardLayouts({
    availableHandles,
    items: {
      1: { title: t('tasks_overview'), render: <TasksOverview /> },
      2: {
        title: t('yearly_overview'),
        render: <YearlyOverview />,
      },
      3: { title: t('my_tasks'), render: <MyTasks /> },
      4: { title: t('recent_activities'), render: <RecentActivities /> },
      5: { title: t('follow_up'), render: <FollowUp /> },
      6: { title: t('calendar'), render: <Calendar /> },
    },
  })

  const renderGridItem = (gridItemNode: ReactNode, key: string) => (
    <div key={key}>{gridItemNode}</div>
  )

  return (
    <ViewLayout
      header={Header}
      headerProps={{ onAllowChange: (allow) => setAllowCustomize(allow) }}
    >
      <div className={`dashboard ${theme}-theme`}>
        <ResponsiveReactGridLayout
          className={`dashboad-layout`}
          layouts={layouts}
          useCSSTransforms={false}
          cols={{ lg: 153, md: 153, sm: 153, xs: 153, xxs: 153 }}
          rowHeight={10}
          draggableHandle=".dashboard-drag-handler"
          width={(sidebarOpen ? width - 220 : width) - 110}
          isDraggable={allowCustomize}
          isResizable={allowCustomize}
        >
          {layout.map((item) => {
            return renderGridItem(
              <GridItem allowCustomize={allowCustomize} item={item.item}>
                {item.item.render}
              </GridItem>,
              item.i,
            )
          })}
        </ResponsiveReactGridLayout>
      </div>
    </ViewLayout>
  )
}

export default Dashbaord
