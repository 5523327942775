export const getFilterCount = (filter) => {
  let countResult = 0
  filter = filter ? JSON.parse(filter) : []
  if (filter?.length !== 0)
    filter.where?.forEach((filter) => {
      countResult += Object.keys(filter).length
    })

  return countResult
}
