/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'
import { CreateListPayload } from './fileTypes'
import { FileType } from './fileSlice'

export const createList = createAsyncThunk(
  'files/create-list',
  async (query: CreateListPayload) => {
    try {
      const response = await axiosInstance.post(`/api/lists`, query)

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const updateList = createAsyncThunk('files/update-list', async (query: FileType) => {
  try {
    const { id, ...body } = query
    const response = await axiosInstance.patch(`/api/lists/${id}`, body)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const moveList = createAsyncThunk(
  'files/move-list',
  async (query: { listId: string; folderId?: string; spaceId?: string }) => {
    try {
      const response = await axiosInstance.patch(`/api/moveList`, query)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const bulklists = createAsyncThunk(
  'spaces/bulk-lists',
  async (query: { lists: { id: string; order: number; newOrder: number }[] }) => {
    try {
      const response = await axiosInstance.patch(`/api/bulklists`, query)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getOneList = createAsyncThunk('files/get-list', async (query: { id: string }) => {
  try {
    const response = await axiosInstance.get(`/api/lists/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const deleteList = createAsyncThunk('files/delete-list', async (query: { id: string }) => {
  try {
    const response = await axiosInstance.delete(`/api/lists/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
