import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'

const DeleteTeamModal = ({ open, handleClose, id, data }: any) => {
  const { t } = useTranslation(['modals'])

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      closable={false}
    >
      <div className="delete-team-modal">
        <div className="team-modal-header">
          <p className="modal-title">{data?.title}</p>
          <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
        </div>

        <div className="delete-image-container">
          <img src={data.icon} />
        </div>

        <p className="title-description">{data.subtitle}</p>

        <p className="delete-description">
          <span>{t('DeleteTeamModal.Important:')}</span> {data.description}
        </p>

        <div className="buttons-container">
          <Button
            children={t('DeleteTeamModal.Cancel')}
            htmlType="button"
            onClick={() => handleClose(id)}
            disabled={false}
            className="cancel-button"
          />

          <Button
            children={t('DeleteTeamModal.Delete')}
            htmlType="submit"
            className="delete-button"
            disabled={false}
            onClick={() => {
              data.handleDelete()
              handleClose(id)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteTeamModal
