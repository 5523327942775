import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import { Rate } from 'antd'

const RateField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const { typeConfig } = field

  const { stars } = typeConfig

  const rateValue = field?.custom ? customFieldValue : task?.[field?.value]

  return (
    <div className="list-rate-field" onClick={(e) => e.stopPropagation()}>
      <Rate
        defaultValue={rateValue}
        count={stars}
        onChange={(value) => saveCustomFieldValue(value.toString())}
        style={{ fontSize: 20 }}
      />
    </div>
  )
}

export default RateField
