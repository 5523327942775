import { useParams, useSearchParams } from 'react-router-dom'
import { memo, useEffect, useRef, useState } from 'react'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import {
  useBulkUpdateTasksMutation,
  useGetBoardGroupsQuery,
  useUpdateTaskMutation,
} from '../../services/tasksApi'
import { store, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { ControlledTreeEnvironment } from 'react-complex-tree'
import StatusTree from './StatusTree'
import { RenderItemWrapper, TaskSkeleton, getFakeTask } from './utils/renderItem'
import { renderArrow } from './utils/renderArrow'
import { resetSocketEvents, setClear } from '../../data/tasksSlice/tasksSlice'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { parseBoolean } from '@src/modules/shared/utils/parseBoolean'
import { setSelectedItems as setSelectedItemsStore } from './../../data/tasksSlice/tasksSlice'
import { manageSocket } from './utils/manageSocket'
import deepClone from 'deep-clone'
import { GroupByFields } from '../../features/Tasks/Tasks'
import { priorities } from '@src/modules/spaces/__mock__'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { useTranslation } from 'react-i18next'
import { getOneTask } from '../../data/tasksSlice/tasksThunk'
import {
  addSkeletons,
  clearExpandedItems,
  convertTasksToTemplateTree,
  generateFakeTask,
  getStateViewByTree,
  mergeFakeTask,
  reformulateTasksCountState,
  reformulateTreeViewState,
  reformulateTrees,
  removeDuplicate,
  removeSkeletons,
  setNewStatusTree,
} from './utils/listViewManagment'
import { getBulkUpdateFieldByGroupBy } from '../../utils/getSortFieldByGroupBy'
import { ListType } from '@src/modules/spaces/components/List/List'
import { getSort } from '../Board/StatusCard/StatusCard'
import {
  useCreateCustomFieldValueMutation,
  useUpdateCustomFieldValueMutation,
} from '../../services/customFieldsApi'
import { message } from 'antd'

export const maxLevel = import.meta.env.VITE_APP_MAX_LEVEL_TASKS || 1

export const defaultOpenStatus = true

const TreeContainer = memo(({ containerProps, children, isDisabled, isOpen }: any) => {
  const treeContainerRef = useRef<any>()
  // const inTheView = useIsInViewport(treeContainerRef)
  return (
    <div
      {...containerProps}
      ref={(el) => {
        containerProps.ref.current = el
        treeContainerRef.current = el
      }}
      style={{
        ...containerProps.style,

        // background: inViewport ? 'red' : 'transparent',
        // height: !inViewport ? defaultHeight : 'fit-content',
      }}
    >
      {!isDisabled && isOpen ? children : null}
    </div>
  )
})

const filterTrees = (status, tasksCount, setActiveTree, isOpen) => {
  if (!status) return status

  const statusTasksNotCompletedIndex = status.findIndex(
    (statusItem) =>
      tasksCount[statusItem.id]?.total_items > tasksCount[statusItem.id]?.loadedTasks &&
      isOpen(statusItem.id),
  )

  if (statusTasksNotCompletedIndex !== -1) {
    const activeStatuses = status
      .slice(0, statusTasksNotCompletedIndex + 1)
      .map((statusItem) => statusItem.id)

    setActiveTree(activeStatuses[activeStatuses.length - 1])

    return status.map((statusItem) =>
      activeStatuses.includes(statusItem.id) ? statusItem : { ...statusItem, isDisabled: true },
    )
  }

  return status
}

export const socketListManagement = (
  { deletedTask, updatedTask, bulkUpdateTask, updatedMany },
  setTree: (tree) => void,
  setTasksCount: (count) => void,
  listId: string,
  groupBy: ListViewProps['groupBy'],
  t,
) => {
  if (updatedTask) {
    manageSocket.updateEvent(
      { ...(updatedTask || bulkUpdateTask), order: bulkUpdateTask?.order || updatedTask?.order },
      setTree,
      setTasksCount,
      groupBy,
      updatedTask,
    )
  } else if (bulkUpdateTask?.taskUUID) {
    store
      .dispatch(getOneTask({ id: bulkUpdateTask?.taskUUID }))
      .unwrap()
      .then((res) => {
        manageSocket.updateEvent(
          { ...res?.payload, order: res?.payload?.order },
          setTree,
          setTasksCount,
          groupBy,
          bulkUpdateTask,
        )
      })
      .catch(() => {
        message.error(t('task-not-found'))
      })
  } else if (deletedTask) {
    setTree((prev) => manageSocket.deleteEvent(deletedTask, setTasksCount, prev, groupBy?.type))
  } else if (updatedMany) {
    manageSocket.updateManyEvent({ ...updatedMany, listId }, setTree, setTasksCount, groupBy)
  }
  store.dispatch(resetSocketEvents())
}

interface ListViewProps {
  search: string
  groupBy: {
    type: GroupByFields
    custom: boolean
    id: string
  }
  list: ListType
}

const ListView = ({ search, groupBy, list }: ListViewProps) => {
  const { deletedTask, updatedTask, bulkUpdateTask, updatedMany } = useAppSelector(
    (state) => state.tasks,
  ) //socket updates "socket events"
  const { t } = useTranslation(['tasks'])
  const { listId } = useParams()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { clearSelected, selectedItems } = useAppSelector((state) => state.tasks)
  const expandAllTasks = searchParams.get('expand')
  const filterByMe = searchParams.get('byMe')
  const [bulkupdate] = useBulkUpdateTasksMutation()
  const [updateTask] = useUpdateTaskMutation()
  const [tree, setTree] = useState<any>()
  const [treesViewState, setTreesViewState] = useState<any>()
  const { data: groupByData, refetch } = useGetBoardGroupsQuery(
    { source: groupBy?.custom ? `cf_${groupBy?.id}` : groupBy?.id, fileId: list?.id },
    { skip: !list?.id || !groupBy?.id, refetchOnMountOrArgChange: true },
  )
  const [createCustomFieldValue] = useCreateCustomFieldValueMutation()
  const [updateCustomFieldValue] = useUpdateCustomFieldValueMutation()
  const { headers } = useAppSelector((state) => state.tasks)
  const [tasksCount, setTasksCount] = useState({})

  const treeRef = useRef<any>()
  let activeTree = null

  useEffect(() => {
    if (
      list?.id &&
      GroupByFields.PRIORITY !== groupBy?.type &&
      GroupByFields.STATUS !== groupBy?.type
    )
      refetch()
    socketListManagement(
      { deletedTask, updatedTask, bulkUpdateTask, updatedMany },
      setTree,
      setTasksCount,
      listId,
      groupBy,
      t,
    )
  }, [deletedTask, updatedTask, bulkUpdateTask, updatedMany])

  useEffect(() => {
    if (list && list?.id === listId && list.status) {
      let dataGroups = groupByData
      if (list?.status && groupBy?.type === GroupByFields.STATUS) {
        dataGroups = list.status
      } else if (groupBy?.type === GroupByFields.PRIORITY) {
        dataGroups = priorities
      } else dataGroups = groupByData

      setTree((prev) => reformulateTrees(dataGroups, prev))
      setTasksCount((prev) => reformulateTasksCountState(dataGroups, prev))
      setTreesViewState(reformulateTreeViewState(dataGroups))
    }
  }, [list?.id, list.status, groupBy?.id, groupByData])

  const expendAllTasksHandler = (tasks?: TaskType[]) => {
    const tasksToExpend = []

    Object.keys(tree)?.map((key) => {
      const item = tree[key]
      if (item?.content?.totalSubtask > 0) tasksToExpend.push(item?.id)
    })

    if (tasks?.length > 0)
      tasksToExpend.push(...tasks.filter((task) => task?.totalSubtask > 0).map((task) => task?.id))

    let newObj = {}
    Object.keys(treesViewState)?.map((treeId) => {
      const tree = treesViewState[treeId]
      newObj = { ...newObj, [treeId]: { ...tree, expandedItems: tasksToExpend } }
    })

    setTreesViewState(newObj)
  }

  useEffect(() => {
    if (parseBoolean(expandAllTasks)) {
      if (!treesViewState) return
      expendAllTasksHandler()
    } else {
      clearSelectedItems('expandedItems')
    }
  }, [expandAllTasks])

  useEffect(() => {
    if (treesViewState) dispatch(setSelectedItemsStore(getAllTreeState('selectedItems')))
    if (clearSelected) {
      clearSelectedItems('selectedItems')
      dispatch(setClear(false))
    }
  }, [treesViewState, clearSelected])

  const clearSelectedItems = (key: string) => {
    let newObj = {}
    if (!treesViewState) return
    Object.keys(treesViewState)?.map((treeId) => {
      const tree = treesViewState[treeId]
      newObj = { ...newObj, [treeId]: { ...tree, [key]: [] } }
    })
    setTreesViewState(newObj)
  }

  const increaseChildrensCount = (item, isRoot: boolean) => {
    if (isRoot) {
      const oldInfo = tasksCount[item?.index]
      if (!oldInfo) return
      saveTasksCount(item?.index, { ...oldInfo, total_items: oldInfo?.total_items + 1 })
      return item
    } else {
      return {
        ...item,
        content: { ...item?.content, totalSubtask: item?.content?.totalSubtask + 1 },
      }
    }
  }

  const decreaseChildrensCount = (item, isRoot: boolean) => {
    if (isRoot) {
      const oldInfo = tasksCount[item?.index]
      if (!oldInfo) return
      saveTasksCount(item?.index, { ...oldInfo, total_items: oldInfo?.total_items - 1 })
      return item
    } else {
      return {
        ...item,
        content: { ...item?.content, totalSubtask: item?.content?.totalSubtask - 1 },
      }
    }
  }

  const dropEndEvent = (dragItems, target) => {
    const item = dragItems[0]
    const trees = document.querySelectorAll('.list-tree')
    trees.forEach((tree: HTMLElement) => tree.classList.remove('drag-over-tree'))

    const saveCustomFieldValue = (value) => {
      const taskField = item?.content?.customFieldsValues?.find(
        (customField) => customField.customFieldId === groupBy.id,
      )

      if (taskField) updateCustomFieldValue({ id: taskField.id, value })
      else
        createCustomFieldValue({
          value,
          task: {
            id: item?.id,
          },
          customField: {
            id: groupBy?.id,
          },
        })
    }

    const parent: any = Object.values(tree).find((potentialParent: any) =>
      potentialParent.children?.includes(item.index),
    )
    const isTreeRoot = parent?.isRoot
    const newTree = target?.treeId

    const fieldToUpdate = groupBy?.custom
      ? null
      : groupBy?.type === GroupByFields.STATUS
        ? 'statusId'
        : groupBy?.type

    const getDataToUpdate = (data) => {
      if (groupBy?.type === GroupByFields.ASSIGNEE && data) {
        return data !== 'not_assigned_users' ? data?.split('&') : []
      } else return data
    }

    if (target.targetType === 'item' || target.targetType === 'root') {
      if (target.targetItem === parent.index) {
        // NOOP
      } else {
        const newParent = tree[target.targetItem]
        setTree((prev) => ({
          ...prev,
          [parent.index]: decreaseChildrensCount(
            {
              ...prev[parent.index],
              children: parent.children.filter((child) => child !== item.index),
            },
            isTreeRoot,
          ),
          [target.targetItem]: increaseChildrensCount(
            {
              ...prev[target.targetItem],
              children: [item.index, ...(prev[target.targetItem].children ?? [])],
            },
            newParent?.isRoot,
          ),
          [item.index]: setNewStatusTree(item, newTree, newParent?.isRoot, prev, groupBy),
        }))

        saveCustomFieldValue(newParent?.content?.value)

        updateTask({
          id: item?.id,
          parentId: newParent?.isRoot ? null : target.targetItem,
          [fieldToUpdate]: getDataToUpdate(newParent?.isRoot ? newTree : null),
        })
      }
    } else {
      const newParent = tree[target.parentItem]
      let newParentChildren = [...(newParent.children ?? [])].filter(
        (child) => child !== item.index,
      )

      if (target.parentItem === item.index) {
        // Trying to drop inside itself
        return
      }

      // if (newParent?.id && !expandedItems?.includes(newParent?.id)) {
      //   treeRef.current.expandItem(newParent?.id)
      //   return
      // }

      if (target.parentItem === parent.index) {
        const isOldItemPriorToNewItem =
          ((newParent.children ?? []).findIndex((child) => child === item.index) ?? Infinity) <
          target.childIndex
        newParentChildren.splice(
          target.childIndex - (isOldItemPriorToNewItem ? 1 : 0),
          0,
          item.index,
        )

        setTree((prev) => ({
          ...prev,
          [target.parentItem]: { ...prev[target.parentItem], children: newParentChildren },
        }))

        const taskBelow =
          newParentChildren[target.childIndex - (isOldItemPriorToNewItem ? 1 : 0) - 1]

        bulkupdate({
          taskUUID: item?.id,
          statusId: item?.content?.status?.id,
          fileId: listId,
          targetIndex: tree[target.parentItem]?.isRoot
            ? getBulkUpdateFieldByGroupBy(groupBy?.type)
            : 'subtaskIndex',
          taskBelowUUID: taskBelow,
          parentId: tree[target.parentItem]?.isRoot ? null : target.parentItem,
        })
      } else {
        newParentChildren.splice(target.childIndex, 0, item.index)
        setTree((prev) => ({
          ...prev,
          [parent?.index]: decreaseChildrensCount(
            {
              ...prev[parent.index],
              children: parent.children.filter((child) => child !== item.index),
            },
            isTreeRoot,
          ),
          [target.parentItem]: increaseChildrensCount(
            {
              ...prev[target.parentItem],
              children: newParentChildren,
            },
            newParent?.isRoot,
          ),
          [item.index]: setNewStatusTree(item, newTree, newParent?.isRoot, prev, groupBy),
        }))

        saveCustomFieldValue(newParent?.content?.value)

        if (newParent?.isRoot) {
          const taskBelow = newParentChildren[target.childIndex - 1]

          bulkupdate({
            taskUUID: item?.id,
            ...(groupBy?.type === GroupByFields.STATUS ? { statusId: newTree } : {}),
            fileId: listId,
            [fieldToUpdate]: getDataToUpdate(newTree),
            targetIndex: getBulkUpdateFieldByGroupBy(groupBy?.type),
            taskBelowUUID: taskBelow,
            parentId: null,
          })
        } else
          updateTask({
            id: item?.id,
            parentId: target.parentItem,
            //TODO: should be fixed
            order: target.childIndex?.toString(),
          })
      }
    }
  }

  const removeLocalTask = (id: string = 'fake-task') => {
    setTree((prev) => {
      const treeClone = deepClone(prev)
      const fakeTask = treeClone[id]
      const fakeTaskParentId = fakeTask?.parentId
      if (treeClone[fakeTaskParentId]?.children) {
        treeClone[fakeTaskParentId].children = treeClone[fakeTaskParentId].children.filter(
          (childId) => childId !== id,
        )
        const totalsubtasks = treeClone[fakeTaskParentId].content?.totalSubtask
        if (id !== 'fake-task') {
          if (treeClone[fakeTaskParentId]?.isRoot) {
            decreaseChildrensCount({ index: fakeTaskParentId }, true)
          } else if (totalsubtasks > 0)
            treeClone[fakeTaskParentId].content = {
              ...treeClone[fakeTaskParentId].content,
              totalSubtask: totalsubtasks - 1,
            }
        }
      }
      if (treeClone[fakeTaskParentId]) treeClone[fakeTaskParentId].isLoading = false

      if (fakeTask) {
        clearExpandedItems(treesViewState, setExpandedItems, (id: string) => treeClone[id])
      }

      delete treeClone[id]

      return treeClone
    })
  }

  const addFakeTask = (parentId: string, isTop: boolean, isBottom: boolean) => {
    removeLocalTask()
    const fakeTask = generateFakeTask('fake-task', parentId, isTop, isBottom)
    setTree((prev) => ({
      ...prev,
      [parentId]: {
        ...prev[parentId],
        children: mergeFakeTask(prev[parentId]?.children || [], fakeTask),
      },
      'fake-task': fakeTask,
    }))
  }

  const collapseTree = (treeId: string, isOpen?: boolean) => {
    setTree((prev) => ({
      ...prev,
      [treeId]: {
        ...prev[treeId],
        isOpen: isOpen !== undefined ? isOpen : !prev[treeId]?.isOpen,
      },
    }))
  }

  const getAllTreeState = (key: string) => {
    let result = []
    Object.keys(treesViewState).forEach((treeId) => {
      const tree = treesViewState[treeId]
      result.push(...(tree[key] || []))
    })
    return result
  }

  const setFocusedItem = (items: string[], treeId: string) =>
    setTreesViewState((prev) => ({ ...prev, [treeId]: { ...prev[treeId], focusedItem: items } }))

  const setSelectedItems = (items: string[], treeId: string) => {
    setTreesViewState((prev) => ({
      ...prev,
      [treeId]: { ...prev[treeId], selectedItems: items },
    }))
  }

  const setExpandedItems = (items: string[], treeId: string) => {
    setTreesViewState((prev) => {
      return {
        ...prev,
        [treeId]: { ...prev[treeId], expandedItems: items },
      }
    })
  }

  const setActiveTree = (id: string) => {
    activeTree = { ...tasksCount[id], treeId: id }
  }

  const incrementPage = () => {
    if (activeTree) {
      addSkeletons({ id: activeTree?.treeId }, setTree)
      setTasksCount((prev) => ({
        ...prev,
        [activeTree?.treeId]: {
          ...prev[activeTree?.treeId],
          currentPage: activeTree?.currentPage + 1,
        },
      }))
    }
  }

  const getGroupByHeaders = () => {
    if (groupBy?.type === GroupByFields.PRIORITY) {
      return priorities.map((priority) => ({
        ...priority,
        name: t(`priorities.${priority.name}`),
      }))
    } else if (groupBy?.type === GroupByFields.STATUS) {
      return list.status
    } else return groupByData
  }

  const saveTasksCount = (treeId: string, info) => {
    setTasksCount((prev) => ({
      ...prev,
      [treeId]: { ...prev[treeId], ...info },
    }))
  }

  const saveRootTasks = (tasks: TaskType[], treeId: string) => {
    const fakeTask = getFakeTask(tree, { index: treeId })
    if (parseBoolean(expandAllTasks)) {
      expendAllTasksHandler(tasks)
    }

    setTree((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          children: mergeFakeTask(
            tasks?.map(({ id }) => id),
            fakeTask,
          ),
          isRoot: true,
        },
        ...convertTasksToTemplateTree(tasks, (taskId) => prev[taskId]?.children, groupBy?.type),
      }
    })
  }

  const saveSubtasks = (subtasks: TaskType[], parentTask: { id: string; content: TaskType }) => {
    const newSubtasks = removeDuplicate(subtasks?.map(({ id }) => id))
    if (parseBoolean(expandAllTasks)) expendAllTasksHandler(subtasks)

    setTree((prev) => {
      const fakeTask = prev['fake-task']?.parentId === parentTask?.id ? prev['fake-task'] : null
      return {
        ...prev,
        [parentTask?.id]: {
          ...parentTask,
          content: {
            ...(parentTask?.content || {}),
            totalSubtask: newSubtasks.length,
          },
          children: removeDuplicate(mergeFakeTask(newSubtasks, fakeTask)),
        },
        ...convertTasksToTemplateTree(subtasks, (taskId: string) => prev[taskId]?.children),
      }
    })
  }

  const infiniteScrollHandler = (e) =>
    handleScroll(e, activeTree?.total_items, activeTree?.currentPage, 20, incrementPage, 1)

  const renderItem = (props) => {
    const renderItemProps = {
      ...props,
      tree,
      removeFakeTask: removeLocalTask,
      addFakeTask,
      groupBy,
      isSelected: treesViewState[props.info.treeId]?.selectedItems?.includes(props.item.index),
      addToSelectedItems: () => {
        setSelectedItems(
          [...(treesViewState[props.info.treeId]?.selectedItems || []), props.item.index],
          props.info.treeId,
        )
      },
      unselectItem: () => {
        setSelectedItems(
          treesViewState[props.info.treeId]?.selectedItems?.filter(
            (id) => id !== props.item.index,
          ) || [],
          props.info.treeId,
        )
      },
      activeList: list,
    }

    return <RenderItemWrapper {...renderItemProps} />
  }

  const isCompatible =
    [GroupByFields?.PRIORITY, GroupByFields?.STATUS].includes(groupBy?.type) ||
    groupByData?.every((item) => item?.fieldType === groupBy?.type || !item?.fieldType)

  if (!list || !tree || list?.id !== listId || !list.status || !isCompatible) {
    return (
      <div className="statuses-loading">
        {Array.from({ length: 20 }).map((_, index) => (
          <TaskSkeleton depth={-2} key={index} />
        ))}
      </div>
    )
  }

  return (
    <div className="list-container" onScroll={infiniteScrollHandler}>
      <RTLStyleDecorator>
        <div className="nested-list-view-container" onKeyDown={(e) => e.stopPropagation()}>
          <ControlledTreeEnvironment
            items={tree}
            ref={treeRef}
            getItemTitle={(item) => item.data}
            viewState={treesViewState}
            onDrop={dropEndEvent}
            renderDragBetweenLine={(info) => {
              return (
                <div
                  style={{
                    width: `calc(100% - ${info.draggingPosition.depth * 29}px)`,
                    height: '3px',
                    marginLeft: 'auto',
                    background: '#6f00ff',
                    zIndex: 1000,
                  }}
                ></div>
              )
            }}
            canDropOnNonFolder={true}
            canDragAndDrop={
              !(selectedItems.length > 1) && getSort(headers)?.length === 0 && !groupBy?.custom
            }
            canDropOnFolder={true}
            canReorderItems={true}
            renderItemArrow={renderArrow}
            onFocusItem={(item: any, treeId) => setFocusedItem(item.index, treeId)}
            onExpandItem={(item, treeId) => {
              if (item)
                setExpandedItems(
                  [
                    ...(getStateViewByTree(treesViewState, treeId, 'expandedItems') || []),
                    item.index,
                  ],
                  treeId,
                )
            }}
            onCollapseItem={(item, treeId) => {
              setExpandedItems(
                getStateViewByTree(treesViewState, treeId, 'expandedItems').filter(
                  (expandedItemIndex) => expandedItemIndex !== item.index,
                ),
                treeId,
              )
            }}
            renderTreeContainer={({ children, containerProps }) => {
              const filtredStatuses = filterTrees(
                getGroupByHeaders(),
                tasksCount,
                setActiveTree,
                (id) => tree[id]?.isOpen,
              )
              const { isOpen } = tree[containerProps['data-rct-tree']]
              const { isDisabled } =
                filtredStatuses?.find((tree) => tree?.id === containerProps['data-rct-tree']) || {}
              return (
                <TreeContainer
                  containerProps={containerProps}
                  children={children}
                  isOpen={isOpen}
                  isDisabled={isDisabled}
                />
              )
            }}
            renderItemsContainer={({ children, containerProps }) => (
              <ul {...containerProps}>{children}</ul>
            )}
            renderItem={renderItem}
            canSearchByStartingTyping={false}
            canSearch={false}
            canRename={false}
          >
            {filterTrees(
              getGroupByHeaders(),
              tasksCount,
              setActiveTree,
              (id) => tree[id]?.isOpen,
            )?.map((data) => (
              <StatusTree
                addTask={(isTop = true, isBottom = false) => addFakeTask(data?.id, isTop, isBottom)}
                selectAll={() => setSelectedItems(tree[data?.id]?.children, data?.id)}
                setSubtasks={(subtasks, parentTask) => saveSubtasks(subtasks, parentTask)}
                setTasks={(tasks, treeId) => saveRootTasks(tasks, treeId || data?.id)}
                removeSkeletons={(itemId) => removeSkeletons(itemId, setTree)}
                setTasksCount={(info) => saveTasksCount(data?.id, info)}
                expandedItems={treesViewState?.[data?.id]?.expandedItems}
                addSkeletons={(item) => addSkeletons(item, setTree)}
                unSelectAll={() => setSelectedItems([], data?.id)}
                currentPage={tasksCount[data?.id]?.currentPage}
                totalItems={tasksCount[data?.id]?.total_items}
                collapseTree={() => collapseTree(data?.id)}
                isLoading={tree?.[data?.id]?.isLoading}
                filterByMe={parseBoolean(filterByMe)}
                isOpen={tree?.[data?.id]?.isOpen}
                isDisabled={data?.isDisabled}
                groupBy={groupBy}
                search={search}
                key={data?.id}
                status={data}
                list={list}
                tree={tree}
              />
            ))}
          </ControlledTreeEnvironment>
        </div>
      </RTLStyleDecorator>
    </div>
  )
}

export default ListView
