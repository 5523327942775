export const TransformedData = (parsedData, setContent) => {
  if (parsedData[0]?.id?.length < 12) return
  if (!parsedData) return
  let results = []
  let i = 0
  const flatten = (item, depth) => {
    transformItem(item, depth) && results && results?.push(transformItem(item, depth))
    if (item?.children && item?.children?.length > 0) {
      item?.children?.forEach((child) => flatten(child, depth + 1))
      i++
    }
  }
  parsedData?.forEach((item) => flatten(item, 0))
  setContent(results)
  return results
}
const transformItem = (item, i: number) => {
  if (!item?.props) return
  if (!item) return
  function generateId() {
    return Math.random().toString(36).substr(2, 5) // Generates a random 5-character string
  }
  let transformedItem = {
    id: item?.id?.split('-')[0], // Simplify the id
    type: item?.type, // Use the original type for now
    children: [{ text: '' }],
    align: 'default',
    color: 'default',
    backgroundColor: 'default',
    indent: 0,
    listStyleType: '',
    url: '',
    width: '',
    lang: '',
  }
  // Handle different types dynamically
  switch (item.type) {
    case 'paragraph':
      transformedItem.type = 'p'
      if (item?.props?.textColor) {
        transformedItem.color = item?.props?.textColor
      }
      if (item?.props?.backgroundColor) {
        transformedItem.backgroundColor = item?.props?.backgroundColor
      }
      if (i) {
        transformedItem.indent = i
      }
      item?.content &&
        item?.content?.forEach((contentItem) => {
          let textNode: any = { text: contentItem?.text || '' }
          if (contentItem.type == 'link') {
            textNode = {
              type: 'a',
              url: contentItem?.href,
              children: [{ text: contentItem?.content[0]?.text || '' }],
            }
          }
          if (contentItem.styles) {
            Object?.keys(contentItem?.styles)?.forEach((style) => {
              let result
              switch (style) {
                case 'textColor':
                  result = 'color'
                  break
                case 'strike':
                  result = 'strikethrough'
                  break
                default:
                  result = style
              }

              textNode[result] = contentItem?.styles[style]
            })
          }
          transformedItem.children.push(textNode)
        })

      break
    case 'heading':
      if (item.content && item.content.length > 0) {
        transformedItem.type = 'h' + item?.props?.level
        if (item?.props?.textColor) {
          transformedItem.color = item?.props?.textColor
        }
        if (item.props?.backgroundColor) {
          transformedItem.backgroundColor = item?.props?.backgroundColor
        }
        if (i) {
          transformedItem.indent = i
        }
        item.content.forEach((contentItem) => {
          let textNode = { text: (contentItem?.text as string) || '' }

          // Apply styles directly to the text node
          if (contentItem?.styles) {
            Object.keys(contentItem?.styles)?.forEach((style) => {
              let result
              switch (style) {
                case 'textColor':
                  result = 'color'
                  break
                case 'strike':
                  result = 'strikethrough'
                  break
                default:
                  result = style
              }
              textNode[result] = contentItem?.styles[style]
            })
          }

          transformedItem?.children?.push(textNode)
        })
      }

      break
    case 'numberedListItem':
      if (item?.content && item?.content?.length > 0) {
        transformedItem.type = 'p'
        if (item.props?.textAlignment) {
          transformedItem.align = item?.props?.textAlignment
        }
        if (item.props?.textColor) {
          transformedItem.color = item?.props?.textColor
        }
        if (item.props?.backgroundColor) {
          transformedItem.backgroundColor = item?.props?.backgroundColor
        }

        if (i) {
          transformedItem.indent = i
        }
        transformedItem.listStyleType = 'decimal'
        item.content.forEach((contentItem) => {
          let textNode = { text: contentItem?.text || '' }
          if (contentItem?.styles) {
            Object.keys(contentItem?.styles).forEach((style) => {
              let result
              switch (style) {
                case 'textColor':
                  result = 'color'
                  break
                case 'strike':
                  result = 'strikethrough'
                  break
                default:
                  result = style
              }
              textNode[result] = contentItem?.styles[style]
            })
          }

          transformedItem?.children?.push(textNode)
        })
      }

      break

    case 'bulletListItem':
      if (item?.content && item?.content?.length > 0) {
        transformedItem.type = 'p'
        if (item.props?.textAlignment) {
          transformedItem.align = item?.props?.textAlignment
        }
        if (item.props?.textColor) {
          transformedItem.color = item?.props?.textColor
        }
        if (item.props?.backgroundColor) {
          transformedItem.backgroundColor = item?.props?.backgroundColor
        }

        if (i) {
          transformedItem.indent = i
        }

        transformedItem.listStyleType = 'disc'
        item.content.forEach((contentItem) => {
          let textNode = { text: contentItem?.text || '' }
          if (contentItem?.type == 'link') {
            textNode = {
              type: 'a',
              url: contentItem?.href,
              children: [{ text: contentItem?.content[0]?.text || '' }],
            } as any
          }
          if (contentItem?.styles) {
            Object?.keys(contentItem?.styles)?.forEach((style) => {
              let result
              switch (style) {
                case 'textColor':
                  result = 'color'
                  break
                case 'strike':
                  result = 'strikethrough'
                  break
                default:
                  result = style
              }
              textNode[result] = contentItem?.styles[style]
            })
          }

          transformedItem.children.push(textNode)
        })
      }

      break
    case 'table':
      if (item?.content && item?.content?.rows?.length > 0) {
        transformedItem.type = 'table'

        if (i) {
          transformedItem.indent = i
        }
        transformedItem.children = item?.content?.rows?.map((row) => {
          return {
            type: 'tr',
            id: generateId(),
            children: row.cells.map((cell) => {
              let textNode = { text: cell[0]?.text || '' }
              if (cell[0]?.styles) {
                Object.keys(cell[0]?.styles)?.forEach((style) => {
                  let result
                  switch (style) {
                    case 'textColor':
                      result = 'color'
                      break
                    case 'strike':
                      result = 'strikethrough'
                      break
                    default:
                      result = style
                  }
                  textNode[result] = cell[0]?.styles[style]
                })
              }

              return {
                type: 'td',
                id: generateId(),
                children: [
                  {
                    type: 'p',
                    id: generateId(),
                    children: [textNode],
                  },
                ],
              }
            }),
          }
        })
      }
      break
    case 'image':
      transformedItem.type = 'img'
      if (item?.props?.textAlignment) {
        transformedItem.align = item?.props?.textAlignment
      }
      if (item?.props?.textColor) {
        transformedItem.color = item?.props?.textColor
      }
      if (item?.props?.backgroundColor) {
        transformedItem.backgroundColor = item?.props?.backgroundColor
      }

      if (item?.props?.url) {
        transformedItem.url = item?.props?.url
        transformedItem.width = item?.props?.width
      }

      break
    case 'codeBlock':
      if (item?.props) {
        transformedItem.type = 'code_block'
        if (item?.props?.textAlignment) {
          transformedItem.align = item?.props?.textAlignment
        }
        if (item.props?.textColor) {
          transformedItem.color = item?.props?.textColor
        }
        if (item.props?.backgroundColor) {
          transformedItem.backgroundColor = item?.props?.backgroundColor
        }
        transformedItem.lang = item?.props?.lang?.toLowerCase()
        transformedItem.children = [
          {
            text: item.props.codeContent || '',
            type: 'code_line',
          },
        ] as any
      }
      break
    default:
      break
  }

  return transformedItem
}
