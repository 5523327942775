import { useNavigate } from 'react-router-dom'
import { ReactComponent as NotFoundIcon } from '../../assets/icons/notFound/Group 1000004395 (1).svg'
import { useTranslation } from 'react-i18next'
type Props = {
  statusCode?: number
  message?: string
  underMessage?: string
  buttonName?: string
  navigateTo?: string
  takeMeBack?: boolean
  onClick?: () => void
}
const NotFound = ({
  statusCode,
  message,
  underMessage,
  buttonName,
  navigateTo,
  takeMeBack,
  onClick,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['notFound'])

  const onClickHandler = () => {
    takeMeBack ? navigate(-1) : navigate(navigateTo ? navigateTo : 'spaces')
    onClick?.()
  }

  return (
    <div className="notfound-view">
      <div className="color1" style={{ background: 'rgba(178, 101, 255, 0.20)' }}></div>
      <div className="color2" style={{ background: 'rgba(255, 239, 182, 0.60)' }}></div>

      <div className="icon-cover">
        <NotFoundIcon />
      </div>

      <div className="text-container">
        <span className="notfound-text">{statusCode || 404}</span>
        <span className="title"> {message || t('oops_looks_like_you_are_lost')}</span>
        <span className="subtitle">
          {underMessage ? underMessage : t('The page you are looking for does not exist ..')}
        </span>
        <button className="notFound-btn" onClick={onClickHandler}>
          {buttonName ? buttonName : t('Take me home')}
        </button>
      </div>
    </div>
  )
}

export default NotFound
