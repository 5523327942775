interface Editor {
  id: number
  content: string
}

interface EditorsState {
  [key: string]: Editor[]
}

const removeEditor = (
  id: number,
  setEditors: React.Dispatch<React.SetStateAction<EditorsState>>,
): void => {
  setEditors((prevEditors) => {
    const updatedEditors = { ...prevEditors }
    Object.keys(updatedEditors).forEach((key) => {
      updatedEditors[key] = updatedEditors[key].filter((editor) => editor.id !== id)
    })
    return updatedEditors
  })
}

export { removeEditor }
