export const getFieldsFilter = (fields, setFilter, filter) => {
  const fieldsKeys = fields && Object.keys(fields)
  return (
    fieldsKeys?.map((field, i) => {
      return {
        key: i,
        label: field,
        onClick: () => setFilter({ ...filter, field, operation: null, type: field }),
      }
    }) || []
  )
}
