import { Skeleton } from 'antd'

const UserTasksSkeleton = () => (
  <div className="user-tasks-skeleton">
    {Array(5)
      .fill(null)
      .map((_, i) => (
        <div className="user-tasks-skeleton-item" key={i}>
          <div className="user-tasks-skeleton-item-left">
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-start-date" />
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-time" />
          </div>
          <div className="user-tasks-skeleton-item-center">
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-title" />
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-path" />
          </div>
          <div className="user-tasks-skeleton-item-right">
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-date" />
            <Skeleton paragraph={false} active className="user-tasks-skeleton-item-priority" />
          </div>
        </div>
      ))}
  </div>
)

export default UserTasksSkeleton
