import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { api } from '@src/modules/shared/services/api'
import { store } from '@src/modules/shared/store'
interface Content {
  content: string
  taskId?: string
  time: string
}

export interface CreateAnswer {
  questionId: string
  parentId?: string
  date: Date
  body: Content[]
}
export interface updateAnswer {
  body: {
    parentId?: string
    date: Date
    body: Content[]
  }
  id: string
}
export const memberAnswersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnswersByTeams: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answerbyteam`,
          method: 'GET',
          params,
        }
      },
    }),
    getAnswersByuser: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answerbyUser`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },

      providesTags: (result = []) => {
        return [
          ...result?.payload?.map((answer: any) => ({ type: 'ANSWERS', id: answer?.id }) as const),
          { type: 'ANSWERS', id: 'MEMBER' },
        ]
      },
    }),
    getAnswers: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answer`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },

      // providesTags: (result = []) => {
      //   return [
      //     ...result?.payload?.map((answer: any) => ({ type: 'ANSWERS', id: answer?.id }) as const),
      //     { type: 'ANSWERS', id: 'MEMBER' },
      //   ]
      // },
    }),
    getReplies: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answerreplies`,
          method: 'GET',
          params,
        }
      },
      transformResponse: (res: any) => {
        return { ...res?.payload, payload: res?.payload?.payload?.reverse() }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { answerId } = queryArgs || {}
        return { answerId }
      },

      merge: (currentCache, newItems) => {
        if (newItems?.payload?.length > 0 && currentCache?.metadata) {
          if (currentCache) {
            newItems.payload = newItems.payload?.filter(
              (item) => !currentCache?.payload?.find((reply) => reply?.id === item?.id),
            )
            if (newItems?.payload) {
              currentCache.payload = currentCache.payload
                ? [...newItems.payload, ...currentCache.payload]
                : [...newItems.payload]
            }
            if (newItems?.metadata) {
              currentCache.metadata = { ...newItems.metadata }
            }
          }
        }

        currentCache.payload = removeDuplicatesById(currentCache?.payload)
      },

      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },

      providesTags: (result = []) => {
        return [
          ...result?.payload?.map((reply: any) => ({ type: 'REPLIES', id: reply?.id }) as const),
          { type: 'REPLIES', id: 'MEMBER' },
        ]
      },
    }),
    postReply: build.mutation<any, any>({
      query: (body: CreateAnswer) => {
        return {
          url: `api/comment`,
          method: 'POST',
          body,
        }
      },
      async onQueryStarted({ answerId }, { dispatch, queryFulfilled }) {
        const {
          data: { payload: data },
        }: any = (await queryFulfilled) || {}
        dispatch(
          memberAnswersApi.util.updateQueryData('getReplies', { answerId }, (draft) => {
            draft?.payload.push(data)
            return draft
          }),
        )
      },
      // invalidatesTags: [{ type: 'REPLIES', id: 'MEMBER' }] as any,
    }),

    addEmojiReply: build.mutation<any, any>({
      query: ({
        ...body
      }: {
        commentId: string
        code: null | string
        answerId: string
        questionId: string
        type: string
      }) => ({
        url: `api/reaction`,
        method: 'POST',
        body: {
          commentId: body?.commentId,
          code: body.code,
          questionId: body?.questionId,
          answerId: body?.answerId,
          type: body?.type,
        },
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: { payload: data },
        }: any = (await queryFulfilled) || {}

        dispatch(
          memberAnswersApi.util.updateQueryData(
            'getReplies',
            { answerId: arg?.answerId },
            (draft) => {
              if (draft?.payload)
                draft.payload = draft?.payload?.map((reply) =>
                  arg?.commentId === reply?.id
                    ? {
                        ...reply,
                        reactions: [...(reply?.reactions || []), data],
                      }
                    : reply,
                )

              return draft
            },
          ),
        )

        return data
      },
    }),
    updateEmojiReply: build.mutation<any, any>({
      query: ({ id, answerId, ...body }) => ({
        url: `api/reaction/${id}`,
        method: 'PATCH',
        body: {
          ...body,
          answerId,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: { payload: data },
        }: any = (await queryFulfilled) || {}
        dispatch(
          memberAnswersApi.util.updateQueryData(
            'getReplies',
            { answerId: arg?.answerId },
            (draft) => {
              if (draft?.payload)
                draft.payload = draft?.payload?.map((reply) =>
                  data?.commentId === reply?.id
                    ? {
                        ...reply,
                        reactions: reply?.reactions?.map((reaction) =>
                          reaction?.id === data?.id ? data : reaction,
                        ),
                      }
                    : reply,
                )
              return draft
            },
          ),
        )
      },
    }),

    deleteEmojiReply: build.mutation<any, any>({
      query: ({ id, questionId, answerId, commentId }) => ({
        url: `api/reaction/${id}`,
        method: 'DELETE',
        body: {
          answerId,
          questionId,
          commentId,
        },
      }),
      async onQueryStarted(arg, { dispatch }) {
        const { id, replyId, answerId } = arg || {}

        dispatch(
          memberAnswersApi.util.updateQueryData('getReplies', { answerId }, (draft) => {
            if (draft?.payload)
              draft.payload = draft?.payload?.map((reply) =>
                replyId === reply?.id
                  ? {
                      ...reply,
                      reactions: reply?.reactions?.filter((reaction) => reaction?.id !== id),
                    }
                  : reply,
              )
            return draft
          }),
        )
      },
      // invalidatesTags: [{ type: 'REPLIES', id: 'MEMBER' }] as any,
    }),

    updateReply: build.mutation<any, any>({
      query: ({ commentId, content }: { commentId: string; content: string }) => ({
        url: `api/comment/${commentId}`,
        method: 'PATCH',
        body: {
          content,
        },
      }),
      async onQueryStarted(arg, { dispatch }) {
        dispatch(
          memberAnswersApi.util.updateQueryData('getReplies', undefined, (draft) => {
            if (draft?.payload)
              draft.payload = draft?.payload?.map((reply) =>
                arg?.commentId === reply?.id
                  ? { ...reply, content: arg?.content, edited: true }
                  : reply,
              )
            return draft
          }),
        )
      },
      // invalidatesTags: [{ type: 'REPLIES', id: 'MEMBER' }] as any,
    }),

    deleteReply: build.mutation<any, any>({
      query: ({ id }: { id: string; answerId?: string }) => ({
        url: `api/comment/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch }) {
        dispatch(
          memberAnswersApi.util.updateQueryData(
            'getReplies',
            { answerId: arg?.answerId },
            (draft) => {
              if (draft?.payload)
                draft.payload = draft.payload.filter((reply) => reply?.id !== arg?.id)
              return draft
            },
          ),
        )
      },
    }),

    createAnswer: build.mutation<any, any>({
      query: (body: CreateAnswer) => ({
        url: `api/answer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ANSWERS', id: 'MEMBER' }] as any,
    }),
    getAnswerByQuestion: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/answersbyquestions`,
          method: 'GET',
          params,
        }
      },
    }),
    updateAnswer: build.mutation<any, any>({
      query: ({ id, ...body }: updateAnswer) => ({
        url: `api/answer/${id}`,
        method: 'PATCH',
        ...body,
      }),
      invalidatesTags: [{ type: 'ANSWERS', id: 'MEMBER' }] as any,
    }),
    getOnAnswer: build.query<any, any>({
      query: ({ id, ...params }) => ({
        url: `api/answer/${id}`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const resetRepliesDraft = () => {
  store.dispatch(
    memberAnswersApi.util.updateQueryData('getReplies', undefined, (draft) => {
      if (draft && draft.payload) draft.payload = []
      // return draft
    }),
  )
}
export const resetAnswers = () => {
  store.dispatch(
    memberAnswersApi.util.updateQueryData('getAnswerByQuestion', undefined, (draft) => {
      if (draft) draft = null
    }),
  )
}

export const {
  useUpdateReplyMutation,
  useCreateAnswerMutation,
  usePostReplyMutation,
  useAddEmojiReplyMutation,
  useUpdateEmojiReplyMutation,
  useDeleteEmojiReplyMutation,
  useDeleteReplyMutation,
  useGetAnswersByTeamsQuery,
  useGetAnswersByuserQuery,
  useGetRepliesQuery,
  useGetAnswerByQuestionQuery,
  useUpdateAnswerMutation,
  useGetOnAnswerQuery,
  useGetAnswersQuery,
} = memberAnswersApi

// async onCacheEntryAdded(arg, { cacheDataLoaded, getState, updateCachedData }) {
//   // Check if we just not started to build the infinite list.
//   // You must reset the page after sort order change etc.
//   // wait for the initial query to resolve before proceeding
//   if (arg?.skip && arg?.limit && arg?.skip > 1) {
//     await cacheDataLoaded

//     // get key of the last query, the query before the current one
//     // "getAllPosts({"limit":5,"page":1})"
//     const lastQueryKeyParams = JSON.stringify({
//       ...arg,
//       skip: arg.skip - 1,
//     })
//     const lastQueryKey = `getReplies(${lastQueryKeyParams})`

//     const lastCacheEntry = getState().tamApi.queries[lastQueryKey]?.data as any
//     // console.log({
//     //   ...getState().tamApi.queries,
//     //   lastCacheEntry,
//     //   lastQueryKey,
//     //   lastQueryKeyParams,
//     // })
//     // add previous data to the current one
//     updateCachedData((draft) => {
//       console.log({ draft: deepClone(draft) })
//       draft.payload.push(...lastCacheEntry?.payload)
//     })
//   }
// },
