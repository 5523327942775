import { Avatar, Badge, Drawer, Skeleton, Tabs, TabsProps, message } from 'antd'
import { formatDistanceToNow } from 'date-fns'
import { ar, fr, enUS } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store'
import RTLStyleDecorator from '../RTLStyleDecorator/RTLStyleDecorator'
import { formatDate } from '@src/modules/dashboard/utils/formatDate'
import { replaceAMPMWithArabic } from '../../utils/replaceAMPMWithArabic'
import i18n from '@src/i18n'
import { useGetOneUserQuery, useGetTeamsQuery } from '@src/modules/auth/services/userApi'
import { ReactComponent as EmailIcon } from '../../../shared/assets/icons/blockNote/email.svg'
import { ReactComponent as ClockIcon } from '../../../shared/assets/icons/blockNote/clock.svg'
import { ReactComponent as CaseIcon } from '../../../shared/assets/icons/case.svg'
import UserActivity from '@src/modules/settings/features/Profile/UserActivity/UserActivity'
import MyTasks from '@src/modules/dashboard/components/MyTasks/MyTasks'
import { useExtractColor } from 'react-extract-colors'
import { updateProfile } from '@src/modules/settings/data/Profile/ProfileThunk'
import { Avatar as TeamAvatar } from '@src/modules/shared/components/Avatar'
import { Input } from '../Input'
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip'
import { useState } from 'react'

function UserProfile({ onClose, open, id: drawerId, data: user }) {
  let { avatar, fullName, isActive, lastActive, id, email } = user || {}

  const lang = localStorage.getItem('i18nextLng')
  const locale = lang === 'ar' ? ar : lang === 'fr' ? fr : enUS
  const { t } = useTranslation(['profile'])
  const { user: me } = useAppSelector((state) => state.auth)
  const { data: teamList, isLoading } = useGetTeamsQuery({
    userId: id,
  })
  const dispatch = useAppDispatch()

  const { data, refetch, isLoading: isLoadingUser } = useGetOneUserQuery({ id })

  const jobTitle = data?.payload?.jobTitle

  const [jobTitleInput, setJobTitleInput] = useState(jobTitle)

  avatar = avatar && avatar?.substr(0, avatar.length - 1) + '1'

  const { dominantColor } = useExtractColor(avatar, {
    format: 'hex',
  })

  isActive = me?.user?.id === id || isActive

  const items: TabsProps['items'] = [
    { key: '1', label: t('recent-activities'), children: <UserActivity id={id} /> },
    { key: '2', label: t('assigned-tasks'), children: <MyTasks id={id} /> },
  ]

  const saveUpdate = (e) => {
    const value = e.target.value
    if (!value.trim()) {
      message.error(t('Invalid-job-name'))
    } else if (value !== jobTitle) {
      dispatch(updateProfile({ jobTitle: value }))
        .unwrap()
        .then(() => refetch())
        .catch(() => {
          message.error(t('Error-update-job'))
        })
    }
  }

  return (
    <Drawer
      width={810}
      onClose={() => onClose(drawerId)}
      open={open}
      className="user-profile-drawer"
    >
      <div className="member-header">
        <div
          className="member-header-background"
          style={{
            background: `linear-gradient(0deg, 
                var(--background), ${dominantColor}88 100%
                )`,
          }}
        ></div>

        {fullName ? (
          <>
            <RTLStyleDecorator>
              <div className="member-avatar">
                <Badge dot color={isActive ? '#12B76A' : '#FF8C22'}>
                  <TeamAvatar
                    size={130}
                    src={avatar}
                    text={fullName && fullName[0].toUpperCase()}
                  />
                </Badge>
              </div>
            </RTLStyleDecorator>
            <div className="member-header-details">
              <p>{fullName}</p>
              <div className="availability">
                <div className={`${isActive ? 'active' : 'absent'}`}>
                  {isActive ? t('present') : t('absent')}
                </div>
                <div className="last-active">
                  {isActive
                    ? t('active-now')
                    : lastActive
                      ? `${t('last-active')} ${formatDistanceToNow(lastActive, { addSuffix: true, locale })}`
                      : t('Never')}
                </div>
              </div>
            </div>
          </>
        ) : (
          '--'
        )}
      </div>
      <div className="member-description">
        <div className="member-description-header">{t('email')}</div>
        <div className="member-description-header">{t('local-time')}</div>
        <div className="member-description-header">{t('job-title')}</div>
        <div className="member-description-header">{t('teams')}</div>
        <div className="member-description-item">
          <EmailIcon />
          <OverflowTooltip className="member-description-item-email">{email}</OverflowTooltip>
        </div>
        <div className="member-description-item">
          <ClockIcon />
          {replaceAMPMWithArabic(formatDate(new Date().toISOString())[1], i18n.language)}
        </div>
        <div className="member-description-item">
          <CaseIcon />
          {!isLoadingUser ? (
            me?.user?.id === id ? (
              <Input
                variant="label-inline"
                onClick={(e) => e.stopPropagation()}
                defaultValue={jobTitle}
                autoFocus
                value={jobTitleInput}
                onChange={(e) => setJobTitleInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    saveUpdate(e)
                  }
                }}
              />
            ) : (
              jobTitle
            )
          ) : (
            <Skeleton paragraph={false} />
          )}
        </div>
        <div className="member-description-item">
          {isLoading ? (
            <Skeleton.Avatar active size={25} />
          ) : (
            <div className="teams">
              <Avatar.Group max={{ count: 4 }}>
                {teamList?.payload?.map((team) => {
                  return (
                    <TeamAvatar
                      className="team-img"
                      key={team?.id}
                      src={team?.icon}
                      text={team?.name}
                      color={team?.color || '#A6A6A6'}
                    />
                  )
                })}
              </Avatar.Group>
            </div>
          )}
        </div>
      </div>
      <Tabs className="tabs" defaultActiveKey="1" items={items} />
    </Drawer>
  )
}

export default UserProfile
