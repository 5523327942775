import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'

export const reformulateData = (
  members: { payload: memberType[] },
  shares: { payload: { userId: string; user: memberType }[] },
  shareType,
  search: string,
) => {
  let { payload: membersData } = members || {}
  let { payload: sharesData } = shares || {}

  sharesData = sharesData?.filter((share) => share?.user)

  const filteredMembersData = membersData?.filter(
    (member) => !sharesData?.find((share) => share.userId === member.id),
  )

  const combinedData = search
    ? membersData
    : sharesData?.map((share) => share?.user)?.concat(filteredMembersData)

  return removeDuplicatesById(
    combinedData?.map((member) => {
      const targetShare = sharesData?.find(
        (share) =>
          share?.userId === member?.id &&
          share[shareType?.type === 'documentId' ? 'fileId' : shareType?.type] === shareType?.id,
      )

      return {
        ...member,
        share: targetShare,
      }
    }),
  )
}
