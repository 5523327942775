import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store'
import { toggleSidebar } from '../../store/slices/settings/settingsSlice'
import { ReactComponent as CollapseIcon } from './../../assets/icons/sidebar/Collapse.svg'
import useWindowSize from '../../hooks/useWindowSize'
import { sidebarCloseAt } from '../../layout/MainLayout/MainLayout'

interface HeaderProps {
  children: React.ReactNode
  sidebarID?: string
}

function ViewTitle({ children, sidebarID }: HeaderProps) {
  const { sidebar } = useAppSelector((state) => state.settings)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { width } = useWindowSize()

  const isSpaces = pathname?.includes('spaces')
  const isNotifications = pathname?.includes('notifications')
  const isTrash = pathname?.includes('trash')
  const isDocument = pathname?.includes('document')
  const isDocuments = pathname?.includes('documents')
  const isDashboard = pathname?.includes('dashboard')
  const inDailyQuestion = pathname?.includes('daily-questions')

  return (
    <header
      className={`view-title ${(isTrash || isDocument) && 'trash_header'}  `}
      style={isDocument ? { justifyContent: 'space-between' } : {}}
    >
      {(isSpaces || isNotifications || isDashboard || isDocuments || inDailyQuestion) &&
      sidebar &&
      !sidebar['layout-sidebar'] &&
      width >= sidebarCloseAt ? (
        <div
          className="sidebar-collapse-btn right"
          onClick={() => dispatch(toggleSidebar(sidebarID))}
        >
          <CollapseIcon />
        </div>
      ) : null}
      {children}
    </header>
  )
}

export default ViewTitle
