import { Dropdown, Empty, Spin } from 'antd'
import {
  MembersListApi,
  useGetMembersListQuery,
} from '@src/modules/settings/services/membersListApi'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { useEffect, useRef, useState } from 'react'

import { ReactComponent as AddMemberIcon } from '../../../../assets/icons/filter/members-filter.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/icons/filter/collapse.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/filter/search-icon.svg'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { useGetTeamsQuery } from '@src/modules/auth/services/userApi'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Search from '@src/modules/shared/components/Input/search'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Avatar } from '@src/modules/shared/components/Avatar'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

const reformulateData = (members = [], teams = [], assigned = [], search, currentUser) => {
  const { t } = useTranslation(['tasks'])
  const newAssigned = search
    ? [
        ...assigned.filter((assign) => members?.find((member) => member?.id === assign?.id)),
        ...assigned.filter((assign) => teams?.find((team) => team?.id === assign?.id)),
      ]
    : assigned

  if (assigned?.length > 0) {
    teams = teams?.filter((team) => !assigned?.find((assign) => assign?.id === team?.id))
    members = members?.filter((member) => !assigned?.find((assign) => assign?.id === member?.id))
  }

  const updatedMembers = members?.filter((member) => member.id !== currentUser.id)
  const userName = currentUser?.fullName || currentUser?.email
  currentUser = { ...currentUser, fullName: t('Me') }
  const currentUserExist = userName?.toLowerCase().includes(search?.toLowerCase())

  return [
    ...(currentUserExist ? (currentUser ? [currentUser] : []) : []),
    ...(newAssigned || []),
    ...(updatedMembers || []),
    ...(teams || []),
  ]
}

export const MembersList = ({ setMembers, assignedMember, listId }: any) => {
  const dispatch = useAppDispatch()
  const modals = useAppSelector((state) => state.modals)
  const { listId: pathListId } = useParams()
  const { t } = useTranslation(['tasks'])
  const { user } = useAppSelector((state) => state.auth)
  const divRef = useRef(null)
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [mergedMembers, setMergedMembers] = useState<any>(null)
  const { data: teams, isFetching: gettingTeams } = useGetTeamsQuery({ limit: 500, search })
  const { data: members, isFetching } = useGetMembersListQuery({
    limit: 20,
    skip: currentPage - 1,
    search,
    listId: pathListId || listId,
  })

  const arrayData = removeDuplicatesById(
    reformulateData(mergedMembers, teams?.payload, assignedMember, search, user?.user),
  )

  useEffect(() => {
    if (members?.payload) {
      setMergedMembers((prev) => [...(prev || []), ...members.payload])
    }
  }, [members])

  const scrollToTop = () => {
    if (divRef.current) divRef.current.scrollTop = 0
  }

  useEffect(() => {
    dispatch(
      MembersListApi.util.updateQueryData('getMembersList', undefined, (draft) => {
        setCurrentPage(1)
        scrollToTop()
        if (draft && draft.payload) draft.payload = []
        return draft
      }),
    )
    setCurrentPage(1)
    setMergedMembers(null)
    scrollToTop()
  }, [listId, search])

  const { metadata } = members || {}
  const loading = isFetching || gettingTeams

  return (
    <div className="ant-dropdown-menu members-list-filter">
      <div className="members-list-filter-search">
        <Search
          onSearch={(s) => {
            setCurrentPage(1)
            setSearch(s)
          }}
          value={search}
          prefix={<SearchIcon />}
          variant="underlined"
        />
        <div
          className="members-list-filter-container"
          onScroll={(e) => handleScroll(e, metadata?.total_items, currentPage, 10, setCurrentPage)}
          ref={divRef}
        >
          <p>{t('people')}</p>

          <Spin spinning={loading}>
            {arrayData.length > 0 ? (
              arrayData?.map((member: memberType, i: number) => {
                const name = member?.fullName || member?.email || member?.name

                return (
                  <div
                    className={`member ${
                      assignedMember?.find((assMem) => assMem?.id === member?.id)
                        ? 'assigned-member'
                        : ''
                    }`}
                    key={i}
                    onClick={() => {
                      setMembers({ ...member, fullName: name })
                      setSearch('')
                    }}
                  >
                    <Avatar
                      src={member?.avatar || member.icon}
                      text={name ? name[0]?.toUpperCase() : ''}
                      size={22}
                    />

                    <OverflowTooltip>{name}</OverflowTooltip>

                    {!member?.name && (
                      <span
                        className="team-badge team-profile"
                        onClick={(e) => {
                          e.stopPropagation()
                          modals['user-profile-drawer'].open ||
                            dispatch(openModal({ id: 'user-profile-drawer', data: member }))
                        }}
                      >
                        {t('Profile')}
                      </span>
                    )}
                    {member?.name && <span className="team-badge">{t('Team')}</span>}
                  </div>
                )
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />
            )}
          </Spin>
        </div>
      </div>
    </div>
  )
}

interface MembersFilterProps {
  onData: (d: any) => void
  disable: boolean
}

const MembersFilter = ({ onData, disable }: MembersFilterProps) => {
  const { t } = useTranslation(['members'])
  const [assignedMembers, setAssignedMembers] = useState<memberType[]>([])

  useEffect(() => onData(assignedMembers), [assignedMembers])
  const updateMembersList = (newMember: memberType) => {
    setAssignedMembers((prev) => {
      const memberExistIndex = prev?.findIndex((member) => newMember?.id === member?.id)
      const newArr = [...prev]
      if (memberExistIndex !== -1) newArr?.splice(memberExistIndex, 1)
      else newArr?.push(newMember)
      return newArr
    })
  }

  return (
    <Dropdown
      overlay={<MembersList setMembers={updateMembersList} assignedMember={assignedMembers} />}
      trigger={['click']}
      disabled={disable}
    >
      <div className="members-filter">
        {assignedMembers?.length > 0 ? (
          assignedMembers?.map((member, i) => {
            return (
              <Avatar
                src={member?.avatar}
                key={i}
                text={
                  member?.fullName || member?.email
                    ? (member.fullName || member?.email)[0]?.toUpperCase()
                    : ''
                }
                size={22}
              />
            )
          })
        ) : (
          <>
            <AddMemberIcon />
            <p>{t('Select assignee')}</p>
            <CollapseIcon />
          </>
        )}
      </div>
    </Dropdown>
  )
}

export default MembersFilter
