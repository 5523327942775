/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'
import { SpacePyload } from './spacesTypes'
import { store } from '@src/modules/shared/store'

export const getSpaces = createAsyncThunk('spaces/get-spaces', async (query?: any) => {
  try {
    const response = await axiosInstance.get(`/api/spaces`, query)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getSpacesTree = createAsyncThunk('spaces/get-spaces-tree', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/spaces`, query)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getShared = createAsyncThunk('spaces/get-shares', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/share/sharedWithMe`, query)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getStatuses = createAsyncThunk('spaces/get-statuses', async () => {
  try {
    const response = await axiosInstance.get(`/api/status`)
    if (response.status === 201) {
      // TODO: Should be 200
      return response.data.payload
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getSpace = createAsyncThunk('spaces/get-space', async (query: { id: string }) => {
  try {
    const response = await axiosInstance.get(`/api/spaces/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const updateSpace = createAsyncThunk(
  'spaces/update-space',
  async (query: {
    id: string
    isPrivate?: boolean
    name?: string
    color?: string
    icon?: string | null
  }) => {
    const { id, ...body } = query
    try {
      const response = await axiosInstance.patch(`/api/spaces/${query.id}`, body)

      if (response.status === 200) {
        store.dispatch(getSpaces({}))
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const bulkspaces = createAsyncThunk(
  'spaces/bulk-space',
  async (query: { spaces: { id: string; order: number; newOrder: number }[] }) => {
    try {
      const response = await axiosInstance.post(`/api/bulkspaces`, query)

      if (response.status === 200) {
        store.dispatch(getSpaces({}))
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const createSpace = createAsyncThunk('spaces/create-space', async (query: SpacePyload) => {
  try {
    const response = await axiosInstance.post(`/api/spaces`, query)

    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const deleteSpace = createAsyncThunk(
  'spaces/delete-space',
  async (query: { id: string | null }) => {
    try {
      const response = await axiosInstance.delete(`/api/spaces/${query.id}`)

      if (response.status === 200) {
        store.dispatch(getSpaces({}))
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
