import Button from '@src/modules/shared/components/Button/Button'
import React, { useState } from 'react'
import { ReactComponent as TickIcon } from '@src/modules/tasks/assets/icons/task/tick.svg'
import TrashIcon from '@src/modules/tasks/assets/icons/task/trash.svg'
import { useTranslation } from 'react-i18next'
import Input from '../../Input/Input'
type TagActionProps = {
  data: Record<string, any>
  setOpenedDropdown: Function
  setSelectedTags?: (value: string[]) => void
  handleDeleteTag: Function
  handleEditColor: Function
  handleKeyDown: Function
}

const TagsActions: React.FC<TagActionProps> = ({
  data,
  handleDeleteTag,
  handleEditColor,
  handleKeyDown,
}) => {
  const { t } = useTranslation(['taskDetails'])
  const [tagName, setTagName] = useState(data?.name)
  const [selectedColor] = useState<string | null>(null)
  const colors = ['#F51F45', '#34A174', '#5F61D2', '#C36D3B', '#05B7ED', '#878787']
  const colorNames = [t('Red'), t('Green'), t('Purple'), t('Yellow'), t('Blue'), t('Light Gray')]
  return (
    <div className="tag-action-container">
      <Input
        variant="label-inline"
        className="input-container"
        value={tagName}
        onChange={(event) => setTagName(event?.target.value)}
        onKeyDown={(event) => handleKeyDown(event, tagName, data.id, data.color)}
      />

      <Button
        className="delete-button"
        children={t('Delete')}
        htmlType="button"
        icon={TrashIcon}
        onClick={() => handleDeleteTag(data.id)}
        disabled={false}
      />
      <div className="color-container">
        <div className="color-header">{t('Colors')}</div>
        {colors.map((color, index) => (
          <div
            key={color}
            className={`color-item ${selectedColor === color ? 'selected' : ''}`}
            onClick={() => handleEditColor(color, data.id, data.name)}
          >
            <span
              className="color-box"
              style={{
                backgroundColor: `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
                  color.slice(3, 5),
                  16,
                )}, ${parseInt(color.slice(5, 7), 16)}, 0.3)`,
                border: `1px solid ${color}`,
              }}
            />
            <span className="color-name"> {colorNames[index]}</span>
            {selectedColor === color && (
              <div className="tick">
                <TickIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TagsActions
