import { isControlKey } from '@src/modules/shared/utils/isControlKey'
import { FileType } from '@src/modules/spaces/data/fileSlice/fileSlice'
import { Fragment, ReactNode } from 'react'
import { TreeItemRenderContext } from 'react-complex-tree'
import DocumentRow from '../DocumentRow/DocumentRow'
import FolderRow from '../FolderRow/FolderRow'
import ListRow from '../ListRow/ListRow'
import SpaceRow from '../SpaceRow/SpaceRow'
import { TaskSkeleton } from '@src/modules/tasks/components/EditTaskPopup/components/SubTasksTree/helpers/renderItem'
import RTLStyleDecorator from '../../RTLStyleDecorator'

const ItemsRows = {
  space: SpaceRow,
  folder: FolderRow,
  list: ListRow,
  document: DocumentRow,
}

export interface renderItemProps {
  title: string
  arrow: ReactNode
  depth: number
  context: TreeItemRenderContext
  children: ReactNode
  tree: any
  activeItem: FileType
  multiple: boolean
  autoOpen: boolean
  isImport?: boolean
  readOnly: boolean
}

export const renderItem = ({
  title,
  arrow,
  depth,
  context,
  children,
  tree,
  multiple,
  readOnly,
  autoOpen,
  isImport,
  activeItem,
}: renderItemProps) => {
  const item = tree[title]
  const data = item?.content
  const isExpanded = context.isExpanded
  const isSelected = context.isSelected

  const itemType = item?.type
  const Row = ItemsRows[itemType] || Fragment

  const { spaceId, folderId } = activeItem || {}

  const selectItem = (e) => {
    if (isControlKey(e)) {
      if (!multiple) return

      if (isSelected) {
        context.unselectItem()
      } else {
        context.addToSelectedItems()
      }
    } else if (multiple) {
      if (isSelected) {
        context.unselectItem()
      } else {
        context.addToSelectedItems()
      }
    } else {
      context.selectItem()
    }
  }

  const toggleExpand = () => {
    context.toggleExpandedState()
  }

  return (
    <RTLStyleDecorator>
      <li
        {...context.itemContainerWithChildrenProps}
        className={`item-row-container  ${
          itemType === 'space' && isExpanded ? 'active-group' : ''
        }`}
        data-id={data?.id}
        style={{
          paddingLeft: (itemType === 'list' || itemType === 'document' ? 0 : depth * 29) + 'px',
          listStyle: 'none',
        }}
      >
        <div
          {...context.itemContainerWithoutChildrenProps}
          {...context.interactiveElementProps.onClick}
          {...context.interactiveElementProps.onDragStart}
          {...context.interactiveElementProps.onDragOver}
          {...context.interactiveElementProps.onFocus}
          draggable={context.interactiveElementProps.draggable}
          className="item-row"
          style={{ paddingBottom: '2px' }}
          onDragStart={(event) => {
            event.dataTransfer.clearData()
            event.dataTransfer.setData('text/plain', '')
            const dragElement = document.createElement('div')
            dragElement.className = 'drag-element-spaces'
            dragElement.innerHTML = `
          <div>${data?.name}</div>
          `
            document.body.appendChild(dragElement)
            document.body.style.overflow = 'hidden'
            event.dataTransfer.setDragImage(dragElement, 0, 0)
            context.interactiveElementProps.onDragStart(event)
          }}
          onDragEnd={() => {
            document.body.style.overflow = 'auto'
            const dragElements = document.querySelectorAll('.drag-element-spaces')
            dragElements.forEach((ele) => ele.remove())
          }}
        >
          <Row
            data={data}
            arrow={arrow}
            toggleExpand={toggleExpand}
            select={selectItem}
            multiple={multiple}
            focus={context.focusItem}
            readOnly={readOnly}
            isActive={(autoOpen && data?.id === spaceId) || data?.id === folderId}
            isExpanded={isExpanded}
            isSelected={isSelected}
            isImport={isImport}
            halfSelect={item.halfSelect}
          />
        </div>

        {item?.isLoading ? (
          <div className="space-tree-loading">
            <TaskSkeleton depth={depth - 1} depthPadding={depth === 0 ? 20 : 0} />
            <TaskSkeleton depth={depth - 1} depthPadding={depth === 0 ? 20 : 0} />
            <TaskSkeleton depth={depth - 1} depthPadding={depth === 0 ? 20 : 0} />
            <TaskSkeleton depth={depth - 1} depthPadding={depth === 0 ? 20 : 0} />
          </div>
        ) : (
          children
        )}
      </li>
    </RTLStyleDecorator>
  )
}
