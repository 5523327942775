/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateWorkspace, getWorkspaces } from './workspaceThunk'

const initialState: IWorkspaceState = {
  status: 'idle',
  workspace: null,
  userWorkspaces: [],
  error: null,
}

const workspaceSettingSlice = createSlice({
  name: 'workspaceSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateWorkspace.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(updateWorkspace.fulfilled, (state, action: PayloadAction<any>) => {
      state.workspace = action.payload.payload
      state.status = 'succeeded'
    })
    builder.addCase(updateWorkspace.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getWorkspaces.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getWorkspaces.fulfilled, (state, action: PayloadAction<any>) => {
      state.userWorkspaces = action.payload.payload
      state.status = 'succeeded'
    })
    builder.addCase(getWorkspaces.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {} = workspaceSettingSlice.actions // eslint-disable-line

export default workspaceSettingSlice.reducer
