import DateNavigator from './DateNavigator'
import { ReactComponent as EmptyQuestion } from '../../assets/emptyQuestions.svg'
import { QuestionItem } from './QuestionITem'
import { useFollowUpContext } from '../../context/FollowUpContext'
import { useGetQuestionsQuery } from '@src/modules/dailyQuestions/services/managerQuestionsApi'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Questions() {
  const { team, teams } = useFollowUpContext()
  const [currentResponse, setCurrentResponse] = useState<Date>(new Date())
  const { t } = useTranslation(['dashboard'])
  const { t: tdq } = useTranslation(['dailyQuestion'])
  let dateQuery: string
  let endDateQuery: string
  const endDate = new Date(currentResponse)
  const indexOfTheDay = endDate.getDay()
  endDate.setDate(endDate.getDate() + 1)
  endDateQuery = `${currentResponse.getFullYear()}-${(currentResponse.getMonth() + 1)
    .toString()
    .padStart(1, '0')}-${currentResponse.getDate().toString().padStart(1, '0')}`
  dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
    .toString()
    .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
  const { data: questions, isLoading: isLoad } = useGetQuestionsQuery(
    { teamId: team?.id },
    { skip: !team?.id },
  )

  return (
    <>
      {teams?.length > 0 ? (
        <div className="followup-dashboard_section">
          <div className="followup-dashboard_section_date">
            <p
              className="followup-dashboard_section_date-today"
              onClick={() => setCurrentResponse(new Date())}
            >
              {tdq('answers_tab.today')}
            </p>
            <DateNavigator currentDate={currentResponse} setCurrentDate={setCurrentResponse} />
          </div>
          <QuestionItem
            isLoad={isLoad}
            dateQuery={dateQuery}
            endDateQuery={endDateQuery}
            questions={questions}
            indexOfTheDay={indexOfTheDay}
          />
        </div>
      ) : (
        <div className="followup-dashboard-empty">
          <EmptyQuestion />
          <p>{t('no_teams')}</p>
        </div>
      )}
    </>
  )
}
