import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { Modal, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import closed from '../../../assets/icons/trash/closed.svg'
import deleteTrash from '../../../assets/icons/trash/deleteTrash.svg'
import hide from '../../../assets/icons/trash/hide.svg'
import visible from '../../../assets/icons/trash/visible.svg'
import circle from '../../../assets/icons/trash/circle.svg'
import { informClearTrash } from '@src/modules/settings/data/trash/TrashSlice'
import { clearTrash } from '@src/modules/settings/data/trash/TrashThunk'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'

const ClearTrashModal = ({ open, handleClose, id, data }: any) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['trash'])
  const { status } = useAppSelector((state) => state.trash)

  const [password, setPassword] = useState<string>('')
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const lang = localStorage.getItem('i18nextLng')

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
    setError('')
  }

  const handleChangeVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const handleClearTrash = () => {
    if (password) {
      dispatch(clearTrash(password))
        .unwrap()
        .then(() => {
          dispatch(informClearTrash(true))
          handleClose(id)
          data()
        })
        .catch(() => {
          message.error(t('Invalid_password'))
        })
    } else setError(t('Password_is_required'))
  }

  return (
    <Modal
      footer={true}
      centered
      open={open}
      closeIcon={null}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      closable={true}
      className="delete-permanently-modal"
    >
      <div className="delete-permanently-modal_header">
        {t('Clear trash')}
        <img
          src={circle}
          onClick={() => handleClose(id)}
          className={`close ${lang === 'ar' ? 'ar-close' : ''}`}
        />
      </div>

      <div className="delete-permanently-modal_content">
        <img src={deleteTrash} />
        <p>
          {t(
            'You are about to clear all the trash. This action is permanent and cannot be undone.',
          )}
        </p>

        <p>{t('To confirm this action, please enter your password.')}</p>

        <label>
          <Input
            variant="label-inline"
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={handlePasswordChange}
            value={password}
            placeholder={t('Enter password to confirm deletion')}
            prefix={<img src={closed} />}
            suffix={
              <img
                src={isPasswordVisible ? visible : hide}
                className="hide"
                onClick={handleChangeVisibility}
              />
            }
          />
          {error && <span className="error">{error}</span>}
        </label>
      </div>

      <div className="delete-permanently-modal_footer">
        <Button className="btn cancel" onClick={() => handleClose(id)}>
          {t('Cancel')}
        </Button>

        <Button
          className="btn delete"
          style={error ? { cursor: 'not-allowed' } : {}}
          disabled={error ? true : false}
          onClick={handleClearTrash}
          loading={status === 'loading'}
        >
          {t('Delete')}
        </Button>
      </div>
    </Modal>
  )
}

export default ClearTrashModal
