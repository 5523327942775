import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { Skeleton } from 'antd'

const SetPrioritySkeleton = () => {
  const { width } = useWindowSize()

  return (
    <div className="skeleton_card setPriority_skeleton">
      <div className="setState_skeleton_header">
        <div className="setState_skeleton_header_right">
          <Skeleton.Avatar active></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={{ width: '194px', height: '16px', borderRadius: '30px' }}
          ></Skeleton.Button>

          {width > 450 && (
            <Skeleton.Avatar active style={{ width: '20px', height: '20px' }}></Skeleton.Avatar>
          )}

          {width > 450 && (
            <Skeleton.Button
              active
              style={{ width: '60px', height: '16px', borderRadius: '30px' }}
            ></Skeleton.Button>
          )}
        </div>

        {width > 900 && (
          <Skeleton.Button
            active
            style={{ width: '85px', height: '22px', borderRadius: '30px' }}
          ></Skeleton.Button>
        )}
      </div>

      <Skeleton.Button
        active
        style={{ width: '220px', height: '16px', borderRadius: '30px', marginLeft: '40px' }}
      ></Skeleton.Button>
    </div>
  )
}

export default SetPrioritySkeleton
