import { getOneTemplate, getTemplates } from '../../data/statusSlice/statusThunk'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { useEffect, useState } from 'react'

import Button from '@src/modules/shared/components/Button/Button'
import { DragDropContext } from 'react-beautiful-dnd'
import { ReactComponent as PrivateIcon } from '../../assets/icons/space/private.svg'
import { ReactComponent as PublicIcon } from '../../assets/icons/space/public.svg'
import { SpaceStepProps } from '../Modals/SpaceModal/SpaceModal'
import Spinner from '../Spinner/Spinner'
import StatusGroup from './StatusGroup'
import TaskUsers from '@src/modules/tasks/components/TaskUsers'
import back from '../../assets/icons/space/back.svg'
import { message } from 'antd'
import { updateTemplates } from '../../data/statusSlice/statusSlice'
import { useTranslation } from 'react-i18next'
import { useGetCategoriesQuery } from '../../services/categoryApi'

interface SpaceTypeProps {
  data: SpaceType
  isActive: boolean
  clickEvent: (t: SpaceType) => void
}

const SpaceType: React.FC<SpaceTypeProps> = ({ data, isActive, clickEvent }) => {
  return (
    <div className={`type ${isActive ? 'active-type' : ''}`} onClick={() => clickEvent(data)}>
      <div className="type-content">
        <p>{data.name}</p>
        {data.icon}
      </div>
      <p className="type-description">{data.description}</p>
    </div>
  )
}

export const spaceTypes: (t) => SpaceType[] = (t) => [
  {
    id: 'private',
    name: t('space.private'),
    icon: <PrivateIcon />,
    description: t('space.private_description'),
  },
  {
    id: 'public',
    name: t('space.public'),
    icon: <PublicIcon />,
    description: t('space.public_description'),
  },
]

interface SpaceType {
  name: string
  icon: any
  description: string
}

const DetailsForm = ({ data, setNextStep, setPreviousStep, setData }: SpaceStepProps) => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const { template, status } = useAppSelector((state) => state.status)
  const [activeType, setActiveType] = useState<string>(data?.sharing?.name || spaceTypes(t)[0].name)
  const { data: categories } = useGetCategoriesQuery()
  const onSelectType = (type: SpaceType) => {
    setActiveType(type.name)
    setData((data: any) => {
      const newData = {
        ...data,
        sharing: type || spaceTypes(t)[0],
      }

      if (type.name === t('space.public')) {
        newData.shareWith = []
      }

      return newData
    })
  }

  const handelNextStep = () => {
    const { status } = template
    const isEmpty = Object.keys(status).every((key) => status[key].length === 0)
    if (isEmpty) {
      message.error(t('space.status-error'))
      return
    }

    if (!data?.sharing) {
      setData((prev) => {
        return {
          ...prev,
          sharing: spaceTypes(t)[0],
        }
      })
    }
    setNextStep()
  }

  useEffect(() => {
    if (!template)
      dispatch(getTemplates())
        .unwrap()
        .then(async (res) => {
          dispatch(getOneTemplate({ name: res?.payload[0]?.name }))
        })
        .catch(() => {
          message.error('error')
        })
  }, [])

  const onDragEnd = (result: any, columns: any) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn]
      const destItems = [...destColumn]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      dispatch(
        updateTemplates({
          ...columns,
          [source.droppableId]: sourceItems,
          [destination.droppableId]: destItems,
        }),
      )
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      dispatch(
        updateTemplates({
          ...columns,
          [source.droppableId]: copiedItems,
        }),
      )
    }
  }

  const getCategoryByName = (name: string) => categories?.find((category) => category.name === name)

  return (
    <div className="ta-form space-form">
      <img src={back} onClick={setPreviousStep} className="back-icon" />

      <div className="sharing">
        <div className="form-header">
          <p className="label">{t('space.Sharing')}</p>
          <p className="description">{t('space.Select the privacy of your Space')}</p>
        </div>

        <div className="space-type">
          {spaceTypes(t).map((type, i: number) => (
            <SpaceType
              data={type}
              clickEvent={onSelectType}
              isActive={type.name === activeType}
              key={i}
            />
          ))}
        </div>

        {activeType === t('space.private') ? (
          <div className="space-form-assigned-users">
            <span>{t('space.Assign to')}</span>

            <TaskUsers
              userNumber={data.shareWith?.length}
              displayAddButton={true}
              members={data.shareWith || []}
              setAssignedMembersList={(m) => setData((prev) => ({ ...prev, shareWith: m }))}
            />
          </div>
        ) : null}
      </div>

      <div className="status">
        <div className="form-header">
          <p className="label">{t('space.Statuses')}</p>
          <p className="description">{t('space.Select the status of your Space')}</p>
        </div>

        <div className="status-content">
          {status === 'loading' ? (
            <Spinner />
          ) : (
            <DragDropContext onDragEnd={(result) => onDragEnd(result, template?.status)}>
              {template?.status &&
                Object.keys(template?.status).map((statusGroupKey, i) => (
                  <StatusGroup
                    name={statusGroupKey}
                    categoryId={getCategoryByName(statusGroupKey)?.id}
                    statuses={template?.status}
                    key={i}
                  />
                ))}
            </DragDropContext>
          )}
        </div>
      </div>

      <div className="form-actions">
        <Button htmlType="submit" className="confirm-action" onClick={handelNextStep}>
          {t('space.next')}
        </Button>
      </div>
    </div>
  )
}

export default DetailsForm
