import { Avatar, Empty, Select, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { ReactComponent as CheckboxSVG } from '../../../assets/icons/manager/checkbox.svg'
import { ReactComponent as ActiveCheckboxSVG } from '../../../assets/icons/manager/active-checkbox.svg'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { ReactComponent as DeleteUserSVG } from '../../../assets/icons/manager/remove-user.svg'
import { localSearch } from '@src/modules/shared/utils/localSearch'
import { useTranslation } from 'react-i18next'
import Search from '@src/modules/shared/components/Input/search'

interface MembersListProps {
  setSearch?: (s: string) => void
  search: string
  members: memberType[]
  select: (id: string) => void
  selectedMembers: string[]
  toggleSelectAll: () => void
}

const MembersList = ({
  setSearch,
  search,
  members,
  select,
  selectedMembers,
  toggleSelectAll,
}: MembersListProps) => {
  const isSelcted = (id: string) => selectedMembers?.includes(id)
  const { t } = useTranslation(['members'])

  return (
    <div className="team-members-dropdown">
      <div className="search-bar">
        <Search
          defaultValue={search}
          onSearch={(s) => setSearch(s)}
          prefix={''}
          variant="underlined"
        />
        <p onClick={toggleSelectAll}>
          {' '}
          {selectedMembers?.length === members?.length ? 'Unselect All' : 'Select All'}
        </p>
      </div>
      <div className="members-container">
        <p>Members</p>
        <div className="members-container-list hovered-scrollbar">
          {members?.length > 0 ? (
            members?.map((member) => {
              const name = member?.fullName || member?.email
              return (
                <div className="member-item" key={member?.id} onClick={() => select(member?.id)}>
                  {isSelcted(member?.id) ? <ActiveCheckboxSVG /> : <CheckboxSVG />}
                  <Avatar src={member?.avatar}>{name && name[0]?.toLocaleUpperCase()}</Avatar>
                  <Tooltip title={name}>
                    <p>{name}</p>
                  </Tooltip>
                </div>
              )
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />
          )}
        </div>
      </div>
    </div>
  )
}

interface TeamsMmebersListProps {
  members: memberType[]
  onSelect: (m: string[]) => void
  defaultSelected?: string[]
}

const TeamsMmebersList = ({ members, onSelect, defaultSelected = [] }: TeamsMmebersListProps) => {
  const { t } = useTranslation(['dailyQuestion'])
  const [search, setSearch] = useState<string>('')
  const [selectedMembers, setSelectedMembers] = useState<string[]>(defaultSelected)
  const filteredMembers = localSearch(members, search, ['fullName', 'email'])

  const selectMember = (id: string) => {
    setSelectedMembers((prev) => {
      const index = prev?.indexOf(id)
      const newMembers = [...(prev || [])]
      if (index !== -1) {
        newMembers.splice(index, 1)
      } else {
        newMembers.push(id)
      }
      onSelect(newMembers)
      return newMembers
    })
  }
  const toggleSelectAll = () => {
    if (selectedMembers?.length === members?.length) {
      setSelectedMembers([])
      onSelect([])
    } else setSelectedMembers(filteredMembers?.map((member) => member?.id))
    onSelect(filteredMembers?.map((member) => member?.id))
  }

  const removeUser = (id: string) => {
    setSelectedMembers((prev) => {
      const filteredMembers = prev?.filter((selectId) => selectId !== id)
      onSelect(filteredMembers)
      return filteredMembers
    })
  }
  useEffect(() => {
    const element = document?.querySelector('.ant-select-selection-overflow')
    const handleWheel = (event) => {
      event?.preventDefault()
      element?.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      })
    }

    element?.addEventListener('wheel', handleWheel)
    return () => {
      element?.removeEventListener('wheel', handleWheel)
    }
  }, [])
  return (
    <div className="teams-members-list">
      <Select
        mode="multiple"
        id="container"
        value={selectedMembers}
        className="teams-members-list-multiple-select"
        showSearch={false}
        onDropdownVisibleChange={() => setSearch('')}
        dropdownStyle={{ width: 'fit-content' }}
        dropdownRender={() => (
          <MembersList
            members={filteredMembers}
            search={search}
            select={selectMember}
            selectedMembers={selectedMembers}
            setSearch={setSearch}
            toggleSelectAll={toggleSelectAll}
          />
        )}
        tagRender={(props) => {
          const user = members?.find((member) => member?.id === props?.value)
          const name = user?.fullName || user?.email
          return (
            <div className="selected-users-item">
              <Avatar src={user?.avatar}>{name && name[0]}</Avatar>
              <Tooltip title={name}>
                <p>{name}</p>
              </Tooltip>
              <DeleteUserSVG onClick={() => removeUser(props?.value)} />
            </div>
          )
        }}
        searchValue={search}
        placeholder={t('search_members_by_name_or_email')}
        filterOption={false}
        onSearch={(s) => setSearch(s)}
        // options={[]}
      />
    </div>
  )
}

export default TeamsMmebersList
