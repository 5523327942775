import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Dropdown, MenuProps, message } from 'antd'
import { statusType, updateTemplates } from '../../data/statusSlice/statusSlice'

import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { ReactComponent as ConfirmIcon } from '../../assets/icons/space/add-status.svg'
import { ReactComponent as DeleteIcon } from './../../assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as DragIcon } from '../../../tasks/assets/icons/status/drag-button.svg'
import { ReactComponent as CloseIcon } from '../../../tasks/assets/icons/status/close-icon.svg'

import { ReactComponent as RenameIcon } from './../../assets/icons/spaceDropdown/update.svg'
import { ReactComponent as SettingsIcon } from '../../../tasks/assets/icons/status/settings.svg'
import { addOpacity } from '@src/modules/shared/utils/addOpacity'
import { useAppDispatch } from '@src/modules/shared/store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '@src/modules/settings/features/Workspace/Workspace'
import Input from '@src/modules/shared/components/Input/Input'

interface StatusGroupProps {
  name: string
  statuses: any
  categoryId?: string
}

const StatusGroup = ({ name, statuses, categoryId }: StatusGroupProps) => {
  const { t } = useTranslation(['modals'])
  const { t: taskTranslation } = useTranslation(['tasks'])

  const newStatusValidation = (status: any): string => {
    if (!status.name || !status?.name.trim()) {
      return t('space.Status-name-is-required')
    }
    if (!status.color) {
      return t('space.Status-color-is-required')
    }
    return ''
  }

  const [newStatus, setNewStatus] = useState<any>(null)
  const dispatch = useAppDispatch()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: t('space.Status Management'),
      icon: <RenameIcon />,
    },
    {
      key: '2',
      label: t('space.Delete'),
      icon: <DeleteIcon />,
    },
  ]

  const createStatusHandler = () => {
    const validationMessage = newStatusValidation(newStatus)
    if (newStatus && validationMessage.length === 0) {
      dispatch(
        updateTemplates({
          ...statuses,
          [name]: newStatus.isEdit
            ? [
                ...statuses[name].map((status: statusType) =>
                  status.id === newStatus.id ? { ...status, ...newStatus } : status,
                ),
              ]
            : [
                ...statuses[name],
                {
                  ...newStatus,
                  id: Math.random() * 9999,
                  categoryId,
                },
              ],
        }),
      )
      setNewStatus(null)
    } else {
      message.error(validationMessage)
    }
  }

  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') createStatusHandler()
  }

  return (
    <Droppable key={name} droppableId={name}>
      {(provided, _) => (
        <div className="status-group" ref={provided.innerRef} {...provided.droppableProps}>
          <div className="group-name">
            <p>{taskTranslation(name)}</p>
            <div
              className="settings"
              style={
                newStatus
                  ? {
                      transform: 'rotate(133deg)',
                      transition: 'transform 300ms',
                    }
                  : { transition: 'transform 300ms' }
              }
              onClick={() => setNewStatus(newStatus ? null : {})}
            >
              +
            </div>
          </div>

          {newStatus ? (
            <div
              className="add-status-input"
              // ref={inputRef}
              // onBlur={focusOut}
              tabIndex={0}
              onKeyDown={keyDownHandler}
              style={{ borderColor: newStatus.color }}
            >
              <Input
                type="text"
                placeholder={t('space.New Status')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewStatus((prev) => ({ ...prev, name: e.target.value }))
                }
                autoFocus
                value={newStatus?.name || ''}
                variant="label-inline"
              />

              <div className="colors">
                <div className="colors-list">
                  <ColorSelect
                    colors={colors.slice(0, 3)}
                    selectedColor={newStatus?.color}
                    setSelectedColor={(c: string) =>
                      setNewStatus((prev) => ({ ...prev, color: c }))
                    }
                  />
                </div>

                <div className="btn-confirm" onClick={createStatusHandler}>
                  <ConfirmIcon />
                </div>
              </div>
            </div>
          ) : null}

          <div className="space-modal-status-list ">
            {statuses[name]?.map((status: statusType, i: number) => {
              return (
                <Draggable key={status.name} draggableId={status.id?.toString()} index={i}>
                  {(provided) => (
                    <div
                      className="status-item"
                      key={i}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="status-item-content">
                        <div>
                          <div className="drag-icon" {...provided.dragHandleProps}>
                            <DragIcon />
                          </div>

                          <div
                            className="status-info"
                            style={{ background: addOpacity(status.color) }}
                          >
                            <div className="icon" style={{ background: status.color }}></div>
                            <p>{taskTranslation(status.name)}</p>
                            <CloseIcon
                              style={{ fill: status.color }}
                              onClick={() =>
                                dispatch(
                                  updateTemplates({
                                    ...statuses,
                                    [name]: statuses[name].filter(
                                      (obj: statusType) => obj.id !== status.id,
                                    ),
                                  }),
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="settings">
                          <Dropdown
                            menu={{
                              items,
                              onClick: (e) => {
                                if (e.key === '1')
                                  setNewStatus({
                                    name: status.name,
                                    color: status.color,
                                    id: status.id,
                                    isEdit: true,
                                  })
                                else {
                                  dispatch(
                                    updateTemplates({
                                      ...statuses,
                                      [name]: statuses[name].filter(
                                        (obj: statusType) => obj.id !== status.id,
                                      ),
                                    }),
                                  )
                                }
                              },
                            }}
                            trigger={['click']}
                          >
                            <SettingsIcon />
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            })}
          </div>
        </div>
      )}
    </Droppable>
  )
}

export default StatusGroup
