/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export type filterType = {
  where: {
    createdAt?: {
      operation: '$between'
      value: string[]
    }
    type?: {
      operation: '$in'
      value: string[]
    }
    userId?: {
      operation: '$in'
      value: string[]
    }
  }
}

export const getTrash = createAsyncThunk(
  'trash/getTrash',
  async ({ filter, page }: { filter?: filterType | string; page: number }) => {
    try {
      const response = await axiosInstance.get(`/api/trash?options=${filter}&limit=15&skip=${page}`)
      if (response.status === 200) {
        return response.data
      }
    } catch (error: any) {
      return Promise.reject(error.message)
    }
  },
)

export const restoreDeletedElem = createAsyncThunk(
  'trash/restoreDeletedElem',
  async (id: string) => {
    try {
      const response = await axiosInstance.post(`/api/trash/restore/${id}`)
      if (response.status === 200) {
        return response.data
      }
    } catch (error: any) {
      return Promise.reject(error.message)
    }
  },
)

export const deleteItemPermanently = createAsyncThunk(
  'trash/deletePermanently',
  async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/api/trash/delete/${id}`)
      if (response.status === 204) {
        return response.data
      }
    } catch (error: any) {
      return Promise.reject(error.message)
    }
  },
)

export const clearTrash = createAsyncThunk('trash/clear-trash', async (password: string) => {
  try {
    const response = await axiosInstance.delete('/api/trash/clear', { data: { password } })
    if (response.status === 204) {
      return response.data
    }
  } catch (error: any) {
    return Promise.reject(error.message)
  }
})
