import LastUpdateInfos from '@src/modules/document/components/LastUpdateInfos'
import { useParams } from 'react-router-dom'
import BlockNoteRichText from '../../components/BlockNoteRichText'
import DocsHeader from '../../components/DocsHeader'
import NotFound from '../../features/NotFound/NotFound'
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader'
import DocumentSkeleton from '@src/modules/document/components/DocumentSkeleton/DocumentSkeleton'
import { useTranslation } from 'react-i18next'
import { useGetPublicDocumentQuery } from '@src/modules/documents/services/documents'
function transformURL(url) {
  try {
    const parsedURL = new URL(url)
    const protocol = parsedURL.protocol
    const hostname = parsedURL.hostname
    const port = parsedURL.port ? `:${parsedURL.port}` : ''
    const newHostname = hostname.startsWith('docs.') ? hostname.slice(5) : hostname
    return `${protocol}//${newHostname}${port}`
  } catch (error) {
    console.error('Invalid URL:', error)
    return null
  }
}
const DocsLayout = () => {
  const { t } = useTranslation(['document'])
  const { documentId } = useParams()

  if (!documentId) {
    window.location.replace(transformURL(window.location.href || '/'))
    return
  }
  const extractedId = documentId?.substring(documentId.lastIndexOf('-') + 1)

  const { data: document, isLoading, isError } = useGetPublicDocumentQuery(extractedId)

  const realTitle = document?.name
  let newRealTitle = realTitle?.toLowerCase()?.replace(/ /g, '-')
  if (newRealTitle?.length > 50) {
    newRealTitle = newRealTitle?.substring(0, 50)
  }

  const newPath = `/${newRealTitle}-${extractedId}`
  if (window.location.pathname !== newPath) {
    window.history.replaceState(null, '', newPath)
  }

  return (
    <SkeletonLoader isLoading={isLoading} skeleton={<DocumentSkeleton />}>
      {document && (
        <div className="shared-doc" id="shared-doc">
          <DocsHeader document={document} />

          <div className="shared-doc_cover">
            <img
              src={document?.cover}
              style={{
                objectPosition: `center ${document?.cover_y}%`,
              }}
            />
            <div className="shared-doc_icon">{document?.icon}</div>
          </div>

          <div className="shared-doc_content">
            <h1 className="name">{document?.name}</h1>

            <LastUpdateInfos owner={document?.owner} updatedAt={new Date()} />

            <div className="divider"></div>

            <BlockNoteRichText value={document?.content} setValue={() => {}} onlyView />
          </div>
        </div>
      )}
      {isError && (
        <NotFound
          statusCode={404}
          message={t('this_page_is_currently_unavailable')}
          underMessage={t('join_now_and_create_your_own_pages')}
          buttonName={t('start_for_free')}
          onClick={() => window.location.replace(transformURL(window.location.href || '/'))}
          takeMeBack={false}
        />
      )}
    </SkeletonLoader>
  )
}

export default DocsLayout
