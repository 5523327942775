import Input from '@src/modules/shared/components/Input/Input'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'
import desktop from '../../../assets/icons/desktop.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import React from 'react'

interface Props {
  formik: any
  setConfirmWorkspaceName: (value: boolean) => void
  confirmWorkspaceName: boolean
  confirm: boolean
}
const WorkspaceName: React.FC<Props> = ({
  formik,
  setConfirmWorkspaceName,
  confirmWorkspaceName,
  confirm,
}) => {
  const { t } = useTranslation(['workspaces'])
  const { width } = useWindowSize()

  return (
    <>
      <h1>{t('Your workspace needs a name')} </h1>

      <Input
        className="custom-input"
        containerClassName="workspace-name-input"
        name="workspaceName"
        formik={formik}
        variant="underlined"
        placeholder={t('Enter your workspace name')}
        disabled={!confirm}
        type="text"
        status={formik.errors.workspaceName ? 'error' : 'success'}
      />

      {width <= 850 && <img src={desktop} alt={'desktopIcon'} />}

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmWorkspaceName(!confirmWorkspaceName)}
        disabled={!(confirm && formik?.values?.workspaceName?.length > 0)}
      />
    </>
  )
}

export default WorkspaceName
