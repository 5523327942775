import DropdownSpinner from '@src/modules/shared/components/DropdownSpinner'
import { Flag } from './TaskPriority'
import { useUpdateTaskMutation } from '../../services/tasksApi'
import { TaskListData, TaskListProps } from '../TaskStatus/StatusList'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { useTranslation } from 'react-i18next'

export interface priorityType {
  id: number
  color: string
  name: string
}

interface PriorityListData extends TaskListData {
  priorities: priorityType[] | null
  task: TaskType
}
interface PriorityListProps extends TaskListProps {
  data: PriorityListData
  mutpile?: boolean
}

const PriorityList = ({ data, handleCloseAllDropdowns, mutpile }: PriorityListProps) => {
  const { t } = useTranslation(['tasks'])
  const [updateTask] = useUpdateTaskMutation()

  const updatePriority = (priorityId: number) => {
    handleCloseAllDropdowns()
    data?.task && updateTask({ id: data.task?.id, priority: priorityId })
    if (mutpile) {
      data?.onSelect([...data.activeItemIDs, priorityId])
    } else data.onSelect(priorityId)
  }
  return (
    <div className="task-dropdown priority-list ant-dropdown-menu">
      {data?.isLoading ? (
        <DropdownSpinner />
      ) : (
        data?.priorities.map((priority, i: number) => (
          <div
            className={`priority-item ${data?.activeItemIDs?.includes(priority?.id) ? 'active-status' : ''}`}
            key={i}
            onClick={() => updatePriority(priority?.id)}
          >
            <Flag color={priority?.color} stroke={priority.color || '#B8B6B6'} />
            <p className="label">{t(`priorities.${priority?.name}`) || 'Priority'}</p>
          </div>
        ))
      )}
    </div>
  )
}

export default PriorityList
