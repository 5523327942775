import { useTranslation } from 'react-i18next'
import { ReactComponent as TaskAttachmentIcon } from './../../assets/icons/task/attachment.svg'
import Input from '@src/modules/shared/components/Input/Input'

export const getFilesUrl = (files: File[]) => {
  return files?.length > 0
    ? files?.map((file) => ({
        file,
        url: file ? URL.createObjectURL(file) : null,
      }))
    : []
}

interface attachmentsType {
  file: File
  url: string | null
}

interface TaskAttachmentsProps {
  onUpload: (e: attachmentsType[]) => void
  defaultFiles: File[]
  setFiles?: (files: File[]) => void
}

const TaskAttachments = ({ onUpload, defaultFiles, setFiles }: TaskAttachmentsProps) => {
  const { t } = useTranslation(['taskDetails'])
  const saveUploadedFiles = (e) => {
    const files: File[] = e.target.files ? Array.from(e.target.files) : []
    const newFiles: attachmentsType[] = getFilesUrl(files)
    onUpload(newFiles)
    setFiles([...defaultFiles, ...files])
  }

  return (
    <label className="task-attachments" htmlFor="create-task-attachments-button">
      <TaskAttachmentIcon />
      <span>{t('attachements')} +</span>
      <Input
        type="file"
        id="create-task-attachments-button"
        multiple
        onChange={saveUploadedFiles}
        variant="label-inline"
        containerClassName="task-attachments-input"
      />
    </label>
  )
}

export default TaskAttachments
