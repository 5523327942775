import axiosInstance from '@src/modules/shared/utils/axios'

export const uploadFile = (file, onUpload, onError?: (error) => void) => {
  const formData = new FormData()
  formData.append('files', file)

  return axiosInstance
    .post(`/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (onUpload) onUpload(response.data.payload[0])
      return response.data.payload[0].location
    })
    .catch((error) => {
      console.error('Upload failed', error)
      onError?.(error)
      return {
        success: 0,
      }
    })
}

export const uploadMultipleFiles = (files, onUpload, onError?: (error) => void) => {
  const formData = new FormData()

  files.forEach((file) => {
    formData.append('files', file)
  })

  return axiosInstance
    .post(`/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (onUpload) onUpload(response.data.payload)
      return response.data.payload[0].location
    })
    .catch((error) => {
      onError?.(error)
      return {
        success: 0,
      }
    })
}
