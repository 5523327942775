/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'
import { TimerType } from '@src/modules/tasks/data/timerData/types'

export const addManualTimer = createAsyncThunk(
  'spaces/add-timer',
  async ({
    startTime,
    endTime,
    taskId,
    description,
  }: {
    startTime: string
    endTime: string
    taskId: string
    description?: string
  }) => {
    try {
      const response = await axiosInstance.post(`/api/timer`, {
        startTime,
        endTime,
        taskId,
        description,
      })

      if (response.status === 201) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const startTimer = createAsyncThunk(
  'spaces/start-timer',
  async ({
    taskId,
    description = 'random description',
  }: {
    taskId: string
    description?: string
  }) => {
    try {
      const response = await axiosInstance.post(`/api/timer/start`, {
        taskId,
        description,
      })

      if (response.status === 201) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const closeTimer = createAsyncThunk(
  'spaces/close-timer',
  async ({ timerId }: { timerId: string }) => {
    try {
      const response = await axiosInstance.patch(`/api/timer/close`, {
        timerId,
      })

      if (response.status === 200) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const deleteTimer = createAsyncThunk(
  'spaces/delete-timer',
  async ({ id }: { id: string }) => {
    try {
      const response = await axiosInstance.delete(`/api/timers/${id}`)

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const updateAndRestartTimer = createAsyncThunk(
  'spaces/update-and-restart-timer',
  async ({
    taskId,
    timerId,
    description,
  }: {
    taskId: string
    timerId: string
    description?: string
  }) => {
    try {
      const stopResponse = await axiosInstance.patch(`/api/timer/close`, {
        timerId,
      })

      if (stopResponse.status !== 200) {
        throw new Error(stopResponse.statusText)
      }

      const startResponse = await axiosInstance.post(`/api/timer/start`, {
        taskId,
        description,
      })

      if (startResponse.status !== 201) {
        throw new Error(startResponse.statusText)
      }
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getTimers = createAsyncThunk(
  'spaces/get-timers',
  async ({ taskId, limit = 5 }: { taskId: string; limit?: number }) => {
    try {
      const response = await axiosInstance.get(`/api/timer/${taskId}?limit=${limit}`)
      if (response.status === 200) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const editTimer = createAsyncThunk(
  'spaces/edit-timer',
  async ({ timerId, payload }: { timerId: string; payload: TimerType }) => {
    try {
      const response = await axiosInstance.patch(`/api/timers/${timerId}`, payload)
      if (response.status === 200) {
        return response.data.payload
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
