/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createComment, deleteComment, getAllComments, updateComment } from './commentThunk'
import { CommentType } from './commentType'

const initialState: CommentType = {
  error: null,
  status: 'idle',
  comment: null,
  commentsList: [],
  deletedComment: '',
  deleteStatus: '',
}

const CommentSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setDeletedComment: (state, { payload }) => {
      state.deletedComment = payload
    },
  },

  extraReducers: (builder) => {
    /** ---create-- */
    builder.addCase(createComment.pending, (state) => {
      state.error = null
    })

    builder.addCase(createComment.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.comment = action?.payload
      state.status = 'success'
    })

    builder.addCase(createComment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    /** ---get all-- */

    builder.addCase(getAllComments.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(getAllComments.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.commentsList = action?.payload?.payload
      state.status = 'success'
    })

    builder.addCase(getAllComments.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    /** -----get-one------ */

    /** -----update------ */
    builder.addCase(updateComment.pending, (state) => {
      state.error = null
      //state.status = 'loading'
    })

    builder.addCase(updateComment.fulfilled, (state, action: PayloadAction<any>) => {
      state.error = null
      state.comment = action?.payload
      state.status = 'success'
    })

    builder.addCase(updateComment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    /** -----delete-one------ */
    builder.addCase(deleteComment.pending, (state) => {
      state.error = null
      state.deleteStatus = 'loading'
    })

    builder.addCase(deleteComment.fulfilled, (state) => {
      state.error = null
      state.deleteStatus = 'success'
    })

    builder.addCase(deleteComment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.deleteStatus = 'failed'
    })
  },
})

export const { setDeletedComment } = CommentSlice.actions // eslint-disable-line

export default CommentSlice.reducer
