import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import calendar from '../../../assets/icons/notification/calendar.svg'

const DueDate = ({ date }: { date: string }) => {
  const dueDate = reformulateDate(date)

  return (
    <div className="due_date_action">
      <img src={calendar} />

      <span>{dueDate?.slice(0, 6)}</span>
    </div>
  )
}

export default DueDate
