import EditSVG from '@src/modules/shared/assets/icons/components/edit-2.svg'
import TrashSVG from '@src/modules/shared/assets/icons/components/trash.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { useAppDispatch } from '@src/modules/shared/store'
import { useAppSelector } from '@src/modules/shared/store/index'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { dateToTimeSince } from '@src/modules/shared/utils/date'
import {
  useAddCommnetMutation,
  useUpdateCommentMutation,
} from '@src/modules/tasks/services/commentsApi'
import { Avatar, Tooltip, message } from 'antd'
import React, { RefAttributes, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CommentInput from '../CommentInput'
import Reactions from './Reactions'
// import { setCommentToEdit } from '@src/modules/tasks/data/commentsData/slices'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'
import { IUser } from '@src/modules/auth/data/authTypes'

type Props = {
  children: React.ReactNode
  ref: React.Ref<HTMLDivElement>
  comment: IComment
  owner: IUser
  comments: IComment[]

  setComments?: (value: IComment[]) => void
  refetch?: () => void
}

const CommentItem = React.forwardRef<HTMLDivElement, Props & RefAttributes<HTMLDivElement>>(
  (props, ref) => {
    const dispatch: Function = useAppDispatch()
    const { t } = useTranslation(['document'])
    const { user } = useAppSelector((state) => state.auth)
    const commentInputRef = React.useRef<HTMLTextAreaElement | HTMLDivElement>(null)

    const [commentContent, setCommentContent] = useState<any>(props?.comment?.content || '[]')
    const [replyValue, setReplyValue] = useState<string>('')
    const [showReply, setShowReply] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [isEdit, setEdit] = useState<boolean>(false)

    const [addComment] = useAddCommnetMutation()
    const [editComment, { isLoading }] = useUpdateCommentMutation()

    const [searchparams, _] = useSearchParams()
    const taskId = searchparams.get('taskId')

    useEffect(() => {
      setCommentContent(props?.comment?.content)
    }, [props?.comment?.content])

    const handleMouseEnter = () => {
      if (user.user.id === props.comment.ownerId) setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    const editCommentHandler = () => {
      editComment({
        commentId: props.comment.id || '',
        content: commentContent,
      })
        .catch((err) => {
          message.error(err.message)
        })
        .finally(() => {
          setEdit(false)
        })
    }

    if (!props?.comment || !props?.comment?.id) return null
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="comment-item"
        ref={ref}
      >
        <div className="comment-item-avatar">
          {props?.owner?.avatar ? (
            <Avatar src={props?.owner?.avatar}>
              {(props?.owner?.fullName || props?.owner?.email)[0]?.toUpperCase()}
            </Avatar>
          ) : (
            <Avatar className="task-users-item">
              {props?.comment?.owner?.fullName && props?.owner?.fullName[0]
                ? props?.owner?.fullName[0]
                : props?.owner?.email[0]}
            </Avatar>
          )}
        </div>

        <div className="comment-item-content">
          <div className="comment-item-content-header">
            <div className="comment-item-content-header-name">
              <Tooltip title={props?.owner?.fullName}>
                {props?.owner?.fullName.length > 33
                  ? props?.owner?.fullName.slice(0, 33) + '...'
                  : props?.owner?.fullName}
              </Tooltip>
              <span className="a-commenter">{t('Commented')}</span>
            </div>

            {!isHovered && (
              <div className="comment-item-content-header-date">
                {dateToTimeSince(props.comment.createdAt)}
              </div>
            )}

            {isHovered && user.user.id === props.comment.ownerId && (
              <RTLStyleDecorator>
                <div className="comment-edit-delete-buttons">
                  <Button onClick={() => setEdit(!isEdit)}>
                    <img src={EditSVG} />
                  </Button>

                  <Button
                    onClick={() => {
                      dispatch(
                        openModal({
                          id: `delete-comment-modal`,
                          data: { id: props.comment.id, fetch: props.refetch },
                        }),
                      )
                    }}
                  >
                    <img src={TrashSVG} />
                  </Button>
                </div>
              </RTLStyleDecorator>
            )}
          </div>

          <div className="comment-item-content-body">
            <BlockNoteRichText
              value={commentContent && commentContent}
              setValue={setCommentContent}
              onlyView={!isEdit}
            />

            {isEdit ? (
              <div className="save-comment" onClick={() => editCommentHandler()}>
                {isLoading ? 'Saving...' : 'Save'}
              </div>
            ) : (
              <Reactions
                commentInputRef={commentInputRef}
                showReply={showReply}
                setShowReply={setShowReply}
                commentId={props.comment.id}
              />
            )}

            {props.children}

            {showReply && (
              <CommentInput
                holderId={`reply-text-editor-${props.comment.id}`}
                ref={commentInputRef as any}
                comment={replyValue}
                setComment={setReplyValue}
                onSubmit={() => {
                  addComment({
                    taskId: taskId || '',
                    parentId: props.comment.id,
                    content: replyValue,
                  })
                    .then(() => {
                      props.refetch()
                      setReplyValue('')
                      setShowReply(false)
                      setReplyValue('')
                    })
                    .catch((err) => {
                      message.error(err.message)
                    })
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  },
)
export default CommentItem
