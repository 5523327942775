import React from 'react'
import { Skeleton } from 'antd'

const WorkspaceSkeleton: React.FC = () => {
  return (
    <div className="skeleton-card-container">
      <div className="skeleton-header-container">
        <Skeleton.Avatar active size={'large'} />
        <Skeleton.Button active size="small" shape="round" style={{ width: '82px' }} />
        <div className="small-avatar-container">
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Avatar active size={'small'} />
        </div>
      </div>

      <div className="skeleton-btn-container">
        <Skeleton.Button active size="small" shape="round" style={{ width: '114px' }} />
        <Skeleton.Button active size="small" shape="round" style={{ width: '70px' }} />
      </div>
      <div className="large-avatar-container">
        <Skeleton.Avatar active size={'large'} className="large-avatar" />
        <Skeleton.Avatar active size={'large'} className="large-avatar" />
        <Skeleton.Avatar active size={'large'} className="large-avatar" />
        <Skeleton.Avatar active size={'large'} className="large-avatar" />
        <Skeleton.Avatar active size={'large'} className="large-avatar" />
      </div>
    </div>
  )
}

export default WorkspaceSkeleton
