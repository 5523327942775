import Input from '@src/modules/shared/components/Input/Input'
import { useTranslation } from 'react-i18next'

function ProgressManual({ formik, data = null }) {
  const { t } = useTranslation(['customFields'])
  return (
    <div className="settings-progress-manual-container">
      <div>
        <Input
          formik={formik}
          name="values.start"
          variant="filled"
          label={t('start_value')}
          disabled={!!data}
          value={data ? data?.typeConfig?.start : null}
          type="number"
          placeholder={'0'}
        />
      </div>
      <div>
        <Input
          formik={formik}
          name="values.end"
          disabled={!!data}
          variant="filled"
          label={t('end_value')}
          value={data ? data?.typeConfig?.end : null}
          type="number"
          placeholder={'100'}
        />
      </div>
    </div>
  )
}

export default ProgressManual
