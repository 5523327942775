import { Button, Dropdown, message } from 'antd'
import { useState } from 'react'
import { ReactComponent as EditeIcon } from '../../assets/spaceDropdown/edit.svg'
import { ReactComponent as DeleteIcon } from '@src/modules/spaces/assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as DotSVG } from '@src/modules/tasks/assets/icons/task/dots.svg'
import { ReactComponent as DuplicateIcon } from '@src/modules/spaces/assets/icons/spaceDropdown/duplicate.svg'
import SeacrhInput from '@src/modules/spaces/components/SeacrhInput'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import { useTranslation } from 'react-i18next'
import { useUpdateCustomFieldMutation } from '../../services/cusomFieldsApi'
import { useDeleteCustomFieldsMutation } from '@src/modules/tasks/services/customFieldsApi'

const CustomFieldActionMenu = ({
  itemData,
  setCustomFieldData,
  setCustomFieldsDrawerData,
  setOpenDrawer,
}: {
  disable: boolean
  itemData: any
  CustomFieldData: any
  setCustomFieldData: (e: any) => void
  setCustomFieldsDrawerData: (e: any) => void
  setOpenDrawer: (e: boolean) => void
}) => {
  const { t } = useTranslation(['tasks'])
  const { t: customFieldsTranslation } = useTranslation(['customFields'])
  const [visisble, setVisisble] = useState<boolean>(false)
  const [treeVisible, setTreeVisible] = useState<{ move: boolean; copy: boolean }>({
    move: false,
    copy: false,
  })
  const closeDropdown = () => setVisisble(false)
  const [delteCustomFields] = useDeleteCustomFieldsMutation()

  const [updateCustomField] = useUpdateCustomFieldMutation()
  const handleChange = (type, newId) => {
    let payload = []

    const createPayload = (ids, key) => {
      if (ids.length > 0) {
        payload.push({ [key]: [ids[0], newId] })
      } else {
        payload.push({ [key]: [newId] })
      }
    }

    if (type === 'list') {
      const ids = itemData?.files?.map((item) => ({ id: item.id })) || []
      createPayload(ids, 'files')
    }

    if (type === 'folder') {
      const ids = itemData?.folders?.map((item) => ({ id: item.id })) || []
      createPayload(ids, 'folders')
    }

    if (type === 'space') {
      const ids = itemData?.spaces?.map((item) => ({ id: item.id })) || []
      createPayload(ids, 'spaces')
    }
    const updatedPayload = { id: itemData?.id, ...payload[0] }
    updateCustomField(updatedPayload).then(() => {
      message.success(customFieldsTranslation('custom_field_successfully_duplicated'))
    })
  }
  const [openDropDown, setOpenDropDown] = useState(false)

  return (
    <div className={`task-item`}>
      <Dropdown
        overlayClassName="space-dropdown"
        trigger={['click']}
        open={visisble}
        onOpenChange={(e) => {
          setTreeVisible({ copy: false, move: false })
          setTimeout(() => setVisisble(e), 10)
        }}
        dropdownRender={() => (
          <div className="task-dropdown task-actions priority-list ant-dropdown-menu">
            <div
              className="task-actions-item"
              onClick={() => {
                setOpenDrawer(true)
                setCustomFieldsDrawerData(itemData)
                closeDropdown()
              }}
            >
              <EditeIcon />
              <span>Edit</span>
            </div>
            <Dropdown
              trigger={['click']}
              open={openDropDown}
              onOpenChange={() => setOpenDropDown((prev) => !prev)}
              dropdownRender={() => {
                return (
                  <div className="ant-dropdown-menu task-dropdown spaces-tree">
                    <SeacrhInput onSearch={() => {}} icon={''} />
                    <div className="spaces-tree-container">
                      <SpacesTree
                        readOnly
                        onSelectList={(items) => {
                          handleChange('list', { id: items[0] })
                          setOpenDropDown(false)
                        }}
                        onSelectSpace={(items) => {
                          handleChange('space', { id: items[0] })
                          setOpenDropDown(false)
                        }}
                        onSelectFolder={(items) => {
                          handleChange('folder', { id: items[0] })
                          setOpenDropDown(false)
                        }}
                      />
                    </div>
                  </div>
                )
              }}
            >
              <div
                className="task-actions-item"
                onClick={() => setTreeVisible((prev) => ({ ...prev, copy: !treeVisible.copy }))}
              >
                <DuplicateIcon />
                <span>{t('list_view.duplicate')}</span>
              </div>
            </Dropdown>

            <div
              className="task-actions-item"
              onClick={() => {
                if (itemData?.id) {
                  delteCustomFields({ id: itemData?.id }).then(() => {
                    setCustomFieldData((prev) => {
                      return {
                        ...prev,
                        payload: prev.payload
                          .map((prevItem) => {
                            if (prevItem.rows.length === 1 && prevItem.rows[0].id === itemData.id) {
                              return null
                            } else {
                              return {
                                ...prevItem,
                                rows: prevItem.rows.filter((subItem) => subItem.id !== itemData.id),
                              }
                            }
                          })
                          .filter((prevItem) => prevItem !== null),
                      }
                    })
                    message.success(customFieldsTranslation('custom_field_deleted_successfully'))
                    setVisisble(false)
                  })
                }
              }}
            >
              <DeleteIcon />
              <span>{t('list_view.delete')}</span>
            </div>
          </div>
        )}
      >
        <div onClick={() => setVisisble(!visisble)}>
          <Button type="text" shape="circle" icon={<DotSVG />} />
        </div>
      </Dropdown>
    </div>
  )
}

export default CustomFieldActionMenu
