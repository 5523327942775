import { createSlice } from '@reduxjs/toolkit'

export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    selectedUsers: [],
    selectedUsersIds: [],
  },
  reducers: {
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload.filter((user) => user !== null && user !== undefined)
    },
    setSelectedUsersIds: (state, action) => {
      state.selectedUsersIds = action.payload.filter((id) => id !== null && id !== undefined)
    },
  },
})

export const { setSelectedUsers, setSelectedUsersIds } = editorSlice.actions

export const selectEditorInstance = (state) => state.editor.instance

export default editorSlice.reducer
