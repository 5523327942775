/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export const getOneTask = createAsyncThunk('spaces/get-task', async (query: { id: string }) => {
  try {
    const response = await axiosInstance.get(`/api/tasks/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getTasks = createAsyncThunk('spaces/get-tasks', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/tasks`, { params: query })

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getSubtasks = createAsyncThunk('spaces/get-subtasks', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/tasks`, { params: query })

    if (response.status === 200) return { response: response.data, query }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const createTag = createAsyncThunk('spaces/create-tag', async (query: any) => {
  try {
    const response = await axiosInstance.post(`/api/tags`, query)

    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getTags = createAsyncThunk('spaces/get-tags', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/tags?spaceId=${query.spaceId}`, query)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const deleteTag = createAsyncThunk('spaces/delete-tag', async (query: any) => {
  try {
    const response = await axiosInstance.delete(`/api/tags/${query.id}`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const editTag = createAsyncThunk('spaces/edit-tag', async (query: any) => {
  try {
    const { id, ...body } = query
    const response = await axiosInstance.patch(`/api/tags/${id}`, body)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
