import { ReactComponent as TimerPause } from '../assets/icons/timer-pause.svg'
import { ReactComponent as TimerPlay } from '../assets/icons/timer-play.svg'
import { formatTimerTime } from '../utils/formateTimerTime'
import { useTranslation } from 'react-i18next'

const TimerContent = ({ isRunning, elapsedSeconds, handelOpen, handelClose }) => {
  const { t } = useTranslation('timer')
  return (
    <div className="timer-dropdown-content-header-tabs-content-timer">
      <div className="timer-dropdown-content-header-tabs-content-timer-controls">
        <div
          onClick={isRunning ? handelClose : handelOpen}
          className={`timer-dropdown-content-header-tabs-content-timer-controls-play ${
            isRunning
              ? 'timer-dropdown-content-header-tabs-content-timer-controls-play-running'
              : ''
          }`}
        >
          {isRunning ? <TimerPause onClick={handelClose} /> : <TimerPlay onClick={handelOpen} />}
          {isRunning ? t('stop') : t('play')}
        </div>

        <span className="timer-dropdown-content-header-tabs-content-timer-display">
          {formatTimerTime(isRunning ? elapsedSeconds : 0)}
        </span>
      </div>
    </div>
  )
}

export default TimerContent
