function Audiopalceholder() {
  return (
    <svg
      fill="#EAEFFD"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 72 72"
      enable-background="new 0 0 72 72"
    >
      <g>
        <path
          d="M10.973,57.619c0-0.553-0.447-1-0.999-1c-0.001,0-0.001,0-0.001,0c-0.552,0-1,0.447-1,0.998
       c-0.001,1.582,0.604,3.08,1.705,4.217c0.197,0.203,0.457,0.305,0.719,0.305c0.251,0,0.501-0.094,0.696-0.281
       c0.397-0.383,0.407-1.019,0.023-1.414C11.378,59.682,10.972,58.68,10.973,57.619z"
        />
        <path
          d="M50.973,49.62c0-0.554-0.447-1.001-1-1.001c-0.554,0-1,0.447-1,0.999c-0.002,1.581,0.603,3.079,1.703,4.216
       c0.198,0.203,0.457,0.305,0.721,0.305c0.25,0,0.5-0.094,0.695-0.281c0.396-0.383,0.407-1.019,0.023-1.414
       C51.376,51.682,50.971,50.68,50.973,49.62z"
        />
        <path
          d="M15.034,61.682c-0.119,0-0.23,0.006-0.334,0.024c-0.498-0.11-1.006,0.168-1.169,0.66c-0.174,0.526,0.111,1.09,0.635,1.264
       c0.226,0.076,0.419,0.106,0.585,0.106c0.138,0,0.257-0.023,0.359-0.057c0.517-0.039,0.924-0.472,0.924-0.998
       C16.034,62.128,15.587,61.682,15.034,61.682z"
        />
        <path
          d="M69.368,40.992c0.001-0.021,0.006-0.04,0.006-0.061L69.207,5.128c-0.001-0.132-0.016-0.265-0.041-0.395
       c0.052-0.249,0.058-0.511,0.007-0.775c-0.207-1.084-1.248-1.792-2.34-1.59l-41,7.832c-1.085,0.208-1.797,1.255-1.59,2.34
       c0.002,0.014,0.008,0.026,0.011,0.04c-0.029,0.141-0.047,0.285-0.046,0.432l0.152,33.677c-2.418-0.444-6.244-1.021-9.818-1.021
       c-6.617,0-12,5.382-12,11.999s5.383,12,12,12c3.913,0,7.1-1.329,9.472-3.946c5.222-5.764,4.44-15.81,4.356-16.723l-0.123-27.185
       l37.004-7.07l0.111,23.945c-2.418-0.443-6.247-1.021-9.822-1.021c-6.617,0-12,5.383-12,12c0,6.616,5.383,11.999,12,11.999
       c3.913,0,7.102-1.329,9.473-3.946C70.248,51.942,69.449,41.861,69.368,40.992z M21.043,63.043c-1.604,1.766-3.73,2.623-6.501,2.623
       c-4.411,0-8-3.588-8-8c0-4.41,3.589-7.999,8-7.999c3.668,0,7.773,0.694,9.909,1.11C24.497,53.531,24.202,59.564,21.043,63.043z
        M28.229,17.743l-0.018-3.924L65.215,6.75l0.018,3.924L28.229,17.743z M62.042,55.043c-1.604,1.766-3.729,2.623-6.501,2.623
       c-4.411,0-8-3.588-8-7.999s3.589-8,8-8c3.669,0,7.773,0.694,9.909,1.11C65.496,45.531,65.201,51.564,62.042,55.043z"
        />
      </g>
    </svg>
  )
}

export default Audiopalceholder
