import { getNotificationCount } from '@src/modules/notifications/data/NotificationsThunk'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/index'
// import { ReactComponent as BrowserIcon } from './../../assets/icons/sidebar/browser.svg'
import { ReactComponent as DashboardIcon } from './../../assets/icons/sidebar/dashboard.svg'
import { ReactComponent as DocumentsIcon } from './../../assets/icons/sidebar/documents.svg'
// import { ReactComponent as EnvelopeIcon } from './../../assets/icons/sidebar/envelope.svg'
import { ReactComponent as DailyIcon } from './../../assets/icons/sidebar/daily.svg'
// import { ReactComponent as SettingsIcon } from './../../assets/icons/sidebar/settings-icon.svg'
import SidebarSpaces from '@src/modules/spaces/components/SidebarSpaces/SidebarSpaces'
import { useTranslation } from 'react-i18next'
import WorkspacePicker from '../WorkspacePicker'
import { ReactComponent as NotificationIcon } from './../../assets/icons/sidebar/notification.svg'
import { disableDashboard } from '@src/modules/dashboard/features/Dashbaord'

function SidebarItems() {
  const { t } = useTranslation(['sidebar'])

  const { notificationCount, newNotifications, clearedNotification } = useAppSelector(
    (state) => state.notification,
  )

  const [newNotif, setNewNotif] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getNotificationCount())
  }, [dispatch, newNotifications?.length, clearedNotification, notificationCount?.important])

  useEffect(() => {
    setNewNotif(true)
  }, [notificationCount?.important])

  return (
    <div className="sidebar-items">
      <div className="main-items">
        <NavLink
          to="/dashboard"
          className={({ isActive }: { isActive: boolean }) =>
            `${disableDashboard ? 'item-disabled' : ''} ${isActive ? 'item active' : ' item'}`
          }
        >
          <DashboardIcon /> <p>{t('dashboard')}</p>
        </NavLink>

        <NavLink to="/notifications" className="item  ">
          <div className="notif_icon">
            {notificationCount?.important > 0 && (
              <span className={`notification_number ${newNotif ? 'new_notif' : ''}`}>
                {notificationCount?.important < 10 ? notificationCount?.important : '+9'}
              </span>
            )}
            <NotificationIcon />
          </div>
          <p>{t('notifications')}</p>
        </NavLink>

        <NavLink to="/daily-questions" className="item">
          <DailyIcon /> <p>{t('daily_questions')}</p>
        </NavLink>

        <NavLink to="/documents" className="item">
          <DocumentsIcon /> <p>{t('documents')}</p>
        </NavLink>
      </div>
      <SidebarSpaces />

      <WorkspacePicker />
    </div>
  )
}

export default SidebarItems
