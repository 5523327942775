import React, { useState, useEffect, useRef } from 'react'
import { Tooltip, TooltipProps } from 'antd'
import { useRefDimensions } from '../../hooks/useRefDimensions'

interface OverflowTooltipProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  className?: string
  as?: React.ElementType | string
}

const OverflowTooltip = (props: OverflowTooltipProps & TooltipProps) => {
  const { children, className, as: Component = 'p', style, arrow, placement, ...rest } = props

  const [isOverflowing, setIsOverflowing] = useState<boolean>(true)
  const textRef = useRef(null)

  const { width } = useRefDimensions(textRef)

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current
      if (element) setIsOverflowing(element.scrollWidth > element.clientWidth)
    }

    checkOverflow()
  }, [width])

  return (
    <Tooltip
      title={isOverflowing ? children : null}
      arrow={arrow}
      placement={placement}
      mouseEnterDelay={0.6}
    >
      <Component
        className={className}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...style }}
        ref={textRef}
        {...rest}
      >
        {children}
      </Component>
    </Tooltip>
  )
}

export default OverflowTooltip
