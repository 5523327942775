const Loader = () => {
  return (
    <div className="worskapce-loading-container">
      <div className="loading"></div>
      <div className="loading-text">loading</div>
    </div>
  )
}

export default Loader
