import { Avatar, Modal, Spin } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
// import { ReactComponent as ErrorSVG } from '../../../assets/icons/answers/error.svg'
import { ReactComponent as CalendarSVG } from '../../../assets/icons/answers/calendar.svg'
import { ReactComponent as AngleSVG } from './../../../assets/icons/answers/angle.svg'
import { ReactComponent as TimeSVG } from './../../../assets/icons/answers/time.svg'
// import Watchers from '../../Watchers'
import {
  useGetRepliesQuery,
  usePostReplyMutation,
} from '@src/modules/dailyQuestions/services/memberAnswerApi'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'
import { useAppSelector } from '@src/modules/shared/store'
import Spinner from '@src/modules/spaces/components/Spinner'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Reply from '../../Reply'

export const reformulateDate = (originalDateString) => {
  const originalDate = new Date(originalDateString)

  const formattedDateResult = originalDate?.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  return formattedDateResult
}

const AnswerDetailsModal = ({ open, handleClose, data, id }) => {
  const { t } = useTranslation(['modals'])
  const { user } = useAppSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const {
    data: replies,
    isLoading,
    isFetching,
  } = useGetRepliesQuery({
    answerId: data?.id,
    limit: 3,
    skip: currentPage,
  })

  const [postReply, { isLoading: replyLoading }] = usePostReplyMutation()
  const [newReply, setReply] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  let { payload, metadata } = replies || {}
  payload = payload?.filter((reply) => reply?.answerId === data?.id)

  const sendReply = () => {
    postReply({ content: newReply, answerId: data?.id })
      .unwrap()
      .then(() => {
        setReply('')
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  useEffect(() => {
    // resetRepliesDraft()
  }, [open])

  return (
    <Modal
      footer={false}
      centered
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => {
        handleClose(id)
      }}
      wrapClassName="custom-modal space-modal answer-modal task-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{data?.question?.title}</p>
        <div>
          <p className="answer-date">
            {t('AnswerDetailsModal.Posted on')} {reformulateDate(data?.date)}
          </p>
          {/* <Watchers number={4} /> */}
          <CloseIcon
            onClick={() => {
              handleClose(id)
            }}
            className="close-icon"
          />
        </div>
      </div>

      <div className="answer-modal-body hovered-scrollbar">
        <div className="answer-content">
          <div className="answer-header">
            <Avatar src={data?.owner?.avatar}>
              {data?.owner?.fullName && data?.owner?.fullName[0]?.toUpperCase()}
            </Avatar>

            <p className="user-name">
              {data?.owner?.fullName}{' '}
              <span>{t('AnswerDetailsModal.answered this question on')}</span>
            </p>

            <div className="answer-date">
              <p>
                <CalendarSVG /> {reformulateDate(data?.date)}
              </p>
            </div>

            {/* <div className="answer-latency">
              <ErrorSVG />
              15 hours late
            </div> */}
          </div>

          <div className="answer-items">
            {data?.body?.map((action) => {
              return (
                <div className="answer-item">
                  <div className="answer-item-content ">
                    <BlockNoteRichText onlyView value={action?.content} setValue={() => {}} />
                  </div>
                  <div className="answer-duration">
                    <p>
                      <TimeSVG /> {action?.time}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="answer-replies">
          {metadata?.total_items > payload?.length && data?.nbreplies > 0 ? (
            <div
              className="load-more-comments"
              onClick={() => !isFetching && setCurrentPage((prev) => prev + 1)}
            >
              {isFetching ? <Spin size={'small'} /> : <AngleSVG />}{' '}
              {t('AnswerDetailsModal.3 more comments')}
            </div>
          ) : null}
          {isLoading ? (
            <Spinner />
          ) : (
            payload?.map((reply) => {
              return <Reply reply={reply} users={data?.teamUsers} key={reply?.id} />
            })
          )}
        </div>

        <div className="answer-modal-footer">
          <div className="current-user">
            <Avatar src={user?.user?.avatar}>
              {user?.user?.fullName && user?.user?.fullName[0].toUpperCase()}
            </Avatar>
          </div>

          <div className="reply-input">
            <div className="block-note hovered-scrollbar">
              <BlockNoteRichText
                value={newReply}
                setValue={(value: string) => setReply(value)}
                onUplaod={() => setUploading(false)}
                onDrop={() => setUploading(true)}
                onPaste={() => setUploading(true)}
                insert
              />
            </div>

            <div className="reply-input-controll-panel">
              {!uploading ? (
                <div className="submit-button" onClick={sendReply}>
                  {replyLoading ? t('AnswerDetailsModal.Loading...') : t('AnswerDetailsModal.Send')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AnswerDetailsModal
