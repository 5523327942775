import { store, useAppDispatch } from '@src/modules/shared/store/index'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { FileType } from '@src/modules/spaces/data/fileSlice/fileSlice'
import { setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { items, PermissionesAction } from '@src/modules/spaces/utils/spaceActions'
import { editDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { Dropdown, message, Tooltip } from 'antd'
import { useState } from 'react'
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg'
import { ReactComponent as SettingsIcon } from './../../../../spaces/assets/icons/list/settings.svg'
import { ReactComponent as LockIcon } from './../../../../spaces/assets/icons/space/lock.svg'
import RTLStyleDecorator from '../../RTLStyleDecorator'
import Input from '@src/modules/shared/components/Input/Input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
export const documentActions = {
  onrename: () => {},

  onshare: (data: DocumentType & { destination: string }) => {
    store.dispatch(openModal({ id: 'share-modal', data: { ...data, type: 'documentId' } }))
  },

  ondelete: (data: DocumentType & { destination: string }) => {
    store.dispatch(
      openModal({
        id: 'confirm-modal',
        data: { source: 'document', ...data, group: data.destination },
      }),
    )
  },
}

export interface DocumentType extends FileType {
  id: string
  spaceId: string
  refetch?: () => void
  status: statusType[]
}

const DocumentRow = ({ isSelected, data, readOnly, select }) => {
  const dispatch = useAppDispatch()
  const [isRename, setRename] = useState<boolean>(false)
  const actions = { ...documentActions, onrename: () => setRename(!isRename) }
  const { t } = useTranslation(['document'])
  const formik = useFormik({
    initialValues: {
      name: data.name || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('name_is_required')),
    }),
    onSubmit: (values) => {
      if (values.name !== data.name) {
        dispatch(editDocument({ documentId: data.id || '', name: values.name }))
          .unwrap()
          .then(() =>
            dispatch(
              setSpacesSocketEvent({
                updateType: 'updatedItem',
                updateEvent: { ...data, name: values.name },
              }),
            ),
          )
          .catch((err) => {
            message.error(err.message || t('error_while_updating_document_name'))
          })
          .finally(() => setRename(false))
      } else {
        setRename(false)
      }
    },
  })
  const location = useLocation()
  const pathName = ['/documents', '/dashboard', '/notifications']
  const isNotSelected = pathName?.includes(location?.pathname)
  return (
    <RTLStyleDecorator>
      <div className={`document_row ${isSelected && !isNotSelected ? 'active_document_row' : ''}`}>
        <div className="left_document_row" onClick={select}>
          <div className="icon">
            <DocumentIcon />
          </div>

          <div className="document_name">
            {!isRename ? (
              <Tooltip title={data.name}>
                {data.name.slice(0, 22)}
                {data.name.length > 22 ? <>...</> : ' '}
              </Tooltip>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Input
                  type="text"
                  name="name"
                  disabled={false}
                  variant="label-inline"
                  onClick={(e) => e.stopPropagation()}
                  onBlur={() => formik.handleSubmit()}
                  className="document-input"
                  size="xxs"
                  formik={formik}
                  defaultValue={data?.name}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      formik.handleSubmit()
                    } else if (e.key === 'Escape') {
                      setRename(false)
                    }
                  }}
                />
              </form>
            )}
          </div>
        </div>

        {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}

        {!readOnly && (
          <Dropdown
            menu={{
              items: items(
                {
                  ...data,
                  resource: 'document',
                  parent: data?.space,
                },

                [
                  PermissionesAction.NO_CREATE,
                  PermissionesAction.NO_OUT_FROM_SPACE,
                  PermissionesAction.NO_CREATE_FOLDER,
                  PermissionesAction.NO_CREATE_LIST,
                  PermissionesAction.NO_CREATE_DOCUMENT,
                ],

                actions,
              ),
            }}
            trigger={['click']}
          >
            <div className="icon_settings">
              <SettingsIcon />
            </div>
          </Dropdown>
        )}
      </div>
    </RTLStyleDecorator>
  )
}

export default DocumentRow
