import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { message, Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import bottom from '../assets/icons/bottom.svg'
import historyIcon from '../assets/icons/pHistory.svg'
import top from '../assets/icons/top.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
type restorType = {
  data: { message: string; payload?: any }
  error: any
}
const DesHistoryView = ({ elem, taskId, setDescription, setIsHistoryOpen }) => {
  const { t } = useTranslation(['taskDetails'])
  const [updateTask] = useUpdateTaskMutation()
  const [isOpen, setIsOpen] = useState(false)
  const [isRestored, setIsRestored] = useState(false)

  const desc = elem?.activity && JSON.parse(elem?.activity?.newValue?.description)

  const fisrtLineContent = desc
    ?.filter((item) => {
      if (item?.type === 'p') {
        return item?.children?.length > 0
      }

      if (item?.type === 'h1') {
        return item?.children?.length > 0
      }

      if (item?.listStyleType === 'bulletListItem') {
        return item?.content?.length > 0
      }

      if (item?.type === 'numberedListItem') {
        return item?.content?.length > 0
      }

      if (item?.type === 'img') {
        return item
      }
    })
    .shift()

  const renderParagraph = (
    text: string,
    styles: {
      backgroundColor: string
      bold: boolean
      italic: boolean
      strike: boolean
      textColor: string
      underline: boolean
    },
  ) => {
    const { backgroundColor, bold, italic, strike, textColor, underline } = styles || {}
    const style = {
      color: textColor,
      backgroundColor,
      fontStyle: italic ? 'italic' : '',
      fontWeight: bold ? 'bold' : '',
      textDecoration: strike ? 'strike' : underline ? 'underline' : '',
    }

    return <p style={style}>{text}</p>
  }

  const renderHeading = (
    level: number,
    text: string,
    styles: {
      textColor: string
      backgroundColor: string
      underline: string
      italic: boolean
      strike: boolean
    },
  ) => {
    const { backgroundColor, textColor, underline, italic, strike } = styles || {}
    const style = {
      color: textColor,
      backgroundColor,
      fontStyle: italic ? 'italic' : '',
      textDecoration: strike ? 'line-through' : underline ? 'underline' : '',
    }

    switch (level) {
      case 1:
        return <h1 style={style}>{text}</h1>
      case 2:
        return <h2 style={style}>{text}</h2>
      case 3:
        return <h3 style={style}>{text}</h3>
    }
  }

  const renderBulletListItem = (
    text: string,
    styles: { textColor: string; backgroundColor: string },
  ) => {
    const { backgroundColor, textColor } = styles || {}
    const style = { backgroundColor, color: textColor }

    return <li style={style}>{text}</li>
  }
  return (
    <div className="description-view">
      {isOpen && (
        <div className="content">
          <BlockNoteRichText value={elem?.activity.newValue?.description} onlyView={true} />
        </div>
      )}

      {isOpen ||
        (fisrtLineContent?.type === 'img' ? (
          <div className="content">
            <img src={fisrtLineContent?.url} />
          </div>
        ) : fisrtLineContent?.type === 'p' ? (
          <div className="content">
            {renderParagraph(
              fisrtLineContent?.children[0]?.text,
              fisrtLineContent?.children[0]?.styles,
            )}
          </div>
        ) : fisrtLineContent?.type === 'h1' ? (
          <div className="content">
            {renderHeading(
              2,
              fisrtLineContent?.children[0]?.text,
              fisrtLineContent?.children[0]?.styles,
            )}
          </div>
        ) : fisrtLineContent?.type === 'bulletListItem' ? (
          <div className="content">
            <ul>
              {renderBulletListItem(
                fisrtLineContent?.content[0]?.text,
                fisrtLineContent?.content[0]?.styles,
              )}
            </ul>
          </div>
        ) : fisrtLineContent?.type === 'numberedListItem' ? (
          <div className="content">
            <ol>
              {renderBulletListItem(
                fisrtLineContent?.content[0]?.text,
                fisrtLineContent?.content[0]?.styles,
              )}
            </ol>
          </div>
        ) : (
          <div className="content">
            <span></span>
          </div>
        ))}

      <RTLStyleDecorator>
        <div className="h-icons">
          <img src={isOpen ? top : bottom} onClick={() => setIsOpen(!isOpen)} />

          {isRestored ? (
            <span>{t('Restored!')}</span>
          ) : (
            <Tooltip title={t('Restore description')}>
              <img
                src={historyIcon}
                onClick={() => {
                  setIsRestored(true)
                  updateTask({
                    id: taskId,
                    description: elem?.activity.newValue?.description,
                  }).then((res: restorType) => {
                    if (res.data?.message) {
                      message.success(t('data_restored_successfully'))
                    } else if (res.error) {
                      message.error(t('something_wrong_in_restored_data'))
                    }
                  })
                  setDescription(elem?.activity.newValue?.description)
                  setIsHistoryOpen(false)
                }}
              />
            </Tooltip>
          )}
        </div>
      </RTLStyleDecorator>
    </div>
  )
}

export default DesHistoryView
