/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export const getTeams = createAsyncThunk('settings/getTeams', async () => {
  try {
    const response = await axiosInstance.get(`/api/teams`)
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getMyTeams = createAsyncThunk(
  'settings/getMyTeams',
  async ({ userId }: { userId: string }) => {
    try {
      const response = await axiosInstance.get(`/api/teams`, { params: { userId } })
      if (response.status >= 200 && response.status < 300) {
        return response.data
      } else {
        throw new Error(response.statusText || 'Failed to fetch teams')
      }
    } catch (err: any) {
      return Promise.reject(err.response?.data?.message || err.message || 'Unknown error')
    }
  },
)

export const createTeam = createAsyncThunk('settings/createTeam', async (query: any) => {
  try {
    const response = await axiosInstance.post(`/api/teams`, query)
    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
export const getTeamById = createAsyncThunk('settings/getTeamById', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/teams/${query?.id}`)
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
export const deleteTeam = createAsyncThunk('settings/deleteTeam', async (query: any) => {
  try {
    const response = await axiosInstance.delete(`/api/teams/${query?.id}`)
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
export const updateTeam = createAsyncThunk(
  'settings/updateTeam',
  async (payload: { id: string; query: any }, thunkAPI) => {
    const { id, query } = payload

    try {
      const response = await axiosInstance.put(`/api/teams/${id}`, query)
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const deleteTeamMember = createAsyncThunk(
  'settings/deleteTeamMember',
  async (payload: { usersIds: any; teamId: string }) => {
    const { usersIds } = payload

    try {
      const response = await axiosInstance.delete(`/api/teamuser`, {
        data: {
          usersIds,
          teamId: payload?.teamId,
        },
      })
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
export const convertTeamMember = createAsyncThunk(
  'settings/ConvertTeamMember',
  async (payload: { id: string; isManager: boolean; teamId: string }) => {
    const { id, isManager, teamId } = payload
    try {
      const response = await axiosInstance.put(`/api/teamuser/${id}`, {
        manager: isManager,
        teamId,
      })
      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
export const addTeamMember = createAsyncThunk('settings/addTeamMember', async (query: any) => {
  try {
    const response = await axiosInstance.post(`/api/teamuser`, query)
    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
