import React, { useEffect, useState } from 'react'
import { ICurrentQuestion } from '../../memberView.interface'
import { MenuProps, Dropdown, Space, Tooltip } from 'antd'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import CollapIcon from '@src/modules/dailyQuestions/assets/icons/member/CollapIcon'
import { isArabic } from '@src/modules/shared/utils/isArabic'
import { useSearchParams } from 'react-router-dom'
interface QuestionListProp {
  managerQuestions: any
  setCurrentQuestion: React.Dispatch<React.SetStateAction<ICurrentQuestion>>
  currentQuestion: ICurrentQuestion
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

function QuestionsList({
  currentQuestion,
  managerQuestions,
  setCurrentQuestion,
  setSuccess,
}: QuestionListProp) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(managerQuestions?.[0])
  useEffect(() => {
    searchParams.set('question', currentTab?.id)
    setSearchParams(searchParams)
    setCurrentQuestion(currentTab)
  }, [currentTab])
  const items: MenuProps['items'] = managerQuestions?.slice(3).map((el, index) => {
    return {
      label: (
        <p
          className="question-list__item"
          style={{ color: el?.id === currentQuestion?.id && '#9e72af' }}
        >
          {index} - {el?.title}
        </p>
      ),
      key: '0',
      onClick: () => {
        setCurrentQuestion(el)
        setCurrentTab(el)
        setSuccess(false)
      },
    }
  })

  return (
    <div className="question-list hovered-scrollbar">
      {managerQuestions?.slice(0, 3)?.map((el, index) => {
        return (
          <div
            key={index}
            className="question-item"
            onClick={() => {
              setCurrentQuestion(el)
              setSuccess(false)
            }}
            style={
              currentQuestion && managerQuestions[index - 1]?.id === currentQuestion?.id
                ? { marginLeft: '-15px' }
                : currentQuestion && managerQuestions[index + 1]?.id === currentQuestion?.id
                  ? { marginRight: '-15px' }
                  : {}
            }
          >
            <Tooltip title={el?.title} placement="topLeft">
              <RTLStyleDecorator>
                <div
                  className={`
                    ${
                      currentQuestion && el?.id === currentQuestion?.id
                        ? 'question-item-button-selected'
                        : 'question-item-button'
                    }
                    ${
                      currentQuestion && el?.id === currentQuestion?.id
                        ? index === 0
                          ? 'first-item'
                          : 'question-item-button-selected'
                        : index === managerQuestions?.length - 1
                          ? 'last-item'
                          : 'no-radius'
                    }
                  `}
                  onClick={() => setCurrentTab(el)}
                >
                  <p
                    className="question-item-name"
                    style={isArabic(el?.title) ? { direction: 'rtl' } : {}}
                  >
                    {el?.title}
                  </p>
                </div>
              </RTLStyleDecorator>
            </Tooltip>
          </div>
        )
      })}
      {managerQuestions?.length > 3 && (
        <div className="question-collapsed">
          <Dropdown menu={{ items }} trigger={['click']} className="question-collapsed-container">
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <CollapIcon />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}
    </div>
  )
}

export default React.memo(QuestionsList)
