import { useRef } from 'react'
import { ReactComponent as CloseIcon } from './../../../settings/assets/icons/invite/close-icon.svg'
import { v4 as uuidv4 } from 'uuid'
import AutosizeInput from 'react-18-input-autosize'
import { useTranslation } from 'react-i18next'

type Email = { id: string; email: string }

type FormikProps = {
  values: {
    emails: Email[]
    role: string
  }
  setValues: (values: { emails: Email[]; role: string }) => void
  errors: any
}

function MultipleSelection({
  formik,
  emailInput,
  setEmailInput,
  children,
}: {
  formik: FormikProps
  emailInput: string
  setEmailInput: (value: string) => void
  children: React.ReactNode
}) {
  const { t } = useTranslation(['modals'])

  const validateEmail = (email: string) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z]{3,}\.[a-zA-Z]+$/.test(email.toLowerCase())

  const handleAddEmail = () => {
    const trimmedEmail = emailInput?.trim()?.toLowerCase()
    if (trimmedEmail) {
      setEmailInput('')
      const newEmail = { id: uuidv4(), email: trimmedEmail }
      formik.setValues({
        ...formik.values,
        emails: [...formik.values.emails, newEmail],
      })
    }
  }

  const handleDeleteTag = (id: string) => {
    formik.setValues({
      ...formik.values,
      emails: formik.values.emails.filter((email) => email.id !== id),
    })
  }

  const handleChangeEmailValue = (value: string, index: number) => {
    const updatedEmails = [...formik.values.emails]
    updatedEmails[index].email = value
    formik.setValues({ ...formik.values, emails: updatedEmails })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleAddEmail()
      e.preventDefault()
    } else if ((e.key === 'Delete' || e.key === 'Backspace') && emailInput === '') {
      const lastEmailIndex = formik.values.emails.length - 1
      if (lastEmailIndex >= 0) {
        const updatedEmails = [...formik.values.emails]
        updatedEmails.splice(lastEmailIndex, 1)
        formik.setValues({ ...formik.values, emails: updatedEmails })
      }
    }
  }

  const renderEmail = (index: number, email: Email) => (
    <div
      key={index}
      className={`email-tag ${!validateEmail(email.email) ? 'email-tag-error' : ''}`}
    >
      <AutosizeInput
        type="text"
        value={email.email}
        className="email-tag-input"
        onChange={(e) => handleChangeEmailValue(e.target.value, index)}
      />
      <div className="email-tag-close" onClick={() => handleDeleteTag(email.id)}>
        <CloseIcon />
      </div>
    </div>
  )
  const emailInputRef = useRef(null)
  const handleContainerClick = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    }
  }
  return (
    <>
      <div className="invite-form-input-container" style={{ width: '100%' }}>
        <div className="invite-form-input-container-tags-container">
          <div
            className="invite-form-input-container-tags-container-tags"
            onClick={handleContainerClick}
            style={{ width: '100%' }}
          >
            {formik.values.emails.map((email, index) => renderEmail(index, email))}
            <AutosizeInput
              onClick={(e) => e.stopPropagation()}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
              className="invite-form-input-container-input"
              style={{ width: '100%' }}
              placeholder={formik.values.emails.length === 0 ? t('invite_people.email') : ''}
            />
          </div>
        </div>
        {children}
      </div>
      {formik.errors && formik.errors.emails ? (
        <p className="error-message">{t('invite_people.email_error')}</p>
      ) : null}
    </>
  )
}

export default MultipleSelection
