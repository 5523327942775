import { FC } from 'react'
import { ReactComponent as EmptySVG } from '../../assets/icons/card/empty.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Skeleton, Tooltip } from 'antd'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'

interface CardProps {
  title: string
  seeAll?: boolean
  onClickSeeAll?: () => void
  documents: any[]
  loading?: boolean
  onBottom?: any
  total_items?: number
  currentPage?: number
  limit?: number
}

const skeleton = (
  <>
    <Skeleton active paragraph={false} />
    <Skeleton active paragraph={false} />
    <Skeleton active paragraph={false} />
    <Skeleton active paragraph={false} />
  </>
)

const Card: FC<CardProps> = (props) => {
  const { t } = useTranslation(['documents'])
  const {
    title,
    seeAll,
    onClickSeeAll,
    documents,
    loading = false,
    onBottom,
    total_items,
    currentPage,
    limit,
  } = props
  const isEmpty = !documents || (documents && !documents.length)
  const navigate = useNavigate()

  const infiniteScrollHandler = (e) => handleScroll(e, total_items, currentPage, limit, onBottom)

  return (
    <div className="documents-card">
      <div className="documents-card-title">
        <p>{title}</p>
        {seeAll && <div onClick={onClickSeeAll && onClickSeeAll}>{t('see_all')}</div>}
      </div>
      {loading ? (
        <div className="documents-card-loading">{skeleton}</div>
      ) : isEmpty ? (
        <div className="documents-card-empty">
          <EmptySVG />
          <p>
            {t('no_docs')} {title}
          </p>
        </div>
      ) : (
        <div
          className="documents-card-documents hovered-scrollbar"
          onScroll={infiniteScrollHandler}
        >
          {documents.map((document) => {
            return (
              <div
                className="document-item"
                key={document.id}
                onClick={() => navigate(`/spaces/document/${document.id}`)}
              >
                <div className="document-item-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M3.88877 13.2812H10.1113C11.3243 13.2812 11.9278 12.666 11.9278 11.4473V6.127C11.9278 5.371 11.8458 5.043 11.377 4.5625L8.14252 1.2755C7.69752 0.81825 7.33402 0.71875 6.67202 0.71875H3.88877C2.68177 0.71875 2.07227 1.33975 2.07227 2.55875V11.4473C2.07227 12.6718 2.68177 13.2812 3.88877 13.2812ZM3.93552 12.338C3.33202 12.338 3.01552 12.0155 3.01552 11.4298V2.57625C3.01552 1.99625 3.33202 1.662 3.94152 1.662H6.54302V5.0665C6.54302 5.80475 6.91802 6.168 7.64452 6.168H10.9845V11.4298C10.9845 12.0155 10.6738 12.338 10.0645 12.338H3.93552ZM7.75002 5.283C7.52152 5.283 7.42752 5.1895 7.42752 4.955V1.84375L10.8025 5.28325L7.75002 5.283ZM9.15627 7.77925H4.70877C4.49802 7.77925 4.34577 7.9375 4.34577 8.13675C4.34577 8.34175 4.49827 8.5 4.70902 8.5H9.15627C9.20418 8.50078 9.25177 8.49191 9.29619 8.47393C9.34061 8.45595 9.38097 8.42922 9.41485 8.39534C9.44874 8.36145 9.47547 8.3211 9.49345 8.27667C9.51143 8.23225 9.52029 8.18467 9.51952 8.13675C9.51952 7.9375 9.36127 7.77925 9.15627 7.77925ZM9.15627 9.82425H4.70877C4.49802 9.82425 4.34577 9.98825 4.34577 10.1932C4.34577 10.3925 4.49827 10.545 4.70902 10.545H9.15627C9.36127 10.545 9.51952 10.3925 9.51952 10.1932C9.51952 9.98825 9.36127 9.82425 9.15627 9.82425Z"
                      fill="#374957"
                    />
                  </svg>
                </div>
                <div className="document-item-name">{document.name}</div>
                <Tooltip title={document?.space?.name || document?.folder?.name}>
                  <div className="document-item-location">
                    {t('in')} {document?.space?.name || document?.folder?.name}
                  </div>
                </Tooltip>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Card
