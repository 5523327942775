import { AbilityBuilder, Ability } from '@casl/ability'

export const GetPermissions = (roles: any[]) => {
  if (!roles) {
    console.log(
      '%cRoles are not provided in the user payload, and user permissions will not work <Casl>',
      'color: white; background-color: rgb(255 0 76); padding: 10px; border-radius: 5px; font-weight:600; ;font-size: 15px;',
    )
  } else
    console.log(
      '%cCasl work',
      'color: black; background-color: rgb(127 253 0); padding: 10px; border-radius: 5px; font-weight:600; ;font-size: 15px;',
    )
  const { can, build } = new AbilityBuilder(Ability)

  let listOfPermissions: { action: string; resource: string }[] = []
  roles?.forEach((role) => {
    role?.permissions?.forEach((permission: { resourceName: string; actions: string[] }) => {
      const resource = permission?.resourceName?.toLowerCase()
      permission?.actions?.forEach((action: string) => {
        listOfPermissions.push({ action: action.toLowerCase(), resource })
      })
    })
  })

  listOfPermissions.forEach((permission) => {
    can(permission?.action, permission?.resource)
  })

  return build()
}
