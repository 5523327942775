import { api } from '@src/modules/shared/services/api'

export interface ICategory {
  id: string
  name: string
  order: number
}

export const categoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<ICategory[], void>({
      query: () => ({
        url: `api/category`,
      }),

      transformResponse: (response: { payload: ICategory[] }) => response.payload,
    }),
  }),
})

export const { useGetCategoriesQuery } = categoryApi
