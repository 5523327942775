import { Skeleton } from 'antd'

function TaskDetailTitleSkeleton() {
  return (
    <div className="task-detail-skeleton-title">
      <div className="top-header">
        <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
        <div className="top-header-right">
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Avatar active size={'small'} />
        </div>
      </div>
    </div>
  )
}

export default TaskDetailTitleSkeleton
