import Button from '@src/modules/shared/components/Button/Button'

import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { createList } from '../../data/fileSlice/fileThunk'
import { message } from 'antd'
import { manageFiles, setSpacesSocketEvent } from '../../data/spaceSlice/spacesSlice'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'

const ListForm = ({ data, isEdit }: any) => {
  const navigate = useNavigate()
  const { status } = useAppSelector((state) => state.files)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals'])

  const initialValues = {
    name: isEdit ? data.name : '',
  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(3, t('space.Name must contains at least 3 caracters'))
        .required(t('list.name is Required')),
    }),

    onSubmit: (values) => {
      if (status === 'loading') return
      dispatch(
        createList({
          ...values,
          spaceId: data?.space?.id,
          folderId: data?.folder?.id.toString(),
        }),
      )
        .unwrap()
        .then((res) => {
          dispatch(
            manageFiles({
              id: data.space?.id || data.folder?.id,
              newObj: res.payload,
              type: 'add',
              parent: data.space ? 'space' : 'folder',
              targetData: 'lists',
            }),
          )
          dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }))
          if (res.payload?.id) navigate(`/spaces/list/${res.payload?.id}`)
          dispatch(closeModal({ id: 'list-modal' }))
        })
        .catch((err) => {
          message.error(err.message || t('list.Error while create a file'))
        })
    },
  })

  return (
    <div className="ta-form list-form">
      <form onSubmit={formik?.handleSubmit}>
        <Input
          type="text"
          name="name"
          variant="filled"
          status={formik.errors.name ? 'error' : 'success'}
          label={t('list.name')}
          placeholder={t('list.placeholder')}
          size="md"
          formik={formik}
        />

        <div className="form-actions flex-end">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              formik.setValues(initialValues)
              dispatch(closeModal({ id: 'list-modal' }))
            }}
          >
            {t('list.cancel')}
          </Button>

          <Button htmlType="submit" className="confirm-action">
            {status === 'loading' ? t('list.loading') : t('list.create')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ListForm
