/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  deleteImport,
  exportData,
  getImportedData,
  importClickUpData,
  importDataFromCsv,
  importDataProcess,
} from './ImportExportThunk'

const initialState = {
  status: 'idle',
  clickupStatus: 'idle',
  error: null,
  metadata: null,

  exportedData: null,
  importedData: null,
  importedDataInfos: [],
  importedDataProcess: { users: null, spaces: null },

  data: {},
  mappedData: {
    spaces: {},
    users: {},
  },
}

const importExportSlice = createSlice({
  name: 'importExport',
  initialState,
  reducers: {
    setSpacesMappedData: (state, action) => {
      state.mappedData.spaces = action.payload
    },
    setUsersMappedData: (state, action) => {
      state.mappedData.users = action.payload
    },
  },

  extraReducers: (builder) => {
    /** exportData */
    builder.addCase(exportData.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(exportData.fulfilled, (state, action: PayloadAction<any>) => {
      state.exportedData = action.payload.payload
      state.status = 'succeeded'
    })
    builder.addCase(exportData.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    /* importDataFromCsv */
    builder.addCase(importDataFromCsv.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(importDataFromCsv.fulfilled, (state, action: PayloadAction<any>) => {
      state.importedData = action?.payload
      state.status = 'succeeded'
    })
    builder.addCase(importDataFromCsv.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    /* importDataProcess */
    builder.addCase(importDataProcess.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(importDataProcess.fulfilled, (state, action: PayloadAction<any>) => {
      state.importedDataProcess = action?.payload
      state.status = 'succeeded'
    })
    builder.addCase(importDataProcess.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    /* getImportedData */
    builder.addCase(getImportedData.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getImportedData.fulfilled, (state, action: PayloadAction<any>) => {
      state.importedDataInfos = action?.payload?.payload
      state.status = 'succeeded'
    })
    builder.addCase(getImportedData.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    /* deleteImport */
    builder.addCase(deleteImport.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteImport.fulfilled, (state) => {
      state.status = 'succeeded'
    })
    builder.addCase(deleteImport.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addCase(importClickUpData.pending, (state) => {
      state.error = null
      state.clickupStatus = 'loading'
    })
    builder.addCase(importClickUpData.fulfilled, (state) => {
      state.clickupStatus = 'succeeded'
    })
    builder.addCase(importClickUpData.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.clickupStatus = 'failed'
    })
  },
})

export const { setSpacesMappedData, setUsersMappedData } = importExportSlice.actions // eslint-disable-line

export default importExportSlice.reducer
