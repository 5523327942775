/* eslint-disable no-unsafe-optional-chaining */
import { IDocument } from '@softylines/tam-types'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { api } from '@src/modules/shared/services/api'

interface getDucumentsParms {
  skip: number
  limit: number
  options?: string
}

export interface documentType {
  owner: memberType
  name: string
  id: string
  createdAt: string
  updatedAt: string
  folder: any
}

export interface getDocumentsPayload {
  payload: documentType[]
  metadata: any
}

const defaultLimit = 10

export const DucumentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocuments: build.query<getDocumentsPayload, getDucumentsParms>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/documentv2`,
          params,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },
    }),

    getMyDucuments: build.query<getDocumentsPayload, getDucumentsParms>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/documentv2`,
          params,
        }
      },
      transformResponse: (res: any) => {
        return res.payload
      },
    }),

    getResentViewedTasks: build.query<getDocumentsPayload, getDucumentsParms>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/recent-doc-access`,
          params,
        }
      },
    }),

    getPublicDocument: build.query<IDocument, string>({
      query: (id) => {
        return {
          url: `api/public/${id}`,
        }
      },
      transformResponse: (res: any) => {
        return res?.payload
      },
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useGetMyDucumentsQuery,
  useGetResentViewedTasksQuery,
  useGetPublicDocumentQuery,
} = DucumentsApi
