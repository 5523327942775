import { useSearchParams } from 'react-router-dom'
import GuestGuard from './GuestGuard'

interface MainLayoutProps {
  children: React.ReactNode
}

const OAuthGuard = ({ children }: MainLayoutProps) => {
  const [searchParams] = useSearchParams()

  const code: string | null = searchParams.get('code')
  //   const token: string | null = searchParams.get('token')
  if (code) {
    return children
  }

  return <GuestGuard>{children}</GuestGuard>
}

export default OAuthGuard
