function PDFPlaceholder() {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 0C10.969 0 0 10.969 0 24.5S10.969 49 24.5 49 49 38.031 49 24.5 38.031 0 24.5 0zm0 47C11.85 47 2 37.15 2 24.5S11.85 2 24.5 2 47 11.85 47 24.5 37.15 47 24.5 47z"
        fill="#EAEFFD"
      />
      <path
        d="M33.5 14H15c-.553 0-1 .448-1 1v19c0 .552.447 1 1 1h18.5c.553 0 1-.448 1-1V15c0-.552-.447-1-1-1zm-1 19H16V16h16.5v17z"
        fill="#EAEFFD"
      />
      <path
        d="M23.25 19h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zM27.75 22h-9c-.414 0-.75.336-.75.75s.336.75.75.75h9c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zM27.75 25h-9c-.414 0-.75.336-.75.75s.336.75.75.75h9c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zM27.75 28h-9c-.414 0-.75.336-.75.75s.336.75.75.75h9c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zM27.75 31h-9c-.414 0-.75.336-.75.75s.336.75.75.75h9c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"
        fill="#EAEFFD"
      />
    </svg>
  )
}

export default PDFPlaceholder
