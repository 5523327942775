export default function AnswerAttachement() {
  return (
    <div
      style={{
        height: '118px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: '8px',
        background: '#F2F7FA',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="53"
        viewBox="0 0 41 53"
        fill="none"
      >
        <path
          d="M26.5441 1.01759H5.5214C3.99288 1.01759 2.75391 2.2428 2.75391 3.75423V49.7282C2.75391 51.2396 3.99288 52.4648 5.5214 52.4648H37.4877C39.0162 52.4648 40.2552 51.2396 40.2552 49.7282V14.576L26.5441 1.01759Z"
          fill="#F7FAFA"
        />
        <path
          d="M26.5439 1.01759L36.1145 14.576V49.7282C36.1145 51.2396 34.8754 52.4648 33.347 52.4648H37.4876C39.016 52.4648 40.2551 51.2396 40.2551 49.7282V14.576L26.5439 1.01759Z"
          fill="#F2F5F5"
        />
        <path
          d="M26.5439 1.0178V11.0772C26.5439 13.0097 28.1281 14.5762 30.0823 14.5762H40.2551L26.5439 1.0178Z"
          fill="#E8EBEB"
        />
        <path
          d="M31.5894 46.498H2.81445C1.67112 46.498 0.744141 45.5712 0.744141 44.4277V31.8424C0.744141 30.699 1.67112 29.7721 2.81445 29.7721H31.5894C32.7328 29.7721 33.6597 30.699 33.6597 31.8424V44.4277C33.6597 45.5712 32.7328 46.498 31.5894 46.498Z"
          fill="#F3E2F8"
        />
        <path
          d="M31.5898 29.7721H27.4492C28.5927 29.7721 29.5195 30.699 29.5195 31.8424V44.4277C29.5195 45.5712 28.5927 46.498 27.4492 46.498H31.5898C32.7333 46.498 33.6602 45.5712 33.6602 44.4277V31.8424C33.6602 30.699 32.7333 29.7721 31.5898 29.7721Z"
          fill="#EED2F7"
        />
        <path
          d="M2.75391 11.8856V29.7734"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.75391 46.4988V49.7283C2.75391 51.2396 3.99299 52.4648 5.5214 52.4648H37.4877C39.0162 52.4648 40.2552 51.2396 40.2552 49.7283V14.576L26.5441 1.01759H5.5214C3.99299 1.01759 2.75391 2.2428 2.75391 3.75423V7.79682"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.5439 1.0178V11.0772C26.5439 13.0097 28.1281 14.5762 30.0823 14.5762H40.2551L26.5439 1.0178Z"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.2263 46.498H2.81445C1.67112 46.498 0.744141 45.5712 0.744141 44.4277V31.8424C0.744141 30.699 1.67112 29.7721 2.81445 29.7721H31.5894C32.7328 29.7721 33.6597 30.699 33.6597 31.8424V44.4277C33.6597 45.5712 32.7328 46.498 31.5894 46.498H28.5113"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.6569 34.2298H24.4902V42.043"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.4902 38.0625H27.4053"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2227 34.3242V41.8301"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4703 38.1361C20.4703 40.2405 19.3023 41.9058 17.4492 41.9382C16.8323 41.949 15.2382 41.9551 15.2382 41.9551C15.2382 41.9551 15.2281 39.1885 15.2281 38.1277C15.2281 37.2564 15.2227 34.3171 15.2227 34.3171H17.3859C19.4184 34.3171 20.4703 36.0316 20.4703 38.1361Z"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.74707 34.1996V42.0703"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.2032 36.4215C11.2032 37.6481 10.1653 38.6426 8.9386 38.6426C8.33024 38.6426 6.76229 38.6523 6.76229 38.6523C6.76229 38.6523 6.75245 37.0398 6.75245 36.4215C6.75245 35.9136 6.74707 34.2003 6.74707 34.2003H8.9386C10.1653 34.2003 11.2032 35.1947 11.2032 36.4215Z"
          stroke="#060606"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}
