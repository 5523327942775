export const handleNotificationType = (
  t: any,
  type: string,
  activity: IActivity,
  comment?: IComment,
  event?: string,
) => {
  if (event === 'click-event') {
    return t('Click up import')
  } else if (type === 'activity') {
    if (activity?.action === 'create') return t('created_task')

    if (activity?.action === 'update') {
      switch (true) {
        case Object.keys(activity?.newValue).includes('name'):
          return t('set_name')
        case Object.keys(activity?.newValue).includes('description'):
          return t('set_description')
        case Object.keys(activity?.newValue).includes('priority'):
          return t('set_priority')
        case Object.keys(activity?.newValue).includes('users'):
          return t('assignment')
        case Object.keys(activity?.newValue).includes('dueDate'):
          return t('set_due_date')
        case Object.keys(activity?.newValue).includes('status'):
          return t('set_status')
        case Object.keys(activity?.newValue).includes('estimation'):
          return t('set_time_estimation')
        case Object.keys(activity?.newValue).includes('parentId'):
          return t('set_task_level')
        case Object.keys(activity?.newValue).includes('attachements'):
          return t('set_attachements')
        default:
          return t('other_type')
      }
    }

    if (activity?.action === 'startTimer') return 'Start Timer'

    if (activity?.action === 'closeTimer') return 'Close Timer'
  } else if (type === 'comment') {
    return comment?.preview && comment?.preview === 'mention' ? t('mention') : t('commented')
  }
}
