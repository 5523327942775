import { Modal, message } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/workspace/Group.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { useDeleteMutipleMemberMutation } from '@src/modules/settings/services/membersApi'
import { useTranslation } from 'react-i18next'

const DeleteMemberModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])
  const [deleteMember, { isLoading }] = useDeleteMutipleMemberMutation()

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-moda"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('DeleteMemberModal.Remove Member')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div className="delete-image-container">
        <DeleteIcon />
      </div>

      <p className="small-description">
        {t('DeleteMemberModal.You are about to delete')} “<span>{data?.fullName}</span>”,{' '}
        {t('DeleteMemberModal.including all its history')},{' '}
        {t('DeleteMemberModal.tasks and files.This action is permanent and cannot be undone.')}
      </p>

      <div className="buttons-container">
        <Button
          children={t('DeleteMemberModal.Cancel')}
          htmlType="button"
          onClick={() => handleClose(id)}
          disabled={false}
          className="cancel-button"
        />

        <Button
          children={isLoading ? t('DeleteMemberModal.Loading...') : t('DeleteMemberModal.Delete')}
          htmlType="submit"
          className="delete-button"
          disabled={false}
          onClick={() => {
            if (isLoading) return
            deleteMember({ userIds: [data?.id] })
              .unwrap()
              .then(() => handleClose(id))
              .catch((err) => {
                message.error(err?.message || t('DeleteMemberModal.error on delete member'))
              })
          }}
        />
      </div>
    </Modal>
  )
}

export default DeleteMemberModal
