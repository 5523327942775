import {
  exportData,
  getClickUpAuthToken,
  getImportedData,
} from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import ViewLayout from '@src/modules/shared/components/ViewLayout'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useAppDispatch } from '@src/modules/shared/store'
import { useAppSelector } from '@src/modules/shared/store/index'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import Spinner from '@src/modules/spaces/components/Spinner/Spinner'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Button from '../../../shared/components/Button/Button'
import Header from '../../components/Header'
import ExportModal from './components/ExportModal'
import ImportModalIsLoading from './components/ImportModalIsLoading'
import ImportedDataCard from './components/ImportedDataCard/index'
import InProgressImport from './components/InProgressImport/InProgressImport'
import SpaceMapping from './components/SpaceMapping/SpaceMapping'
import UserMapping from './components/UserMapping/UserMapping'
import ImportExportSkeleton from './components/ImportExportSkeleton/ImportExportSkeleton'

const ImportExpert = () => {
  const { t } = useTranslation(['importExport'])
  const headerProps = { title: t('import_export_data') }
  const dispatch = useAppDispatch()
  useTitle(t('Settings - Import Export'))

  const { importedDataInfos, clickupStatus, status } = useAppSelector((state) => state.importExport)
  const { importClickUpProgressValue } = useAppSelector((state) => state.notification)

  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false)
  const [isImportModalIsLoadingOpen, setIsImportModalIsLoadingOpen] = useState<boolean>(false)
  const [downloadLink, setDowloadLink] = useState<string>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const code: string = searchParams.get('code')
  const view: string = searchParams.get('view')
  const maxProgress = importClickUpProgressValue === 100
  // +++++++++++++++++ IMPORT MODAL +++++++++++++++++
  const showImportModal = () => {
    dispatch(openModal({ id: 'import-task-modal' }))
  }

  // +++++++++++++++++ IMPORT MODAL LOADING +++++++++++++++++
  // const showImportModalIsLoading = () => {
  //   setIsImportModalIsLoadingOpen(true)
  // }

  const handleIsLoadingCancel = () => {
    setIsImportModalIsLoadingOpen(false)
  }

  // +++++++++++++++++ EXPORT MODAL +++++++++++++++++
  const showExportModal = () => {
    setIsExportModalOpen(true)
    dispatch(exportData())
      .unwrap()
      .then((data) => {
        setDowloadLink(data?.location)
      })
      .catch((err) => message.error(err?.message || t('error_on_export_data')))
  }

  const handleExportOk = () => {
    const link = document.createElement('a')
    link.href = downloadLink
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleExportCancel = () => {
    setIsExportModalOpen(false)
    setDowloadLink(null)
  }

  useEffect(() => {
    dispatch(getImportedData())
  }, [dispatch, importedDataInfos?.length, maxProgress])

  useEffect(() => {
    if (code)
      dispatch(getClickUpAuthToken(code))
        .unwrap()
        .then((res: any) => {
          dispatch(openModal({ id: 'import-selected-spaces-modal', data: {} }))
          const { access_token } = res
          localStorage.setItem('clickup_access_token', access_token)
        })
        .catch((error) => message.error(error.message || t('something_went_wrong')))
        .finally(() => {
          searchParams.delete('code')
          setSearchParams(searchParams)
        })
  }, [code])

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <ImportModalIsLoading
        isModalOpen={isImportModalIsLoadingOpen}
        handleCancel={handleIsLoadingCancel}
      />

      <ExportModal
        downloadLink={downloadLink}
        isModalOpen={isExportModalOpen}
        handleOk={handleExportOk}
        handleCancel={handleExportCancel}
      />

      <div className="dashboard-members">
        <div className="dashboard-import-export">
          {view ? (
            <></>
          ) : (
            <div className="import-export-data-contrainer">
              <h1>{t('import_data')}</h1>

              <p>{t('import_description')}</p>

              {clickupStatus === 'loading' && <Spinner />}

              {importClickUpProgressValue && importClickUpProgressValue < 100 && (
                <InProgressImport importClickUpProgressValue={importClickUpProgressValue} />
              )}

              <div className="importedCards_container">
                {status === 'loading' ? (
                  <ImportExportSkeleton />
                ) : status === 'failed' ? (
                  <span>{t('Error')}</span>
                ) : importedDataInfos?.length > 0 ? (
                  importedDataInfos?.map((importedInfo, index) => {
                    return <ImportedDataCard importedInfo={importedInfo} key={index} />
                  })
                ) : (
                  <small>{t('no_imports')}</small>
                )}
              </div>

              <Can I="create" a="import">
                <Button onClick={showImportModal}>{t('import_btn')}</Button>
              </Can>
            </div>
          )}

          {view ? (
            <></>
          ) : (
            <div className="import-export-data-contrainer">
              <h1>{t('export_data')}</h1>

              <p>{t('export_description')}</p>

              <Can I="create" a="export">
                <Button onClick={showExportModal}>{t('export_btn')}</Button>
              </Can>
            </div>
          )}

          {view === 'inSpace' && <SpaceMapping />}

          {view === 'userMapping' && <UserMapping />}
        </div>
      </div>
    </ViewLayout>
  )
}

export default ImportExpert
