import styled from 'styled-components'
import { isArabic } from '../../utils/isArabic'
import { Tooltip } from 'antd'

interface ArabicSupportProps {
  tag: 'p' | 'div' | 'span'
  content: string
  className?: string
  onClick?: () => void
  tooltip?: boolean
}

const ArabicElement = ({ content, tooltip, tag, ...props }: ArabicSupportProps) => {
  const Arabic = styled[tag]`
    direction: ${isArabic(content) ? 'rtl' : 'ltr'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `

  return (
    <Tooltip title={tooltip ? content : null}>
      <Arabic {...props}>{content}</Arabic>
    </Tooltip>
  )
}

export default ArabicElement
