import TaskPriority from '../../TaskPriority/TaskPriority'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useEffect, useState } from 'react'

type Props = {
  taskId: string
  defaultValue: number
  task: TaskType
  showLabel?: boolean
}

function SetTaskPriority({ taskId, task, showLabel = true }: Props) {
  const [updateTask] = useUpdateTaskMutation()
  const [priority, setPriority] = useState<number>(task.priority)

  const onSelectPriority = (value) => {
    setPriority(value)
    updateTask({ id: taskId, priority: value })
  }

  useEffect(() => {
    setPriority(task.priority)
  }, [task])

  return (
    <TaskPriority onSelect={onSelectPriority} activePriorityCode={priority} showLabel={showLabel} />
  )
}

export default SetTaskPriority
