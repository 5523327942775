import { useTranslation } from 'react-i18next'
import estimationIcon from '../../../assets/icons/notification/estimation.svg'

const convertEstimationToTime = (estimation: number): { hours: number; minutes: number } => {
  const hours = Math.floor(estimation)
  const minutes = Math.round((estimation - hours) * 60)

  return { hours, minutes }
}

const SetEstimation = (estimation: { date: number }) => {
  const { hours, minutes } = convertEstimationToTime(estimation.date)
  const { t } = useTranslation(['notifications'])

  return (
    <div className="set_estimation">
      <img src={estimationIcon} />
      <p>
        <span>
          {hours} {hours > 1 ? t('Hours') : t('Hour')}
        </span>

        {minutes > 0 ? (
          <span>
            {minutes} {t('Minutes')}
          </span>
        ) : (
          <></>
        )}
      </p>
    </div>
  )
}

export default SetEstimation
