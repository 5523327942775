import { ReactComponent as TimerClock } from '../assets/icons/timer-clock.svg'

import { useState, useRef, useEffect } from 'react'
import { ReactComponent as ManualTimer } from '../assets/icons/manual-timer.svg'
import { ReactComponent as ShieldCross } from '../assets/icons/cancle-shield-cross.svg'
import { ReactComponent as SavePlay } from '../assets/icons/save-play.svg'
import { Avatar, Dropdown, Skeleton, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { ReactComponent as TimerTask } from '../assets/icons/timer-task.svg'
import { ReactComponent as EditIcon } from '../assets/icons/edit-icon.svg'

import { useSearchParams } from 'react-router-dom'
import { useGetTimerQuery } from '../../../services/timerApi'

import { formatTimerTime } from '../utils/formateTimerTime'
import { handleSave } from '../Timer'
import TimerContent from '../TimerContent'
import ManualContent from '../ManualContent'
import EditSession from '../EditSession'
import { useTranslation } from 'react-i18next'
import { useGetTaskQuery } from '@src/modules/tasks/services/tasksApi'

const TimerDropdownContent = ({ elapsedSeconds, isRunning, handelOpen, handelClose }) => {
  const { t } = useTranslation(['timer'])
  const [selectedTab, setSelectedTab] = useState('timer')
  const tabsRef = useRef<HTMLDivElement>(null)
  const timerTabRef = useRef<HTMLDivElement>(null)
  const manualTabRef = useRef<HTMLDivElement>(null)
  const [indicatorStyle, setIndicatorStyle] = useState({})
  const [page, setPage] = useState(1)
  const [searchparams, _] = useSearchParams()
  const dispatch = useAppDispatch()
  const taskId = searchparams.get('taskId')
  const { user } = useAppSelector((state) => state.auth.user)
  const { currentActiveTimer } = useAppSelector((state) => state.timer)

  useEffect(() => {
    const updateIndicator = () => {
      const activeTab = selectedTab === 'timer' ? timerTabRef.current : manualTabRef.current
      if (activeTab) {
        setIndicatorStyle({
          left: `${activeTab.offsetLeft}px`,
          width: `${activeTab.offsetWidth}px`,
        })
      }
    }
    updateIndicator()
    window.addEventListener('resize', updateIndicator)
    return () => window.removeEventListener('resize', updateIndicator)
  }, [selectedTab])
  const { data: task, isLoading, refetch } = useGetTimerQuery({ id: taskId, limit: 20, skip: page })
  const { data: taskDetails } = useGetTaskQuery({ id: taskId })
  const handelCloseTimer = async (e) => {
    await handelClose(e)
    refetch()
  }
  const handelOpenTimer = async (e) => {
    await handelOpen(e)
  }

  const MockTask =
    currentActiveTimer && currentActiveTimer.task?.id === taskId
      ? {
          id: currentActiveTimer.id,
          description: currentActiveTimer.description,
          startTime: currentActiveTimer.startTime,
          endTime: currentActiveTimer.endTime,
          duration: currentActiveTimer.duration,
          manual: currentActiveTimer.manual,
          user: currentActiveTimer.user,
          task: currentActiveTimer.task,
          mockTask: true,
        }
      : null

  const taskTimers = MockTask ? [MockTask].concat(task?.payload || []) : task?.payload || []
  const tasksMetaData = task?.metadata
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')

  const handleCreateSession = async () => {
    const result = await handleSave({ hours, minutes, taskId, dispatch, t })
    if (result.payload) {
      setHours('')
      setMinutes('')
      refetch()
    }
  }
  const isInvalid =
    (+hours === 0 && +minutes === 0) ||
    (!hours && !minutes) ||
    parseInt(hours) > 99 ||
    parseInt(minutes) > 99

  const tooltipTitle = isInvalid ? t('please_enter_valid_hours_and_minutes') : ''
  return (
    <div className="timer-dropdown-content">
      <div
        className="timer-dropdown-content-header"
        style={{
          height: selectedTab === 'timer' ? '100px' : '123px',
        }}
      >
        <div className="timer-dropdown-content-header-tabs" ref={tabsRef}>
          <div
            ref={timerTabRef}
            className={`timer-dropdown-content-header-tabs-tab ${selectedTab === 'timer' ? 'timer-dropdown-content-header-tabs-tab-selected' : ''}`}
            onClick={() => setSelectedTab('timer')}
          >
            <TimerClock />
            {t('timer')}
          </div>
          <div
            ref={manualTabRef}
            className={`timer-dropdown-content-header-tabs-tab ${selectedTab === 'manual' ? 'timer-dropdown-content-header-tabs-tab-selected' : ''}`}
            onClick={() => setSelectedTab('manual')}
          >
            <ManualTimer />
            {t('manual')}
          </div>
          <div className="timer-dropdown-content-header-tabs-indicator" style={indicatorStyle} />
        </div>
        <div className="timer-dropdown-content-header-tabs-content">
          {selectedTab === 'timer' && (
            <TimerContent
              isRunning={isRunning}
              elapsedSeconds={elapsedSeconds}
              handelOpen={handelOpenTimer}
              handelClose={handelCloseTimer}
            />
          )}
          {selectedTab === 'manual' && (
            <ManualContent
              hours={hours}
              setHours={setHours}
              minutes={minutes}
              setMinutes={setMinutes}
            />
          )}
        </div>
      </div>

      <div
        className="timer-dropdown-content-body"
        onScroll={(e) => handleScroll(e, tasksMetaData.total_items, page, 20, setPage)}
      >
        <div className="timer-dropdown-content-body-header">
          <div className="timer-dropdown-content-body-header-left">
            <TimerTask />
            {t('total_for_this_task')}
          </div>
          <div className="timer-dropdown-content-body-header-right">
            {taskDetails?.totalTime ? formatTimerTime(taskDetails?.totalTime) : '00:00:00'}
          </div>
        </div>
        {isLoading &&
          Array.from({ length: 10 }).map((_, index) => (
            <div className="timer-dropdown-content-body-skeleton" key={index}>
              <Skeleton.Input active />
            </div>
          ))}
        <>
          {Array.isArray(taskTimers) &&
            taskTimers?.map((item, index) => {
              if (!item.endTime && !item.mockTask) {
                return null
              }
              if (item.mockTask && !isRunning) {
                return null
              }
              return (
                <div className="timer-dropdown-content-body-item" key={index}>
                  {true ? (
                    <>
                      <div className="timer-dropdown-content-body-item-content">
                        <div className="timer-dropdown-content-body-item-content-header">
                          <span className="timer-dropdown-content-body-item-content-header-avatar">
                            <Avatar src={item?.user?.avatar}>
                              {item?.user?.firstName?.charAt(0) || 'U'}
                            </Avatar>
                          </span>
                          <OverflowTooltip
                            title={item?.description}
                            style={{ width: item?.manual ? '90px' : '150px' }}
                          >
                            <span>
                              {item?.user?.id === user?.id
                                ? t('me') + (item.mockTask ? ` (${t('current_timer')})` : '')
                                : item?.user?.firstName || item?.user?.lastName || 'USER'}
                            </span>
                          </OverflowTooltip>
                        </div>
                        <div className="timer-dropdown-content-body-item-content-footer">
                          {item?.manual && (
                            <Dropdown
                              dropdownRender={() => <EditSession item={item} refetch={refetch} />}
                              trigger={['click']}
                            >
                              <EditIcon />
                            </Dropdown>
                          )}
                          <span>
                            {item.mockTask && isRunning
                              ? formatTimerTime(elapsedSeconds)
                              : formatTimerTime(item?.duration)}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              )
            })}

          {tasksMetaData?.next && (
            <div className="timer-dropdown-content-body-skeleton">
              <Skeleton.Input />
            </div>
          )}
        </>
      </div>
      {selectedTab === 'manual' && (hours !== '' || minutes !== '') && (
        <div className="timer-dropdown-content-body-footer">
          <div className="timer-dropdown-content-body-footer-left">
            <ShieldCross />
            {t('cancel')}
          </div>
          <Tooltip title={tooltipTitle} open={isInvalid}>
            <div
              className={`timer-dropdown-content-body-footer-right ${isInvalid ? 'disabled' : ''}`}
              onClick={() => {
                if (!isInvalid && (hours !== '00' || minutes !== '00')) {
                  handleCreateSession()
                }
              }}
              style={{
                opacity: isInvalid ? 0.5 : 1,
                cursor: isInvalid ? 'not-allowed' : 'pointer',
              }}
            >
              <SavePlay />
              {t('save')}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default TimerDropdownContent
