import { FC, ReactNode } from 'react'
import { ReactComponent as DragSVG } from './../../assets/icons/dashboard/drag.svg'

interface GridItemProps {
  children: ReactNode
  item: { title: ReactNode }
  allowCustomize: boolean
}

const GridItem: FC<GridItemProps> = (props) => {
  const { children, item, allowCustomize } = props

  const { title } = item

  return (
    <div className="grid-item">
      <div className="grid-item-title">
        {title}
        {allowCustomize && (
          <div className="dashboard-drag-handler">
            <DragSVG width={20} height={20} />
          </div>
        )}
      </div>
      <div className="grid-item-content">{children}</div>
    </div>
  )
}

export default GridItem
