import ViewLayout from '@src/modules/shared/components/ViewLayout/ViewLayout'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { Dropdown } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import all from '../../assets/icons/integrations/all.svg'
import calendar from '../../assets/icons/integrations/calendar.svg'
import cloud from '../../assets/icons/integrations/cloud.svg'
import comment from '../../assets/icons/integrations/comment.svg'
import envelope from '../../assets/icons/integrations/envelope.svg'
import filter from '../../assets/icons/integrations/filter.svg'
import managment from '../../assets/icons/integrations/managment.svg'
import pulse from '../../assets/icons/integrations/pulse.svg'
import time from '../../assets/icons/integrations/time.svg'
import Header from '../../components/Header/Header'

import { getIntegrations } from '../../data/Integrations/IntegrationsThunk'
import IntegrationCard, { Iintegration } from './IntegrationCard/IntegrationCard'
import IntegrationSkeletonCard from './IntegrationSkeletonCard/IntegrationSkeletonCard'
import Search from '@src/modules/shared/components/Input/search'

const Integrations = () => {
  const { t } = useTranslation(['integrations'])
  useTitle(t('Settings - Integrations'))

  const headerProps = { title: t('integrations') }
  const dispatch = useAppDispatch()

  const { integrationsData, status, loadingIntegrationId } = useAppSelector(
    (state) => state.integrations,
  )

  const [integrations, setIntegrations] = useState<Iintegration[]>(integrationsData || [])

  const filterItems = [
    { icon: all, name: t('filters.all_integrations') },
    { icon: calendar, name: t('filters.calendars') },
    { icon: cloud, name: t('filters.cloud_storage') },
    { icon: comment, name: t('filters.communication') },
    { icon: pulse, name: t('filters.development') },
    { icon: managment, name: t('filters.management') },
    { icon: envelope, name: t('filters.mailing') },
    { icon: time, name: t('filters.time_tracking') },
  ]

  useEffect(() => {
    dispatch(getIntegrations())
      .unwrap()
      .then((res) => {
        setIntegrations(res?.payload?.payload || [])
      })
  }, [dispatch])

  const handleStatusChange = () => {
    dispatch(getIntegrations())
      .unwrap()
      .then((res) => {
        setIntegrations(res?.payload?.payload || [])
      })
  }

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="integrations_container">
        <div className="integrations_header">
          <Search
            size="sm"
            style={{ width: '100%', maxWidth: '370px' }}
            containerClassName="integrations_header-search"
            variant="label-inline"
          />

          <Dropdown
            className="filter_dropdown"
            trigger={['click']}
            dropdownRender={() => (
              <div className="filter_items">
                {filterItems.map((filter) => (
                  <div className="filter_item" key={filter.name}>
                    <img src={filter.icon} alt={filter.name} />
                    <span>{filter.name}</span>
                  </div>
                ))}
              </div>
            )}
          >
            <div className="filter_btn">
              <img src={filter} alt="Filter" />
              <span>{t('filter')}</span>
            </div>
          </Dropdown>
        </div>
        {status === 'loading' && !integrations.length ? (
          <div className="integration_content">
            {Array(3)
              .fill(1)
              .map((_, index) => (
                <IntegrationSkeletonCard key={index} />
              ))}
          </div>
        ) : (
          <div className="integration_content">
            {integrations.map((item) => (
              <IntegrationCard
                item={item}
                key={item.id}
                onStatusChange={handleStatusChange}
                loadingIntegrationId={loadingIntegrationId}
              />
            ))}
          </div>
        )}
      </div>
    </ViewLayout>
  )
}

export default Integrations
