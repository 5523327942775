import { ReactComponent as DateSVG } from '../../../assets/icons/fields/date.svg'
import { ReactComponent as CreatedBySVG } from '../../../assets/icons/fields/created-by.svg'
import { ReactComponent as NameSVG } from '../../../assets/icons/fields/name.svg'
import { ReactComponent as PriorityVG } from '../../../assets/icons/fields/priority.svg'
import { Avatar, Tooltip } from 'antd'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { formatDateToMultiLang } from '@src/modules/tasks/utils/formateDateToMultiLang'
import { useTranslation } from 'react-i18next'

const getLocation = (data) => {
  let location = ''
  if (data?.files?.length > 0 || data?.isPublic) {
    location += 'all Workspace'
  }

  if (data?.spaces && data?.spaces?.length > 0) {
    location += data?.spaces?.map((space) => space?.name).join(', ')
  }
  if (data?.folders && data?.folders?.length > 0) {
    location += (location ? ', ' : '') + data?.folders?.map((folder) => folder?.name).join(', ')
  }
  if (data?.files && data?.files?.length > 0) {
    location += (location ? ', ' : '') + data?.files?.map((file) => file?.name).join(', ')
  }

  return location === '' ? 'No spaces available' : location
}
const CreatedAt = ({ customField }) => {
  const { i18n } = useTranslation()
  return (
    <div className="list-string-field">
      <Tooltip title={formatDateToMultiLang(customField?.createdAt, i18n?.language)}>
        {formatDateToMultiLang(customField?.createdAt, i18n?.language)}
      </Tooltip>
    </div>
  )
}
const Location = ({ customField }) => {
  const location = getLocation(customField)
  return (
    <div className="list-string-field">
      <Tooltip title={location}>
        {location.length > 13 ? location.slice(0, 13) + '...' : location}
      </Tooltip>
    </div>
  )
}

const Visibility = ({ customField }) => {
  const { t } = useTranslation(['customFields'])
  return (
    <div className="list-string-field">{customField?.isPublic ? t('public') : t('private')}</div>
  )
}
const Type = ({ customField }) => {
  return (
    <div className="list-string-field">
      {customField?.fieldType?.charAt(0)?.toUpperCase() +
        customField?.fieldType?.slice(1)?.toLowerCase()}
    </div>
  )
}

const CreatedBy = ({ customField }) => {
  return (
    <div className="list-string-field list-string-field-created-by ">
      <Avatar src={customField?.createdBy?.avatar} size={'default'}>
        {customField?.createdBy?.fullName ? customField?.createdBy?.fullName?.[0] : ''}
      </Avatar>
      <OverflowTooltip style={{ width: 110 }} as="span">
        {customField?.createdBy?.fullName}
      </OverflowTooltip>
    </div>
  )
}
export const CustomfieldComponentMap = {
  visibility: {
    component: Visibility,
    props: (_, item) => ({
      customField: item,
    }),
  },
  type: {
    component: Type,
    props: (_, item) => ({
      customField: item,
    }),

    //   disable: canOnlyView,
    //   defEnd: task?.dueDate,
    //   defStart: task?.startDate,
    //   setStart: (date) => {
    //     localUpdateTask('startDate', date)
    //     updateTask({ id: task?.id, startDate: date })
    //   },
    //   setEnd: (date) => {
    //     localUpdateTask('dueDate', date)
    //     updateTask({ id: task?.id, dueDate: date })
    //   },
    //   onClick: (e) => e.stopPropagation(),
    //   isInTaskList: true,
    // }),
  },
  location: {
    component: Location,
    props: (_, item) => ({
      customField: item,
      // disable: canOnlyView,
      // showLabel: false,
      // activePriorityCode: task?.priority,
      // task: task,
      // onSelect: (p) => localUpdateTask('priority', p),
    }),
  },
  created_by: {
    component: CreatedBy,
    props: (_, item) => ({
      customField: item,
    }),
  },
  created_at: {
    component: CreatedAt,

    props: (_, item) => ({
      customField: item,
    }),
  },
}

export const allCustomFields = [
  { field: 'name', fieldName: 'Name', disbaled: true, icon: NameSVG },
  { field: 'location', fieldName: 'Location', disbaled: false, icon: DateSVG },
  { field: 'created_by', fieldName: 'Created by', disbaled: false, icon: PriorityVG },
  { field: 'created_at', fieldName: 'Created At', disbaled: false, icon: NameSVG },
  { field: 'visibility', fieldName: 'Visibility', disbaled: false, icon: CreatedBySVG },
  { field: 'type', fieldName: 'Type', disbaled: false, icon: CreatedBySVG },
]
