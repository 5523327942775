import { Modal, message } from 'antd'
import { ReactComponent as CloseIcon } from '@src/modules/shared/assets/icons/components/close-icon.svg'
import { ReactComponent as TrashIcon } from '@src/modules/shared/assets/icons/components/trash.svg'

import { useAppDispatch } from '@src/modules/shared/store'

import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useState } from 'react'
import Button from '@src/modules/shared/components/Button/Button'
import axiosInstance from '@src/modules/shared/utils/axios'
import { useTranslation } from 'react-i18next'

type props = {
  open: boolean
  handleClose: (id: string) => void
  id: string
  data: any
}

const DeleteCommentModal = ({ open, handleClose, id, data }: props) => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>()

  const handleDelete = () => {
    setLoading(true)
    try {
      axiosInstance.delete(`/api/comment/${data.id}`).then((_) => {
        data.fetch()
        message.success(t('DeleteCommentModal.Comment deleted successfully'))
      })
    } catch (error) {
      message.error(t('DeleteCommentModal.Something went wrong'))
    } finally {
      setLoading(false)
      handleClose(id)
    }
  }

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal confirmation-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('DeleteCommentModal.Delete Confirmation')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div>
        <div className="delete-confirmation">
          <div className="trash-icon">
            <TrashIcon />
          </div>

          <div className="description">
            {t(
              'DeleteCommentModal.You are about to delete this comment! Are you sure you want to proceed?',
            )}
          </div>
        </div>

        <div className="form-actions">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              dispatch(closeModal({ id }))
            }}
          >
            {t('DeleteCommentModal.Cancel')}
          </Button>

          <Button htmlType="submit" className="confirm-action" onClick={handleDelete}>
            {loading ? t('DeleteCommentModal.Loading...') : t('DeleteCommentModal.Delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteCommentModal
