/* eslint-disable no-unsafe-optional-chaining */
import { api } from '@src/modules/shared/services/api'
import { TaskType } from '../data/tasksSlice/tasksTypes'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import socketManager from '@src/modules/shared/socket/socketManager'

interface getMyTasksPayload {
  skip: number
  limit: number
  options?: string
  userId?: string
}
interface getTasksPayload {
  fileId?: string
  statusId?: string
  search?: string
  options?: string
  groupBy?: string
  skip?: number
  limit?: number
}

interface IGroup {
  id: string
  value: any
  fieldType: string
}

const defaultLimit = 10

export const TasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query<any, getTasksPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/tasks`,
          params,
        }
      },

      transformResponse: (res: any) => {
        res.payload.payload = res.payload.payload?.map((task) => ({
          ...task,
          users: [...(task?.users || {}), ...task?.teams],
        }))
        return res
      },

      providesTags: (result = []) => {
        return result
          ? [
              result?.payload?.payload.map(
                ({ id }: { id: string }) => ({ type: 'TASKS', id }) as const,
              ),
              { type: 'TASKS', id: 'LIST' },
            ]
          : []
      },
    }),

    getMyTasks: build.query<any, getMyTasksPayload>({
      query: (params) => {
        const { userId, ...newParams } = params
        params = { limit: defaultLimit, ...newParams }

        return {
          url: `api/users/${userId}/tasks`,
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs.arg.options
        const shouldCache = !withFilter
        if (newItems?.result?.payload?.length > 0) {
          currentCache?.result?.payload?.push(...newItems?.result?.payload)
        }
        currentCache.result.metadata = newItems?.result.metadata

        currentCache.result.payload =
          shouldCache || otherArgs.arg.skip > 1
            ? removeDuplicatesById(currentCache?.result?.payload)
            : newItems?.result?.payload
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
    }),

    getMyTasksByDay: build.query<any, getMyTasksPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/stat/mytasksv2`,
          params,
        }
      },
    }),

    getBoardGroups: build.query<any, { source: string; fileId: string }>({
      query: (params) => ({
        url: `api/board/group`,
        params,
      }),
      transformResponse: (response: { payload: IGroup[] }) => {
        const res = response?.payload?.map((group) => {
          return {
            ...group,
            id:
              group?.fieldType === 'users'
                ? group?.value?.map((user) => user?.id).join('&')
                : group?.value,
          }
        })

        return [...(res || []), { id: 'empty', custom: true, value: 'empty' }]
      },
    }),

    getSubtasks: build.query<any, Partial<{ parentId: string }> & getTasksPayload>({
      query: (params) => {
        return {
          url: `api/tasks`,
          params: {
            ...params,
            parentId: params.parentId,
          },
        }
      },
      transformResponse: (response: { payload: TaskType }): TaskType => {
        return response.payload
      },
      providesTags: (result = [], _error) => [
        ...result?.payload?.map(({ id }: { id: string }) => ({ type: 'SUBTASKS', id }) as const),
        { type: 'SUBTASKS', id: 'LIST' },
      ],
    }),

    getTask: build.query<any, { id: string; options?: string; orderField?: string }>({
      query: ({ id, ...params }) => {
        if (!id) return

        return {
          url: `api/tasks/${id}`,
          params,
        }
      },
      transformResponse: (response: { payload: TaskType }): TaskType => {
        return response?.payload
      },
      async onCacheEntryAdded(_, { updateCachedData }) {
        socketManager._getSocket().on('notifRoom', (update) => {
          updateCachedData((draft) => {
            let { action, data, entity } = update
            if (
              entity === 'Task' &&
              action === 'UPDATE' &&
              JSON.stringify(draft) !== JSON.stringify(data) &&
              draft?.id === data?.id
            )
              return data
          })
        })
      },
    }),

    addTask: build.mutation<TaskType, TaskType & { statusId: string; fileId: string }>({
      query: ({ ...body }) => ({
        url: `api/tasks`,
        method: 'POST',
        body,
      }),
    }),

    updateTask: build.mutation<
      any,
      Partial<
        TaskType & {
          tagsId: string[]
          previousParentId?: string
        }
      >
    >({
      query(data) {
        const { id, previousParentId, ...body } = data
        return {
          url: `api/tasks/${id}`,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { payload: data },
          } = (await queryFulfilled) || {}
          dispatch(
            TasksApi.util.updateQueryData('getTask', { id: data.id }, () => {
              return data
            }),
          )
        } catch {
          // patchResult.undo()
        }
      },
      // invalidatesTags: (task) => [{ type: 'SUBTASKS', id: task?.id }] as any,
    }),

    bulkUpdateTasks: build.mutation<any, Partial<any>>({
      query(body) {
        return {
          url: `api/bulkupdate`,
          method: 'PATCH',
          body,
        }
      },
    }),

    updateMultipleTasks: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/updateMany`,
          method: 'PATCH',
          body: data,
        }
      },
    }),

    bulkUpdateTasksList: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/bulkupdate`,
          method: 'PATCH',
          body: data,
        }
      },

      // invalidatesTags: (task) => [{ type: 'TASKS', id: task?.id }] as any,
    }),

    moveTask: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/moveTask`,
          method: 'PATCH',
          body: data,
        }
      },

      // invalidatesTags: (task) => [{ type: 'TASKS', id: task?.id }] as any,
    }),

    copyTask: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/copyTask`,
          method: 'PATCH',
          body: data,
        }
      },

      // invalidatesTags: (task) => [{ type: 'TASKS', id: task?.id }] as any,
    }),

    deleteTask: build.mutation<any, Partial<TaskType & { fileId: string }>>({
      query(data) {
        const { id } = data
        return {
          url: `api/tasks/${id}`,
          method: 'DELETE',
        }
      },
    }),

    deleteMutipleTask: build.mutation<any, Partial<any>>({
      query(data) {
        return {
          url: `api/bulkdeletetasks`,
          method: 'POST',
          body: data,
        }
      },
      async onQueryStarted({ id, ...patch }: any, { dispatch }) {
        try {
          dispatch(
            TasksApi.util.updateQueryData(
              'getSubtasks',
              { parentId: patch.parentId, fileId: patch?.fileId, limit: 200, skip: 1 } as Partial<{
                parentId: string
              }> &
                getTasksPayload,
              (draft) => {
                if (draft?.payload?.payload)
                  draft.payload.payload = draft.payload.payload?.filter((task) => task?.id !== id)
              },
            ),
          )
        } catch {
          // patchResult.undo()
        }
      },
      // invalidatesTags: (task) => [{ type: 'TASKS', id: task?.id }] as any,
    }),

    uploadTaskAttachements: build.mutation<any, Partial<{ files: File[] }>>({
      query(data) {
        return {
          url: `api/upload`,
          method: 'POST',
          body: objectToFormData(data),
          formData: true,
        }
      },
    }),

    getTaskModelsFilter: build.query<any, any>({
      query: () => ({
        url: `api/data/models/task`,
      }),
      transformResponse: (response: { payload: TaskType }): TaskType => {
        return response.payload
      },
    }),

    getAttachmentById: build.query<any, any>({
      query: ({ id }) => ({
        url: `api/upload/${id}`,
      }),
      transformResponse: (response: { payload: TaskType }): TaskType => {
        return response.payload
      },
    }),

    deleteAttachements: build.mutation<any, Partial<{ id: string }>>({
      query(data) {
        const { id } = data
        return {
          url: `api/upload/${id}`,
          method: 'DELETE',
        }
      },
    }),

    getTasksWithSearch: build.query<any, getTasksPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/tasks`,
          params,
        }
      },
      providesTags: (result = []) => {
        return result
          ? [
              result?.payload?.payload.map(
                ({ id }: { id: string }) => ({ type: 'TASKS', id }) as const,
              ),
              { type: 'TASKS', id: 'LIST' },
            ]
          : []
      },
    }),
  }),
})

export const updateTask = (task: any, query: getTasksPayload) => {
  return TasksApi.util.updateQueryData('getTasks', query, (draftTasks) => {
    return {
      new: task,
      ...draftTasks,
    }
  })
}

export const {
  useGetTasksQuery,
  useGetMyTasksQuery,
  useGetMyTasksByDayQuery,
  useGetBoardGroupsQuery,
  useGetTaskModelsFilterQuery,
  useMoveTaskMutation,
  useAddTaskMutation,
  useGetAttachmentByIdQuery,
  useUpdateTaskMutation,
  useBulkUpdateTasksMutation,
  useBulkUpdateTasksListMutation,
  useUploadTaskAttachementsMutation,
  useDeleteTaskMutation,
  useDeleteMutipleTaskMutation,
  useCopyTaskMutation,
  useGetTaskQuery,
  useGetSubtasksQuery,
  useDeleteAttachementsMutation,
  useUpdateMultipleTasksMutation,
  useGetTasksWithSearchQuery,
} = TasksApi

function objectToFormData(data) {
  const formData = new FormData()

  // Append files to FormData
  data?.files?.forEach((file: File) => {
    formData.append(`files`, file, file.name)
  })

  // Append other form fields
  // formData.append('taskId', data.taskId)

  return formData
}
