import { ReactNode } from 'react'
import { motion } from 'framer-motion'

export default function AnimatedButton({ children }: { children: ReactNode }) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ maxWidth: '99%' }}
    >
      {children}
    </motion.button>
  )
}
