export const getOperationFilter = (fields, setFilter, selectedField, filter) => {
  const { operation: operations } = (selectedField && fields[selectedField]) || {}
  return (
    operations?.map((operation, i) => {
      operation = operation?.replace('$', '')
      return {
        key: i,
        label: operation === 'ilike' ? 'is Like' : operation,
        onClick: () => setFilter({ ...filter, operation }),
      }
    }) || []
  )
}
