import { format, parseISO } from 'date-fns'

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export function dateToTimeSince(inputDate: Date | string | number): string {
  if (!inputDate) return ''
  if (typeof inputDate === 'string') {
    inputDate = new Date(inputDate)
  }
  if (typeof inputDate === 'number') {
    inputDate = new Date(inputDate)
  }
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - inputDate.getTime()

  const seconds = Math.floor(timeDifference / 1000)
  if (seconds < 60) {
    return (seconds > 0 ? seconds : -seconds) + 's ago'
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return minutes + 'm ago'
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    return hours + 'h ago'
  }

  const days = Math.floor(hours / 24)
  return days + 'd ago'
}

export function dateAndTimestammpFormat(input: string | Date): string {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = typeof input === 'string' ? new Date(input) : input
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  const minutesStr = minutes < 10 ? `0${minutes}` : minutes

  return `${monthNames[monthIndex]} ${day}, ${year} at ${hours}:${minutesStr} ${ampm}`
}
export function formatTimeRange(startTime: string | Date, endTime: string | Date): string {
  try {
    const parsedStartTime = typeof startTime === 'string' ? parseISO(startTime) : startTime
    const parsedEndTime = typeof endTime === 'string' ? parseISO(endTime) : endTime

    const formattedStartTime = format(parsedStartTime, 'p')
    const formattedEndTime = format(parsedEndTime, 'p')

    return `${formattedStartTime} - ${formattedEndTime}`
  } catch (e) {
    return ''
  }
}
