import {
  useAddTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
} from '@src/modules/tasks/services/tagsApi'
import { Dropdown, Tooltip, message } from 'antd'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TagIcon } from '@src/modules/tasks/assets/icons/task/tag.svg'
import { ReactComponent as WhiteTag } from '@src/modules/tasks/assets/icons/task/whiteTag.svg'

import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { hexToRgba } from '@src/modules/tasks/utils/hexTorgba'
import { ReactComponent as CrossIcon } from '@src/modules/tasks/assets/icons/task/cross.svg'
import TagsList from './TagsList/TagsList'
import { TagTypes } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import useDebounce from '../../hooks/useDebounce'

type Props = {
  children?: ReactNode
  spaceId?: string
  taskId?: string
  tags?: TagTypes[]
  CountOfTags?: number
  maxOfTextLength?: number
  listView?: boolean
  customTagRender?: boolean
  handleChange?: any
  renderTags?: Function
  isBordView?: boolean
  onChange?: any
  disabled?: boolean
}
function Tags({
  spaceId,
  taskId,
  disabled = false,
  children,
  tags,
  CountOfTags,
  maxOfTextLength,
  listView,
  customTagRender,
  renderTags,
  isBordView,
  onChange,
}: Props) {
  const { t } = useTranslation(['tasks'])
  const [tagname, setTagName] = useState('')

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const loadMore = () => {
    setPage(page + 1)
  }
  const { data: allTags, isLoading } = useGetTagsQuery({
    spaceId,
    limit: 20,
    skip: page,
    options: `{"where":{"name":{"operation":"$contains","value":"${searchValue}"}}}`,
  })
  const [selectedTags, setSelectedTags] = useState<TagTypes[]>(tags || [])
  const [addTag] = useAddTagMutation()
  const [deletTag] = useDeleteTagMutation()

  const [updateTag] = useUpdateTagMutation()
  const [updateTask] = useUpdateTaskMutation()
  useMemo(() => {
    if (tags && JSON.stringify(selectedTags) !== JSON.stringify(tags)) {
      setSelectedTags(tags)
    }
  }, [tags]) //TODO
  useDebounce(
    async () => {
      setSearchValue(tagname)
      setPage(1)
    },
    500,
    [tagname],
  )
  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const colors = ['#F51F45', '#34A174', '#5F61D2', '#C36D3B', '#05B7ED', '#878787']
    const randomColor = colors[Math.floor(Math.random() * colors.length)]
    if (event.key === 'Enter') {
      const tagExists = allTags.payload.some((data) => data.name === tagname)
      if (tagExists) {
        message.error('Tag name already exists')
        return
      }
      addTag({ name: tagname, color: randomColor, spaceId }).then((res: any) => {
        if (res?.data) {
          setSelectedTags((prevTags) => {
            const updatedTags = [...prevTags, res.data.payload]
            handelUpdateTask(updatedTags)
            return updatedTags
          })

          setTagName('')
        } else {
          message.error(res.error.message)
        }
      })
    }
  }
  const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event?.target?.value)
  }
  const handelUpdateTask = (selectedTags) => {
    if (taskId) {
      if (JSON.stringify(selectedTags) !== JSON.stringify(tags)) {
        updateTask({
          id: taskId,
          tagsId: selectedTags?.map((item) => item.id),
        })
      }
    }
  }

  const handleDeleteTag = (tagIdToDelete: string) => {
    const Result = selectedTags.filter((item) => item.id !== tagIdToDelete)
    deletTag({ id: tagIdToDelete })
    setSelectedTags(Result)
  }
  const handleDeletetagInTask = (tagIdToDelete: string) => {
    const Result = selectedTags.filter((item) => item.id !== tagIdToDelete)
    setSelectedTags(Result)
    handelUpdateTask(Result)
  }
  useEffect(() => {
    if (onChange) onChange(selectedTags)
  }, [selectedTags])
  const handleKeyDownTaskAction = (
    event: React.KeyboardEvent<HTMLInputElement>,
    name,
    id,
    color,
  ) => {
    if (event.key === 'Enter') {
      updateTag({ id: id, name: name, spaceId })
        .then((res: any) => {
          if (res) {
            setSelectedTags((prev) => prev.filter((tag) => tag.id !== id))
            setSelectedTags((prev) => [...prev, { id: id, name: name, color: color }])
          }
        })
        .catch((err) => {
          message.error(err?.message || t('Error while updating the tag'))
        })
    }
  }
  const handleEditColor = (color: string, id: string, name: string) => {
    updateTag({ id: id, color: color, spaceId })
      .then((res: any) => {
        if (res) {
          setSelectedTags((prev) => prev.filter((tag) => tag.id !== id))
          setSelectedTags((prev) => [...prev, { id: id, name: name, color }])
        }
      })
      .catch((err) => {
        message.error(err?.message || t('Error while updating the tag'))
      })
  }

  const handleTagSelect = (tag, checked) => {
    setSelectedTags((prevSelectedTags) => {
      const updatedTags = checked
        ? [...prevSelectedTags, tag]
        : prevSelectedTags.filter((t) => t.id !== tag.id)

      handelUpdateTask(updatedTags)
      return updatedTags
    })
  }

  return (
    <div className="tag-container" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        dropdownRender={() => (
          <TagsList
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            tagName={tagname}
            page={page}
            setPage={setPage}
            allTags={allTags}
            isLoading={isLoading}
            setTagName={setTagName}
            handleKeyDown={handleKeyDown}
            loadMore={loadMore}
            updateTask={handelUpdateTask}
            handleTagNameChange={handleTagNameChange}
            handleDeleteTag={handleDeleteTag}
            handleTagSelect={handleTagSelect}
            handleEditColor={handleEditColor}
            handleKeyDownTaskAction={handleKeyDownTaskAction}
          />
        )}
        trigger={['click']}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <div
            className={`add-tag ${!listView && 'add-tag-purple'}`}
            style={{ padding: '2px 7px 2px 2px' }}
          >
            {!listView ? (
              <>
                <WhiteTag /> {t('tag')} +
              </>
            ) : (
              <TagIcon />
            )}
          </div>
        )}
      </Dropdown>

      {customTagRender
        ? renderTags()
        : selectedTags?.length > 0 &&
          selectedTags?.slice(0, CountOfTags).map((tag: any, index: number) => {
            return (
              <span
                style={{
                  color: tag?.color,
                  backgroundColor: hexToRgba(tag?.color, 0.25),
                }}
                className="selected-tag"
                key={index}
              >
                <span>
                  <Tooltip title={tag?.name.length > maxOfTextLength && tag?.name}>
                    {tag?.name.length > maxOfTextLength
                      ? tag?.name.slice(0, maxOfTextLength) + '...'
                      : tag?.name}
                  </Tooltip>
                </span>
                <span
                  style={{
                    stroke: tag?.color,
                  }}
                  className="cross-icon"
                  onClick={() => handleDeletetagInTask(tag.id)}
                >
                  <CrossIcon />
                </span>
              </span>
            )
          })}

      {isBordView ||
        (selectedTags?.length - CountOfTags > 0 && (
          <Tooltip
            title={selectedTags
              ?.slice(CountOfTags)
              .map((tag) => tag?.name)
              .join(', ')}
          >
            <span className="additional-tags-count">+{selectedTags?.length - CountOfTags}</span>
          </Tooltip>
        ))}
    </div>
  )
}

export default Tags
