import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { ReactComponent as CloseIcon } from './../../../assets/icons/task/close-add-task.svg'
import TaskUsers from '../../TaskUsers'
import TaskPriority from '../../TaskPriority'
import { useAddTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { CreateTaskInfo, reformulateTaskInfo } from '../../Modals/CreateTaskModal/CreateTaskModal'
import { message } from 'antd'
import Calendar from '../../Calendar'
import { useGetStatusesByListQuery } from '@src/modules/spaces/services/fileApi'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'
import { getTaskfield } from '../../ListView/AddSubTask/AddSubTaskInput'

export const getDefaultValue = (data, groupBy) => {
  if (groupBy?.type === GroupByFields.STATUS) {
    return data?.id
  } else if (groupBy?.type === GroupByFields.PRIORITY) {
    return data?.id
  } else if (groupBy?.type === GroupByFields.ASSIGNEE) {
    return data?.value?.map(({ id, username }) => ({ id, fullName: username }))
  } else {
    return [
      {
        customFieldId: groupBy?.id,
        value: data?.value,
      },
    ]
  }
}

interface AddTaskProps {
  data: statusType
  fileId: string
  addTaskInfo: CreateTaskInfo
  setAddTaskMode: (data) => void
  onAddTask: (t: any) => void
  groupBy: { type: string; id: string; custom: boolean }
}

const AddTask = ({
  data,
  fileId,
  addTaskInfo,
  onAddTask,
  setAddTaskMode,
  groupBy,
}: AddTaskProps) => {
  const { data: statuses } = useGetStatusesByListQuery({ listId: fileId }, { skip: !fileId })
  const { t } = useTranslation(['tasks'])
  const [addTask, { isLoading }] = useAddTaskMutation()

  const newTask = reformulateTaskInfo({
    ...addTaskInfo,
    [getTaskfield(groupBy)]: getDefaultValue(data, groupBy),
  } as CreateTaskInfo)

  const addTaskHandler = () => {
    if (isLoading) return
    if (!newTask.name?.trim()) {
      message.error('Task name is required')
      return
    }

    addTask({
      statusId: statuses?.[0]?.id,
      ...newTask,
      name: newTask?.name,
      fileId,
    })
      .unwrap()
      .then((res) => {
        setAddTaskMode(null)
        onAddTask(res?.payload)
      })
      .catch((err) => {
        message.error(err?.message || 'Error')
      })
  }

  const keyDownHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') addTaskHandler()
    else if (e.key === 'Escape') setAddTaskMode(null)
  }

  const focusOut = () => {
    if (newTask.name?.trim() && !isLoading) addTaskHandler()
    else if (!isLoading) setAddTaskMode(null)
  }

  return (
    <>
      <div className="overlay-dropdown" onClick={focusOut}></div>
      <div
        className={`status-card-button  active-status-card-button`}
        onClick={() => !addTaskInfo && setAddTaskMode({ users: [] })}
        onKeyDown={keyDownHandler}
        tabIndex={0}
      >
        <p className="new-task-path">
          <CloseIcon
            className="new-task-close-icon"
            onClick={(e) => {
              e.stopPropagation()
              setAddTaskMode(null)
            }}
          />
        </p>
        <div className="new-task-name">
          <Input
            type="text"
            placeholder={t('board_view.type_task_name')}
            autoFocus
            value={newTask?.name}
            onChange={(e) => setAddTaskMode((prev) => ({ ...prev, name: e.target.value }))}
            variant="label-inline"
            containerClassName="new-task-input"
          />
          <TaskUsers
            userNumber={2}
            displayAddButton={!addTaskInfo?.users?.length}
            members={addTaskInfo?.users}
            setAssignedMembersList={(m) => setAddTaskMode((prev) => ({ ...prev, users: m }))}
          />
        </div>
        <div className="new-task-footer">
          <div className="new-task-footer-part-1">
            <TaskPriority
              showLabel={false}
              activePriorityCode={
                groupBy?.type === GroupByFields.PRIORITY ? newTask?.priority : addTaskInfo.priority
              }
              onSelect={(p) => setAddTaskMode((prev) => ({ ...prev, priority: p }))}
            />
            <Calendar
              defStart={addTaskInfo?.startDate}
              defEnd={addTaskInfo?.dueDate}
              setStart={(date) => {
                setAddTaskMode((prev) => ({ ...prev, startDate: date }))
              }}
              setEnd={(date) => {
                setAddTaskMode((prev) => ({ ...prev, dueDate: date }))
              }}
              onClick={(e) => e.stopPropagation()}
              isInTaskList={true}
            />
          </div>
          <div className="save-button" onClick={addTaskHandler} aria-disabled={isLoading}>
            {isLoading ? t('board_view.loading') : t('board_view.save')}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTask
