import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import noTasks from '../../../assets/icons/dashboard/no-tasks.svg'
import { useTranslation } from 'react-i18next'

function HigChart({ options }) {
  const { t } = useTranslation(['tasks'])
  return (
    <div>
      {options.series.length === 0 ? (
        <div className="no-data">
          <img src={noTasks} alt="No tasks" />
          <p>{t('No Data Yet...')}</p>
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  )
}

export default HigChart
