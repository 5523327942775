export const getSortFieldByGroupBy = (groupBy: string) => {
  if (groupBy === 'users') {
    groupBy = 'assigned'
  }
  return `${groupBy}Count`
}

export const getBulkUpdateFieldByGroupBy = (groupBy: string) => {
  if (groupBy === 'users') {
    groupBy = 'assigned'
  }
  return `${groupBy}Index`
}
