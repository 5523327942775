import axiosInstance from '@src/modules/shared/utils/axios'
import { message } from 'antd'
import { v4 as uuidv4 } from 'uuid'

export const uploadImage = async (file) => {
  try {
    const formData = new FormData()
    formData.append('files', file)

    const response = await axiosInstance.post('/api/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return response?.data?.payload[0]
  } catch (error) {
    console.log('uploadImage error', error)
  }
}

export const insertInEditor = (
  blob: File,
  editor: any,
  setData: (obj: any) => void,
  onUplaod: (link: string) => void,
  type: 'image' | 'video',
  insert?: boolean,
  onInsert?: (v: any) => void,
) => {
  const fileUrl = URL.createObjectURL(blob)
  const blockIdentifier = editor.getTextCursorPosition().block
  const blockId = uuidv4()

  onInsert && onInsert(fileUrl)
  if (type === 'image') {
    editor.insertBlocks(
      [{ type: 'image', id: blockId, props: { url: fileUrl } }],
      blockIdentifier,
      'after',
    )
  } else if (type === 'video') {
    editor.insertBlocks(
      [
        {
          type: 'video',
          id: blockId,
          props: {
            src: fileUrl,
            class: 'description-uploading',
          },
        },
      ],
      editor.getTextCursorPosition().block,
      'after',
    )
  } else message.info('media type not supported')

  uploadImage(blob)
    .then((res) => {
      editor.removeBlocks([{ id: blockId }])

      if (insert) {
        if (type === 'image') {
          editor.insertBlocks(
            [{ type: 'image', id: res?.id, props: { url: res?.location } }],
            blockIdentifier,
            'after',
          )
        } else if (type === 'video') {
          editor.insertBlocks(
            [
              {
                type: 'video',
                id: res?.id,
                props: {
                  src: res?.location,
                  class: 'description-uploaded',
                },
              },
            ],
            editor.getTextCursorPosition().block,
            'after',
          )
        }
      }
      setData && setData((prev) => [...(prev || []), { id: res?.id, src: res?.location }])
      onUplaod && onUplaod(res)
    })
    .catch(() => {
      editor.removeBlocks([{ id: blockId }])
      onUplaod && onUplaod(null)
      //TODO: translate
      message.error('Error on upload image')
    })
}

export const handlePasteInEditor = (
  event: any,
  editor,
  setData?: (obj: { id: string; src: string }[]) => void,
  onUplaod?: (link: string) => void,
  insert: boolean = false,
  onPaste?: (v: any) => void,
) => {
  const items = (event.clipboardData || event.originalEvent.clipboardData).items
  for (let i = 0; i < items.length; i++) {
    const blob = items[i].getAsFile()

    if (items[i].type.indexOf('image') !== -1) {
      insertInEditor(blob, editor, setData, onUplaod, 'image', insert, onPaste)
    } else if (items[i].type.indexOf('video') !== -1) {
      insertInEditor(blob, editor, setData, onUplaod, 'video', insert, onPaste)
    }
  }
}
