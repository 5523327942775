import { message, Modal, Radio } from 'antd'
import { useEffect, useState } from 'react'
import {
  useGetHierarchyTypeQuery,
  useGetStatausQuery,
  useUpdateHierarchyMutation,
  useUpdateManyStatusesMutation,
} from '@src/modules/spaces/services/fileApi'
import SkeletonLoader from '@src/modules/shared/components/SkeletonLoader'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'
import { useGetCategoriesQuery } from '@src/modules/spaces/services/categoryApi'
import StatusGroup from './StatusGroup'
import { colors } from '@src/modules/auth/features/CreateWorkspace/CreateWorkspace'
import { useAppDispatch } from '@src/modules/shared/store'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { ReactComponent as StatusSkeleton } from '../../../assets/icons/status/status-skeleton.svg'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'

const STATUS_CONFLICTS_CODE = 'STATUS_CONFLICT'

const formatNewCustomStatus = (statuses, removeId = false, removeIsNew = false) => {
  return statuses.map((item, index) => {
    const { color, name, id, isNew, categoryId, isDeleted } = item
    item = { color, name, id, isNew, categoryId, order: index, isDeleted }
    if (item.isNew !== undefined) {
      removeId && item.isNew && delete item.id
      removeIsNew && delete item.isNew
      return item
    } else return item
  })
}

const getParent = (data) => {
  if (data?.type === 'folderId') {
    return { ...(data?.space || {}), type: 'space' }
  } else if (data?.folderId) {
    return { id: data?.folderId, type: 'folder' }
  } else return { id: data?.spaceId || data?.id, type: 'space' }
}

const generateStatus = (category) => ({
  categoryId: category.id,
  name: category.name,
  id: Math.random().toString(36).substr(2, 9),
  color: colors[Math.floor(Math.random() * colors.length)],
  isNew: true,
})

const updateEntityInheritedStatus = (entity, inherit: boolean, updateHierarchy) => {
  return updateHierarchy({
    id: entity.id,
    hierarchyType: entity?.type,
    inheritStatusFromParent: inherit,
  })
}

const renderStatusTypes = (parent, isCustom, t) => [
  {
    label: t('UpdateStatusModal.Inherit-from') + t(`UpdateStatusModal.${parent.toLowerCase()}`),
    defaultChecked: !isCustom,
    key: 'inherit',
  },
  {
    label: t('UpdateStatusModal.Use-custom-statuses'),
    defaultChecked: isCustom,
    key: 'custom',
  },
]

const UpdateStatusModal = ({ open, handleClose, data, id }) => {
  const { name, id: entityId, type } = data
  const { t } = useTranslation(['modals'])
  const [isCustom, setIsCustom] = useState(true)
  const [loading, setLoading] = useState(false)
  const [customStatus, setCustomStatus] = useState([])
  const isSpace = type === 'spaceId'
  const dispatch = useAppDispatch()
  const { type: parentType, ...parent } = getParent(data)
  const {
    data: entity,
    refetch: refetchInheritStatus,
    isLoading: gettingHierarchyType,
  } = useGetHierarchyTypeQuery(
    {
      id: entityId,
      hierarchyType: type,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const { inheritStatusFromParent } = entity || {}
  const [updateManyStatus] = useUpdateManyStatusesMutation()
  const [updateHierarchy] = useUpdateHierarchyMutation()
  const { data: categories } = useGetCategoriesQuery()
  const {
    data: status,
    isLoading: gettingStatus,
    refetch: refetchStatuses,
  } = useGetStatausQuery({
    hierarchyType: type,
    entityUuid: entityId,
  })
  const {
    data: parentStatus,
    isLoading: gettingParentStatus,
    refetch: refetchParentStatuses,
  } = useGetStatausQuery(
    {
      hierarchyType: parentType + 'Id',
      entityUuid: parent?.id,
    },
    {
      skip: !parent?.id,
    },
  )
  const targetStatus = isCustom ? customStatus : parentStatus

  useEffect(() => {
    if (!status) return
    setIsCustom(isSpace || !inheritStatusFromParent)
    if (isSpace || !inheritStatusFromParent) setCustomStatus(status)
    else {
      const defaultStatuses = []
      categories?.forEach((category) => defaultStatuses.push(generateStatus(category)))
      setCustomStatus(defaultStatuses)
    }
  }, [status, inheritStatusFromParent])

  const onSaveSuccess = () => {
    handleClose(id)
    refetchStatuses()
    refetchParentStatuses()
    refetchInheritStatus()
  }

  const saveStatus = async () => {
    try {
      setLoading(true)
      if (!isSpace && inheritStatusFromParent === isCustom)
        await updateEntityInheritedStatus(data, !isCustom, updateHierarchy).unwrap()

      if (isCustom || isSpace) {
        await updateManyStatus({
          statuses: formatNewCustomStatus(customStatus, true, true),
          hierarchyType: type,
          entityUuid: entityId,
        }).unwrap()
      }
      onSaveSuccess()
    } catch (error) {
      const isStatusConflicts = error?.code === STATUS_CONFLICTS_CODE
      if (isStatusConflicts) {
        dispatch(
          openModal({
            id: 'conflict-management-drawer',
            data: {
              statuses: isCustom || isSpace ? formatNewCustomStatus(customStatus) : parentStatus,
              hierarchyType: type,
              entityUuid: entityId,
              inheritStatusFromParent: !isCustom,
              onResolve: onSaveSuccess,
            },
          }),
        )
      } else message.error(error.message || t('UpdateStatusModal.Error-while-updating-statuses'))
    } finally {
      setLoading(false)
    }
  }

  const updateStatus = (status) => {
    const newStatus = customStatus.map((item) => (item.id === status.id ? status : item))
    setCustomStatus(newStatus)
  }

  const deleteStatus = (statusId: string) => {
    const newStatus = customStatus.map((item) =>
      item.id === statusId ? { ...item, isDeleted: true } : item,
    )
    setCustomStatus(newStatus)
  }

  const createStatus = (status) => {
    const newStatus = [{ ...status, order: 0 }, ...customStatus]
    setCustomStatus(newStatus)
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return
    const { source, destination, draggableId } = result
    if (source.droppableId !== destination.droppableId) {
      const newStatus = Array.from(customStatus)
      const itemIndex = newStatus.findIndex((item) => item.id === draggableId)
      let [removed] = newStatus.splice(itemIndex, 1)
      removed = { ...removed, categoryId: destination.droppableId, order: destination.index }
      newStatus.splice(destination.index, 0, removed)
      setCustomStatus(newStatus)
    } else {
      const newStatus = Array.from(customStatus)
      const itemIndex = newStatus.findIndex((item) => item.id === draggableId)
      let [removed] = newStatus.splice(itemIndex, 1)
      removed = { ...removed, order: destination.index }
      newStatus.splice(destination.index, 0, removed)
      setCustomStatus(newStatus)
    }
  }

  return (
    <Modal
      footer={true}
      centered
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal task-modal space-form update-status-modal"
      closable={false}
    >
      <div className="status-modal">
        <div className="space-modal-header">
          <div className="modal-title">
            {t('UpdateStatusModal.Update')} <span>{name}</span>
            {t('UpdateStatusModal.Statuses')}
          </div>
        </div>
        <SkeletonLoader
          skeleton={<StatusSkeleton />}
          isLoading={gettingParentStatus || gettingStatus || gettingHierarchyType}
        >
          <>
            <div className="status-type">
              {!isSpace && (
                <>
                  <p className="status-type-title">{t('UpdateStatusModal.Status-type')}</p>
                  <div className="status-type-options">
                    <Radio.Group value={isCustom ? 'custom' : 'inherit'}>
                      {renderStatusTypes(parentType, isCustom, t).map((item) => (
                        <Radio
                          key={item.key}
                          value={item.key}
                          onChange={() => setIsCustom(item.key === 'custom')}
                        >
                          <span>{item.label}</span>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                </>
              )}
            </div>
            <div className="status-content-list">
              <DragDropContext onDragEnd={onDragEnd}>
                {categories?.map((category) => {
                  return (
                    <Droppable key={category.id} droppableId={category.id}>
                      {(provided, _) => (
                        <StatusGroup
                          key={category.id}
                          category={category}
                          status={targetStatus?.filter((item) => !item.isDeleted)}
                          updateStatus={updateStatus}
                          deleteStatus={deleteStatus}
                          viewOnly={!isCustom}
                          addStatus={createStatus}
                          provided={provided}
                        />
                      )}
                    </Droppable>
                  )
                })}
              </DragDropContext>
            </div>
            <div className="status-actions">
              <Button className="cancel-action" htmlType="reset" onClick={() => handleClose(id)}>
                {t('UpdateStatusModal.Cancel')}
              </Button>
              <Button className="confirm-action" loading={loading} onClick={saveStatus}>
                {t('UpdateStatusModal.Save-changes')}
              </Button>
            </div>
          </>
        </SkeletonLoader>
      </div>
    </Modal>
  )
}

export default UpdateStatusModal
