import axiosInstance from '@src/modules/shared/utils/axios'

export const uploadImage = (file) => {
  const formData = new FormData()
  formData.append('files', file)
  return axiosInstance
    .post(`/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data.payload[0]
    })
    .catch((error) => {
      console.error('Upload failed', error)
      return {
        success: 0,
      }
    })
}
