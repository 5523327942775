import { SVGProps } from 'react'

type Props = {}

function EmojiIcon(props: SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9202 11.2979C10.572 11.6461 10.1586 11.9224 9.70363 12.1108C9.24865 12.2993 8.76102 12.3963 8.26855 12.3963C7.77609 12.3963 7.28846 12.2993 6.83348 12.1108C6.37851 11.9224 5.96511 11.6461 5.61689 11.2979M15.7686 8.64624C15.7686 9.63115 15.5746 10.6064 15.1977 11.5164C14.8207 12.4263 14.2683 13.2531 13.5719 13.9495C12.8754 14.646 12.0486 15.1984 11.1387 15.5753C10.2287 15.9522 9.25347 16.1462 8.26855 16.1462C7.28364 16.1462 6.30837 15.9522 5.39843 15.5753C4.48849 15.1984 3.66169 14.646 2.96525 13.9495C2.26881 13.2531 1.71637 12.4263 1.33946 11.5164C0.962548 10.6064 0.768555 9.63115 0.768555 8.64624C0.768555 6.65712 1.55873 4.74946 2.96525 3.34294C4.37178 1.93642 6.27943 1.14624 8.26855 1.14624C10.2577 1.14624 12.1653 1.93642 13.5719 3.34294C14.9784 4.74946 15.7686 6.65712 15.7686 8.64624ZM6.39355 6.77124C6.39355 7.11624 6.25355 7.39624 6.08105 7.39624C5.90855 7.39624 5.76855 7.11624 5.76855 6.77124C5.76855 6.42624 5.90855 6.14624 6.08105 6.14624C6.25355 6.14624 6.39355 6.42624 6.39355 6.77124ZM6.08105 6.77124H6.08772V6.78374H6.08105V6.77124ZM10.7686 6.77124C10.7686 7.11624 10.6286 7.39624 10.4561 7.39624C10.2836 7.39624 10.1436 7.11624 10.1436 6.77124C10.1436 6.42624 10.2836 6.14624 10.4561 6.14624C10.6286 6.14624 10.7686 6.42624 10.7686 6.77124ZM10.4561 6.77124H10.4627V6.78374H10.4561V6.77124Z"
        stroke="#6D8496"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmojiIcon
