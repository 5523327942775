import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import { Checkbox } from 'antd'

const CheckboxField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const checked = field.custom ? customFieldValue : task[field.value]

  return (
    <div className="list-checkbox-field" onClick={(e) => e.stopPropagation()}>
      <Checkbox
        defaultChecked={typeof checked === 'boolean' ? checked : checked === 'true'}
        onChange={(e) => saveCustomFieldValue(e.target.checked.toString())}
      />
    </div>
  )
}

export default CheckboxField
