/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getOneTask, getTasks, getTags, getSubtasks } from './tasksThunk'
import { column } from './tasksTypes'
import { isJsonString } from '@src/modules/shared/utils/isJSON'
import { ListCustomFieldsApi } from '../../services/customFieldsApi'
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import { groupByNestedField } from '../utils/groupByNestedField'
import { toField } from '../../utils/toField'
export interface TasksState {
  tasks: any
  error: any
  task: any
  status: string
  tags: any
  subtasks: any
  treeViewState: any
  selectedItems: string[]
  clearSelected: boolean
  createdTask: any
  updatedTask: any
  bulkUpdateTask: any
  deletedTask: any
  updatedMany: any
  headers: column[] | null
  customFields: column[] | null
}

const customFields = localStorage.getItem('custom_fields')

const initialState: TasksState = {
  tasks: null,
  task: null,
  error: null,
  status: 'idle',
  tags: [],
  selectedItems: [],
  treeViewState: {},
  clearSelected: false,
  subtasks: null,
  createdTask: null,
  updatedTask: null,
  bulkUpdateTask: null,
  deletedTask: null,
  updatedMany: null,
  headers: [],
  customFields:
    customFields && isJsonString(customFields)
      ? JSON.parse(localStorage.getItem('custom_fields'))
      : [
          {
            id: 'col4',
            field: 'name',
            fieldName: 'Name',
            width: 400,

            sortBy: false,
            disable: true,
            order: 4,
            groupBy: false,
            hidden: false,
            maxWidth: 300,
            minWidth: '400px',
          },
          {
            id: 'col1',
            field: 'location',
            fieldName: 'Location',
            width: 211,
            minWidth: 200,
            maxWidth: 300,
            sortBy: true,
            groupBy: true,
            order: 1,
            hidden: false,
          },
          {
            id: 'col2',
            field: 'created_by',
            fieldName: 'Created By',
            width: 240,
            minWidth: 249,
            maxWidth: 300,
            sortBy: false,
            groupBy: true,
            order: 2,
            hidden: false,
          },
          {
            id: 'col3',
            field: 'created_at',
            fieldName: 'Created At',
            width: 200,
            minWidth: 200,
            maxWidth: 300,
            sortBy: true,
            groupBy: true,
            order: 2,
            hidden: false,
          },
          {
            id: 'col5',
            field: 'visibility',
            fieldName: 'Visibility',
            width: 150,
            sortBy: false,
            minWidth: 130,
            maxWidth: 300,
            groupBy: true,
            order: 2,
            hidden: false,
          },
          {
            field: 'type',
            fieldName: 'Type',
            disbaled: false,
            sortBy: false,
            minWidth: 140,
            maxWidth: 300,
            width: 150,
            order: 2,
          },
        ],
}

const spacesSlice = createSlice({
  name: 'tasks',
  initialState,

  reducers: {
    setNewSubtasks: (state, { payload }) => {
      if (state.subtasks) state.subtasks[payload?.taskId] = payload?.subtasks
    },

    setSocketEvent(state, { payload }) {
      state[payload.updateType] = payload.updateEvent
    },

    resetSocketEvents(state) {
      state.bulkUpdateTask =
        state.createdTask =
        state.deletedTask =
        state.updatedTask =
        state.updatedMany =
          null
    },

    setSelectedItems(state, { payload }) {
      state.selectedItems = payload
    },

    setTreeViewState(state, { payload }) {
      state.treeViewState = payload
    },

    setClear(state, { payload }) {
      state.clearSelected = payload
    },

    setHeaders(state, { payload }) {
      state.headers = payload
    },
    setCustomFields(state, { payload }) {
      state.customFields = payload
    },

    hideColumn(state, { payload }) {
      state.headers = state.headers?.map((column) =>
        column.id === payload ? { ...column, hidden: true, sortBy: 0, sortOrder: 'ASC' } : column,
      )
    },
    showColumn(state, { payload }) {
      state.headers = state.headers?.map((column) =>
        column.id === payload ? { ...column, hidden: false } : column,
      )
    },
    hideCustomFieldColumn(state, { payload }) {
      state.customFields = state.customFields?.filter((column) => column.field !== payload)
    },
    setCustomFieldColumn(state, { payload }) {
      state.customFields = [payload, ...state.customFields]
    },
    setColumn(state, { payload }) {
      state.headers = [payload, ...state.headers]
    },

    moveColumnToSart(state, { payload }) {
      const columns = [...state.headers]
      const index = columns.findIndex((item) => item.id === payload)
      if (index > -1) {
        const [item] = columns.splice(index, 1)
        columns.unshift(item)
      }
      state.headers = columns
    },
    moveCustomFieldColumnToSart(state, { payload }) {
      const columns = [...state.customFields]
      const index = columns.findIndex((item) => item.id === payload)
      if (index > -1) {
        const [item] = columns.splice(index, 1)
        columns.unshift(item)
      }
      state.customFields = columns
    },
    moveColumnToEnd(state, { payload }) {
      const columns = [...state.headers]

      const index = columns.findIndex((item) => item.id === payload)
      if (index > -1) {
        const [item] = columns.splice(index, 1)
        columns.push(item)
      }

      state.headers = columns
    },

    sortByField(state, { payload }) {
      const columns = [...state.headers]

      const maxSortBy = Math.max(...columns.map((item) => item.sortBy))
      const field = payload
      let originalSortBy = null

      let newColumns = columns.map((item) => {
        if (item.id === field) {
          if (item.sortBy === 0) {
            return { ...item, sortBy: maxSortBy + 1 }
          } else {
            if (item.sortOrder === 'ASC') {
              return { ...item, sortOrder: 'DESC' }
            } else {
              originalSortBy = item.sortBy
              return { ...item, sortBy: 0, sortOrder: 'ASC' }
            }
          }
        }

        return item
      })

      if (originalSortBy !== null) {
        newColumns = newColumns.map((item) => {
          if (item.sortBy > originalSortBy) {
            return { ...item, sortBy: item.sortBy - 1 }
          }
          return item
        })
      }

      state.headers = newColumns
    },

    clearSort(state, { payload }) {
      const columns = [...state.headers]
      let originalSortBy = null

      let newColumns = columns.map((item) => {
        if (item.id === payload) {
          originalSortBy = item.sortBy
          return { ...item, sortBy: 0, sortOrder: 'ASC' }
        }
        return item
      })

      if (originalSortBy !== null) {
        newColumns = newColumns.map((item) => {
          if (item.sortBy > originalSortBy) {
            return { ...item, sortBy: item.sortBy - 1 }
          }
          return item
        })
      }

      state.headers = newColumns
    },
    moveCustomFieldColumnToEnd(state, { payload }) {
      const columns = [...state.customFields]

      const index = columns.findIndex((item) => item.id === payload)
      if (index > -1) {
        const [item] = columns.splice(index, 1)
        columns.push(item)
      }

      state.customFields = columns
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTasks.fulfilled, (state, action: PayloadAction<any>) => {
      state.tasks = action.payload
      state.status = 'idle'
    })
    builder.addCase(getTasks.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getSubtasks.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getSubtasks.fulfilled, (state, action: PayloadAction<any>) => {
      const { response, query } = action.payload
      state.subtasks = { ...state.subtasks, [query?.parentId]: response?.payload?.payload }
      state.status = 'idle'
    })
    builder.addCase(getSubtasks.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getOneTask.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getOneTask.fulfilled, (state, action: PayloadAction<any>) => {
      state.task = action.payload
      state.status = 'idle'
    })
    builder.addCase(getOneTask.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getTags.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getTags.fulfilled, (state, action: PayloadAction<any>) => {
      state.tags = action.payload.payload
      state.status = 'idle'
    })
    builder.addCase(getTags.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })

    builder.addMatcher(
      ListCustomFieldsApi.endpoints.createCustomField.matchFulfilled,
      (state, action) => {
        const { payload } = action

        state.headers = [...state.headers, toField(payload?.payload, false)]
      },
    )
    builder.addMatcher(
      ListCustomFieldsApi.endpoints.deleteCustomFields.matchFulfilled,
      (state, action) => {
        const { meta } = action

        const deletedFieldId = meta.arg.originalArgs?.id

        state.headers = state.headers?.filter((field) => field.id !== deletedFieldId)
      },
    )
  },
})

export const {
  setSocketEvent,
  resetSocketEvents,
  setNewSubtasks,
  setSelectedItems,
  setTreeViewState,
  setClear,
  setCustomFields,
  showColumn,
  moveCustomFieldColumnToSart,
  moveCustomFieldColumnToEnd,
  setCustomFieldColumn,
  hideCustomFieldColumn,
  moveColumnToEnd,
  moveColumnToSart,
  setHeaders,
  hideColumn,
  clearSort,
  sortByField,
  setColumn,
} = spacesSlice.actions // eslint-disable-line
export default spacesSlice.reducer
