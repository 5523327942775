import { Dropdown } from 'antd'
import { currencies } from 'currencies.json'
import { useEffect, useState, useMemo } from 'react'
import { ReactComponent as SearchIcon } from '@src/modules/tasks/assets/icons/filter/search-icon.svg'
import { ReactComponent as ArrowBottomIcon } from '@src/modules/customFields/assets/icon-bottom.svg'
import { useTranslation } from 'react-i18next'
import Search from '@src/modules/shared/components/Input/search'

function Money({ formik, data = null }) {
  const { t } = useTranslation(['customFields'])
  const [search, setSearch] = useState('')

  const filteredCurrencies = useMemo(
    () =>
      currencies.filter((currency) => currency?.name.toLowerCase().includes(search.toLowerCase())),
    [search],
  )

  const initialCurrency = useMemo(() => {
    if (data?.values?.code) {
      return (
        currencies.find((currency) => currency.code === data.values.code) || filteredCurrencies[0]
      )
    }
    return filteredCurrencies[0]
  }, [data, filteredCurrencies])

  const [selectedCurrency, setSelectedCurrency] = useState(initialCurrency)
  useEffect(() => {
    if (initialCurrency) {
      formik.setFieldValue(
        'values',
        {
          code: initialCurrency.code,
          name: initialCurrency.name,
          symbol: initialCurrency.symbolNative,
        },
        false,
      )
    }
  }, [initialCurrency, formik.setFieldValue])

  useEffect(() => {
    if (selectedCurrency && selectedCurrency !== initialCurrency) {
      formik.setFieldValue(
        'values',
        {
          code: selectedCurrency.code,
          name: selectedCurrency.name,
          symbol: selectedCurrency.symbolNative,
        },
        false,
      )
    }
  }, [selectedCurrency, initialCurrency, formik.setFieldValue])

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency)
  }

  return (
    <>
      <div className="currency-dropdown-label">{t('currency')}</div>
      <Dropdown
        trigger={['click']}
        className="currency-dropdown"
        dropdownRender={() => (
          <div className="currency-dropdown-container">
            <Search
              onSearch={setSearch}
              prefix={<SearchIcon />}
              value={search}
              className="custom-fields-modal-dashbord-navbar-search-input"
              variant="underlined"
            />
            <div className="currency-dropdown-container-content">
              {filteredCurrencies.map((item, index) => (
                <div
                  key={index}
                  className="currency-dropdown-item"
                  onClick={() => handleCurrencySelect(item)}
                >
                  <div>{item.code}</div>-<div>{item.name} </div>
                  <span>({item.symbolNative})</span>
                </div>
              ))}
            </div>
          </div>
        )}
      >
        <div className="custom-fields-drawer-under-header-dropdown-item">
          <div className="dropdpwn-view">
            <div className="currency-dropdown-item">
              <div>{selectedCurrency?.code}</div>-<div>{selectedCurrency?.name} </div>
              <span>({selectedCurrency?.symbolNative})</span>
            </div>
          </div>
          <ArrowBottomIcon />
        </div>
      </Dropdown>
    </>
  )
}

export default Money
