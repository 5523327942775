import CustomFieldsTableHeader from '@src/modules/customFields/components/CustomFieldsTable/CustomFieldsTableHeader'
import CustomFieldListColumns from '@src/modules/customFields/components/CustomFieldsTable/CustomFieldsListColumn'

const CustomFieldsTable = ({
  openDrawer,
  CustomFieldData,
  setOpenDrawer,
  setCustomFieldData,
  setType,
  setCustomFieldsDrawerData,
  setPage,
  isLoading,
  isFetching,
  page,
  sort,
  setSort,
}) => {
  return (
    <div className={`custom-fields-modal-dashbord-table-container`}>
      <div className={`custom-fields-modal-dashbord-table`}>
        <div className="list-header">
          <CustomFieldsTableHeader sort={sort} setSort={setSort} />
        </div>
        <CustomFieldListColumns
          canOnlyView={false}
          CustomFieldData={CustomFieldData}
          localUpdateTask={(e) => console.log('localUpdate', e)}
          openDrawer={openDrawer}
          setCustomFieldData={setCustomFieldData}
          setPage={setPage}
          setOpenDrawer={setOpenDrawer}
          setType={setType}
          isLoading={isLoading}
          isFetching={isFetching}
          setCustomFieldsDrawerData={setCustomFieldsDrawerData}
          page={page}
        />
      </div>
    </div>
  )
}
export default CustomFieldsTable
