export const reformulateDate = (inputDate: string | Date, date?: string) => {
  const parsedDate = new Date(inputDate)

  const lang = localStorage.getItem('i18nextLng')

  const monthNames = [
    lang === 'ar' ? 'جانفي' : lang === 'fr' ? 'janv' : 'Jan',
    lang === 'ar' ? 'فيفري' : lang === 'fr' ? 'Fév' : 'Feb',
    lang === 'ar' ? 'مارس' : lang === 'fr' ? 'Mar' : 'Mar',
    lang === 'ar' ? 'أفريل' : lang === 'fr' ? 'Avr' : 'Apr',
    lang === 'ar' ? 'ماي' : lang === 'fr' ? 'Mai' : 'May',
    lang === 'ar' ? 'جوان' : lang === 'fr' ? 'Juin' : 'Jun',
    lang === 'ar' ? 'جويلية' : lang === 'fr' ? 'Juil' : 'Jul',
    lang === 'ar' ? 'أوت' : lang === 'fr' ? 'Août' : 'Aug',
    lang === 'ar' ? 'سبتمبر' : lang === 'fr' ? 'Sept' : 'Sep',
    lang === 'ar' ? 'أكتوبر' : lang === 'fr' ? 'Oct' : 'Oct',
    lang === 'ar' ? 'نوفمبر' : lang === 'fr' ? 'Nov' : 'Nov',
    lang === 'ar' ? 'ديسمبر' : lang === 'fr' ? 'Déc' : 'Dec',
  ]

  const pm = lang === 'ar' ? 'مساء' : 'pm'
  const am = lang === 'ar' ? 'صباحا' : 'am'
  const at = lang === 'ar' ? 'في' : lang === 'fr' ? 'en' : 'at'

  const month = monthNames[parsedDate.getMonth()]
  const day = parsedDate.getDate()
  const hours = parsedDate.getHours()
  const minutes = parsedDate.getMinutes()
  const period = hours >= 12 ? pm : am
  const formattedHours = hours % 12 || 12

  const formattedDate = date
    ? `${month} ${day}`
    : `${month} ${day} ${at} ${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`

  const arabicFormattedDate = date
    ? `${month} ${day}`
    : ` ${day} ${month}  ${at} ${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`

  return lang === 'ar' ? arabicFormattedDate : formattedDate
}
