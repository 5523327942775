import { importDataProcess } from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import Button from '@src/modules/shared/components/Button/Button'
import Spinner from '@src/modules/shared/components/Spinner/Spinner'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import back from '../../../../assets/icons/importExport/Back.svg'
import MappLine from './components/MappLine'
import { useTranslation } from 'react-i18next'

const UserMapping = () => {
  const { t } = useTranslation(['importExport'])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [mappedUsers, setMappedUsers] = useState<any>({})
  const { importedDataProcess, status, mappedData } = useAppSelector((state) => state.importExport)
  const importedUsers: any = importedDataProcess?.users && Object.keys(importedDataProcess?.users)
  const fileKey = window.localStorage.getItem('fileKey')

  const confirm = () => {
    dispatch(importDataProcess({ key: fileKey, repair: '', mappedData }))
      .unwrap()
      .then((res) => {
        message.success(res)
        navigate('/settings/import-export')
        window.localStorage.removeItem('fileKey')
      })
  }

  useEffect(() => {
    if (importedUsers?.length === 0 && status !== 'loading') confirm()
  }, [importedUsers])

  if (status === 'loading') {
    return <Spinner />
  } else
    return (
      <div className="user_mapping">
        <img
          src={back}
          className="back_icon"
          onClick={() =>
            navigate(
              updateURL({
                label: 'view',
                value: 'inSpace',
              }),
            )
          }
        />

        <div className="user_mapping_heading">
          <h2>{t('user_mapping.title')}</h2>

          <p>{t('user_mapping.description')}</p>
        </div>

        <div className="user_mapping_content">
          <div className="content_line">
            <h3>{t('user_mapping.imported_users')}</h3>

            <h3 className="tam_users">{t('user_mapping.tam_users')}</h3>
          </div>

          <form className="users">
            {importedUsers &&
              importedUsers?.map((user, index) => {
                return (
                  <MappLine
                    key={'user' + index}
                    importedUser={user}
                    setMappedUsers={setMappedUsers}
                    mappedUsers={mappedUsers}
                  />
                )
              })}
          </form>
        </div>

        <Button className="next_btn" onClick={confirm}>
          {t('user_mapping.next')}
        </Button>
      </div>
    )
}

export default UserMapping
