import { setSpacesMappedData } from '@src/modules/settings/data/ImportExport/ImportExportSlice'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import SeacrhInput from '@src/modules/spaces/components/SeacrhInput/index'
import { Dropdown } from 'antd'
import { useState } from 'react'
import right from '../../../../assets/icons/importExport/arrow-right.svg'
import down from '../../../../assets/icons/importExport/down.svg'
import rightt from '../../../../assets/icons/importExport/right.svg'

const SpaceMapLine = ({
  importedSpace,
  setMappedSpaces,
  mappedSpaces,
}: {
  importedSpace: any
  setMappedSpaces: Function
  mappedSpaces: any
}) => {
  const { projects } = useAppSelector((state) => state.spaces)
  const dispatch = useAppDispatch()
  const [selectedSpace, setSelectedSpace] = useState<any>({ name: 'New space' })

  return (
    <div className="content_line">
      <div className="imported_space">{importedSpace}</div>

      <img src={rightt} />

      <Dropdown
        dropdownRender={() => (
          <div className="ant-dropdown-menu task-dropdown spaces-tree task-dropdown-import">
            <SeacrhInput onSearch={() => {}} icon={''} />

            <div className="space_list">
              {projects?.payload?.map((space) => {
                return (
                  <div
                    className="space_item"
                    onClick={() => {
                      setSelectedSpace(space)
                      setMappedSpaces({
                        ...mappedSpaces,
                        [importedSpace]: space?.id,
                      })
                      dispatch(setSpacesMappedData(mappedSpaces))
                    }}
                  >
                    <img src={right} />

                    {space?.icon ? (
                      <img src={space?.icon} className="space_icon" />
                    ) : (
                      <span
                        className="first_letter"
                        style={space?.color ? { background: space?.color } : {}}
                      >
                        {space?.name?.slice(0, 1)}
                      </span>
                    )}
                    {space?.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        trigger={['click']}
      >
        <div className="select_space">
          <div className="select_space_right">
            {selectedSpace?.icon ? (
              <img src={selectedSpace?.icon} className="space_icon" />
            ) : (
              <span
                className="first_letter"
                style={selectedSpace?.color ? { background: selectedSpace?.color } : {}}
              >
                {selectedSpace?.name?.slice(0, 1)}
              </span>
            )}
            <span>{selectedSpace?.name}</span>
          </div>

          <img src={down} />
        </div>
      </Dropdown>
    </div>
  )
}

export default SpaceMapLine
