import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import Avatar from '@src/modules/shared/components/Avatar/Avatar'
import ViewLayout from '@src/modules/shared/components/ViewLayout'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { RootState, useAppDispatch } from '@src/modules/shared/store'
import { message } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Settingscon } from '../../assets/icons/workspace/Settings_Future.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/workspace/Vector.svg'
import { ReactComponent as UpgradeIcon } from '../../assets/icons/workspace/carbon_upgrade.svg'
import { ReactComponent as SwitchIcon } from '../../assets/icons/workspace/left right.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/workspace/logout.svg'
import Header from '../../components/Header'
import WorkspaceSkeleton from '../../components/WorkspaceSkeleton'
import { getWorkspaces, leaveWorkspaces } from '../../data/workspaceSlice/workspaceThunk'
import Button from '@src/modules/shared/components/Button/Button'

const ids = ['6599bc23bda8ee00d6d7f39c', '65952244d634bb0ccdf57a93']

const ManageWorkspace = () => {
  const { t } = useTranslation(['workspaces'])

  const headerProps = { title: t('title') }
  const { userWorkspaces, status } = useSelector((state: RootState) => state.workspaceSetting)
  const { user } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useTitle(t('Settings - Workspaces'))

  useEffect(() => {
    dispatch(getWorkspaces())
  }, [dispatch])

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="user-workspaces-container">
        {status === 'loading' ? (
          <>
            <WorkspaceSkeleton />
            <WorkspaceSkeleton />
            <WorkspaceSkeleton />
          </>
        ) : (
          <>
            {userWorkspaces?.map(
              (
                workspace: IWorkspace & {
                  icon: string
                  color: string
                  users: any
                  tags: Array<
                    | {
                        id: string
                        name: string
                      }
                    | string
                  >
                },
                index,
              ) => (
                <div
                  className={`workspace-container ${
                    workspace?.id === user?.currentWorkspace?.workspace?.id && 'current-Workspace'
                  }`}
                  key={index}
                >
                  <div className="workspace-avatar-container">
                    <div className="avatar-name-container">
                      <Avatar
                        text={workspace?.name || 'workspace'}
                        src={workspace?.icon}
                        color={workspace?.color || '#A6A6A6'}
                      />
                      <ArabicElement tooltip content={workspace?.name} tag="span" />
                    </div>

                    <div className="action-btn-container">
                      {workspace?.id !== user?.currentWorkspace?.workspace?.id && (
                        <Button
                          onClick={() => {
                            navigate(`/select-workspace/${workspace?.id}`)
                            localStorage.setItem('changedWorkspace', 'yes')
                          }}
                          className="switch-button"
                        >
                          <SwitchIcon />
                        </Button>
                      )}

                      {workspace?.id === user?.currentWorkspace?.workspace?.id && (
                        <Button
                          className="setting-button"
                          onClick={() => navigate('/settings/workspace')}
                        >
                          <Settingscon />
                        </Button>
                      )}

                      {workspace?.userId !== user?.user?.centralId &&
                        workspace?.id === user?.currentWorkspace?.workspace?.id && (
                          <Button
                            className="setting-button"
                            onClick={() =>
                              dispatch(leaveWorkspaces())
                                .unwrap()
                                .then((res) => {
                                  if (res?.message?.toLowerCase() === 'success') {
                                    if (userWorkspaces?.length > 0) {
                                      for (const workspace of userWorkspaces) {
                                        if (
                                          workspace?.id !== user?.currentWorkspace?.workspace?.id
                                        ) {
                                          navigate(`/select-workspace/${workspace?.id}`)
                                          return
                                        }
                                      }
                                    }
                                    navigate('/workspace')
                                  }
                                })
                                .catch((error) =>
                                  message.error(error?.message || 'Something went wrong'),
                                )
                            }
                          >
                            <LogoutIcon />
                          </Button>
                        )}
                    </div>
                  </div>

                  <div className="owner-container">
                    <span className="owner-name">
                      {t('owner')}
                      {workspace.users.map(
                        (user) => user?.isOwner === true && user?.firstName + ' ' + user?.lastName,
                      )}
                    </span>
                  </div>

                  <div className="shared-user">
                    <span>{t('shared_with')}</span>

                    <div className="invited-avatar-container">
                      {workspace?.users
                        .slice(0, 3)
                        .map((user, index) => (
                          <Avatar
                            key={index}
                            text={user?.firstName || 'workspace'}
                            src={user?.avatar}
                            color={user?.color || '#A6A6A6'}
                          />
                        ))}

                      {workspace?.users.length > 3 && (
                        <div className="avatar-number">{`+${workspace?.users.length - 3}`}</div>
                      )}
                    </div>
                  </div>

                  <div className="offer-plan-container">
                    <span
                      className={`free-plan ${
                        ids?.includes(workspace?.id) ? 'business-plan' : ''
                      } `}
                    >
                      {ids?.includes(workspace?.id) ? t('buisness_premium') : t('free')}
                    </span>

                    <Button className="upgrade-plan-button">
                      <UpgradeIcon />
                      {t('upgrade_your_plan')}
                    </Button>
                  </div>
                </div>
              ),
            )}

            <Can I="create" a="workspaces">
              <Button className="add-button" onClick={() => navigate('/workspace')}>
                <AddIcon /> {t('add_new')}
              </Button>
            </Can>
          </>
        )}
      </div>
    </ViewLayout>
  )
}

export default ManageWorkspace
