import 'react-complex-tree/lib/style-modern.css'

import { ControlledTreeEnvironment, InteractionMode, Tree } from 'react-complex-tree'
import {
  bulkChildgenericfolder,
  bulkugenericfolders,
  getFolder,
} from '@src/modules/spaces/data/folderSlice/folderThunk'
import { bulklists, moveList } from '@src/modules/spaces/data/fileSlice/fileThunk'
import {
  bulkspaces,
  getSpace,
  getSpacesTree,
} from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { renderItem, renderItemProps } from './helpers/renderItem'
import { store, useAppDispatch, useAppSelector } from '../../store'
import { useEffect, useRef, useState } from 'react'
import { FolderType } from '@src/modules/spaces/components/Folder/Folder'
import { TaskSkeleton } from '@src/modules/tasks/components/EditTaskPopup/components/SubTasksTree/helpers/renderItem'
import { getActiveSpace } from '../../utils/activeSpace'
import { renderItemArrow } from './helpers/renderItemArrow'
import { resetSpacesSocketEvents, setSpaces } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { socketSpacesManagement } from './helpers/socketSpacesManagement'
import { useGetDocumentQuery } from '@src/modules/spaces/services/documentApi'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import { TreeItemType } from '@src/modules/spaces/components/Spaces/Spaces'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'

export const manageSocketUpdate = (updateData, updateInfo) => {
  const { deletedItem, updatedItem, createdItem } = updateInfo
  if (deletedItem) {
    updateData((prev) => socketSpacesManagement.deleteItem(prev, deletedItem))
  } else if (updatedItem) {
    updateData((prev) => socketSpacesManagement.updateItem(prev, updatedItem))
  } else if (createdItem) {
    updateData((prev) => socketSpacesManagement.createItem(prev, createdItem))
  }
  store.dispatch(resetSpacesSocketEvents())
}

const getSpaceChildren = async (spaceId) => {
  const response = await store.dispatch(getSpace({ id: spaceId })).unwrap()
  return response.payload
}

const getFolderChildren = async (folderId) => {
  const response = await store.dispatch(getFolder({ id: folderId })).unwrap()
  return response.payload
}

export enum treeSupportedTypes {
  SPACE = 'space',
  FOLDER = 'folder',
  LIST = 'list',
  DOCUMENT = 'document',
}

const allowedTypeForSelect = [
  treeSupportedTypes.LIST,
  treeSupportedTypes.DOCUMENT,
  treeSupportedTypes.SPACE,
  treeSupportedTypes.FOLDER,
]

interface SpacesTreeProps {
  multiple?: boolean
  onSelectList?: (items, contetn?) => void
  onSelectSpace?: (items, contetn?) => void
  onSelectFolder?: (items, contetn?) => void
  onSelectDocument?: (items) => void
  drag?: boolean
  search?: string
  noDocumentView?: boolean
  readOnly?: boolean
  autoOpen?: boolean
  item?: TreeItemType
}

const isCurrentType = (itemType, correctType) => {
  return itemType === correctType
}

export const addLoading = (item, setTree) => {
  setTree((tree) => ({ ...tree, [item?.id]: { ...tree[item?.id], isLoading: true } }))
}

export const removeLoading = (item, setTree) => {
  setTree((tree) => ({ ...tree, [item?.id]: { ...tree[item?.id], isLoading: false } }))
}

const SpacesTree = ({
  multiple = false,
  onSelectList,
  onSelectSpace,
  onSelectFolder,
  onSelectDocument,
  noDocumentView = false,
  readOnly = false,
  drag = false,
  item = { id: null, type: null },
  autoOpen = false,
}: SpacesTreeProps) => {
  const { updatedItem, deletedItem, createdItem } = useAppSelector((state) => state.spaces)
  const treeRef = useRef<any>()
  const expandeAll = false
  const activeSpace = getActiveSpace()

  const { t } = useTranslation(['modals'])

  const { data: list } = useGetListQuery(
    { id: item.id },
    { skip: !isCurrentType(item.type, treeSupportedTypes.LIST || !item.id) },
  )
  const { data: document } = useGetDocumentQuery(item.id, {
    skip: !isCurrentType(item.type, treeSupportedTypes.DOCUMENT),
  })
  const [loading, setLoading] = useState<boolean>(false)

  const dataByType = {
    [treeSupportedTypes.LIST]: list,
    [treeSupportedTypes.DOCUMENT]: document,
  }
  const dispatch = useAppDispatch()
  const [tree, setTree] = useState<any>({ root: {} })
  const [focusedItem, setFocusedItem] = useState()
  const [expandedItems, setExpandedItems] = useState([])
  const [selectedItems, setSelectedItems] = useState<string[]>(autoOpen ? [item.id] : [])
  useEffect(() => {
    if (activeSpace && !item.id) {
      setExpandedItems([activeSpace])
      setSelectedItems([])
    }
  }, [activeSpace])

  useEffect(() => {
    if (createdItem && createdItem?.type === 'folders') {
      setExpandedItems((prev) => [...prev, createdItem?.spaceId])
    }
    manageSocketUpdate(setTree, { updatedItem, deletedItem, createdItem })
  }, [updatedItem, deletedItem, createdItem])

  useEffect(() => {
    setLoading(true)
    dispatch(getSpacesTree({}))
      .unwrap()
      .then((res) => {
        let spaces = res?.payload
        spaces = spaces.sort((a, b) => a.order - b.order)
        dispatch(setSpaces(spaces))
        const treeSpaces = convertToTemplateTree(spaces, 'space')
        const spacesIds = Object.keys(treeSpaces)
        const newTree = {
          ...tree,
          root: { ...tree?.root, children: spacesIds },
          ...treeSpaces,
        }

        setTree(newTree)
        expandManagement(newTree)
        // for expande all
        if (expandeAll) {
          setExpandedItems(spacesIds)
        } else if (activeSpace && !item.id) setExpandedItems([activeSpace])
      })
      .finally(() => setLoading(false))
  }, [])

  const expandManagement = (tree) => {
    expandedItems?.forEach(async (itemId) => {
      const item = tree[itemId]
      if (item?.children) return
      addLoading(item, setTree)
      let itemChilds = null

      if (item?.type === 'space') {
        const response = await getSpaceChildren(itemId)
        const documents = noDocumentView ? [] : response?.documents || []

        itemChilds = {
          ...convertToTemplateTree(
            response?.folders?.map((folder: FolderType) => ({ ...folder, space: item?.content })),
            'folder',
          ),
          ...convertToTemplateTree(response?.list, 'list'),
          ...convertToTemplateTree(documents, 'document'),
        }
      } else if (item?.type === 'folder') {
        const response = await getFolderChildren(itemId)
        const documents = noDocumentView ? [] : response?.documents || []

        itemChilds = {
          ...convertToTemplateTree(response?.list, 'list'),
          ...convertToTemplateTree(documents, 'document'),
        }
      }

      const childsIds = Object.keys(itemChilds || {})
      setTree((tree) => ({
        ...tree,
        [itemId]: { ...tree[itemId], children: childsIds },
        ...itemChilds,
      }))
      removeLoading(item, setTree)
    })
  }

  const selectItems = (items: string[]) => {
    const { type, content } = tree[items[items?.length - 1]]
    if (!allowedTypeForSelect.includes(type)) return
    // if (!multiple) {
    if (type === treeSupportedTypes.LIST) onSelectList?.(items, content)
    else if (type === treeSupportedTypes.SPACE) onSelectSpace?.(items, content)
    else if (type === treeSupportedTypes.FOLDER) onSelectFolder?.(items, content)
    else if (type === treeSupportedTypes.DOCUMENT) onSelectDocument?.(items)

    // }
    setSelectedItems(items)
  }

  const getCurrentDataByType = (currentType: string) => dataByType[currentType]

  useEffect(() => {
    expandManagement(tree)
  }, [expandedItems])

  const dropEndEvent = (dragItems, target) => {
    const item = dragItems[0]
    const spaceId = item?.content?.spaceId
    const parent: any = Object.values(tree).find((potentialParent: any) =>
      potentialParent.children?.includes(item.index),
    )

    if (
      item?.type !== 'space' &&
      target?.parentItem === 'root' &&
      target?.targetType === 'between-items'
    ) {
      console.log('only space can be in the root')
      return
    }

    if (
      item?.type === 'space' &&
      !(target?.parentItem === 'root' && target?.targetType === 'between-items')
    ) {
      console.log('space only drop in the root')
      return
    }

    const privious = tree[parent?.children[target?.childIndex - 1]]
    const next = tree[parent?.children[target?.childIndex]]

    if (next?.type === 'folder' && privious?.type === 'folder' && item?.type === 'list') {
      console.log('list should not be between two folders')
      return
    }

    if (
      (next?.type === 'list' && privious?.type === 'list' && item?.type === 'folder') ||
      (privious?.type === 'list' && item?.type === 'folder')
    ) {
      console.log('list should not be between two lists')
      return
    }

    if (
      (privious && privious?.content?.spaceId !== spaceId) ||
      (next && next?.content?.spaceId !== spaceId)
    ) {
      console.log(
        'drag and drop only allowed in space 1',
        privious && privious?.content?.spaceId !== spaceId,
      )
      return
    }

    if (target.targetType === 'item' || target.targetType === 'root') {
      if (target.targetItem === parent.index) {
        // NOOP
      } else {
        if (tree[target.targetItem]?.type === 'folder' && item.type === 'folder') {
          console.log('nested folder in not supported')
          return
        }

        if (!expandedItems?.includes(target.targetItem)) {
          treeRef.current.expandItem(target.targetItem)
          return
        }

        console.log('drop in', tree[target.targetItem]?.type)
        if (
          tree[target.targetItem]?.type === 'space' &&
          tree[target.targetItem]?.index !== spaceId
        ) {
          console.log('drag and drop only allowed in space 2')
          return
        }
        setTree((prev) => ({
          ...prev,
          [parent.index]: {
            ...prev[parent.index],
            children: parent.children.filter((child) => child !== item.index),
          },
          [target.targetItem]: {
            ...prev[target.targetItem],
            children: [...(tree[target.targetItem].children ?? []), item.index],
          },
        }))

        dispatch(moveList({ listId: item?.index, folderId: tree[target.targetItem]?.index }))
          .unwrap()
          .catch(() => {
            message.error('Error while moving list')
          })
      }
    } else {
      const newParent = tree[target.parentItem]
      let newParentChildren = [...(newParent.children ?? [])].filter(
        (child) => child !== item.index,
      )

      if (target.parentItem === item.index) {
        // Trying to drop inside itself
        return
      }
      if (newParent.type === 'folder' && item.type === 'folder') {
        console.log('nested folder in not supported')
        return
      }

      if (newParent?.id && !expandedItems?.includes(newParent?.id)) {
        treeRef.current.expandItem(newParent?.id)
        return
      }

      if (target.parentItem === parent.index) {
        const isOldItemPriorToNewItem =
          ((newParent.children ?? []).findIndex((child) => child === item.index) ?? Infinity) <
          target.childIndex
        newParentChildren.splice(
          target.childIndex - (isOldItemPriorToNewItem ? 1 : 0),
          0,
          item.index,
        )

        setTree((prev) => ({
          ...prev,
          [target.parentItem]: { ...prev[target.parentItem], children: newParentChildren },
        }))
        if (item?.type === 'folder') {
          newParentChildren = newParentChildren?.filter((id) => tree[id]?.type === 'folder')
          const newFolders = {
            folders: newParentChildren?.map((id, i) => ({
              id,
              order: tree[id]?.content?.order || 0,
              newOrder: i,
            })),
            spaceId: tree[target.parentItem]?.index,
          }
          dispatch(bulkugenericfolders(newFolders))
        } else if (item?.type === 'list') {
          const parentKey = tree[target.parentItem]?.type === 'space' ? 'spaceId' : 'folderId'
          newParentChildren = newParentChildren?.filter((id) => tree[id]?.type === 'list')

          const newLists = {
            lists: newParentChildren?.map((id, i) => ({
              id,
              order: tree[id]?.content?.order || 0,
              newOrder: i,
            })),
            [parentKey]: tree[target.parentItem]?.index,
          }
          if (parentKey === 'spaceId') dispatch(bulklists(newLists))
          else dispatch(bulkChildgenericfolder(newLists))
        }
      } else {
        if (
          (tree[target.targetItem] &&
            tree[target.parentItem]?.type === 'space' &&
            tree[target.targetItem]?.index !== spaceId) ||
          (tree[target.targetItem] &&
            tree[target.parentItem]?.type === 'folder' &&
            tree[target.targetItem]?.index !== spaceId)
        ) {
          console.log('drag and drop only allowed in space ')
          return
        }

        newParentChildren.splice(target.childIndex, 0, item.index)
        setTree((prev) => ({
          ...prev,
          [parent.index || 'root']: {
            ...prev[parent.index],
            children: parent.children.filter((child) => child !== item.index),
          },
          [target.parentItem]: { ...prev[target.parentItem], children: newParentChildren },
        }))
        if (!parent.index) {
          const newSpaces = newParentChildren?.map((spaceId, i) => ({
            id: spaceId,
            order: tree[spaceId]?.content?.order,
            newOrder: i,
          }))

          dispatch(bulkspaces({ spaces: newSpaces }))
        } else if (target.parentItem)
          dispatch(
            moveList({
              listId: item?.index,
              [tree[target.parentItem]?.type === 'folder' ? 'folderId' : 'spaceId']:
                target.parentItem,
            }),
          )
            .unwrap()
            .catch(() => {
              message.error('Error while moving list')
            })
      }
    }
  }

  useEffect(() => {
    item.id && setSelectedItems([item.id] as string[])
  }, [item.id])

  if (loading)
    return (
      <div className="space-tree-loading">
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
      </div>
    )

  if (tree?.root?.children?.length === 0)
    return (
      <div className="empty-tree">
        <p>{t('space.No spaces found')}</p>
      </div>
    )

  return (
    <div className="space-tree" onKeyDown={(e) => e.stopPropagation()}>
      <ControlledTreeEnvironment
        ref={treeRef}
        items={tree}
        getItemTitle={(item) => item.data}
        viewState={{
          ['tree-1']: {
            focusedItem,
            expandedItems,
            selectedItems,
          },
        }}
        renderTreeContainer={({ containerProps, children }) => {
          return (
            <div className={drag || readOnly ? '' : 'tree-container'} {...containerProps}>
              {children}
            </div>
          )
        }}
        defaultInteractionMode={InteractionMode.ClickItemToExpand}
        onDrop={dropEndEvent}
        canDropOnNonFolder={false}
        canDragAndDrop={drag}
        canDropOnFolder={true}
        canReorderItems={true}
        renderItemArrow={renderItemArrow}
        onFocusItem={(item: any) => setFocusedItem(item.index)}
        onExpandItem={(item) => {
          if (item) setExpandedItems([...expandedItems, item.index])
        }}
        onCollapseItem={(item) =>
          setExpandedItems(
            expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index),
          )
        }
        renderItem={(props) =>
          renderItem({
            ...props,
            tree,
            multiple,
            readOnly,
            autoOpen,
            activeItem: getCurrentDataByType(item.type),
          } as renderItemProps)
        }
        canSearch={false}
        canRename={false}
        onSelectItems={selectItems}
      >
        <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
      </ControlledTreeEnvironment>
    </div>
  )
}

export default SpacesTree

export const convertToTemplateTree = (items: any = [], type: string) => {
  let data = {}
  items?.forEach((item) => {
    data[item?.id] = {
      index: item?.id,
      id: item?.id,
      parentId: item?.folderId || item?.spaceId,
      canMove: true,
      type,
      isFolder: type !== 'list',
      children: undefined,
      data: item?.id,
      content: item,
      canRename: true,
      halfSelect: false,
    }
  })

  return data
}
