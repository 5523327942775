import { useGetDocumentsQuery } from '@src/modules/documents/services/documents'
import {
  MembersListApi,
  useGetMembersListQuery,
} from '@src/modules/settings/services/membersListApi'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import type { TabsProps } from 'antd'
import { Avatar, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { setSelectedUsersIds } from '../../../BlockNote/Data/slices'
import Search from '../../../Input/search'

export type MentionedElem = {
  type: string
  value: string
  id: string
  link: string
}

type MentionListProps = {
  editor?: any
  setIsMention?: Function
}
const insertMention = (editor, user, currentUser) => {
  const isCurrentUser = user?.id === currentUser?.id

  const mention = {
    type: 'mention_tam',
    children: [
      {
        type: 'p',
        text: `@${user?.fullName || user?.email} `,
        user_id: user?.id,
        email: user?.fullName || user?.email,
        fullName: isCurrentUser ? currentUser?.fullName : user?.fullName,
        avatar: user?.avatar,
        isActive: user?.isActive,
      },
    ],
  }
  editor.deleteForward(3)
  editor.deleteBackward(3)
  editor.insertNode(mention)
}
const insertDocMention = (editor, document) => {
  const mention = {
    type: 'mention_doc_tam',
    children: [
      {
        text: '',
        id: document?.id,
      },
    ],
  }
  editor.deleteForward(3)
  editor.deleteBackward(3)
  editor.insertNode(mention)
}
//TODO
// const MemberSkeleton = () => {
//   return (
//     <div className="member-skeleton">
//       {Array(3)
//         .fill(1)
//         .map((_, i) => (
//           <div className="skeleton-line" key={i}>
//             <Skeleton.Avatar active={true} />
//             <Skeleton.Input
//               active={true}
//               style={{ height: '20px', width: '250px', borderRadius: '8px' }}
//             />
//           </div>
//         ))}
//     </div>
//   )
// }
const reformulateMentionsList = (users = [], search = '', currentUser) => {
  const { t } = useTranslation(['document'])

  const userName = currentUser?.fullName || currentUser?.email
  const currentUserExist = userName?.toLowerCase().includes(search?.toLowerCase())
  currentUser = { ...currentUser, fullName: t('Me') }

  const filteredUsers = users?.filter((user) => user?.id !== currentUser?.id)

  return [...(currentUserExist ? [currentUser] : []), ...(filteredUsers || [])]
}

const MentionsList = ({ editor }: MentionListProps) => {
  const { t } = useTranslation(['document'])

  const dispatch = useAppDispatch()
  const { documentId, listId } = useParams()
  const { selectedUsersIds } = useAppSelector((state) => state.editor)
  const [usersPage, setUsersPage] = useState(1)
  const [documentsPage, setDocumentsPage] = useState(1)
  const [usersSearch, setUsersSearch] = useState('')
  const [documentSearch, setDocumentSearch] = useState('')
  const [documentsList, setDocumentList] = useState([])
  const [usersList, setUserList] = useState<any>([])

  const { data: documents } = useGetDocumentsQuery({
    limit: 10,
    skip: documentsPage,
  })
  const { user } = useAppSelector((state) => state.auth)
  const currentUser = user?.user
  const { data: usersData } = useGetMembersListQuery({
    limit: 10,
    skip: usersPage,
    search: usersSearch,
    listId,
    documentId,
  })
  useEffect(() => {
    setDocumentList(documents?.payload)
  }, [documents])
  useEffect(() => {
    setUserList(usersData)
  }, [usersData])

  const updatedUsersList = reformulateMentionsList(usersData?.payload, usersSearch, currentUser)

  useEffect(() => {
    dispatch(
      MembersListApi.util.updateQueryData('getMembersList', undefined, (draft) => {
        if (draft?.payload) draft.payload = []
        return draft
      }),
    )
  }, [usersSearch])

  const loadMoreUsers = () => {
    if (usersList?.metadata?.last_page >= usersPage || usersList?.metadata?.next?.page) {
      setUsersPage((prev) => prev + 1)
    }
  }

  const loadMoreDocuments = () => {
    if (documents?.metadata?.next?.page) setDocumentsPage((prev) => prev + 1)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Persons'),
      children: (
        <>
          <div className="input-search-container">
            <Search
              placeholder={t('search_for_persons')}
              value={usersSearch}
              onSearch={(s: string) => setUsersSearch(s)}
              prefix={<SearchIcon />}
              variant="filled"
            />
          </div>

          <InfiniteScroll
            dataLength={usersList?.payload?.length || 0}
            next={loadMoreUsers}
            hasMore={usersList?.metadata?.next}
            loader={false}
            height={'250px'}
            className="users-list"
          >
            {updatedUsersList?.map((user, index: number) => (
              <div
                className="user-line"
                key={user?.id + index}
                onClick={() => {
                  insertMention(editor, user, currentUser)

                  dispatch(setSelectedUsersIds([...selectedUsersIds, user?.id]))
                }}
              >
                <Avatar src={user?.avatar} className="user-line-avatar">
                  {user?.fullName?.slice(0, 1)?.toUpperCase() ||
                    user?.email?.slice(0, 1)?.toUpperCase()}
                </Avatar>
                <span>{user?.fullName || user?.email}</span>
              </div>
            ))}
          </InfiniteScroll>
          {/* )} */}
        </>
      ),
    },
    {
      key: '2',
      label: t('Documents'),
      children: (
        <>
          <div className="input-search-container">
            <Search
              placeholder={t('Search for documents...')}
              value={documentSearch}
              onSearch={(s: string) => setDocumentSearch(s)}
              prefix={<SearchIcon />}
              variant="filled"
            />
          </div>

          <InfiniteScroll
            dataLength={documentsList?.length || 0}
            next={loadMoreDocuments}
            hasMore={documents?.metadata?.next}
            loader={false}
            height={'250px'}
            className="documents-list"
          >
            {documentsList?.map((document, key: number) => (
              <span
                className="document-line"
                key={document?.id + key}
                onClick={() => {
                  insertDocMention(editor, document)
                }}
              >
                <span className="icon">{document?.icon}</span> {document?.name}
              </span>
            ))}
          </InfiniteScroll>
        </>
      ),
    },
    //TODO
    // {
    //   key: '3',
    //   label: 'Tasks',
    //   children: (
    //     <>
    //       <InputSearch placeholder="Search for tasks..." value="" />
    //       <div className="tasks-list">tasks list</div>
    //     </>
    //   ),
    // },
  ]

  return (
    <div className="mention-list">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  )
}

export default MentionsList
