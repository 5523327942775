import en from '../../locales/en'
import ar from '../../locales/ar'
import fr from '../../locales/fr'

const lang = localStorage.getItem('i18nextLng')
const data = lang === 'ar' ? ar : lang === 'fr' ? fr : en

export const ThemeColors = [
  '#A6A6A6',
  '#DC7400',
  '#FBAC54',
  '#FFE976',
  '#C1B332',
  '#97D856',
  '#3FABE8',
  '#96D9FF',
]
export const categories = [
  data.workspaces.Personal_productivity,
  data.workspaces.IT_services,
  data.workspaces.Software_development,
  data.workspaces.Design,
  data.workspaces.Marketing_and_advertising,
  data.workspaces.Education,
  data.workspaces.Healthcare,
  data.workspaces.Sales_and_CRM,
  data.workspaces.Event_planning,
  data.workspaces.Research_and_academia,
  data.workspaces.Engineering,
  data.workspaces.Retail_and_E_commerce,
  data.workspaces.Travel_and_hospitality,
  data.workspaces.Business_and_management,
  data.workspaces.Other,
]
export const options = [
  data.workspaces.Only_me,
  '2 - 10',
  '11 - 30',
  '31 - 60',
  '61 - 100',
  '101 - 200',
  '201 - 500',
  '+ 500',
  data.workspaces.I_don_t_know,
]
