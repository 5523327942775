import { Modal } from 'antd'
import CloseIcon from '../icons/CloseIcon'
import { ReactComponent as DownloadSVG } from '@src/modules/shared/assets/icons/components/Download_duotone_line.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { useTranslation } from 'react-i18next'

type Props = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  downloadLink: string
}

function ExportModal({ isModalOpen, handleOk, handleCancel, downloadLink }: Props) {
  const { t } = useTranslation(['modals'])

  const ModalFooter = () => {
    return (
      <div className="import-modal-footer">
        <Button className="cancel" onClick={handleCancel}>
          {t('export.cancel')}
        </Button>

        <Button
          className={`ok ${downloadLink === null || downloadLink === undefined ? 'disabled' : ''}`}
          onClick={handleOk}
          disabled={downloadLink === null || downloadLink === undefined}
        >
          {t('export.download')}
        </Button>
      </div>
    )
  }

  return (
    <div className="import-modal">
      <Modal
        centered
        title={<h1 className="import-modal-title">{t('export.export_data')}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<ModalFooter />}
        closeIcon={<CloseIcon />}
      >
        <div className="export-modal-container">
          <DownloadSVG />
          <p>
            {downloadLink !== null && downloadLink !== undefined
              ? t('export.ready_for_download')
              : t('export.preparing_all_data')}
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default ExportModal
