import { api } from '@src/modules/shared/services/api'

// interface getNotificationPayload {
//   fileId: string
//   statusId?: string
//   skip?: number
//   limit?: number
//   taskId?: string
// }

export const NotificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query<any, any>({
      query: (params) => {
        const { taskId, ...rest } = params
        return {
          url: `api/tasks/${taskId}/history`,
          rest,
        }
      },
      transformResponse: (response: { payload: any }): any => {
        return response
      },
    }),

    getDescriptionHistory: build.query<any, any>({
      query: (params) => {
        const { taskId, ...rest } = params

        return {
          url: `api/tasks/${taskId}/description`,
          rest,
        }
      },
      transformResponse: (response: { payload: any }): any => {
        return response
      },
    }),
  }),
})

// export const updateActivity = (notificvation: any, query: getNotificationPayload) => {
//   return NotificationApi.util.updateQueryData('getNotification', query, (draftNotification) => {
//     return {
//       new: notificvation,
//       ...draftNotification,
//     }
//   })
// }

// export const removeDeletedComment = (commentId: string, query: getNotificationPayload) => {
//   return NotificationApi.util.updateQueryData('getNotification', query, (draftNotification) => {
//     const deletedCommentIndex = draftNotification.findIndex((notification: any) => {
//       return notification.type === 'comment' && notification.coment.id === commentId
//     })
//     if (deletedCommentIndex !== -1) {
//       draftNotification.splice(deletedCommentIndex, 1)
//     }

//     return draftNotification
//   })
// }

// export const removeDeletedComment = (commentId: string, query: getNotificationPayload) => {
//   return NotificationApi.util.updateQueryData('getNotification', query, (draftNotification) => {
//     const updatedNotifications = draftNotification.filter((notification: any) => {
//       if (notification.type === 'comment' && notification.commentId === commentId) {
//         return false
//       }
//       return true
//     })

//     return updatedNotifications
//   })
// }

export const { useGetHistoryQuery, useGetDescriptionHistoryQuery } = NotificationApi
