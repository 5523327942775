const baseLayoutsSettings = {
  lg: {
    1: { x: 0, y: 0, w: 34, h: 17 },
    2: { x: 35, y: 0, w: 47, h: 17 },
    3: { x: 83, y: 9, w: 68, h: 20.5 },
    4: { x: 0, y: 16, w: 47, h: 28.4 },
    5: { x: 48, y: 16, w: 33.4, h: 28.4 },
    6: { x: 83, y: 19.5, w: 68, h: 25 },
  },
  md: {
    1: { x: 0, y: 0, w: 77, h: 17 },
    2: { x: 78, y: 0, w: 75, h: 17 },
    3: { x: 0, y: 0, w: 77, h: 25 },
    4: { x: 110, y: 16, w: 75, h: 25 },
    5: { x: 78, y: 16, w: 75, h: 29 },
    6: { x: 0, y: 0, w: 77, h: 29 },
  },
  sm: {
    1: { x: 0, y: 0, w: 154, h: 17 },
    2: { x: 78, y: 0, w: 154, h: 17 },
    3: { x: 0, y: 0, w: 154, h: 25 },
    4: { x: 110, y: 16, w: 154, h: 25 },
    5: { x: 78, y: 16, w: 154, h: 29 },
    6: { x: 0, y: 0, w: 154, h: 29 },
  },
}

export const dashboardLayouts = ({ availableHandles, items }: any) => {
  const layouts = {}

  Object.keys(baseLayoutsSettings).forEach((key) => {
    const setting = baseLayoutsSettings[key]
    const generatedLayouts = []

    Object.keys(items).forEach((itemKey, i) => {
      generatedLayouts[i] = {
        i: itemKey,
        x: setting[itemKey].x,
        y: setting[itemKey].y,
        w: setting[itemKey].w,
        h: setting[itemKey].h,
        item: items[itemKey],
        resizeHandles: availableHandles,
      }
    })

    layouts[key] = generatedLayouts
  })

  return { layouts, layout: layouts['lg'] }
}
