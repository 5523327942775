import { defaultProps } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import {
  setIframeElement,
  setIframeHeight,
} from '@src/modules/document/data/documentSlice/documentSlice'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { Dropdown, Switch } from 'antd'
import { useEffect, useRef, useState } from 'react'
import GridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { useTranslation } from 'react-i18next'
import 'react-resizable/css/styles.css'
import darkEmbed from '../../../../../shared/assets/icons/blockNote/darkEmbed.svg'
import dots from '../../../../../shared/assets/icons/blockNote/dots.svg'
import embed from '../../../../../shared/assets/icons/blockNote/embed.svg'
import iconError from '../../../../../shared/assets/icons/blockNote/error.svg'
import eye from '../../../../../shared/assets/icons/blockNote/eye.svg'
import open from '../../../../../shared/assets/icons/blockNote/open.svg'
import Button from '../../../Button/Button'

const IframeBlock = createReactBlockSpec(
  {
    type: 'iFrame',
    propSchema: {
      ...defaultProps,
      link: { default: '' },
      height: { default: 0 },
    },
    content: 'none',
  },
  {
    render: ({ block }: any) => {
      const { t } = useTranslation(['document'])
      const className = block.props.class || 'embed-container'
      const link = block.props.link
      const BlockHeight = block.props.height

      const dispatch = useAppDispatch()
      const iframeRef = useRef<string>('')
      const [iframeLink, setIframeLink] = useState<string>(link || '')
      const [isError, setIsError] = useState<boolean>(false)
      const [height, setHeight] = useState(BlockHeight || 2)
      const [layout, setLayout] = useState([{ i: 'item', x: 0, y: 0, w: 1, h: height }])

      useEffect(() => {
        if (iframeLink) dispatch(setIframeElement({ link: iframeLink }))
      }, [iframeLink])

      useEffect(() => {
        if (height) dispatch(setIframeHeight(height))
      }, [height])

      const onSwitchChange = (checked: boolean) => {
        console.log(`switch to ${checked}`)
      }

      const openSite = () => {
        iframeLink && window.open(iframeLink, '_blank')
      }

      const onInputChange = (e) => {
        iframeRef.current = e.target.value
        if (
          iframeRef.current !== '' &&
          !/^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/.test(iframeRef.current)
        ) {
          setIsError(true)
        } else setIsError(false)
      }

      const onLayoutChange = (newLayout) => {
        setHeight(newLayout[0].h)
        setLayout(newLayout)
      }

      return (
        <div className={`${className} iframe  `} style={!iframeLink ? { height: '60px' } : {}}>
          <div className="iframe-top">
            <div className="embed-container-left">
              {!iframeLink && <span>{t('Paste Website Link :')}</span>}

              {!iframeLink && (
                <input
                  type="text"
                  placeholder="Paste or type URL here..."
                  defaultValue={iframeLink || ''}
                  onChange={onInputChange}
                />
              )}
            </div>

            <div className="embed-container-right">
              {isError && (
                <span className="error">
                  <img src={iconError} /> {t('Unrecognized URL')}
                </span>
              )}

              {iframeLink ? (
                <div className="settings-embed">
                  <img src={open} className="open-icon" onClick={openSite} />

                  <Dropdown
                    className={className}
                    dropdownRender={() => (
                      <div className="dropdown-render-settings">
                        <span>
                          <img src={eye} /> {t('Show preview')}
                          <Switch size="small" onChange={onSwitchChange} />
                        </span>
                        <div className="divider"></div>
                        <span>
                          <img src={darkEmbed} /> {t('Copy link to block')}
                        </span>

                        <span>
                          <img src={darkEmbed} /> {t('Copy link to original')}
                        </span>
                      </div>
                    )}
                    trigger={['click']}
                  >
                    <img src={dots} />
                  </Dropdown>
                </div>
              ) : (
                <Button
                  className="btn-embed"
                  style={isError ? { cursor: 'not-allowed' } : {}}
                  disabled={isError}
                  onClick={() => !isError && setIframeLink(iframeRef.current)}
                >
                  <img src={embed} /> {t('Embed')}
                </Button>
              )}
            </div>
          </div>

          {iframeLink && (
            <GridLayout
              className="iframe-bottom"
              layout={layout}
              onLayoutChange={onLayoutChange}
              isResizable={true}
            >
              <div key={'item'} className="iframe-content">
                <iframe src={iframeLink}></iframe>
              </div>
            </GridLayout>
          )}
        </div>
      )
    },
  },
)

export default IframeBlock
