export function formatTimerTime(input: number | string | undefined | null): string {
  if (input === undefined || input === null) {
    return '00:00:00'
  }

  let totalSeconds: number

  if (typeof input === 'number') {
    totalSeconds = Math.floor(input)
  } else if (typeof input === 'string') {
    const parsedInput = parseFloat(input)
    if (isNaN(parsedInput)) {
      return '00:00:00'
    }
    totalSeconds = Math.floor(parsedInput)
  } else {
    return '00:00:00'
  }

  if (totalSeconds < 0 || !isFinite(totalSeconds)) {
    return '00:00:00'
  }

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return [hours, minutes, seconds].map((val) => val.toString().padStart(2, '0')).join(':')
}
