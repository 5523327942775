import DocsLayout from '@src/modules/shared/layout/DocsLayout/DocsLayout'
import routes, { renderRoutes } from '@src/modules/shared/routes'
import { useAppSelector } from '@src/modules/shared/store'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

const devMode = process.env.NODE_ENV === 'dev'

const App = () => {
  const { i18n } = useTranslation('translation')
  const { user } = useAppSelector((state) => state.auth)
  document.body.dir = i18n?.dir()

  console.log = devMode ? () => {} : console.log

  useEffect(() => {
    const language = user?.user?.lang || localStorage.getItem('i18nextLng')
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [i18n, user?.user?.lang])

  const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')

  const themeSystem = darkThemeMq.matches ? 'dark' : 'light'

  useEffect(() => {
    document.body.id = user?.user?.theme || themeSystem || 'light'
    localStorage.setItem('theme', document.body.id)
  }, [user?.user?.theme, themeSystem])

  window.addEventListener('storage', () => {
    if (!localStorage.getItem('access_token') && !window.location.href.includes('login')) {
      window.location.href = '/login'
    }
  })

  const {
    screenRecording: { isRecording },
  } = useAppSelector((state) => state.settings)

  useEffect(() => {
    window.onbeforeunload = isRecording && confirmExit
    function confirmExit() {
      return 'show warning'
    }
  }, [isRecording])
  if (window.location.host.replace('www.', '').split('.')[0] === 'docs') {
    return (
      <Routes>
        <Route path={'/'} element={<DocsLayout />}></Route>
        <Route path={'/:documentId'} element={<DocsLayout />}></Route>
      </Routes>
    )
  }

  return <div>{renderRoutes(routes)} </div>
}

export default App
