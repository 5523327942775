import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import { useRef, useState } from 'react'
import { uploadMultipleFiles } from '@src/modules/shared/components/BlockNote/utils/uploadFileBlockNote'
import { Dropdown, message, Spin, Tooltip } from 'antd'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { ReactComponent as AttachmentSVG } from '../../../assets/icons/customFields/attachment.svg'
import { ReactComponent as RemoveSVG } from '../../../assets/icons/customFields/remove.svg'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { isJsonString } from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'

interface IFileFieldProps extends BaseFieldProps {
  customFieldValue: string
}

const FileField = ({ task, field, saveCustomFieldValue, customFieldValue }: IFileFieldProps) => {
  const fileInputRef = useRef(null)
  const [uploading, setUploading] = useState<boolean>(false)
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const parsedValue = field.custom
    ? isJsonString(customFieldValue)
      ? JSON.parse(customFieldValue)
      : customFieldValue
    : task[field.value]

  const extraFiles = parsedValue && parsedValue?.length > 2 ? parsedValue?.length - 2 : 0

  const handleClick = (e) => {
    e.stopPropagation()
    if (!parsedValue || parsedValue?.length === 0) setDropdownVisible(true)
  }

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files)
    const newFieldValue = [...(parsedValue || [])]
    const maxSizeInBytes = 2 * 1024 * 1024

    const isFileValid = files.every((file: File) => file.size <= maxSizeInBytes)
    if (!isFileValid) {
      message.error('File size exceeds 2MB')
      return
    }

    const onUpload = (uploadedFile) => {
      uploadedFile.forEach((file) => {
        const { location, rawLink, originalname, mimetype, id } = file || {}
        const value = {
          location,
          rawLink,
          originalname,
          mimetype,
          id,
        }
        newFieldValue.push(value)
      })
    }

    setUploading(true)

    try {
      await uploadMultipleFiles(files, onUpload, () => message.error('Failed to upload file')),
        saveCustomFieldValue(newFieldValue)
    } catch (error) {
      message.error('Failed to upload files')
    } finally {
      setUploading(false)
    }
  }

  const removeFile = (e, fileId: string) => {
    e.stopPropagation()
    const newFieldValue = parsedValue.filter((file) => file.id !== fileId)
    saveCustomFieldValue(newFieldValue)
  }

  const openAttachment = (e, file) => {
    e.stopPropagation()

    if (
      file?.mimetype?.includes('image') ||
      file?.mimetype?.includes('video') ||
      file?.mimetype?.includes('pdf')
    ) {
      dispatch(
        openModal({
          id: 'media-player',
          data: {
            withSwiper: true,
            attachements: [file],
          },
        }),
      )
    } else window.open(file?.location, '_blank')
  }

  return (
    <div className="list-file-field" onClick={handleClick}>
      <Spin spinning={uploading} size={'small'}>
        {parsedValue?.slice(0, 2).map((file) => {
          const isImage = file?.mimetype?.includes('image')
          return (
            <div
              key={file?.id}
              className={`file-uploaded ${!isImage ? 'file-other-types' : null}`}
              onClick={(e) => openAttachment(e, file)}
            >
              {isImage ? <img src={file?.location} /> : <AttachmentSVG width={13} height={13} />}
            </div>
          )
        })}
        <Dropdown
          trigger={['click']}
          placement={'bottomCenter'}
          dropdownRender={() => {
            return (
              <div
                className="ant-dropdown-menu custom-file-upload-dropdown"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="uploaded-files">
                  {parsedValue?.map((file) => (
                    <div key={file?.id} className="uploaded-files-item">
                      {file?.mimetype?.includes('image') && <img src={file?.location} />}
                      <OverflowTooltip onClick={(e) => openAttachment(e, file)}>
                        {file?.originalname}
                      </OverflowTooltip>
                      <Tooltip title={'Remove file'}>
                        <span onClick={(e) => removeFile(e, file?.id)}>
                          <RemoveSVG width={11} height={11} />
                        </span>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                <div className="upload-button" onClick={() => fileInputRef.current.click()}>
                  <AttachmentSVG width={15} height={15} />
                  <p>Upload File</p>
                </div>
              </div>
            )
          }}
          open={dropdownVisible}
          onOpenChange={setDropdownVisible}
        >
          <div className="add-file-button">+{extraFiles !== 0 && extraFiles}</div>
        </Dropdown>
        <input
          type="file"
          ref={fileInputRef}
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Spin>
    </div>
  )
}

export default FileField
