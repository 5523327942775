import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { priorities } from '@src/modules/spaces/__mock__'
import React, { RefAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import estimationIcon from '../../assets/icons/estimation.svg'
import timeIcon from '../../assets/icons/timer.svg'
import { Avatar, Tooltip } from 'antd'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'

const renderPriority = (p) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        d="M1 1V10.1429H12.0878C12.6229 10.1429 12.8904 10.1429 12.9529 9.98474C13.0154 9.82663 12.8201 9.64374 12.4296 9.27796L12.4296 9.27795L8.51753 5.61335C8.37146 5.47653 8.29843 5.40812 8.29843 5.32143C8.29843 5.23473 8.37146 5.16632 8.51752 5.0295L12.4296 1.36491C12.8201 0.99912 13.0154 0.816226 12.9529 0.658113C12.8904 0.5 12.6229 0.5 12.0878 0.5H1.5C1.2643 0.5 1.14645 0.5 1.07322 0.573223C1 0.646447 1 0.764298 1 1Z"
        fill={p?.color}
      />
      <path
        d="M1 10.1429V1C1 0.764298 1 0.646447 1.07322 0.573223C1.14645 0.5 1.2643 0.5 1.5 0.5H12.0878C12.6229 0.5 12.8904 0.5 12.9529 0.658113C13.0154 0.816226 12.8201 0.99912 12.4296 1.36491L8.51752 5.0295C8.37146 5.16632 8.29843 5.23473 8.29843 5.32143C8.29843 5.40812 8.37146 5.47653 8.51753 5.61335L12.4296 9.27795C12.8201 9.64374 13.0154 9.82663 12.9529 9.98474C12.8904 10.1429 12.6229 10.1429 12.0878 10.1429H1ZM1 10.1429V15.5"
        stroke={p?.color}
        strokeLinecap="round"
      />
    </svg>
  )
}

function findTaskTitleDifference(str1: string, str2: string) {
  const minLength = Math.min(str1.length, str2.length)

  for (let i = 0; i < minLength; i++) {
    if (str1[i] !== str2[i]) {
      return (
        <span>
          <span className="oldValue">{str1}</span>
          <span className="newValue">{str2}</span>
        </span>
      )
    }
  }

  if (str1.length !== str2.length) {
    return (
      <span className="name">
        {str1.slice(0, minLength)}
        <span className="newValue">{str2.slice(minLength)}</span>
      </span>
    )
  }

  return <span className="newValue">{str2}</span>
}

function determineUpdateField(owner, oldValue, newValue, t) {
  if (!oldValue || !newValue) return t('Update the task')
  const dispatch = useAppDispatch()
  const openImage = (att) => {
    dispatch(
      openModal({
        id: 'media-player',
        data: {
          withSwiper: false,
          attachements: [att],
        },
      }),
    )
  }
  const oldKeys = Object.keys(oldValue)
  const newKeys = Object.keys(newValue)

  for (const key of newKeys) {
    if (
      !oldKeys.includes(key) ||
      (JSON.stringify(oldValue[key]) !== JSON.stringify(newValue[key]) && key !== 'description')
    ) {
      const p = priorities.find((elem) => elem.id === newValue[key])
      const oldP = priorities.find((elem) => elem.id === oldValue[key])

      switch (key) {
        case 'tags':
          return (
            <div>
              {owner} {t('Added tag :')} {newValue[key]}
            </div>
          )

        case 'attachements':
          return (
            <div>
              {owner} {t('Added an attachement :')}
              {typeof newValue?.attachements !== 'boolean' &&
                newValue?.attachements?.map((att, i) => {
                  if (att?.location && att?.mimetype?.includes('image'))
                    return (
                      <img
                        key={i}
                        src={att?.location}
                        onClick={() => openImage(att)}
                        style={{
                          width: '55px',
                          height: '35px',
                          margin: '0 2px',
                          objectFit: 'cover',
                          borderRadius: '5px',
                          marginBottom: '2px',
                        }}
                      />
                    )
                })}
            </div>
          )

        case 'dueDate':
          return (
            <div>
              {owner} {t('Set Due Date :')} {reformulateDate(newValue[key], newValue[key])}
            </div>
          )

        case 'startDate':
          return (
            <div>
              {owner} {t('Set Start Date :')} {reformulateDate(newValue[key], newValue[key])}
            </div>
          )

        case 'name':
          return (
            <div className="name">
              {owner} {t('Updated the name :')}
              {findTaskTitleDifference(oldValue[key], newValue[key])}
            </div>
          )

        case 'users':
          return (
            <div>
              {owner} {t('Assigned to :')}{' '}
              {newValue[key].map((item, index: number) => (
                <Tooltip title={item?.fullName} key={index}>
                  <Avatar
                    src={item?.avatar}
                    style={{
                      width: '23px',
                      height: '23px',
                      marginRight: '-10px',
                      marginLeft: '5px',
                      backgroundColor: item.color,
                    }}
                  >
                    {item &&
                      (item.email
                        ? item.email[0]
                        : item.fullName
                          ? item.fullName[0]
                          : item.name
                            ? item.name[0]
                            : ''
                      ).toUpperCase()}
                  </Avatar>
                </Tooltip>
              ))}
            </div>
          )

        case 'priority':
          return oldValue[key] ? (
            <div className="pri">
              {owner} {t('Set Priority : from')} {renderPriority(oldP)} {t('to')}
              {renderPriority(p)}
            </div>
          ) : (
            <div className="pri">
              {owner} {t('Set Priority :')} {renderPriority(p)}
            </div>
          )

        case 'status':
          return (
            <div>
              {owner} {t('Change Status : from')} {oldValue[key]?.name}
              <div className="color" style={{ backgroundColor: oldValue[key]?.color }}></div>
              {t('To')} {newValue[key]?.name}
              <div className="color" style={{ backgroundColor: newValue[key]?.color }}></div>
            </div>
          )

        case 'estimation':
          return (
            <div className="esti">
              {owner} {t('Set Estimation :')} <img src={estimationIcon} />
              {newValue[key]}H
            </div>
          )

        default:
          return (
            <div>
              {owner} {t('other activity')}: {key}
            </div>
          )
      }
    }
  }
}

function determineAction(owner, activity, t) {
  if (!activity) return t('performed an unknown action')
  const lang = localStorage.getItem('i18nextLng')
  function formatDuration(seconds, lang = 'en') {
    const totalSeconds = parseFloat(seconds)
    const days = Math.floor(totalSeconds / (60 * 60 * 24))
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60))
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
    const remainingSeconds = Math.floor(totalSeconds % 60)
    const translations = {
      en: { d: 'd', h: 'h', m: 'm', s: 's' },
      fr: { d: 'j', h: 'h', m: 'min', s: 's' },
      ar: { d: 'يوم', h: 'ساعة', m: 'دقيقة', s: 'ثانية' },
    }
    const t = translations[lang] || translations['en']

    let formattedDuration = ''
    if (days > 0) {
      formattedDuration += `${days}${t.d} `
    }
    if (hours > 0) {
      formattedDuration += `${hours}${t.h} `
    }

    if (minutes > 0 || formattedDuration) {
      formattedDuration += `${minutes}${t.m} `
    }

    formattedDuration += `${remainingSeconds}${t.s}`

    return formattedDuration.trim()
  }

  switch (activity.action) {
    case 'create':
      return (
        <div>
          {owner} {t('Created this task')}
        </div>
      )

    case 'delete':
      return (
        <div>
          {owner} {t('Deleted a task')}
        </div>
      )

    case 'update':
      return determineUpdateField(owner, activity?.oldValue, activity?.newValue, t)

    case 'startTimer':
      return (
        <div className="timer">
          {owner}
          {t('start_a_timer')} <img src={timeIcon} /> {activity?.newValue?.duration} {t('on')}{' '}
          {reformulateDate(activity?.newValue?.endTime)}
        </div>
      )

    case 'closeTimer':
      return (
        <div className="timer">
          {owner} {t('closed_a_timer')} <img src={timeIcon} />{' '}
          {formatDuration(activity?.newValue?.duration, lang)}
        </div>
      )

    default:
      return owner + ' ' + 'updated this task'
  }
}

type Props = {
  activity: IActivity
}

const ActivityItem = React.forwardRef<HTMLDivElement, Props & RefAttributes<HTMLDivElement>>(
  ({ activity }, ref) => {
    const { t } = useTranslation(['taskDetails'])
    const { user } = useAppSelector((state) => state.auth)

    const owner = activity?.owner
      ? user?.user?.id === activity?.owner?.id
        ? t('You')
        : activity?.owner?.firstName
      : 'Unknown User'

    if (
      Object.keys(activity.newValue)[0] !== 'description' &&
      Object.keys(activity.newValue).length > 0
    ) {
      return (
        <div ref={ref} style={{ width: '100%' }}>
          <div key={activity.id} className="comment-header-item">
            <RTLStyleDecorator>
              <div className="comment-header-item-title">
                <RTLStyleDecorator>
                  <span className="dot">.</span>

                  {determineAction(owner, activity, t)}
                </RTLStyleDecorator>
              </div>
            </RTLStyleDecorator>

            <small className="comment-header-item-date">
              {reformulateDate(
                activity?.createdAt ? activity.createdAt : activity?.newValue?.createdAt,
              )}
            </small>
          </div>
        </div>
      )
    }
  },
)

export default ActivityItem
