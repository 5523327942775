import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { Skeleton } from 'antd'

const SetStateSkeleton = () => {
  const { width } = useWindowSize()

  return (
    <div className="skeleton_card  setState_skeleton ">
      <div className="setState_skeleton_header">
        <div className="setState_skeleton_header_right">
          <Skeleton.Avatar active></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={
              width > 450
                ? { width: '194px', height: '16px', borderRadius: '30px' }
                : { width: '60px', height: '14px', borderRadius: '30px' }
            }
          ></Skeleton.Button>

          {width > 900 && (
            <Skeleton.Button
              active
              style={{ width: '85px', height: '20px', borderRadius: '30px' }}
            ></Skeleton.Button>
          )}

          <Skeleton.Avatar
            active
            style={{ width: '22px', height: '22px', borderRadius: '30px' }}
          ></Skeleton.Avatar>

          <Skeleton.Button
            active
            style={
              width > 450
                ? { width: '85px', height: '20px', borderRadius: '30px' }
                : { width: '60px', height: '14px', borderRadius: '30px' }
            }
          ></Skeleton.Button>
        </div>

        {width > 900 && (
          <Skeleton.Button
            active
            style={{ width: '85px', height: '22px', borderRadius: '30px' }}
          ></Skeleton.Button>
        )}
      </div>

      <Skeleton.Button
        active
        style={
          width > 450
            ? { width: '194px', height: '16px', borderRadius: '30px', marginLeft: '40px' }
            : { width: '100px', height: '14px', borderRadius: '30px', marginLeft: '40px' }
        }
      ></Skeleton.Button>
    </div>
  )
}

export default SetStateSkeleton
