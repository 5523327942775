import { store, useAppDispatch } from '@src/modules/shared/store/index'
import { getOneTask } from '@src/modules/tasks/data/tasksSlice/tasksThunk'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import bell from '../../assets/icons/notification/bell.svg'
import close from '../../assets/icons/notification/close.svg'
import { clearNewNotification } from '../../data/NotificationsSlice'
import { handleNotificationType } from '../../utils/notificationType'

export const openTaskDetails = (taskId: string, navigate) => {
  store.dispatch(getOneTask({ id: taskId })).then((res) => {
    if (res?.meta?.requestStatus === 'fulfilled') {
      navigate(`/spaces/list/${res.payload?.payload?.fileId}?taskId=${res?.payload?.payload?.id}`)
    }
  })
}

const NotificationAsideCard = ({ notification }: { notification: INotification | any }) => {
  const { t } = useTranslation(['notifications'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const type = notification?.activity ? 'activity' : 'comment'
  const source = type === 'activity' ? notification?.activity : notification?.comment
  const lang = localStorage.getItem('i18nextLng')
  const separation = lang === 'ar' ? ' > ' : ' < '

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearNewNotification(notification?.id))
    }, 7000)
  }, [])

  const notifType = handleNotificationType(
    t,
    type,
    notification?.activity,
    notification?.comment,
    notification?.event,
  )

  const openNotification = () => {
    openTaskDetails(notification?.activity?.taskId, navigate)
    dispatch(clearNewNotification(notification?.id))
  }

  return (
    <div className={`notification_card_aside ${lang === 'ar' && 'ar-annimation'}`}>
      <div className="notification_card_icon_container" onClick={() => openNotification()}>
        <img src={bell} />
      </div>

      <div className="notification_aside_card_content" onClick={() => openNotification()}>
        <p className="content_header">
          <span className="owner">{source?.owner?.fullName}</span>
          <span className="notification_type">{notifType}</span>
        </p>

        {notification?.event === 'click-event' && (
          <span className="notification_type">{notification?.message}</span>
        )}

        <div className="content_path">
          {type === 'activity' &&
            source?.oldValue?.path?.map((item: { name: string }, index: number) => {
              return (
                <span>
                  {item?.name}
                  {index !== (source?.oldValue?.path as string[]).length - 1 ? separation : ''}
                </span>
              )
            })}
        </div>
      </div>

      <img
        src={close}
        className={`close_icon ${lang === 'ar' && 'ar-close-icon'}`}
        onClick={(e) => {
          e.stopPropagation()
          dispatch(clearNewNotification(notification?.id))
        }}
      />
    </div>
  )
}

export default NotificationAsideCard
