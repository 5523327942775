import { logout } from '@src/modules/auth/data/authThunk'
import { Avatar, Badge, Divider, Dropdown, Tooltip } from 'antd'
import { Header as AntHeader } from 'antd/es/layout/layout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useStopwatch } from 'react-timer-hook'
import { ReactComponent as ProfileSVG } from '../../assets/icons/header/profile.svg'
import { ReactComponent as SearchSVG } from '../../assets/icons/header/search.svg'
import { ReactComponent as LogoRtlSVG } from '../../assets/icons/logo/logo-rtl-tam.svg'
import { ReactComponent as LogoSVG } from '../../assets/icons/logo/logo.svg'
import { ReactComponent as CollapseSVG } from '../../assets/icons/sidebar/collapse-btn.svg'
import { ReactComponent as UpgradeSVG } from '../../assets/icons/sidebar/upgrade.svg'
import useWindowSize from '../../hooks/useWindowSize'
import { sidebarCloseAt } from '../../layout/MainLayout/MainLayout'
import { useAppDispatch, useAppSelector } from '../../store'
import { openSidebar, stopRecording } from '../../store/slices/settings/settingsSlice'
import { isMobile } from '../../utils/isMobile'
import ControlBar from '../ControlBar'
import FloatGlobalActions from '../FloatGlobalActions'
import { ReactComponent as VideoIcon } from './../../../tasks/assets/icons/task/screen-recording.svg'
import { ReactComponent as RecordingIcon } from './../../../tasks/assets/icons/tasks/recording.svg'
import { ReactComponent as LogoutIcon } from './../../assets/icons/sidebar/logout.svg'
import { ReactComponent as SettingIcon } from './../../assets/icons/sidebar/settings-icon.svg'
import { ReactComponent as TrashIcon } from './../../assets/icons/sidebar/trash.svg'
import Search from '../Input/search'

const ScreenRecordDuration = () => {
  const dispatch = useAppDispatch()
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true })

  return (
    <div className="screen-recording-duration">
      <VideoIcon />
      <Tooltip title="Stop Recording">
        <RecordingIcon className="stop-recording-btn" onClick={() => dispatch(stopRecording())} />
      </Tooltip>

      <div className="timer-duration">
        {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </div>
    </div>
  )
}

const ProfileHeaderDropdown = ({ close }) => {
  const { t } = useTranslation(['header'])
  const { user } = useAppSelector((state) => state.auth)
  const { email, fullName, avatar } = user?.user || {}
  const dispatch = useAppDispatch()

  return (
    <div className="profile-header">
      <div className="profile-header-user">
        <Badge dot color="#12B76A">
          <Avatar src={avatar}>{fullName && fullName[0]?.toUpperCase()}</Avatar>
        </Badge>

        <div className="user-info">
          <p>{fullName}</p>
          <Tooltip title={email}>
            <p>{email}</p>
          </Tooltip>
        </div>
      </div>

      <div className="profile-header-options">
        <Link to="/settings/profile" onClick={close} className="profile-item">
          <ProfileSVG />
          <p>{t('profile')}</p>
        </Link>

        <Link to="/settings/workspace" onClick={close} className="profile-item">
          <SettingIcon />
          <p>{t('settings')}</p>
        </Link>

        <Divider />
        <Link to="/settings/trash" onClick={close} className="profile-item">
          <TrashIcon />
          <p>{t('Trash')}</p>
        </Link>
        <div className="profile-item" onClick={() => dispatch(logout())}>
          <LogoutIcon />
          <p className="logout">{t('logout')}</p>
        </div>
      </div>
    </div>
  )
}

const Header = ({ removeEvents }: { removeEvents?: boolean }) => {
  const { t, i18n } = useTranslation(['header'])
  const { pathname } = useLocation()
  const { user } = useAppSelector((state) => state.auth?.user)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const [profileShow, setProfileShow] = useState<boolean>(false)
  const {
    screenRecording: { isRecording },
  } = useAppSelector((state) => state.settings)
  const isSpace = !pathname.includes('settings') && !pathname.includes('daily-questions')

  const openSidebarHandler = () => {
    dispatch(openSidebar('layout-sidebar'))
  }

  const Logo = i18n.language === 'ar' ? LogoRtlSVG : LogoSVG
  const handelRedirect = () => {
    window.open('https://www.tamtasks.com/en#plans', '_blank')
  }
  return (
    <AntHeader className="layout-header">
      <ControlBar />
      <div className="layout-header-logo">
        {width < sidebarCloseAt && isSpace && (
          <CollapseSVG onClick={openSidebarHandler} className="collapse-icon" />
        )}
        <Logo className="logo" onClick={() => navigate('/dashboard')} />
      </div>
      {!removeEvents && (
        <div className="header-search-bar">
          <Search
            onSearch={() => {}}
            prefix={<SearchSVG />}
            variant="filled"
            size="xs"
            containerClassName="header-search-bar-input"
            disabled
          />
        </div>
      )}

      <div className="header-controll">
        {!removeEvents && (
          <div className="upgrade-button" onClick={handelRedirect}>
            <UpgradeSVG />
            <p>{t('upgrade')}</p>
          </div>
        )}

        {isRecording && !removeEvents && <ScreenRecordDuration />}

        {!isMobile() && window?.navigator?.mediaDevices && !removeEvents && <FloatGlobalActions />}

        <div className="user-profile">
          <Dropdown
            open={profileShow}
            disabled={removeEvents}
            onOpenChange={(e) => setProfileShow(e)}
            dropdownRender={() => <ProfileHeaderDropdown close={() => setProfileShow(false)} />}
            trigger={['click']}
          >
            <Avatar src={user?.avatar}>{user?.fullName && user?.fullName[0]?.toUpperCase()}</Avatar>
          </Dropdown>
        </div>
      </div>
    </AntHeader>
  )
}

export default Header
