/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthGuard from '@src/modules/shared/guards/AuthGuard'
import MainLayout from '@src/modules/shared/layout/MainLayout/MainLayout'
import { Fragment } from 'react'
import { RouteProps } from 'react-router-dom'
import ImportExport from '../features/ImportExport'
import ManageWorkspaces from '../features/ManageWorkspaces'
import Members from '../features/Members'
import Profile from '../features/Profile'
import Teams from '../features/Teams'
import TeamMembers from '../features/TeamsMembers/TeamsMembers'
import Workspace from '../features/Workspace'
import Integrations from '../features/Integrations/Integrations'
import Trash from '../features/Trash/Trash'
// import Trash from '../features/Trash/Trash'

type RouteConfig = {
  exact: boolean | null
  path: string
  component: React.ComponentType<any>
  guard?: React.ComponentType<any> | typeof Fragment | any
  layout?: React.ComponentType<any> | typeof Fragment
  requiredPermission?: any
} & RouteProps

const routes: RouteConfig[] = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/members',
    component: Members,
    layout: MainLayout,
    requiredPermission: { action: 'read', resource: 'users' }, // this route with permission
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/workspace',
    component: Workspace,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/profile',
    component: Profile,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/import-export',
    component: ImportExport,
    layout: MainLayout,
    requiredPermission: { action: 'read', resource: 'import' },
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/manage-workspaces',
    component: ManageWorkspaces,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/teams',
    component: Teams,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/teams/:id',
    component: TeamMembers,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/integrations',
    component: Integrations,
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings/trash',
    component: Trash,
    layout: MainLayout,
  },
]

export default routes
