import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const handleDownloadPDF = (id: string) => {
  const input = document.getElementById(id)
  // Specify the id of the element you want to convert to PDF
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png')
    const pdf = new jsPDF()
    const width = pdf.internal.pageSize.getWidth()
    const height = pdf.internal.pageSize.getHeight()
    pdf.addImage(imgData, 'PNG', 0, 0, width, height)
    pdf.save('Tam-docs.pdf')
  })
}

// export const handleDownloadPDF = (id: string) => {
//   htmlToImage.toPng(document.getElementById(id), { quality: 0.95 }).then(function (dataUrl) {
//     var link = document.createElement('a')
//     link.download = 'my-image-name.jpeg'
//     const pdf = new jsPDF()
//     const imgProps = pdf.getImageProperties(dataUrl)
//     const pdfWidth = pdf.internal.pageSize.getWidth()
//     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
//     pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight)
//     pdf.save('download.pdf')
//   })
// }
