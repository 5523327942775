export const handleActivityDescription = (t: any, activity: any) => {
  if (activity?.action === 'create') {
    switch (true) {
      case activity?.source === 'space':
        return `${t('Created')} ${t('Space')} : ${activity?.newValue?.name} `
      case activity?.source === 'list':
        return `${t('Created')} ${t('List')} : ${activity?.newValue?.name} `
      case activity?.source === 'genericFolder':
        return `${t('Created')} ${t('Folder')} : ${activity?.newValue?.name} `
      case activity?.source === 'task':
        return `${t('Created')} ${t('Task')} : ${activity?.newValue?.name} `
      default:
        return 'other type'
    }
  }
  if (activity?.action === 'delete') {
    switch (true) {
      case activity?.source === 'space':
        return `${t('Deleted')} ${activity?.oldValue?.name} ${t('Space')}`
      case activity?.source === 'list':
        return `${t('Deleted')} ${activity?.oldValue?.name} ${t('List')}`
      case activity?.source === 'genericFolder':
        return `${t('Deleted')} ${activity?.oldValue?.name} ${t('Folder')}`
      case activity?.source === 'task':
        return `${t('Deleted')} ${activity?.oldValue?.name} ${t('Task')}`
      default:
        return 'other type'
    }
  }

  if (activity?.action === 'update') {
    switch (true) {
      case Object.keys(activity?.newValue).includes('name'):
        return t('Updated name')
      case Object.keys(activity?.newValue).includes('description'):
        return t('Updated description')
      case Object.keys(activity?.newValue).includes('priority'):
        return t('Updated priority')
      case Object.keys(activity?.newValue).includes('users'):
        return t('Assignment users')
      case Object.keys(activity?.newValue).includes('dueDate'):
        return t('Updated dueDate')
      case Object.keys(activity?.newValue).includes('status'):
        return t('Updated status')
      default:
        return 'other type'
    }
  }
}
