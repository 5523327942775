import { api } from '@src/modules/shared/services/api'

interface getCommentsPayload {
  fileId: string
  statusId?: string
  skip?: number
  limit?: number
}

const defaultLimit = 3

export const CommentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getComments: build.query<any, getCommentsPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/comment`,
          params,
        }
      },
      // return the payload
      transformResponse: (response: { payload: any }): any => {
        return response.payload
      },
    }),

    addCommnet: build.mutation<any, any>({
      query: (body: { taskId: string; content: string; parentId: null | string }) => ({
        url: `api/comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'COMMENTS', id: 'LIST' }] as any,
    }),

    updateComment: build.mutation<any, any>({
      query: ({ commentId, content }: { commentId: string; content: string }) => ({
        url: `api/comment/${commentId}`,
        method: 'PATCH',
        body: {
          content,
        },
      }),
      invalidatesTags: [{ type: 'COMMENTS', id: 'LIST' }] as any,
    }),
  }),
})

export const updateComment = (comment: any, query: getCommentsPayload) => {
  return CommentsApi.util.updateQueryData('getComments', query, (draftComments) => {
    return {
      new: comment,
      ...draftComments,
    }
  })
}

export const { useGetCommentsQuery, useAddCommnetMutation, useUpdateCommentMutation } = CommentsApi
