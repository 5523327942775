import Logo from '../assets/images/sidebar/logoIcon.svg'
import { useTranslation } from 'react-i18next'

const MaintenanceProvider = () => {
  const { t } = useTranslation(['layout'])

  const containerStyle = {
    maxWidth: '600px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  }

  const textStyle = {
    color: '#333',
  }

  const imageStyle = {
    maxWidth: '100px',
    height: '50%',
    width: '50%',
  }

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
        margin: 0,
        padding: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={containerStyle}>
        <img src={Logo} alt="Under Maintenance" style={imageStyle} />
        <h1 style={textStyle}>{t('under_maintenance')}</h1>
        <p style={{ color: '#666' }}>{t('apologize_message')}</p>
        <p style={{ color: '#666' }}>{t('check_back_soon')}</p>
      </div>
    </div>
  )
}

export default MaintenanceProvider
