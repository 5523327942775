import { Dropdown, Empty, Row, Skeleton } from 'antd'
import { ReactComponent as CollapseIcon } from '../../../../assets/icons/filter/collapse.svg'
import { useEffect, useState } from 'react'
import { useGetTagsQuery } from '@src/modules/tasks/services/tagsApi'
import SeacrhInput from '@src/modules/spaces/components/SeacrhInput'
import SearchIcon from '../../../../assets/icons/filter/search-icon.svg'
import { addOpacity } from '@src/modules/shared/utils/addOpacity'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'

const TagsList = ({ selcetdTags, setTags }: any) => {
  const [search, setSearch] = useState<string>()
  const [page, setPage] = useState(1)
  const { listId } = useParams()
  const { data: list } = useGetListQuery({ id: listId })

  const handelSearch = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  const { data: tags, isFetching } = useGetTagsQuery({
    limit: 20,
    spaceId: list?.spaceId,
    skip: page,
    options: `{"where":{"name":{"operation":"$contains","value":"${search}"}}}`,
  })

  return (
    <div className="ant-dropdown-menu members-list-filter tags-list-filter">
      <div className="members-list-filter-search">
        <SeacrhInput onSearch={(s) => handelSearch(s)} icon={SearchIcon} />
        <div
          className="members-list-filter-container"
          onScroll={(e) => handleScroll(e, tags?.metadata.total_items || 0, page, 20, setPage)}
        >
          {isFetching ? (
            <LoadingSkeleton rows={4} />
          ) : tags?.payload?.length > 0 ? (
            tags?.payload?.map((tag, i: number) => {
              return (
                <div
                  className={`member tag-filter ${
                    selcetdTags?.find((assTag) => assTag?.id === tag?.id) ? 'selected-tag' : ''
                  }`}
                  key={i}
                  onClick={() => setTags(tag)}
                  style={{ color: tag?.color }}
                >
                  {tag?.name}
                </div>
              )
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {tags?.metadata?.next && <LoadingSkeleton rows={2} />}
        </div>
      </div>
    </div>
  )
}

const TagsFilter = ({ onData, disable = false }) => {
  const { t } = useTranslation(['taskDetails'])
  const [selcetdTags, setTags] = useState<any>([])

  const setTagsHandler = (newTag) => {
    setTags((prev) => {
      const memberExistIndex = prev?.findIndex((member) => newTag?.id === member?.id)
      const newArr = [...prev]
      if (memberExistIndex !== -1) newArr?.splice(memberExistIndex, 1)
      else newArr?.push(newTag)
      return newArr
    })
  }

  useEffect(() => {
    onData(selcetdTags)
  }, [selcetdTags])

  return (
    <Dropdown
      overlay={<TagsList selcetdTags={selcetdTags} setTags={setTagsHandler} />}
      trigger={['click']}
      disabled={disable}
    >
      <div className="members-filter tags-filter">
        {selcetdTags?.length > 0 ? (
          <>
            {selcetdTags.slice(0, 3).map((tag, i: number) => (
              <div
                className="selected-tag"
                key={i}
                style={{ background: addOpacity(tag?.color), color: tag?.color }}
              >
                {tag?.name}
              </div>
            ))}
            {selcetdTags.length > 3 ? (
              <div className="extra-tags">+{selcetdTags.length - 3}</div>
            ) : null}
          </>
        ) : (
          <>
            <p>{t('Select Tags')}</p>
            <CollapseIcon />
          </>
        )}
      </div>
    </Dropdown>
  )
}
const LoadingSkeleton = ({ rows }) => {
  return Array.from({ length: rows || 2 }).map((_, index) => (
    <div className="tag-list-skeleton">
      <div key={index}>
        <Row justify="center" align="middle">
          <Skeleton.Input active size="small" style={{ width: 180 }} />
        </Row>
      </div>
    </div>
  ))
}
export default TagsFilter
