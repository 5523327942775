import Button from '@src/modules/shared/components/Button/Button'

import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { message } from 'antd'
import { manageFiles, setSpacesSocketEvent } from '../../data/spaceSlice/spacesSlice'
import { createFolder } from '../../data/folderSlice/folderThunk'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'

const FolderForm = ({ data, isEdit }: any) => {
  const { status } = useAppSelector((state) => state.folders)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals'])

  const initialValues = {
    name: isEdit ? data.name : '',
  }
  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().min(3).required('name is Required'),
    }),
    onSubmit: (values) => {
      if (status === 'loading') return
      dispatch(createFolder({ ...values, spaceId: data.space.id }))
        .unwrap()
        .then((res) => {
          dispatch(
            manageFiles({
              id: data.space?.id,
              newObj: res.payload,
              type: 'add',
              parent: 'space',
              targetData: 'folders',
            }),
          )
          dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }))
          dispatch(closeModal({ id: 'folder-modal' }))
        })
        .catch((err) => {
          message.error(err.message || 'error while create a folder')
        })
    },
  })
  return (
    <div className="ta-form list-form">
      <form onSubmit={formik?.handleSubmit}>
        <Input
          type="text"
          name="name"
          variant="filled"
          status={formik.errors.name ? 'error' : 'success'}
          label={t('folder.name')}
          placeholder={t('folder.placeholder')}
          size="md"
          formik={formik}
        />
        <div className="form-actions flex-end">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              formik.setValues(initialValues)
              dispatch(closeModal({ id: 'folder-modal' }))
            }}
          >
            {t('folder.cancel')}
          </Button>
          <Button htmlType="submit" className="confirm-action">
            {status === 'loading' ? t('folder.loading') : t('folder.create')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default FolderForm
