import { Button, Dropdown, message } from 'antd'
import {
  useCopyTaskMutation,
  useDeleteMutipleTaskMutation,
  useMoveTaskMutation,
} from '../../services/tasksApi'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ReactComponent as CopyIcon } from './../../../spaces/assets/icons/spaceDropdown/copy.svg'
import { ReactComponent as DeleteIcon } from './../../../spaces/assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as DeplaceIcon } from './../../assets/icons/task/send.svg'
import { ReactComponent as DotSVG } from '../../assets/icons/task/dots.svg'
import { ReactComponent as DuplicateIcon } from './../../../spaces/assets/icons/spaceDropdown/duplicate.svg'

import SpacesTree from '@src/modules/shared/components/SpacesTree'
import { Spin } from 'antd'
import { setSocketEvent } from '../../data/tasksSlice/tasksSlice'
import { updateURL } from '../../utils/updateURL'
import { useAppDispatch } from '@src/modules/shared/store'
import { useGetListQuery, useGetStatusesByListQuery } from '@src/modules/spaces/services/fileApi'
import { useTranslation } from 'react-i18next'
import Search from '@src/modules/shared/components/Input/search'

interface taskProps {
  disable?: boolean
  data:
    | {
        id: string
      }
    | any
}

const DropdownMove = ({ children, treeVisible, setTreeVisible, moveTask, copyTask, type }) => {
  const [open, setOpen] = useState<boolean>(treeVisible[type])

  useEffect(() => {
    setOpen(treeVisible[type])
  }, [treeVisible[type]])

  return (
    <Dropdown
      trigger={['click']}
      open={open}
      onOpenChange={(e) => {
        setTreeVisible((prev) => ({ ...prev, [type]: e }))
        setOpen(e)
      }}
      dropdownRender={() => {
        return (
          <div className="ant-dropdown-menu task-dropdown spaces-tree">
            <Search onSearch={() => {}} variant="underlined" prefix={''} />
            <div className="spaces-tree-container">
              <SpacesTree
                readOnly
                onSelectList={(items) => {
                  type === 'copy' ? copyTask(items[0]) : moveTask(items[0])
                }}
              />
            </div>
          </div>
        )
      }}
    >
      {children}
    </Dropdown>
  )
}

const TaskActionMenu: React.FC<taskProps> = ({ data }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['tasks'])

  const { listId } = useParams()
  const [newListId, setNewListId] = useState<{ id: string; type: string }>({ id: '', type: '' })
  const [visisble, setVisisble] = useState<boolean>(false)
  const [treeVisible, setTreeVisible] = useState<{ move: boolean; copy: boolean }>({
    move: false,
    copy: false,
  })
  const [searchParams, setSearchparams] = useSearchParams()
  const [deleteMutipleTasks, { isLoading: isDeleting }] = useDeleteMutipleTaskMutation()
  // const [updateTask] = useUpdateTaskMutation()
  const [moveTask] = useMoveTaskMutation()
  const [copyTask] = useCopyTaskMutation()
  const { data: newList } = useGetListQuery({ id: newListId?.id }, { skip: !newListId?.id })
  const { data: status } = useGetStatusesByListQuery(
    { listId: newListId?.id },
    { skip: !newListId?.id },
  )
  const closeDropdown = () => setVisisble(false)

  const moveTaskHandler = (fileId: string) => {
    setTreeVisible((prev) => ({ ...prev, move: false }))
    setNewListId({ id: fileId, type: 'move' })
  }

  const copyTaskHandler = (fileId: string) => {
    setTreeVisible((prev) => ({ ...prev, copy: false }))
    setNewListId({ id: fileId, type: 'copy' })
  }

  useEffect(() => {
    if (newList && newListId)
      if (newListId.type === 'move')
        moveTask({
          tasks: [
            {
              taskId: data?.id,
              fileId: newListId.id,
              statusId: status?.[0]?.id,
            },
          ],
        })
          .unwrap()
          .then(() => {
            setNewListId(null)
            if (newListId.id !== listId)
              dispatch(setSocketEvent({ updateType: 'deletedTask', updateEvent: [data?.id] }))
          })
      else if (newListId.type === 'copy') {
        copyTask({
          tasks: [
            {
              taskId: data?.id,
              fileId: newListId.id,
              statusId: status?.[0]?.id,
            },
          ],
        })
          .unwrap()
          .then(() => setNewListId(null))
      }
  }, [newList, newListId])

  return (
    <div className={`task-item`} onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlayClassName="space-dropdown"
        trigger={['click']}
        open={visisble}
        onOpenChange={(e) => {
          setTreeVisible({ copy: false, move: false })
          setTimeout(() => setVisisble(e), 10)
        }}
        dropdownRender={() => (
          <div className="task-dropdown task-actions priority-list ant-dropdown-menu">
            <div
              className="task-actions-item"
              onClick={() => {
                const currentUrl = window.location.origin
                navigator.clipboard.writeText(
                  `${currentUrl}${updateURL({ label: 'taskId', value: data?.id })}`,
                )
                closeDropdown()
                message.success(t('Copied'))
              }}
            >
              <CopyIcon />
              <span>{t('list_view.copy_link')}</span>
            </div>

            <DropdownMove
              treeVisible={treeVisible}
              setTreeVisible={setTreeVisible}
              moveTask={moveTaskHandler}
              copyTask={null}
              type={'move'}
            >
              <div
                className="task-actions-item"
                onClick={() => setTreeVisible((prev) => ({ ...prev, move: !treeVisible.move }))}
              >
                <DeplaceIcon />
                <span>{t('list_view.move')}</span>
              </div>
            </DropdownMove>

            <DropdownMove
              treeVisible={treeVisible}
              setTreeVisible={setTreeVisible}
              moveTask={null}
              copyTask={copyTaskHandler}
              type={'copy'}
            >
              <div
                className="task-actions-item"
                onClick={() => setTreeVisible((prev) => ({ ...prev, copy: !treeVisible.copy }))}
              >
                <DuplicateIcon />
                <span>{t('list_view.duplicate')}</span>
              </div>
            </DropdownMove>

            <div
              className="task-actions-item"
              onClick={() => {
                if (data?.id) {
                  deleteMutipleTasks({ tasks: [data?.id] })
                    .unwrap()
                    .then(() => {
                      if (searchParams.get('taskId') === data?.id) {
                        searchParams.delete('taskId')
                        setSearchparams(searchParams)
                      }
                      dispatch(
                        setSocketEvent({ updateType: 'deletedTask', updateEvent: [data?.id] }),
                      )
                      closeDropdown()
                    })
                }
              }}
            >
              {isDeleting ? <Spin size={'small'} /> : <DeleteIcon />}
              <span>{t('list_view.delete')}</span>
            </div>
          </div>
        )}
      >
        <div onClick={() => setVisisble(!visisble)}>
          <Button type="text" shape="circle" icon={<DotSVG />} />
        </div>
      </Dropdown>
    </div>
  )
}

export default TaskActionMenu
