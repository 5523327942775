export function groupByFieldType(fields: any[]): any[] {
  const groupedFields: { [key: string]: any } = {}

  fields?.forEach((field) => {
    if (groupedFields[field.fieldType || field.locationType]) {
      groupedFields[field.fieldType || field.locationType].rows = groupedFields[
        field.fieldType || field.locationType
      ].rows.concat(field.rows)
    } else {
      groupedFields[field.fieldType || field.locationType] = { ...field }
    }
  })
  for (const fieldType in groupedFields) {
    if (groupedFields.hasOwnProperty(fieldType)) {
      groupedFields[fieldType].rows = [...new Set(groupedFields[fieldType].rows)]
    }
  }

  return Object.values(groupedFields)
}

export interface IMetadataTypes {
  current_page?: number
  last_page?: number
  next?: { limit: number; page: number }
  rows_per_page?: number
  total_items?: number
}

export const groupByFields = (activeFieldIndex: string) =>
  [
    { key: '0', label: 'Field Type' },
    { key: '1', label: 'Location Type' },
  ].map((item) => ({
    ...item,
    className: activeFieldIndex === item.key && 'active-groupby-field',
  }))
