import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../../components/Header'
import Card from '../../components/Card'
import { Empty, Skeleton, Table, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import { getDocsColumns } from '../../utils/getDocsColumns'
import { useEffect, useState } from 'react'
import {
  useGetDocumentsQuery,
  useGetMyDucumentsQuery,
  useGetResentViewedTasksQuery,
} from '../../services/documents'
import { loadingHandler, trasformDocuments } from '../../utils/trasformDocuments'
import { useAppSelector } from '@src/modules/shared/store'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { useNavigate } from 'react-router-dom'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import Search from '@src/modules/shared/components/Input/search'

const generateFilter = (filters) => ({ where: filters })
const getMyDocsFilter = (userId: string) => ({ ownerId: { operation: '$Equal', value: userId } })

const tabs = (t) => [
  { key: '0', label: t('nav.all') },
  { key: '1', label: t('nav.my_docs') },
  // { key: '2', label: t('nav.shared') },
  // { key: '3', label: t('nav.private') },
]

const Documents = () => {
  const { i18n, t } = useTranslation(['documents'])
  const { user } = useAppSelector((state) => state.auth.user)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [recentlyViewPage, setRecentlyViewPage] = useState<number>(1)
  const [myDocsPage, setMyDocsPage] = useState<number>(1)
  const [filter, setFilter] = useState<object>({})
  const [sort, setSort] = useState({
    sortBy: '',
    sortOrder: '',
  })
  const [activeTab, setActiveTab] = useState<string>('0')
  const [search, setSearch] = useState<string>('')
  const navigate = useNavigate()

  useTitle(t('documents'))
  const {
    data: documents,
    isLoading,
    isFetching,
  } = useGetDocumentsQuery({
    limit: 20,
    skip: currentPage,
    options: JSON.stringify({
      ...(filter ? filter : {}),
      ...(sort.sortBy.length > 0 && sort.sortOrder.length > 0
        ? { order: { [sort.sortBy]: sort.sortOrder } }
        : {}),
    }),
  })

  const { data: myDocs, isLoading: isLoadingMyDocs } = useGetMyDucumentsQuery({
    limit: myDocsPage * 10,
    skip: 1,
    options: JSON.stringify(generateFilter(getMyDocsFilter(user?.id))),
  })

  const { data: recentlyViewedDocs, isLoading: isLoadingRecentView } = useGetResentViewedTasksQuery(
    {
      limit: recentlyViewPage * 10,
      skip: 1,
    },
  )

  const onTab = (activeKey) => {
    setCurrentPage(1)
    setActiveTab(activeKey)
  }

  useEffect(() => {
    let filter = { ...(search ? { name: { operation: '$contains', value: search } } : {}) }
    if (activeTab === '0') {
      filter
    } else if (activeTab === '1') {
      filter = { ...filter, ...getMyDocsFilter(user?.id) }
    }
    setFilter(generateFilter(filter))
  }, [activeTab, search])

  const infiniteScrollHandler = (e) =>
    handleScroll(e, documents?.metadata?.total_items, currentPage, 20, setCurrentPage)

  return (
    <ViewLayout header={Header} headerProps={{}}>
      <div className="documents" onScroll={infiniteScrollHandler}>
        <div className="documents-cards">
          <Card
            title={t('recently_viewed')}
            loading={isLoadingRecentView}
            seeAll={false}
            total_items={recentlyViewedDocs?.metadata?.total_items}
            currentPage={recentlyViewPage}
            limit={10}
            onBottom={setRecentlyViewPage}
            documents={recentlyViewedDocs?.payload}
          />
          <Card title={t('favorites')} seeAll={false} documents={[]} />
          <Card
            title={t('created_by_me')}
            loading={isLoadingMyDocs}
            seeAll={false}
            total_items={myDocs?.metadata?.total_items}
            currentPage={myDocsPage}
            limit={10}
            onBottom={setMyDocsPage}
            documents={myDocs?.payload}
          />
        </div>
        <div className="documents-lists">
          <div className="documents-lists-header">
            <div className="header-left">
              <Tabs
                direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                defaultActiveKey={activeTab}
                items={tabs(t)}
                onChange={onTab}
              />
            </div>
            <div className="header-right dashboard-tasks-search">
              <Search
                onSearch={(s) => setSearch(s)}
                prefix={''}
                size="xs"
                containerClassName="dashboard-tasks-search-input"
                style={{ padding: '0' }}
                variant="filled"
              />
              {/* <TasksFilter /> */}
            </div>
          </div>
          <div className="documents-lists-table">
            <Table
              columns={getDocsColumns(t, navigate, i18n.language)}
              dataSource={loadingHandler(
                trasformDocuments(documents?.payload),
                documents?.metadata?.next || isLoading,
                isFetching,
              )}
              onChange={(_, filters, sorter: any) => {
                setCurrentPage(1)
                const { order } = sorter || filters || {}
                if (order) {
                  setSort({
                    sortBy: sorter?.columnKey,
                    sortOrder: order === 'descend' ? 'DESC' : 'ASC',
                  })
                }
              }}
              pagination={false}
              rootClassName="documents-table"
              locale={{
                emptyText: isFetching ? <Skeleton active={true} /> : <Empty />,
              }}
            />
          </div>
        </div>
      </div>
    </ViewLayout>
  )
}

export default Documents
