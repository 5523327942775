import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'

export const getIntegrations = createAsyncThunk('settings/integrations', async () => {
  try {
    const response = await axiosInstance.get(`/api/integrations`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const getUserIntegrations = createAsyncThunk('settings/user-integrations', async () => {
  try {
    const response = await axiosInstance.get(`/api/user-integrations`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const toggleIntegrationStatus: any = createAsyncThunk(
  'settings/toggle-integration',
  async ({ integrationId, isEnabled }: any) => {
    try {
      let response

      if (typeof isEnabled === 'string')
        response = await axiosInstance.patch(`/api/integrations/${integrationId}/status`, {
          isEnabled: isEnabled === 'true' ? true : false,
        })
      else
        response = await axiosInstance.patch(`/api/integrations/${integrationId}/status`, {
          isEnabled: isEnabled,
        })
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)
