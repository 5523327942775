import { useEffect, useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from '@src/modules/customFields/assets/arrowLeft-icon.svg'
import { ReactComponent as CloseDrawerIcon } from '@src/modules/customFields/assets/close-icon.svg'
import { ReactComponent as ArrowBottomIcon } from '@src/modules/customFields/assets/icon-bottom.svg'
import Input from '@src/modules/shared/components/Input/Input'
import { Drawer, Dropdown, message } from 'antd'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '@src/modules/shared/components/Button/Button'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons.tsx'
import Label from '../Fields/Label'
import Money from '../Fields/Money'
import People from '../Fields/People'
import Rating from '../Fields/Rating'
import Relationship from '../Fields/Relationship'
import DropDown from '../Fields/DropDown'
import ProgressManual from '../Fields/ProgressManual'
import { useTranslation } from 'react-i18next'
import {
  useAddCustomFieldMutation,
  useUpdateCustomFieldMutation,
} from '../../services/cusomFieldsApi'
// import { removeDuplicate } from '@src/modules/tasks/components/ListView/utils/listViewManagment'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'

export const Fields = ({ formik }) => ({
  label: {
    component: <Label formik={formik} />,
  },
  money: {
    component: <Money formik={formik} />,
  },
  people: {
    component: <People formik={formik} />,
  },
  // 8: {
  //   component: <ProgressAuto formik={formik} />,
  // },
  progress: {
    component: <ProgressManual formik={formik} />,
  },
  rating: {
    component: <Rating formik={formik} />,
  },
  relationship: {
    component: <Relationship formik={formik} />,
  },
  tasks: {
    component: <Relationship formik={formik} />,
  },
  dropdown: {
    component: <DropDown formik={formik} />,
  },
})
type editeType = {
  data: { message: string }
}
export const colors = [
  '#A6A6A6',
  '#DC7400',
  '#FBAC54',
  '#FFE976',
  '#C1B332',
  '#97D856',
  '#3FABE8',
  '#96D9FF',
  '#B294FF',
]
function CustomDrawer({
  openDrawer,
  setOpenDrawer,
  aciveLocation,
  type,
  data,
  //   setPage,
  //   page,
  setCustomFieldData,
}: {
  openDrawer: boolean
  setOpenDrawer: any
  aciveLocation: any
  type: string
  data?: any
  setPage: (e: number) => void
  page: number
  customFieldData: any //TODO
  setCustomFieldData: (e: any) => void //TODO
}) {
  const [selectedField, setSelectedField] = useState(type)

  const { t } = useTranslation(['customFields'])
  const [createCustomField, { isLoading: createCustomFieldIsLoading }] = useAddCustomFieldMutation()
  const initialValues = {
    fieldName: '',
    fieldDescription: '',
    values: {},
  }
  useEffect(() => {
    formik.setFieldValue('type', type)
    formik.resetForm()
  }, [type])
  useEffect(() => {
    if (data) {
      formik.setFieldValue('fieldName', data?.name)
      formik.setFieldValue('fieldDescription', data?.description)
      formik.setFieldValue('values', data?.typeConfig)
    } else {
      formik.resetForm()
    }
  }, [data])

  const validationSchema = Yup.object().shape({
    fieldName: Yup.string()
      .matches(/\S/, t('invalid_field_name'))
      .required(t('field_name_is_required')),
  })
  const [updateCustomFields, { isLoading }] = useUpdateCustomFieldMutation()
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (data) {
        updateCustomFields({
          id: data.id,
          name: values.fieldName,
          description: values?.fieldDescription,
          typeConfig: values?.values,
        })
          .then((res: editeType) => {
            setCustomFieldData((prev) => {
              if (!prev || !prev.payload) return prev
              const updatedPayload = prev?.payload?.map((field) => {
                if (field?.fieldType === data?.fieldType) {
                  return {
                    ...field,
                    rows: field?.rows?.map((row) => {
                      if (row?.id === data?.id) {
                        return {
                          ...row,
                          name: values?.fieldName,
                          description: values?.fieldDescription ?? row?.description,
                          typeConfig: values?.values ?? row?.typeConfig,
                          updatedAt: new Date().toISOString(),
                        }
                      }
                      return row
                    }),
                  }
                }
                return field
              })

              return {
                ...prev,
                payload: updatedPayload,
              }
            })
            if (res?.data?.message) {
              message.success(t('custom_field_edited_successfully'))
              setOpenDrawer(false)
            } else {
              message.error(t('custom_field_edit_failed'))
            }
          })
          .catch(() => message.error(t('custom_field_edit_failed')))
      } else {
        if (createCustomFieldIsLoading) return

        const fieldType = CustomFieldsIcons?.find((item) => item?.field == selectedField)?.field
        const typeConfig = [
          'label',
          'dropdown',
          'money',
          'people',
          'progress',
          'rating',
          'relationship',
          'tasks',
        ].includes(fieldType)
          ? values.values
          : null
        const entityConfig = {
          name: values.fieldName,
          ...(values?.fieldDescription && { description: values?.fieldDescription }),

          fieldType,
          typeConfig,
          isPublic: false,
        }
        const payload = {
          ...entityConfig,
          entityType: aciveLocation?.path || 'workspace',
          ...(aciveLocation?.path
            ? { [`${aciveLocation.path}s`]: [{ id: aciveLocation.id }] }
            : { isPublic: true }),
        }

        createCustomField(payload).then((res: any) => {
          setCustomFieldData((prev) => {
            const updatedCustomFieldData = [...prev.payload]

            const existingItemIndex = updatedCustomFieldData.findIndex(
              (item) => item.fieldType === fieldType,
            )

            if (existingItemIndex !== -1) {
              const removeDublicate = removeDuplicatesById([
                ...updatedCustomFieldData[existingItemIndex].rows,
                res?.data?.payload,
              ])
              updatedCustomFieldData[existingItemIndex].rows = removeDublicate
            } else {
              updatedCustomFieldData.push({
                fieldType: fieldType,
                rows: [res?.data?.payload],
              })
            }
            return { metadata: prev.metadata, payload: updatedCustomFieldData }
          })
          message.success(t('custom_field_created_successfully'))
          setOpenDrawer(false)
          formik.resetForm()
        })
      }
    },
  })
  const handleMenuClick = ({ key }) => {
    setSelectedField(key)
    formik.resetForm()
    formik.setFieldValue('type', key)
  }
  const defaultField = CustomFieldsIcons.find((item) => item.field === type)

  const Fields = {
    label: {
      component: <Label formik={formik} />,
    },
    money: {
      component: <Money formik={formik} data={data} />,
    },
    people: {
      component: <People formik={formik} data={data} />,
    },
    progress: {
      component: <ProgressManual formik={formik} data={data} />,
    },
    rating: {
      component: <Rating formik={formik} />,
    },
    relationship: {
      component: <Relationship formik={formik} data={data} />,
    },
    tasks: {
      component: <Relationship formik={formik} data={data} />,
    },
    dropdown: {
      component: <DropDown formik={formik} />,
    },
  }
  return (
    <Drawer
      placement="right"
      getContainer={false}
      className="custom-fields-drawer"
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-fields-drawer-header">
          <ArrowLeftIcon onClick={() => setOpenDrawer(false)} />
          <span>{t('add_new_field')}</span>
          <CloseDrawerIcon onClick={() => setOpenDrawer(false)} />
        </div>
        <div>
          <div className="custom-fields-drawer-under-header">
            <span>{t('type')}</span>

            <Dropdown
              disabled={data}
              trigger={['click']}
              className="custom-fields-drawer-under-header-dropdown"
              menu={{
                items: CustomFieldsIcons.map((item) => ({
                  key: item.field,
                  label: (
                    <div className="dropdpwn-item">
                      {item.icon}
                      <span>{t(item.label)}</span>
                    </div>
                  ),
                })),
                onClick: handleMenuClick,
              }}
            >
              <div
                className="custom-fields-drawer-under-header-dropdown-item"
                style={{ cursor: data ? 'not-allowed' : 'pointer' }}
              >
                <div className="dropdpwn-view">
                  {data
                    ? CustomFieldsIcons.find((item) => item.field === data.fieldType)?.icon
                    : CustomFieldsIcons.find(
                        (item) => item.field === (selectedField || defaultField?.field),
                      )?.icon}

                  {t(
                    data
                      ? CustomFieldsIcons.find((item) => item.field === data.fieldType)?.label
                      : CustomFieldsIcons.find(
                          (item) => item.field === (selectedField || defaultField?.field),
                        )?.label,
                  )}
                </div>
                <ArrowBottomIcon />
              </div>
            </Dropdown>
          </div>
          <div className="custom-fields-drawer-input-container">
            <Input
              name="fieldName"
              label={t('field_name')}
              formik={formik}
              variant="filled"
              placeholder={t('entre_name')}
              type="text"
              required={false}
              disabled={false}
            />
          </div>
          <div className="custom-fields-drawer-input-container">
            <Input
              name="fieldDescription"
              label={
                <span>
                  {t('description')} <span className="optional"> ({t('optional')}) </span>
                </span>
              }
              formik={formik}
              variant="filled"
              placeholder={t('entre_description')}
              type="text"
              required={false}
              disabled={false}
            />
          </div>
          <div className="custom-fields-drawer-settings">
            <div>
              {data
                ? Fields[data.fieldType]?.component
                : selectedField != '' && Fields[selectedField]?.component}
            </div>
            <div className="custom-fields-drawer-settings-button-container">
              <Button
                children={t('cancel')}
                htmlType="reset"
                onClick={() => {
                  setOpenDrawer(false)
                  setSelectedField(CustomFieldsIcons[0].field)
                  formik.resetForm()
                }}
                className="custom-fields-drawer-settings-button-container-close-button"
              />
              <Button
                children={data ? t('Edit') : t('create')}
                htmlType="submit"
                disabled={isLoading || createCustomFieldIsLoading}
                loading={isLoading || createCustomFieldIsLoading}
                className="custom-fields-drawer-settings-button-container-valide-button"
              />
            </div>
          </div>
        </div>
      </form>
    </Drawer>
  )
}

export default CustomDrawer
