import { Skeleton } from 'antd'

const ImportExportSkeleton = () => (
  <div className="import-export-skeleton-container">
    <div className="import">
      <Skeleton.Avatar
        active
        style={{ width: '72px', height: '72px', borderRadius: '50%' }}
      ></Skeleton.Avatar>

      <div className="import-details">
        <Skeleton.Button
          active
          style={{ width: '420px', height: '18px', borderRadius: '30px' }}
        ></Skeleton.Button>
        <Skeleton.Button
          active
          style={{ width: '420px', height: '18px', borderRadius: '30px' }}
        ></Skeleton.Button>
      </div>
    </div>
    <div className="import">
      <Skeleton.Avatar
        active
        style={{ width: '72px', height: '72px', borderRadius: '50%' }}
      ></Skeleton.Avatar>

      <div className="import-details">
        <Skeleton.Button
          active
          style={{ width: '420px', height: '18px', borderRadius: '30px' }}
        ></Skeleton.Button>
        <Skeleton.Button
          active
          style={{ width: '420px', height: '18px', borderRadius: '30px' }}
        ></Skeleton.Button>
      </div>
    </div>
  </div>
)

export default ImportExportSkeleton
