import React, { createContext, useState, useContext, ReactNode } from 'react'

export const FollowUpContext = createContext(undefined)

export const FollowUpContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [team, setTeam] = useState(null)
  const [teams, setTeams] = useState(null)
  const [question, setQuestion] = useState<string | null>(null)
  return (
    <FollowUpContext.Provider value={{ team, setTeam, question, setQuestion, teams, setTeams }}>
      {children}
    </FollowUpContext.Provider>
  )
}

export const useFollowUpContext = () => {
  const context = useContext(FollowUpContext)
  if (!context) {
    throw new Error('useFollowUpContext must be used within a FollowUpContextProvider')
  }
  return context
}
