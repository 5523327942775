export const orderArray = (array: any[]) => {
  const newArray = []

  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    const id = item && item.id
    const statusId = item && item.status && item.status.id

    newArray.push({
      id,
      statusId,
      order: i,
    })
  }

  return newArray
}
