import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: FiltersState = {
  tasks: null,
  tasksFilterCount: null,
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<setFilterPayload>) => {
      const { filter, model } = action.payload
      state[model] = filter
    },
    resetFilter: (state, action: PayloadAction<resetFilterPayload>) => {
      if (action.payload) {
        const { model } = action.payload
        state[model] = null
      }
    },
  },
})

export const { setFilter, resetFilter } = modalsSlice.actions

export default modalsSlice.reducer
