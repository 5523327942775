import SwitchWorkspaceLoader from '@src/modules/settings/components/SwitchWorkspaceLoader'
import { useAppDispatch } from '../../store'
import { message } from 'antd'
import { initialise } from '@src/modules/auth/data/authSlice'
import axiosInstance from '../../utils/axios'
import { logout, selectWorkspace } from '@src/modules/auth/data/authThunk'
import { setCurrentWorkspace } from '../../utils/setCurrentWorkspace'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const SwitchWorkspace = () => {
  const { newWorkspaceId } = useParams()
  const dispatch = useAppDispatch()
  const handleSwitchWorkspace = (workspaceId: string) => {
    dispatch(selectWorkspace(workspaceId))
      .unwrap()
      .then(async () => {
        setCurrentWorkspace(workspaceId)

        const response = await axiosInstance.get('/auth/me')
        const user = response.data.payload
        dispatch(initialise({ isAuthenticated: true, user }))
        window.location.href = '/dashboard'
      })
      .catch((err) => {
        message.error(err.message)
        dispatch(logout())
      })
  }

  useEffect(() => {
    if (newWorkspaceId) {
      handleSwitchWorkspace(newWorkspaceId)
    } else {
      // navigate()
      dispatch(logout())
      message.error('Work space id not found')
    }
  }, [newWorkspaceId])

  return (
    <div className="loader-container">
      <SwitchWorkspaceLoader />
    </div>
  )
}

export default SwitchWorkspace
