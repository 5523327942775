import { ColorPicker as AntColorPicker } from 'antd'
import { Color } from 'antd/es/color-picker'
import { ReactComponent as ColorPickerIcon } from '../../assets/icons/colorPicker/color-picker.svg'

import React, { useState } from 'react'
import { debounce } from 'lodash'

export interface ColorPickerPropsType {
  defaultColor?: string | null
  onPick?: (color: string) => void
  children?: React.ReactNode
  classNames?: string
  iconClassNames?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const sanitizeHexString = (hexString: string) => {
  return `#${hexString.replace(/[^0-9a-fA-F]/g, '')}`
}

const ColorPicker = (props: ColorPickerPropsType) => {
  const { defaultColor, onPick, children, classNames, style, disabled = false } = props

  const [debouncedOnChange] = useState(() =>
    debounce((color: Color) => {
      onPick(sanitizeHexString(color.toHexString()))
    }, 500),
  )

  const onChange = (color: Color) => {
    debouncedOnChange(color)
  }

  const classes = `color-picker ${classNames}`

  return (
    <div className={classes} style={style}>
      <AntColorPicker
        disabled={disabled}
        defaultValue={defaultColor}
        prefixCls="color-picker"
        onChange={onChange}
        format={'hex'}
        disabledAlpha={true}
      >
        {children ? children : <ColorPickerIcon />}
      </AntColorPicker>
    </div>
  )
}
export default ColorPicker
