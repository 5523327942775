import { defaultProps } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { setGoogleDocsElement } from '@src/modules/document/data/documentSlice/documentSlice'
import { Dropdown, Switch } from 'antd'
import { useEffect, useRef, useState } from 'react'
import darkEmbed from '../../../../../shared/assets/icons/blockNote/darkEmbed.svg'
import docs from '../../../../../shared/assets/icons/blockNote/docs.svg'
import dots from '../../../../../shared/assets/icons/blockNote/dots.svg'
import embed from '../../../../../shared/assets/icons/blockNote/embed.svg'
import iconError from '../../../../../shared/assets/icons/blockNote/error.svg'
import eye from '../../../../../shared/assets/icons/blockNote/eye.svg'
import open from '../../../../../shared/assets/icons/blockNote/open.svg'
import { useTranslation } from 'react-i18next'
import Button from '../../../Button/Button'

const GoogleDocsBlock = createReactBlockSpec(
  {
    type: 'googleDocs',
    propSchema: {
      ...defaultProps,
      link: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: ({ block }: any) => {
      const className = block.props.class || 'embed-container'
      const link = block.props.link

      const { t } = useTranslation(['global'])
      const dispatch = useAppDispatch()
      const docsRef = useRef<string>('')
      const [docsLink, setDocsLink] = useState<string>(link || '')
      const [isError, setIsError] = useState(false)

      const onSwitchChange = () => {}

      const onInputChange = (e) => {
        docsRef.current = e.target.value
        if (
          docsRef.current !== '' &&
          !/^https:\/\/docs\.google\.com\/(?:document|presentation|spreadsheets|forms)\/d\/[a-zA-Z0-9_-]+\/edit(?:\?usp=sharing)?$/.test(
            docsRef.current,
          )
        ) {
          setIsError(true)
        } else setIsError(false)
      }

      const openDocs = () => {
        docsLink && window.open(docsLink, '_blank')
      }

      useEffect(() => {
        if (docsLink) dispatch(setGoogleDocsElement({ link: docsLink }))
      }, [docsLink])

      return (
        <div className={className}>
          <div className="embed-container-left">
            <img src={docs} className="embed-icon" />

            <span className="embed-name">{t('Google Docs')}</span>

            <input
              type="text"
              placeholder={t('Paste or type URL here...')}
              defaultValue={docsLink || ''}
              onChange={onInputChange}
            />
          </div>

          <div className="embed-container-right">
            {isError && (
              <span className="error">
                <img src={iconError} /> {t('Unrecognized URL')}
              </span>
            )}

            {docsLink ? (
              <div className="settings-embed">
                <img src={open} className="open-icon" onClick={openDocs} />

                <Dropdown
                  className={className}
                  dropdownRender={() => (
                    <div className="dropdown-render-settings">
                      <span>
                        <img src={eye} /> {t('Show preview')}
                        <Switch size="small" onChange={onSwitchChange} />
                      </span>
                      <div className="divider"></div>
                      <span>
                        <img src={darkEmbed} /> {t('Copy link to block')}
                      </span>

                      <span>
                        <img src={darkEmbed} /> {t('Copy link to original')}
                      </span>
                    </div>
                  )}
                  trigger={['click']}
                >
                  <img src={dots} />
                </Dropdown>
              </div>
            ) : (
              <Button
                className="btn-embed"
                style={isError ? { cursor: 'not-allowed' } : {}}
                disabled={isError}
                onClick={() => !isError && setDocsLink(docsRef.current)}
              >
                <img src={embed} /> {t('Embed')}
              </Button>
            )}
          </div>
        </div>
      )
    },
  },
)

export default GoogleDocsBlock
