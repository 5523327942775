import React, { useEffect, useState } from 'react'
import { ReactComponent as CollapseIcon } from './../../assets/icons/folder/collapse.svg'
import { ReactComponent as PlusIcon } from './../../assets/icons/folder/plus.svg'
import { ReactComponent as SettingsIcon } from './../../assets/icons/folder/settings.svg'
import {
  manageFiles,
  setSpacesSocketEvent,
  spaceType,
  toggleFolder,
} from '../../data/spaceSlice/spacesSlice'
import { store, useAppDispatch } from '@src/modules/shared/store'
import { Dropdown, Input, Tooltip, message } from 'antd'
import { AddListItems, PermissionesAction, items } from '../../utils/spaceActions'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { updateFolder } from '../../data/folderSlice/folderThunk'
import { ReactComponent as LockIcon } from './../../assets/icons/space/lock.svg'
import { useParams } from 'react-router-dom'
import { useGetListQuery } from '../../services/fileApi'
import { detectLanguage } from '../../utils/detectLanguage'
import { createDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { useTranslation } from 'react-i18next'
import { IGenericFolder } from '@softylines/tam-types'

export let folderActions = {
  onrename: () => {},
  onupdatestatuses: (data) => {
    store.dispatch(
      openModal({ id: 'update-status-modal', data: { ...data, folder: data, type: 'folderId' } }),
    )
  },
  onOpenCustomFieldsManger: (data) => {
    store.dispatch(openModal({ id: 'custom-fields-modal', data: { folder: data } }))
  },
  oncreatelist: (data: FolderType & { group: string }) => {
    store.dispatch(openModal({ id: 'list-modal', data: { folder: data } }))
  },

  oncreatedocument: (data: spaceType, navigate) => {
    store
      .dispatch(createDocument({ folderId: data?.id, name: 'Docs' }))
      .unwrap()
      .then((res) => {
        store.dispatch(
          setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }),
        )
        navigate(`/spaces/document/${res?.payload?.id}`)
      })
  },

  onshare: (data: FolderType) => {
    store.dispatch(openModal({ id: 'share-modal', data: { ...data, type: 'folderId' } }))
  },
  onchangecolor: (data: FolderType & { group: string }, color: string) => {
    store.dispatch(
      setSpacesSocketEvent({ updateType: 'updatedItem', updateEvent: { ...data, color } }),
    )
    store
      .dispatch(updateFolder({ id: data.id, color, name: data.name }))
      .unwrap()
      .then(() => {
        store.dispatch(
          manageFiles({
            targetId: data.id,
            id: data.spaceId || '',
            newObj: { ...data, color },
            type: 'update',
            parent: 'space',
            targetData: 'folders',
            group: data.group,
          }),
        )
      })
      .catch((err) => {
        store.dispatch(
          setSpacesSocketEvent({ updateType: 'updatedItem', updateEvent: { ...data, color } }),
        )
        message.error(err.message || 'Error while update list color')
      })
  },
  ondelete: (data: FolderType & { group: string }) =>
    store.dispatch(
      openModal({ id: 'confirm-modal', data: { source: 'folder', ...data, group: data.group } }),
    ),
}

export interface FolderType extends Partial<IGenericFolder> {
  id: string
  icon?: string
  isOpen?: boolean
  name?: string
  spaceId?: string
  isPrivate?: boolean
  color?: string
  inheritStatusFromParent?: boolean
}

interface FolderProps {
  data: FolderType
  space: spaceType
  group: string
}

const Folder: React.FC<FolderProps> = ({ data, space, group }) => {
  const { t } = useTranslation(['global'])
  const { listId } = useParams()
  const { data: list } = useGetListQuery({ id: listId }, { skip: !listId })
  const [isRename, setRename] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const toggleFolderHandler = (open: boolean | null) =>
    dispatch(
      toggleFolder({
        folderID: data.id,
        group,
        spaceID: space.id || '',
        ...(open !== null ? { open } : {}),
      }),
    )

  useEffect(() => {
    if (list && list?.folderId === data?.id) toggleFolderHandler(true)
  }, [list])

  const saveUpdate = (e: any) => {
    const value = e.target.value
    if (!value.trim()) {
      message.error(t('Invalid name'))
      setRename(false)
    } else if (value !== data.name) {
      dispatch(updateFolder({ id: data.id, name: value }))
        .unwrap()
        .then(() => {
          dispatch(
            manageFiles({
              targetId: data.id,
              id: data.spaceId || '',
              newObj: { ...data, name: value },
              type: 'update',
              parent: 'space',
              targetData: 'folders',
              group,
            }),
          )
        })
        .catch((err) => {
          message.error(err.message || t('Error while update list name'))
        })
        .finally(() => setRename(false))
    } else setRename(false)
  }

  folderActions = { ...folderActions, onrename: () => setRename(!isRename) }

  return (
    <div className={`folder-item ${data.isOpen ? 'active-folder' : ''} shared-folder-item`}>
      <div onClick={() => toggleFolderHandler(null)}>
        <div className="folder-item-icon">
          <FolderIcon color={data.color} open={data.isOpen} />
        </div>

        <div className="folder-item-collapse">
          <CollapseIcon />
        </div>

        <div
          className="folder-item-name"
          style={
            detectLanguage(data?.name) === 'arabic' ? { direction: 'rtl' } : { direction: 'ltr' }
          }
        >
          {!isRename ? (
            <Tooltip title={data.name}>{data.name}</Tooltip>
          ) : (
            <Input
              onClick={(e) => e.stopPropagation()}
              className="sidebar-rename-input"
              defaultValue={data.name || ''}
              onBlur={saveUpdate}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  saveUpdate(e)
                } else if (e.key === 'Escape') {
                  setRename(false)
                }
              }}
              autoFocus
            />
          )}
        </div>
      </div>

      {data.isPrivate ? <LockIcon className="space-lock-icon" /> : null}

      <div className="folder-item-settings">
        <Dropdown
          menu={{
            items: items(
              { ...data, group, resource: 'folders', parent: space },
              [PermissionesAction.NO_OUT_FROM_SPACE, PermissionesAction.NO_CREATE_FOLDER],
              folderActions,
            ),
          }}
          trigger={['click']}
        >
          <span>
            <SettingsIcon />
          </span>
        </Dropdown>

        <Dropdown
          menu={{
            items: AddListItems(
              { ...data, group, resource: 'folders', parent: space },
              [PermissionesAction.NO_CREATE_FOLDER],
              folderActions,
            ),
          }}
          trigger={['click']}
        >
          <span>
            <PlusIcon />
          </span>
        </Dropdown>
      </div>
    </div>
  )
}

export default Folder

export const FolderIcon = ({ color, open = false }: { open?: boolean; color?: string }) => {
  return (
    <>
      {open ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
        >
          <path
            d="M11.8691 4.09445C11.8178 4.03 11.753 3.97808 11.6795 3.9425C11.6059 3.90693 11.5255 3.88861 11.444 3.8889H10.3541V3.33334C10.3541 2.89131 10.1819 2.46739 9.8753 2.15483C9.5687 1.84227 9.15287 1.66668 8.71928 1.66668H5.29696L5.12257 1.11112C5.00952 0.785139 4.79982 0.503069 4.52252 0.30399C4.24523 0.104911 3.91406 -0.0013205 3.5749 1.23909e-05H1.63486C1.20127 1.23909e-05 0.785437 0.175607 0.478841 0.488167C0.172244 0.800727 0 1.22465 0 1.66668V8.33333C0 8.77536 0.172244 9.19929 0.478841 9.51185C0.785437 9.82441 1.20127 10 1.63486 10H9.48221C9.85344 9.9989 10.2133 9.86903 10.5025 9.63175C10.7917 9.39447 10.993 9.06391 11.0735 8.69445L11.989 4.56667C12.0056 4.48385 12.0034 4.39826 11.9826 4.31643C11.9618 4.2346 11.923 4.15868 11.8691 4.09445ZM2.38145 8.45556C2.35377 8.58088 2.28434 8.69255 2.18498 8.77156C2.08562 8.85057 1.96247 8.89203 1.8365 8.88889H1.63486C1.49033 8.88889 1.35172 8.83036 1.24952 8.72617C1.14732 8.62199 1.08991 8.48068 1.08991 8.33333V1.66668C1.08991 1.51933 1.14732 1.37803 1.24952 1.27384C1.35172 1.16965 1.49033 1.11112 1.63486 1.11112H3.5749C3.69374 1.1048 3.81134 1.1383 3.90974 1.20652C4.00814 1.27473 4.08194 1.37391 4.11986 1.4889L4.41413 2.40001C4.4491 2.50598 4.51462 2.59873 4.60213 2.66613C4.68963 2.73353 4.79505 2.77244 4.90459 2.77779H8.71928C8.86381 2.77779 9.00242 2.83632 9.10462 2.94051C9.20682 3.04469 9.26423 3.186 9.26423 3.33334V3.8889H3.81468C3.68871 3.88576 3.56556 3.92722 3.4662 4.00623C3.36684 4.08524 3.29741 4.19691 3.26973 4.32223L2.38145 8.45556ZM10.0108 8.45556C9.98313 8.58088 9.9137 8.69255 9.81434 8.77156C9.71498 8.85057 9.59184 8.89203 9.46586 8.88889H3.38417C3.41231 8.82699 3.43246 8.76163 3.44411 8.69445L4.25065 5.00001H10.7901L10.0108 8.45556Z"
            fill={color || '#0BA5EC'}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
        >
          <path
            d="M9.35 1.57896H5.896L5.72 1.05264C5.6059 0.743816 5.39426 0.476592 5.1144 0.287991C4.83453 0.0993897 4.5003 -0.001251 4.158 1.17387e-05H1.65C1.21239 1.17387e-05 0.792709 0.166364 0.483274 0.462474C0.173839 0.758584 0 1.16019 0 1.57896V8.42105C0 8.83982 0.173839 9.24143 0.483274 9.53754C0.792709 9.83365 1.21239 10 1.65 10H9.35C9.78761 10 10.2073 9.83365 10.5167 9.53754C10.8262 9.24143 11 8.83982 11 8.42105V3.1579C11 2.73914 10.8262 2.33753 10.5167 2.04142C10.2073 1.74531 9.78761 1.57896 9.35 1.57896ZM9.9 8.42105C9.9 8.56064 9.84205 8.69451 9.73891 8.79322C9.63576 8.89192 9.49587 8.94737 9.35 8.94737H1.65C1.50413 8.94737 1.36424 8.89192 1.26109 8.79322C1.15795 8.69451 1.1 8.56064 1.1 8.42105V1.57896C1.1 1.43937 1.15795 1.3055 1.26109 1.2068C1.36424 1.10809 1.50413 1.05264 1.65 1.05264H4.158C4.2733 1.05236 4.38579 1.08676 4.47955 1.15098C4.57331 1.2152 4.6436 1.306 4.6805 1.41054L4.9775 2.27369C5.0144 2.37823 5.08469 2.46903 5.17845 2.53325C5.27221 2.59747 5.3847 2.63187 5.5 2.63159H9.35C9.49587 2.63159 9.63576 2.68704 9.73891 2.78574C9.84205 2.88444 9.9 3.01832 9.9 3.1579V8.42105Z"
            fill={color || '#0BA5EC'}
          />
        </svg>
      )}
    </>
  )
}
