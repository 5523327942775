import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { useEffect, useState } from 'react'
import { Footer } from '../AssignedToMe/AssignedToMe'
import NoNotifications from '../../components/NoNotifications/NoNotifications'
import NotificationCard from '../../components/NotificationCard/NotificationCard'
import NotificationSkeleton from '../../components/NotificationSkeleton/NotificationSkeleton'
import { Virtuoso } from 'react-virtuoso'
import { getAllNotifications } from '@src/modules/notifications/data/NotificationsThunk'

type props = {
  isRead: boolean
}

const Mentions = ({ isRead }: props) => {
  const dispatch = useAppDispatch()
  const { notifications, metadata } = useAppSelector((state) => state.notification)
  const [notificationList, setNotificationList] = useState(notifications?.payload || [])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const loadMoreNotifications = () => {
    if (metadata?.next) {
      setLoading(true)
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    dispatch(getAllNotifications({ page, isRead, type: 'all', isMention: true }))
      .unwrap()
      .then((res) => {
        if (page === 1) {
          setNotificationList(res?.payload?.payload)
        } else {
          setNotificationList((prev) => [...prev, ...res.payload.payload])
        }
        setLoading(false)
      })
  }, [dispatch, isRead, page])

  useEffect(() => {
    if (page === 1) {
      setNotificationList(notifications?.payload || [])
    }
  }, [notifications, page])

  if (loading && page === 1) return <NotificationSkeleton />

  if (notificationList?.length === 0) return <NoNotifications />

  return (
    <Virtuoso
      className="all_notification_section"
      style={{ height: 'calc(100% - 70px)' }}
      data={notificationList}
      endReached={loadMoreNotifications}
      itemContent={(_, notification) => (
        <NotificationCard notification={notification} isRead={isRead} key={notification.id} />
      )}
      components={loading && metadata?.next ? { Footer } : {}}
    />
  )
}

export default Mentions
