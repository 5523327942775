import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { api } from '@src/modules/shared/services/api'

interface TimerTypes {
  Timer?: any
  skip?: number
  search?: string
  limit?: number
  spaceId?: string
  options?: any
}
const defaultLimit = 10
export const TimerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTimer: build.query<any, Partial<{ id: string; limit: number; skip: number; options: any }>>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/timer/${params.id}`,
          params: {
            limit: params?.limit,
            skip: params?.skip,
          },
        }
      },
      transformResponse: (response: { payload }): TimerTypes => {
        return response.payload
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs.arg
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }
        currentCache.metadata = newItems.metadata

        currentCache.payload =
          shouldCache || otherArgs.arg.skip > 1
            ? removeDuplicatesById(currentCache?.payload)
            : newItems?.payload
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
    }),

    addTag: build.mutation<any, any & { name: string; color: string; spaceId: string }>({
      query: (body) => ({
        url: `api/Timer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),
  }),
})

export const { useGetTimerQuery, useAddTagMutation } = TimerApi
