import { useAppDispatch } from '@src/modules/shared/store/index'
import { Tabs, TabsProps, Dropdown } from 'antd'
import React, { useState } from 'react'

import ViewTitle from '@src/modules/shared/components/ViewTitle'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'
import { clearAllNotifications, getNotificationCount } from '../../data/NotificationsThunk'
import NotificationsOptions from '../NotificationsOptions/NotificationsOptions'
import settings from '../../assets/icons/header/settings.svg'
interface HeaderProps {
  items: TabsProps['items']
  activeTab: string
  onTab: (t: string) => void
  setIsRead: Function
  setNotificationList: Function
}

const Header: React.FC<HeaderProps> = ({
  items,
  activeTab,
  onTab,
  setIsRead,
  setNotificationList,
}) => {
  const { t, i18n } = useTranslation(['notifications'])
  const { width } = useWindowSize()

  const dispatch = useAppDispatch()
  const [selectedOption, setSelectedOption] = useState('New')
  const [isOptionsOpened, setIsOptionsOpened] = useState(false)
  const source = activeTab === '1' ? 'IMPORTANT' : activeTab === '3' ? 'MENTION' : ''

  const clearAllNotificationsHanlder = () => {
    dispatch(clearAllNotifications(source)).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        dispatch(getNotificationCount())
        setNotificationList([])
      }
    })
  }

  return (
    <div className="header_notif">
      <ViewTitle>
        <div className="header_top">
          <h2>{t('notifications')}</h2>
        </div>
      </ViewTitle>

      <div className="navs">
        <Tabs
          direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          defaultActiveKey={activeTab}
          items={items}
          onChange={onTab}
        />

        {width > 768 ? (
          <NotificationsOptions
            selectedOption={selectedOption}
            clearAllNotificationsHanlder={clearAllNotificationsHanlder}
            setIsRead={setIsRead}
            setSelectedOption={setSelectedOption}
            t={t}
          />
        ) : (
          <Dropdown
            dropdownRender={() => (
              <div className="dropdown-container">
                <NotificationsOptions
                  selectedOption={selectedOption}
                  clearAllNotificationsHanlder={clearAllNotificationsHanlder}
                  setIsRead={setIsRead}
                  setSelectedOption={setSelectedOption}
                  t={t}
                  isInDropdown={true}
                />
              </div>
            )}
            trigger={['click']}
          >
            <img src={settings} onClick={() => setIsOptionsOpened(!isOptionsOpened)} />
          </Dropdown>
        )}
      </div>
    </div>
  )
}

export default Header
