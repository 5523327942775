import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { Avatar, Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import check from '../../assets/icons/notification/check.svg'
import { setClearedNotification } from '../../data/NotificationsSlice'
import { clearNotification } from '../../data/NotificationsThunk'
import { handleNotificationType } from '../../utils/notificationType'
import { reformulateDate } from '../../utils/reformulateDate'
import AddComment from '../NotificationActions/AddComment/AddComment'
import ChangeName from '../NotificationActions/ChangeName/ChangeName'
import ChangeStatus from '../NotificationActions/ChangeStatus/ChangeStatus'
import DueDate from '../NotificationActions/DueDate/DueDate'
import SetAssignment from '../NotificationActions/SetAssignment/SetAssignment'
import SetEstimation from '../NotificationActions/SetEstimation/SetEstimation'
import SetPriority from '../NotificationActions/SetPriority/SetPriority'
import SetTaskLevel from '../NotificationActions/SetTaskLevel/SetTaskLevel'
import Owner from '../Owner'
import Question from '../Question'
import Reminder from '../Reminder'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

const NotificationCard = ({
  notification,
  isRead,
  isInDashboard,
}: {
  notification: any
  isRead?: boolean
  isInDashboard?: boolean
}) => {
  const { t } = useTranslation(['notifications'])
  const dispatch = useAppDispatch()
  const { owner, createdAt, activity, type, comment, id: notifId, task, reminder } = notification
  const [extraNotifInfoVisible, setExtraNotifInfosVisible] = useState(false)
  const extraInfoUsers = activity?.oldValue?.users || task?.users
  const notifType = handleNotificationType(t, type, activity)
  const createDate = reformulateDate(createdAt)
  const lang = localStorage.getItem('i18nextLng')
  const pathSeparation = lang === 'ar' ? ' < ' : ' > '

  const { width } = useWindowSize()

  if (reminder)
    return (
      <div className="notification_card reminder-card">
        <div className="reminder-card_content">
          {/* <Owner owner={reminder?.question?.manager} /> */}
          <Reminder reminder={reminder} />
          <Question content={reminder?.question?.title} />
        </div>

        {isInDashboard || <span className="reminder-date">{createDate}</span>}
      </div>
    )

  return (
    <RTLStyleDecorator>
      <div
        className={`notification_card ${isInDashboard || 'margin-card'}`}
        onMouseOver={() => {
          setExtraNotifInfosVisible(true)
        }}
        onMouseOut={() => {
          setExtraNotifInfosVisible(false)
        }}
        onClick={() =>
          dispatch(clearNotification(notifId))
            .unwrap()
            .then(() => {
              dispatch(setClearedNotification(notifId))
            })
        }
      >
        <Link
          to={`/spaces/list/${task?.fileId}?taskId=${task?.id}`}
          className="notification_card_left"
        >
          <Owner owner={owner} />
        </Link>

        <Link
          to={`/spaces/list/${task?.fileId}?taskId=${task?.id}`}
          className="notification_card_right"
        >
          <div className="notification_header">
            <div className="notification_header_right">
              <span className="user_name">
                {owner?.fullName ? owner?.fullName : 'Deleted user'}
              </span>

              <span className="notification_type">{notifType}</span>

              <div
                className="notification_action_content"
                style={isInDashboard ? { overflow: 'hidden' } : {}}
              >
                {activity?.newValue?.status && (
                  <ChangeStatus
                    newValue={activity?.newValue?.status}
                    oldValue={activity?.oldValue?.status}
                  />
                )}

                {activity?.newValue?.priority && activity?.action !== 'create' ? (
                  <SetPriority priority={activity?.newValue?.priority} />
                ) : (
                  <></>
                )}

                {activity?.newValue?.name && <ChangeName name={activity?.newValue?.name} />}

                {activity?.newValue?.description && activity?.action !== 'create' && <></>}

                {activity?.newValue?.users ? (
                  <SetAssignment users={activity?.newValue?.users} />
                ) : null}

                {activity?.newValue?.dueDate && <DueDate date={activity?.newValue?.dueDate} />}

                {activity?.newValue?.estimation && (
                  <SetEstimation date={activity?.newValue?.estimation} />
                )}

                {activity?.newValue?.parentId && (
                  <SetTaskLevel parentId={activity?.newValue?.parentId} />
                )}
              </div>
            </div>

            {(extraNotifInfoVisible && width > 1024) || isInDashboard || (
              <span className="notification_create_date">{createDate}</span>
            )}
          </div>

          <RTLStyleDecorator>
            <div className="notification_source">
              <div>
                {activity?.action !== 'create' &&
                  activity?.oldValue?.path?.map((link: any, index: number) => (
                    <RTLStyleDecorator key={index}>
                      <span>
                        {typeof link === 'string' ? link : link?.name}

                        <span>
                          {index !== (activity?.oldValue?.path as string[]).length - 1
                            ? pathSeparation
                            : ''}
                        </span>
                      </span>
                    </RTLStyleDecorator>
                  ))}

                {type !== 'comment' &&
                  ` ${pathSeparation} ${task?.name?.slice(0, 18)} ${
                    task?.name?.slice(0, 18)?.length < task?.name?.length ? '...' : ''
                  }`}
              </div>

              {isInDashboard && (
                <span className="notification_create_date notification_create_date_dashboard">
                  {createDate}
                </span>
              )}
            </div>
          </RTLStyleDecorator>

          {type === 'comment' && (
            <div className="comment_infos">
              <AddComment comment={comment} />
            </div>
          )}
        </Link>

        {extraNotifInfoVisible && width > 1024 && !isInDashboard && (
          <RTLStyleDecorator>
            <div className={`notification_extra_infos `}>
              {notification?.task?.priority > 0 && (
                <div className="task_priority">
                  <SetPriority priority={notification?.task?.priority} />
                </div>
              )}

              <div className="assigned_users">
                {extraInfoUsers?.slice(0, 10).map((user, index) => {
                  return user?.avatar ? (
                    <Avatar src={user?.avatar} key={index}>
                      {user?.fullName && owner?.fullName[0]?.toUpperCase()}
                    </Avatar>
                  ) : user?.icon ? (
                    <Avatar src={user?.icon} key={index}></Avatar>
                  ) : (
                    <span className="user_first_letter" key={index}>
                      {user?.email?.slice(0, 1)}
                    </span>
                  )
                })}
              </div>

              {!isRead && (
                <Tooltip title={t('clear')} placement="top">
                  <img
                    src={check}
                    className="check_icon"
                    onClick={() => {
                      dispatch(clearNotification(notifId))
                        .unwrap()
                        .then(() => {
                          dispatch(setClearedNotification(notifId))
                        })
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </RTLStyleDecorator>
        )}
      </div>
    </RTLStyleDecorator>
  )
}

export default NotificationCard
