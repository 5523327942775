import { useState, useEffect, useRef, useMemo } from 'react'

import Attachements from '../components/Attachements/index'
import useDebounce from '../../../../shared/hooks/useDebounce'
import { useSearchParams } from 'react-router-dom'
import {
  useGetTaskQuery,
  useUpdateTaskMutation,
  useUploadTaskAttachementsMutation,
} from '@src/modules/tasks/services/tasksApi'
import { message } from 'antd'
import { removeDuplicateAttachments } from '../../../../shared/utils/arrays'
import { removeFirstDuplicateById } from '../../../utils/removeDuplicate'
import { resetSocketEvents } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { useAppDispatch } from '@src/modules/shared/store'
import { useTranslation } from 'react-i18next'

type Props = {
  taskAttachments: EnhancedUploadFile<File>[]
}

function SetTaskAttachements({ taskAttachments }: Props) {
  const { t } = useTranslation(['taskDetails'])
  const [attachments, setAttachments] = useState<EnhancedUploadFile<File>[]>()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadTaskAttachments] = useUploadTaskAttachementsMutation()
  const [updateTask] = useUpdateTaskMutation()
  const [files, setFiles] = useState<File[]>([])

  const isFirstRender = useRef(true)
  const { data: task, refetch } = useGetTaskQuery({ id: taskId } as any)
  const dispatch: Function = useAppDispatch()
  const [lastUploadedFiles, setLastUploadedFiles] = useState<File[]>([])

  const combinedAttachments = useMemo(
    () =>
      attachments?.length > 0 && removeDuplicateAttachments([...taskAttachments, ...attachments]),
    [taskAttachments, attachments],
  )

  useEffect(() => {
    isFirstRender.current = false
    task && task?.attachements && setAttachments(task?.attachements as any)
  }, [task])

  const uploadFiles = () => {
    setLoading(true)

    uploadTaskAttachments({ taskId, files: removeDuplicateAttachments(files as any) } as any)
      .then((res: any) => {
        if (res?.data) {
          const filesLocations = res?.data?.payload?.map((file) => file.id)
          if (filesLocations?.length > 0) {
            updateTask({
              id: taskId,
              attachementsId: removeFirstDuplicateById(filesLocations) as string[],
            } as any)
              .unwrap()
              .then(() => {
                setLastUploadedFiles((prev) => [...prev, ...files])
                refetch()
                dispatch(resetSocketEvents())
              })
              .finally(() => setLoading(false))
          }
        } else {
          message.error(t('large_file_size'))
        }
      })
      .catch(() => {
        message.error(t('error_upload_attachment'))
      })
  }

  useDebounce(
    () => {
      if (files.length > 0) {
        uploadFiles()
      }
    },
    1000,
    [files],
  )
  return (
    <Attachements
      task={task}
      attachments={combinedAttachments}
      setAttachments={(newAttachments) => {
        const newFiles = newAttachments.map((file) => file.originFileObj)

        setFiles(
          newFiles.filter(
            (file) => !lastUploadedFiles.some((lastFile) => lastFile.name === file.name),
          ),
        )
      }}
      isLoading={loading}
    />
  )
}

export default SetTaskAttachements
