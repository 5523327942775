import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { api } from '@src/modules/shared/services/api'

interface getMembersPayload {
  skip?: number
  limit?: number
  search?: string
}

const defaultLimit = 3

export const MembersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMembers: build.query<any, getMembersPayload>({
      query: (params) => {
        params = { limit: defaultLimit, ...params }
        return {
          url: `api/users`,
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
          currentCache.metadata = newItems.metadata
        }
        currentCache.payload = removeDuplicatesById(currentCache?.payload)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
  }),
})

export const { useGetMembersQuery } = MembersApi
