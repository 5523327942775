import {
  getClickUpSpaces,
  getClickupFolders,
  getClickupFoldersLists,
  getSpacesList,
} from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import {
  addLoading,
  convertToTemplateTree,
  removeLoading,
} from '@src/modules/shared/components/SpacesTree/SpacesTree'
import { useAppDispatch } from '@src/modules/shared/store'
import { useEffect, useState } from 'react'
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree'
import { renderItem } from '@src/modules/shared/components/SpacesTree/helpers/renderItem'
import { TaskSkeleton } from '@src/modules/tasks/components/EditTaskPopup/components/SubTasksTree/helpers/renderItem'

interface props {
  spaces: any
  setSelectedSpaces: Function
}

const SelectSpacesTree = ({ spaces, setSelectedSpaces }: props) => {
  const dispatch = useAppDispatch()
  const token = localStorage.getItem('clickup_access_token')

  const [tree, setTree] = useState<any>({ root: {} })
  const [focusedItem, setFocusedItem] = useState()
  const [expandedItems, setExpandedItems] = useState([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedItems.length === 0) setSelectedSpaces([])
  }, [selectedItems])

  useEffect(() => {
    expandManagement(tree)
  }, [expandedItems])

  useEffect(() => {
    if (token) setLoading(true)
    dispatch(getClickUpSpaces(token))
      .unwrap()
      .then((res) => {
        const clickupSpacesIds = res?.map((item) => item?.id)
        const clickupSpaces = convertToTemplateTree(res, 'space')
        setTree({
          ...tree,
          root: { ...tree?.root, children: clickupSpacesIds },
          ...clickupSpaces,
        })
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }, [])

  const expandManagement = (tree) => {
    expandedItems?.forEach(async (itemId) => {
      const item = tree[itemId]

      if (item?.children) return
      addLoading(item, setTree)

      let itemChilds = null
      let childsIds = null
      let spaceFolders = null
      let spaceLists = null

      if (item?.type === 'space') {
        const foldersResponse = await dispatch(getClickupFolders({ token, spaceId: itemId }))
        spaceFolders = convertToTemplateTree(foldersResponse.payload, 'folder')
        const listsResponse = await dispatch(getSpacesList({ token, spaceId: itemId }))
        spaceLists = convertToTemplateTree(listsResponse.payload, 'list')

        itemChilds = { ...spaceFolders, ...spaceLists }
        const listIds = Object.keys(spaceLists || {})
        const folderIds = Object.keys(spaceFolders || {})

        childsIds = [...listIds, ...folderIds]
      } else if (item?.type === 'folder') {
        const response = await dispatch(getClickupFoldersLists({ token, folderId: itemId }))
        itemChilds = {
          ...convertToTemplateTree(response?.payload, 'list'),
        }
      }

      childsIds = Object.keys(itemChilds || {})
      setTree((tree) => ({
        ...tree,
        [itemId]: { ...tree[itemId], children: childsIds },
        ...itemChilds,
      }))
      removeLoading(item, setTree)
    })
  }

  const selectItems = (items: string[]) => {
    const item = tree[items[0]]
    const isSelected = selectedItems?.includes(item.id)
    const isHalfSelected = item.halfSelect
    const treeClone = structuredClone(tree)
    const parentId = (
      (!item?.content?.folder?.hidden && item?.content?.folder) ||
      item?.content?.space
    )?.id
    const childrens = tree[parentId]?.children
    const newSelectedItems = selectedItems?.includes(item.id)
      ? selectedItems?.filter((el) => el !== item.id)
      : [...selectedItems, item.id]
    setSelectedItems([...newSelectedItems])
    if (item.type === 'space') {
      const isSpaceExist = spaces?.some((el) => el.id === item.id)
      const newSelectedSpaces = isSpaceExist
        ? spaces?.filter((el) => el.id !== item.id)
        : [...spaces, { id: item.id }]

      setSelectedSpaces(newSelectedSpaces)
    } else if (item.type === 'folder') {
      const spaceParentId = item?.content?.space?.id
      let spaceParentIndex = spaces?.findIndex((el) => el.id === spaceParentId)

      if (spaceParentIndex >= 0) {
        const isFolderExist = spaces[spaceParentIndex]?.folders?.some((el) => el.id === item.id)

        spaces[spaceParentIndex].folders = isFolderExist
          ? spaces[spaceParentIndex]?.folders?.filter((el) => el.id !== item.id)
          : [...spaces[spaceParentIndex]?.folders, { id: item.id, lists: [] }]
      } else {
        if (spaceParentIndex === -1) {
          setSelectedSpaces([
            ...spaces,
            {
              id: spaceParentId,
              folders: [{ id: item.id, lists: [] }],
              lists: [],
            },
          ])
        }
      }
    } else if (item.type === 'list') {
      const spaceParentId = item?.content?.space?.id
      const folderParentId = !item?.content?.folder?.hidden ? item?.content?.folder?.id : null
      let spaceParentIndex
      let folderParentIndex

      // list inside space directly
      if (!folderParentId) {
        spaceParentIndex = spaces?.findIndex((el) => el.id === spaceParentId)

        if (spaceParentIndex >= 0) {
          const findList = spaces[spaceParentIndex]?.lists?.filter((el) => el.id === item.id)
          spaces[spaceParentIndex].lists =
            findList?.length > 0
              ? spaces[spaceParentIndex]?.lists?.filter((el) => el.id !== item.id)
              : [...spaces[spaceParentIndex].lists, { id: item.id }]
        } else if (spaceParentIndex === -1) {
          setSelectedSpaces([
            ...spaces,
            {
              id: spaceParentId,
              lists: [{ id: item.id }],
              folders: [],
            },
          ])
        }
      }
      //list inside folder
      else {
        spaceParentIndex = spaces.findIndex((el) => el.id === spaceParentId)

        // spaceParent doesnt exist
        if (spaceParentIndex === -1) {
          setSelectedSpaces([
            ...spaces,
            {
              id: spaceParentId,
              folders: [{ id: folderParentId, lists: [{ id: item.id }] }],
              lists: [],
            },
          ])
        }
        // spaceParent exists
        else if (spaceParentIndex >= 0) {
          spaceParentIndex = spaces.findIndex((el) => el.id === spaceParentId)
          folderParentIndex = spaces[spaceParentIndex].folders.findIndex(
            (el) => el.id === folderParentId,
          )

          // folderParent doesnt exist
          if (folderParentIndex === -1) {
            const newFolderWithList = { id: folderParentId, lists: [{ id: item.id }] }
            spaces[spaceParentIndex].folders = [
              ...spaces[spaceParentIndex].folders,
              newFolderWithList,
            ]
          }
          // folderParent exists
          if (folderParentIndex >= 0) {
            const isListInFolder = spaces[spaceParentIndex].folders[folderParentIndex].lists.some(
              (el) => el.id === item.id,
            )

            spaces[spaceParentIndex].folders[folderParentIndex].lists = isListInFolder
              ? spaces[spaceParentIndex].folders[folderParentIndex].lists.filter(
                  (el) => el.id !== item.id,
                )
              : [...spaces[spaceParentIndex].folders[folderParentIndex].lists, { id: item.id }]
          }
        }
      }
    }

    if (item?.type === 'folder' || item?.type === 'list') {
      treeClone[parentId].halfSelect = true
      setTree(treeClone)
    }

    if (isSelected) {
      const selectedchild = childrens?.some((el) => newSelectedItems.includes(el))
      if (selectedchild) {
        treeClone[parentId].halfSelect = true
        setTree(treeClone)
        setSelectedItems(newSelectedItems.filter((item) => item !== parentId))
      } else if (treeClone[parentId]) {
        treeClone[parentId].halfSelect = false
        setTree(treeClone)
      }
    } else {
      const allSelected = childrens?.every((el) => newSelectedItems.includes(el))
      if (allSelected) {
        setSelectedItems([...newSelectedItems, parentId])
        treeClone[parentId].halfSelect = false
        setTree(treeClone)
      }
    }

    if (item.type === 'space' || item.type === 'folder') {
      const childrens = item.children
      if (isSelected) {
        setSelectedItems(newSelectedItems?.filter((el) => !childrens?.includes(el)))
      } else if (isHalfSelected) {
        treeClone[item.id].halfSelect = false
        setTree(treeClone)
        setSelectedItems(newSelectedItems?.filter((el) => childrens?.includes(el)))
        setSelectedItems(selectedItems?.filter((el) => el === item?.id))
      } else {
        setSelectedItems([...newSelectedItems, ...childrens])
      }
    }
  }

  if (loading)
    return (
      <div className="space-tree-loading">
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
      </div>
    )

  return (
    <ControlledTreeEnvironment
      items={tree}
      getItemTitle={(item) => item.data}
      onFocusItem={(item: any) => setFocusedItem(item.index)}
      renderItem={(props) =>
        renderItem({
          ...props,
          tree,
          multiple: false,
          readOnly: true,
          autoOpen: false,
          isImport: true,
          activeItem: 'space',
        } as any)
      }
      onCollapseItem={(item) =>
        setExpandedItems(
          expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index),
        )
      }
      onExpandItem={(props: any) => setExpandedItems((prev) => [...prev, props.id])}
      viewState={{
        ['tree-1']: {
          focusedItem,
          expandedItems,
          selectedItems,
        },
      }}
      onSelectItems={selectItems}
    >
      <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
    </ControlledTreeEnvironment>
  )
}

export default SelectSpacesTree
