import right from '../../../assets/icons/notification/arrow_right.svg'

const ChangeStatus = ({ newValue, oldValue }: { newValue: any; oldValue: any }) => {
  const lang = localStorage.getItem('i18nextLng')

  return (
    <div className="change_status_card">
      <div className="status" style={{ backgroundColor: `${oldValue?.color}50` }}>
        <div className="circle" style={{ backgroundColor: oldValue?.color }}></div>
        <p>{oldValue?.name}</p>
      </div>

      <img src={right} className={`${lang === 'ar' && 'reversed-icon'}`} />

      <div className="status" style={{ backgroundColor: `${newValue?.color}50` }}>
        <div className="circle" style={{ backgroundColor: newValue?.color }}></div>
        <p>{newValue?.name}</p>
      </div>
    </div>
  )
}

export default ChangeStatus
