export const getDataType = (data) => {
  switch (data.type) {
    case 'folderId':
      return 'folder'
      break
    case 'spaceId':
      return 'space'
    case 'fileId':
      return 'list'
      break
    case 'documentId':
      return 'document'
      break
    default:
      'Not valid data type'
  }
}
