import { priorities } from '@src/modules/spaces/__mock__'

const SetPriority = ({ priority }: { priority: number }) => {
  const taskPriority = priorities.find((elem) => elem.id === priority)

  return (
    <div className="set_prioriry_card">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
      >
        <path
          d="M1 1V10.1429H12.0878C12.6229 10.1429 12.8904 10.1429 12.9529 9.98474C13.0154 9.82663 12.8201 9.64374 12.4296 9.27796L12.4296 9.27795L8.51753 5.61335C8.37146 5.47653 8.29843 5.40812 8.29843 5.32143C8.29843 5.23473 8.37146 5.16632 8.51752 5.0295L12.4296 1.36491C12.8201 0.99912 13.0154 0.816226 12.9529 0.658113C12.8904 0.5 12.6229 0.5 12.0878 0.5H1.5C1.2643 0.5 1.14645 0.5 1.07322 0.573223C1 0.646447 1 0.764298 1 1Z"
          fill={taskPriority.color}
        />
        <path
          d="M1 10.1429V1C1 0.764298 1 0.646447 1.07322 0.573223C1.14645 0.5 1.2643 0.5 1.5 0.5H12.0878C12.6229 0.5 12.8904 0.5 12.9529 0.658113C13.0154 0.816226 12.8201 0.99912 12.4296 1.36491L8.51752 5.0295C8.37146 5.16632 8.29843 5.23473 8.29843 5.32143C8.29843 5.40812 8.37146 5.47653 8.51753 5.61335L12.4296 9.27795C12.8201 9.64374 13.0154 9.82663 12.9529 9.98474C12.8904 10.1429 12.6229 10.1429 12.0878 10.1429H1ZM1 10.1429V15.5"
          stroke={taskPriority.color}
          strokeLinecap="round"
        />
      </svg>

      <span>{taskPriority.name}</span>
    </div>
  )
}

export default SetPriority
