import SpacesTree, {
  treeSupportedTypes,
} from '@src/modules/shared/components/SpacesTree/SpacesTree'
import { useAppSelector } from '@src/modules/shared/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getTreeItemById } from '../../utils/getTreeItemById'
import { sidebarItemIDs } from '../SidebarSpaces/SidebarSpaces'
import SharedTree, {
  sharedTreeSupportedTypes,
} from '@src/modules/shared/components/SpacesTree/SharedTree'

interface SidebarSpacesProps {
  id: sidebarItemIDs
}

export type TreeItemType = {
  type: sharedTreeSupportedTypes | treeSupportedTypes
  id: string
}

const DragableSpacesTree = () => {
  const { listId, documentId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <SpacesTree
      autoOpen
      drag
      data-testid="spaces-tree"
      item={getTreeItemById(listId, documentId)}
      onSelectSpace={() => {}}
      onSelectList={(lists) => {
        navigate({
          pathname: `/spaces/list/${lists[0]}`,
          search: location.search,
        })
      }}
      onSelectDocument={(documents) => navigate(`/spaces/document/${documents[0]}`)}
      onSelectFolder={() => {}}
    />
  )
}

const renderSpaces = () => {
  return <DragableSpacesTree />
}

const DragableSharesTree = () => {
  const { listId, documentId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <SharedTree
      autoOpen
      drag
      data-testid="shared-tree"
      item={getTreeItemById(listId, documentId)}
      onSelectList={(lists) =>
        navigate({
          pathname: `/spaces/list/${lists[0]}`,
          search: location.search,
        })
      }
      onSelectDocument={(documents) => navigate(`/spaces/document/${documents[0]}`)}
      onSelectFolder={() => {}}
    />
  )
}

const renderShared = () => {
  return <DragableSharesTree />
}

const getActiveItem = (data: any, key: sidebarItemIDs): any => {
  const renderProjectsFn: { [key in sidebarItemIDs]: any } = {
    [sidebarItemIDs.SHARE]: renderShared,
    [sidebarItemIDs.PROJECTS]: renderSpaces,
    [sidebarItemIDs.FAVORITIES]: undefined,
    [sidebarItemIDs.ARCHIVE]: undefined,
  }

  return { data: data[key], renderFn: renderProjectsFn[key] }
}

const Spaces: React.FC<SidebarSpacesProps> = ({ id }) => {
  const { t } = useTranslation(['sidebar'])
  const { favorities, share, projects, archive } = useAppSelector((state) => state.spaces)
  const { data, renderFn } = getActiveItem({ favorities, share, projects, archive }, id) || {}

  return (
    <div className="sidebar-spaces-group">
      {id === sidebarItemIDs.ARCHIVE || id === sidebarItemIDs.FAVORITIES ? (
        <p>{t('coming_soon')}</p>
      ) : (
        renderFn && renderFn({ data, id })
      )}
    </div>
  )
}

export default Spaces
