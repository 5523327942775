import Button from '@src/modules/shared/components/Button/Button'
import { Avatar, Checkbox } from 'antd'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useGetQuestionsQuery } from '../../services/managerQuestionsApi'
import Spinner from '@src/modules/shared/components/Spinner'
import Calendar from './components/Calendar/Calendar'
import { generateNonWatcher } from '../QuestionForm/QuestionForm'
import { useState } from 'react'
import TeamsMmebersList from '../QuestionForm/TeamsMmebersList'
import { ReactComponent as ManagerIcon } from '../../assets/icons/manager/manager.svg'
import { ReactComponent as EmptySearch } from '../../assets/icons/advanced/emty-adv.svg'
import { answersApi } from '../../services/answersApi'
import OneAnswer from '../MemberView/components/AnswersList/OneAnswer'
import { useAppSelector } from '@src/modules/shared/store'
import { ActionsResponse } from '../MemberView/components/AnswersList/answersList.inteface'
import MemberEmptyView from '../MemberEmptyView/MemberEmptyView'

interface FormValues {
  questionId: string[]
  startDate: string
  endDate: string
  usersIds: string[]
}

const initialValues: FormValues = {
  questionId: [],
  startDate: '',
  endDate: '',
  usersIds: [],
}

export default function AdvancedSearch({ team }) {
  const { t } = useTranslation(['dailyQuestion'])
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      questionId: Yup.array()
        .min(1, t('select_at_least_one_question'))
        .required('Question selection is required'),
    }),
    onSubmit: (values) => {
      const options = {
        skip: 1,
        limit: 100,
        options: JSON.stringify({
          where: {
            question: {
              teamId: {
                operation: '$Equal',
                value: team?.id,
              },
              id: {
                operation: '$in',
                value: values.questionId,
              },
            },
            date: {
              operation: '$between',
              value: [values.startDate, values.endDate],
            },
            ...(values.usersIds && values.usersIds.length > 0
              ? {
                  ownerId: {
                    operation: '$in',
                    value: values.usersIds,
                  },
                }
              : {}),
          },
        }),
      }
      trigger(options)
    },
  })

  const handleRadioChange = (value: string) => {
    const questionId = formik.values.questionId
    if (questionId.includes(value)) {
      formik.setFieldValue(
        'questionId',
        questionId.filter((id) => id !== value),
      )
    } else {
      formik.setFieldValue('questionId', [...questionId, value])
    }
  }
  const { data, isLoading } = useGetQuestionsQuery(
    {
      teamId: team?.id,
    },
    {
      skip: !team?.id,
    },
  )
  const answersData = useAppSelector((state) => state.answers)
  const [newQuestion, _] = useState<any>(initialValues)

  const currentKey = (answers) => {
    let key = 'none'
    if (answers?.payload[0]) {
      let date = new Date(answers?.payload[0]?.date)
      let dateQuery
      date.setDate(date.getDate() + 1)
      dateQuery = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${date.getDate().toString().padStart(1, '0')}`
      key = `${answers?.payload[0]?.question?.id}-${dateQuery}`
    }
    return key
  }

  const [trigger, { data: answers, isLoading: loading }] =
    answersApi.endpoints.searchAnswers.useLazyQuery()
  return (
    <>
      {data?.length > 0 ? (
        <div className="followup_advancedSearch-section">
          <div className="followup_advancedSearch">
            <form onSubmit={formik.handleSubmit} className="followup_advancedSearch-form">
              <p className="followup_advancedSearch-input">{t('advanced_search')}</p>
              <div className="followup_advancedSearch-questions">
                <p className="form-label followup_advancedSearch-label">
                  {t('select_the_question')}
                </p>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div className="followup_advancedSearch-questions_section">
                    {data?.map((question, i) => (
                      <div key={i} className="followup_advancedSearch-radio">
                        <Checkbox
                          onChange={(e) => handleRadioChange(e.target.value)}
                          value={question?.id}
                        />
                        <p className="followup_advancedSearch-question">{question?.title}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {formik.errors.questionId && formik.touched.questionId && (
                <p className="followup_advancedSearch-error-message">{formik.errors.questionId}</p>
              )}
              <div>
                <p className="form-label followup_advancedSearch-label">{t('select_time_range')}</p>
                <Calendar
                  onChange={(date) => {
                    formik.setFieldValue('startDate', date?.startDate)
                    formik.setFieldValue('endDate', date?.endDate)
                  }}
                />
              </div>
              <div className="followup_advancedSearch-users">
                <p className="form-label followup_advancedSearch-label">{t('which_member')}</p>
                <div className="target-team">
                  <div className="team-icon">
                    <Avatar src={team?.icon} style={{ background: !team?.icon && team?.color }}>
                      {team?.name ? team?.name[0]?.toUpperCase() : '-'}
                    </Avatar>
                  </div>
                  <div className="team-info">
                    <span>{team?.name}</span>
                    <span>({generateNonWatcher(team)?.length} members)</span>
                  </div>
                  <div className="team-role">
                    <ManagerIcon />
                  </div>
                </div>
                <TeamsMmebersList
                  members={generateNonWatcher(team)}
                  onSelect={(members) => formik.setFieldValue('usersIds', members)}
                  defaultSelected={newQuestion?.usersIds}
                />
              </div>
              <div className="followup_advancedSearch-buttons">
                <Button className="followup_advancedSearch-button" htmlType="submit">
                  {t('search')}
                </Button>
              </div>
            </form>
          </div>
          <div className="followup_advancedSearch_preview">
            {loading ? (
              <span className="questions-loader"></span>
            ) : answers?.payload?.length == 0 || !answers ? (
              <div className="followup_advancedSearch_preview-empty">
                <EmptySearch />
                {t('no_filter')}
              </div>
            ) : (
              <div className="followup_advancedSearch_answers">
                {!loading &&
                  answersData?.answers[currentKey(answers)]?.map(
                    (el: ActionsResponse, index: number) => {
                      return (
                        <OneAnswer
                          answer={el}
                          team={team}
                          key={index}
                          AnswersKey={currentKey(answers)}
                          questionId={el?.question?.id}
                        />
                      )
                    },
                  )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <MemberEmptyView />
      )}
    </>
  )
}
