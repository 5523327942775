import { Checkbox, Dropdown, Row, Skeleton } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '@src/modules/tasks/utils/hexTorgba'
import TagsActions from '../TagsActions/index'
import { ReactComponent as CrossIcon } from '@src/modules/tasks/assets/icons/task/cross.svg'
import { ReactComponent as DotsIcon } from '@src/modules/tasks/assets/icons/task/dots.svg'
import { ReactComponent as TagIcon } from '@src/modules/tasks/assets/icons/task/tag.svg'
import { TagTypes } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import Input from '../../Input/Input'

type TagsListProps = {
  tagName: string

  selectedTags: TagTypes[]
  setSelectedTags: (selectedTags: any) => void
  allTags: { metadata: any; payload: TagTypes[] }
  page: number
  setPage: (v: number) => void
  setTagName: (tagName: string) => void
  loadMore: () => void
  isLoading: boolean
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  updateTask: (tags: TagTypes[]) => void
  handleTagNameChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDeleteTag: (tagId: string) => void
  handleEditColor: Function
  handleKeyDownTaskAction: Function
  handleTagSelect: (tagId: string, value) => void
}

const TagsList = ({
  tagName,

  allTags,
  selectedTags,
  setSelectedTags,
  page,
  setPage,
  isLoading,
  handleKeyDown,
  handleTagNameChange,
  handleDeleteTag,
  updateTask,
  handleEditColor,
  handleKeyDownTaskAction,
  handleTagSelect,
}: TagsListProps) => {
  const { t } = useTranslation(['taskDetails'])
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef?.current?.focus()
  }, [])
  const [openedDropdown, setOpenedDropdown] = useState<number | null>(null)
  const handleDropdownVisibleChange = (tagId: number, visible: boolean) => {
    if (visible) {
      setOpenedDropdown(tagId)
    } else {
      setOpenedDropdown(null)
    }
  }
  const handleSelectStyle = (tag) => {
    return {
      color: tag?.color,
      backgroundColor: hexToRgba(tag?.color, 0.25),
      padding: '3px 10px',
      borderRadius: '16px',
    }
  }

  return (
    <div className="tag-list-container">
      <div style={{ width: '100%' }}>
        <div className="input-title-container">
          <div className="selected-tag-container">
            {selectedTags?.map((t) => (
              <span
                key={t?.id}
                className="selected-tag"
                style={{
                  color: t?.color,
                  backgroundColor: hexToRgba(t?.color, 0.25),
                }}
              >
                <span className="selected-tag-name">{t?.name}</span>

                <span
                  style={{
                    cursor: 'pointer',
                    stroke: t?.color,
                  }}
                  className="cross-icon"
                  onClick={() => {
                    setSelectedTags((prev) => {
                      const updatedTags = prev?.filter((tag) => tag?.id !== t?.id)
                      updateTask(updatedTags)
                      return updatedTags
                    })
                  }}
                >
                  <CrossIcon />
                </span>
              </span>
            ))}
          </div>

          <div className="input-container">
            <TagIcon />

            <Input
              placeholder={t('Type new tag')}
              onChange={(e) => handleTagNameChange(e)}
              onKeyDown={handleKeyDown}
              value={tagName}
              ref={inputRef}
              autoFocus
              variant="label-inline"
              containerClassName="tag-input"
            />
          </div>

          <div className="title">{t('select_or_create_tag')}</div>
        </div>

        <div
          className="tag-list"
          onScroll={(e) => handleScroll(e, allTags?.metadata.total_items || 0, page, 20, setPage)}
        >
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              {allTags?.payload?.map((tag: any) => (
                <div className="tag-item">
                  <Checkbox
                    checked={selectedTags?.some((selectedTag) => selectedTag.id === tag.id)}
                    onChange={(e) => handleTagSelect(tag, e.target.checked)}
                  >
                    <span style={handleSelectStyle(tag)}>{tag?.name}</span>
                  </Checkbox>

                  <Dropdown
                    onOpenChange={(visible) => handleDropdownVisibleChange(tag?.id, visible)}
                    visible={openedDropdown === tag?.id}
                    overlay={
                      <>
                        <TagsActions
                          data={tag}
                          setOpenedDropdown={setOpenedDropdown}
                          handleDeleteTag={handleDeleteTag}
                          handleEditColor={handleEditColor}
                          handleKeyDown={handleKeyDownTaskAction}
                        />
                      </>
                    }
                    trigger={['click']}
                  >
                    <div className="DotsIcon">
                      <DotsIcon />
                    </div>
                  </Dropdown>
                </div>
              ))}
              {allTags?.metadata?.next && <LoadingInfintyScrollSkeleton />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default TagsList

const LoadingInfintyScrollSkeleton = () => {
  return (
    <div className="tag-list-skeleton">
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 250 }} />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 250 }} />
      </Row>
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <div className="tag-list-skeleton">
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 280 }} />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 280 }} />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 280 }} />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 280 }} />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 280 }} />
      </Row>
    </div>
  )
}
