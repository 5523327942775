import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { Tooltip } from 'antd'
import { ReactComponent as TickIcon } from '@src/modules/customFields/assets/tick-icon.svg'
import {
  hideCustomFieldColumn,
  setCustomFieldColumn,
} from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { allCustomFields } from '@src/modules/tasks/components/ListView/utils/CustomFieldComponentMap.tsx'
import { useTranslation } from 'react-i18next'
const FieldsVisibility = () => {
  const { t } = useTranslation(['customFields'])
  const { customFields } = useAppSelector((state) => state.tasks)
  const dispatch = useAppDispatch()

  const filtredFields = allCustomFields
  const hiddenFields = filtredFields.filter(
    (field) => !customFields?.find((column) => column.field === field.field),
  )
  const shownFields = filtredFields.filter((field) =>
    customFields?.find((column) => column.field === field.field),
  )

  const hideField = (name: string) => {
    dispatch(hideCustomFieldColumn(name))
  }

  const showField = (field) => {
    const newField = {
      id: field.field,
      field: field.field,
      fieldName: field.fieldName,
      width: 200,
      minWidth: 200,
      sortBy: false,
      order: 1,
      hidden: false,
      maxWidth: 300,
    }
    dispatch(setCustomFieldColumn(newField))
  }

  return (
    <div className="custom-fields-tab">
      <div className="tab-title">
        <span>{t('show_columns')}</span>
      </div>
      {shownFields?.map((column) => {
        return (
          <Tooltip title={column.disbaled && 'This field is disabled'} placement="top">
            <div
              className={`field-item ${column.disbaled ? 'disabled-field-item' : ''}`}
              onClick={() => !column.disbaled && hideField(column.field)}
            >
              <div className="field-item-name">{t(column.fieldName)}</div>
              <div className="field-item-toggle">
                <TickIcon />
              </div>
            </div>
          </Tooltip>
        )
      })}
      {hiddenFields?.map((field) => {
        return (
          <div
            className={`field-item ${field.disbaled ? 'disabled-field-item' : ''}`}
            onClick={() => !field.disbaled && showField(field)}
          >
            <div className="field-item-name">{t(field.fieldName)}</div>
          </div>
        )
      })}
    </div>
  )
}

const CustomFieldsManagmentSidebar = () => {
  return (
    <div className="custom-fields-managment-sidebar">
      <FieldsVisibility />
    </div>
  )
}

export default CustomFieldsManagmentSidebar
