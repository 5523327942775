import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import QuestionForm from '../QuestionForm'
import { renderDays } from '../QuestionForm/QuestionForm'
import { ReactComponent as ThreeDotsSVG } from './../../assets/icons/manager/question-dots.svg'
import { ReactComponent as DeleteSVG } from './../../assets/icons/answers/delete.svg'
import { ReactComponent as EditSVG } from './../../assets/icons/answers/edit.svg'
import { Dropdown } from 'antd'
import { useDeleteQuestionMutation } from '../../services/managerQuestionsApi'
import { reformulateDate } from '../Modals/AnswerDetailsModal/AnswerDetailsModal'
import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { useTranslation } from 'react-i18next'

export const QuestionItem = ({ question, index, onClick }) => {
  const { startTime, endTime, days } = question || {}
  const startTimeValues = startTime?.split(':')
  const endTimeValues = endTime?.split(':')
  const [deleteQuestion] = useDeleteQuestionMutation()
  const { t } = useTranslation(['dailyQuestion'])

  const menuItems = [
    {
      key: '1',
      label: t('edit_question'),
      onClick: () => onClick(),
      icon: <EditSVG />,
    },
    {
      key: '2',
      label: t('delete'),
      icon: <DeleteSVG />,
      onClick: () => deleteQuestion(question?.id),
    },
  ]

  return (
    <div className="question-item">
      <div className="question-item-section1">
        <Dropdown menu={{ items: menuItems }} trigger={['click']}>
          <ThreeDotsSVG className="question-item-dots" onClick={(e) => e.stopPropagation()} />
        </Dropdown>
        <div className="question-item-index" onClick={onClick}>
          {t('question')} {index + 1}
        </div>
        <ArabicElement
          content={question?.title}
          tooltip
          className="question-item-title"
          onClick={onClick}
          tag="div"
        />
        <div className="question-item-date" onClick={onClick}>
          {t('posted_on')} {reformulateDate(question?.createdAt)}
        </div>
      </div>
      <div className="question-item-section2" onClick={onClick}>
        <div className="question-item-days">
          <span>{t('daily_on')}</span>
          <div className="form-days">{renderDays(days, () => {}, true)}</div>
        </div>
        <div className="question-item-time">
          <span>{t('from')}</span>
          <div className="time">
            <span>{startTimeValues[0]}</span>:<span>{startTimeValues[1]}</span>
          </div>
          <span>{t('to')}</span>
          <div className="time">
            <span>{endTimeValues[0]}</span>:<span>{endTimeValues[1]}</span>
          </div>
        </div>
        <div className="question-item-team"></div>
      </div>
    </div>
  )
}

const QuestionsList = ({ questions, addQuestionForm, removeQuestionForm, team }: any) => {
  const questionFormExist = questions?.find((question) => question?.type === 'question-form')

  return (
    <div className="questions-list">
      {questionFormExist ? (
        <QuestionForm
          data={questionFormExist}
          removeQuestionForm={removeQuestionForm}
          team={team}
        />
      ) : (
        <DragDropContext
          onDragEnd={(e) => {
            console.log('drop end', { e })
          }}
        >
          <Droppable droppableId="droppableList" direction="horizontal">
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="questions-list"
                >
                  {questions
                    ?.filter((question) => question?.type !== 'question-form')
                    ?.map((question, index) => {
                      return (
                        <Draggable
                          isDragDisabled={true}
                          key={question.id}
                          draggableId={question.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              className="question-element"
                            >
                              <QuestionItem
                                onClick={() =>
                                  questionFormExist
                                    ? removeQuestionForm()
                                    : addQuestionForm(question?.id, { isEdit: true })
                                }
                                question={question}
                                index={question?.index}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  )
}

export default QuestionsList
