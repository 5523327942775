import Button from '@src/modules/shared/components/Button/Button'
import { ReactComponent as Calendar } from '../assets/happyCalendar.svg'

interface Props {
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SuccessPopUp({ setSuccess }: Props) {
  return (
    <div className="success_popup">
      <Calendar className="success_popup-image" />
      <p className="success_popup-title">Answer sent!</p>

      <span className="success_popup-description">
        Boom! Your answer's in! Catch you tomorrow for more daily fun!
      </span>

      <Button
        className="success_popup-button"
        onClick={() => {
          setSuccess(false)
        }}
      >
        Got it!
      </Button>
    </div>
  )
}
