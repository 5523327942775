import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

export const spinIndicator = (size: string = 'small') => (
  <LoadingOutlined
    style={{ fontSize: 24, width: size === 'large' ? 30 : 20, height: size === 'large' ? 30 : 20 }}
    spin
  />
)

const Spinner = () => {
  return (
    <div className="sibebar-spinner">
      {/* <Spin indicator={spinIndicator()} /> */}
      <Spin />
    </div>
  )
}

export default Spinner
