/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'
import { startTimer, closeTimer } from './thunks'
import { ITimer } from '@softylines/tam-types'
import { getDifferenceInSeconds } from '../../components/Timer/utils/getDifferntTime'

export interface Pagination {
  total_items: number
  rows_per_page: number
  current_page: number
  last_page: number
  next: {
    page: number
    limit: number
  }
  prev: {
    page: number
    limit: number
  }
}

export interface TimerState {
  timerStartAt: null | string
  currentActiveTimer: null | ITimer
  globalTimerStartAt: null | string
  elapsedSeconds: number
  currentActiveTimerTaskId: null | string
  isGlobalTimerOpen: boolean
}

const initialState: TimerState = {
  timerStartAt: null,
  globalTimerStartAt: null,
  currentActiveTimer: null,
  elapsedSeconds: 0,
  currentActiveTimerTaskId: null,
  isGlobalTimerOpen: false,
}
const startTimerForTask = (taskId: string, description?: string) => async (dispatch) => {
  try {
    const result = await dispatch(startTimer({ taskId, description }))
    if (result?.payload) {
      dispatch(setTimerStartAt(result?.payload?.startTime))
      dispatch(setGlobalTimerStartAt(result?.payload?.startTime))
      dispatch(setCurrentActiveTimerTaskId(taskId))
      dispatch(setCurrentActiveTimer(result?.payload))
      dispatch(setIsGlobalTimerOpen(true))
      return result
    }
  } catch (error) {
    console.error('Failed to start timer:', error)
    return error
  }
}

const startTimerWithPayload = (payload) => async (dispatch) => {
  try {
    dispatch(setTimerStartAt(payload?.startTime))
    dispatch(setGlobalTimerStartAt(payload?.startTime))
    dispatch(setCurrentActiveTimer(payload))
    dispatch(setCurrentActiveTimerTaskId(payload?.task?.id))
    dispatch(setIsGlobalTimerOpen(true))
  } catch (error) {
    console.error('Failed to start timer:', error)
  }
}

const closeTimerById = (timerId: string) => async (dispatch) => {
  try {
    await dispatch(closeTimer({ timerId })).then((result) => {
      if (result?.payload) {
        dispatch(resetTimer())
      }
    })
  } catch (error) {
    console.error('Failed to close timer:', error)
  }
}
const startSocketTimerThunk = (state, payload) => {
  if (payload?.startTime) {
    state.timerStartAt = payload?.startTime
    state.currentActiveTimer = payload
    state.globalTimerStartAt = payload?.startTime
    state.currentActiveTimerTaskId = payload?.task?.id
    state.isGlobalTimerOpen = true
  }
}

const stopSocketTimerThunk = (state) => {
  state.timerStartAt = null
  state.elapsedSeconds = 0
  state.currentActiveTimerTaskId = null
  state.currentActiveTimer = null
  state.globalTimerStartAt = null
  state.isGlobalTimerOpen = false
}

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimerSocketEvent(state, { payload }) {
      if (payload.updateType === 'STARTED') {
        startSocketTimerThunk(state, payload.updateEvent)
      }
      if (payload.updateType === 'CLOSED') {
        stopSocketTimerThunk(state)
      }
    },
    setGlobalTimerStartAt(state, { payload }) {
      state.globalTimerStartAt = payload
    },
    setTimerStartAt(state, { payload }) {
      state.timerStartAt = payload
      if (payload) {
        state.elapsedSeconds = getDifferenceInSeconds(payload)
      } else {
        state.elapsedSeconds = 0
      }
    },
    resetTimer(state) {
      state.timerStartAt = null
      state.globalTimerStartAt = null
      state.currentActiveTimer = null
      state.elapsedSeconds = 0
      state.currentActiveTimerTaskId = null
      state.isGlobalTimerOpen = false
    },
    updateElapsedTime(state) {
      if (state.timerStartAt) {
        state.elapsedSeconds = getDifferenceInSeconds(state.timerStartAt)
      }
    },
    setCurrentActiveTimer(state, { payload }) {
      state.currentActiveTimer = payload
    },
    setElapsedSeconds(state, { payload }) {
      state.elapsedSeconds = payload
    },
    setCurrentActiveTimerTaskId(state, { payload }) {
      state.currentActiveTimerTaskId = payload
    },
    setIsGlobalTimerOpen(state, { payload }) {
      state.isGlobalTimerOpen = payload
    },
  },
})

export const {
  setTimerSocketEvent,
  setTimerStartAt,
  setGlobalTimerStartAt,
  updateElapsedTime,
  setCurrentActiveTimer,
  setCurrentActiveTimerTaskId,
  setElapsedSeconds,
  setIsGlobalTimerOpen,
  resetTimer,
} = timerSlice.actions // eslint-disable-line
export default timerSlice.reducer
export {
  startSocketTimerThunk,
  startTimerForTask,
  closeTimerById,
  stopSocketTimerThunk,
  startTimerWithPayload,
}
