import { Avatar, Form, Upload, Spin, Tooltip } from 'antd'
import { dateToTimeSince } from '@src/modules/shared/utils/date'
import PaperClipSVG from './icons/PaperClipSVG'
import getSvgBasedOnMediaType from './utils/extensions'
import HoverVideoPlayer from 'react-hover-video-player'
import { useAppSelector } from '../../../../../shared/store/index'
import Actions from './components/Actions'
import { useCallback, useRef, useState } from 'react'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import AddAttachement from './components/AddAttachement'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { useNavigate } from 'react-router-dom'
import { CaretRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const previewAttachmentContent = (attachement, navigate) => {
  if (
    attachement.mimetype.includes('image') ||
    attachement.mimetype.includes('video') ||
    attachement?.mimetype?.includes('pdf')
  ) {
    navigate(updateURL({ label: 'attachementId', value: attachement?.id }))
  }
}

type AtrtachemntProps = {
  attachments: EnhancedUploadFile<any>[]
  setAttachments: (files: EnhancedUploadFile<any>[]) => void
  setAttachementsToDelete?: (files: EnhancedUploadFile<any>[]) => void
  isLoading?: boolean
  task?: TaskType
}

function Attachements({ attachments, setAttachments, task, isLoading }: AtrtachemntProps) {
  const { t } = useTranslation(['taskDetails', 'header'])
  const { user } = useAppSelector((state) => state.auth)
  const [isDragging, setIsDragging] = useState(false)
  const navigate = useNavigate()

  const handleFileChange = useCallback(
    ({ fileList }) => {
      setAttachments(fileList as EnhancedUploadFile<any>[])
    },
    [setAttachments],
  )

  const draggerRef = useRef(null)

  const uploadEvent = () => {
    const input = draggerRef.current?.upload?.uploader?.fileInput
    if (input) input.click()
  }

  return (
    <Spin spinning={isLoading}>
      <div className="Attachements">
        <div className="Attachements-header-title">
          <span>{t('Attachements')}</span>
          <AddAttachement actions={{ uploadEvent }} task={task} attachments={attachments} />
        </div>

        <div className="attachements-container">
          <div className="attachments-preview">
            {(attachments || []).map((attachment: EnhancedUploadFile<any>, index: number) => (
              <div key={`attachment-preview-card-${index}`} className="attachment-preview-card">
                {attachment.mimetype.includes('image') ? (
                  <div className="attachment-preview-icon">
                    <Avatar
                      style={{
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }}
                      shape="square"
                      src={attachment.location}
                      onClick={() => previewAttachmentContent(attachment, navigate)}
                    />
                    <Actions attachment={attachment} />
                  </div>
                ) : (
                  <div className="attachment-preview-icon">
                    <Actions attachment={attachment} />
                    <div
                      style={
                        attachment?.mimetype?.includes('video') ||
                        attachment?.mimetype?.includes('pdf')
                          ? { cursor: 'pointer' }
                          : {}
                      }
                      onClick={() => previewAttachmentContent(attachment, navigate)}
                      className="attachment-content"
                    >
                      {attachment?.mimetype?.includes('video') ? (
                        <HoverVideoPlayer
                          videoSrc={attachment.location}
                          pausedOverlay={
                            <div className="viedo-play">
                              <CaretRightOutlined />
                            </div>
                          }
                        />
                      ) : (
                        getSvgBasedOnMediaType(attachment?.mimetype)
                      )}
                    </div>
                  </div>
                )}

                <div
                  className="attachment-footer"
                  onClick={() => previewAttachmentContent(attachment, navigate)}
                  style={
                    attachment?.mimetype?.includes('image') ||
                    attachment?.mimetype?.includes('video')
                      ? { cursor: 'pointer' }
                      : {}
                  }
                >
                  <div className="attachment-footer-text">
                    <Tooltip title={attachment.originalname ? attachment.originalname : ''}>
                      <div className="attachment-preview-name">
                        {attachment.originalname
                          ? attachment.originalname
                          : (attachment.key as string)}
                      </div>
                    </Tooltip>
                    <div className="attachment-preview-size">
                      {dateToTimeSince(
                        attachment.lastModified ? attachment.lastModified : attachment.updatedAt,
                      )}
                    </div>
                  </div>
                  <div className="attachment-footer-avatar">
                    <Avatar
                      className="task-users-item"
                      size={32}
                      src={attachment?.user ? attachment?.user?.avatar : ''}
                      alt={attachment.name}
                    >
                      {user?.user?.fullName && !user?.user?.avatar
                        ? user?.user?.fullName[0]
                        : user?.user?.email[0]}
                    </Avatar>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Form.Item
            style={
              attachments.length === 1
                ? { marginLeft: 'auto', width: '70%' }
                : { marginInline: 'auto', width: '100%' }
            }
            name="dragger"
            valuePropName="fileList"
          >
            <Upload.Dragger
              fileList={attachments as EnhancedUploadFile<any>[] as any}
              onChange={handleFileChange}
              multiple
              customRequest={() => {}}
              name="files"
              className={`attachements-drag ${isDragging ? 'dragging' : ''}`}
              ref={draggerRef}
              onDrop={() => setIsDragging(false)}
            >
              {
                <>
                  <PaperClipSVG
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                  <p className="ant-upload-hint">
                    {t('drag_drop_files')}{' '}
                    <span className="ant-upload-hint-browse">{t('browse')}</span>
                  </p>
                  {isDragging && <div className="dragging-indicator">{t('drop_file_here')}</div>}
                </>
              }
            </Upload.Dragger>
          </Form.Item>
        </div>
      </div>
    </Spin>
  )
}

export default Attachements
