import { createSlice } from '@reduxjs/toolkit'
import { memberAnswersApi } from '../../services/memberAnswerApi'
import { answersApi } from '../../services/answersApi'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
//import { useAppDispatch } from '@src/modules/shared/store'

const initialState = {
  answers: {},
  comments: {},
}
const deleteEmojiById = (reactionsArray, emojiIdToDelete) => {
  return reactionsArray.filter((emojiObj) => emojiObj.id !== emojiIdToDelete)
}

const updateEmojiById = (reactionsArray, updatedEmoji) => {
  return reactionsArray.map((emojiObj) =>
    emojiObj.id === updatedEmoji.id ? updatedEmoji : emojiObj,
  )
}

const answerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addEmojiToAnswer: (state, action) => {
      const { key, emoji, answerId } = action.payload
      if (state.answers.hasOwnProperty(key)) {
        const answerIndex = state.answers[key].findIndex((answer) => answer.id === answerId)
        if (answerIndex !== -1) {
          state.answers[key] = [
            ...state.answers[key].slice(0, answerIndex),
            {
              ...state.answers[key][answerIndex],
              reactions: removeDuplicatesById([
                ...state.answers[key][answerIndex].reactions,
                emoji,
              ]),
            },
            ...state.answers[key].slice(answerIndex + 1),
          ]
        }
      }
    },
    updateEmojiInAnswer: (state, action) => {
      const { key, answerId, updatedEmoji } = action.payload
      if (state.answers.hasOwnProperty(key)) {
        const answerIndex = state.answers[key].findIndex((answer) => answer.id === answerId)
        if (answerIndex !== -1) {
          state.answers[key] = [
            ...state.answers[key].slice(0, answerIndex),
            {
              ...state.answers[key][answerIndex],
              reactions: updateEmojiById(state.answers[key][answerIndex].reactions, updatedEmoji),
            },
            ...state.answers[key].slice(answerIndex + 1),
          ]
        }
      }
    },
    deleteEmojiFromAnswer: (state, action) => {
      const { key, answerId, emojiIdToDelete } = action.payload
      if (state.answers.hasOwnProperty(key)) {
        const answerIndex = state.answers[key].findIndex((answer) => answer.id === answerId)
        if (answerIndex !== -1) {
          state.answers[key] = [
            ...state.answers[key].slice(0, answerIndex),
            {
              ...state.answers[key][answerIndex],
              reactions: deleteEmojiById(
                state.answers[key][answerIndex].reactions,
                emojiIdToDelete,
              ),
            },
            ...state.answers[key].slice(answerIndex + 1),
          ]
        }
      }
    },
    deleteEmojiFromReply: (state, action) => {
      const { key, commentId, emojiIdToDelete } = action.payload
      if (state.comments.hasOwnProperty(key)) {
        const answerIndex = state?.comments[key]?.findIndex((answer) => answer?.id === commentId)
        if (answerIndex !== -1) {
          state.comments[key] = [
            ...state.comments[key].slice(0, answerIndex),
            {
              ...state.comments[key][answerIndex],
              reactions: deleteEmojiById(
                state.comments[key][answerIndex].reactions,
                emojiIdToDelete?.id,
              ),
            },
            ...state.comments[key].slice(answerIndex + 1),
          ]
        }
      }
    },
    addAnswerToAnswerList: (state, action) => {
      const { key, payload } = action.payload
      if (state.answers.hasOwnProperty(key)) {
        const index = state.answers[key].findIndex((item) => item.id === payload.id)
        if (index !== -1) {
          state.answers[key][index] = payload
        } else {
          state.answers[key].push(payload)
        }
      } else {
        state.answers[key] = [payload]
      }
    },
    updateAnswersPayload: (state, action) => {
      const { key, payloadId, body } = action.payload
      if (state.answers.hasOwnProperty(key)) {
        const index = state.answers[key].findIndex((item) => item.id === payloadId)
        if (index !== -1) {
          const newState = {
            ...state,
            answers: {
              ...state.answers,
              [key]: [
                ...state.answers[key].slice(0, index),
                {
                  ...state.answers[key][index],
                  body: body,
                },
                ...state.answers[key].slice(index + 1),
              ],
            },
          }
          return newState
        } else {
          console.error(`Payload with ID ${payloadId} does not exist in state.answers[${key}]`)
        }
      } else {
        console.error(`Key ${key} does not exist in state.answers`)
      }
      return state
    },
    addReplyToAnswer: (state, action) => {
      const key = action?.payload?.answerId
      if (state.comments[key]) {
        state.comments[key].push(action?.payload)
        state.comments[key] = removeDuplicatesById(state?.comments[key])
      } else {
        state.comments[key] = removeDuplicatesById([action?.payload])
      }
    },
    updateReplyToAnswer: (state, action) => {
      const key = action.payload?.commentFound?.answerId
      if (state.comments[key]) {
        const updatedComments = state.comments[key]?.map((el) => {
          if (el?.id === action.payload?.commentFound?.id) {
            return {
              ...action.payload?.commentFound,
              content: action?.payload?.content,
              updatedAt: new Date().toString(),
              edited: true,
              id: action?.payload?.commentFound?.id,
            }
          } else {
            return el
          }
        })

        return {
          ...state,
          comments: {
            ...state.comments,
            [key]: updatedComments,
          },
        }
      }
    },
    deleteReplyToAnswer: (state, action) => {
      const key = action?.payload?.answerId
      state.comments[key] = state.comments[key].filter((data) => data?.id != action?.payload?.id)
    },
    addEmojiToReply: (state, action) => {
      const { key, emoji } = action.payload
      if (state.comments.hasOwnProperty(key)) {
        const answerIndex = state.comments[key].findIndex(
          (answer) => answer.id === emoji?.commentId,
        )
        if (answerIndex !== -1) {
          state.comments[key] = [
            ...state.comments[key].slice(0, answerIndex),
            {
              ...state.comments[key][answerIndex],
              reactions: removeDuplicatesById([
                ...(state.comments[key][answerIndex].reactions || []),
                emoji,
              ]),
            },
            ...state.comments[key].slice(answerIndex + 1),
          ]
        }
      }
    },
    updateEmojiReply: (state, action) => {
      const { key, commentId, updatedEmoji } = action.payload
      if (state.comments.hasOwnProperty(key)) {
        const answerIndex = state.comments[key].findIndex((answer) => answer.id === commentId)
        if (answerIndex !== -1) {
          state.comments[key] = [
            ...state.comments[key].slice(0, answerIndex),
            {
              ...state.comments[key][answerIndex],
              reactions: updateEmojiById(state.comments[key][answerIndex].reactions, updatedEmoji),
            },
            ...state.comments[key].slice(answerIndex + 1),
          ]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(memberAnswersApi.endpoints.getAnswers.matchFulfilled, (state, action) => {
      if (action?.payload?.payload?.length > 0) {
        const currentResponse = new Date(action?.payload?.payload[0]?.date)
        let dateQuery: string
        const endDate = currentResponse
        endDate.setDate(endDate.getDate() + 1)
        dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
        const key = `${action?.payload?.payload[0]?.question?.id}-${dateQuery}`
        state.answers[key] = action?.payload?.payload
      }
    }),
      builder.addMatcher(answersApi.endpoints.searchAnswers.matchFulfilled, (state, action) => {
        if (action?.payload?.payload?.length > 0) {
          const currentResponse = new Date(action?.payload?.payload[0]?.date)
          let dateQuery: string
          const endDate = currentResponse
          endDate.setDate(endDate.getDate() + 1)
          dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
          const key = `${action?.payload?.payload[0]?.question?.id}-${dateQuery}`
          state.answers[key] = action?.payload?.payload
        }
      }),
      builder.addMatcher(memberAnswersApi.endpoints.getReplies.matchFulfilled, (state, action) => {
        if (action?.payload?.payload && action?.payload?.payload?.length > 0) {
          const key = action?.payload?.payload[0]?.answerId
          state.comments[key] = action?.payload?.payload
        }
      })
  },
})

export const {
  addEmojiToAnswer,
  updateEmojiInAnswer,
  deleteEmojiFromAnswer,
  updateAnswersPayload,
  addAnswerToAnswerList,
  addReplyToAnswer,
  deleteReplyToAnswer,
  updateReplyToAnswer,
  addEmojiToReply,
  deleteEmojiFromReply,
  updateEmojiReply,
} = answerSlice.actions

export default answerSlice.reducer
