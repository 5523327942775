import { useState } from 'react'
import { Dropdown } from 'antd'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import { treeSupportedTypes } from '@src/modules/shared/components/SpacesTree/SpacesTree'
import { ReactComponent as ArrowBottomIcon } from '@src/modules/customFields/assets/icon-bottom.svg'
import Toggle from '@src/modules/shared/components/SpacesTree/Toggle'
import { useTranslation } from 'react-i18next'
import Search from '@src/modules/shared/components/Input/search'

function Relationship({ formik, data = null }) {
  const { t } = useTranslation(['customFields'])

  const [selectedCheckbox, setSelectedCheckbox] = useState(null)
  const [list, setList] = useState(null)

  const handleCheckboxChange = (checkboxName) => {
    setSelectedCheckbox(checkboxName)
  }

  return (
    <>
      <div className="settings-relationship-container-label">{t('related_to')}</div>
      <div className={'settings-relationship-container'}>
        <div>
          <Toggle
            isSelected={
              data
                ? Object?.keys(data?.typeConfig)?.length === 0 &&
                  data?.typeConfig?.constructor === Object
                : selectedCheckbox === 'c1'
            }
            select={() => {
              if (!data) {
                handleCheckboxChange('c1')
                formik?.setFieldValue('values.listId', null)
              }
            }}
          />
          <span
            onClick={() => {
              if (!data) handleCheckboxChange('c1')
            }}
          >
            {t('any_task_in_your_workspace')}
          </span>
        </div>
        <div>
          <Toggle
            isSelected={data ? data?.typeConfig?.listId : selectedCheckbox === 'c2'}
            select={() => {
              if (!data) handleCheckboxChange('c2')
            }}
          />
          <span
            onClick={() => {
              if (!data) handleCheckboxChange('c2')
            }}
          >
            {t('tasks_from_a_specific_List')}
          </span>
        </div>
        {selectedCheckbox === 'c2' && !data && (
          <div className={'settings-relationship-list'}>
            <div>{t('related_list')}</div>
            <div>
              <Dropdown
                dropdownRender={() => (
                  <div className="ant-dropdown-menu ">
                    <Search onSearch={() => {}} prefix={''} variant="underlined" />
                    <div className="spaces-tree-container">
                      <SpacesTree
                        readOnly
                        noDocumentView={true}
                        autoOpen
                        item={{ id: list?.id, type: treeSupportedTypes.LIST }}
                        onSelectList={(_, content) => {
                          formik?.setFieldValue('values.listId', content.id)
                          setList(content)
                        }}
                      />
                    </div>
                  </div>
                )}
                trigger={['click']}
              >
                <div className="dropdpwn-relationship">
                  <div className="dropdpwn-relationship-view">{list?.name || t('select_list')}</div>
                  <ArrowBottomIcon />
                </div>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Relationship
