import { api } from '@src/modules/shared/services/api'
import { removeDuplicatesById } from '../utils/removeDup'

export const MembersListApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMembersList: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/users`,
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }
        currentCache.payload = removeDuplicatesById(currentCache?.payload)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: (result = []) => {
        return result?.payload
          ? [
              ...result?.payload?.map(({ id }: any) => ({ type: 'MEMBERS', id }) as const),
              { type: 'MEMBERS', id: 'USER' },
            ]
          : []
      },
    }),
  }),
})

export const { useGetMembersListQuery } = MembersListApi
