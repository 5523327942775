import { useState, useEffect, useRef } from 'react'

// sol 1
export function useIsInViewport(ref, data = null, containerClassName?: string) {
  const [isIntersecting, setIsIntersecting] = useState(true)
  const refId = useRef(null)
  const scrollTimeout = useRef(null)

  const checkVisibility = () => {
    if (!ref || !ref.current) return false

    const { top, bottom, left, right } = ref.current.getBoundingClientRect()
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth
    if (top < 0) return false
    const isVisible = bottom >= 0 && top <= windowHeight && right >= 0 && left <= windowWidth

    return isVisible
  }

  const handleScroll = () => {
    if (!refId.current) {
      refId.current = requestAnimationFrame(() => {
        const visibility = checkVisibility()
        setIsIntersecting(visibility)
        refId.current = null
      })
    }

    if (!scrollTimeout.current) {
      scrollTimeout.current = setTimeout(() => {
        refId.current = null
        clearTimeout(scrollTimeout.current)
        scrollTimeout.current = null
      }, 100) // Adjust the delay (in milliseconds) as needed
    }
  }

  const handleResize = () => {
    handleScroll() // Recheck visibility on resize
  }

  useEffect(() => {
    const [element] = document.getElementsByClassName(containerClassName || 'list-container')

    const scrollHandler = () => handleScroll()

    const resizeHandler = () => handleResize()

    element?.addEventListener('scroll', scrollHandler)
    window.addEventListener('resize', resizeHandler)

    return () => {
      cancelAnimationFrame(refId.current)
      element?.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('resize', resizeHandler)
      clearTimeout(scrollTimeout.current)
    }
  }, [ref])

  useEffect(() => {
    if (data) handleScroll()
  }, [data])

  useEffect(() => {
    // Initial check
    const visibility = checkVisibility()
    setIsIntersecting(visibility)
  }, [])

  return isIntersecting
}

//sol 2

// import { debounce } from 'lodash'
// import { useEffect, useState } from 'react'

// export function useIsInViewport(ref, data = null, containerClassName) {
//   const [isIntersecting, setIsIntersecting] = useState(true)

//   const checkVisibility = () => {
//     if (!ref || !ref.current) return false

//     const { top, bottom, left, right } = ref.current.getBoundingClientRect()
//     const windowHeight = window.innerHeight || document.documentElement.clientHeight
//     const windowWidth = window.innerWidth || document.documentElement.clientWidth
//     if (top < 0) return false
//     const isVisible = bottom >= 0 && top <= windowHeight && right >= 0 && left <= windowWidth

//     return isVisible
//   }

//   const handleScroll = debounce(() => {
//     const visibility = checkVisibility()
//     setIsIntersecting(visibility)
//   }, 100) // Adjust the debounce delay as needed

//   const handleResize = debounce(() => {
//     handleScroll() // Recheck visibility on resize
//   }, 100)

//   useEffect(() => {
//     const [element] = document.getElementsByClassName(containerClassName || 'list-container')

//     element.addEventListener('scroll', handleScroll)
//     window.addEventListener('resize', handleResize)

//     return () => {
//       element?.removeEventListener('scroll', handleScroll)
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [ref])

//   useEffect(() => {
//     if (data) handleScroll()
//   }, [data])

//   useEffect(() => {
//     const visibility = checkVisibility()
//     setIsIntersecting(visibility)
//   }, [])

//   return isIntersecting
// }
