/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'
import { serialize } from 'object-to-formdata'

export const updateWorkspace = createAsyncThunk(
  'settings/updateWorkspace',
  async (payload: { id: string; query: any }) => {
    const { id, query } = payload

    try {
      const formData = serialize(query)
      const response = await axiosInstance.patch(`/api/workspaces/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getWorkspaces = createAsyncThunk('settings/getWorkspaces', async () => {
  try {
    const response = await axiosInstance.get(`/api/workspaces/user`)

    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const leaveWorkspaces = createAsyncThunk('settings/leaveWorkspaces', async () => {
  try {
    const response = await axiosInstance.post(`/api/workspace/leave`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
