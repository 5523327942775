import React, { useState } from 'react'
import dayjs from 'dayjs'
import { TimePicker as AntTimePicker } from 'antd'

interface TimePickerProps {
  initialTime?: string
  label?: string
  disabled: boolean
  updateEditorTime?: (id: number | null, time: string | null) => void
  id?: number
  value?: string
}

const TimePicker: React.FC<TimePickerProps> = ({
  initialTime = '00:00',
  label,
  disabled,
  updateEditorTime,
  id,
  value,
}) => {
  const [time, setTime] = useState(initialTime)

  const handleTimeChange = (value: dayjs.Dayjs | null) => {
    const newTime = value ? value.format('HH:mm') : ''
    setTime(newTime)
    updateEditorTime && updateEditorTime(id, newTime)
  }
  const format = 'HH:mm'

  return (
    <div className="time-picker">
      <label htmlFor="time-input">{label}</label>
      <AntTimePicker
        defaultValue={dayjs('00:00', format)}
        allowClear={false}
        format={format}
        showNow={false}
        disabled={disabled}
        suffixIcon={null}
        clearIcon={null}
        onChange={handleTimeChange}
        value={dayjs(value, format) || dayjs(time, format)}
        inputReadOnly
      />
    </div>
  )
}

export default TimePicker
