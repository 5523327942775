import { api } from '@src/modules/shared/services/api'
import { removeDuplicatesById } from '../utils/removeDup'

export const TRASHApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTrashs: build.query<any, any>({
      query: (params) => ({
        url: `api/trash`,
        params,
      }),
      transformResponse: (response: { payload: any }): any => {
        return response?.payload
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, { arg }) => {
        const withFilter = arg.options
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }
        currentCache.metadata = newItems.metadata

        currentCache.payload =
          shouldCache || arg.skip > 1
            ? removeDuplicatesById(currentCache?.payload)
            : newItems?.payload
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      providesTags: (result) => {
        if (!result?.payload) return [{ type: 'TRASH', id: 'LIST' }]
        return [
          ...result.payload.map(({ id }: any) => ({ type: 'TRASH' as const, id })),
          { type: 'TRASH' as const, id: 'LIST' },
        ]
      },
    }),
  }),
})

export const { useGetTrashsQuery } = TRASHApi
