import Calendar from '../../Calendar'
import { ReactComponent as DateSVG } from '../../../assets/icons/task/grayCalendar.svg'
import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import dayjs from 'dayjs'

const DateField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const dateValue = field.custom ? customFieldValue : task[field.value]

  const parsedDate = dateValue ? dayjs(dateValue) : null

  return (
    <div className="list-date-field" onClick={(e) => e.stopPropagation()}>
      <Calendar
        placement="bottomCenter"
        setEnd={(date) => saveCustomFieldValue(date.format())}
        onClick={(e) => e.stopPropagation()}
        defEnd={parsedDate}
      >
        <span>{!parsedDate ? <DateSVG /> : parsedDate.format('D MMM')}</span>
      </Calendar>
    </div>
  )
}

export default DateField
