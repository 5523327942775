import { Dropdown, MenuProps, Progress, notification } from 'antd'
import {
  resetFiles,
  setRecordedFile,
  setStatus,
  startRecording,
  stopRecording,
} from '../../store/slices/settings/settingsSlice'
import { store, useAppDispatch, useAppSelector } from '../../store'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ArgsProps } from 'antd/es/notification'
import { ReactComponent as CloseIcon } from './../../assets/icons/globalActions/notif-close-icon.svg'
import { ReactComponent as CopyLinkIcon } from './../../assets/icons/globalActions/copy-link.svg'
import { ReactComponent as CreateDocumentIcon } from './../../assets/icons/globalActions/Document.svg'
import { ReactComponent as CreateTaskIcon } from './../../assets/icons/globalActions/create-task.svg'
import { ReactComponent as GlobalActionIcon } from './../../assets/icons/globalActions/dashboard.svg'
import { ReactComponent as NewTaskIcon } from './../../assets/icons/globalActions/new_task.svg'

import { ReactComponent as ScreenRecordingIcon } from './../../assets/icons/globalActions/ScreenRecording.svg'
import { ReactComponent as NewTimerIcon } from './../../assets/icons/globalActions/new-timer.svg'
import axios from 'axios'
import axiosInstance from '../../utils/axios'
import { objectToFormData } from '@src/modules/tasks/services/tagsApi'
import { openModal } from '../../store/slices/modals/modalsSlice'
import { useReactMediaRecorder } from 'react-media-recorder'
import { useTranslation } from 'react-i18next'
import { setIsGlobalTimerOpen } from '@src/modules/tasks/data/timerData/slices'

const CancelToken = axios.CancelToken

export const cancelTokenSource = CancelToken.source()

export const sreenRecordingActions: (t) => MenuProps['items'] = (t) => [
  {
    key: '1',
    label: store.getState().settings.screenRecording.isRecording
      ? t('header:stop_recording')
      : t('header:record_clip'),
    className: 'record-clip-row',
    onClick: () =>
      store.dispatch(
        store.getState().settings.screenRecording.isRecording ? stopRecording() : startRecording(),
      ),
    icon: <ScreenRecordingIcon />,
  },
  {
    key: '2',
    label: t('new_document'),
    onClick: () => store.dispatch(openModal({ id: 'create-new-document-modal' })),
    icon: <CreateDocumentIcon />,
  },
  {
    key: '3',
    label: t('new_task'),
    onClick: () => store.dispatch(openModal({ id: 'create-task-modal' })),
    icon: <NewTaskIcon />,
  },
  {
    key: '4',
    label: !store.getState().timer.isGlobalTimerOpen ? t('track_time') : t('close_global_timer'),
    onClick: () => {
      const isOpen = store.getState().timer.isGlobalTimerOpen
      store.dispatch(setIsGlobalTimerOpen(!isOpen))
    },
    icon: <NewTimerIcon />,
  },
]

interface NotificationMessageProps {
  step: 'uploading' | 'success' | 'error'
  percent?: number
  file?: any
}

const NotificationMessage = ({ step, percent = 30, file }: NotificationMessageProps) => {
  const { t } = useTranslation(['header'])
  const { listId } = useParams()
  const [copied, setCopied] = useState(false)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(file?.location)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <div className="screen-recording-notif-message">
      {step === 'uploading' ? (
        <div className="message-container">
          <p>{t('uploading_clip')}</p>
          <Progress percent={percent} showInfo />
        </div>
      ) : step === 'success' ? (
        <div className="message-container">
          <p>{t('clip_is_ready')}</p>

          <div className="message-container-success">
            <div
              onClick={() =>
                store.dispatch(
                  openModal({ id: 'create-task-modal', data: { list: { id: listId } } }),
                )
              }
            >
              <CreateTaskIcon /> {t('create_task')}
            </div>

            <span>{t('or')}</span>

            <div onClick={handleCopyLink}>
              <CopyLinkIcon />
              {copied ? 'Copied!' : t('copy_link')}
            </div>
          </div>
        </div>
      ) : (
        <div className="message-container">{t('error')}</div>
      )}
    </div>
  )
}

const getNotifOptions = (
  step: NotificationMessageProps['step'],
  percent = 0,
  file = null,
): ArgsProps => ({
  message: <NotificationMessage step={step} percent={percent} file={file} />,
  placement: 'bottomLeft',
  className: 'screen-recording-notif',
  icon: <ScreenRecordingIcon />,
  closeIcon: <CloseIcon />,
  key: 'record',
  onClose: () => {
    if (step === 'uploading') cancelTokenSource.cancel('Operation canceled')
    store.dispatch(resetFiles())
  },
  duration: 0,
})

const onUploadProgress = (progressEvent) => {
  const { loaded, total } = progressEvent
  let percent = Math.floor((loaded * 100) / total)
  if (percent < 100) notification.info(getNotifOptions('uploading', percent))
}

const uploadRecordingHandler = async (_: string, blob: File) => {
  notification.info(getNotifOptions('uploading'))

  blob = new File([blob], 'file', {
    type: blob.type,
    lastModified: blob.lastModified,
  })

  const timestamp = blob.lastModified
  const date = new Date(timestamp)

  const fileName = `Screen Recording ${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')} at ${String(date.getHours() % 12 || 12).padStart(
    2,
    '0',
  )}.${String(date.getMinutes()).padStart(2, '0')}.${String(date.getSeconds()).padStart(2, '0')} ${
    date.getHours() >= 12 ? 'PM' : 'AM'
  }`

  blob = new File([blob], fileName, {
    type: blob.type,
    lastModified: blob.lastModified,
  })

  try {
    const response = await axiosInstance.post('api/upload', objectToFormData({ files: [blob] }), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      cancelToken: cancelTokenSource.token,
    })

    const file = response?.data?.payload && response?.data?.payload[0]
    store.dispatch(setRecordedFile({ file, blob }))

    notification.info(getNotifOptions('success', null, file))
  } catch (err) {
    if (!axios.isCancel(err)) notification.info(getNotifOptions('error'))
  }
}

const FloatGlobalActions = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const { t: taskDetailsTranslation } = useTranslation(['taskDetails'])

  const {
    screenRecording: { startRecording, stopRecording },
  } = useAppSelector((state) => state.settings)

  const [audio, setAudio] = useState(true)

  if (navigator?.permissions) {
    navigator.permissions
      .query({
        name: 'microphone' as PermissionDescriptor['name'],
      })
      .then(async (micPermission) => {
        if (micPermission.state === 'denied') {
          setAudio(false)
        }
      })
      .catch((error) => {
        console.error('Error querying microphone permission:', error)
      })
  } else console.warn('Permissions API is not supported in this browser')

  const {
    status,
    startRecording: startRecordingFn,
    stopRecording: stopRecordingFn,
    clearBlobUrl,
  } = useReactMediaRecorder({
    audio: navigator?.permissions ? audio : false,
    screen: true,
    video: true,
    onStop: uploadRecordingHandler,
  })

  const stopRecordingHandler = () => {
    stopRecordingFn()
    clearBlobUrl()
  }

  const startRecordingHandler = async () => {
    try {
      if (audio) await navigator.mediaDevices.getUserMedia({ audio: true })

      startRecordingFn()
    } catch (err) {
      console.error('Error: screen recording', err)
    }
  }

  useEffect(() => {
    if (startRecording) {
      startRecordingHandler()
    } else {
      stopRecordingHandler()
    }
  }, [startRecording, stopRecording])

  useEffect(() => {
    dispatch(setStatus({ status, taskId }))
  }, [status])

  return (
    <div className="float-button-global-actions">
      <Dropdown
        menu={{
          items: sreenRecordingActions(taskDetailsTranslation),
        }}
        trigger={['click']}
        overlayClassName="global-action-dropdown"
      >
        <div className="global-actions-button">
          <GlobalActionIcon />
        </div>
      </Dropdown>
    </div>
  )
}

export default FloatGlobalActions
