import 'react-complex-tree/lib/style-modern.css'

import { ControlledTreeEnvironment, InteractionMode, Tree } from 'react-complex-tree'
import { getFolder } from '@src/modules/spaces/data/folderSlice/folderThunk'
import { getShared } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { renderItem, renderItemProps } from './helpers/renderItem'
import { store, useAppDispatch, useAppSelector } from '../../store'
import { useEffect, useRef, useState } from 'react'

import { TaskSkeleton } from '@src/modules/tasks/components/EditTaskPopup/components/SubTasksTree/helpers/renderItem'
import { renderItemArrow } from './helpers/renderItemArrow'
import { useGetDocumentQuery } from '@src/modules/spaces/services/documentApi'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import { TreeItemType } from '@src/modules/spaces/components/Spaces/Spaces'

const getFolderChildren = async (folderId) => {
  const response = await store.dispatch(getFolder({ id: folderId })).unwrap()
  return response.payload
}

export enum sharedTreeSupportedTypes {
  FOLDER = 'folder',
  LIST = 'list',
  DOCUMENT = 'document',
}

const allowedTypeForSelect = [
  sharedTreeSupportedTypes.LIST,
  sharedTreeSupportedTypes.DOCUMENT,
  sharedTreeSupportedTypes.FOLDER,
]

interface SpacesTreeProps {
  multiple?: boolean
  onSelectList?: (items) => void
  onSelectFolder?: (items) => void
  onSelectDocument?: (items) => void
  drag?: boolean
  search?: string
  noDocumentView?: boolean
  readOnly?: boolean
  autoOpen?: boolean
  item?: TreeItemType
}

const isCurrentType = (itemType, correctType) => {
  return itemType === correctType
}

export const addLoading = (item, setTree) => {
  setTree((tree) => ({ ...tree, [item?.id]: { ...tree[item?.id], isLoading: true } }))
}

export const removeLoading = (item, setTree) => {
  setTree((tree) => ({ ...tree, [item?.id]: { ...tree[item?.id], isLoading: false } }))
}

const SharedTree = ({
  multiple = false,
  onSelectList,
  onSelectFolder,
  onSelectDocument,
  noDocumentView = false,
  readOnly = false,
  drag = false,
  item = { id: null, type: null },
  autoOpen = false,
}: SpacesTreeProps) => {
  const { updatedItem, deletedItem, createdItem } = useAppSelector((state) => state.spaces)
  const treeRef = useRef<any>()
  const expandeAll = false

  const { data: list } = useGetListQuery(
    { id: item.id },
    { skip: !isCurrentType(item.type, sharedTreeSupportedTypes.LIST || !item.id) },
  )
  const { data: document } = useGetDocumentQuery(item.id, {
    skip: !isCurrentType(item.type, sharedTreeSupportedTypes.DOCUMENT),
  })
  const [loading, setLoading] = useState<boolean>(false)

  const dataByType = {
    [sharedTreeSupportedTypes.LIST]: list,
    [sharedTreeSupportedTypes.DOCUMENT]: document,
  }
  const dispatch = useAppDispatch()
  const [tree, setTree] = useState<any>({ root: {} })
  const [focusedItem, setFocusedItem] = useState()
  const [expandedItems, setExpandedItems] = useState([])
  const [selectedItems, setSelectedItems] = useState<string[]>(autoOpen ? [item.id] : [])

  useEffect(() => {
    if (createdItem && createdItem?.type === 'folders') {
      setExpandedItems((prev) => [...prev, createdItem?.spaceId])
    }
  }, [updatedItem, deletedItem, createdItem])

  useEffect(() => {
    setLoading(true)
    dispatch(getShared({}))
      .unwrap()
      .then((res) => {
        let { documents, folders, lists } = res?.payload
        const treeDocuments = convertToTemplateTree(documents, 'document')

        const treeFolders = convertToTemplateTree(folders, 'folder')

        const treeLists = convertToTemplateTree(lists, 'list')

        const sharedTree = { ...treeDocuments, ...treeFolders, ...treeLists }

        const sharedIds = Object.keys(sharedTree)
        const newTree = {
          ...tree,
          root: { ...tree?.root, children: sharedIds },
          ...sharedTree,
        }

        setTree(newTree)
        expandManagement(newTree)
        // for expande all
        if (expandeAll) {
          setExpandedItems(sharedIds)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  const expandManagement = (tree) => {
    expandedItems?.forEach(async (itemId) => {
      const item = tree[itemId]
      if (item?.children) return
      addLoading(item, setTree)
      let itemChilds = null

      if (item?.type === 'folder') {
        const response = await getFolderChildren(itemId)
        const documents = noDocumentView ? [] : response?.documents || []

        itemChilds = {
          ...convertToTemplateTree(response?.list, 'list'),
          ...convertToTemplateTree(documents, 'document'),
        }
      }

      const childsIds = Object.keys(itemChilds || {})
      setTree((tree) => ({
        ...tree,
        [itemId]: { ...tree[itemId], children: childsIds },
        ...itemChilds,
      }))
      removeLoading(item, setTree)
    })
  }

  const selectItems = (items: string[]) => {
    const { type } = tree[items[0]]

    if (!allowedTypeForSelect.includes(type)) return

    if (!multiple) {
      if (type === sharedTreeSupportedTypes.LIST) onSelectList?.(items)
      else if (type === sharedTreeSupportedTypes.FOLDER) onSelectFolder?.(items)
      else if (type === sharedTreeSupportedTypes.DOCUMENT) onSelectDocument?.(items)
    }

    setSelectedItems(items)
  }

  const getCurrentDataByType = (currentType: string) => dataByType[currentType]

  useEffect(() => {
    expandManagement(tree)
  }, [expandedItems])

  useEffect(() => {
    item.id && setSelectedItems([item.id] as string[])
  }, [item.id])

  if (loading)
    return (
      <div className="space-tree-loading">
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
        <TaskSkeleton depth={0} depthPadding={0} />
      </div>
    )

  return (
    <div className="space-tree shared-with-me">
      <ControlledTreeEnvironment
        ref={treeRef}
        items={tree}
        getItemTitle={(item) => item.data}
        viewState={{
          ['tree-1']: {
            focusedItem,
            expandedItems,
            selectedItems,
          },
        }}
        renderTreeContainer={({ containerProps, children }) => {
          return (
            <div className={drag || readOnly ? '' : 'tree-container'} {...containerProps}>
              {children}
            </div>
          )
        }}
        defaultInteractionMode={InteractionMode.ClickItemToExpand}
        canReorderItems={true}
        renderItemArrow={renderItemArrow}
        onFocusItem={(item: any) => setFocusedItem(item.index)}
        onExpandItem={(item) => {
          if (item) setExpandedItems([...expandedItems, item.index])
        }}
        onCollapseItem={(item) =>
          setExpandedItems(
            expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index),
          )
        }
        renderItem={(props) =>
          renderItem({
            ...props,
            tree,
            multiple,
            readOnly,
            autoOpen,
            activeItem: getCurrentDataByType(item.type),
          } as renderItemProps)
        }
        canSearch={false}
        canRename={false}
        onSelectItems={selectItems}
      >
        <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
      </ControlledTreeEnvironment>
    </div>
  )
}

export default SharedTree

export const convertToTemplateTree = (items: any = [], type: string) => {
  let data = {}
  items?.forEach((item) => {
    data[item?.id] = {
      index: item?.id,
      id: item?.id,
      parentId: item?.folderId,
      canMove: true,
      type,
      isFolder: type !== 'list',
      children: undefined,
      data: item?.id,
      content: item,
      canRename: true,
      halfSelect: false,
    }
  })

  return data
}
