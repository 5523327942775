import { useState } from 'react'
import Toggle from '@src/modules/shared/components/SpacesTree/Toggle'
import { useTranslation } from 'react-i18next'

function People({ formik, data = null }) {
  const { t } = useTranslation(['customFields'])

  const [toggleSelected, setToggleSelected] = useState([
    {
      id: 1,
      name: t('show_people_from_my_entire_workspace'),
      checked: data ? data?.typeConfig?.singleUser : false,
    },
    {
      id: 2,
      name: t('select_multiple_people'),
      checked: data ? data?.typeConfig?.includeGroups : false,
    },
    {
      id: 3,
      name: t('include_teams'),
      checked: data ? data?.typeConfig?.includeTeamMembers : false,
    },
  ])

  const handleToggleChange = (id) => {
    setToggleSelected((prev) =>
      prev.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)),
    )
    formik?.setFieldValue('values', {
      singleUser: toggleSelected[0].checked,
      includeGroups: toggleSelected[1].checked,
      includeTeamMembers: toggleSelected[2].checked,
    })
  }
  return (
    <>
      <div className="settings-checkbox-container-label">{t('settings')}</div>
      <div className={'settings-checkbox-container'}>
        {toggleSelected.map((item) => (
          <div key={item.id}>
            <Toggle
              select={() => (data ? false : handleToggleChange(item.id))}
              isSelected={item.checked}
              isMargin={true}
            />
            <span onClick={() => (data ? false : handleToggleChange(item.id))}>{item.name}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default People
