export const renderArrow = ({ item, context }) => {
  const isExpanded = context.isExpanded
  return item?.isFolder ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      className={`${isExpanded ? 'expended-task-arrow' : ''}`}
      style={{ transform: !isExpanded ? 'rotate(0)' : 'rotate(90deg)' }}
    >
      <path
        d="M4.81837 10.4269V4.44692C4.81775 4.34059 4.84881 4.23648 4.9076 4.14786C4.96638 4.05925 5.05022 3.99015 5.14843 3.94937C5.24664 3.9086 5.35477 3.89799 5.45903 3.91891C5.56329 3.93982 5.65896 3.99131 5.73384 4.06682L8.72116 7.05949C8.82088 7.15979 8.87684 7.29548 8.87684 7.43692C8.87684 7.57835 8.82088 7.71404 8.72116 7.81435L5.73384 10.807C5.65896 10.8825 5.56329 10.934 5.45903 10.9549C5.35477 10.9758 5.24664 10.9652 5.14843 10.9245C5.05022 10.8837 4.96638 10.8146 4.9076 10.726C4.84881 10.6374 4.81775 10.5332 4.81837 10.4269Z"
        fill={'#374957'}
      />
    </svg>
  ) : null
}
