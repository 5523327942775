import clear from '../../assets/icons/header/clear.svg'
import clearAll from '../../assets/icons/header/clearAll.svg'
import newIcon from '../../assets/icons/header/new.svg'
import { Tooltip } from 'antd'
import { useAppSelector } from '@src/modules/shared/store/index'

type props = {
  selectedOption: string
  clearAllNotificationsHanlder: Function
  setIsRead: Function
  setSelectedOption: Function
  isInDropdown?: boolean
  t: any
}

const NotificationsOptions = ({
  selectedOption,
  clearAllNotificationsHanlder,
  setIsRead,
  setSelectedOption,
  isInDropdown,
  t,
}: props) => {
  const { notificationCount } = useAppSelector((state) => state.notification)

  return (
    <div
      className="options_container"
      style={isInDropdown ? { flexDirection: 'column', alignItems: 'start', gap: '10px' } : {}}
    >
      {selectedOption !== 'Cleared' && notificationCount?.all > 0 ? (
        <Tooltip title={t('Clear All Notifications')}>
          <div className="clear-all-notif" onClick={() => clearAllNotificationsHanlder()}>
            <img src={clearAll} />
            {t('Clear All')}
          </div>
        </Tooltip>
      ) : null}

      <span
        className={`option  ${selectedOption === 'New' && 'active_option'}`}
        onClick={() => {
          setIsRead(false)
          setSelectedOption('New')
        }}
      >
        <img src={newIcon} />
        <p>{t('New')}</p>
      </span>

      <span
        className={`option  ${selectedOption === 'Cleared' && 'active_option'}`}
        onClick={() => {
          setIsRead(true)
          setSelectedOption('Cleared')
        }}
      >
        <img src={clear} />
        <p>{t('Cleared')}</p>
      </span>
    </div>
  )
}

export default NotificationsOptions
