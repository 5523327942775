import { store } from '@src/modules/shared/store'

interface parentType {
  name: string
  icon: string
  color: string
  id: string
}

export const getDataParent = (data: { type: string }, listOptions): parentType => {
  let selectedOption: parentType & { path: parentType[] } = {} as parentType & {
    path: parentType[]
  }
  if (data.type === 'fileId') {
    selectedOption = listOptions.list
  } else if (data.type === 'documentId') {
    selectedOption = listOptions.document
  } else if (data.type === 'folderId') {
    return listOptions.folder?.parent
  }

  const { currentWorkspace } = store.getState().auth.user

  return selectedOption?.path
    ? selectedOption?.path[0]
    : data.type === 'spaceId'
      ? currentWorkspace.workspace
      : null
}
