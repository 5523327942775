import { Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Down } from '../../assets/icons/profile/down.svg'
import { useState } from 'react'

type props = {
  selectedLanguage: string
  setSelectedLanguage: Function
}

const CustomSelect = ({ selectedLanguage, setSelectedLanguage }: props) => {
  const { t } = useTranslation(['translation'])
  const [isSelectOpened, setIsSelectOpened] = useState(false)

  const languages = [
    { value: 'en', label: t('language.en') },
    { value: 'fr', label: t('language.fr') },
    { value: 'ar', label: t('language.ar') },
  ]

  const handleChange = (value: string) => {
    setSelectedLanguage(value)
  }

  return (
    <div>
      <Dropdown
        dropdownRender={() => (
          <div className="languages-list">
            {languages.map((lang, index: number) => {
              return (
                <span
                  key={index}
                  onClick={() => {
                    handleChange(lang.value)
                    setIsSelectOpened(false)
                  }}
                >
                  {lang.label}
                </span>
              )
            })}
          </div>
        )}
        trigger={['click']}
        open={isSelectOpened}
        onOpenChange={(visible) => setIsSelectOpened(visible)}
      >
        <div className="language-value" onClick={() => setIsSelectOpened(!isSelectOpened)}>
          <span className="selected-lang">
            {selectedLanguage === 'ar'
              ? t('language.ar')
              : selectedLanguage === 'fr'
                ? t('language.fr')
                : t('language.en')}
          </span>
          <Down
            style={{
              transform: isSelectOpened ? 'rotate(180deg) translateX(0)' : '',
              transition: '300ms',
            }}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default CustomSelect
