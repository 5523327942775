import { Dropdown } from 'antd'
import document from '../../assets/icons/trash/document.svg'
import folder from '../../assets/icons/trash/folder.svg'
import list from '../../assets/icons/trash/list.svg'
import space from '../../assets/icons/trash/space.svg'
import subtask from '../../assets/icons/trash/subtask.svg'
import task from '../../assets/icons/trash/task.svg'
import { useTranslation } from 'react-i18next'
import check from '../../assets/icons/trash/check.svg'

type props = {
  selectedTypes: string[]
  setSelectedTypes: Function
}

const TrashFilterType = ({ selectedTypes, setSelectedTypes }: props) => {
  const { t } = useTranslation(['trash'])

  const trashFilterType = [
    {
      icon: space,
      name: t('Space'),
    },
    {
      icon: folder,
      name: t('Folder'),
    },
    {
      icon: document,
      name: t('Document'),
    },
    {
      icon: list,
      name: t('List'),
    },
    {
      icon: task,
      name: t('Task'),
    },
    {
      icon: subtask,
      name: t('Subtask'),
    },
  ]

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <div className="trash_type_dropdown filter_items">
          {trashFilterType.map((item, index) => {
            return (
              <div
                className="filter_item"
                key={index + item.name}
                onClick={() => {
                  if (!selectedTypes.includes(item?.name?.toLocaleLowerCase())) {
                    setSelectedTypes((prev) => [...prev, item?.name.toLocaleLowerCase()])
                  } else {
                    const newSelectedTypes = selectedTypes.filter(
                      (type) => item?.name.toLocaleLowerCase() !== type,
                    )
                    setSelectedTypes(newSelectedTypes)
                  }
                }}
              >
                <img src={item.icon} />
                <span>{item.name}</span>

                {selectedTypes.includes(item?.name.toLocaleLowerCase()) && (
                  <img src={check} className="check-icon" />
                )}
              </div>
            )
          })}
        </div>
      )}
    >
      <div className="filter_item">
        <span>
          {t('Type')} {selectedTypes.length > 0 ? <>({selectedTypes.length})</> : ''}
        </span>
      </div>
    </Dropdown>
  )
}

export default TrashFilterType
