import { useState } from 'react'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

const Calendar = ({ onChange }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const { width } = useWindowSize()
  const handleOnChange = (ranges) => {
    const { selection } = ranges
    onChange(selection)
    setState([selection])
  }

  return (
    <DateRangePicker
      onChange={handleOnChange}
      showSelectionPreview={false}
      showMonthAndYearPicker={false}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction={width > 650 ? 'horizontal' : 'vertical'}
      className="followup_advancedSearch-calendar"
      rangeColors={['#9747FF']}
      color="9747FF"
    />
  )
}

Calendar.propTypes = {
  onChange: PropTypes.func,
}

export default Calendar
