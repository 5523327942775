import CommentSkeleton from './components/CommentSkeleton'
import SetAssignment from './components/SetAssignment'
import SetNameSkeleton from './components/SetNameSkeleton'
import SetPrioritySkeleton from './components/SetPrioritySkeleton'
import SetStateSkeleton from './components/SetStateSkeleton'
import SmallCommentSkeleton from './components/SmallCommentSkeleton'

const NotificationSkeleton = ({ isInDashboard }: { isInDashboard?: boolean }) => {
  return (
    <div className="notification_skeleton_section" style={isInDashboard ? { width: '100%' } : {}}>
      <SetStateSkeleton />

      <SetStateSkeleton />

      <SetNameSkeleton />

      <SetPrioritySkeleton />

      <CommentSkeleton />

      <SetNameSkeleton />

      <SetAssignment />

      <SmallCommentSkeleton />
    </div>
  )
}

export default NotificationSkeleton
