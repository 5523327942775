import React, { useState, useCallback, useEffect } from 'react'
import { TextAreaProps } from './@types'

import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { FormikProps } from 'formik'
import { getPrefixCls } from '../../utils/getPrefixCls'

interface FormikTextAreaProps<T = any> extends TextAreaProps {
  formik?: FormikProps<T>
  name?: string
}

const InternalTextArea: React.ForwardRefRenderFunction<HTMLTextAreaElement, FormikTextAreaProps> = (
  props,
) => {
  const {
    variant = 'outlined',
    name,
    style,
    status,
    defaultValue,
    required,
    disabled,
    rounded = 'md',
    size,
    characterNumberLimit,
    label,
    direction,
    formik,
    ...rest
  } = props

  const [characterNum, setCharacterNum] = useState(defaultValue ? String(defaultValue).length : 0)

  // Debounce validation
  const debouncedValidateField = useCallback(
    debounce(() => {
      formik?.validateField(name)
    }, 500),
    [formik, name],
  )

  useEffect(() => {
    return () => {
      debouncedValidateField.cancel()
    }
  }, [debouncedValidateField])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharacterNum(e.target.value.length)

    // Call Formik's handleChange
    if (formik) {
      formik.handleChange(e)
      debouncedValidateField()
    }

    if (rest.onChange) {
      rest.onChange(e)
    }
  }

  const textAreaPrefixCls = getPrefixCls('textarea')
  const textAreaClasses = classNames(textAreaPrefixCls, `${textAreaPrefixCls}-${variant}`, {
    [`${textAreaPrefixCls}-${variant}-disabled ${textAreaPrefixCls}-disabled`]: disabled,
    [`${textAreaPrefixCls}-${rounded}`]: rounded,
    [`${textAreaPrefixCls}-${size}`]: size,
    [`${textAreaPrefixCls}-${status}`]: status,
    [`${textAreaPrefixCls}-${direction}`]: direction === 'rtl',
  })

  let element = (
    <textarea
      disabled={disabled}
      onChange={handleChange}
      value={formik ? formik.values[name] : defaultValue}
      className={textAreaClasses}
      {...rest}
      name={name}
      onBlur={formik?.handleBlur}
    />
  )

  if (characterNumberLimit) {
    const charaNumberClasses = classNames(`${textAreaPrefixCls}-character-number `, {})
    const charaNumberGroupClasses = classNames(`${textAreaPrefixCls}-group-character-number `, {})

    element = (
      <div className={charaNumberGroupClasses}>
        {element}
        <span className={`${charaNumberClasses}`}>
          {`${characterNum} / ${characterNumberLimit}`}
        </span>
      </div>
    )
  }

  if (label) {
    const groupLabelClasses = classNames(`${textAreaPrefixCls}-group-label `, {
      [`${textAreaPrefixCls}-group-label-inline`]: variant === 'label-inline',
      [`${textAreaPrefixCls}-group-label-${size}`]: size,
      [`${textAreaPrefixCls}-${status}`]: status,
      [`${textAreaPrefixCls}-group-label-${direction}`]: direction === 'rtl',
    })
    const labelClasses = classNames(`${textAreaPrefixCls}-label `, {
      [`${textAreaPrefixCls}-label-required`]: required,
      [`${textAreaPrefixCls}-label-inline`]: variant === 'label-inline',
      [`${textAreaPrefixCls}-label-${direction}`]: direction === 'rtl',
    })
    element =
      variant === 'label-inline' ? (
        <fieldset className={groupLabelClasses}>
          <legend className={labelClasses}>{label}</legend>
          {element}
        </fieldset>
      ) : (
        <div className={groupLabelClasses}>
          <p className={labelClasses}>{label}</p>
          {element}
        </div>
      )
  }

  return (
    <>
      <div className="tam-textarea-container">
        {React.cloneElement(element, {
          className: classNames(element.props?.className, rest.className) || null,
          style: {
            ...element.props?.style,
            ...style,
          },
        })}
        {formik?.touched[name] && formik?.errors[name] ? (
          <p className="error-message">{String(formik.errors[name])}</p>
        ) : null}
      </div>
    </>
  )
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, FormikTextAreaProps>(InternalTextArea)
