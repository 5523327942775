import { ReactSlashMenuItem } from '@blocknote/react'
import { ReactComponent as GoogleDocs } from '../../assets/icons/blockNote/docs.svg'
import { ReactComponent as GoogleSlides } from '../../assets/icons/blockNote/slides.svg'
import { ReactComponent as GoogleSheets } from '../../assets/icons/blockNote/sheets.svg'
import { ReactComponent as GoogleDrive } from '../../assets/icons/blockNote/drive.svg'
import { ReactComponent as IFrame } from '../../assets/icons/blockNote/iFrame.svg'
import { ReactComponent as Person } from '../../assets/icons/blockNote/person.svg'
import { ReactComponent as Code } from '../../assets/icons/blockNote/code.svg'

export const MentionMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const mentionBlock = { type: 'mention' }
  editor.updateBlock(currentBlock, mentionBlock)
}

export const insertMention: ReactSlashMenuItem = {
  name: 'Mention',
  execute: MentionMenu,
  aliases: ['Mention', 'me'],
  group: 'Inline',
  icon: <Person />,
  hint: 'Mention Person',
}

export const GoogleDocsMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const googleDocsBlock = { type: 'googleDocs' }
  editor.updateBlock(currentBlock, googleDocsBlock)
}

export const insetGoogleDocs: ReactSlashMenuItem = {
  name: 'Google Docs',
  execute: GoogleDocsMenu,
  aliases: ['google docs', 'gd'],
  group: 'Integrations',
  icon: <GoogleDocs />,
  hint: 'Insert Google Docs',
}

export const GoogleSlidesMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const googleSlideBlock = { type: 'googleSlides' }
  editor.updateBlock(currentBlock, googleSlideBlock)
}

export const insetGoogleSlides: ReactSlashMenuItem = {
  name: 'Google Slides',
  execute: GoogleSlidesMenu,
  aliases: ['google slides', 'gs'],
  group: 'Integrations',
  icon: <GoogleSlides />,
  hint: 'Insert Google Slides',
}

export const GoogleSheetsMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const googleSheetBlock = { type: 'googleSheets' }
  editor.updateBlock(currentBlock, googleSheetBlock)
}

export const insetGoogleSheets: ReactSlashMenuItem = {
  name: 'Google Sheets',
  execute: GoogleSheetsMenu,
  aliases: ['google sheets', 'gs'],
  group: 'Integrations',
  icon: <GoogleSheets />,
  hint: 'Insert Google Sheets',
}

export const GoogleDriveMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const googleDriveBlock = { type: 'googleDrive' }
  editor.updateBlock(currentBlock, googleDriveBlock)
}

export const insertGoogleDrive: ReactSlashMenuItem = {
  name: 'Google Drive',
  execute: GoogleDriveMenu,
  aliases: ['Drive', 'dr'],
  group: 'Integrations',
  icon: <GoogleDrive />,
  hint: 'Insert Google Drive',
}

export const IframeMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const iFrameBlock = { type: 'iFrame' }
  editor.updateBlock(currentBlock, iFrameBlock)
}

export const insertIframe: ReactSlashMenuItem = {
  name: 'Embed any website',
  execute: IframeMenu,
  aliases: ['Iframe', 'ifr'],
  group: 'Integrations',
  icon: <IFrame />,
  hint: 'Insert Embed Website',
}

export const CodeMenu = (editor) => {
  const currentBlock = editor.getTextCursorPosition().block
  const codeBlock = { type: 'codeBlock' }
  editor.updateBlock(currentBlock, codeBlock)
}

export const insertCode: ReactSlashMenuItem = {
  name: 'Code block',
  execute: CodeMenu,
  aliases: ['Code', 'c'],
  group: 'Advanced',
  icon: <Code />,
  hint: 'Insert Code',
}
