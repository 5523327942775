import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal, Skeleton } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useGetAllCustomFieldsQuery } from '../services/cusomFieldsApi'
import { ICustomField } from '@softylines/tam-types'

import Button from '@src/modules/shared/components/Button/Button'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import CustomDrawer from '../components/CustomDrawer'
import CustomFieldsFilter from '@src/modules/customFields/components/CustomFieldsFilter'
import CustomFieldsTable from '@src/modules/customFields/components/CustomFieldsTable'

import { ReactComponent as CloseIcon } from '@src/modules/shared/assets/icons/components/close-icon.svg'
import { ReactComponent as GroupByIcon } from '@src/modules/tasks/assets/icons/tasks/group-by.svg'
import { ReactComponent as SearchIcon } from '@src/modules/tasks/assets/icons/filter/search-icon.svg'
import NotFoundImage from '@src/modules/customFields/assets/Group.png'
import { groupByFields, groupByFieldType, IMetadataTypes } from '../utils/customFieldsHelpers'
import Search from '@src/modules/shared/components/Input/search'
type Option =
  | null
  | { isPublic: { operation: string; value: boolean } }
  | { files: { id: { operation: string; value: string } } }
  | { folders: { id: { operation: string; value: string } } }
  | { spaces: { id: { operation: string; value: string } } }
interface CustomFieldPayload {
  limit: number
  skip: number
  groupBy: string
  search: string
  sort: string | null
  options: any //TODO
}
interface CustomFieldsModalProps {
  id?: string
  data?: any
  handleClose?: (id: string) => void
}
const CustomFieldsModal = ({ id, data: modalData, handleClose }: CustomFieldsModalProps) => {
  const { t } = useTranslation(['customFields'])
  const [searchParams, setSearchParams] = useSearchParams()
  const searchRef = useRef<HTMLInputElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [search, setSearch] = useState('')
  const [groupBy, setGroupBy] = useState('0')
  const [activeItem, setActiveItem] = useState<string>('all_custom_fields')
  const [activeLocation, setActiveLocation] = useState({ id: '', name: '', path: '' })
  const [type, setType] = useState<string>('date')
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({ element: 'name', order: 'ASC' })
  const [filter, setFilter] = useState<{ filter: string }>({ filter: '' })
  const [customFieldsDrawerData, setCustomFieldsDrawerData] = useState('')

  const [customFieldData, setCustomFieldData] = useState<{
    metadata: IMetadataTypes
    payload: ICustomField[]
  }>({
    metadata: {},
    payload: [],
  })

  useEffect(() => {
    if (modalData) {
      const { space, folder, list } = modalData || {}
      if (space) {
        setActiveItem(space?.name)
        setActiveLocation({ id: space?.id, name: space?.name, path: 'space' })
      } else if (folder) {
        setActiveItem(folder?.name)
        setActiveLocation({ id: folder?.id, name: folder?.name, path: 'folder' })
      } else if (list) {
        setActiveItem(list?.name)
        setActiveLocation({ id: list?.id, name: list?.name, path: 'file' })
      }
    }
  }, [modalData])

  enum GroupByFields {
    FIELDTYPE = 'Field Type',
    LOCATIONTYPE = 'Location Type',
  }

  const handelClose = () => {
    setIsModalOpen(false)
    searchParams.delete('customFields')
    id && handleClose(id)
    setSearchParams(searchParams)
  }
  const [sortItem, setSortItem] = useState({})

  useEffect(() => {
    const element = sort.element
    const order = sort.order
    if (element?.toLocaleLowerCase() === 'created by') {
      setSortItem({ createdBy: { fullName: order } })
      setPage(1)
      return
    }
    setSortItem({
      [element?.toLocaleLowerCase() == 'created at'
        ? 'createdAt'
        : element?.toLocaleLowerCase() == 'location'
          ? 'locationType'
          : element?.toLocaleLowerCase() === 'visibility'
            ? 'isPublic'
            : element?.toLocaleLowerCase() === 'type'
              ? 'fieldType'
              : element?.toLocaleLowerCase()]: order,
    })
    setPage(1)
  }, [sort])
  const options =
    ([
      activeItem === 'all_custom_fields'
        ? null
        : activeItem === 'workspace'
          ? { isPublic: { operation: '$Equal', value: true } }
          : activeLocation.path == 'file'
            ? { files: { id: { operation: '$Equal', value: activeLocation.id } } }
            : activeLocation.path == 'folder'
              ? { folders: { id: { operation: '$Equal', value: activeLocation.id } } }
              : { spaces: { id: { operation: '$Equal', value: activeLocation.id } } },
    ].filter(Boolean) as Option[]) || null
  let filterPayload = !filter?.filter
    ? options?.length > 0 && {
        where: [options && options?.reduce((acc, option) => ({ ...acc, ...option }))],
      }
    : JSON?.parse?.(filter?.filter)

  if (options && options?.length > 0 && filterPayload?.where) {
    const newOption = options?.reduce((acc, option) => ({ ...acc, ...option }))

    const exists = filterPayload.where.some(
      (existingOption) => JSON.stringify(existingOption) === JSON.stringify(newOption),
    )

    if (!exists) {
      filterPayload.where.push(newOption)
    }
  }
  const payload: CustomFieldPayload = {
    limit: page * 20,
    skip: 1,
    groupBy: groupBy == '0' ? 'fieldType' : 'locationType',
    search: search,
    sort: sort.element.length > 0 ? JSON.stringify(sortItem) : null,
    options: JSON.stringify(filterPayload),
  }

  const { data, isLoading, isFetching } = useGetAllCustomFieldsQuery(payload)

  useEffect(() => {
    const CustomFieldData = { metadata: data?.metadata, payload: groupByFieldType(data?.payload) }
    setCustomFieldData(CustomFieldData)
  }, [data])

  const InvalidSearch = data?.payload?.length === 0 && search?.length > 0
  const InvalidFilter =
    data?.payload?.length === 0 && search?.length === 0 && filter?.filter?.length > 0
  return (
    <>
      <Modal
        footer={false}
        centered={true}
        transitionName=""
        open={isModalOpen}
        onOk={() => handelClose}
        onCancel={handelClose}
        wrapClassName="custom-fields"
        className="custom-fields-modal"
        closable={false}
        style={{ borderRadius: '14px' }}
      >
        <CustomDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          aciveLocation={activeLocation}
          type={type}
          setPage={setPage}
          page={page}
          customFieldData={customFieldData}
          setCustomFieldData={setCustomFieldData}
          data={customFieldsDrawerData}
        />
        <>
          <div className={`custom-fields-modal-sidebar`}>
            <div className={`custom-fields-modal-sidebar-col1`}>
              <span className={`custom-fields-modal-sidebar-span`}>
                {t('custom_field_manager')}
              </span>

              <div className="custom-fields-modal-sidebar-content">
                <div
                  className={` ${activeItem === 'all_custom_fields' ? 'custom-fields-modal-sidebar-content-active-item' : ''} custom-fields-modal-sidebar-content-item`}
                  onClick={() => {
                    setActiveItem('all_custom_fields')
                    setPage(1)
                  }}
                >
                  {t('all-custom-fields')}
                </div>

                <div
                  className={` ${activeItem === 'workspace' ? 'custom-fields-modal-sidebar-content-active-item' : ''} custom-fields-modal-sidebar-content-item`}
                  onClick={() => {
                    setActiveItem('workspace')
                    setPage(1)
                  }}
                >
                  {t('workspace')}
                </div>

                <div
                  className={` ${activeItem === 'personal_list' ? 'custom-fields-modal-sidebar-content-active-item' : ''} custom-fields-modal-sidebar-content-item`}
                  onClick={() => {
                    setActiveItem('personal_list')
                    setPage(1)
                  }}
                >
                  {t('personal_list')}
                </div>
              </div>
            </div>
            <div className="custom-fields-modal-sidebar-by-location">
              <div className={`custom-fields-modal-sidebar-by-location-title`}>
                {t('by_location')}
              </div>
              <div>
                <SpacesTree
                  readOnly
                  noDocumentView={true}
                  onSelectSpace={(items, content) => {
                    setActiveLocation({ id: items[0], name: content?.name, path: 'space' })
                    setActiveItem(content?.name)
                    setPage(1)
                  }}
                  onSelectFolder={(items, content) => {
                    setActiveLocation({ id: items[0], name: content?.name, path: 'folder' })
                    setActiveItem(content?.name)
                    setPage(1)
                  }}
                  autoOpen
                  onSelectList={(items, content) => {
                    setActiveLocation({ id: items[0], name: content?.name, path: 'file' })
                    setActiveItem(content?.name)
                    setPage(1)
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`custom-fields-modal-dashbord`}>
            <div className={'custom-fields-modal-dashbord-header-container'}>
              <div className={`custom-fields-modal-dashbord-header`}>
                <div> {t('all-custom-fields')} </div>

                <CloseIcon onClick={() => handelClose()} className="close-icon" />
              </div>
              <div className={`custom-fields-modal-dashbord-under-header`}>
                <div>
                  {activeItem === 'all_custom_fields'
                    ? t('all-custom-fields')
                    : activeItem === 'workspace'
                      ? t('workspace')
                      : activeItem === 'personal_list'
                        ? t('personal_list')
                        : activeLocation.name}
                </div>

                <Button
                  children={t('create_new_field')}
                  onClick={() => {
                    setOpenDrawer(true)
                    setCustomFieldsDrawerData('')
                  }}
                />
              </div>
              <div className={`custom-fields-modal-dashbord-navbar`}>
                <div>
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: groupByFields(groupBy),
                      onClick: ({ key }) => {
                        setGroupBy(key), setPage(1)
                      },
                    }}
                  >
                    <div className={`dashboard-tasks-button groupby-btn`}>
                      <GroupByIcon />
                      <p>
                        {t('group_by')}: {Object.values(GroupByFields)[groupBy]}
                      </p>
                    </div>
                  </Dropdown>
                  <CustomFieldsFilter filter={filter} setFilter={setFilter} setPage={setPage} />
                </div>
                <div className="custom-fields-modal-dashbord-navbar-search">
                  <Search
                    size="xs"
                    onSearch={(s: string) => {
                      setSearch(s)
                      setPage(1)
                    }}
                    style={{ width: '100%', maxWidth: '180px' }}
                    prefix={<SearchIcon />}
                    value={search}
                    containerClassName="custom-fields-modal-dashbord-navbar-search-input"
                    placeholder={t('search')}
                    variant="filled"
                    inputRef={searchRef}
                  />
                </div>
              </div>
            </div>
            {isLoading ? (
              <LoadingComponent rows={10} />
            ) : InvalidSearch || InvalidFilter ? (
              <div className="not-found-custom-fields">
                <img src={NotFoundImage} alt={t('not_found')} />
                <div className="text-content">
                  <h1>{t('no_result_found')}</h1>
                  <span>
                    {InvalidSearch
                      ? t('check_your_search_or_another')
                      : InvalidFilter
                        ? t('check_your_filter_or_another')
                        : ''}
                  </span>
                </div>
              </div>
            ) : customFieldData?.payload?.length === 0 ? (
              <div className="not-found-custom-fields">
                <img src={NotFoundImage} alt={t('not_found')} />
                <div className="text-content">
                  <h1>{t('no_result_found')}</h1>
                  <span>
                    {InvalidSearch
                      ? t('check_your_search_or_another')
                      : InvalidFilter
                        ? t('check_your_filter_or_another')
                        : ''}
                  </span>
                </div>
              </div>
            ) : (
              <CustomFieldsTable
                openDrawer={openDrawer}
                CustomFieldData={customFieldData}
                setPage={setPage}
                setCustomFieldData={setCustomFieldData}
                setOpenDrawer={setOpenDrawer}
                setType={setType}
                page={page}
                isLoading={isLoading}
                isFetching={isFetching}
                sort={sort}
                setCustomFieldsDrawerData={setCustomFieldsDrawerData}
                setSort={setSort}
              />
            )}
          </div>
        </>
      </Modal>
    </>
  )
}
const LoadingComponent = ({ rows }) => {
  return (
    <div className="custom-field-skeleton-container">
      {Array.from({ length: rows }).map((_, index) => (
        <div key={index} className="custom-field-skeleton">
          <Skeleton.Input key={`${index}-1`} />
          <Skeleton.Input key={`${index}-2`} />
          <Skeleton.Input key={`${index}-3`} />
          <Skeleton.Input key={`${index}-4`} />
        </div>
      ))}
    </div>
  )
}
export default CustomFieldsModal
