import { useTranslation } from 'react-i18next'
import { ReactComponent as LeftArrowSVG } from '../../assets/icons/dashboard/leftArrow.svg'
import { ReactComponent as RightArrowSVG } from '../../assets/icons/dashboard/rightArrow.svg'
import { formatDateToMultiLang } from '@src/modules/tasks/utils/formateDateToMultiLang'

const Toolbar = (props) => {
  const { i18n } = useTranslation()
  const { onNavigate, date, localizer } = props
  const navigate = (action: string) => onNavigate(action)
  const isAr = i18n.language === 'ar'
  const Left = isAr ? RightArrowSVG : LeftArrowSVG
  const Right = isAr ? LeftArrowSVG : RightArrowSVG
  return (
    <div className="calendar-tootbar">
      <div className="calendar-tootbar-left">
        <Left onClick={() => navigate(isAr ? 'NEXT' : 'PREV')} />
        <p>{formatDateToMultiLang(date, i18n?.language)}</p>
        <Right onClick={() => navigate(isAr ? 'PREV' : 'NEXT')} />
      </div>
      <div className="calendar-tootbar-right">
        <div onClick={() => navigate('TODAY')}>{localizer.messages['today']}</div>
      </div>
    </div>
  )
}

export default Toolbar
