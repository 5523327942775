import { Dropdown, Tooltip } from 'antd'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useTranslation } from 'react-i18next'

type props = {
  documentEmoji: string
  onEmojiClick: any
}

const DocumentEmogi = ({ documentEmoji, onEmojiClick }: props) => {
  const { t } = useTranslation(['document'])

  return documentEmoji ? (
    <Dropdown
      dropdownRender={() => (
        <Picker
          data={data}
          categories={['frequent', 'people', 'nature', 'foods', 'activity']}
          onEmojiSelect={(emoji) => {
            onEmojiClick(emoji)
          }}
        />
      )}
      trigger={['click']}
    >
      <Tooltip title={t('Change icon')}>
        <span className="document_emoji">{documentEmoji}</span>
      </Tooltip>
    </Dropdown>
  ) : (
    <></>
  )
}

export default DocumentEmogi
