import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import React from 'react'

type Props = {
  showReply: boolean
  setShowReply: (value: boolean) => void
  commentInputRef?: React.RefObject<HTMLTextAreaElement | HTMLDivElement>
  commentId?: string
}

function Reactions(props: Props) {
  const { t } = useTranslation(['taskDetails'])

  return (
    <div className="comment-reactions">
      <div className="reaction-left">
        {/* <Button className="react-button">
          <LikeSVG />
        </Button> */}

        {/* <Button className="react-button">
          <SmileSVG />
        </Button> */}
      </div>

      <Button
        onClick={() => {
          props.setShowReply(!props.showReply)
          setTimeout(() => {
            if (props.commentInputRef?.current) {
              props.commentInputRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          }, 0)
        }}
        className="reply-button"
      >
        {t('Reply')}
      </Button>
    </div>
  )
}

export default Reactions
