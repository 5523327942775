import type { Dayjs } from 'dayjs'

import CalendarDropDown from '../../Calendar/Calendar'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { useEffect, useRef, useState } from 'react'

import dayjs from 'dayjs'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { ISubTask } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

type Props = {
  taskId: string
  task: ISubTask
  taskStartDate: Dayjs
  taskDueDate: Dayjs
  isEditTaskPopup?: boolean
}

function SetTaskDate({ taskId, taskStartDate, task, taskDueDate, isEditTaskPopup }: Props) {
  const [updateTask] = useUpdateTaskMutation()

  const isFirstRender = useRef(true)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(taskStartDate))
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs(taskDueDate))

  useDebounce(
    () => {
      if (dueDate) {
        updateTask({
          id: taskId,
          dueDate: dueDate.toDate(),
          startDate: startDate.toDate(),
        })
      }
    },
    500,
    [startDate, dueDate],
  )

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  return (
    <CalendarDropDown
      defEnd={task?.dueDate}
      defStart={task?.startDate}
      setStart={(date) => setStartDate(date as Dayjs)}
      setEnd={(date) => setDueDate(date as Dayjs)}
      onClick={(e) => e.stopPropagation()}
      isEditTaskPopup={isEditTaskPopup}
    />
  )
}

export default SetTaskDate
