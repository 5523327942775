function ImagePlaceholder() {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.2844 46.1625H11.7444C8.70443 46.1625 5.92443 44.6225 4.32443 42.0225C2.72443 39.4225 2.58443 36.2625 3.94443 33.5225L7.38443 26.6224C8.50443 24.3825 10.3044 22.9825 12.3244 22.7625C14.3444 22.5425 16.4044 23.5424 17.9644 25.4825L18.4044 26.0424C19.2844 27.1224 20.3044 27.7025 21.3044 27.6025C22.3044 27.5225 23.2244 26.8025 23.9044 25.5825L27.6844 18.7625C29.2444 15.9425 31.3244 14.4825 33.5844 14.5825C35.8244 14.7025 37.7444 16.3825 39.0244 19.3425L45.2844 33.9625C46.4444 36.6625 46.1644 39.7425 44.5444 42.2025C42.9444 44.7025 40.2244 46.1625 37.2844 46.1625ZM12.8844 25.7625C12.8044 25.7625 12.7244 25.7625 12.6444 25.7825C11.6444 25.8825 10.7244 26.6825 10.0644 27.9825L6.62443 34.8825C5.72443 36.6625 5.82443 38.7624 6.86443 40.4625C7.90443 42.1625 9.74443 43.1825 11.7444 43.1825H37.2644C39.2244 43.1825 40.9644 42.2424 42.0444 40.6024C43.1244 38.9625 43.3044 37.0025 42.5244 35.2025L36.2644 20.5825C35.5044 18.7825 34.4444 17.6825 33.4244 17.6425C32.4844 17.5825 31.2644 18.5825 30.3044 20.2825L26.5244 27.1025C25.3644 29.1825 23.5444 30.4825 21.5644 30.6625C19.5844 30.8225 17.5644 29.8625 16.0644 27.9825L15.6244 27.4225C14.7844 26.3225 13.8244 25.7625 12.8844 25.7625Z"
        fill="#EAEFFD"
      />
      <path
        d="M14.5044 18.1626C10.3844 18.1626 7.00439 14.8026 7.00439 10.6626C7.00439 6.5226 10.3644 3.1626 14.5044 3.1626C18.6444 3.1626 22.0044 6.5226 22.0044 10.6626C22.0044 14.8026 18.6444 18.1626 14.5044 18.1626ZM14.5044 6.1626C12.0244 6.1626 10.0044 8.1826 10.0044 10.6626C10.0044 13.1426 12.0244 15.1626 14.5044 15.1626C16.9844 15.1626 19.0044 13.1426 19.0044 10.6626C19.0044 8.1826 16.9844 6.1626 14.5044 6.1626Z"
        fill="#EAEFFD"
      />
    </svg>
  )
}

export default ImagePlaceholder
