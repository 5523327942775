import * as Sentry from '@sentry/react'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App'
import './app/index.scss'
import './i18n'
import AuthProvider from './modules/auth/context/AuthProvider'
import { store } from './modules/shared/store'
import { register as registerServiceWorker } from './serviceWorkerRegistration'

Sentry.init({
  dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  maxBreadcrumbs: 50,
  debug: false,
  // Performance Monitoring
  environment: import.meta.env.VITE_APP_BASE_NODE_ENV,

  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <FlagsmithProvider
    options={{
      environmentID: import.meta.env.VITE_REACT_APP_FLAGSMITH_API_KEY,
      api: import.meta.env.VITE_REACT_APP_FLAGSMITH_API_URL,
    }}
    flagsmith={flagsmith}
  >
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  </FlagsmithProvider>,
)

registerServiceWorker()
