import { useEffect, useState } from 'react'
import { isValidImageUrl } from '../../utils/isValidURL'
import { Skeleton } from 'antd'

interface IAvatarProps {
  text: string
  color?: string
  src: string
  className?: string
  size?: 'small' | 'large' | 'default' | number
}

const sizes = {
  small: 25,
  large: 50,
  default: 38,
}

const getSize = (size: IAvatarProps['size']): number => {
  if (typeof size === 'number') return size
  return sizes[size] || sizes.default
}

const capitalizeFirstLetter = (str: string): string => {
  return str?.charAt(0)?.toUpperCase()
}

const Avatar = ({ src, text, color = '#f0f0f0', className = '', size }: IAvatarProps) => {
  const capitalizedText = capitalizeFirstLetter(text)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const checkImageValidity = async () => {
      const isValid = await isValidImageUrl(src)
      setIsValid(isValid as boolean)
      if (!isValid) setIsLoading(false)
    }

    checkImageValidity()
  }, [src])

  const handleImageLoad = () => {
    setIsLoading(false)
  }

  const handleImageError = () => {
    setIsLoading(false)
    setIsValid(false)
  }

  const imageSize = getSize(size)

  const containerStyle = {
    backgroundColor: color,
    width: imageSize,
    height: imageSize,
  }

  const imageStyle = {
    display: isLoading ? 'none' : 'block',
  }

  return (
    <div className={`avatar-container ${className}`} style={containerStyle}>
      {isLoading && <Skeleton.Avatar active size={imageSize} />}
      {isValid ? (
        <img
          src={src}
          alt="avatar"
          loading="lazy"
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={imageStyle}
        />
      ) : (
        !isLoading && <p>{capitalizedText}</p>
      )}
    </div>
  )
}

export default Avatar
