import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'
import { useAppDispatch } from '@src/modules/shared/store'
import { message } from 'antd'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { updateUserPassword } from '../../../data/Profile/ProfileThunk'

type FormValues = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const UpdatePassword = ({ setEnableUpdatePassword }) => {
  const { t } = useTranslation(['auth'])
  const dispatch = useAppDispatch()

  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(t('reset_password.Old password is required')),
      newPassword: Yup.string().required(t('reset_password.New password is required')),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('reset_password.Passwords must match'))
        .required(t('reset_password.Confirm the new password is required')),
    }),
    onSubmit: (values) => {
      delete values.confirmNewPassword
      dispatch(updateUserPassword(values))
        .unwrap()
        .then(() => {
          message.success(t('reset_password.Password updated successfully'))
        })
        .catch((error) => {
          message.error(error?.message || t('reset_password.Error while updating password'))
        })
    },
  })

  return (
    <div className="update-password-container">
      <form onSubmit={formik.handleSubmit}>
        <div className="password-input-container">
          <Input
            type="password"
            className="profile-password-input"
            name="oldPassword"
            formik={formik}
            variant="filled"
            placeholder={t('reset_password.Enter Your Current Password')}
            disabled={false}
            status={formik?.touched.oldPassword && formik.errors.oldPassword ? 'error' : 'success'}
          />
        </div>

        <div className="password-input-container">
          <Input
            type="password"
            className="profile-password-input"
            name="newPassword"
            formik={formik}
            variant="filled"
            placeholder={t('reset_password.Enter Your New Password')}
            disabled={false}
            status={formik?.touched.newPassword && formik.errors.newPassword ? 'error' : 'success'}
          />
        </div>

        <div className="password-input-container">
          <Input
            type="password"
            className="profile-password-input"
            name="confirmNewPassword"
            formik={formik}
            variant="filled"
            placeholder={t('reset_password.Confirm Your New Password')}
            disabled={false}
            status={
              formik?.touched.confirmNewPassword && formik.errors.confirmNewPassword
                ? 'error'
                : 'success'
            }
          />
        </div>

        <div className="update-password-buttons-container">
          <Button
            children={t('reset_password.Cancel')}
            className="cancel-password-button"
            onClick={() => setEnableUpdatePassword(false)}
          />

          <Button
            children={t('reset_password.Save change')}
            className="save-password-button"
            onClick={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default UpdatePassword
