export function isValidImageUrl(url: string) {
  // Create a temporary image element
  if (!url) return null
  const img = new Image()
  img.src = url

  // Check if the URL is a valid image by listening for the "load" and "error" events
  return new Promise((resolve) => {
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}
