import { ListType } from '../components/List/List'

export const toList = (obj: ListType | undefined) => {
  return {
    id: obj?.id,
    name: obj?.name,
    color: obj?.color,
    folderId: obj?.folderId,
    folder: obj?.folder,
    icon: obj?.icon,
    path: obj?.path,
    spaceId: obj?.spaceId,
    ownerId: obj?.ownerId,
    permissions: obj?.permissions,
    genericView: obj?.genericView,
  } as ListType
}
