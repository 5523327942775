export function getTimeDifferenceFromDate(
  dateString: string,
  language: string = 'en',
  noAgo: boolean = false,
): string {
  const currentDate = new Date()
  const date = new Date(dateString)
  const diffInSeconds = Math.abs(Math.floor((currentDate.getTime() - date.getTime()) / 1000))

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${language === 'ar' ? 'ثانية' : 'sec'} ${language === 'ar' ? 'مضت' : noAgo ? '' : 'ago'}`
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60)
    return `${diffInMinutes} ${language === 'ar' ? 'دقيقة' : 'min'} ${language === 'ar' ? 'مضت' : noAgo ? '' : 'ago'}`
  } else if (diffInSeconds < 86400) {
    const diffInHours = Math.floor(diffInSeconds / 3600)
    return `${diffInHours} ${language === 'ar' ? 'ساعة' : 'hour'} ${language === 'ar' ? 'مضت' : noAgo ? '' : 'ago'}`
  } else {
    const diffInDays = Math.floor(diffInSeconds / 86400)
    return `${diffInDays} ${language === 'ar' ? 'يوم' : 'day'} ${language === 'ar' ? 'مضت' : noAgo ? '' : 'ago'}`
  }
}
