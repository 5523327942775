import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import { useCallback, useEffect, useState } from 'react'
import DndCalendar from './DndCalendar'
import Toolbar from './Toolbar'
import { reformulateEvents } from './utils/reformulateEvents'
import {
  useGetMyTasksByDayQuery,
  useUpdateTaskMutation,
} from '@src/modules/tasks/services/tasksApi'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'

import { openTaskDetails } from '@src/modules/notifications/components/NotificationAsideCard/NotificationAsideCard'
import { useNavigate } from 'react-router-dom'
import { Spin, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { addHours } from 'date-fns'

const EventComponent = ({ event }) => {
  const { i18n } = useTranslation()
  const { title, task, start, end } = event || {}
  return (
    <div className="calendar-task-event">
      <div>
        <span style={{ background: task.status?.color }}></span>
        <Tooltip title={title}>
          <p>{title}</p>
        </Tooltip>
      </div>
      <p>
        {start.toLocaleTimeString(i18n.language)} - {end.toLocaleTimeString(i18n.language)}
      </p>
    </div>
  )
}

const Calendar = () => {
  const { t } = useTranslation(['dashboard'])
  const { createdTask } = useAppSelector((state) => state.tasks)
  const [date, setDate] = useState(new Date(Date.now()))
  const { data: tasks, isLoading, isError } = useGetMyTasksByDayQuery({ limit: 100, skip: 1 })
  const dispatch = useAppDispatch()
  const [events, setEvents] = useState<any>([])
  const [updateTask] = useUpdateTaskMutation()
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.auth.user)

  const onNavigate = useCallback(
    (newDate) => {
      setDate(newDate)
    },
    [setDate],
  )

  useEffect(() => {
    if (tasks?.payload) setEvents(reformulateEvents(tasks?.payload))
  }, [tasks])

  useEffect(() => {
    if (createdTask) {
      setEvents((prev) => reformulateEvents([...prev, createdTask]))
    }
  }, [createdTask])

  const onEventResize: withDragAndDropProps['onEventResize'] = (data) => {
    const { start, end, event, isAllDay } = data
    const { id } = event

    setEvents((currentEvents) => {
      return currentEvents.map((event) =>
        event?.id === id
          ? { ...event, start: new Date(start), end: new Date(end), allDay: isAllDay }
          : event,
      )
    })
    //@ts-ignore
    updateTask({ id, startDate: addHours(start, 1), dueDate: addHours(end, 1) })
  }

  const onEventDrop: withDragAndDropProps['onEventDrop'] = (data) => {
    onEventResize(data)
  }

  const selectSlotHandle = (slot) => {
    const { start, end } = slot
    dispatch(
      openModal({
        id: 'create-task-modal',
        data: { startDate: start, dueDate: end, users: [user] },
      }),
    )
  }

  const selectEventHandle = (event) => {
    const { id } = event || {}
    openTaskDetails(id, navigate)
  }

  return (
    <div className="dashboard-calendar">
      {isError && <div className="dashboard-get-data-error">Error while getting data</div>}
      <Spin spinning={isLoading}>
        <DndCalendar
          date={date}
          events={events}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          onNavigate={onNavigate}
          components={{
            toolbar: Toolbar,
            timeGutterHeader: () => {
              return <p>{t('calendra.all-day')}</p>
            },
            timeSlotWrapper: (props) => {
              return <div>{props.children}</div>
            },
            event: EventComponent,
          }}
          onSelectSlot={selectSlotHandle}
          onSelectEvent={selectEventHandle}
        />
      </Spin>
    </div>
  )
}

export default Calendar
