import { Skeleton } from 'antd'

const IntegrationSkeletonCard = () => {
  return (
    <div className="integration-card-skeleton">
      <Skeleton.Button
        active
        style={{ width: '50px', height: '50px', borderRadius: '100px', marginBottom: '10px' }}
      ></Skeleton.Button>

      <Skeleton.Button
        active
        style={{ width: '80px', height: '15px', borderRadius: '100px' }}
      ></Skeleton.Button>

      <Skeleton.Button
        active
        style={{ width: '180px', height: '15px', borderRadius: '100px' }}
      ></Skeleton.Button>

      <Skeleton.Button
        active
        style={{ width: '150px', height: '15px', borderRadius: '100px' }}
      ></Skeleton.Button>

      <Skeleton.Button
        active
        style={{ width: '100px', height: '15px', borderRadius: '100px' }}
      ></Skeleton.Button>
    </div>
  )
}

export default IntegrationSkeletonCard
