import 'swiper/css'

import { Editor, ICurrentQuestion } from './memberView.interface'
import React, { useCallback, useEffect, useState } from 'react'
import {
  useCreateAnswerMutation,
  useGetAnswerByQuestionQuery,
  useUpdateAnswerMutation,
} from '../../services/memberAnswerApi'

import AddAnswerButton from './components/AddAnswerButton/AddAnswerButton'
import AddBlocButton from './components/AddBlocButton/AddBlocButton'
import AnswersList from './components/AnswersList/AnswersList'
import { Attachment } from './components/QestionBlocsList/attachement.interface'
import { Avatar } from 'antd'
import Calendar from '../Calendar'
import EditAnswerButton from './components/EditAnswerButton/EditAnswerButton'
import InvalidDay from './components/InvalidDay/InvalidDay'
import MemberEmptyView from '../MemberEmptyView/MemberEmptyView'
import QestionBlocsList from './components/QestionBlocsList/QestionBlocsList'
import QuestionsList from './components/QuestionsList/QuestionsList'
import Spinner from '@src/modules/spaces/components/Spinner/Spinner'
import SuccessPopUp from './components/SuccessPopUp'
import addNewEditor from './utils/addNewEditor'
import { createAnswerFn } from './utils/handleCreateAnswer'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import socketManager from '@src/modules/shared/socket/socketManager'
import { updateAnswerFn } from './utils/handleUpdateAnswer'
import { useGetQuestionsQuery } from '../../services/managerQuestionsApi'
import { useGetQuickAnswersQuery } from '../../services/quickAnswerApi'
import { useTranslation } from 'react-i18next'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { useAppSelector } from '@src/modules/shared/store'
import { useParams, useSearchParams } from 'react-router-dom'
import { canAnswerQuestions } from './utils/canAnswerQuestions'

dayjs.extend(customParseFormat)

const MemberView = ({ team }) => {
  const { t } = useTranslation(['dailyQuestion'])
  const { data: managerQuestions, status } = useGetQuestionsQuery({ teamId: team?.id })
  const [currentResponse, setCurrentResponse] = useState<Date>(new Date())
  const [isLoading, setLoading] = useState<boolean>(false)
  const [currentQuestion, setCurrentQuestion] = useState<ICurrentQuestion | null>(null)
  const [dayNum, setDayNum] = useState()
  const [editors, setEditors] = useState<{ [key: string]: Editor[] }>({})
  const [success, setSuccess] = useState()
  const [isView, setIsView] = useState<boolean>(false)
  const [showCreation, setShowCreation] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isPrevDisbaled, setIsPrevDisbaled] = useState<boolean>(true)
  const [createAnswer] = useCreateAnswerMutation()
  const [udpateAnswer] = useUpdateAnswerMutation()
  let dateQuery: string
  let endDateQuery: string
  const endDate = new Date(currentResponse)
  endDate.setDate(endDate.getDate() + 1)
  const { width } = useWindowSize()
  const { myTeams } = useAppSelector((state) => state.teams)
  const userId = useAppSelector((state) => state.auth?.user?.user?.id)
  const { teamId: selectedTeamId } = useParams()
  const [searchParams] = useSearchParams()

  const hasAccess = canAnswerQuestions(myTeams, selectedTeamId, userId)

  endDateQuery = `${currentResponse.getFullYear()}-${(currentResponse.getMonth() + 1)
    .toString()
    .padStart(1, '0')}-${currentResponse.getDate().toString().padStart(1, '0')}`
  dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
    .toString()
    .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
  useEffect(() => {
    const questionId = searchParams.get('question')
    const currentTableData = managerQuestions?.filter((item) => item.id === questionId)
    if (managerQuestions && managerQuestions?.[0])
      setCurrentQuestion(currentTableData?.[0] || managerQuestions?.[0])
  }, [managerQuestions])
  const {} = useGetQuickAnswersQuery({
    limit: 10,
    page: 1,
  })
  const {
    data: answers,
    status: stats,
    refetch: getRes,
  } = useGetAnswerByQuestionQuery(
    {
      questionId: currentQuestion?.id,
      startDate: endDateQuery,
      endDate: dateQuery,
    },
    { skip: !currentQuestion?.id },
  )

  useEffect(() => {
    setIsEdit(false)
    if (getEditorsForQuestionId(currentQuestion?.id)?.length === 0) {
      handleAddNewEditor()
    }
    if (currentQuestion?.id) socketManager._joinRoom(currentQuestion?.id)
  }, [currentQuestion])

  useEffect(() => {
    if (stats === 'fulfilled' && answers?.payload?.current?.body) {
      const newEditorsMap = {}
      answers.payload.current.body.forEach((el, index) => {
        const questionId = currentQuestion?.id
        if (!newEditorsMap[questionId]) {
          newEditorsMap[questionId] = []
        }
        const currentDate = new Date(answers.payload.current.date)
        const editorDate = new Date(currentResponse)
        if (currentDate.toDateString() === editorDate.toDateString()) {
          const attachements = answers?.payload?.current?.attachments
            ?.filter((att) => el?.attachmentIds?.includes(att?.id))
            ?.map((att) => {
              return {
                data: att,
                editorId: answers.payload.current.id + index,
              }
            })
          newEditorsMap[questionId].push({
            id: answers.payload.current.id + index,
            content: el.content,
            editable: true,
            time: el.time,
            question: questionId,
            date: answers.payload.current.date,
            files: answers?.payload?.current?.attachments ? attachements : [],
          })
        }
      })

      setEditors(newEditorsMap)
      setIsView(true)
    } else {
      setEditors({})
      if (getEditorsForQuestionId(currentQuestion?.id)?.length === 0) {
        handleAddNewEditor()
        setIsEdit(false)
      }
      setIsView(false)
    }
  }, [answers, stats])

  const handleAddNewEditor = useCallback(() => {
    addNewEditor({ currentQuestion, editors, setEditors, currentResponse })
  }, [currentQuestion, editors, setEditors, currentResponse])

  const updateAnswerFunction = () => {
    updateAnswerFn(
      currentQuestion,
      currentResponse,
      getEditorsForQuestionId(currentQuestion?.id),
      setLoading,
      setIsView,
      setEditors,
      getRes,
      udpateAnswer,
      answers?.payload?.current?.id || '',
      setIsEdit,
    )
  }

  const createAnswerFunction = () => {
    createAnswerFn(
      currentQuestion,
      currentResponse,
      getEditorsForQuestionId(currentQuestion?.id),
      setLoading,
      setIsView,
      setEditors,
      getRes,
      createAnswer,
      setSuccess,
    )
  }

  const getEditorsForQuestionId = (questionId: string): Editor[] => {
    const current = editors[questionId] || []
    if (current?.length === 0) {
      handleAddNewEditor()
    }
    return current
  }

  const handleEdit = () => {
    setIsView(false)
  }
  const getCurrentTimeString = () => {
    const now = new Date()
    const hours = now.getHours().toString().padStart(2, '0')
    const minutes = now.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }
  let answerInTime = currentQuestion?.inTime
  let can =
    currentQuestion?.days?.includes(dayNum) &&
    (answerInTime
      ? currentQuestion?.startTime <= getCurrentTimeString() &&
        currentQuestion?.endTime >= getCurrentTimeString()
      : true)

  const setFilesForEditor = (
    questionId: string,
    editorId: number,
    files: { data: Attachment; editorId: number; id?: string }[],
  ) => {
    setEditors((prevEditors) => {
      const updatedEditors = { ...prevEditors }
      updatedEditors[questionId] = updatedEditors[questionId]?.map((editor) => {
        if (editor.id === editorId) {
          return {
            ...editor,
            files,
          }
        }
        return editor
      })
      return updatedEditors
    })
  }

  return (
    <>
      {status === 'pending' ? (
        <Spinner />
      ) : managerQuestions?.length > 0 ? (
        <div className="daily-question-member-container">
          <QuestionsList
            setCurrentQuestion={setCurrentQuestion}
            managerQuestions={managerQuestions}
            currentQuestion={currentQuestion}
            setSuccess={setSuccess}
          />
          <div className="avatar-answer-container">
            <motion.div
              key={currentQuestion?.id}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{ width: '100%' }}
            >
              <div className="question-answer-container">
                <Calendar
                  missings={answers?.payload?.missings || []}
                  setCurrentResponse={setCurrentResponse}
                  startDate={currentQuestion?.createdAt}
                  isPrevDisbaled={isPrevDisbaled}
                  setIsPrevDisbaled={setIsPrevDisbaled}
                  questionId={currentQuestion?.id}
                  setDayNum={setDayNum}
                  setIsEdit={setIsEdit}
                  AfterCurrentNumber={
                    width < 410 ? 0 : width < 570 ? 1 : width < 720 ? 2 : width < 900 ? 3 : 4
                  }
                  BeforeCurrentNumber={width < 485 ? 0 : width < 635 ? 1 : width < 825 ? 2 : 3}
                />
                {success && <SuccessPopUp setSuccess={setSuccess} />}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row-reverse',
                  }}
                >
                  {can && isView && (
                    <div className="answer-date">
                      <EditAnswerButton
                        handleEdit={handleEdit}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        answers={answers}
                      />
                    </div>
                  )}
                  {can && hasAccess && (
                    <div className="daily-description">
                      <p className="daily-description_bold">
                        {currentQuestion?.showMembers
                          ? t('answers_tab.all_members')
                          : t('answers_tab.only_managers_watchers')}
                        <Avatar
                          src={team.icon}
                          style={{
                            background: !team.icon && team?.color,
                            width: '30px',
                            height: '30px',
                          }}
                        >
                          {team?.name ? team?.name[0]?.toUpperCase() : '-'}
                        </Avatar>
                        {team?.name}
                      </p>
                      <p className="daily-description_thin">{t('answers_tab.see_replies')}</p>
                    </div>
                  )}
                </div>

                {hasAccess &&
                  (stats === 'pending' ? (
                    <span className="questions-loader"></span>
                  ) : (
                    <div className="answer_blocs">
                      {!can ? (
                        <InvalidDay
                          getCurrentTime={getCurrentTimeString}
                          startTime={currentQuestion?.startTime}
                        />
                      ) : (
                        <React.Fragment>
                          <QestionBlocsList
                            key={answers?.payload?.current?.id}
                            editors={getEditorsForQuestionId(currentQuestion?.id)}
                            isView={isView}
                            setEditors={setEditors}
                            currentQuestion={currentQuestion}
                            setShowCreation={setShowCreation}
                            currentResponse={currentResponse}
                            stats={stats}
                            setFilesForEditor={setFilesForEditor}
                          />
                          <AddBlocButton
                            isView={isView}
                            addNewEditor={handleAddNewEditor}
                            editors={editors}
                            setEditors={setEditors}
                            currentQuestion={currentQuestion}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  ))}
                {hasAccess && can && (
                  <AddAnswerButton
                    updateAnswerFn={updateAnswerFunction}
                    createAnswerFn={createAnswerFunction}
                    showCreation={showCreation}
                    isView={isView}
                    isEdit={isEdit}
                    isLoading={isLoading}
                    team={team}
                    endDateQuery={endDateQuery}
                    dateQuery={dateQuery}
                    currentQuestion={currentQuestion}
                  />
                )}
              </div>
            </motion.div>
          </div>
          <AnswersList
            team={team}
            endDateQuery={endDateQuery}
            dateQuery={dateQuery}
            currentQuestion={currentQuestion}
          />
        </div>
      ) : (
        <MemberEmptyView />
      )}
    </>
  )
}
export default MemberView
