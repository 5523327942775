import { Modal, message } from 'antd'
import { ReactComponent as CloseIcon } from './../../../assets/icons/components/close-icon.svg'
import { ReactComponent as TrashIcon } from './../../../assets/icons/components/trash.svg'
import { deleteList } from '@src/modules/spaces/data/fileSlice/fileThunk'
import { store, useAppDispatch } from '@src/modules/shared/store'
import { manageFiles, setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useState } from 'react'
import { deleteSpace } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { deleteFolder } from '@src/modules/spaces/data/folderSlice/folderThunk'
import { useLocation, useNavigate } from 'react-router-dom'
import { deleteDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { useTranslation } from 'react-i18next'
import OverflowTooltip from '../../OverflowTooltip'
import Button from '../../Button/Button'

const ConfirmModal = ({ open, handleClose, id, data }: any) => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [loading, setLoading] = useState<boolean>()
  const redirect = () => {
    navigate('/dashboard')
    localStorage.removeItem('lastSelectedList')
  }

  const DeleteHandler = () => {
    switch (data?.source) {
      case 'space':
        setLoading(true)
        dispatch(deleteSpace({ id: data.id }))
          .unwrap()
          .then(() => {
            dispatch(closeModal({ id }))
            message.success(t('Space Deleted with success'))
            redirect()
            store.dispatch(
              setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data.id }),
            )
          })
          .catch((err) => {
            message.error(err.message || 'error')
          })
          .finally(() => {
            setLoading(false)
          })
        break
      case 'list':
        setLoading(true)
        dispatch(deleteList({ id: data.id }))
          .unwrap()
          .then(() => {
            store.dispatch(
              setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data.id }),
            )

            if (state?.id === data.id) redirect()
            setLoading(false)
            dispatch(closeModal({ id }))
            message.success('List deleted successfully')
          })
          .catch((err) => {
            message.error(err.message || 'error')
          })
        break
      case 'folder':
        setLoading(true)
        dispatch(deleteFolder({ id: data.id || '' }))
          .unwrap()
          .then(() => {
            store.dispatch(
              setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data.id }),
            )
            dispatch(
              manageFiles({
                targetId: data.id,
                id: data.spaceId || '',
                newObj: null,
                type: 'remove',
                parent: 'space',
                targetData: 'folders',
                group: data?.group,
              }),
            )
            setLoading(false)
            redirect()
            dispatch(closeModal({ id }))
          })
          .catch((err) => {
            message.error(err.message || 'error')
          })
        break
      case 'document':
        setLoading(true)
        dispatch(deleteDocument(data?.id))
          .unwrap()
          .then(() => {
            store.dispatch(
              setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data.id }),
            )
            dispatch(
              manageFiles({
                targetId: data.id,
                id: data.spaceId || '',
                newObj: null,
                type: 'remove',
                parent: 'space',
                targetData: 'documents',
                group: data?.group,
              }),
            )
            setLoading(false)
            redirect()
            dispatch(closeModal({ id }))
          })
          .catch((err) => {
            message.error(err.message || 'error')
          })
        break
      default:
      // code block
    }
  }

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal confirmation-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('Delete confirmation.title')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div>
        <div className="delete-confirmation">
          <div className="trash-icon">
            <TrashIcon />
          </div>
          <div className="description">
            {t('Delete confirmation.part1')}
            <OverflowTooltip as={'span'} children={data?.name} className="description-tooltip" />
            <span>{data?.source}”</span>
            {t('Delete confirmation.part2')}
          </div>
        </div>

        <div className="form-actions">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              dispatch(closeModal({ id }))
            }}
          >
            {t('Delete confirmation.cancel')}
          </Button>
          <Button
            htmlType="submit"
            className="confirm-action"
            onClick={DeleteHandler}
            disabled={loading}
          >
            {loading ? t('Delete confirmation.loading') : t('Delete confirmation.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
