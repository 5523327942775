import { createSlice } from '@reduxjs/toolkit'
import sound from '../../notifications/assets/sound/newSound.mp3'

import {
  clearAllNotifications,
  clearNotification,
  getAllNotifications,
  getNotificationCount,
} from './NotificationsThunk'

const initialState: any = {
  status: 'idle',
  fetchStatus: 'idle',
  error: null,
  data: null,

  notifications: null,
  notification: null,
  metadata: null,
  notificationCount: null,
  importClickUpProgressValue: null,

  clearedNotification: '',
  newNotifications: [],
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getData: (state, { payload }) => {
      state.data = payload

      if (state?.data?.event === 'click-event' && state?.data?.status === 'progress') {
        state.importClickUpProgressValue = state.data.progress
      }

      if (
        state?.data?.activity ||
        state?.data?.comment ||
        (state?.data?.event === 'click-event' && state?.data?.status === 'success')
      ) {
        state.newNotifications = [...state.newNotifications, state.data]

        const notif = new Audio(sound)
        notif.play()
      }
    },

    setClearedNotification: (state, { payload }) => {
      state.clearedNotification = payload

      state.notifications = {
        ...state.notifications,
        payload: state.notifications?.payload?.filter((notif) => notif.id !== payload),
      }
    },

    clearNewNotification: (state, { payload }) => {
      state.newNotifications = state.newNotifications.filter((notif) => notif.id !== payload)
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllNotifications.pending, (state) => {
      state.error = null
      state.fetchStatus = 'loading'
    })

    builder.addCase(getAllNotifications.fulfilled, (state, { payload }: any) => {
      state.fetchStatus = 'succeeded'
      state.notifications = payload.payload
      state.metadata = payload.payload.metadata
    })

    builder.addCase(getAllNotifications.rejected, (state, { payload }: any) => {
      state.fetchStatus = 'failed'
      state.error = payload
    })

    /**** clearNotification */

    builder.addCase(clearNotification.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(clearNotification.fulfilled, (state, { payload }: any) => {
      state.status = 'succeeded'
      state.notification = payload
    })

    builder.addCase(clearNotification.rejected, (state, { payload }: any) => {
      state.status = 'failed'
      state.error = payload
    })

    /**** clearAllNotifications */

    builder.addCase(clearAllNotifications.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(clearAllNotifications.fulfilled, (state) => {
      state.status = 'succeeded'
      state.notifications = { payload: [] }
    })

    builder.addCase(clearAllNotifications.rejected, (state, { payload }: any) => {
      state.status = 'failed'
      state.error = payload
    })

    /**** getNotificationCount */

    builder.addCase(getNotificationCount.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })

    builder.addCase(getNotificationCount.fulfilled, (state, { payload }: any) => {
      state.status = 'succeeded'
      state.notificationCount = payload
    })

    builder.addCase(getNotificationCount.rejected, (state, { payload }: any) => {
      state.status = 'failed'
      state.error = payload
    })
  },
})

export const { getData, setClearedNotification, clearNewNotification } = notificationsSlice.actions

export default notificationsSlice.reducer
