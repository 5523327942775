import { addOpacity } from '@src/modules/shared/utils/addOpacity'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { memo, useEffect, useRef, useState } from 'react'
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'
import CalendarDropDown from '../../Calendar/Calendar'
import TaskActionMenu from '../../TaskActionMenu'
import TaskName from '../../TaskName'
import TaskPriority from '../../TaskPriority'
import TaskUsers from '../../TaskUsers'
import { ReactComponent as AddSubtaskIcon } from './../../../assets/icons/task/add-subtask.svg'
import { ReactComponent as AttachmentsIcon } from './../../../assets/icons/task/attachments.svg'
import { ReactComponent as SubtaskIcon } from './../../../assets/icons/task/subtask.svg'
import { Checkbox } from 'antd'
import { ListType } from '@src/modules/spaces/components/List/List'
import Tags from '@src/modules/shared/components/Tags/Tags'

interface taskCardProps {
  data: TaskType
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  style: DraggingStyle | NotDraggingStyle
  canOnlyView: boolean
  select: () => void
  list: ListType
  selected: boolean
  index: number
  setSize: (i: number, h: number) => void
}

const generateTaskPathBaseOnListPath = (list: ListType) => {
  const newPath = {
    name: list?.name,
    type: 'LIST',
    icon: list?.icon,
    isPrivate: list?.isPrivate,
  }

  return [...list?.path, newPath]
}

const renderAttachements = (attachements: string[]) => {
  return attachements?.map((attachement: string) => {
    return (
      <span className="attachement-container">
        <img src={attachement} alt="error" />
      </span>
    )
  })
}

const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

const TaskCard: React.FC<taskCardProps> = ({
  data,
  provided,
  snapshot,
  canOnlyView = false,
  index,
  list,
  style,
  select,
  selected,
  setSize,
}) => {
  const [task, setTask] = useState<TaskType>(data)
  const taskRef = useRef<HTMLDivElement>()
  const navigate = useNavigate()
  const isDragging = snapshot?.isDragging
  const [updateTask] = useUpdateTaskMutation()

  const localUpdateTask = (label: string, value) => setTask((prev) => ({ ...prev, [label]: value }))

  useEffect(() => {
    setTask(data)
    if (setSize) setSize(index, taskRef.current.getBoundingClientRect().height)
  }, [index, data])

  const path = generateTaskPathBaseOnListPath(list)

  const openTaskDetails = () => navigate(updateURL({ label: 'taskId', value: task?.id }))

  const attachements = task?.attachements
    ?.filter((attachement: { location: string }) =>
      isImage((attachement?.location || attachement) as string),
    )
    .map((attachement: { location: string }) => attachement?.location || attachement) as string[]

  const language = localStorage.getItem('i18nextLng')
  return (
    <div
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={(el) => {
        provided.innerRef(el)
        taskRef.current = el
      }}
      style={{
        ...provided.draggableProps.style,
        ...style,
        height: 'fit-content',
      }}
      data-is-dragging={isDragging}
      data-testid={task?.id || ''}
      key={task?.id}
    >
      <div
        className={`task-card ${isDragging ? 'task-dragging' : ''}  ${
          selected ? 'task-selected' : ''
        }`}
        onClick={(e) => {
          e.currentTarget === e.target ? openTaskDetails() : null
        }}
        dir={language === 'ar' && 'ltr'}
      >
        <div className="task-card-path" onClick={openTaskDetails}>
          {path?.map((item: any, index) => (
            <span key={index}>
              {item?.name} {index < path?.length - 1 && ' > '}
            </span>
          ))}
        </div>

        <div className="task-card-title-container" onClick={openTaskDetails}>
          <TaskName data={task} disable={canOnlyView} />
          <div className="task-priority">
            <TaskPriority
              showLabel={false}
              disable={canOnlyView}
              activePriorityCode={task?.priority}
              task={task}
              onSelect={(p) => localUpdateTask('priority', p)}
            />
          </div>
        </div>

        <div className="task-card-attachements" onClick={openTaskDetails}>
          {renderAttachements([...(attachements || [])].splice(0, 4))}
          {attachements?.length > 4 && (
            <span className="attachement-container rest-attachements">
              +{attachements?.length - 4}
            </span>
          )}
        </div>

        <div className="task-card-tags">
          {task?.tags?.slice(0, 2)?.map((tag, i) => {
            return (
              <div
                key={i}
                style={{
                  background: addOpacity(tag?.color, 40),
                  color: tag?.color,
                }}
              >
                <p className="board-selected-tag">{tag.name}</p>
              </div>
            )
          })}
          {task?.tags?.length > 2 ? <span> +{task?.tags?.length - 2}</span> : null}
        </div>

        <div className="task-card-subtasks-date">
          <div className="task-subtask-count" onClick={openTaskDetails}>
            <div>
              <SubtaskIcon />
              <span>{task?.subtasks?.length || 0}</span>
            </div>

            <div>
              <AddSubtaskIcon />
            </div>
          </div>
          <Tags
            tags={task?.tags}
            spaceId={list?.spaceId}
            isBordView={true}
            listView={true}
            taskId={task?.id}
            onChange={(select) => localUpdateTask('tags', select)}
          />

          <div className="task-duedate">
            <CalendarDropDown
              disable={canOnlyView}
              defStart={task?.startDate?.toString()}
              defEnd={task?.dueDate?.toString()}
              setEnd={(date) => {
                localUpdateTask('dueDate', date)
                updateTask({ id: task?.id, dueDate: date.toDate() })
              }}
              setStart={(date) => {
                localUpdateTask('startDate', date)
                updateTask({ id: task?.id, startDate: date.toDate() })
              }}
              onClick={(e) => e.stopPropagation()}
              isInTaskList={true}
            />
          </div>
        </div>

        <div className="task-card-footer" onClick={openTaskDetails}>
          <div className="members" onClick={(e) => e.stopPropagation()}>
            <TaskUsers
              disable={canOnlyView}
              displayAddButton={!task?.users?.length}
              userNumber={3}
              members={task?.users || []}
              setAssignedMembersList={(m) => {
                updateTask({
                  id: task?.id,
                  users:
                    m?.filter((user) => user?.email !== undefined)?.map((user) => user?.id) || null,
                  //@ts-ignore
                  teams: m?.filter((user: any) => user?.name !== undefined) || null,
                }).then((_res: any) => {
                  // if (res?.data?.message === 'Success') {
                  //   message.success('user assigned with success!')
                  // }
                })
                localUpdateTask('users', m)
              }}
            />
          </div>

          <div className="task-info">
            <div className="task-files" onClick={openTaskDetails}>
              {task.attachements?.length > 0 && (
                <>
                  <AttachmentsIcon />
                  <span>{task.attachements?.length}</span>
                </>
              )}
            </div>
            <div className="task-options">
              <Checkbox
                checked={selected}
                onClick={(e) => e.stopPropagation()}
                onChange={select}
                className={`select-checkbox-task ${selected ? 'show' : ''}`}
              />
              <TaskActionMenu disable={canOnlyView} data={task} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(TaskCard)
