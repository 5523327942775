import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from '../../../assets/icons/list/collapse-modal.svg'
import ListForm from '../../ListForm/ListForm'
import { useTranslation } from 'react-i18next'

const SpaceModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])

  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal list-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('list.title')}</p>
        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div>
        <ListForm isEdit={!!data?.isEdit} data={{ ...data }} />
      </div>
    </Modal>
  )
}

export default SpaceModal
