import { useAppSelector } from '@src/modules/shared/store'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { CustomfieldComponentMap } from '@src/modules/tasks/components/ListView/utils/CustomFieldComponentMap.tsx'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons.tsx'
import { ReactComponent as PlusIcon } from '@src/modules/customFields/assets/plus-icon.svg'
import CustomFieldActionMenu from '@src/modules/customFields/components/CustomFieldActionMenu'
import { useTranslation } from 'react-i18next'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { Skeleton } from 'antd'
import { ReactComponent as ListIcon } from '@src/modules/customFields/assets/list-icon.svg'
import { ICustomField } from '@softylines/tam-types'
import { useRef } from 'react'
import { useIsInViewport } from '@src/modules/tasks/hooks/useInViewport'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
interface IMetadataTypes {
  current_page?: number
  last_page?: number
  next?: { limit: number; page: number }
  rows_per_page?: number
  total_items?: number
}
interface ICustomFields {
  metadata?: IMetadataTypes
  payload: ICustomField[]
}
interface listColumnsProps {
  canOnlyView: boolean
  localUpdateTask: (key: string, value: any) => void
  CustomFieldData: ICustomFields //TODO
  openDrawer: boolean
  setOpenDrawer: (key: boolean) => void
  setType: (key: string) => void
  setPage: (key: number) => void
  page: number
  isLoading: boolean
  isFetching: boolean
  setCustomFieldData: (e: any) => void
  setCustomFieldsDrawerData: (e: any) => void
}
interface CustomFieldsGroupBy {
  fieldType?: string
  locationType?: string
  rows?: ICustomField[]
}
const CustomFieldListColumns = ({
  canOnlyView,
  CustomFieldData,
  setOpenDrawer,
  setPage,
  page,
  setCustomFieldData,
  setCustomFieldsDrawerData,
  isFetching,

  setType,
}: listColumnsProps) => {
  const { t } = useTranslation(['customFields'])
  const { customFields } = useAppSelector((state) => state.tasks)
  const [updateTask] = useUpdateTaskMutation()

  const getCustomField = (key) => {
    return CustomFieldsIcons.find((item) => item.field === key)
  }

  const RenderItem = ({ index, customField, item }) => {
    const divRef = useRef(null)
    const InVIew = useIsInViewport(divRef, null, 'custom-fields-list-view')
    return (
      <div
        className={'custom-fields-list-columns-items list-view-columns'}
        key={index}
        ref={divRef}
      >
        {InVIew ? (
          <>
            {!isFetching ? (
              <>
                <div className="custom-fields-list-columns-name">
                  <div className="custom-fields-list-columns-name-icon">{customField?.icon}</div>
                  <OverflowTooltip as="span" style={{ width: 200 }}>
                    {item?.name}
                  </OverflowTooltip>
                </div>

                {customFields?.map((field, i) => {
                  if (field.disable) return null

                  const ComponentConfig = CustomfieldComponentMap[field.field]
                  const { component: CustomComponent, props } = ComponentConfig || {}
                  if (!ComponentConfig) return null

                  const componentProps = props(field, item, canOnlyView, updateTask, item)

                  const customStyle = {
                    width: field.width,
                    minWidth: field.minWidth,
                    maxWidth: field.maxWidth,
                  }

                  return (
                    <div className="list-view-column" style={customStyle} key={i}>
                      <CustomComponent {...componentProps} />
                    </div>
                  )
                })}
                <div className="list-view-column list-view-more-column  ">
                  <CustomFieldActionMenu
                    disable={canOnlyView}
                    itemData={item}
                    setCustomFieldsDrawerData={setCustomFieldsDrawerData}
                    CustomFieldData={CustomFieldData}
                    setCustomFieldData={setCustomFieldData}
                    setOpenDrawer={setOpenDrawer}
                  />
                </div>
              </>
            ) : (
              <div className="custom-field-skeleton">
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </div>
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
  return (
    <div
      className="custom-fields-list-view"
      onScroll={(e) => handleScroll(e, CustomFieldData?.metadata?.total_items, page, 20, setPage)}
    >
      {CustomFieldData?.payload?.map((item: CustomFieldsGroupBy, index: number) => {
        const customField = getCustomField(item?.fieldType)
        return (
          <>
            <div className="list-view-item" key={index}>
              <div className={`list-view-item-title`}>
                <div>
                  {customField?.icon}
                  {item.locationType && <ListIcon />}
                  <span
                    style={{
                      color: customField?.color || (item.locationType && '#374957'),
                    }}
                  >
                    {t(
                      customField?.label ||
                        (item.locationType === 'file' ? 'List' : item.locationType),
                    )}
                  </span>
                </div>
              </div>

              <div className="custom-fields-list-columns">
                {item?.rows?.map((item: any, index) => {
                  const customField = getCustomField(item?.fieldType)
                  return (
                    <RenderItem index={index} customField={customField} item={item} key={index} />
                  )
                })}
              </div>

              <div
                className={'list-view-item-create-field'}
                onClick={() => {
                  setOpenDrawer(true)
                  setType(customField?.field)
                }}
              >
                {' '}
                <PlusIcon />
                {t('create_field')}
              </div>
            </div>
          </>
        )
      })}

      {CustomFieldData?.metadata?.last_page > CustomFieldData?.metadata?.current_page && (
        <div style={{ height: '50px' }}>
          <Skeleton active paragraph={false} className="skeleton-custom" />
        </div>
      )}
    </div>
  )
}

export default CustomFieldListColumns
