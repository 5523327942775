import sharedRoutes from './sharedRoutes'
import authRoutes from '../../auth/routes/routes'
import dashboardRoutes from '../../dashboard/routes/routes'
import spacesRoutes from '../../spaces/routes/routes'
import settingsRoutes from '../../settings/routes/routes'
import tasksRoutes from '../../tasks/routes/routes'
import documentRoutes from '../../document/routes/routes'
import notificationRoutes from '../../notifications/routes/routes'
import dailyQuestionsRoutes from '../../dailyQuestions/routes/routes'
import documentsRoutes from '../../documents/routes/routes'
import { Ability, AbilityTuple, MongoQuery } from '@casl/ability'

export const filterPrivateRoutes = (routes: any[], ability: Ability<AbilityTuple, MongoQuery>) => {
  return ability
    ? routes.filter((route: { requiredPermission: { action: string; resource: string } }) => {
        if (route.requiredPermission)
          return ability.can(route.requiredPermission.action, route.requiredPermission.resource)
        return true
      })
    : routes
}

const routes = [
  ...sharedRoutes,
  ...authRoutes,
  ...dashboardRoutes,
  ...spacesRoutes,
  ...settingsRoutes,
  ...tasksRoutes,
  ...documentRoutes,
  ...notificationRoutes,
  ...dailyQuestionsRoutes,
  ...documentsRoutes,
]

export default routes
