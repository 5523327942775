import { api } from '@src/modules/shared/services/api'

export const ListCustomFieldsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomFieldsByList: build.query<any, string>({
      query: (listId) => {
        return {
          url: `api/lists/${listId}/custom-fields`,
        }
      },

      providesTags: (result = []) => {
        if (result) {
          return [
            ...result.map(({ id }: { id: string }) => ({
              type: 'CustomFields',
              id,
            })),
            { type: 'CustomFields', id: 'FIELDS' },
          ]
        } else {
          return [{ type: 'CustomFields', id: 'FIELDS' }]
        }
      },
    }),

    createCustomField: build.mutation<any, any>({
      query: ({ ...body }) => ({
        url: `api/custom-fields`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CustomFields', id: 'FIELDS' }] as any,
    }),

    updateCustomField: build.mutation<
      any,
      Partial<
        any & {
          tagsId: string[]
          previousParentId?: string
        }
      >
    >({
      query(data) {
        const { id, previousParentId, ...body } = data
        return {
          url: `api/custom-fields/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),

    deleteCustomFields: build.mutation<any, any>({
      query(data) {
        const { id } = data
        return {
          url: `api/custom-fields/${id}`,
          method: 'DELETE',
        }
      },
    }),

    createCustomFieldValue: build.mutation<any, any>({
      query(body) {
        return {
          url: `api/custom-fields-value`,
          method: 'POST',
          body,
        }
      },
    }),

    updateCustomFieldValue: build.mutation<any, Partial<any & { fileId: string }>>({
      query({ id, ...body }) {
        return {
          url: `api/custom-fields-value/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),

    deleteCustomFieldValue: build.mutation<any, Partial<any & { fileId: string }>>({
      query({ id, ...body }) {
        return {
          url: `api/custom-fields-value/${id}`,
          method: 'DELETE',
          body,
        }
      },
    }),
  }),
})

export const {
  useGetCustomFieldsByListQuery,
  useUpdateCustomFieldMutation,
  useCreateCustomFieldMutation,
  useDeleteCustomFieldsMutation,
  useCreateCustomFieldValueMutation,
  useUpdateCustomFieldValueMutation,
  useDeleteCustomFieldValueMutation,
} = ListCustomFieldsApi
