import { Skeleton } from 'antd'
import TaskActivitiesSkeleton from './TaskActivitiesSkeleton'

function TaskDetailSkeleton() {
  return (
    <div className="task-detail-skeleton-container">
      <div className="task-detail-skeleton-left">
        <div className="section-1">
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '1px',
              }}
            >
              <Skeleton.Avatar active size={'small'} />
              <Skeleton.Avatar active size={'small'} />
              <Skeleton.Avatar active size={'small'} />
              <Skeleton.Avatar active size={'small'} />
              <Skeleton.Avatar active size={'small'} />
            </div>
            <Skeleton.Avatar active size={'small'} />
          </div>
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Skeleton.Avatar active size={'small'} />
            <Skeleton.Button size="small" shape="round" style={{ width: '62px' }} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Skeleton.Avatar active size={'small'} />
            <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          </div>
        </div>
        <div className="section2" style={{ width: '100%' }}>
          <Skeleton.Button size="small" shape="round" style={{ width: '500px' }} />
        </div>
        <div className="description-skeleton">
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
          />
        </div>
        <div className="tags-skeleton">
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
        </div>
        <div className="tags-skeleton">
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
          <Skeleton.Avatar active size={'small'} />
          <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
        </div>

        <div className="subtask-item-skeleton">
          <Skeleton.Avatar size={'small'} active style={{ width: '16px', height: '16px' }} />
          <Skeleton.Input active size={'small'} style={{ width: `${500}px`, height: '16px' }} />
        </div>
        <div className="skeleton-atatchements">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton.Button size="small" shape="round" style={{ width: '82px' }} />
            <Skeleton.Avatar active size={'small'} />
          </div>
          <Skeleton.Button
            size="small"
            shape="square"
            style={{ width: '182px', height: '116px' }}
          />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              width: '20%',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'column',
              }}
            >
              <Skeleton.Button
                size="small"
                shape="round"
                style={{
                  width: '150px',
                }}
              />
              <Skeleton.Button
                style={{
                  width: '150px',
                }}
                size="small"
                shape="round"
              />
            </div>
            <Skeleton.Avatar active size={'small'} />
          </div>
        </div>
      </div>
      <div className="task-detail-skeleton-right">
        <TaskActivitiesSkeleton />
      </div>
    </div>
  )
}

export default TaskDetailSkeleton
