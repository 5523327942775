interface MyObject {
  order?: number
}

export const orderByOrderField = (array: MyObject[]) => {
  if (!array?.some((obj) => 'order' in obj)) {
    return array
  }

  return array.slice().sort((a, b) => (a.order || 0) - (b.order || 0))
}
