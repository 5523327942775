export const isValidFilter = (filters): boolean => {
  let error = false

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]

    const { field, operation, orm, targetFilter } = filter || {}
    if (!field) {
      // setError(id, { type: 'field', error: 'Field Is Required' })
      error = true
    } else if (!operation) {
      // setError(id, { type: 'operation', error: 'Operation Is Required' })
      error = true
    } else if (!orm) {
      // setError(id, { type: 'orm', error: 'You have to select "AND" or "OR"' })
      error = true
    } else if (!targetFilter || targetFilter?.length === 0) {
      // setError(id, { type: 'targetFilter', error: 'Data is Empty' })
      error = true
    }

    if (!field && !operation && !targetFilter) error = false

    if (error) break
  }

  return !error
}
