import { createSlice } from '@reduxjs/toolkit'
import { getIntegrations, getUserIntegrations, toggleIntegrationStatus } from './IntegrationsThunk'

interface IntegrationsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: any | null
  metadata: any | null
  integrationsData: any | null
  userIntegrationsData: any | null
  loadingIntegrationId: string | null // Added field
}

const initialState: IntegrationsState = {
  status: 'idle',
  error: null,
  metadata: null,
  integrationsData: null,
  userIntegrationsData: null,
  loadingIntegrationId: null, // Initialize field
}

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* getIntegrations */
    builder.addCase(getIntegrations.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getIntegrations.fulfilled, (state, { payload }) => {
      state.integrationsData = payload?.payload?.payload
      state.metadata = payload?.payload?.metadata
      state.status = 'succeeded'
    })
    builder.addCase(getIntegrations.rejected, (state, { payload }) => {
      state.error = payload
      state.status = 'failed'
    })

    /* getUserIntegrations */
    builder.addCase(getUserIntegrations.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getUserIntegrations.fulfilled, (state, { payload }) => {
      state.error = null
      state.status = 'succeeded'
      state.userIntegrationsData = payload.payload
    })
    builder.addCase(getUserIntegrations.rejected, (state, { payload }) => {
      state.error = payload
      state.status = 'failed'
    })

    /** toggleIntegrationStatus */
    builder.addCase(toggleIntegrationStatus.pending, (state, { meta }) => {
      state.error = null
      state.status = 'loading'
      state.loadingIntegrationId = meta.arg.integrationId // Set loadingIntegrationId
    })
    builder.addCase(toggleIntegrationStatus.fulfilled, (state) => {
      state.error = null
      state.status = 'succeeded'
      state.loadingIntegrationId = null // Clear loadingIntegrationId
    })
    builder.addCase(toggleIntegrationStatus.rejected, (state) => {
      state.error = null
      state.status = 'failed'
      state.loadingIntegrationId = null // Clear loadingIntegrationId
    })
  },
})

export const {} = integrationsSlice.actions // eslint-disable-line

export default integrationsSlice.reducer
