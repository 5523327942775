export function fileToObjectURL(file: File) {
  if (!file) {
    console.error('No file provided.')
    return null
  }

  try {
    const objectURL = URL.createObjectURL(file)
    return objectURL
  } catch (error) {
    console.error('Error creating object URL:', error)
    return null
  }
}
