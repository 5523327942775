import EstimationSVG from './icons/EstimationSVG'
import { Dropdown, Tooltip } from 'antd'
import EstimationMenu from './EstimationMenu'
import { useTranslation } from 'react-i18next'
import { ISubTask } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

function Index({ task }: { task: ISubTask }) {
  const { t } = useTranslation(['taskDetails'])
  return (
    <Dropdown trigger={['click']} overlay={<EstimationMenu task={task} />} placement="bottomLeft">
      <Tooltip title={t('estimation')}>
        <div className="task-estimation">
          <EstimationSVG />
          <span>{task.estimation ? `${task.estimation} ${t('h')}` : t('estimation')}</span>
        </div>
      </Tooltip>
    </Dropdown>
  )
}

export default Index
