import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'
const AddComment = ({ comment }: { comment: any }) => {
  return (
    <div className="add_comment_action">
      <BlockNoteRichText value={comment?.content} onlyView={true} />
    </div>
  )
}

export default AddComment
