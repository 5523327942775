import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowUp } from '../../../assets/icons/task/arrow-up.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons/task/arrow-down.svg'
import useDebounce from '@src/modules/shared/hooks/useDebounce'

interface TimeSelectorProps {
  defaultHour?: number
  defaultMinute?: number
  onTimeChange?: (hour: number, minute: number) => void
  hours?: number
  minutes?: number
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  defaultHour = 0,
  defaultMinute = 0,
  hours,
  minutes,
  onTimeChange,
}) => {
  const [hour, setHour] = useState<number>(hours ? hours : defaultHour)
  const [minute, setMinute] = useState<number>(minutes ? minutes : defaultMinute)
  useEffect(() => {
    if (hours) setHour(hours)
  }, [hours])

  useEffect(() => {
    if (minutes) {
      setMinute(minutes)
    }
  }, [minutes])

  const handleHourChange = (delta: number) => {
    let newHour = hour + delta
    if (newHour < 0) newHour = 23
    if (newHour > 23) newHour = 0
    setHour(newHour)
  }

  const handleMinuteChange = (delta: number) => {
    let newMinute = minute + delta
    if (newMinute < 0) newMinute = 59
    if (newMinute > 59) newMinute = 0
    setMinute(newMinute)
  }

  useDebounce(
    () => {
      if (minute) onTimeChange && onTimeChange(hour, minute)
    },
    500,
    [minute, hour],
  )

  useDebounce(
    () => {
      if (hour) onTimeChange && onTimeChange(hour, minute)
    },
    500,
    [hour],
  )

  return (
    <div className="time-select-container">
      {/* Hours */}
      <div className="hours-container">
        <button onClick={() => handleHourChange(1)}>
          <ArrowUp />
        </button>
        <span>{hour?.toString()?.padStart(2, '0')} h</span>
        <button onClick={() => handleHourChange(-1)}>
          <ArrowDown />
        </button>
      </div>

      <span style={{ fontSize: '20px', padding: '10px' }}>:</span>

      {/* Minutes */}
      <div className="minutes-container">
        <button onClick={() => handleMinuteChange(1)}>
          <ArrowUp />
        </button>
        <span>{minute?.toString()?.padStart(2, '0')} m</span>
        <button onClick={() => handleMinuteChange(-1)}>
          <ArrowDown />
        </button>
      </div>
    </div>
  )
}

export default TimeSelector
