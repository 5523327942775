import socketManager from '@src/modules/shared/socket/socketManager'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import EditTaskPopup from '@src/modules/tasks/components/EditTaskPopup'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useFlags } from 'flagsmith/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import Header from '../../components/Header'
import RTLStyleDecorator from '../../components/RTLStyleDecorator'
import Sidebar from '../../components/Sidebar'
import { FLAGS } from '../../constants'
import MaintenanceProvider from '../../providers/MaintenanceProvider'
import NotificationsProvider from '../../providers/NotificationsProvider'
import { useAppDispatch, useAppSelector } from '../../store/index'
import { openModal } from '../../store/slices/modals/modalsSlice'
import { ReactComponent as AddTaskIcon } from './../../../tasks/assets/icons/task/add-task.svg'
import CustomFieldsModal from '@src/modules/customFields/feataures/CustomFields'
import GlobalTimer from '@src/modules/tasks/components/GlobalTimer/GlobalTimer'

interface MainLayoutProps {
  children: React.ReactNode
}

export const sidebarCloseAt = 1000

const MainLayout = ({ children }: MainLayoutProps) => {
  socketManager // create new socket connection from the socket manager
  const dispatch: Function = useAppDispatch()
  const flags = useFlags([FLAGS.MAINTENANCE])
  // create new socket connection from the socket manager
  const { t } = useTranslation(['layout'])
  const { user } = useAppSelector((state) => state.auth)
  const { listId = '' } = useParams()
  const { data: list } = useGetListQuery({ id: listId } as any, { skip: !listId })
  const { isGlobalTimerOpen } = useAppSelector((state) => state.timer)
  const [searchParams] = useSearchParams()
  const attachementId: string = searchParams.get('attachementId')
  const taskId = searchParams.get('taskId')
  const CustomFields = searchParams.get('customFields')

  const changedWsorkspace = localStorage.getItem('changedWorkspace')
  if (changedWsorkspace === null) {
    localStorage.setItem('changedWorkspace', 'no')
  }
  window.addEventListener('storage', (event) => {
    if (event.storageArea != localStorage) return
    if (event.key === 'changedWorkspace') {
      window.location.reload()
      localStorage.removeItem('changedWorkspace')
    }
  })

  useEffect(() => {
    if (user) {
      socketManager._connectSocket()
      socketManager.connectUserRoom(user?.user?.id)
    }
  }, [user])

  useEffect(() => {
    if (attachementId) dispatch(openModal({ id: 'media-player', data: { attachementId } }))
  }, [attachementId])

  if (flags.maintenance.enabled) {
    return <MaintenanceProvider />
  }

  // if (width < 1024) return <div className="no-res">{t('responsiveness_message')}</div>
  return (
    <Layout className="main-layout">
      {taskId && <EditTaskPopup />}
      {CustomFields && <CustomFieldsModal />}

      <NotificationsProvider />
      <RTLStyleDecorator disableRTLAdjustments>
        <div className={`dashboard-tasks-actions `}>
          {(isGlobalTimerOpen || user?.openTimer) && <GlobalTimer />}
          <div
            className="dashboard-tasks-actions-btn"
            onClick={() => dispatch(openModal({ id: 'create-task-modal', data: { list } }))}
          >
            <AddTaskIcon />
            {t('task')}
          </div>
        </div>
      </RTLStyleDecorator>

      <Header />

      <Layout className="main-layout-container">
        <Sidebar />
        <Content className="main-layout-content">{children}</Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
