import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface RTLStyleDecoratorProps {
  children: ReactNode
  disableRTLAdjustments?: boolean
  disableArabicClassName?: boolean
}

const RTLStyleDecorator: FC<RTLStyleDecoratorProps> = (props) => {
  const { i18n } = useTranslation()
  const isRTL = i18n.language === 'ar'
  const { children, disableRTLAdjustments = false, disableArabicClassName = false } = props
  const childRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const childNode = childRef.current
    let classesList = null
    let mainClass = null
    if (childNode) {
      classesList = childNode.classList
      mainClass = classesList[0]
    }

    if (childNode && !isRTL) classesList.remove(`${mainClass}-arabic`)

    if (childNode && !childNode.hasAttribute('data-processed') && isRTL) {
      if (!disableArabicClassName) {
        classesList.add(`${mainClass}-arabic`)
      }

      if (!disableRTLAdjustments && isRTL) {
        const childStyle = window.getComputedStyle(childNode)
        const paddingLeft = childStyle.getPropertyValue('padding-left')
        const paddingRight = childStyle.getPropertyValue('padding-right')
        const marginLeft = childStyle.getPropertyValue('margin-left')
        const marginRight = childStyle.getPropertyValue('margin-right')
        const left = childStyle.getPropertyValue('left')
        const right = childStyle.getPropertyValue('right')
        const position = childStyle.getPropertyValue('position')
        const borderRght = childStyle.getPropertyValue('border-right')
        const borderLeft = childStyle.getPropertyValue('border-left')

        if (paddingLeft || paddingRight) {
          childNode.style.paddingLeft = paddingRight || ''
          childNode.style.paddingRight = paddingLeft || ''
          childNode.setAttribute('data-processed', 'true')
        }

        if (marginLeft || marginRight) {
          childNode.style.marginLeft = marginRight || ''
          childNode.style.marginRight = marginLeft || ''
          childNode.setAttribute('data-processed', 'true')
        }

        if ((left || right) && (position === 'absolute' || position === 'fixed')) {
          childNode.style.left = right
          childNode.style.right = left
          childNode.setAttribute('data-processed', 'true')
        }

        if (borderLeft || borderRght) {
          childNode.style.borderLeft = borderRght || ''
          childNode.style.borderRight = borderLeft || ''
          childNode.setAttribute('data-processed', 'true')
        }
      }
    }
  }, [children, disableRTLAdjustments, isRTL])

  return React.Children.map(children, (child, index) => {
    if (index === 0) {
      return React.cloneElement(child as React.ReactElement<any>, { ref: childRef })
    }
    return child
  })
}

export default RTLStyleDecorator
