import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../components/Header'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import ManagerView from '../components/ManagerView/ManagerView'
import MemberView from '../components/MemberView/MemberView'
import { useAppSelector } from '@src/modules/shared/store'
import { useEffect, useState } from 'react'
import { isManagerCheck } from '../components/Sidebar/Sidebar'
import { Avatar, Tooltip, Segmented } from 'antd'
import MemberEmptyView from '../components/MemberEmptyView/MemberEmptyView'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Questions } from '../assets/icons/answers/questions.svg'
import { ReactComponent as AnswersIcon } from '../assets/icons/answers/answers.svg'
import { ReactComponent as AdvSearch } from '../assets/icons/manager/adv-search.svg'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import AdvancedSearch from '../components/AdvancedSearch/AdvancedSearch'
import Spinner from '@src/modules/shared/components/Spinner'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { UserIsWatcher } from '@src/modules/dashboard/components/FollowUp/components/Questions/QuestionITem'

const DailyQuestions = () => {
  const { t } = useTranslation(['dailyQuestion'])
  const { teamId: selectedTeamId } = useParams()
  const { user } = useAppSelector((state) => state.auth)
  const { myTeams, status } = useAppSelector((state) => state.teams)

  const userId = user?.user?.id

  useTitle(t('Follow-up'))

  const team = myTeams?.find((team) => team?.id === selectedTeamId)
  const isManager = isManagerCheck(team, userId)
  const isWatcher = UserIsWatcher(team?.roles, userId)
  const [searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(searchParams.get('tab'))
  let defaultView = searchParams.get('tab')
  const navigate = useNavigate()
  useEffect(() => {
    if (!isManager) {
      setActiveTab('2')
      navigate(updateURL({ label: 'tab', value: '2' }))
    } else {
      setActiveTab(defaultView)
      navigate(updateURL({ label: 'tab', value: defaultView }))
    }
  }, [selectedTeamId, isManager])
  const { width } = useWindowSize()
  const items = isManager
    ? [
        {
          label: t('question'),
          value: '0',
          icon: <Questions />,
        },
        {
          label: t('answer'),
          value: '2',
          icon: <AnswersIcon />,
        },
        {
          label: t('advanced_search'),
          value: '3',
          icon: <AdvSearch />,
        },
      ]
    : isWatcher
      ? [
          {
            label: t('answer'),
            value: '2',
            icon: <AnswersIcon />,
          },
          {
            label: t('advanced_search'),
            value: '3',
            icon: <AdvSearch />,
          },
        ]
      : [
          {
            label: t('answer'),
            value: '2',
            icon: <AnswersIcon />,
          },
        ]

  return (
    <ViewLayout header={Header}>
      {status === 'loading' ? (
        <div>
          <Spinner />
        </div>
      ) : !selectedTeamId || !team ? (
        <MemberEmptyView message={t('no_teams')} />
      ) : (
        <div className="daily-questions">
          <div className="shared-header">
            <div className="navbar dashboard-tasks-bar">
              <Segmented
                options={items}
                defaultValue={defaultView}
                onChange={(e) => {
                  navigate(updateURL({ label: 'tab', value: e }))
                  setActiveTab(e)
                }}
                value={activeTab}
              />
            </div>
            <div>
              {width >= 600 && (
                <Avatar.Group
                  maxCount={4}
                  maxStyle={{ color: '#986AAA', backgroundColor: '#FAEDFF' }}
                >
                  {team?.roles?.map((el, index: number) => {
                    return (
                      <Tooltip
                        title={el?.user?.fullName || el?.user?.email}
                        placement="top"
                        key={index}
                      >
                        {el?.user?.avatar ? (
                          <Avatar
                            src={el?.user?.avatar}
                            style={{ border: '1px solid var(--outline)' }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: 'rgb(167 179 186)',
                              cursor: 'pointer',
                              border: '1px solid var(--background)',
                            }}
                          >
                            {el?.user?.email[0]}
                          </Avatar>
                        )}
                      </Tooltip>
                    )
                  })}
                </Avatar.Group>
              )}
            </div>
          </div>
          {activeTab === '0' && isManager ? (
            <ManagerView team={team} />
          ) : activeTab === '3' ? (
            <AdvancedSearch team={team} />
          ) : (
            <MemberView team={team} />
          )}
        </div>
      )}
    </ViewLayout>
  )
}

export default DailyQuestions
