export const getOrmOptions = (setFilter, filter) => {
  const orms = ['AND', 'OR']
  return orms.map((orm, i) => ({
    key: i,
    label: orm,
    onClick: () => {
      setFilter({ ...filter, orm })
    },
  }))
}
