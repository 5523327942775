/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createList, deleteList } from './fileThunk'
import { ListType } from '../../components/List/List'
import { IFile } from '@softylines/tam-types'

export interface FileType extends Partial<IFile> {
  path?: string[]
  inheritStatusFromParent?: boolean
}

export interface FilesType {
  error: any
  status: string
  list: ListType | null
}

const initialState: FilesType = {
  error: null,
  status: 'idle',
  list: null,
}

const FilesSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createList.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(createList.fulfilled, (state) => {
      state.status = 'idle'
    })
    builder.addCase(createList.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(deleteList.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteList.fulfilled, (state) => {
      state.status = 'idle'
    })
    builder.addCase(deleteList.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {} = FilesSlice.actions // eslint-disable-line

export default FilesSlice.reducer
