import { documentType } from '../services/documents'

export const trasformDocuments = (data: documentType[] | null) => {
  return data
}

export const loadingHandler = (data, isLoading, globalLoading) => {
  const skeletons = isLoading ? Array(5).fill({ isSkeleton: true }) : []
  return globalLoading && !isLoading ? skeletons : [...(data || []), ...skeletons]
}
