import React, { useState } from 'react'
import { ReactComponent as Attachement } from '../../assets/attachement.svg'
import { ReactComponent as Quick } from '../../assets/quick.svg'
import { ReactComponent as SelectedQuick } from '../../assets/selectedQuick.svg'
import { ReactComponent as Coll } from '../../assets/collap.svg'
import QuickAnswers from '../QuickAnswers/QuickAnswers'
import CustomTimeInput from '../../../CustomTimeInput'
import AnswerAttachement from './AnswerAttachement'
import { ReactComponent as Download } from '../../assets/download.svg'
import { ReactComponent as Timer } from '../../assets/timer.svg'
import { Editor } from '../../memberView.interface'
import { useDeleteAttachementsMutation } from '@src/modules/tasks/services/tasksApi'
import { uploadImage } from '../../utils/uploadImage'
import { Attachment } from './attachement.interface'
import { message, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { previewAttachmentContent } from '@src/modules/tasks/components/EditTaskPopup/components/Attachements/index'
import { useTranslation } from 'react-i18next'
import updateEditorTime from '../../utils/updateEditorTime'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '@src/modules/shared/components/Button/Button'
import Input from '@src/modules/shared/components/Input/Input'

interface QuestionBlocFooterProps {
  isView: boolean
  setValue: (content: string) => void
  editor: Editor
  questionId: string
  setFilesForEditor: (
    questionId: string,
    editorId: number,
    files: { data: Attachment; editorId: number; id?: string }[],
  ) => void
  updateEditorContent: (key: string, editorId: number, newContent: string) => void
  deleteAttachementEditorContent: (key: string, editorId: number, id: string) => void
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>
}

const QuestionBlocFooter: React.FC<QuestionBlocFooterProps> = ({
  isView,
  setValue,
  editor,
  setFilesForEditor,
  questionId,
  updateEditorContent,
  deleteAttachementEditorContent,
  setEditors,
}) => {
  const { t } = useTranslation(['dailyQuestion'])
  const navigate = useNavigate()
  const cursor = {
    cursor: 'pointer',
  }
  const [showQuick, setShowQuick] = useState<boolean>(false)
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [deleteAttachements] = useDeleteAttachementsMutation()
  const handleUpdateEditorTime = React.useCallback((id: number | null, time: string | null) => {
    updateEditorTime(id, time, setEditors)
  }, [])

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (fileList) {
      document.body.classList.add('loader-cursor')
      const button = document.querySelector('.post-answer-container') as HTMLElement
      if (button) {
        button.style.display = 'none'
      }
      try {
        const newFiles = Array.from(fileList)
        const uploadedFiles = await Promise.all(newFiles.map(uploadImage))
        const updatedFiles = uploadedFiles.map((data) => ({ data, editorId: editor.id }))
        setFilesForEditor(questionId, editor?.id, [...editor?.files, ...updatedFiles])
      } catch (error) {
        message.error(error)
      } finally {
        document.body.classList.remove('loader-cursor')
        if (button) {
          button.style.display = 'flex'
        }
      }
    }
  }

  const handleDeleteFile = (id: string) => {
    try {
      document.body.classList.add('loader-cursor')
      deleteAttachements({ id }).unwrap()
    } catch (error) {
      message.error(error)
    } finally {
      document.body.classList.remove('loader-cursor')
    }
  }

  return (
    <>
      <div className="bloc-footer">
        <div className="bloc-footer_right" style={showQuick && !isView ? { width: '15%' } : {}}>
          <CustomTimeInput
            disabled={isView}
            updateEditorTime={handleUpdateEditorTime}
            value={editor?.time}
            id={editor.id}
          />
          <Tooltip title={'Duration'}>
            <Timer />
          </Tooltip>
        </div>
        <div className="bloc-footer_left" style={showQuick && !isView ? { width: '85%' } : {}}>
          <Input
            id={`fileInput-{${editor?.id}}`}
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,image/*"
            multiple
            hidden={true}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            ref={(fileInput) => {
              if (fileInput && editor?.files.length > 0) {
                fileInput.value = ''
              }
            }}
          />
          <Attachement
            style={cursor}
            onClick={() => {
              if (!isView) {
                const fileInput = document.getElementById(
                  `fileInput-{${editor?.id}}`,
                ) as HTMLInputElement
                if (fileInput) {
                  fileInput.click()
                }
              }
            }}
          />
          {editor?.files?.length > 0 && (
            <div className="bloc-footer_left-files">
              <p>
                {editor?.files?.length} {t('answers_tab.attached_files')}
              </p>
              <Coll
                cursor={'pointer'}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  rotate: collapsed ? '180deg' : '0deg',
                }}
              />
            </div>
          )}

          {!showQuick ? (
            <>
              {!isView && (
                <Quick
                  style={cursor}
                  onClick={() => {
                    if (!isView) setShowQuick(true)
                  }}
                />
              )}
            </>
          ) : (
            <SelectedQuick style={cursor} onClick={() => setShowQuick(false)} />
          )}
          {/*<AnimatePresence>*/}
          {showQuick && !isView && (
            <motion.div
              key={editor?.id}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.1 }}
              style={{ width: '100%' }}
            >
              <QuickAnswers
                editor={editor}
                questionId={questionId}
                setValue={setValue}
                updateEditorContent={updateEditorContent}
              />
            </motion.div>
          )}
          {/*</AnimatePresence>*/}
        </div>
      </div>
      <AnimatePresence>
        {collapsed && (
          <motion.div
            key={editor?.id}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.1 }}
            style={{ width: '100%' }}
          >
            <div className="bloc-footer_attachements">
              {editor?.files?.map((file: { data: Attachment; editorId: number }, index: number) => {
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div
                      key={index}
                      className="bloc-footer_attachement"
                      onClick={() => previewAttachmentContent(file?.data, navigate)}
                    >
                      <div className="bloc-footer_attachement-info">
                        <span>
                          <Tooltip title={file?.data?.originalname} placement="topLeft">
                            {file?.data?.originalname}
                          </Tooltip>
                        </span>
                        <a href={file?.data?.location} download target="_blank">
                          <Download />
                        </a>
                      </div>
                      {file?.data?.mimetype && file?.data?.mimetype?.startsWith('image/') ? (
                        <img src={file?.data?.location} alt="error" />
                      ) : (
                        <AnswerAttachement />
                      )}
                      {!isView && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            handleDeleteFile(file?.data?.id)
                            deleteAttachementEditorContent(questionId, editor?.id, file?.data?.id)
                          }}
                          className="bloc-footer_attachements-button"
                        >
                          x
                        </Button>
                      )}
                    </div>
                  </motion.div>
                )
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default React.memo(QuestionBlocFooter)

export function shortenFileName(fileName: string, maxLength: number): string {
  if (fileName?.length <= maxLength) {
    return fileName
  }
  const extensionIndex = fileName.lastIndexOf('.')
  const extension = fileName.substring(extensionIndex)
  const fileNameWithoutExtension = fileName.substring(0, extensionIndex)
  const remainingLength = maxLength - extension.length - 3
  const shortenedFileName = fileNameWithoutExtension.substring(0, remainingLength)

  return shortenedFileName + '...' + extension
}
