import { DatePicker } from 'antd'
import { useEffect, useRef } from 'react'
interface DateInputProps {
  selectedDate: any
  Icon: any
  setSelectDate: any
  dateType: string
  placeholder: string
  autoFocus?: boolean
  isSelected?: boolean
}
const DateInput: React.FC<DateInputProps> = ({
  Icon,
  selectedDate,
  setSelectDate,
  dateType,
  placeholder,
  isSelected,
}) => {
  const datePickerRef = useRef(null)
  useEffect(() => datePickerRef.current.focus(), [])

  return (
    <div className={`date-input-container ${isSelected ? 'input-date-selected' : ''}`}>
      <DatePicker
        ref={datePickerRef}
        autoFocus
        placeholder={placeholder}
        className="date-input"
        allowClear={false}
        value={selectedDate}
        inputReadOnly={true}
        open={false}
        format="YYYY-MM-DD HH:mm"
        size="large"
        suffixIcon={Icon}
        onFocus={() => (selectedDate !== null && setSelectDate ? setSelectDate(dateType) : null)}
      />
    </div>
  )
}
export default DateInput
