import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { editDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { documentApi } from '@src/modules/spaces/services/documentApi'
import { message } from 'antd'
import Input from '@src/modules/shared/components/Input/Input'

type props = {
  documentTitle: string
  documentId: string
}

const DocumentTitle = ({ documentTitle, documentId }: props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['document'])
  const [title, setTitle] = useState<string>(documentTitle)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (title !== documentTitle) {
      setTitle(documentTitle)
    }
  }, [documentTitle, documentId])

  useEffect(() => {
    if (title?.length > 50) {
      setError(t('Title is too long!'))
      message.error(t('Title is too long!'))
    } else setError(null)
  }, [title])

  useDebounce(
    () => {
      if (documentTitle !== title && !error) {
        dispatch(editDocument({ documentId, name: title }))
          .unwrap()
          .then(() => {
            dispatch(
              documentApi.util.updateQueryData('getDocument', documentId, (draft) => {
                return { ...draft, name: title }
              }),
            )
          })
      }
    },
    1000,
    [title],
  )

  return (
    <label className="documen_title_container">
      <Input
        size="xl"
        variant="label-inline"
        type="text"
        placeholder={title != undefined && t('Untitled')}
        className="document_main_title_input"
        onChange={(event) => setTitle(event.target.value)}
        value={title}
      />
      <span className="error">{error}</span>
    </label>
  )
}

export default DocumentTitle
