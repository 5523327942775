import { IYearlyOverview } from '../yearly.interface'
interface IStatus {
  name: string
  color: string
  percentage: string
  total: string
}

interface IMonthData {
  month: string
  status: IStatus[]
}
export const generateMonthlyData = (data: IYearlyOverview[]): IMonthData[] => {
  const months: { [key: number]: IMonthData } = {}
  for (let i = 1; i <= 12; i++) {
    const monthName = new Date(new Date()?.getFullYear(), i - 1)
      .toLocaleString('en-US', { month: 'short' })
      .toLowerCase()
    months[i] = {
      month: monthName,
      status: [],
    }
  }

  data.forEach(({ category, month }: any) => {
    const monthNumber = new Date(month).getMonth() + 1
    category?.forEach(({ name, color, count }) => {
      const total = category?.reduce((acc, curr) => acc + parseInt(curr.count, 10), 0)
      const percentage = ((parseInt(count, 10) / total) * 100).toFixed(2)
      const existingStatus = months[monthNumber].status.find((s) => s.name === name)

      if (existingStatus) {
        existingStatus.percentage = `${
          parseFloat(existingStatus.percentage) + parseFloat(percentage)
        }%`
      } else {
        months[monthNumber].status.push({
          name,
          color,
          percentage: `${percentage}%`,
          total: count,
        })
      }
    })
  })
  const monthlyData = Object.values(months)

  return monthlyData
}
