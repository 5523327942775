import { Avatar } from 'antd'

const SetAssignment = ({ users }: { users: any[] }) => {
  return (
    <div className="set_assignment_action">
      {users.slice(0, 10).map((user, index: number) => {
        if (user?.avatar) {
          return (
            <div className="user_profile_img " key={index}>
              {
                <Avatar src={user?.avatar}>
                  {user?.firstName
                    ? user?.firstName[0]?.toUpperCase()
                    : user?.email[0]?.toUpperCase()}
                </Avatar>
              }
            </div>
          )
        } else if (user?.icon) {
          return (
            <div className="user_profile_img ">
              {
                <Avatar src={user?.icon}>
                  {user?.name ? user?.name[0]?.toUpperCase() : user?.email[0]?.toUpperCase()}
                </Avatar>
              }
            </div>
          )
        } else
          return (
            <div className="first_letter">
              {user?.fullName
                ? user?.fullName?.slice(0, 1).toUpperCase()
                : user?.name
                  ? user?.name?.slice(0, 1).toUpperCase()
                  : user?.email?.slice(0, 1).toUpperCase()}
            </div>
          )
      })}
      {users.length > 10 ? '...' : ''}
    </div>
  )
}

export default SetAssignment
