import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import socketManager from '@src/modules/shared/socket/socketManager'
import { IDocument } from '@softylines/tam-types'
import { useEffect, useState } from 'react'

type props = {
  documentContent: string
  documentId: string
  document: IDocument
  isUploading: boolean
  setIsUploading: (e: boolean) => void
  setDocumentContetn: (e: string) => void
}

const DocumentContent = ({
  documentContent,
  documentId,
  isUploading,
  setIsUploading,
  setDocumentContetn,
}: props) => {
  const [content, setContent] = useState(documentContent)
  useEffect(() => {
    if (documentContent) {
      setContent(documentContent)
      setDocumentContetn(documentContent)
    }
  }, [documentContent, documentId])

  useDebounce(
    async () => {
      if (!isUploading && content !== documentContent && content?.indexOf('slash_input') == -1) {
        // await dispatch(editDocument({ documentId, content }))
        socketManager._updateDocument({
          payload: { content: content },
          roomId: documentId,
          documentId,
        })
      }
    },
    500,
    [content, isUploading],
  )
  return (
    <div className="document_content">
      <BlockNoteRichText
        value={documentContent}
        setValue={(e) => {
          setContent(e)
          setDocumentContetn(e)
        }}
        focus={false}
        key={documentContent}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      />
    </div>
  )
}

export default DocumentContent
