import { api } from '@src/modules/shared/services/api'
import { column } from '../data/tasksSlice/tasksTypes'
import { listApi } from '@src/modules/spaces/services/fileApi'

export const GenericViewsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateGenericView: build.mutation<any, { fileId: string; settings: column[] }>({
      query(data) {
        const { fileId, ...body } = data
        return {
          url: `api/genericView/${fileId}`,
          method: 'PATCH',
          body,
        }
      },

      async onQueryStarted({ fileId, settings }, { dispatch }) {
        dispatch(
          listApi.util.updateQueryData('getList', { id: fileId }, (draft) => {
            return {
              ...draft,
              genericView: {
                ...draft.genericView,
                settings,
              },
            }
          }),
        )
      },
    }),
  }),
})

export const { useUpdateGenericViewMutation } = GenericViewsApi
