import { sreenRecordingActions } from '@src/modules/shared/components/FloatGlobalActions/FloatGlobalActions'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { stopRecording } from '@src/modules/shared/store/slices/settings/settingsSlice'
import Spinner from '@src/modules/spaces/components/Spinner'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import { Dropdown, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RecordingSVG from '../icons/Recording'

interface AddAttachementProps {
  actions: any
  task: TaskType
  attachments: any
}

const AddAttachement = ({ task, actions }: AddAttachementProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['taskDetails'])

  const {
    screenRecording: { isRecording, recordedFile, taskId },
  } = useAppSelector((state) => state.settings)
  const [loading, setLoading] = useState<boolean>(false)
  const [updateTask] = useUpdateTaskMutation()

  useEffect(() => {
    if (recordedFile && !isRecording && taskId === task?.id) {
      setLoading(true)
      updateTask({
        id: task?.id,
        attachementsId: [recordedFile?.id],
      } as any)
        .unwrap()
        .finally(() => {
          setLoading(false)
        })
    }
  }, [recordedFile, taskId])

  const newItemActions = [
    ...(isRecording ? [] : [sreenRecordingActions(t)[0]]),
    {
      ...sreenRecordingActions(t)[1],
      onClick: () => actions.uploadEvent(),
      label: t('new_attachment'),
    },
  ]

  return (
    <div className="add-attachement-button">
      <Tooltip title={t('add_attachment')}>
        {loading ? (
          <Spinner />
        ) : (
          <Dropdown
            menu={{
              items: newItemActions,
            }}
            trigger={['click']}
            overlayClassName="global-action-dropdown"
          >
            <span>+</span>
          </Dropdown>
        )}
      </Tooltip>
      {isRecording && taskId && taskId === task?.id ? (
        <Tooltip title="stop recording">
          <span onClick={() => dispatch(stopRecording())} className="recording-icon">
            <RecordingSVG /> {t('stop_recording')}
          </span>
        </Tooltip>
      ) : null}
    </div>
  )
}

export default AddAttachement
