import { editDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { message, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import Input from '@src/modules/shared/components/Input/Input'
type Props = {
  documentCover: string
  documentId: string
  cover_y: number
  handleCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isCoverChanging: boolean
}

const DocumentCover = ({
  documentCover,
  documentId,
  cover_y,
  handleCoverChange,
  isCoverChanging,
}: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['document'])

  const backgroundImageRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isPositionChange, setIsPositionChange] = useState(false)
  const [backgroundImagePosition, setBackgroundImagePosition] = useState({ y: cover_y || 0 })
  const style = {
    opacity: `${isCoverChanging ? '.6' : '1'}`,
    objectPosition: `center ${backgroundImagePosition.y < 0 ? 0 : backgroundImagePosition.y > 100 ? 100 : backgroundImagePosition.y}%`,
    transition: isDragging ? 'none' : 'object-position 0.5s ease',
  }
  const [documentCoverUrl, setDocumentCoverUrl] = useState('')
  useEffect(() => {
    if (documentCover?.length > 0) {
      setDocumentCoverUrl(documentCover)
    }
  }, [documentCover])
  const [savedCoverY, setSavedCoverY] = useState(cover_y)
  useEffect(() => {
    if (!backgroundImagePosition?.y) setBackgroundImagePosition({ y: cover_y })

    setSavedCoverY(cover_y)
  }, [cover_y])

  useEffect(() => {
    if (!isPositionChange) {
      setIsDragging(false)
    }

    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        const movementY = event.movementY * 0.07
        setBackgroundImagePosition((prevPosition) => ({
          y: prevPosition.y - movementY,
        }))
      }
    }

    const handleMouseUp = () => {
      setIsDragging(false)
    }

    if (backgroundImageRef.current && isPositionChange) {
      backgroundImageRef.current.addEventListener('mousemove', handleMouseMove)
      backgroundImageRef.current.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      if (backgroundImageRef.current && isPositionChange) {
        backgroundImageRef.current.removeEventListener('mousemove', handleMouseMove)
        backgroundImageRef.current.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isDragging])

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragStart = () => {
    return false
  }
  return documentCoverUrl ? (
    <div className="document_cover_image" ref={backgroundImageRef}>
      <img
        src={documentCoverUrl}
        style={style}
        className={isPositionChange ? 'isPositionChange' : ''}
        onBlur={() => setIsPositionChange(false)}
        onMouseDown={handleMouseDown}
        onDragStart={handleDragStart}
        alt="Document Cover"
      />
      {isCoverChanging && (
        <div className="cover-loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
        </div>
      )}
      {isPositionChange && <span className="drag-message">{t('Drag image to reposition')}</span>}

      <div className="cover_btns">
        <Input
          type="file"
          accept="image/jpeg,image/png,image/gif,image/bmp,image/webp"
          id="change-input-cover"
          onChange={handleCoverChange}
          style={{ display: 'none' }}
        />
        <label
          htmlFor={!isPositionChange ? 'change-input-cover' : ''}
          onClick={() => {
            if (isPositionChange) {
              dispatch(editDocument({ documentId, cover_y: backgroundImagePosition.y }))
                .unwrap()
                .then(() => {
                  message.success(t('Position updated with success'))
                  setIsPositionChange(false)
                  setSavedCoverY(backgroundImagePosition.y)
                })
            }
          }}
        >
          <span>{isPositionChange ? t('Save Position') : t('Change Cover')}</span>
        </label>
        <span
          onClick={() => {
            setIsPositionChange(!isPositionChange)
            setBackgroundImagePosition({ y: savedCoverY })
          }}
        >
          {isPositionChange ? t('Cancel') : t('Reposition')}
        </span>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default DocumentCover
