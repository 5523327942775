import { api } from '@src/modules/shared/services/api'

export type TasksOverviewPayload = { name: string; color: string; count: string }

export const tasksOverviewApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasksOverview: build.query<any, any>({
      query: () => ({
        url: `api/stat/tasks`,
      }),
      transformResponse: (data): any => {
        return data
      },
    }),
    getYearlyOverview: build.query<any, any>({
      query: (params) => ({
        url: 'api/stat/monthly',
        params,
      }),
      transformResponse: (data) => {
        return data
      },
    }),
  }),
})

export const { useGetTasksOverviewQuery, useGetYearlyOverviewQuery } = tasksOverviewApi
