import { detectLanguage } from '@src/modules/spaces/utils/detectLanguage'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

type Props = {
  task: TaskType
  hasScrolled: boolean
}

function StickyTaskName({ task, hasScrolled }: Props) {
  const language = detectLanguage(task?.name)
  const textDirection = language === 'arabic' ? 'rtl' : 'ltr'

  return (
    <div className={`sticky-task-name ${hasScrolled ? 'visible' : ''}`}>
      <div className="task-name">
        <span style={{ direction: textDirection }}>{task?.name}</span>
      </div>
    </div>
  )
}

export default StickyTaskName
