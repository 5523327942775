import { useState } from 'react'
import { useUpdateTaskMutation } from '@src/modules/tasks/services/tasksApi'
import EstimationSVG from './icons/EstimationSVG'
import NoteSVG from './icons/NoteSVG'
import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ISubTask } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import Input from '@src/modules/shared/components/Input/Input'

type Props = {
  task: ISubTask
}

function EstimationMenu(props: Props) {
  const { t } = useTranslation(['taskDetails'])
  const [updateTask] = useUpdateTaskMutation()
  const [estimation, setEstimation] = useState<string>('')

  const [searchParams, _] = useSearchParams()
  const taskId = searchParams.get('taskId')

  useDebounce(
    () => {
      updateTask({ id: taskId, estimation: estimation as any })
    },
    1500,
    [estimation],
  )
  const handleChange = (e) => {
    const inputValue = e.target.value

    const sanitizedValue = inputValue.replace(/\D/g, '')

    if (sanitizedValue.length <= 4) {
      setEstimation(sanitizedValue)
    }
  }

  return (
    <div className="task-estimation-menu">
      <div className="estimation-title">
        <div className="title">
          <EstimationSVG />
          <span>{t('estimation')}</span>
        </div>

        <small className="note">
          <NoteSVG /> {t('days_equal_20')}
        </small>
      </div>

      <div className="estimation-input">
        <div>{t('time_estimate')}</div>
        <Input
          type="text"
          value={props.task.estimation ? props.task.estimation : estimation}
          onChange={handleChange}
          placeholder={t('type_in_time')}
          size="xs"
          containerClassName="estimation-input-input"
        />
      </div>

      <div
        style={{ width: '100%', height: '5px', borderBottom: ' 1px solid var(--Stroke, #ccd2e3)' }}
      ></div>

      <div className="estimation-note">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4392_80807)">
            <path
              d="M7.16667 3.36111H4.14444C3.87998 3.36111 3.74774 3.36111 3.64673 3.30964C3.55787 3.26437 3.48563 3.19213 3.44036 3.10327C3.38889 3.00226 3.38889 2.87002 3.38889 2.60556V1M8.11111 9.5V6.47778C8.11111 6.21331 8.11111 6.08107 8.05964 5.98006C8.01437 5.89121 7.94213 5.81896 7.85327 5.77369C7.75226 5.72222 7.62002 5.72222 7.35556 5.72222H4.14444C3.87997 5.72222 3.74774 5.72222 3.64673 5.77369C3.55787 5.81896 3.48563 5.89121 3.44036 5.98006C3.38889 6.08107 3.38889 6.21331 3.38889 6.47778V9.5M10 3.98703V7.23333C10 8.02674 10 8.42344 9.84559 8.72649C9.70977 8.99305 9.49305 9.20977 9.22649 9.34559C8.92344 9.5 8.52674 9.5 7.73333 9.5H3.76667C2.97326 9.5 2.57656 9.5 2.27351 9.34559C2.00695 9.20977 1.79023 8.99305 1.65441 8.72649C1.5 8.42344 1.5 8.02674 1.5 7.23333V3.26667C1.5 2.47326 1.5 2.07656 1.65441 1.77351C1.79023 1.50695 2.00695 1.29023 2.27351 1.15441C2.57656 1 2.97326 1 3.76667 1H7.01297C7.24397 1 7.35947 1 7.46816 1.0261C7.56453 1.04923 7.65666 1.08739 7.74116 1.13917C7.83647 1.19758 7.91814 1.27925 8.08148 1.44259L9.55741 2.91852C9.72075 3.08186 9.80242 3.16353 9.86083 3.25884C9.91261 3.34334 9.95077 3.43547 9.9739 3.53184C10 3.64053 10 3.75603 10 3.98703Z"
              stroke="#6A859A"
              strokeWidth="0.75"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4392_80807">
              <rect width="10" height="10" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <div className="name">{t('changes_auto')}</div>
      </div>
    </div>
  )
}

export default EstimationMenu
