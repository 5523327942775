import { useEffect, useRef, useState } from 'react'
import screenIcon from '../../assets/icons/screen.svg'
import screenIcon2 from '../../assets/icons/second_screen.svg'
import screenIcon3 from '../../assets/icons/third_screen.svg'
import paintIcon from '../../assets/icons/paint.svg'
import messagesIcon from '../../assets/icons/messages.svg'
import usersIcon from '../../assets/icons/user_group.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import Input from '@src/modules/shared/components/Input/Input'

interface CarouselProps {
  confirm: boolean
  confirmWorkspaceName: boolean
  confirmAvatar: boolean
  confirmTheme: boolean
  confirmUsersNumber: boolean
  confirmInviteUsers: boolean
}
const Carousel: React.FC<CarouselProps> = ({
  confirm,
  confirmWorkspaceName,
  confirmAvatar,
  confirmTheme,
}) => {
  const cardsRef = useRef([])
  const [selectedItem, setSelectedItem] = useState<number>(1)

  useEffect(() => {
    if (confirm) {
      setSelectedItem(2)
    }
    if (confirmWorkspaceName) {
      setSelectedItem(3)
    }
    if (confirmAvatar) {
      setSelectedItem(1)
    }
    if (confirmAvatar) {
      setSelectedItem(1)
    }
    if (confirmTheme) {
      setSelectedItem(2)
    }
  }, [confirm, confirmWorkspaceName, confirmAvatar, confirmTheme])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    }

    const callback = (entries: any[]) => {
      entries.forEach(
        (entry: { target: { getAttribute: (arg0: string) => any }; isIntersecting: any }) => {
          const targetId = entry.target.getAttribute('id')
          if (entry.isIntersecting && targetId) {
            setSelectedItem(parseInt(targetId.split('-')[1]))
          }
        },
      )
    }

    const observer = new IntersectionObserver(callback, options)

    cardsRef.current.forEach((card) => {
      observer.observe(card)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleRadioChange = (item: number) => {
    setSelectedItem(item)
    document.body.classList.toggle('blue')
  }

  return (
    <RTLStyleDecorator>
      <div className="container">
        <Input
          type="radio"
          name="slider"
          id="item-1"
          checked={selectedItem === 1}
          onChange={() => handleRadioChange(1)}
          disabled
        />
        <Input
          type="radio"
          name="slider"
          id="item-2"
          checked={selectedItem === 2}
          onChange={() => handleRadioChange(2)}
          disabled
        />
        <Input
          type="radio"
          name="slider"
          id="item-3"
          checked={selectedItem === 3}
          onChange={() => handleRadioChange(3)}
          disabled
        />

        <div className="cards">
          <RTLStyleDecorator>
            <label className="card" htmlFor="item-1" id="icon-1">
              <div className="rectangle">
                <img src={confirmAvatar ? paintIcon : screenIcon} />
              </div>
            </label>
          </RTLStyleDecorator>

          <RTLStyleDecorator>
            <label className="card" htmlFor="item-2" id="icon-2">
              <div className="rectangle">
                <img src={confirmTheme ? usersIcon : screenIcon2} />
              </div>
            </label>
          </RTLStyleDecorator>

          <RTLStyleDecorator>
            <label className="card" htmlFor="item-3" id="icon-3">
              <div className="rectangle">
                <img src={confirmTheme ? messagesIcon : screenIcon3} />
              </div>
            </label>
          </RTLStyleDecorator>
        </div>
      </div>
    </RTLStyleDecorator>
  )
}

export default Carousel
