import deepClone from 'deep-clone'
import { convertToTemplateTree } from '../SpacesTree'
import { removeDuplicate } from '@src/modules/tasks/components/ListView/utils/listViewManagment'

export const socketSpacesManagement = {
  updateItem: (tree, updatedObject: any) => {
    const newTree = deepClone(tree)
    newTree[updatedObject?.id] = { ...newTree[updatedObject?.id], content: updatedObject }
    return newTree
  },

  deleteItem: (tree, deletedId: string) => {
    if (!deletedId) return tree
    const newTree = deepClone(tree)

    const deleteItem = newTree[deletedId]
    delete newTree[deletedId]
    if (deleteItem?.parentId) {
      newTree[deleteItem?.parentId] = {
        ...newTree[deleteItem?.parentId],
        children: newTree[deleteItem?.parentId]?.children?.filter((id) => id !== deletedId),
      }
    }
    return newTree
  },

  createItem: (tree, createdObject: any) => {
    if (!createdObject) return tree
    let newTree = deepClone(tree)
    const { spaceId, folderId, path, id, cover } = createdObject || {}
    let type = null
    let parentId = null
    if (spaceId === undefined) {
      type = 'space'
      parentId = 'root'
    } else if ((spaceId && (folderId || folderId === null)) || path) {
      if (cover !== undefined) {
        type = 'document'
        parentId = folderId || spaceId
      } else {
        type = 'list'
        parentId = folderId || spaceId
      }
    } else if (spaceId && folderId === undefined) {
      type = 'folder'
      parentId = spaceId
    }
    if (!parentId || !type) {
      console.error('There was a problem , parent or type not found or type')
      return newTree
    }
    newTree = { ...newTree, ...convertToTemplateTree([createdObject], type) }
    newTree[parentId] = {
      ...newTree[parentId],
      children: removeDuplicate(organizeTreeChildren(newTree, newTree[parentId]?.children, id)),
    }

    return newTree
  },
}

const organizeTreeChildren = (tree, childrens = [], newItemId) => {
  const folders = getTargetType(tree, 'folder', childrens)
  const lists = getTargetType(tree, 'list', childrens)
  const spaces = getTargetType(tree, 'space', childrens)
  const documents = getTargetType(tree, 'document', childrens)
  const newItem = tree[newItemId]

  const data = {
    folder: folders,
    list: lists,
    space: spaces,
    document: documents,
  }

  data?.[newItem?.type]?.push(newItemId)

  return [...data.folder, ...data.list, ...data.document, ...data.space]
}

const getTargetType = (tree, type, childrens) =>
  childrens?.filter((id: string) => tree[id]?.type === type)
