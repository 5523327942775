import { Dropdown, MenuProps, message } from 'antd'
import { ReactComponent as SettingIcon } from './../../assets/icons/members/settings.svg'
import { ReactComponent as RemoveIcon } from './../../assets/icons/members/remove.svg'
import { ReactComponent as ManagerIcon } from './../../assets/icons/teams/manager.svg'
import deleteTeamIcon from '../../assets/icons/teams/delete_team.svg'
import { deleteTeamMember, convertTeamMember, getTeamById } from '../../data/teams/TeamsThunk'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { useParams } from 'react-router-dom'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { manageDropdownPermissions } from '@src/modules/spaces/utils/spaceActions'
import { useTranslation } from 'react-i18next'

const TeamMembersAction = ({ data }: any) => {
  const { t } = useTranslation(['teams'])
  const { team } = useAppSelector((state) => state.teams)
  const isManager = team?.managers?.some((item) => {
    return item.userId === data?.user?.id
  })
  const countOfManagerInTeam = team?.managers?.length
  const dispatch = useAppDispatch()
  const params = useParams()
  const handleDelete = () => {
    dispatch(deleteTeamMember({ usersIds: [data?.role?.id], teamId: params.id }))
      .unwrap()
      .then(() => {
        message.success(t('Member_deleted_successfully'))
        dispatch(getTeamById({ id: params.id }))
      })
      .catch((err) => message.error(err?.message || t('error_while_deleting_the_member')))
  }

  const memberActions: MenuProps['items'] = manageDropdownPermissions(
    [
      {
        key: '1',
        access: true,
        label:
          isManager && countOfManagerInTeam === 1
            ? t('Team_needs_a_manager')
            : isManager && countOfManagerInTeam > 1
              ? t('Convert to Member')
              : t('Convert_to_Manager'),
        disabled: isManager && countOfManagerInTeam === 1,
        requiredPermission: { action: 'update', resource: 'teams' },

        icon: <ManagerIcon />,
        onClick: () => {
          dispatch(
            convertTeamMember({
              id: data?.role?.id,
              isManager: !isManager ? true : false,
              teamId: params?.id,
            }),
          )
            .unwrap()
            .then(() => {
              message.success(t('Successfully_converted')), dispatch(getTeamById({ id: params.id }))
            })

            .catch((err) => message.error(err?.message || t('error_while_deleting_the_member')))
        },
      },

      {
        key: '2',
        label: t('Remove'),
        access: true,
        disabled: isManager && countOfManagerInTeam === 1,
        requiredPermission: { action: 'update', resource: 'teams' },

        icon: <RemoveIcon />,
        onClick: () => {
          dispatch(
            openModal({
              id: 'delete-team-modal',
              data: {
                icon: deleteTeamIcon,
                handleDelete: handleDelete,
                title: t('remove_member_from_team'),
                subtitle: `${t('Remove')} ${data?.user?.fullName}`,
                description: t('Remove_member_from_team_description'),
              },
            }),
          )
        },
      },
    ],
    false,
  )

  return (
    <Dropdown
      menu={{ items: memberActions }}
      trigger={['click']}
      disabled={memberActions?.length === 0}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <SettingIcon />
      </div>
    </Dropdown>
  )
}

export default TeamMembersAction
