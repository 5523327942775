import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../../components/Header'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@src/modules/shared/components/Input/Input'
import { FileUpload } from '@src/modules/shared/components/FileUpload'
import { useState } from 'react'
import Avatar from '@src/modules/shared/components/Avatar/Avatar'
import Button from '@src/modules/shared/components/Button/Button'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import { message, Radio, Switch } from 'antd'
import UpdatePassword from './UpdatePassword'
import { updateProfile } from '../../data/Profile/ProfileThunk'
import { uploadFile } from '@src/modules/shared/utils/uploadFile'
import { getChangedValues } from '@src/modules/shared/utils/getChangedValuesFormik'
import { fetchUser } from '../../../../modules/auth/context/AuthProvider'
import UserActivity from './UserActivity'
import Spinner from '@src/modules/shared/components/Spinner'
import { detectLanguage } from '@src/modules/spaces/utils/detectLanguage'
import { useTranslation } from 'react-i18next'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { toggleTheme } from '@src/modules/shared/store/slices/theme/themeSlice'
import { useExtractColor } from 'react-extract-colors'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  avatar: string
  lang: string
  theme: string
}

const Profile = () => {
  const { t } = useTranslation(['profile'])
  const { i18n } = useTranslation()

  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const { status } = useAppSelector((state) => state.profile)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedImage, setUploadedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState<any | null>()
  const [enableUpdatePassword, setEnableUpdatePassword] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng'))
  const [activeView, setActiveView] = useState<'profile' | 'activity'>('profile')
  const headerProps = { title: t('my_profile'), isProfile: true, activeView, setActiveView }
  const { width } = useWindowSize()
  useTitle(t('Settings - Profile'))
  const theme = document.querySelector('body')?.id
  const [selectedTheme, setSelectedTheme] = useState(theme)
  const { dominantColor } = useExtractColor(
    user?.user?.avatar?.substr(0, user?.user?.avatar?.length - 1) + '1',
    {
      format: 'hex',
    },
  )

  const handleFileChange = (file: any) => {
    const maxFileSize = 1 * 1024 * 1024

    if (file) {
      if (file.size > maxFileSize) {
        message.error(t('error_file_size'))
        return
      }

      setUploadedImage(file)

      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target) {
          const result = e.target.result as string
          setPreviewImage(result)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const toggleUpdatePassword = () => {
    setEnableUpdatePassword((prevState) => !prevState)
  }

  const initialValues: FormValues = {
    firstName: user?.user?.firstName || user?.firstName,
    lastName: user?.user?.lastName || user?.lastName,
    email: user?.user?.email || user?.email,
    avatar: user?.user?.avatar || user?.avatar,
    lang: user?.user?.lang || '',
    theme: user?.user?.theme || 'light',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(t('required_first_name'))
        .matches(/^(?!\s+$).+$/, t('first_name_not_only_space')),
      lastName: Yup.string()
        .required(t('last_name_required'))
        .matches(/^(?!\s+$).+$/, t('last_name_not_only_spaces')),
    }),

    onSubmit: async (values) => {
      try {
        values.lang = selectedLanguage
        values.theme = selectedTheme
        if (theme !== selectedTheme) dispatch(toggleTheme())
        delete values.email
        if (uploadedImage !== null) {
          const fileUploadResponse = await uploadFile(uploadedImage)
          values.avatar = fileUploadResponse?.location
        }
        const changedValues = getChangedValues(values, initialValues)
        const isObjectEmpty = (obj) => {
          return Object.keys(obj).length === 0 && obj.constructor === Object
        }
        if (isObjectEmpty(changedValues)) return
        const updateProfileResponse = await dispatch(updateProfile(changedValues)).unwrap()
        fetchUser()
        message.success(updateProfileResponse.message || t('update_success'))
        i18n.changeLanguage(updateProfileResponse.payload.lang)
        localStorage.setItem('i18nextLng', updateProfileResponse.payload.lang)
      } catch (error) {
        message.error(error?.message || t('update_error'))
      }
    },
  })
  const [calendarWeekStart, setCalendarWeekStart] = useState('Sunday')
  const [timeFormat, setTimeFormat] = useState('24')
  const [dateFormat, setDateFormat] = useState('mm/dd/yyyy')
  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="profile">
        {(activeView === 'profile' || width > 1024) && (
          <div className="user-profile-container">
            <div
              className="profile-setting-bar"
              style={{
                background: `linear-gradient(0deg,
                  var(--background), ${dominantColor}35 100%
                  )`,
              }}
            >
              <div className="profile-avatar-container">
                <div className="image-upload-profile">
                  <Avatar
                    text={formik?.values?.firstName || 'User'}
                    src={previewImage || user?.user?.avatar}
                    color={'#A6A6A6'}
                    size={188}
                  />
                  <FileUpload
                    handleFile={handleFileChange}
                    label={t('upload_an_image')}
                    disabled={false}
                  />
                </div>
              </div>
            </div>

            <div className="profile-settings-container">
              <form className="profile-form-container" onSubmit={formik.handleSubmit}>
                <div className="form-inputs-container">
                  <div className="profile-input-container">
                    <span>{t('first_name')}</span>

                    <Input
                      className="profile-name-input"
                      name="firstName"
                      formik={formik}
                      type={'text'}
                      variant="filled"
                      placeholder={t('enter_first_name')}
                      disabled={false}
                      style={
                        detectLanguage(initialValues.firstName) === 'arabic'
                          ? { direction: 'rtl' }
                          : { direction: 'ltr' }
                      }
                      status={formik.errors.firstName ? 'error' : 'success'}
                    />
                  </div>

                  <div className="profile-input-container">
                    <span>{t('last_name')}</span>

                    <Input
                      className="profile-name-input"
                      name="lastName"
                      formik={formik}
                      type="text"
                      variant="filled"
                      placeholder={t('enter_last_name')}
                      disabled={false}
                      style={
                        detectLanguage(initialValues.lastName) === 'arabic'
                          ? { direction: 'rtl' }
                          : { direction: 'ltr' }
                      }
                      status={formik.errors.lastName ? 'error' : 'success'}
                    />
                  </div>

                  <div className="profile-input-container">
                    <span>{t('email')}</span>

                    <Input
                      className="profile-name-input"
                      name="email"
                      formik={formik}
                      type="email"
                      variant="filled"
                      placeholder={t('enter_email')}
                      disabled={true}
                      status={formik.errors.email ? 'error' : 'success'}
                    />
                  </div>
                </div>

                <div className="password-button-container">
                  <span>{t('password')}</span>

                  {enableUpdatePassword ? (
                    <UpdatePassword setEnableUpdatePassword={setEnableUpdatePassword} />
                  ) : (
                    <form onSubmit={(e) => e.preventDefault()}>
                      <Button
                        children={t('change_password')}
                        className="password-button"
                        onClick={toggleUpdatePassword}
                      />
                    </form>
                  )}
                </div>

                <div className="option-form-container">
                  <div className="language-select-container">
                    <span className="language">{t('language')}</span>
                    <CustomSelect
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                    />
                  </div>
                </div>
                <div className="option-form-container">
                  <div className="theme-select-container">
                    <div>
                      {t('dark_mode')}
                      <Switch
                        checked={selectedTheme == 'dark'}
                        onChange={() =>
                          setSelectedTheme(selectedTheme == 'light' ? 'dark' : 'light')
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="date-option-contaienr">
                  <div className="date-option-contaienr-col2">
                    <div className="date-option-contaienr-col2-option">
                      <div className="date-option-contaienr-col2-option-title">
                        {t('start_of_the_calender_week')}
                      </div>
                      <div className="date-option-contaienr-col2-option-radio">
                        <Radio.Group
                          value={calendarWeekStart}
                          onChange={(e) => setCalendarWeekStart(e.target.value)}
                        >
                          <Radio
                            value="Sunday"
                            className={calendarWeekStart === 'Sunday' ? 'active' : ''}
                          >
                            {' '}
                            {t('sunday')}
                          </Radio>
                          <Radio value="Monday">{t('monday')}</Radio>
                        </Radio.Group>
                      </div>
                    </div>

                    <div className="date-option-contaienr-col2-option">
                      <div className="date-option-contaienr-col2-option-title">
                        {t('time_foramt')}
                      </div>
                      <div className="date-option-contaienr-col2-option-radio">
                        <Radio.Group
                          value={timeFormat}
                          onChange={(e) => setTimeFormat(e.target.value)}
                        >
                          <Radio value="24">{t('24_hour')}</Radio>
                          <Radio value="12">{t('12_hour')}</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="date-option-contaienr-col2-option">
                      <div className="date-option-contaienr-col2-option-title">
                        {t('date_format')}
                      </div>
                      <div className="date-option-contaienr-col2-option-radio">
                        <Radio.Group
                          value={dateFormat}
                          onChange={(e) => setDateFormat(e.target.value)}
                        >
                          <Radio value="mm/dd/yyyy">{t('mm/dd/yyyy')}</Radio>
                          <Radio value="dd/mm/yyyy">{t('dd/mm/yyyy')}</Radio>
                          <Radio value="yyyy/mm/dd">{t('yyyy/mm/dd')}</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="save-button-container">
                  <Button
                    children={
                      status === 'loading' ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            fontSize: '20px',
                          }}
                        >
                          <Spinner /> {t('updating')}
                        </div>
                      ) : (
                        t('save')
                      )
                    }
                    className="save-button"
                    htmlType="submit"
                    disabled={status === 'loading'}
                  />
                </div>
              </form>
            </div>
          </div>
        )}

        {(activeView === 'activity' || width > 1024) && (
          <div className="user-activity-container">
            <UserActivity />
          </div>
        )}
      </div>
    </ViewLayout>
  )
}

export default Profile
