function VideoPlaceholder() {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5 12H9.5C7.567 12 6 13.567 6 15.5V33.5C6 35.433 7.567 37 9.5 37H39.5C41.433 37 43 35.433 43 33.5V15.5C43 13.567 41.433 12 39.5 12ZM39.5 33.5H9.5V15.5H39.5V33.5Z"
        fill="#EAEFFD"
      />
      <path d="M31 24.5L22 20.05V28.95L31 24.5Z" fill="#EAEFFD" />
    </svg>
  )
}

export default VideoPlaceholder
