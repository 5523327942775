import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../../components/Header'
import { ReactComponent as SearchIcon } from './../../assets/icons/members/search.svg'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { Table, message } from 'antd'
import MemberName from '../../components/MemberName'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deleteTeamMember, getTeamById } from '../../data/teams/TeamsThunk'
import TeamMembersAction from '../../components/TeamMemebersAction/TeamMembersAction'
import UserSelectFilter from '../../components/UserSelectFilter/UserSelectFilter'
import { ReactComponent as ManagerIcon } from '../../assets/icons/teams/arcticons_manager.svg'
import { addTeamMember } from '../../data/teams/TeamsThunk'
import Button from '@src/modules/shared/components/Button/Button'
import TrashIcon from '../../assets/icons/teams/trash_red.svg'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import deleteTeamIcon from '../../assets/icons/teams/delete_team.svg'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { useTitle } from '@src/modules/shared/hooks/useTitle'
import { useTranslation } from 'react-i18next'
import MemberRole from '../../components/MemberRole/MemberRole'
import Search from '@src/modules/shared/components/Input/search'

const TeamMembers = () => {
  const { t } = useTranslation(['teams'])
  const dispatch = useAppDispatch()
  const params = useParams()
  const { team, status } = useAppSelector((state) => state.teams)
  const [selectedUser, setSelectedUser] = useState([])
  const [searchString, setSearchString] = useState<string>('')
  const headerProps = { title: t('manage_teams') }
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const adminLabels = ['admin', 'manager']

  useTitle(t('settings-teams-members'))

  const filterMembers = (members, searchString) => {
    if (!searchString) return members

    return members.filter(
      (member) =>
        member?.user?.fullName?.toLowerCase().includes(searchString?.toLowerCase()) ||
        member?.user?.email?.toLowerCase().includes(searchString?.toLowerCase()),
    )
  }

  const filteredMembers = filterMembers(
    team?.roles.map((role, index) => ({ user: role?.user, role: role, key: index })) || [],
    searchString,
  )

  const [selectedRole, setSelectedRole] = useState(
    team?.roles.map((role) => ({
      name: role?.name,
      user_id: role?.userId,
      role_id: role?.roleId,
    })) || [],
  )

  const columns = [
    {
      title: t('name'),
      key: 'name',

      render: (data: any) => (
        <div className="member-name-container">
          <MemberName data={data?.user} />
          {adminLabels.includes(data?.role?.name?.toLowerCase()) && (
            <div className="is-manager-container">
              <ManagerIcon />
              <span className="team-manager">{t('Team Manager')}</span>
            </div>
          )}
        </div>
      ),
      sorter: (a, b) => a?.user?.fullName?.localeCompare(b?.user?.fullName),
    },
    {
      title: t('Email'),
      key: 'email',

      className: 'member-email',
      render: (data: any) => (
        <div className="member-email">
          <p>{data?.user?.email}</p>
        </div>
      ),
      sorter: (a, b) => a?.user?.email?.localeCompare(b?.user?.email),
    },
    {
      title: t('role'),
      key: 'role',
      sorter: true,
      render: (data: any) => {
        return (
          <MemberRole
            role={data?.role?.name || 'member'}
            setTeamMemberRoleAction={true}
            level={4}
            teamId={team.id}
            team={team}
            member={data?.user}
            onClick={(role) =>
              setSelectedRole(() => {
                const userExists = selectedRole?.some((item) => item?.user_id === data?.user?.value)

                if (userExists) {
                  return selectedRole?.map((item) =>
                    item?.user_id === data?.user?.value
                      ? {
                          ...item,
                          name: role?.name,
                          role_id: role?.id,
                          dataId: role?.dataId,
                        }
                      : item,
                  )
                } else {
                  return [
                    ...selectedRole,
                    {
                      name: role?.name,
                      user_id: data?.user?.value,
                      role_id: role?.id,
                      dataId: role?.dataId,
                    },
                  ]
                }
              })
            }
          />
        )
      },
    },
    {
      title: t('Settings'),
      key: 'settings',
      className: 'team-member-setting',
      render: (data: any) => <TeamMembersAction data={data} />,
    },
  ]

  const handleAddMember = () => {
    if (selectedUser?.length > 0) {
      dispatch(
        addTeamMember({
          usersIds: selectedUser?.map((user) => user?.id),
          teamId: params?.id,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getTeamById({ id: params?.id }))
          setSelectedUser([])
        })
        .catch((error) => message.error(error?.message || t('error while adding members')))
    } else {
      message.error(t('You need to select at least 1 member'))
    }
  }

  const handleRowSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectChange,
  }

  const handleDelete = (teamId) => {
    const adminLabels = ['admin', 'manager']
    if (selectedRows?.some((data) => adminLabels.includes(data?.role?.name?.toLowerCase()))) {
      message.error(t('You_can_not_delete_team_manager'))
      return
    }
    dispatch(
      deleteTeamMember({ teamId: teamId, usersIds: selectedRows?.map((data) => data?.role?.id) }),
    )
      .unwrap()
      .then(() => {
        message.success(t('Member_deleted_successfully'))
        dispatch(getTeamById({ id: params.id }))
        setSelectedRowKeys([])
        setSelectedRows([])
      })
      .catch((err) => message.error(err?.message || t('error_while_deleting_the_member')))
  }

  useEffect(() => {
    dispatch(getTeamById({ id: params?.id }))
  }, [])

  const users = team?.roles?.map((user) => {
    return user.user
  })

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className="dashboard-members">
        <div className="dashboard-members-bar settings-bar">
          <div className="setting-button">
            <Search
              onSearch={(s) => setSearchString(s)}
              prefix={<SearchIcon />}
              containerClassName="input-search"
              placeholder={t('Search by name or email')}
              variant="label-inline"
              size="sm"
            />
          </div>

          <div className="add-member-container">
            {selectedRows.length === 0 ? (
              <>
                <UserSelectFilter
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  users={users}
                />
                <Can I="update" a="teams">
                  <div className="setting-button-b1" onClick={handleAddMember}>
                    {t('Add')}
                  </div>
                </Can>
              </>
            ) : (
              <Can I="update" a="teams">
                <Button
                  children={t('Remove selected')}
                  className="remove-selected-members"
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: 'delete-team-modal',
                        data: {
                          icon: deleteTeamIcon,
                          handleDelete: () => {
                            handleDelete(params?.id)
                          },
                          title: t('remove_member_from_team'),
                          subtitle: `${
                            selectedRows.length > 1
                              ? t('Remove_Multiple_members')
                              : `${t('Remove')} ${selectedRows?.[0]?.user?.fullName}`
                          }`,
                          description: t('Remove_member_from_team_description'),
                        },
                      }),
                    )
                    setSelectedRows([])
                  }}
                  icon={TrashIcon}
                />
              </Can>
            )}
          </div>
        </div>

        <div className="dashboard-members-container">
          <Table
            dataSource={filteredMembers}
            columns={columns}
            className="members-team-table"
            showSorterTooltip
            pagination={false}
            loading={status === 'loading'}
            rowSelection={rowSelection}
            locale={{ emptyText: t('no_data') }}
          />
        </div>
      </div>
    </ViewLayout>
  )
}

export default TeamMembers
