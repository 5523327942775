import { importClickUpData } from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import Btn from '@src/modules/shared/components/Btn/Btn'
import { useAppDispatch } from '@src/modules/shared/store'
import { Modal, message } from 'antd'
import { useState } from 'react'
import clickUpIcon from '../../../../assets/icons/importExport/ClickUpActive.svg'
import close from '../../../../assets/icons/importExport/close-circle.svg'
import SelectSpacesTree from './SelectSpacesTree'
import { useTranslation } from 'react-i18next'

const ImportSelectedSpaces = ({ id, open, handleClose }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals'])
  const token = localStorage.getItem('clickup_access_token')
  const [selectedItems, setSelectedItems] = useState([])

  return (
    <Modal
      open={open}
      footer={false}
      closeIcon={false}
      className="import-selected-spaces"
      onCancel={() => {
        handleClose(id)
        localStorage.removeItem('clickup_access_token')
      }}
    >
      <div className="header">
        <h2 className="title">{t('import.import_data')}</h2>
        <img src={close} className="icon-close" onClick={() => handleClose(id)} />
      </div>

      <div className="info">
        <img className="icon" src={clickUpIcon} />

        <h3>{t('import.You are importing your data from ClickUp')}</h3>
      </div>

      <div className="content">
        <h3 className="content_title">{t('import.Import selected Spaces')}</h3>

        <div className="content-tree">
          <SelectSpacesTree spaces={selectedItems} setSelectedSpaces={setSelectedItems} />
        </div>
      </div>

      <div className="btns">
        <Btn
          title={t('import.cancel')}
          className="import-selected-space-btn cancel"
          onClick={() => handleClose(id)}
        />

        <Btn
          title={t('import.Start Import')}
          className="import-selected-space-btn confirm"
          onClick={() => {
            dispatch(importClickUpData({ token, selectedItems }))
              .unwrap()
              .then((res) => {
                message.success(res.message || res)
              })
              .catch((error) => message.error(error.message))
              .finally(() => {
                localStorage.removeItem('clickup_access_token')
                handleClose(id)
              })
          }}
        />
      </div>
    </Modal>
  )
}

export default ImportSelectedSpaces
