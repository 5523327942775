/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit'

export interface TasksState {
  error: any
  status: string
  commentToEdit: IComment | null
}

const initialState: TasksState = {
  error: null,
  status: 'idle',
  commentToEdit: null,
}

const spacesSlice = createSlice({
  name: 'comments',
  initialState,

  reducers: {
    setCommentToEdit: (state, { payload }) => {
      state.commentToEdit = payload
    },
    resetCommentToEdit: (state) => {
      state.commentToEdit = null
    },
  },
})

export const { setCommentToEdit, resetCommentToEdit } = spacesSlice.actions
export default spacesSlice.reducer
