import { api } from '@src/modules/shared/services/api'
import { ListType } from '../components/List/List'
import { toList } from '../utils/toList'
import { statusType } from '../data/statusSlice/statusSlice'

interface getListPayload {
  id: string
}

interface getStatusesByListPayload {
  listId: string
}

export const listApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLists: build.query<ListType, any>({
      query: (query: any) => ({
        url: `api/lists`,
        params: query,
      }),
      transformResponse: (response: { payload: ListType }): ListType => {
        return toList(response.payload)
      },
    }),

    getStatusesByList: build.query<statusType[], getStatusesByListPayload>({
      query: ({ listId }) => ({
        url: `api/lists/${listId}/statuses`,
      }),
      transformResponse: (response: { payload: { payload: statusType[] } }): statusType[] => {
        return response.payload?.payload
      },
    }),

    getList: build.query<ListType, getListPayload>({
      query: (query: any) => ({
        url: `api/lists/${query.id}`,
      }),
      transformResponse: (response: { payload: ListType }): ListType => {
        return toList(response.payload)
      },
    }),
    getStataus: build.query<any, any>({
      query: (query: { hierarchyType: string; entityUuid: string }) => ({
        url: `api/status`,
        params: query,
      }),
      transformResponse: (response: { payload: { payload: statusType[] } }): statusType[] => {
        return response.payload?.payload
      },
    }),

    updateHierarchy: build.mutation({
      query: ({ id, hierarchyType, ...body }) => ({
        url: `api/hierarchy/${id}?hierarchyType=${hierarchyType}`,
        method: 'PATCH',
        body,
      }),
    }),

    getConflictedStatuses: build.query({
      query: (query: { hierarchyType: string; entityUuid: string }) => ({
        url: `api/conflicted-status`,
        params: query,
      }),

      transformResponse: (response: { payload: statusType[] }): statusType[] => {
        return response?.payload
      },
    }),

    getHierarchyType: build.query({
      query: ({ id, hierarchyType }) => ({
        url: `api/hierarchy/${id}`,
        method: 'GET',
        params: { hierarchyType },
      }),
    }),

    resolveConflictedStatuses: build.mutation({
      query: (body) => ({
        url: `api/resolve-conflicted-status`,
        method: 'POST',
        body,
      }),
    }),

    updateManyStatuses: build.mutation({
      query: (body) => ({
        url: `api/manyStatus`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useGetListQuery,
  useGetListsQuery,
  useGetStatusesByListQuery,
  useGetStatausQuery,
  useGetHierarchyTypeQuery,
  useGetConflictedStatusesQuery,
  useResolveConflictedStatusesMutation,
  useUpdateHierarchyMutation,
  useUpdateManyStatusesMutation,
} = listApi
