import { Editor, ICurrentQuestion } from '../memberView.interface'

interface Props {
  currentQuestion: ICurrentQuestion
  editors: { [key: string]: Editor[] }
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>
  currentResponse: Date
}
const addNewEditor = ({ currentQuestion, editors, setEditors, currentResponse }: Props) => {
  const questionId = currentQuestion?.id
  if (!questionId) return

  const newId =
    editors[questionId]?.length > 0 ? editors[questionId][editors[questionId].length - 1].id + 1 : 1

  const updatedEditors = {
    ...editors,
    [questionId]: [
      ...(editors[questionId] || []),
      {
        id: newId,
        content: '',
        editable: true,
        time: '00:00',
        question: questionId,
        date: currentResponse,
        files: [],
      },
    ],
  }

  setEditors(updatedEditors)
}

export default addNewEditor
