import { Dropdown } from 'antd'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons'
import { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from '@src/modules/tasks/assets/icons/filter/search-icon.svg'
import Search from '@src/modules/shared/components/Input/search'

const FieldTypeFilter = ({ onData, disabel }) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const handleDropdownVisibleChange = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const customFieldsearch = CustomFieldsIcons.filter((fields) => {
    return fields.label.toLowerCase().includes(search.toLowerCase())
  })

  const [createBy, setCreateBy] = useState('')
  useEffect(() => onData(createBy), [createBy])
  return (
    <div className="create-by-filter">
      <Dropdown
        open={dropdownVisible}
        onOpenChange={handleDropdownVisibleChange}
        placement={'bottom'}
        disabled={disabel}
        className="create-by-filter-dropdown"
        dropdownRender={() => (
          <div className="create-by-filter-dropdown-items-container">
            <div className="create-by-filter-dropdown-items-container-search">
              <Search
                prefix={<SearchIcon />}
                onSearch={setSearch}
                value={search}
                variant="underlined"
              />
            </div>

            <div className="create-by-filter-dropdown-items">
              {customFieldsearch?.map((item, i) => {
                return (
                  <span key={i} onClick={() => setCreateBy(item?.field)}>
                    {item?.label}
                  </span>
                )
              })}
            </div>
          </div>
        )}
        trigger={['click']}
      >
        <div className="create-by-filter-item">{createBy ? createBy : 'Select field type ...'}</div>
      </Dropdown>
    </div>
  )
}

export default FieldTypeFilter
