import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close_tag.svg'
import { useAppSelector } from '@src/modules/shared/store'
import Input from '@src/modules/shared/components/Input/Input'
import { useTranslation } from 'react-i18next'
interface EmailInputProps {
  disabled: boolean
  emails: string[]
  setEmails: any
}

const EmailInput: React.FC<EmailInputProps> = ({ emails, setEmails, disabled }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { t } = useTranslation(['workspaces'])
  const { user } = useAppSelector((state) => state.auth)
  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }
  const currentUserEmail = user?.email || user?.user?.email
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && inputValue.trim() !== '') {
      if (isValidEmail(inputValue.trim())) {
        if (!emails.includes(inputValue.trim())) {
          if (inputValue.trim() !== currentUserEmail) {
            setEmails((prev: any) => [...prev, inputValue.trim()])
            setError('')
            setInputValue('')
          } else {
            setError('This email is the same as your current email')
          }
        } else {
          setError('This email is already added')
        }
      } else {
        setError('Please enter a valid email')
      }
    }
  }

  const removeEmail = (email: string) => {
    setEmails((prev: any[]) => prev.filter((e: string) => e !== email))
  }

  return (
    <div className="email-input-container">
      <Input
        size="lg"
        variant="label-inline"
        type="email"
        disabled={disabled}
        value={inputValue}
        placeholder={t('Enter_email_address')}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        containerClassName="email-input"
      />
      {error !== '' && <p className="email-error">{error}</p>}
      <div className="email-tag-container">
        {emails.map((email, index) => (
          <span key={index} className="email-tag">
            {email}
            <span className="remove-email" onClick={() => removeEmail(email)}>
              <CloseIcon />
            </span>
          </span>
        ))}
      </div>
    </div>
  )
}

export default EmailInput
