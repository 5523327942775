// import SelectSpacesTree from '@src/modules/settings/features/ImportExport/components/ImportSelectedSpaces/SelectSpacesTree'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import { Dropdown } from 'antd'
import { useEffect, useState } from 'react'

const LocationFilter = ({ onData, disabel }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFolders, setSelectedFolders] = useState([])
  const [selectedSpaces, setSelectedSpaces] = useState([])
  useEffect(() => {
    onData({ files: selectedFiles, folders: selectedFolders, spaces: selectedSpaces })
  }, [selectedFiles, selectedFolders, selectedSpaces])
  const handleSelectList = (items) => {
    const selectedItem = items[items.length - 1]
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.includes(selectedItem)
        ? prevSelectedFiles.filter((item) => item !== selectedItem)
        : [...prevSelectedFiles, selectedItem],
    )
  }

  const handleSelectFolder = (items) => {
    const selectedItem = items[items.length - 1]
    setSelectedFolders((prevSelectedFolders) =>
      prevSelectedFolders.includes(selectedItem)
        ? prevSelectedFolders.filter((item) => item !== selectedItem)
        : [...prevSelectedFolders, selectedItem],
    )
  }

  const handleSelectSpace = (items) => {
    const selectedItem = items[items.length - 1]
    setSelectedSpaces((prevSelectedSpaces) =>
      prevSelectedSpaces.includes(selectedItem)
        ? prevSelectedSpaces.filter((item) => item !== selectedItem)
        : [...prevSelectedSpaces, selectedItem],
    )
  }
  return (
    <Dropdown
      disabled={disabel}
      trigger={['click']}
      className="location-filter"
      dropdownRender={() => (
        <div className="location-container">
          <div className="location-dropDown">
            <SpacesTree
              onSelectList={handleSelectList}
              onSelectFolder={handleSelectFolder}
              onSelectSpace={handleSelectSpace}
              multiple
              readOnly
            />
          </div>
        </div>
      )}
    >
      <span style={{ width: '100%' }}>
        {selectedFiles.length + selectedFolders.length + selectedSpaces.length} Location
      </span>
    </Dropdown>
  )
}

export default LocationFilter
