import activeToggle from '../../assets/icons/active-toggle.svg'
import toggle from '../../assets/icons/toggle.svg'
import halfToggle from '../../assets/icons/half-toggle.svg'

type props = {
  select: () => any
  isSelected: boolean
  isMargin?: boolean
  halfSelect?: boolean
}

const Toggle = ({ select, isSelected, isMargin, halfSelect }: props) => {
  return (
    <img
      src={isSelected ? activeToggle : halfSelect ? halfToggle : toggle}
      onClick={select}
      style={isMargin ? { marginRight: '3px' } : {}}
    />
  )
}

export default Toggle
