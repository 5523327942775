import { Skeleton } from 'antd'

const UserActivitySkeleton = () => (
  <div className="user-activity-skeleton">
    <div className="user-activity-skeleton-item">
      <Skeleton paragraph={false} active className="user-activity-skeleton-item-icon" />

      <div className="user-activity-skeleton-item-details">
        <Skeleton paragraph={false} active className="user-activity-skeleton-item-title" />
        <Skeleton paragraph={false} active className="user-activity-skeleton-item-description" />
        <Skeleton paragraph={false} active className="user-activity-skeleton-item-date" />
      </div>
    </div>
  </div>
)

export default UserActivitySkeleton
