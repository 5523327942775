/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../shared/utils/axios'
import { LoginPayload, RegisterPayload, WorkspacePayload, VerificationPayload } from './authTypes'
import { serialize } from 'object-to-formdata'
import socketManager from '@src/modules/shared/socket/socketManager'

export const login = createAsyncThunk('auth/login', async (query: LoginPayload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/auth/login`, query)

    if (response.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const register = createAsyncThunk(
  'auth/register',
  async (query: RegisterPayload, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/auth/register`, { ...query })

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const logout = createAsyncThunk('auth/logout', async () => {
  try {
    const response = await axiosInstance.get(`/auth/logout`)

    if (response.status === 200) {
      socketManager.disconnect()
      return response.data
    }
    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const createWorkspace = createAsyncThunk(
  'workspace/createWorkspace',
  async (query: WorkspacePayload, { rejectWithValue }) => {
    const formData = serialize(query)

    try {
      const response = await axiosInstance.post(`/api/workspaces`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const selectWorkspace = createAsyncThunk('workspace/selectWorkspace', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/workspaces/tntk`, {
      headers: {
        'x-workspace': query,
      },
    })

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const inviteToWorkspace = createAsyncThunk(
  'workspace/inviteToWorkspace',
  async (query: any) => {
    try {
      const response = await axiosInstance.post(`/api/workspace/invitation`, query, {
        headers: {
          'x-workspace': query?.workspace,
        },
      })

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (query: VerificationPayload, thunkAPI) => {
    const { token } = query
    try {
      const response = await axiosInstance.post(
        '/auth/verify-account/email',
        { token },
        {
          headers: {
            Authorization: `Bearer ${query.access_token}`,
          },
        },
      )
      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const requestEmailVerification = createAsyncThunk(
  'auth/requestEmailVerification',
  async () => {
    try {
      const response = await axiosInstance.post('/auth/verify-account/request/email')
      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const forgetPassword = createAsyncThunk('auth/forgetPassword', async (query: any) => {
  try {
    const response = await axiosInstance.post('/auth/forgot-password', query)
    if (response.status === 201) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const resetPassowrd = createAsyncThunk(
  'auth/resetPassowrd',
  async (query: any, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/auth/reset-password', query)
      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      // return Promise.reject(err.message)
      return thunkAPI.rejectWithValue(err)
    }
  },
)

export const googleOauth = createAsyncThunk('auth/googleOauth', async (query: string) => {
  try {
    const response = await axiosInstance.get('/api/google/oauth', { params: { code: query } })
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const microsoftOauth: any = createAsyncThunk(
  'auth/microsoftOauth',
  async (query: string) => {
    try {
      const response = await axiosInstance.get('/api/auth/azure/callback', {
        params: { code: query },
      })
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const integrationOauth: any = createAsyncThunk(
  'auth/integrationOauth',
  async (query: string) => {
    try {
      const response = await axiosInstance.get('/api/auth/azure/callback/bot', {
        params: { code: query },
      })
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const invitationResponse = createAsyncThunk(
  'auth/invitationResponse',
  async (query: any) => {
    try {
      const response = await axiosInstance.post('/api/workspace/invitation/respond', query)
      if (response.status === 201) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const userExist = createAsyncThunk('auth/userExist', async (query: any) => {
  try {
    const response = await axiosInstance.get(`/api/central_users/has-account/${query?.email}`)
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})
