import { useFlags } from 'flagsmith/react'
import { Fragment } from 'react'
import Logo from '../../assets/images/sidebar/logoIcon.svg'
import { FLAGS } from '../../constants'

interface MainLayoutProps {
  children: React.ReactNode
}

const GuestLayout = ({ children }: MainLayoutProps) => {
  const flags = useFlags([FLAGS.MAINTENANCE])

  if (flags.maintenance.enabled) {
    const containerStyle = {
      maxWidth: '600px',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    }

    const textStyle = {
      color: '#333',
    }

    const imageStyle = {
      maxWidth: '100px',
      height: '50%',
      width: '50%',
    }

    return (
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          textAlign: 'center',
          backgroundColor: '#f4f4f4',
          margin: 0,
          padding: 0,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={containerStyle}>
          <img src={Logo} alt="Under Maintenance" style={imageStyle} />
          <h1 style={textStyle}>Website Under Maintenance</h1>
          <p style={{ color: '#666' }}>
            We apologize for the inconvenience. Our website is currently undergoing maintenance to
            serve you better.
          </p>
          <p style={{ color: '#666' }}>Please check back soon.</p>
        </div>
      </div>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default GuestLayout
