import TasksFilter from '@src/modules/tasks/components/TasksFilter'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useGetMyTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { useCallback, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import TaskItem from './TaskItem'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'
import { ReactComponent as NoTasksSVG } from '../../assets/icons/dashboard/no-tasks.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/tasks/search.svg'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { concatStatusFilter } from '@src/modules/tasks/components/Board/StatusCard/StatusCard'
import { resetFilter } from '@src/modules/shared/store/slices/filter/filterSlice'
import { useLocation } from 'react-router-dom'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import Search from '@src/modules/shared/components/Input/search'
import { debounce } from 'lodash'
import UserTasksSkeleton from '../UserTasksSkeleton/UserTasksSkeleton'

interface UserTasksProps {
  id?: string
}

const MyTasks = ({ id }: UserTasksProps) => {
  const { t } = useTranslation(['dashboard'])
  const { pathname } = useLocation()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { tasks: filter } = useAppSelector((state) => state.filter)
  const disptach = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth.user)

  const handleSearch = useCallback(
    debounce((s) => {
      setLoading(true)
      setCurrentPage(1)
      setSearch(s) // This updates the search state after debounce
    }, 300), // 300ms delay
    [],
  )

  const { data, isLoading, isError, isSuccess, isFetching } = useGetMyTasksQuery({
    limit: 20,
    skip: currentPage,
    userId: id || user?.id,
    options: concatStatusFilter(
      {
        ...(search ? { name: { operation: '$contains', value: search } } : {}),
      },
      filter,
      null,
      null,
      GroupByFields.STATUS,
    ),
  })

  useEffect(() => {
    disptach(resetFilter({ model: 'tasks' }))
  }, [pathname])

  useEffect(() => {
    if (!isFetching) setLoading(false)
  }, [isFetching, isSuccess])

  const infiniteScrollHandler = (e) => {
    handleScroll(e, metadata?.total_items, currentPage, 20, setCurrentPage)
  }

  const userTasks = data?.result?.payload
  const metadata = data?.result?.metadata

  return (
    <div className="dashboad-my-tasks">
      {isError ? (
        <div>{t('Error')}</div>
      ) : (
        <>
          <div className="dashboad-my-tasks-header dashboard-tasks-search">
            <div className="dashboard-tasks-ts">
              {t('total_tasks')} : <span>{metadata?.total_items}</span>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <TasksFilter />
              <Search
                size="xs"
                onSearch={handleSearch}
                prefix={<SearchIcon />}
                placeholder={t('search_tasks')}
                containerClassName="dashboard-tasks-search-input"
                variant="filled"
              />
            </div>
          </div>
          <div className="dashboad-my-tasks-list" onScroll={infiniteScrollHandler}>
            {isLoading || loading ? (
              <UserTasksSkeleton />
            ) : metadata?.total_items === 0 ? (
              search.length > 0 ? (
                <div className="dashboad-my-tasks-empty">
                  <NoTasksSVG />
                  <p>{t('myTasks.No tasks')}</p>
                </div>
              ) : (
                <div className="dashboad-my-tasks-empty">
                  <NoTasksSVG />
                  <p>{t('myTasks.No tasks are currently assigned to you ..')}</p>
                </div>
              )
            ) : isFetching && currentPage === 1 ? (
              <UserTasksSkeleton />
            ) : (
              userTasks?.map((task: TaskType) => <TaskItem task={task} key={task.id} />)
            )}
            {metadata?.next && !loading && (
              <>
                <Skeleton active paragraph={false} />
                <Skeleton active paragraph={false} />
                <Skeleton active paragraph={false} />
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default MyTasks
