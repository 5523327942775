import { ReactComponent as AddSpaceIcon } from './../../assets/icons/sidebar/add-space.svg'
import { ReactComponent as CollapseLinkIcon } from './../../assets/icons/sidebar/collapse-icon.svg'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { useState } from 'react'
import Spaces from '../Spaces/Spaces'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { Avatar } from 'antd'
import {
  closeSidebar,
  toggleSidebar,
} from '@src/modules/shared/store/slices/settings/settingsSlice'
import { getActiveSpace, setActiveSpace } from '@src/modules/shared/utils/activeSpace'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'
import { sidebarCloseAt } from '@src/modules/shared/layout/MainLayout/MainLayout'

export enum sidebarItemIDs {
  FAVORITIES = 'favorities',
  SHARE = 'share',
  PROJECTS = 'projects',
  ARCHIVE = 'archive',
}

interface sibebarItemType {
  id: sidebarItemIDs
  label: string
  isOpen: boolean
  disabled?: boolean
}

const toggleSibebarItem = (itemID: string, fn: any): void => {
  fn((items: sibebarItemType[]) => {
    const newItems = items.map((item: sibebarItemType) =>
      item.id === itemID && !item.disabled ? { ...item, isOpen: !item.isOpen } : item,
    )
    return newItems
  })
}

function SidebarSpaces() {
  const { t } = useTranslation(['sidebar'])
  const { width } = useWindowSize()

  const items: sibebarItemType[] = [
    // {
    //   id: sidebarItemIDs.FAVORITIES,
    //   label: 'Favorities',
    //   isOpen: false,
    //   disabled: true,
    // },
    {
      id: sidebarItemIDs.SHARE,
      label: t('Shared with me'),
      isOpen: false,
    },
    {
      id: sidebarItemIDs.PROJECTS,
      label: t('All spaces'),
      isOpen: true,
    },
    // {
    //   id: sidebarItemIDs.ARCHIVE,
    //   label: 'Archives',
    //   isOpen: false,
    //   disabled: true,
    // },
  ]

  const { spaces } = useAppSelector((state) => state.spaces)
  const [sidebItems, setSidebarItems] = useState<sibebarItemType[]>(items)
  const dispatch = useAppDispatch()
  const activeSpace = getActiveSpace()
  const navigate = useNavigate()
  const lang = localStorage.getItem('i18nextLng')

  return (
    <div className="spaces-sidebar hovered-scrollbar">
      <div className="spaces-sidebar-links">
        {sidebItems?.map((item, i) => (
          <div className="spaces-sidebar-links-item-container" key={i}>
            <div
              className={`spaces-sidebar-links-item ${item.isOpen ? 'open-item' : ''} `}
              onClick={() => toggleSibebarItem(item.id, setSidebarItems)}
            >
              <div
                className={`${item.disabled ? 'disabled-collapse-icon' : 'collapse-icon'} ${lang === 'ar' && 'ar-collapse-icon'}`}
              >
                <CollapseLinkIcon />
              </div>

              <div className={`item-label ${item.disabled ? 'disabled-item' : ''}`}>
                {item.label}
              </div>
            </div>

            {item.isOpen ? <Spaces id={item.id} /> : null}
          </div>
        ))}
      </div>

      {spaces?.map((space, i) => (
        <div
          className={`spaces-list-item  ${activeSpace === space?.id ? 'active-space' : ''}`}
          key={i}
          onClick={() => {
            setActiveSpace(space?.id)
            dispatch(toggleSidebar('layout-sidebar'))
            navigate('/dashboard')
          }}
        >
          <Avatar
            shape={'square'}
            src={space?.icon}
            style={{ background: !space?.icon && space?.color }}
            size={'small'}
          >
            {space?.name && space.name[0]?.toUpperCase()}
          </Avatar>
        </div>
      ))}

      <div className="spaces-sidebar-footer">
        <Can I="create" a="spaces">
          <div
            className="add-space-btn"
            onClick={() => {
              dispatch(openModal({ id: 'space-modal' }))
              width < sidebarCloseAt && dispatch(closeSidebar())
            }}
          >
            <AddSpaceIcon />
            <p>{t('new_space')}</p>
          </div>
        </Can>
      </div>
    </div>
  )
}

export default SidebarSpaces
