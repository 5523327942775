interface SkeletonLoaderProps {
  skeleton: any
  children: any
  isLoading: boolean
}

const SkeletonLoader = ({ children, skeleton, isLoading }: SkeletonLoaderProps) => {
  return <>{isLoading ? skeleton : children}</>
}

export default SkeletonLoader
