import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: ModalsState = {
  'space-modal': { open: false },
  'list-modal': { open: false },
  'folder-modal': { open: false },
  'confirm-modal': { open: false },
  'invite-modal': { open: false },
  'delete-workspace-modal': { open: false },
  'transfer-ownership-modal': { open: false },
  'delete-member-modal': { open: false },
  'invited-users-modal': { open: false },
  'create-task-modal': { open: false },
  'share-modal': { open: false },
  'update-status-modal': { open: false },
  'media-player': { open: false },
  'teams-modal': { open: false },
  'delete-team-modal': { open: false },
  'delete-comment-modal': { open: false },
  'edit-comment-modal': { open: false },
  'edit-timer-modal': { open: false },
  'user-answer-modal': { open: false },
  'preview-blocks-modal': { open: false },
  'quick-answer-modal': { open: false },
  'delete-permanently-modal': { open: false },
  'clear-tash-modal': { open: false },
  'create-new-document-modal': { open: false },
  'import-selected-spaces-modal': { open: false },
  'import-task-modal': { open: false },
  'custom-fields-modal': { open: false },
  'user-profile-drawer': { open: false },
  'conflict-management-drawer': { open: false },
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<openModalPayload>) => {
      const { id, data } = action.payload
      state[id].open = true
      state[id].data = data
    },
    closeModal: (state, action: PayloadAction<closeModalPayload>) => {
      const { id } = action.payload
      state[id].open = false
    },
  },
})

export const { openModal, closeModal } = modalsSlice.actions

export default modalsSlice.reducer
