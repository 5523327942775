import { Avatar } from '@src/modules/shared/components/Avatar'
import Button from '@src/modules/shared/components/Button/Button'
import { SpaceStepProps } from '../Modals/SpaceModal/SpaceModal'
import { fileToObjectURL } from '@src/modules/shared/utils/fileToObjectURL'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { createSpace } from '../../data/spaceSlice/spacesThunk'
import { Tooltip, message } from 'antd'
import { statusType, templateStatusType, templateType } from '../../data/statusSlice/statusSlice'
import { uploadFile } from '@src/modules/shared/utils/uploadFile'
import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Avatar as AntdAvatart } from 'antd'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import { setSpacesSocketEvent } from '../../data/spaceSlice/spacesSlice'
import TaskUsers from '@src/modules/tasks/components/TaskUsers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransferOwnerShipMutation } from '@src/modules/settings/services/membersApi'
import back from '../../assets/icons/space/back.svg'
import { IUser } from '@src/modules/auth/data/authTypes'

const formatStatus = (categoryID: string, status: statusType) => {
  return {
    name: status?.name,
    color: status?.color,
    categoryId: categoryID,
  }
}

const getStatusArray = (groupedStatus: templateStatusType) => {
  return (
    groupedStatus &&
    Object.keys(groupedStatus)
      .flatMap((key) => {
        return groupedStatus[key]?.map((status) => formatStatus(status.categoryId, status))
      })
      .map((status, i) => {
        return { ...status, order: i }
      })
  )
}

const getSpaceInfo = (data: any, template: templateType) => {
  return {
    name: data?.name,
    icon: data?.avatar,
    color: data?.selectedColor,
    status: getStatusArray(template?.status as templateStatusType),
    isPrivate: data?.sharing?.id !== 'public',
    permissions:
      data.shareWith?.map((member: memberType) => ({
        userId: member?.id,
      })) || [],
  }
}

const SpaceReview = ({ data, setPreviousStep }: SpaceStepProps) => {
  const { avatar, selectedColor, name, sharing, isEdit, color, icon } = data || {}
  const [newOwner, setNewOwner] = useState<IUser | null>(null)
  const { template } = useAppSelector((state) => state.status)
  const { status } = useAppSelector((state) => state.spaces)
  const dispatch = useAppDispatch()
  const [transferOwnerShip, { isLoading, isError }] = useTransferOwnerShipMutation()
  const statusesArray = getStatusArray(template?.status as templateStatusType)
  const owner = newOwner || data?.owner
  const { t } = useTranslation(['modals'])
  const { t: taskTranslation } = useTranslation(['tasks'])

  const createSpaceHandler = async () => {
    if (template && status !== 'loading' && !isEdit) {
      const translatedData = {
        ...template,
        status: Object.fromEntries(
          Object.entries(template.status).map(([key, valueArray]) => [
            key,
            (valueArray as statusType[]).map((item) => ({
              ...item,
              name: taskTranslation(item.name),
            })),
          ]),
        ),
      }
      const createFn = (path?: string) =>
        dispatch(createSpace(getSpaceInfo({ ...data, avatar: path }, translatedData)))
          .unwrap()
          .then((res) => {
            dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }))
            dispatch(closeModal({ id: 'space-modal' }))
            message.success(t('space.Space created with success'))
          })
          .catch((error) => {
            message.error(error.message || t('space.Error'))
          })

      if (data.avatar) {
        const { location } = (await uploadFile(data.avatar)) || {}
        createFn(location)
      } else createFn()
    }

    if (isEdit) {
      if (newOwner === null || newOwner?.id === data?.owner?.id) {
        dispatch(closeModal({ id: 'space-modal' }))
        return null
      }

      transferOwnerShip({ id: data.id, newOwnerId: newOwner?.id || data?.owner?.id })
        .unwrap()
        .then(() => dispatch(closeModal({ id: 'space-modal' })))
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      createSpaceHandler()
    }
  }

  const trasnferSpaceOnwer = (newMembers) => {
    setNewOwner(newMembers[0])
  }

  useEffect(() => {
    if (isError) message.error('Error while transfer owner ship')
  }, [isError])
  const nameTranslation = sharing?.name === 'Private' ? t('space.private') : t('space.public')
  return (
    <div className="ta-form space-form" onKeyDown={handleKeyPress} tabIndex={0}>
      <img src={back} onClick={setPreviousStep} className="back-icon" />

      <div className="key-value">
        <div className="key">{t('space.Space Name')}</div>
        <div className="value">{name}</div>
      </div>

      <div className="key-value">
        <div className="key">{t('space.Style')}</div>
        <div className="value">
          <Avatar
            text={name}
            src={avatar ? fileToObjectURL(avatar) : icon}
            color={selectedColor || color || '#f4f6f8'}
          />
        </div>
      </div>

      {isEdit && (
        <div className="key-value">
          <div className="key">{t('space.Owner')}</div>
          <TaskUsers
            userNumber={0}
            displayAddButton={false}
            members={[]}
            multiple={false}
            setAssignedMembersList={trasnferSpaceOnwer}
          >
            <div className="value">
              <Tooltip title={owner?.fullName || owner?.email}>
                <span>{owner?.fullName || owner?.email}</span>
              </Tooltip>
              <Avatar
                text={owner?.fullName || owner?.email}
                src={owner?.avatar}
                color={null}
                size={34}
              />
            </div>
          </TaskUsers>
        </div>
      )}

      <div className="key-value">
        <div className="key">{t('space.Sharing')}</div>
        <div className="value value-owner">
          <p>{nameTranslation}</p>
          {sharing?.icon}
        </div>
      </div>

      {!isEdit && (
        <div className="key-value">
          <div className="key">{t('space.Statuses')}</div>
          <div className="value">
            {statusesArray?.map((status, i) => (
              <Tooltip key={i} title={status.name}>
                <AntdAvatart
                  className="status-avatar-space"
                  style={{ background: status.color }}
                  size={20}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      )}

      <div className="form-actions">
        {isEdit ? (
          <Button
            htmlType="submit"
            className="confirm-action"
            onClick={createSpaceHandler}
            disabled={status === 'loading'}
            loading={status === 'loading'}
          >
            {status === 'loading' || isLoading ? t('space.Loading...') : t('space.Save changes')}
          </Button>
        ) : (
          <Button
            htmlType="submit"
            className="confirm-action"
            onClick={createSpaceHandler}
            disabled={status === 'loading'}
            loading={status === 'loading'}
          >
            {status === 'loading' ? t('space.Loading...') : t('space.Create')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default SpaceReview
