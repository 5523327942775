import {
  useDeleteQuickAnswerMutation,
  useGetQuickAnswersQuery,
} from '@src/modules/dailyQuestions/services/quickAnswerApi'
import { useEffect, useRef, useState } from 'react'

import { ReactComponent as AddQA } from '../../assets/plus.svg'
import { ReactComponent as Delete } from '../../assets/delete.svg'
import { Editor } from '../../memberView.interface'
import { ReactComponent as Put } from '../../assets/put.svg'
import { QuickAnswer } from './quickAnswer.interfac'
import { ReactComponent as Update } from '../../assets/update.svg'
import { message } from 'antd'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { useAppDispatch } from '@src/modules/shared/store'

interface QuickAnswersProps {
  setValue: (content: string) => void
  updateEditorContent: (key: string, editorId: number, newContent: string) => void
  questionId: string
  editor: Editor
}

export default function QuickAnswers({
  setValue,
  updateEditorContent,
  questionId,
  editor,
}: QuickAnswersProps) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data, isSuccess, refetch } = useGetQuickAnswersQuery({
    limit: 10,
    page: 1,
  })
  const [selectedQuick, setSelectedQuick] = useState<string>('')
  const [deleteQuickAnswer] = useDeleteQuickAnswerMutation()
  useEffect(() => {
    if (isSuccess) setSelectedQuick(data?.payload?.data?.[0]?.id)
  }, [isSuccess])
  const handleDeleteQuickAnswer = (id: string) => {
    try {
      setIsLoading(true)
      deleteQuickAnswer({ id })
        .unwrap()
        .then(() => {
          message.success('Quick Answer Delete With Success !')
          refetch().then(() => {
            if (data?.payload?.data?.length > 0) setSelectedQuick(data?.payload?.data?.[0]?.id)
          })
        })
    } catch (error) {
      message.error(error?.message)
    } finally {
      setIsLoading(false)
    }
  }
  const elementsRef = useRef(null)

  const handleMouseDown = (e) => {
    const ele = elementsRef.current
    if (!ele) return

    ele.isDown = true
    ele.startX = e.pageX - ele.offsetLeft
    ele.scrollLeft = ele.scrollLeft
  }

  const handleMouseLeave = () => {
    const ele = elementsRef.current
    if (ele) ele.isDown = false
  }

  const handleMouseUp = () => {
    const ele = elementsRef.current
    if (ele) ele.isDown = false
  }

  const handleMouseMove = (e) => {
    const ele = elementsRef.current
    if (!ele || !ele.isDown) return
    e.preventDefault()
    const x = e.pageX - ele.offsetLeft
    const walk = (x - ele.startX) * 0.1
    ele.scrollLeft = ele.scrollLeft - walk
  }

  return (
    <div className="quick-answers">
      <div
        className="quick-answers_elements"
        ref={elementsRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {data?.payload?.data?.map((el: QuickAnswer) => {
          return (
            <div
              className={`${
                selectedQuick === el?.id ? 'quick-answers_element-selected' : ''
              } quick-answers_element`}
              onClick={() => {
                setSelectedQuick(el?.id)
              }}
              key={el?.id}
            >
              <p>{el?.name}</p>
              {selectedQuick === el?.id && (
                <div className="quick-answers_element-tools">
                  <Put
                    cursor={'pointer'}
                    onClick={() => {
                      setValue(el?.description)
                      updateEditorContent(questionId, editor?.id, el?.description)
                    }}
                  />
                  <Update
                    cursor={'pointer'}
                    onClick={() => {
                      dispatch(
                        openModal({
                          id: 'quick-answer-modal',
                          data: {
                            data: el,
                            isUpdate: true,
                          },
                        }),
                      )
                    }}
                  />
                  {isLoading ? (
                    '...'
                  ) : (
                    <Delete
                      cursor={'pointer'}
                      onClick={() => {
                        handleDeleteQuickAnswer(el?.id)
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>

      <AddQA
        cursor={'pointer'}
        onClick={() =>
          dispatch(
            openModal({
              id: 'quick-answer-modal',
              data,
            }),
          )
        }
      />
    </div>
  )
}
