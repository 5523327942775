import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeAr from 'dayjs/locale/ar'
import localeEn from 'dayjs/locale/en'
import localeFr from 'dayjs/locale/fr'
import { DateInput } from '@src/modules/shared/components/DateInput'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/task/calendar-add.svg'
import { useTranslation } from 'react-i18next'
import Button from '@src/modules/shared/components/Button/Button'
interface DateSelectorProps {
  onChange: (date: Dayjs) => void
  setSelectDate: (date: Date | Dayjs) => void
  selectedDate: Date
  dateType: string
  isSelected: boolean
}

const DateSelector: React.FC<DateSelectorProps> = ({
  onChange,
  setSelectDate,
  selectedDate,
  dateType,
  isSelected,
}) => {
  const { t } = useTranslation(['global'])
  const lang = localStorage.getItem('i18nextLng')
  const locale = lang === 'fr' ? localeFr : lang === 'ar' ? localeAr : localeEn
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)
  dayjs.locale(locale)

  return (
    <div className="date-selector">
      <DateInput
        placeholder={t('Start Date')}
        Icon={<CalendarIcon />}
        setSelectDate={setSelectDate}
        dateType={dateType}
        selectedDate={selectedDate}
        isSelected={isSelected}
      />

      <Button onClick={() => onChange(dayjs())}>
        <span>{t('Today')}</span> <span className="date">{dayjs().format('dddd')}</span>{' '}
      </Button>

      <Button onClick={() => onChange(dayjs().add(1, 'day'))}>
        <span>{t('Tomorrow')}</span>{' '}
        <span className="date">{dayjs().add(1, 'day').format('dddd')}</span>
      </Button>

      <Button onClick={() => onChange(dayjs().add(2, 'hours'))}>
        <span>{t('Later')}</span>
        <span className="date">{dayjs().add(2, 'hours').format('HH:mm')}</span>
      </Button>

      <Button onClick={() => onChange(dayjs().add(7, 'day'))}>
        <span>{t('Next week')}</span>
        <span className="date">{dayjs().add(7, 'day').format('DD-MMM')}</span>
      </Button>

      <Button onClick={() => onChange(dayjs().add(14, 'day'))}>
        <span>{t('2 weeks')}</span>
        <span className="date">{dayjs().add(14, 'day').format('DD-MMM')}</span>
      </Button>

      <Button onClick={() => onChange(dayjs().add(28, 'day'))}>
        <span>{t('4 weeks')}</span>
        <span className="date">{dayjs().add(28, 'day').format('DD-MMM')}</span>
      </Button>
    </div>
  )
}

export default DateSelector
