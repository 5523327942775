export function toField(original, defaultHidden = true) {
  return {
    id: original.id,
    field: original.fieldType,
    fieldType: original.fieldType,
    fieldName: original.name,
    value: original.name,
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    groupBy: false,
    order: 0,
    custom: true,
    hidden: defaultHidden,
    maxWidth: 300,
    description: original.description,
    typeConfig: original.typeConfig,
  }
}
