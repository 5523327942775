import { useAppDispatch } from '@src/modules/shared/store'
import { Dropdown, Avatar } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchIcon } from '../../assets/icons/integrations/search.svg'
import { MembersApi, useGetMembersQuery } from '../../services/membersApi'
import InfiniteScroll from 'react-infinite-scroll-component'
import check from '../../assets/icons/trash/check.svg'
import Search from '@src/modules/shared/components/Input/search'

type props = {
  selectedUsers: string[]
  setSelectedUsers: Function
}

const TrashFilterUsers = ({ selectedUsers, setSelectedUsers }: props) => {
  const { t } = useTranslation(['trash'])
  const dispatch = useAppDispatch()

  const [usersPage, setUsersPage] = useState<number>(1)
  const [usersSearch, setUsersSearch] = useState<string>('')

  const { data: usersList } = useGetMembersQuery({
    limit: 10,
    skip: usersPage,
    search: usersSearch,
  })
  useEffect(() => {
    dispatch(
      MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
        if (draft?.payload) draft.payload = []
        return draft
      }),
    )
  }, [usersSearch])

  const loadMoreUsers = () => {
    if (usersList?.metadata?.next) setUsersPage((prev) => prev + 1)
  }
  const handelSearch = (value: string) => {
    setUsersPage(1)
    setUsersSearch(value)
  }
  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <div className="filter_items trash_users">
          <div className="search_input">
            <Search
              placeholder={t('search_for_persons')}
              value={usersSearch}
              onSearch={handelSearch}
              prefix={<SearchIcon />}
              variant="label-inline"
              size="sm"
            />
          </div>

          <span className="People">
            {t('People')} {selectedUsers.length > 0 ? <>({selectedUsers.length})</> : ''}
          </span>

          <InfiniteScroll
            dataLength={usersList?.payload?.length || 0}
            next={loadMoreUsers}
            hasMore={usersList?.metadata?.next}
            loader={false}
            height={'250px'}
            className="users_list"
          >
            {usersList?.payload?.map((user: any, index: number) => (
              <div
                className="user_line"
                key={user?.id + index}
                onClick={() => {
                  if (!selectedUsers.includes(user?.id)) {
                    setSelectedUsers((prev) => [...prev, user?.id])
                  } else {
                    const newSelectedUsers = selectedUsers.filter((type) => user?.id !== type)
                    setSelectedUsers(newSelectedUsers)
                  }
                }}
              >
                <Avatar src={user?.avatar}>{user?.email?.slice(0, 1).toUpperCase()}</Avatar>
                <span>
                  {user?.fullName ||
                    (user?.email?.length < 17 ? user?.email : user?.email?.slice(0, 17) + '...')}
                </span>

                {selectedUsers?.includes(user?.id) && <img src={check} className="check-icon" />}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    >
      <div className="filter_item">
        <span>
          {t('Deleted by')} {selectedUsers.length > 0 ? <>({selectedUsers.length})</> : ''}
        </span>
      </div>
    </Dropdown>
  )
}

export default TrashFilterUsers
