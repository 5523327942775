/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'

export const exportData = createAsyncThunk('settings/export', async () => {
  try {
    const response = await axiosInstance.get(`/api/data/export`)
    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (err: any) {
    return Promise.reject(err.message)
  }
})

export const getImportedData = createAsyncThunk('settings/getImports', async () => {
  try {
    const response = await axiosInstance.get(`/api/data/import`)

    if (response.status === 200) {
      return response.data
    }

    throw new Error(response.statusText)
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const deleteImport = createAsyncThunk('settings/deleteImport', async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/api/data/import/${id}`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const importDataFromCsv = createAsyncThunk(
  'settings/import',
  async (query: { file: File; spaceId?: string }) => {
    try {
      const formData = new FormData()
      formData.append('file', query.file)
      const response = await axiosInstance.post(`/api/data/import?process=true`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.status === 201) {
        return response.data
      }
      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const importDataProcess = createAsyncThunk('settings/import-process', async (query: any) => {
  try {
    const { key, repair, mappedData } = query
    const processResponse = await axiosInstance.post(
      `api/data/process/${key}${repair ? `?repair=${repair}` : ''}`,
      mappedData ? mappedData : '',
    )
    if (processResponse.status === 201) {
      return processResponse.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})
// import from click-up
export const getRedirectUrl = createAsyncThunk('settings/import-clickup-redirect', async () => {
  try {
    const response = await axiosInstance.get(`/api/clickup-import`)

    if (response.status === 200) return response.data
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const getClickUpAuthToken = createAsyncThunk('settings/get-token', async (code: string) => {
  try {
    const response = await axiosInstance.post(`/api/clickup-import?code=${code}`)

    if (response.status === 201) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const getClickUpSpaces = createAsyncThunk(
  'settings/clickup-spaces',
  async (token: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/clickup-import/clickupSpaces?access_token=${token}`,
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getSpacesList = createAsyncThunk(
  'settings/clickup-spaces-lists',
  async ({ token, spaceId }: { token: string; spaceId: string }) => {
    try {
      const response = await axiosInstance.get(
        `/api/clickup-import/${spaceId}/clickOrphanLists?access_token=${token}`,
      )

      if (response.status === 200) {
        return response?.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getClickupFolders = createAsyncThunk(
  'settings/clickup-spaces-folders',
  async ({ token, spaceId }: { token: string; spaceId: string }) => {
    try {
      const response = await axiosInstance.get(
        `/api/clickup-import/${spaceId}/clickupFolders?access_token=${token}`,
      )

      if (response.status === 200) {
        return response?.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const getClickupFoldersLists = createAsyncThunk(
  'settings/clickup-spaces-folders-lists',
  async ({ token, folderId }: { token: string; folderId: string }) => {
    try {
      const response = await axiosInstance.get(
        `/api/clickup-import/${folderId}/clickLists?access_token=${token}`,
      )

      if (response.status === 200) {
        return response?.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const importClickUpData = createAsyncThunk(
  'settings/import-clickup',
  async ({ token, selectedItems }: { token: string; selectedItems: any[] }) => {
    try {
      const response = await axiosInstance.post(
        `/api/clickup-import/import?access_token=${token}`,
        { spaces: selectedItems },
      )

      if (response.status === 201) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const cancelImport = createAsyncThunk(
  `settings/cancel-clickup-import`,
  async (id: string) => {
    try {
      const response = await axiosInstance.post(`/api/clickup-import/cancelImport/${id}`)
      if (response.status === 200) return response.data
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)
