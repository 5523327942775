import { ComponentProps } from 'react'

type Props = {}

function CloseIcon(props: Props & ComponentProps<'svg'>) {
  return (
    <svg
      {...props}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1189_10184)">
        <path
          d="M13.4106 13.6154L10.8808 11.0856M10.8808 11.0856L8.35093 8.55577M10.8808 11.0856L8.35093 13.6154M10.8808 11.0856L13.4106 8.55577M1.60474 11.0856C1.60474 16.2086 5.75775 20.3616 10.8808 20.3616C16.0038 20.3616 20.1568 16.2086 20.1568 11.0856C20.1568 5.96259 16.0038 1.80957 10.8808 1.80957C5.75775 1.80957 1.60474 5.96259 1.60474 11.0856Z"
          stroke="#374957"
          strokeWidth="2.13038"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1189_10184">
          <rect
            width="20.2386"
            height="20.2386"
            fill="white"
            transform="translate(0.761475 0.966797)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CloseIcon
