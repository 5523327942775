import { Skeleton } from 'antd'

function TaskActivitiesSkeleton() {
  return (
    <div className="task-detail-skeleton-right-activities">
      <Skeleton
        avatar
        active={true}
        paragraph={{
          rows: 0,
        }}
      />
      <Skeleton
        avatar
        active={true}
        style={{
          padding: '20px',
          borderRadius: '30px',
        }}
        paragraph={{
          rows: 2,
        }}
      />

      <Skeleton
        avatar
        active={true}
        paragraph={{
          rows: 0,
        }}
      />
      {/* <Skeleton
        avatar
        active={true}
        style={{
          background: '#FFF',
          padding: '20px',
          borderRadius: '30px',
        }}
        paragraph={{
          rows: 2,
        }}
      /> */}

      {/* <Skeleton
        avatar
        active={true}
        paragraph={{
          rows: 0,
        }}
      /> */}

      <Skeleton
        avatar
        active={true}
        style={{
          padding: '20px',
          borderRadius: '30px',
        }}
        paragraph={{
          rows: 2,
        }}
      />
    </div>
  )
}

export default TaskActivitiesSkeleton
