import { defaultProps } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { setCodeElement } from '@src/modules/document/data/documentSlice/documentSlice'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { Dropdown, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import copy from '../../../../assets/icons/blockNote/copy.svg'
import embed from '../../../../assets/icons/blockNote/darkEmbed.svg'
import down from '../../../../assets/icons/blockNote/down.svg'
import modeIcon from '../../../../assets/icons/blockNote/night-mode.svg'

const languages = [
  { name: 'Bash' },
  { name: 'BASIC' },
  { name: 'C' },
  { name: 'C++' },
  { name: 'C#' },
  { name: 'Clojure' },
  { name: 'Dart' },
  { name: 'Docker' },
  { name: 'F#' },
  { name: 'Go' },
  { name: 'HTML' },
  { name: 'Java' },
  { name: 'JavaScript' },
  { name: 'Kotlin' },
  { name: 'Objective-C' },
  { name: 'PHP' },
  { name: 'Python' },
  { name: 'R' },
  { name: 'Rust' },
  { name: 'Scala' },
  { name: 'Shell' },
  { name: 'SQL' },
  { name: 'Swift' },
  { name: 'TypeScript' },
]

const CodeBlock = createReactBlockSpec(
  {
    type: 'codeBlock',
    propSchema: {
      ...defaultProps,
      lang: {
        default: '',
      },
      codeContent: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: ({ block }: any) => {
      const className = block.props.class || 'code-container'
      const lang = block.props.lang
      const codeContent = block.props.codeContent

      const dispatch = useAppDispatch()
      const [code, setCode] = useState(codeContent)
      const [selectedLang, setSelectedLang] = useState<string>(lang)
      const [mode, setMode] = useState<'dark' | 'light'>('dark')

      useEffect(() => {
        dispatch(setCodeElement({ code: code, lang: selectedLang }))
      }, [code, selectedLang])

      return (
        <div className={className}>
          <div className="settings">
            <Dropdown
              dropdownRender={() => (
                <div className="dropdown-render-settings">
                  {languages.map((language) => {
                    return (
                      <span
                        onClick={() => {
                          setSelectedLang(language.name)
                        }}
                      >
                        {language.name}
                      </span>
                    )
                  })}
                </div>
              )}
              trigger={['click']}
            >
              <span className="language">
                <span>{selectedLang || 'Language'}</span>
                <img src={down} />
              </span>
            </Dropdown>

            <div className="icons">
              <Tooltip title="Copy code">
                <img
                  src={copy}
                  onClick={() => {
                    navigator.clipboard.writeText(code)
                    message.success('Copied')
                  }}
                />
              </Tooltip>

              <img src={embed} />

              <Tooltip title="Switch mode">
                <img
                  src={modeIcon}
                  onClick={() => setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))}
                  style={{ width: '25px' }}
                />
              </Tooltip>
            </div>
          </div>

          <CodeEditor
            value={code}
            language={selectedLang}
            placeholder="Enter your code..."
            className={`code-editor ${mode === 'light' ? 'code-editor-transparent' : ''}`}
            data-color-mode={mode}
            onChange={(event) => {
              setCode(event.target.value)
            }}
          />
        </div>
      )
    },
  },
)

export default CodeBlock
