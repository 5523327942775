import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import LastUpdateInfos from '@src/modules/document/components/LastUpdateInfos'
import { createDocument } from '@src/modules/document/data/documentSlice/documentThunk'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { getFolder } from '@src/modules/spaces/data/folderSlice/folderThunk'
import { getSpace } from '@src/modules/spaces/data/spaceSlice/spacesThunk'
import { uploadImage } from '@src/modules/tasks/components/EditTaskPopup/utils/pastEvent'
import { Dropdown, Modal, Spin, message } from 'antd'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import down from '../../assets/icons/modal/down.svg'
import image from '../../assets/icons/modal/image.svg'
import search from '../../assets/icons/modal/search.svg'
import share from '../../assets/icons/modal/share.svg'
import smile from '../../assets/icons/modal/smile.svg'
import star from '../../assets/icons/modal/star.svg'
import Button from '@src/modules/shared/components/Button/Button'
import { setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { useNavigate } from 'react-router-dom'
import Search from '@src/modules/shared/components/Input/search'
import Input from '@src/modules/shared/components/Input/Input'

type props = { open: boolean; handleClose: Function; id: string }

type propsValues = {
  name: string
  cover?: string
  icon?: string
  content?: string
  spaceId?: string
  folderId?: string
}

const CreateNewDocument = ({ open, handleClose, id }: props) => {
  const { t } = useTranslation(['document'])

  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const currentTime = new Date()

  const [cover, setCover] = useState(null)
  const [icon, setIcon] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [spaceId, setSpaceId] = useState<string>('')
  const [folderId, setFolderId] = useState<string>('')
  const [locationName, setLocationName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleCoverChange = (event) => {
    setIsLoading(true)
    try {
      uploadImage(event.target.files[0]).then(async (res) => {
        setCover(res?.location)
        setIsLoading(false)
      })
    } catch (error) {
      message.error(error?.message)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
    },

    onSubmit: (values: propsValues) => {
      if (!folderId && !spaceId) {
        message.error(t('Pleas select location First'))
        return
      }
      if (!values?.name?.trim()) {
        message.error(t('name_is_required'))
        return
      }
      if (cover) values.cover = cover
      if (content) values.content = content
      if (icon) values.icon = icon
      if (spaceId) values.spaceId = spaceId
      if (folderId) values.folderId = folderId

      try {
        dispatch(createDocument(values))
          .unwrap()
          .then((res) => {
            dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: res.payload }))
            message.success(t('Document created with success'))
            handleClose(id)
            navigate(`/spaces/document/${res?.payload?.id}`)
          })
          .catch((error) => {
            message.error(error.message || t('Error while creating a document!'))
          })
      } catch (error) {
        message.error(error.message || t('Error while creating a document!'))
      }
    },
  })

  return (
    <Modal
      footer={true}
      centered
      open={open}
      closeIcon={null}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      closable={false}
      className="create-new-document-modal"
    >
      <div className="header">
        <div className="title">{t('Create a new Document')}</div>

        <div className="header-options">
          <span>{t('In')}</span>

          <Dropdown
            dropdownRender={() => (
              <div className="ant-dropdown-menu task-dropdown spaces-tree">
                <label className="location-search">
                  <Search
                    placeholder={t('Search...')}
                    variant="label-inline"
                    size="sm"
                    prefix={<img src={search} />}
                  />
                </label>

                <SpacesTree
                  readOnly
                  onSelectFolder={(folder) => {
                    setFolderId(folder[0])
                    dispatch(getFolder({ id: folder[0] || '' }))
                      .unwrap()
                      .then((res) => {
                        setLocationName(res?.payload?.name)
                      })
                  }}
                  onSelectSpace={(space) => {
                    setSpaceId(space[0])
                    dispatch(getSpace({ id: space[0] || '' }))
                      .unwrap()
                      .then((res) => {
                        setLocationName(res?.payload?.name)
                      })
                  }}
                />
              </div>
            )}
            trigger={['click']}
          >
            <div className="select-location">
              {locationName ? (
                <span>
                  {locationName.slice(0, 15) + `${locationName.length > 15 ? '...' : ''}`}
                </span>
              ) : (
                <span>{t('Select Location')}</span>
              )}
              <img src={down} />
            </div>
          </Dropdown>

          <img src={star} />

          <img src={share} />
        </div>
      </div>

      <form
        className="content"
        onSubmit={formik.handleSubmit}
        style={cover ? { marginTop: '0px' } : {}}
      >
        {isLoading && <Spin />}

        {cover && <img src={cover} className="cover" />}

        {icon && <span className={`emoji ${cover && 'is-cover-exist'}`}>{icon}</span>}

        <div className="options" style={cover && icon ? { display: 'none' } : {}}>
          {!icon && (
            <Dropdown
              dropdownRender={() => (
                <Picker
                  data={data}
                  categories={['frequent', 'people', 'nature', 'foods', 'activity']}
                  onEmojiSelect={(emoji) => {
                    setIcon(emoji.native)
                  }}
                />
              )}
              trigger={['click']}
            >
              <div className="option">
                <img src={smile} />
                <span>{t('Add icon')}</span>
              </div>
            </Dropdown>
          )}

          {!cover && (
            <label className="option">
              <Input
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleCoverChange(e)}
                variant="label-inline"
              />

              <img src={image} />
              <span>{t('Add cover')}</span>
            </label>
          )}
        </div>

        <Input
          type="text"
          placeholder={t('Untitled')}
          containerClassName="doc-name"
          style={cover && icon ? { marginTop: '40px' } : {}}
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          variant="label-inline"
          size="lg"
        />

        <LastUpdateInfos owner={user?.user} updatedAt={currentTime} />

        <div className="divider"></div>

        <BlockNoteRichText value={content} setValue={setContent} />

        <div className="btns">
          <Button onClick={() => handleClose(id)} className="btn btn-cancel">
            {t('Cancel')}
          </Button>

          <Button htmlType="submit" className="btn btn-save">
            {t('Save')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateNewDocument
