import { message } from 'antd'
import { VideoPlayerProps, VideoPlayer as Vidify } from 'vidify'
import logo from './../../../assets/icons/logo/logo-sub.svg'
import { ReactComponent as CopySVG } from '../../../assets/icons/mediaPlayer/copy.svg'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'

const VideoPlayer = ({ videos, media }: any) => {
  const { i18n } = useTranslation()
  const videoRef = useRef(null)
  const handlerError = () => {
    message.error('Error on video URL')
  }

  const activeIndex = videos.findIndex((video) => video?.id === media?.id)

  return (
    <div className="media-video-player" onClick={(e) => e.stopPropagation()}>
      <Vidify
        src={media?.location}
        defaultSrcIndex={activeIndex}
        ref={videoRef}
        onError={handlerError}
        className="video"
        block
        autoPlay
        annotation={<img src={logo} />}
        primaryColor="#9747FF"
        lang={i18n.language as VideoPlayerProps['lang']}
        error={{ withRetry: true }}
        contextMenu={[
          {
            label: 'Copy video URL',
            onClick: () => window.navigator.clipboard.writeText(videoRef.current.src),
            icon: <CopySVG />,
          },
        ]}
      />

      {/* <MuxPlayer src={media.location} autoPlay onError={handlerError} /> */}
    </div>
  )
}

export default VideoPlayer
