import React, { ReactNode, useCallback, useRef } from 'react'
import { TreeItemRenderContext } from 'react-complex-tree'
import { FileType } from '@src/modules/spaces/data/fileSlice/fileSlice'
import TaskRow from '../TaskRow'
import { useIsInViewport } from '@src/modules/tasks/hooks/useInViewport'
import { Checkbox, Skeleton } from 'antd'
import AddSubTaskInput from '../AddSubTask/AddSubTaskInput'
import { ReactComponent as DragIcon } from './../../../assets/icons/task/drag.svg'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'

export const TaskSkeleton = ({ depth }: { depth: number }) => {
  const randomWidth = Math.floor(Math.random() * (400 - 150 + 1)) + 150
  return (
    <RTLStyleDecorator>
      <div
        className="task-item-skeleton"
        style={{
          paddingLeft: `${(depth + 1) * 29 + 61}px`,
        }}
      >
        <Skeleton.Avatar size={'small'} active style={{ width: '16px', height: '16px' }} />
        <Skeleton.Input
          active
          size={'small'}
          style={{ width: `${randomWidth}px`, height: '16px' }}
        />
      </div>
    </RTLStyleDecorator>
  )
}

export const getFakeTask = (tree, item) => {
  return tree['fake-task'] && tree['fake-task']?.parentId === item?.index ? tree['fake-task'] : null
}

export interface renderItemProps {
  title: string
  arrow: ReactNode
  depth: number
  context: TreeItemRenderContext
  children: ReactNode
  addToSelectedItems: () => void
  unselectItem: () => void
  isSelected: boolean
  groupBy: string
  tree: any
  removeFakeTask: () => void
  addFakeTask: (p: string, t: boolean, b: boolean) => void
  activeList: FileType
  multiple: boolean
  autoOpen: boolean
  readOnly: boolean
  lineatIndex: number
}

export const RenderItemWrapper = (props: renderItemProps) => {
  const taskContainerRef = useRef()
  const { tree, title } = props
  const item = tree[title]
  const data = item?.content
  const isInViewport = useIsInViewport(taskContainerRef, { ...data }, null)

  return <div ref={taskContainerRef}>{renderItem(props, isInViewport)}</div>
}

const renderItem = (props, isInViewport) => {
  const {
    title,
    arrow,
    depth,
    context,
    children,
    addToSelectedItems,
    unselectItem,
    isSelected,
    removeFakeTask,
    addFakeTask,
    tree,
    groupBy,
    activeList,
  } = props

  const item = tree[title]
  const data = item?.content
  const parentItem = tree[item?.parentId]
  const fakeTask = getFakeTask(tree, item)

  const selectItem = () => {
    if (isSelected) unselectItem()
    else addToSelectedItems()
  }

  const onDragStartHandler = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    document.body.style.overflow = 'auto'
    const dragElements = document.querySelectorAll('.drag-element-spaces')
    dragElements.forEach((ele) => ele.remove())

    event.dataTransfer.clearData()
    event.dataTransfer.setData('text/plain', '')
    const dragElement = document.createElement('div')
    dragElement.className = 'drag-element-spaces'
    dragElement.innerHTML = `<div>${data?.name.length > 70 ? data?.name.slice(0, 70) + '...' : data?.name}</div>`
    document.body.appendChild(dragElement)
    document.body.style.overflow = 'hidden'
    event.dataTransfer.setDragImage(dragElement, 0, 0)
    context.interactiveElementProps.onDragStart(event)
  }, [])

  return (
    <li {...context.itemContainerWithChildrenProps} className="item-task-row">
      {isInViewport ? (
        <div
          {...context.itemContainerWithoutChildrenProps}
          onDragOver={context.interactiveElementProps.onDragOver}
          draggable={context.interactiveElementProps.draggable}
          tabIndex={context.interactiveElementProps.tabIndex}
          data-rct-item-focus={context.interactiveElementProps['data-rct-item-focus']}
          data-rct-item-id={context.interactiveElementProps['data-rct-item-id']}
          data-rct-item-interactive={context.interactiveElementProps['data-rct-item-interactive']}
          style={{ position: 'relative' }}
          className={`task-container ${isSelected ? 'selected-task' : ''}`}
          onDragStart={onDragStartHandler}
        >
          {item?.id === 'fake-task' ? (
            <AddSubTaskInput
              parentId={parentItem?.isRoot ? null : item?.parentId}
              removeFakeTask={removeFakeTask}
              isBottom={item?.isBottom}
              data={parentItem?.isRoot ? parentItem?.content : parentItem?.content?.status}
              groupBy={groupBy}
              depth={depth}
            />
          ) : item ? (
            <>
              <RTLStyleDecorator>
                <div className="task-container-options">
                  <span className="drag-icon-task">
                    <DragIcon />
                  </span>
                  <Checkbox
                    checked={isSelected}
                    onClick={(e) => e.stopPropagation()}
                    onChange={selectItem}
                    className={`select-checkbox-task ${isSelected ? 'show' : ''}`}
                  />
                </div>
              </RTLStyleDecorator>
              <TaskRow
                data={data}
                list={activeList}
                depth={depth}
                toogleExpand={context.toggleExpandedState}
                expand={context.expandItem}
                arrow={arrow}
                addFakeTask={(top, bottom) => addFakeTask(data?.id, top, bottom)}
                arrowProps={context.arrowProps}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className="list-view-default-task"></div>
      )}

      {item?.isLoading ? (
        <>
          {fakeTask?.isTop && (
            <AddSubTaskInput
              parentId={tree[fakeTask?.index]?.isRoot ? null : tree[fakeTask?.index]?.parentId}
              removeFakeTask={removeFakeTask}
              isBottom={false}
              groupBy={groupBy}
              data={
                tree[fakeTask?.index]?.isRoot
                  ? tree[fakeTask?.index]?.content
                  : tree[fakeTask?.index]?.content?.status
              }
              depth={depth + 1}
            />
          )}
          {Array(item?.content?.totalSubtask || 0)
            .fill('')
            .map((_, i) => (
              <TaskSkeleton depth={depth} key={i} />
            ))}
          {fakeTask?.isBottom && (
            <AddSubTaskInput
              parentId={tree[fakeTask?.index]?.isRoot ? null : tree[fakeTask?.index]?.parentId}
              removeFakeTask={removeFakeTask}
              isBottom={true}
              groupBy={groupBy}
              data={
                tree[fakeTask?.index]?.isRoot
                  ? tree[fakeTask?.index]?.content
                  : tree[fakeTask?.index]?.content?.status
              }
              depth={depth + 1}
            />
          )}
        </>
      ) : (
        children
      )}
    </li>
  )
}
