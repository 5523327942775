import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ar_EG'
import { useTranslation } from 'react-i18next'

const DateFilter = ({ onData, disable }) => {
  const { i18n } = useTranslation()
  return (
    <div className="date-filter">
      <DatePicker
        disabled={disable}
        inputReadOnly={true}
        variant={'borderless'}
        {...(i18n.language === 'ar' ? { locale } : {})}
        onChange={(date) => onData(date.toISOString())}
      />
    </div>
  )
}

export default DateFilter
