import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { ReactComponent as DescriptionsIcon } from './../../assets/icons/task/descriptions.svg'
import { Popover } from 'antd'

interface TaskDescriptionPreview {
  task: TaskType
}

const TaskDescriptionPreview = ({ task }: TaskDescriptionPreview) => {
  const description = task?.description
  if (!description) return null

  return (
    <div className="description-preview">
      <Popover
        placement={'leftTop'}
        overlayInnerStyle={{
          maxHeight: '400px',
          maxWidth: '400px',
        }}
        overlayClassName="description-preview-popover"
        content={<BlockNoteRichText onlyView value={description} setValue={() => {}} />}
      >
        <DescriptionsIcon />
      </Popover>
    </div>
  )
}

export default TaskDescriptionPreview
