import { Fragment } from 'react'
import Content from '../Content'

interface LayoutViewProps {
  children: React.ReactNode
  sidebar?: React.ComponentType<any>
  header: React.ComponentType<LayoutViewProps['headerProps']>
  headerProps?: {
    title?: string
    items?: { key: string; label: string } | any
    onTab?: void | any
    onAllowChange?: (a: boolean) => void
    document?: any
  }
  sidebarProps?: any
}

function ViewLayout({ children, sidebar, header, headerProps, sidebarProps }: LayoutViewProps) {
  const Sidebar = sidebar || Fragment
  const Header = header || Fragment

  return (
    <div className="view-layout">
      <Sidebar {...sidebarProps} />
      <div className="view-layout-content">
        <Header {...headerProps} />
        <Content>{children}</Content>
      </div>
    </div>
  )
}

export default ViewLayout
