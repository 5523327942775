import Button from '@src/modules/shared/components/Button/Button'
import { CustomRadioButton } from '../../../components/CustomRadioButton'
import { useTranslation } from 'react-i18next'
import persons from '../../../assets/icons/persons.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

interface Props {
  options: string[]
  selected: any
  setSelected: any
  confirmTheme: boolean
  setConfirmUserNumber: any
  confirmUsersNumber: boolean
}
const WorkspaceUsers: React.FC<Props> = ({
  options,
  selected,
  setSelected,
  confirmTheme,
  setConfirmUserNumber,
  confirmUsersNumber,
}) => {
  const { t } = useTranslation(['workspaces'])
  const { width } = useWindowSize()

  return (
    <>
      <h1>{t('How many persons will this Workspace include?')}</h1>

      <CustomRadioButton
        options={options}
        selected={selected}
        onSelect={setSelected}
        disabled={confirmTheme ? false : true}
      />

      {width <= 850 && <img src={persons} />}

      <Button
        children={t('Next')}
        className="workspace-btn next-btn"
        htmlType="button"
        onClick={() => setConfirmUserNumber(!confirmUsersNumber)}
        disabled={confirmTheme ? false : true}
      />
    </>
  )
}

export default WorkspaceUsers
