import { treeSupportedTypes } from '@src/modules/shared/components/SpacesTree/SpacesTree'
import { sharedTreeSupportedTypes } from '@src/modules/shared/components/SpacesTree/SharedTree'
import { TreeItemType } from '../components/Spaces/Spaces'

export const getTreeItemById = (listId: string | null, documentId: string | null): TreeItemType => {
  let id = null
  let type = null

  if (listId) {
    id = listId
    type = treeSupportedTypes.LIST || sharedTreeSupportedTypes.LIST
  } else if (documentId) {
    id = documentId
    type = treeSupportedTypes.DOCUMENT || sharedTreeSupportedTypes.DOCUMENT
  }

  return { id, type }
}
