import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useGetTasksOverviewQuery } from '../../services/dashboardApi'
import { useTranslation } from 'react-i18next'
import noTasks from '../../assets/icons/dashboard/no-tasks.svg'
import { Skeleton, Row, Tooltip } from 'antd'

const TasksOverview = () => {
  const { t } = useTranslation(['dashboard'])
  const { data, isLoading } = useGetTasksOverviewQuery({})

  const totalTasks = data
    ?.map((item) => Number(item.count))
    .reduce((acc: number, item: number) => acc + item, 0)

  const tasksData = data?.map((d) => ({
    x: d.category_name,
    y: Number(d.count),
    color: d.category_color,
  }))

  const options: Highcharts.Options | any = {
    chart: {
      type: 'pie',
    },

    tooltip: {
      pointFormat: '{point.x}: <b>{point.y} tasks</b>',
    },

    plotOptions: {
      pie: {
        innerSize: '82%',
        depth: 45,
      },
      series: {
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        animation: {
          duration: 1000,
        },
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        data: tasksData,
      },
    ],
  }

  if (tasksData?.length === 0)
    return (
      <div className="no-data">
        <img src={noTasks} />
        <p>{t('No Data Yet...')}</p>
      </div>
    )

  return (
    <div className="tasks-overview">
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <p className="title">
            <span className="total-tasks">{t('Total tasks')}</span>
            <span className="tasks-number">{totalTasks}</span>
          </p>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <div className="legends">
            {tasksData?.map((data, index: number) => {
              return (
                <Tooltip
                  title={data.category_name}
                  color={'#fff'}
                  overlayInnerStyle={{ color: '#374957' }}
                >
                  <div className="legend" key={index + data.x}>
                    <span style={{ backgroundColor: data.color }}>.</span> {data.x}
                  </div>
                </Tooltip>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default TasksOverview

const LoadingSkeleton = () => {
  return (
    <div className="task-overview-skeleton">
      <Row justify="center" align="middle">
        <Skeleton.Avatar active size={150} shape="circle" />
      </Row>
      <Row justify="center" align="middle">
        <Skeleton.Input active size="small" style={{ width: 200 }} />
      </Row>
    </div>
  )
}
