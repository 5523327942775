import { api } from '@src/modules/shared/services/api'
import { IDocument } from '@softylines/tam-types'
import socketManager from '@src/modules/shared/socket/socketManager'

export const documentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocument: build.query<IDocument, string>({
      query: (id) => {
        return {
          url: `api/documents/${id}`,
        }
      },
      transformResponse: (response: { payload: IDocument }): IDocument => {
        return response?.payload
      },
      async onCacheEntryAdded(_, { updateCachedData }) {
        socketManager._getSocket().on('notifRoom', (update) => {
          updateCachedData((draft) => {
            const newContent = update?.payload?.content
            if (newContent !== draft.content)
              if (update.event?.toLowerCase() === 'document') {
                draft.content = newContent
                return draft
              }

            //   TODO
            // let index = update?.indexOf('//')
            // if (index !== -1) {
            //   let type = update?.substr(0, index)
            //   let data = update?.substr(index + 2)
            //   if (
            //     type === 'UPDATED' &&
            //     JSON.stringify(draft) !== data &&
            //     draft?.id === JSON.parse(data)?.id
            //   ) {
            //     return JSON.parse(data)
            //   }
            // }
          })
        })
      },
    }),
    shareDocument: build.mutation<any, any>({
      query(data) {
        return {
          url: `api/publicShare`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: (data) => {
        if (data.payload != 1) {
          return [{ type: 'DOCUMENT', id: data._id }] as any
        }
        return []
      },
    }),
    getDocumentById: build.query({
      query: (params) => {
        return {
          url: `api/share/public/${params.id}`,
        }
      },
      providesTags: (result: { _id: string; expiresAt: string; entityId: string }) => {
        return result ? ([{ type: 'DOCUMENT', id: result._id }] as any) : result
      },
    }),
  }),
})

export const { useGetDocumentQuery, useShareDocumentMutation, useGetDocumentByIdQuery } =
  documentApi
