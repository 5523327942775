import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'
import MenuSVG from '../icons/MenuSVG'
import {
  useDeleteAttachementsMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from '@src/modules/tasks/services/tasksApi'
import { useSearchParams } from 'react-router-dom'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import { downloadFile } from '@src/modules/shared/utils/downloadFile'
import { EyeOutlined } from '@ant-design/icons'
import Button from '@src/modules/shared/components/Button/Button'

type Props = {
  attachment: EnhancedUploadFile<any>
}

const Actions = ({ attachment }: Props) => {
  const [deleteAttachements] = useDeleteAttachementsMutation()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  const { data: task } = useGetTaskQuery({ id: searchParams.get('taskId') } as any)
  const [updateTask] = useUpdateTaskMutation()
  const navigate = useNavigate()
  const allowPreview =
    attachment?.mimetype?.includes('image') ||
    attachment?.mimetype?.includes('video') ||
    attachment?.mimetype?.includes('pdf')

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Preview',
      disabled: !allowPreview,
      icon: <EyeOutlined />,
      onClick: () => navigate(updateURL({ label: 'attachementId', value: attachment?.id })),
    },
    {
      key: '2',
      label: 'Download',
      icon: <DownloadOutlined />,
      onClick: () => downloadFile({ url: attachment?.location, name: attachment?.originalname }),
    },
    {
      key: '3',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: () => {
        deleteAttachements({ id: attachment.id as any }).then(() => {
          updateTask({
            id: taskId,
            attachementsId: task?.attachements
              ?.filter((attachement) => attachement.id !== attachment.id)
              .map((attachement) => attachement.id) as string[],
          } as any)
        })
      },
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button className="attachment-preview-menu">
        <MenuSVG />
      </Button>
    </Dropdown>
  )
}

export default Actions
