import { CreateAnswer } from '@src/modules/dailyQuestions/services/memberAnswerApi'
import { message } from 'antd'
import { Editor, ICurrentQuestion } from '../memberView.interface'

interface CreateAnswerResponse {
  error: {
    message: string[]
    statusCode: number
  }
}

interface CreateAnswerResponse {
  error: {
    message: string[]
    statusCode: number
  }
}

export const handleCreateAnswer = async (
  answerContent: CreateAnswer,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsView: React.Dispatch<React.SetStateAction<boolean>>,
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>,
  getRes: () => void,
  createAnswerMutation: (answerContent: CreateAnswer) => Promise<CreateAnswerResponse>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  try {
    setLoading(true)
    const response: CreateAnswerResponse = await createAnswerMutation(answerContent)
    if (response?.error?.statusCode === 400) {
      if (response?.error?.message?.length > 0) {
        message.error(response?.error?.message)
      } else {
        message.error('error while updating answer')
      }
    } else {
      message.success('Answer created with success')
      setIsView(true)
      setSuccess(true)
      setEditors({})
      getRes()
    }
  } catch (error) {
    message.error(error?.message || 'Error creating answer')
  } finally {
    setLoading(false)
  }
}

export const createAnswerFn = (
  currentQuestion: ICurrentQuestion,
  currentResponse: Date,
  getEditorsForQuestionId: Editor[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsView: React.Dispatch<React.SetStateAction<boolean>>,
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>,
  getRes,
  createAnswerMutation,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const body = []
  let empty: number = 0
  getEditorsForQuestionId?.map((el) => {
    const attachmentIds = el?.files?.map((el) => {
      return el?.data?.id
    })
    body?.push({
      time: el?.time,
      content: el?.content,
      attachmentIds,
    })

    if (!el?.content) {
      empty++
    }
  })
  const answerData = {
    questionId: currentQuestion ? currentQuestion.id : undefined,
    body,
    date: currentResponse,
  }

  if (!currentResponse) message.error('You Must Define The Date !')
  else if (empty !== 0) {
    message.error('Your blocs should not be empty')
  } else {
    handleCreateAnswer(
      answerData,
      setLoading,
      setIsView,
      setEditors,
      getRes,
      createAnswerMutation,
      setSuccess,
    )
  }
}
