/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUsers } from './membersThunk'

const initialState = {
  loading: false,
  error: null,
  users: [],
  selectedUsers: [],
  metadata: null,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = []
    },
    addUser: (state, payload) => {
      state.selectedUsers.push(payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.error = null
        state.loading = true
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.error = null
        state.loading = false
        state.users.push(...action?.payload?.payload)
        state.metadata = action.payload?.metadata
      })
      .addCase(getUsers.rejected, (state) => {
        state.error = true
        state.loading = false
      })
  },
})

export const { clearUsers, addUser } = usersSlice.actions // eslint-disable-line

export default usersSlice.reducer
