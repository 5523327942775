import { Tooltip } from 'antd'
import React, { MouseEventHandler } from 'react'
import { Editor, ICurrentQuestion } from '../../memberView.interface'
import { ReactComponent as Add } from '../../assets/plus.svg'
import { useTranslation } from 'react-i18next'
import AnimatedButton from '@src/modules/shared/components/AnimatedButton/AnimatedButton'
import Button from '@src/modules/shared/components/Button/Button'

interface IAddBlocButton {
  isView: boolean
  currentQuestion: ICurrentQuestion
  addNewEditor: (currentQuestion: ICurrentQuestion, editors: any, setEditors: any) => void
  editors: any
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>
}

function AddBlocButton({
  isView,
  addNewEditor,
  setEditors,
  editors,
  currentQuestion,
}: IAddBlocButton) {
  const handleAddNewEditor: MouseEventHandler<HTMLButtonElement> = () => {
    addNewEditor(currentQuestion, editors, setEditors)
  }
  const { t } = useTranslation(['dailyQuestion'])

  return (
    <>
      {!isView && (
        <Tooltip title="Add answer">
          <AnimatedButton>
            <Button className="add-answer-container_button" onClick={handleAddNewEditor}>
              <Add />
              <p>{t('answers_tab.add_task')}</p>
            </Button>
          </AnimatedButton>
        </Tooltip>
      )}
    </>
  )
}

export default React.memo(AddBlocButton)
