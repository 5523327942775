import { ReactComponent as CSV } from '../../../../assets/icons/importExport/csv-active.svg'
import { ReactComponent as CLICKUP } from '../../../../assets/icons/importExport/ClickUpActive.svg'
import { ReactComponent as CheckSVG } from '@src/modules/shared/assets/icons/components/Check_ring_light.svg'
import { ReactComponent as DeleteSVG } from '@src/modules/shared/assets/icons/components/trash.svg'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { reformulateDate } from '@src/modules/notifications/utils/reformulateDate'
import { useAppDispatch } from '@src/modules/shared/store/index'
import {
  deleteImport,
  getImportedData,
} from '@src/modules/settings/data/ImportExport/ImportExportThunk'
import { useTranslation } from 'react-i18next'

interface propsType {
  createdAt: string
  deletedAt: null
  endAt: string
  id: string
  import_type: string
  name: string
  ownerId: string
  startAt: string
  updatedAt: string
}

function index({ importedInfo }: { importedInfo: propsType }) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['importExport'])

  return (
    <div className="imported-data-card">
      {importedInfo.import_type === 'click' ? (
        <CLICKUP className="asana-logo" />
      ) : (
        <CSV className="asana-logo" />
      )}

      <div className="info">
        <div className="success">
          <CheckSVG />
          <span>
            {t('imported_on')} {reformulateDate(importedInfo?.createdAt)}
          </span>
        </div>

        <Can I="delete" a="import">
          <div
            className="delete"
            onClick={() =>
              dispatch(deleteImport(importedInfo?.id))
                .unwrap()
                .then(() => dispatch(getImportedData()))
            }
          >
            <DeleteSVG /> <span>{t('delete_imported_data')}</span>
          </div>
        </Can>
      </div>
    </div>
  )
}

export default index
