interface Editor {
  id: number
  content: string
  time: string | null
}

interface EditorsState {
  [key: string]: Editor[]
}

const updateEditorTime = (
  id: number | null,
  time: string | null,
  setEditors: React.Dispatch<React.SetStateAction<EditorsState>>,
): void => {
  setEditors((prev) => {
    const updatedEditors = { ...prev }
    Object.keys(updatedEditors).forEach((key) => {
      updatedEditors[key] = updatedEditors[key].map((editor) =>
        editor.id === id ? { ...editor, time } : editor,
      )
    })
    return updatedEditors
  })
}

export default updateEditorTime
