import { api } from '@src/modules/shared/services/api'

export const RolesApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRoles: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/roles`,
          params,
        }
      },

      providesTags: (result = []) => [
        ...result?.payload?.map(({ id }: any) => ({ type: 'ROLES', id }) as const),
        { type: 'ROLES', id: 'ROLE' },
      ],
    }),
  }),
})

export const { useGetRolesQuery } = RolesApi
