export const removeDuplicatesById = (arr) => {
  const uniqueIds = new Set()
  return arr?.filter((obj) => {
    if (!uniqueIds.has(obj.id)) {
      uniqueIds.add(obj.id)
      return true
    }
    return false
  })
}
