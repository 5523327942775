import { forwardRef, useCallback } from 'react'
import { TypeAttributes, WithAsProps } from '../../types/common'
import { ButtonHTMLType, ButtonType } from './utils/buttonHelpers'
import classNames from 'classnames'
import { getPrefixCls } from '../../utils/getPrefixCls'

import Loading from '../Loading'

export interface BaseButtonProps {
  type?: ButtonType | 'danger'
  icon?: string | React.ReactNode
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  shape?: TypeAttributes.Shape
  size?: TypeAttributes.Size
  disabled?: boolean
  loading?: boolean
  block?: boolean
  danger?: boolean
  direction?: TypeAttributes.Direction
}

export type MergedHTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement> &
    React.ButtonHTMLAttributes<HTMLElement> &
    React.AnchorHTMLAttributes<HTMLElement>,
  'type'
>

export interface ButtonProps extends BaseButtonProps, MergedHTMLAttributes, WithAsProps {
  href?: string
  htmlType?: ButtonHTMLType
}

export const InternalButton: React.ForwardRefRenderFunction<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
> = (props: ButtonProps, ref) => {
  const {
    as,
    children,
    htmlType,
    className,
    shape,
    type = 'default',
    icon,
    disabled,
    size = 'sm',
    block,
    startIcon,
    endIcon,
    loading,
    danger,
    onClick,
    direction = 'ltr',
    ...rest
  } = props

  const prefixCls = getPrefixCls('btn')

  const classes = classNames(
    className,
    prefixCls,
    {
      [`${prefixCls}-${shape}`]: shape !== 'default' && shape,
      [`${prefixCls}-${size}`]: size,
      [`${prefixCls}-disabled`]: disabled,
      [`${prefixCls}-block`]: block,
      [`${prefixCls}-icon-only`]: !children && children !== 0 && !loading && icon,
      [`${prefixCls}-loading`]: loading,
      [`${prefixCls}-danger`]: !!danger,
    },
    `${prefixCls}-${direction}`,
    `${prefixCls}-${type}`,
  )

  const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    if (loading || disabled) {
      e.preventDefault()
      return
    }

    onClick?.(e)
  }

  const iconNode =
    icon && !loading ? (
      <span className={`${prefixCls}-icon`}>
        {icon && <img src={icon as string} alt="icon" className="tam-btn-icon" />}
      </span>
    ) : loading ? (
      Loading()
    ) : null

  const renderStartIcon = (): JSX.Element | null => {
    if (startIcon && !loading) {
      return (
        <span className={`${prefixCls}-start-icon`}>
          {startIcon && <img src={startIcon as string} alt="icon" className="icon" />}
        </span>
      )
    } else if (loading) {
      return null
    }
    return null
  }

  const renderButtonContent = useCallback(() => {
    if (icon)
      return (
        <>
          {iconNode} {children}
        </>
      )

    return (
      <>
        {renderStartIcon()}
        {children}
        {endIcon ? (
          <span className={`${prefixCls}-end-icon`}>
            {<img src={endIcon as string} alt="icon" className="icon" />}
          </span>
        ) : null}
      </>
    )
  }, [icon, iconNode, renderStartIcon, children, endIcon, loading, prefixCls, startIcon])

  const Component = as || 'button'

  if (rest.href !== undefined) {
    return (
      <a
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...rest}
        className={classes}
        onClick={handleClick}
        href={rest.href}
        tabIndex={disabled ? -1 : 0}
      >
        {loading ? Loading() : null} {renderButtonContent()}
      </a>
    )
  }

  return (
    <Component
      ref={ref as React.Ref<HTMLButtonElement>}
      className={classes}
      {...rest}
      onClick={handleClick}
      type={htmlType}
      disabled={disabled || loading}
    >
      {loading ? Loading() : null} {renderButtonContent()}
    </Component>
  )
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(InternalButton)

export default Button
