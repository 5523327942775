import DropdownSpinner from '@src/modules/shared/components/DropdownSpinner'
import { statusType } from '../../../spaces/data/statusSlice/statusSlice'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { useTranslation } from 'react-i18next'

export interface TaskListData {
  activeItemIDs?: [string | number] | null | undefined
  activeItemID?: string | null | undefined
  isLoading: boolean
  onSelect?: (s: any) => void
}
export interface TaskListProps {
  handleCloseAllDropdowns: () => void
}

interface StatusListData extends TaskListData {
  statuses: statusType[] | null
}
interface StatusListProps extends TaskListProps {
  data: StatusListData
}

const StatusList = ({ data, handleCloseAllDropdowns }: StatusListProps) => {
  const { t } = useTranslation(['tasks'])

  return (
    <div className="task-dropdown status-list-dropdown ant-dropdown-menu">
      {data.isLoading ? (
        <DropdownSpinner />
      ) : (
        data.statuses?.map((status: statusType, i: number) => (
          <div
            className={`status-item ${data?.activeItemID === status.id ? 'active-status' : ''}`}
            onClick={() => {
              data?.onSelect && data.onSelect(status)
              handleCloseAllDropdowns()
            }}
            key={i}
          >
            <div className="icon" style={{ backgroundColor: status.color }}></div>
            <OverflowTooltip children={t(status?.name)} className="label" />
          </div>
        ))
      )}
    </div>
  )
}

export default StatusList
